import React from 'react';
import { string, bool, shape } from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import capitalize from '@mui/material/utils/capitalize';
import Box from '@mui/material/Box';
import { NotificationManager } from 'react-notifications';
import { UI_ROUTES } from '../../../constants/routes';
import { StyledBox } from './styled';
import OptionsMenu from '../../../components/atoms/OptionsMenu';
import {
  StyledCopyToClipboard,
  StyledCopyValue,
} from '../../../components/FlowEditor/components/AutomationDetails/AutomationStep/fields/styled';
import Copy from '../../../assets/icons/Copy';
import THEME from '../../../constants/theme';
import { CopyToClipboard } from '../../../components/atoms';
import ToastCustomContainer from '../../../components/ToastCustomContainer';
import { TOAST_TIMEOUT } from '../../../constants/toasts';

const Header = ({ primary, secondary, connection, statusActionHandlers, isPaymentConnection }) => {
  const { t } = useTranslation();
  const { id: smartId } = useParams();

  const options = [
    statusActionHandlers[connection.status] && {
      label: statusActionHandlers[connection.status]?.label,
      onClick: statusActionHandlers[connection.status]?.event,
    },
  ]?.filter(Boolean);

  const handleCopy = () => {
    NotificationManager.success(
      <ToastCustomContainer message="Copied Smart ID to clipboard." title="Smart ID." />,
      null,
      TOAST_TIMEOUT
    );
  };

  return (
    <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
      <StyledBox display="flex" alignItems="center" m="10px 0 0 0">
        <StyledBox component={Link} to={primary?.route} fontSize="24px" fontWeight="600" mt="-8px" color="#232629">
          <span>{t(capitalize(primary?.title))}</span>
        </StyledBox>

        {secondary && (
          <Box component="span" display="flex" alignItems="center" mt="-5px">
            <StyledBox
              alignSelf="flex-start"
              component="span"
              m="0 6px"
              fontSize="16px"
              lineHeight="normal"
              color="#C1C3C6"
              fontWeight="600"
            >
              /
            </StyledBox>

            <StyledBox
              component="span"
              fontSize="14px"
              lineHeight="1.5"
              color="#787F88"
              whiteSpace="nowrap"
              flexGrow="1"
              width="auto"
              overflow="hidden"
            >
              {secondary}
            </StyledBox>

            {isPaymentConnection && smartId ? (
              <>
                <StyledBox
                  alignSelf="flex-start"
                  component="span"
                  m="0 6px"
                  fontSize="16px"
                  lineHeight="normal"
                  color="#C1C3C6"
                  fontWeight="600"
                >
                  /
                </StyledBox>

                <CopyToClipboard className="smallUrl" text={smartId} onCopy={handleCopy}>
                  <StyledCopyToClipboard $justifyContent="flex-start" $padding="0">
                    <StyledCopyValue $width="100%" $margin="0 4px 0 0" $padding="0">
                      {smartId}
                    </StyledCopyValue>
                    <Copy fill={THEME.greyColors.grey1} />
                  </StyledCopyToClipboard>
                </CopyToClipboard>
              </>
            ) : null}
          </Box>
        )}
      </StyledBox>

      <Box ml="auto">
        <OptionsMenu options={options} />
      </Box>
    </Box>
  );
};

Header.propTypes = {
  primary: shape({
    title: string,
    route: string,
  }),
  secondary: string,
  connection: shape({
    id: string,
    status: string,
    company: shape({
      name: string,
    }),
  }).isRequired,
  statusActionHandlers: shape({}).isRequired,
  isPaymentConnection: bool,
};

Header.defaultProps = {
  primary: {
    title: 'Connections',
    route: UI_ROUTES.connections,
  },
  secondary: undefined,
  isPaymentConnection: false,
};

export default Header;
