const formNames = {
  companyMarkets: 'companyMarkets',
};

const formFields = [{ field: formNames.companyMarkets, label: '' }];

const initialValues = {
  [formNames.companyMarkets]: [],
};

export { initialValues, formFields, formNames };
