import { gql } from '@apollo/client';

export const GET_CONFIG_FORM = gql`
  query getConfigForm($id: String!) {
    getConfigForm(id: $id) {
      form
    }
  }
`;

export const UPDATE_CONNECTION_CONFIG = gql`
  mutation updateConnectionConfig($id: String!, $formInput: Object!) {
    updateConnectionConfig(id: $id, formInput: $formInput) {
      id
      name
      status
    }
  }
`;
