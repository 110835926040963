import {
  AmountCell,
  DateCell,
  TextCapitalizeCell,
  TextCell,
  TextUppercaseCell,
  TagStatusCell,
} from '../../../components/table/ReactTableCells';
import { DISPUTE_FIELDS } from './disputeFields';
import ConnectionCell from '../components/Details/DetailsTable/cells/ConnectionCell';

const CELL_SETTINGS = {
  disablePadding: false,
  numeric: false,
  width: 150,
};

const MD_WIDTH = 200;
const CUSTOM_MAX_COPY_TEXT_LENGTH = 15;

const headCells = [
  {
    accessor: DISPUTE_FIELDS.amount,
    Header: DISPUTE_FIELDS.amount,
    Cell: AmountCell,
    ...CELL_SETTINGS,
  },
  {
    accessor: DISPUTE_FIELDS.status,
    Header: DISPUTE_FIELDS.status,
    Cell: TagStatusCell,
    ...CELL_SETTINGS,
  },
  {
    accessor: DISPUTE_FIELDS.reason,
    Header: DISPUTE_FIELDS.reason,
    Cell: TextCapitalizeCell,
    ...CELL_SETTINGS,
    width: MD_WIDTH,
  },
  {
    accessor: DISPUTE_FIELDS.connectionId,
    Header: DISPUTE_FIELDS.connectionId,
    Cell: ConnectionCell,
    ...CELL_SETTINGS,
  },
  {
    accessor: DISPUTE_FIELDS.disputeDate,
    Header: DISPUTE_FIELDS.disputeDate,
    Cell: DateCell,
    ...CELL_SETTINGS,
  },
  {
    accessor: DISPUTE_FIELDS.responseDate,
    Header: DISPUTE_FIELDS.responseDate,
    Cell: DateCell,
    ...CELL_SETTINGS,
  },
  {
    accessor: DISPUTE_FIELDS.currency,
    Header: DISPUTE_FIELDS.currency,
    Cell: TextUppercaseCell,
    ...CELL_SETTINGS,
  },
  {
    accessor: DISPUTE_FIELDS.processorStatus,
    Header: DISPUTE_FIELDS.processorStatus,
    Cell: TextCell,
    ...CELL_SETTINGS,
  },
  {
    accessor: DISPUTE_FIELDS.processor,
    Header: DISPUTE_FIELDS.processor,
    Cell: TextUppercaseCell,
    ...CELL_SETTINGS,
  },
  {
    accessor: DISPUTE_FIELDS.sourceId,
    Header: DISPUTE_FIELDS.sourceId,
    ...CELL_SETTINGS,
    width: MD_WIDTH,
    Cell: TextCell,
    maxTextLength: CUSTOM_MAX_COPY_TEXT_LENGTH,
  },
  {
    accessor: DISPUTE_FIELDS.paymentId,
    Header: DISPUTE_FIELDS.paymentId,
    ...CELL_SETTINGS,
    width: MD_WIDTH,
    Cell: TextCell,
    maxTextLength: CUSTOM_MAX_COPY_TEXT_LENGTH,
  },
  {
    accessor: DISPUTE_FIELDS.teamId,
    Header: DISPUTE_FIELDS.teamId,
    ...CELL_SETTINGS,
    width: MD_WIDTH,
    Cell: TextCell,
    maxTextLength: CUSTOM_MAX_COPY_TEXT_LENGTH,
  },
  {
    accessor: DISPUTE_FIELDS.id,
    Header: DISPUTE_FIELDS.id,
    ...CELL_SETTINGS,
    width: MD_WIDTH,
    Cell: TextCell,
    maxTextLength: CUSTOM_MAX_COPY_TEXT_LENGTH,
  },
];

const transformNodeToRow = (node) => ({
  id: node.id,
  [DISPUTE_FIELDS.amount]: node.amount?.formattedAmount ?? 'N/A', // TODO: BE - needs to be formatted
  [DISPUTE_FIELDS.currency]: node.currency ?? 'N/A',
  [DISPUTE_FIELDS.disputeDate]: node.disputeDate ?? 'N/A',
  [DISPUTE_FIELDS.processor]: node.processor ?? 'N/A',
  [DISPUTE_FIELDS.processorStatus]: node.processorStatus ?? 'N/A',
  [DISPUTE_FIELDS.reason]: node.reason ?? 'N/A',
  [DISPUTE_FIELDS.responseDate]: node.responseDate ?? 'N/A',
  [DISPUTE_FIELDS.status]: node.status ?? 'N/A',
  [DISPUTE_FIELDS.teamId]: node.teamId ?? 'N/A',
  [DISPUTE_FIELDS.sourceId]: node.sourceId ?? 'N/A',
  [DISPUTE_FIELDS.paymentId]: node.paymentId ?? 'N/A',
  [DISPUTE_FIELDS.connectionId]: node.connectionId ?? 'N/A',
});

export { transformNodeToRow, headCells };
