import React, { useEffect, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { NotificationManager } from 'react-notifications';
import isEmpty from 'lodash/isEmpty';

import { useLocalStorage } from 'react-use';
import { GQL_M_IMPORT_HISTORIC_PAYMENT } from '../../../../../../utils/queries/connections/connectionsMutations';
import { GET_IMPORT_STATUS } from '../../../../../../utils/queries/connections/connectionsQueries';
import { isDefined } from '../../../../../../utils/helpers';
import { MAX_MOCK_PROGRESS, IMPORT_STATUS_DICTIONARY } from './constant';
import ToastCustomContainer from '../../../../../../components/ToastCustomContainer';

const TIMEOUT_MS = 60000; // 1 minute
const TOAST_TITLE = 'Connection.';
const TOAST_TIMEOUT = 5000;
const mutateOptions = (id) => ({
  refetchQueries: [{ query: GET_IMPORT_STATUS, variables: { id } }],
  awaitRefetchQueries: true,
});
/**
 * @typedef HistoricPaymentResult
 * @property {number} progress
 * @property {function} handleImportHistoricPayment
 * @property {string} importStatus
 * @property {boolean} loading
 * @property {boolean} importHistoricPaymentLoading
 *
 *
 *
 * */

/**
 * @param {string} connectionId
 *
 * @returns {HistoricPaymentResult}
 * */

const useHistoricPayment = ({ connectionId }) => {
  const { data, loading: importStatusLoading, startPolling, stopPolling } = useQuery(GET_IMPORT_STATUS, {
    variables: { id: connectionId },
    skip: !connectionId,
  });

  const loading = importStatusLoading && isEmpty(data?.getImportConnectionPaymentsGateway);

  useEffect(() => {
    if (data?.getImportConnectionPaymentsGateway?.status === IMPORT_STATUS_DICTIONARY.importing) {
      startPolling(TIMEOUT_MS * 5); //5 minutes
    }
    return () => {
      stopPolling();
    };
  }, [data?.getImportConnectionPaymentsGateway?.status, startPolling, stopPolling]);

  const importStatus = useMemo(
    () => data?.getImportConnectionPaymentsGateway?.status ?? IMPORT_STATUS_DICTIONARY.default,
    [data?.getImportConnectionPaymentsGateway?.status]
  );

  const [importHistoricPayment, { loading: importHistoricPaymentLoading }] = useMutation(
    GQL_M_IMPORT_HISTORIC_PAYMENT,
    mutateOptions(connectionId)
  );
  const [progress, setProgress] = useLocalStorage(`importProgressBar:${connectionId}`, 0);

  const handleStartProgress = () => {
    setProgress(1);
  };

  useEffect(() => {
    if (progress && progress < MAX_MOCK_PROGRESS && importStatus !== IMPORT_STATUS_DICTIONARY.completed) {
      setTimeout(
        () =>
          setProgress((prevState) => {
            if (prevState < MAX_MOCK_PROGRESS) {
              return prevState + Math.floor(Math.random() * 3);
            }
            return prevState;
          }),
        TIMEOUT_MS
      );
    }
  }, [progress, setProgress, importStatus]);

  const handleImportHistoricPayment = () => {
    importHistoricPayment({
      variables: { id: connectionId },
    }).then((res) => {
      if (res && !isEmpty(res?.errors)) {
        NotificationManager.error(
          <ToastCustomContainer message={res?.errors[0]?.message} title={TOAST_TITLE} />,
          null,
          TOAST_TIMEOUT
        );

        return;
      }

      if (isDefined(res?.data?.importConnectionPaymentsGateway?.id)) {
        handleStartProgress();
      } else {
        NotificationManager.error(
          <ToastCustomContainer message="Something went wrong" title={TOAST_TITLE} />,
          null,
          TOAST_TIMEOUT
        );
      }
    });
  };

  return {
    progress,
    handleImportHistoricPayment,
    importStatus,
    loading,
    importHistoricPaymentLoading,
  };
};

export { useHistoricPayment };
