import { shape } from 'prop-types';
import React from 'react';
import omit from 'lodash/omit';
import { Pagination } from '@mui/lab';
import { StyledDialogActions } from '../../../../Dialog/styled';
import useSearch from '../../../../../hooks/useSearch';
import { useStyles } from './styled';

const Footer = ({ pageInfo }) => {
  const [searchParams, setSearchParams] = useSearch();
  const { totalPages } = pageInfo;
  const classes = useStyles({});

  const currentPage = (searchParams?.page ? searchParams.page - 1 : 0) + 1;

  const handleChange = (e, page) => {
    setSearchParams((prevSearchParams) =>
      page === 1 ? omit(prevSearchParams, 'page') : { ...prevSearchParams, page }
    );
  };

  return (
    <StyledDialogActions px="24px" py="16px" $borderTop>
      <Pagination
        count={totalPages}
        page={currentPage}
        shape="rounded"
        onChange={handleChange}
        classes={{ ul: classes.ul }}
      />
    </StyledDialogActions>
  );
};

Footer.propTypes = {
  pageInfo: shape({}).isRequired,
};

export default Footer;
