import { Box } from '@mui/material';
import React from 'react';
import THEME from '../../../../constants/theme';
import { P14 } from '../../../atoms';
import { HighlightedText } from './styled';

const StepTwo = () => (
  <Box>
    <P14 margin="0 0 12px 0" color={THEME.greyColors.grey23}>
      <HighlightedText color={THEME.primaryColors.black}>Intent API Integration: </HighlightedText>
      Use the Intent API to help track your sales funnel, from when a customer begins the checkout process to payment
      completion. The benefit of using this API is that you can get rich insights on active and inactive checkout
      sessions, potentially lost payments, and failed payment attempts.
    </P14>
  </Box>
);

export default StepTwo;
