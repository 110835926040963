import React from 'react';

const Fraud = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22Z"
        fill="#0CC48C"
      />
      <path
        d="M18.6725 13.9875C17.482 14.3156 16.2996 14.6724 15.1263 15.0575C15.0191 15.0922 14.9239 15.1563 14.8514 15.2425C14.7789 15.3287 14.7321 15.4335 14.7163 15.545C14.0238 20.7413 15.6238 24.5325 17.5325 27.03C18.3407 28.098 19.3043 29.0389 20.3913 29.8213C20.8238 30.1262 21.2063 30.3463 21.5075 30.4875C21.6575 30.5588 21.78 30.6063 21.8738 30.635C21.9148 30.6494 21.957 30.6598 22 30.6663C22.0425 30.6593 22.0843 30.6489 22.125 30.635C22.22 30.6063 22.3425 30.5588 22.4925 30.4875C22.7925 30.3463 23.1763 30.125 23.6088 29.8213C24.6957 29.0388 25.6593 28.098 26.4675 27.03C28.3763 24.5338 29.9763 20.7413 29.2838 15.545C29.2681 15.4334 29.2213 15.3286 29.1488 15.2423C29.0763 15.1561 28.981 15.0921 28.8738 15.0575C28.06 14.7913 26.6863 14.3575 25.3275 13.9887C23.94 13.6125 22.6638 13.3338 22 13.3338C21.3375 13.3338 20.06 13.6125 18.6725 13.9887V13.9875ZM18.34 12.7C19.6963 12.3313 21.1375 12 22 12C22.8625 12 24.3038 12.3313 25.66 12.7C27.0475 13.075 28.4463 13.5187 29.2688 13.7875C29.6126 13.9011 29.9175 14.1093 30.1484 14.3884C30.3792 14.6674 30.5266 15.0059 30.5738 15.365C31.3188 20.9613 29.59 25.1088 27.4925 27.8525C26.6031 29.0262 25.5425 30.0598 24.3463 30.9188C23.9326 31.216 23.4944 31.4773 23.0363 31.7C22.6863 31.865 22.31 32 22 32C21.69 32 21.315 31.865 20.9638 31.7C20.5056 31.4773 20.0674 31.216 19.6538 30.9188C18.4575 30.0598 17.397 29.0262 16.5075 27.8525C14.41 25.1088 12.6813 20.9613 13.4263 15.365C13.4734 15.0059 13.6208 14.6674 13.8517 14.3884C14.0825 14.1093 14.3874 13.9011 14.7313 13.7875C15.9252 13.396 17.1285 13.0334 18.34 12.7Z"
        fill="white"
      />
      <path
        d="M25.5676 18.4325C25.6258 18.4906 25.6719 18.5595 25.7034 18.6355C25.735 18.7114 25.7512 18.7928 25.7512 18.875C25.7512 18.9572 25.735 19.0386 25.7034 19.1146C25.6719 19.1905 25.6258 19.2595 25.5676 19.3175L21.8176 23.0675C21.7595 23.1257 21.6905 23.1719 21.6146 23.2034C21.5387 23.2349 21.4573 23.2511 21.3751 23.2511C21.2928 23.2511 21.2114 23.2349 21.1355 23.2034C21.0596 23.1719 20.9906 23.1257 20.9326 23.0675L19.0576 21.1925C18.9994 21.1344 18.9534 21.0654 18.9219 20.9895C18.8905 20.9136 18.8743 20.8322 18.8743 20.75C18.8743 20.6678 18.8905 20.5865 18.9219 20.5105C18.9534 20.4346 18.9994 20.3656 19.0576 20.3075C19.1157 20.2494 19.1847 20.2033 19.2606 20.1719C19.3365 20.1404 19.4179 20.1242 19.5001 20.1242C19.5822 20.1242 19.6636 20.1404 19.7395 20.1719C19.8155 20.2033 19.8844 20.2494 19.9426 20.3075L21.3751 21.7413L24.6826 18.4325C24.7406 18.3743 24.8096 18.3281 24.8855 18.2966C24.9614 18.2651 25.0428 18.2489 25.1251 18.2489C25.2073 18.2489 25.2887 18.2651 25.3646 18.2966C25.4405 18.3281 25.5095 18.3743 25.5676 18.4325Z"
        fill="white"
      />
    </svg>
  );
};

export default Fraud;
