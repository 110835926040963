import React, { useCallback } from 'react';
import { bool, oneOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import { GoogleAuthButton } from '../atoms';
import { useNotificationManager } from '../../hooks/useNotificationManager';
import { useGoogleOAuth, GOOGLE_AUTH_TYPE, ROUTE_BY_GOOGLE_AUTH_TYPE_MAP } from '../../hooks/useGoogleOAuth';
import { TOAST_TIMEOUT, TOAST_UNEXPECTED_ERROR_TITLE } from '../../constants/toasts';
import { useGlobalContext } from '../../containers/App/context';

const GoogleAuth = ({ type, loading, children }) => {
  const { t } = useTranslation();
  const { authenticate, error } = useGoogleOAuth();
  const { isReactGAInitialized } = useGlobalContext();

  useNotificationManager('error', error, t(TOAST_UNEXPECTED_ERROR_TITLE), TOAST_TIMEOUT);

  const handleCustomSignIn = useCallback(() => {
    const redirectUri = `${window.origin}${ROUTE_BY_GOOGLE_AUTH_TYPE_MAP[type]}`;
    authenticate(type, redirectUri);

    if (isReactGAInitialized) {
      // For Google Analytics
      ReactGA.event({
        category: 'Button',
        action: "Click the button for 'Sign up with Google'",
      });
    }
  }, [authenticate, type, isReactGAInitialized]);

  return (
    <GoogleAuthButton handleClick={handleCustomSignIn} loading={loading}>
      {children}
    </GoogleAuthButton>
  );
};

GoogleAuth.propTypes = {
  type: oneOf(Object.values(GOOGLE_AUTH_TYPE)).isRequired,
  loading: bool,
};

GoogleAuth.defaultProps = {
  loading: false,
};

export default GoogleAuth;
