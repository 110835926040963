import React, { useMemo, useEffect } from 'react';
import { string } from 'prop-types';
import { Background, ConnectionLineType, MarkerType, ReactFlowProvider } from 'react-flow-renderer';
import Box from '@mui/material/Box';
import { CircularLoader } from '../atoms';
import { FlowHeader } from '../FlowHeader';
import { useGlobalContext } from '../../containers/App/context';
import { FlowEditorContextProvider } from '../FlowEditor/context';
import { useEditorData } from '../FlowEditor/hooks/useEditorData';
import { getEdgeColor } from '../FlowEditor/utils/getEdgeColor';
import { CanvasContainer, StyledReactFlow } from '../FlowEditor/styled';
import { AutomationDetails } from '../FlowEditor/components/AutomationDetails';
import { nodeTypesMap } from '../FlowEditor/utils/nodeTypesMap';
import { edgeTypes } from '../FlowEditor/utils/edgeTypes';
import THEME from '../../constants/theme';
import FlowEmpty from '../FlowEditor/components/FlowEmpty';
import { flowStepTypes } from '../FlowEditor/types';
import { getFlowNodes } from '../FlowEditor/utils/getFlowNodes';

const PLAN_MAP = ['business_individual', 'business_team', 'enterprise'];

const FlowVersion = ({ flowId, versionId }) => {
  const {
    context,
    nodes,
    edges,
    isDataReady,
    publishedData,
    loading,
    reactFlowWrapper,
    setReactFlowInstance,
    minimumPlanRequired,
    toggleShowUpgradeModal,
    selectedNodeId,
    setSelectedNodeId,
    setIsValidateOn,
    hoverNodeId,
  } = useEditorData(flowId, versionId);

  const { getMeData } = useGlobalContext();
  const activePlan = getMeData?.we?.activePlan?.plan?.uiCode;

  useEffect(() => {
    if (PLAN_MAP.indexOf(minimumPlanRequired) > PLAN_MAP.indexOf(activePlan) && !loading) {
      toggleShowUpgradeModal();
    }
  }, [minimumPlanRequired, activePlan, loading, toggleShowUpgradeModal]);

  const isEmpty = isDataReady && nodes.length === 0;

  const onNodeClick = (_, { id, data }) => {
    if (data?.__typename !== flowStepTypes.AnnotationStep) {
      setSelectedNodeId(id);
    }
  };

  const reactFlowNodes = useMemo(() => getFlowNodes(nodes), [nodes]);

  const reactFlowEdges = useMemo(
    () =>
      edges.map((edge) => ({
        ...edge,
        markerEnd: {
          type: MarkerType.Arrow,
          width: 22,
          height: 22,
          color: getEdgeColor({ edge, selectedNodeId, hoverNodeId }),
        },
      })),
    [edges, hoverNodeId, selectedNodeId]
  );

  return (
    <FlowEditorContextProvider value={{ ...context, versionId }}>
      <ReactFlowProvider>
        <CanvasContainer ref={reactFlowWrapper}>
          <AutomationDetails />
          <FlowHeader flowId={flowId} publishedData={publishedData} onValidate={() => setIsValidateOn(true)} />
          {loading && (
            <Box position="absolute" left="50%" top="45%">
              <CircularLoader />
            </Box>
          )}
          <StyledReactFlow
            onInit={setReactFlowInstance}
            defaultNodes={reactFlowNodes}
            defaultEdges={reactFlowEdges}
            nodeTypes={nodeTypesMap}
            edgeTypes={edgeTypes}
            nodesDraggable={false}
            nodesConnectable={false}
            elementsSelectable={false}
            onNodeClick={onNodeClick}
            connectionLineType={ConnectionLineType.SmoothStep}
            minZoom={0.5}
            maxZoom={2}
          >
            <Background variant="dots" gap={24} size={0.9} color={THEME.greyColors.grey8} />
          </StyledReactFlow>
        </CanvasContainer>
      </ReactFlowProvider>
      {isEmpty && !loading && <FlowEmpty />}
    </FlowEditorContextProvider>
  );
};

FlowVersion.propTypes = {
  flowId: string,
  versionId: string,
};
FlowVersion.defaultProps = {
  flowId: null,
  versionId: null,
};

export default FlowVersion;
