import React, { useMemo } from 'react';
import { shape, string, bool, arrayOf, oneOfType, number } from 'prop-types';
import Box from '@mui/material/Box';
import capitalize from '@mui/material/utils/capitalize';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next/src';
import { PAYMENT_METHOD_ICON_MAP } from '../../../../../assets/icons/PaymentMethods';
import { TopBox, TopBoxesWrapper, StyledCardTypeIcon } from '../../../../../components/GridSystem/styled';
import { H2, P14, P12 } from '../../../../../components/atoms';
import THEME from '../../../../../constants/theme';
import { Tag } from '../../../../../components/common/Tag/styled';
import { PAYMENT_STATUS_MAP, INTENT_STATUSES } from '../../constant';
import { STATUS_UI_LABEL_MAP, transformStatusUiLabel } from '../../../../../components/table/Cells/constant';
import { transformUiStatusLabel } from '../constant';
import IntentTrackingId from './components/IntentTrackingId';
import { StyledLink } from './styled';

const TITLE_COLOR = '#787F88';
const PT = '5px';

const Summary = ({ payment, intentTrackingId, customer, vaultedCustomerId }) => {
  const { t } = useTranslation();
  const paymentAmount = payment?.amount?.formattedAmount || '';
  const paymentDate = payment?.date || '';
  const isIntent = INTENT_STATUSES.includes(payment?.status);
  const isRefundPayment = payment?.status === PAYMENT_STATUS_MAP.REFUNDED;
  const isDeclinedPayment = payment?.status === PAYMENT_STATUS_MAP.DECLINED;
  const { refundedAmount } = payment;
  const { paymentMethod: method, processorStatus, outcome } = payment?.paymentCore ?? {};
  const { declineCode } = outcome ?? {};
  const paymentStatus = payment.status;

  const cols = useMemo(() => {
    const common = [
      { title: 'Date', children: paymentDate ? moment(paymentDate).format('MMM DD, LT') : 'N/A' },
      {
        title: 'Customer',
        children: (
          <Box
            component={vaultedCustomerId ? StyledLink : 'span'}
            {...(vaultedCustomerId ? { href: `/data-tables/customers/details/${vaultedCustomerId}` } : null)}
          >
            {customer?.name || 'Guest'}{' '}
          </Box>
        ),
      },
    ];

    if (!isIntent) {
      const Icon = PAYMENT_METHOD_ICON_MAP[method] ?? PAYMENT_METHOD_ICON_MAP.UNKNOWN_CARD;
      common.push({
        title: 'Payment Method',
        children: (
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <StyledCardTypeIcon component="span" $margin="-4px 0 0 0">
              <Icon />
            </StyledCardTypeIcon>
            <Box component="span" mb="4px" ml="8px">
              {method?.toUpperCase() || 'N/A'}
            </Box>
          </Box>
        ),
      });

      common.push({ title: 'Processor Status', children: transformUiStatusLabel(processorStatus) || 'N/A' });

      if (isRefundPayment) {
        common.push({
          title: refundedAmount?.label || 'Refunded Amount',
          children: refundedAmount?.value || 'N/A',
        });
      }

      if (isDeclinedPayment) {
        common.push({ title: 'Decline Reason', children: transformUiStatusLabel(declineCode) || 'N/A' });
      }
    }

    return common;
  }, [
    declineCode,
    method,
    paymentDate,
    processorStatus,
    refundedAmount,
    isRefundPayment,
    isDeclinedPayment,
    isIntent,
    customer,
    vaultedCustomerId,
  ]);

  return (
    <Box component="section" mt="34px" mb="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <P12 margin="12px 0" color={THEME.greyColors.grey18}>
          {capitalize(t(isIntent ? 'Intent' : 'Payment'))}
        </P12>
        {isIntent && intentTrackingId && <IntentTrackingId intentTrackingId={intentTrackingId} />}
      </Box>

      <Box alignItems="center" display="flex" mt="-4px" mb="25px">
        <H2 fontSize="32px" mr="16px" fontWeight="700">
          {paymentAmount}
        </H2>
        {paymentStatus && (
          <Box ml="16px">
            <Tag variant={paymentStatus?.toLowerCase()}>
              {STATUS_UI_LABEL_MAP[paymentStatus]?.toUpperCase() || transformStatusUiLabel(paymentStatus)}
            </Tag>
          </Box>
        )}
      </Box>

      <TopBoxesWrapper>
        {cols.map((col) => (
          <TopBox key={col.title}>
            <P14 color={TITLE_COLOR}>{t(col.title)}</P14>

            <Box pt={PT}>
              {col.children && col.children !== 'N/A' ? col.children : null}
              {(!col.children || col.children === 'N/A') && <span>N/A</span>}
            </Box>
          </TopBox>
        ))}
      </TopBoxesWrapper>
    </Box>
  );
};

Summary.propTypes = {
  payment: shape({
    trackingId: string,
    amount: shape({
      value: string,
      formattedAmount: string,
    }),
    status: shape({
      isEditable: bool,
      label: string,
      type: string,
      value: string,
    }),
    type: shape({
      value: string,
    }),
    date: shape({
      isEditable: bool,
      label: string,
      type: string,
      value: string,
    }),
    paymentCore: shape({
      value: arrayOf(
        shape({
          key: string,
          value: oneOfType([number, string, shape({}), arrayOf(shape({}))]),
        })
      ),
    }),
    paymentGateway: shape({
      value: arrayOf(
        shape({
          key: string,
          value: string,
        })
      ),
    }),
    refundedAmount: shape({
      label: string,
      value: string,
    }),
    intent: shape({
      value: arrayOf(
        shape({
          id: string,
        })
      ),
    }),
  }).isRequired,
  intentTrackingId: string,
  customer: oneOfType([
    shape({
      name: string,
    }),
    shape({
      value: arrayOf(shape({})),
    }),
  ]).isRequired,
  vaultedCustomerId: string,
};
Summary.defaultProps = {
  intentTrackingId: undefined,
  vaultedCustomerId: undefined,
};

export default Summary;
