import React, { useState } from 'react';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import { bool, shape, string } from 'prop-types';
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from './styled';
import { P14M } from '../../../../../atoms';
import DataSetSelect from './DataSetSelect';

const DataSetAccordion = ({ record, isSelected }) => {
  const { key, label, type } = record;

  const [expanded, setExpanded] = useState(isSelected && key);
  const [isMenuOpened, setMenuOpened] = useState(false);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <StyledAccordion expanded={expanded === key} onChange={handleChange(key)} zIndex={isMenuOpened ? 1 : 0}>
      <StyledAccordionSummary expandIcon={<KeyboardArrowDownRounded />} aria-controls="panel1d-content" id={key}>
        <P14M>{label}</P14M>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <DataSetSelect dataKey={key} type={type} onMenuStateChange={setMenuOpened} />
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

DataSetAccordion.propTypes = {
  record: shape({
    key: string.isRequired,
    label: string.isRequired,
  }).isRequired,
  isSelected: bool.isRequired,
};

export default DataSetAccordion;
