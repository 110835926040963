import React, { useEffect, useState } from 'react';
import { string, shape, func, bool } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { generateLabel } from '../constant';
import XSmall from '../../../../../../assets/icons/XSmall';
import useSearch from '../../../../../../hooks/useSearch';
import { StyledTag } from './styled';
import THEME from '../../../../../../constants/theme';

const Tag = ({ label, tag, selectedFilters, setSelectedFilters, persistent, clearSearchFilter }) => {
  const [, setSearchParams] = useSearch();
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    const selected = selectedFilters?.[label]?.includes(tag);

    setIsSelected(selected);
  }, [selectedFilters, setIsSelected, label, tag]);

  const updateSearchParams = (filters) => {
    if (isEmpty(filters)) {
      return setSearchParams({});
    }

    setSearchParams((prevSearchParams) => {
      const prevFilters = { ...prevSearchParams.filters };
      const hasFilterItems = filters[label].length !== 0;

      const searchParams = hasFilterItems
        ? { ...prevSearchParams, filters: { ...prevFilters, [label]: filters[label].join(',') } }
        : { ...prevSearchParams, filters: omit(prevSearchParams.filters, [label]) };

      return searchParams;
    });

    return null;
  };

  const handleSelect = () => {
    setIsSelected(!isSelected);

    if (typeof clearSearchFilter === 'function') {
      clearSearchFilter();
    }

    setSelectedFilters((prev) => {
      if (isSelected) {
        const filteredItems = prev[label].filter((item) => item !== tag);
        const filters = { ...prev, [label]: [...filteredItems] };

        updateSearchParams(filters);
        return filters;
      }

      const prevTags = prev?.[label] ? prev[label] : [];
      const filters = { ...prev, [label]: [...prevTags, tag] };

      updateSearchParams(filters);
      return filters;
    });
  };

  return (
    <StyledTag isSelected={isSelected} onClick={handleSelect} isPersistent={persistent}>
      {generateLabel(tag)} {isSelected && <XSmall color={THEME.primaryColors.white} />}
    </StyledTag>
  );
};

Tag.propTypes = {
  label: string.isRequired,
  tag: string.isRequired,
  selectedFilters: shape({}).isRequired,
  setSelectedFilters: func.isRequired,
  clearSearchFilter: func,
  persistent: bool,
};

Tag.defaultProps = {
  persistent: false,
  clearSearchFilter: null,
};

export default Tag;
