import React from 'react';

const FAQ = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="15.5" fill="white" stroke="#E6E9EC" />
      <path
        d="M16.1803 19.1159L16.5408 18.5551L16.1803 19.1159ZM15.8198 19.1159L15.4593 18.5551L15.8198 19.1159ZM11.847 21.6699L12.2075 22.2306L11.847 21.6699ZM20.1531 21.6699L19.7927 22.2306L20.1531 21.6699ZM13.0001 11.3333H19.0001V10H13.0001V11.3333ZM20.0001 12.3333V21.3895H21.3334V12.3333H20.0001ZM12.0001 21.3895V12.3333H10.6667V21.3895H12.0001ZM20.5137 21.1091L16.5408 18.5551L15.8198 19.6767L19.7927 22.2306L20.5137 21.1091ZM15.4593 18.5551L11.4865 21.1091L12.2075 22.2306L16.1803 19.6767L15.4593 18.5551ZM16.5408 18.5551C16.2114 18.3433 15.7887 18.3433 15.4593 18.5551L16.1803 19.6767C16.0705 19.7473 15.9296 19.7473 15.8198 19.6767L16.5408 18.5551ZM10.6667 21.3895C10.6667 22.1806 11.542 22.6585 12.2075 22.2306L11.4865 21.1091C11.7083 20.9665 12.0001 21.1257 12.0001 21.3895H10.6667ZM20.0001 21.3895C20.0001 21.1257 20.2918 20.9665 20.5137 21.1091L19.7927 22.2306C20.4581 22.6585 21.3334 22.1806 21.3334 21.3895H20.0001ZM19.0001 11.3333C19.5523 11.3333 20.0001 11.781 20.0001 12.3333H21.3334C21.3334 11.0447 20.2887 10 19.0001 10V11.3333ZM13.0001 10C11.7114 10 10.6667 11.0447 10.6667 12.3333H12.0001C12.0001 11.781 12.4478 11.3333 13.0001 11.3333V10Z"
        fill="#4E40EF"
      />
    </svg>
  );
};

export default FAQ;
