import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { ConditionsBox, ElseConditionBox } from './styled';
import NewCondition from '../NewCondition';
import { P16M } from '../../../../../../atoms';
import { useNodeDataToSave } from '../hooks/useNodeDataToSave';
import Trash3 from '../../../../../../../assets/icons/Trash3';
import { IconBox } from '../Test/styled';

const NewConditions = () => {
  const { t } = useTranslation();
  const [{ conditions: nodeConditions, elseStepId }, updateDataToSave] = useNodeDataToSave();
  const conditions = nodeConditions?.map((condition) => ({ ...condition, id: condition?.id ?? v4() }));

  const onDragEnd = (dragUpdateObj) => {
    const { source, destination, draggableId } = dragUpdateObj;
    const condition = conditions?.find(({ id }) => id === draggableId);
    const updatedConditions = [...conditions];
    updatedConditions.splice(source.index, 1);
    updatedConditions.splice(destination.index, 0, condition);
    updateDataToSave({ conditions: updatedConditions });
  };

  const removeElseCondition = () => {
    updateDataToSave({ elseStepId: null });
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="new-conditions">
          {(droppableProvided) => (
            <ConditionsBox {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
              {conditions?.map((condition, index) => (
                <Draggable key={condition.id} draggableId={condition.id} index={index} content={condition}>
                  {(provided, snapshot) => (
                    <NewCondition
                      provided={provided}
                      snapshot={snapshot}
                      index={index}
                      condition={condition}
                      conditions={conditions}
                    />
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </ConditionsBox>
          )}
        </Droppable>
      </DragDropContext>
      {!!elseStepId && (
        <ElseConditionBox>
          <P16M textTransform="uppercase">{t('else')}</P16M>
          <IconBox onClick={removeElseCondition}>
            <Trash3 />
          </IconBox>
        </ElseConditionBox>
      )}
    </>
  );
};

export default NewConditions;
