import React from 'react';
import { useTranslation } from 'react-i18next';

import capitalize from '@mui/material/utils/capitalize';
import isEmpty from 'lodash/isEmpty';
import { P } from '../../atoms';

import { signUpPaymentDetailsNames } from './signUpPaymentDetailsSchema';

const OTHERS_VALUE = 'OTHER';

const CUSTOM_SELECT_PROPS = {
  placeholder: 'Select',
  errorProps: {
    margin: '-6px 0 14px 0',
  },
};

const generateOptions = (values) =>
  values.map((value) => ({
    value: value
      .split(' ')
      .join('_')
      .toUpperCase(),
    text: { text: value },
  }));

const PAYMENT_MODEL_OPTIONS = generateOptions(['E-commerce (one-off payments)', 'Subscriptions (recurring)', 'Other']);

const PAYMENT_GOALS_OPTIONS = generateOptions([
  'Reduce fraud and chargebacks',
  'Reduce cost of accepting payments',
  'Accept new payment methods ',
  'Expand to new markets',
  'Improve payments resiliency(e.g., vaulting and routing)',
  'Improve checkout conversion and authorization rates',
  'Payment operations & monitoring',
  'Payout to sellers or counter - parties ',
  'Not sure - advise me on what I should be doing',
  'Payment tokenization',
  'Enable network tokens',
  'PCI DSS',
]);

const PAYMENT_TRANSACTIONS_OPTIONS = [
  {
    value: 'ZERO_5M',
    text: { text: '$0 - $5M' },
  },
  {
    value: 'FIVE_MILION_10',
    text: { text: '$5 - $10M' },
  },
  {
    value: 'TEN_MILION_PLUS',
    text: { text: '>$10M' },
  },
];

const PAYMENT_PROCESSORS_OPTIONS = generateOptions([
  'Adyen',
  'Authorize.net',
  'Bambora',
  'Braintree',
  'Checkout.com',
  'Global Payments',
  'Moneris',
  'PayPal',
  'Stripe',
  'Worldline',
  'WorldPay',
  'Other',
]);

const PAYMENT_PROCESSOR_OPTIONS = [
  {
    value: 'YES',
    text: { text: 'Yes' },
  },
  {
    value: 'NO',
    text: { text: 'No' },
  },
];

const SELECT_OPTIONS = {
  [signUpPaymentDetailsNames.paymentModel]: PAYMENT_MODEL_OPTIONS,
  [signUpPaymentDetailsNames.paymentServiceProvider]: PAYMENT_GOALS_OPTIONS,
  [signUpPaymentDetailsNames.paymentTransactions]: PAYMENT_TRANSACTIONS_OPTIONS,
  [signUpPaymentDetailsNames.paymentGoals]: PAYMENT_GOALS_OPTIONS,
  [signUpPaymentDetailsNames.paymentProcessor]: PAYMENT_PROCESSOR_OPTIONS,
  [signUpPaymentDetailsNames.paymentProcessors]: PAYMENT_PROCESSORS_OPTIONS,
};

const OTHERS_INPUT_NAME_MAP = {
  [signUpPaymentDetailsNames.paymentModel]: signUpPaymentDetailsNames.paymentModelOther,
  [signUpPaymentDetailsNames.paymentServiceProvider]: signUpPaymentDetailsNames.paymentServiceProviderOther,
  [signUpPaymentDetailsNames.paymentGoals]: signUpPaymentDetailsNames.paymentGoalsOther,
  [signUpPaymentDetailsNames.paymentProcessors]: signUpPaymentDetailsNames.paymentProcessorsOther,
};

const SelectPlaceHolder = () => {
  const { t } = useTranslation();
  return <P color="#8e8f8f">{t('Select')}</P>;
};

const renderValue = (values, max) => {
  if (!Array.isArray(values)) return values;
  if (Array.isArray(values) && values.some((value) => typeof value !== 'string')) return values;

  if (isEmpty(values)) {
    return <SelectPlaceHolder />;
  }
  return (
    values
      ?.slice(0, max || values.length)
      ?.map((value) => capitalize(value?.toLowerCase()?.replace(/_/g, ' ') || ''))
      ?.join(', ') + (values?.length > (max || values.length) ? `...` : '')
  );
};

const RENDER_VALUE_MAP = {
  [signUpPaymentDetailsNames.paymentModel]: (value) => renderValue(value, 1),
  [signUpPaymentDetailsNames.paymentServiceProvider]: (value) => renderValue(value, 1),
  [signUpPaymentDetailsNames.paymentGoals]: (value) => renderValue(value, 2),
  [signUpPaymentDetailsNames.paymentProcessors]: (value) => renderValue(value, 2),
};

export { SELECT_OPTIONS, CUSTOM_SELECT_PROPS, OTHERS_VALUE, OTHERS_INPUT_NAME_MAP, RENDER_VALUE_MAP, renderValue };
