import React from 'react';
import { string, bool, shape } from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { Box, TextField } from '@mui/material';
import { Autocomplete } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { P } from '../../../atoms';
import { StyledLabel, StyledError } from '../../_common/styled';
import StyledTooltip from '../../../atoms/StyledTooltip';
import ChevronDown from '../../../../assets/icons/Select/ChevronDown';
import THEME from '../../../../constants/theme';
import { DATA } from './constant';
import { StyledAutocomplete } from './styled';

const processData = (data) => {
  const lines = data.trim().split('\n');

  const options = lines.map((line) => {
    const [value, ...textParts] = line.split('\t');
    const text = textParts.join(' ');

    return { value, label: `${value} ${text}` };
  });

  return options;
};

const MerchantCategoryAutocomplete = ({
  name,
  label,
  tooltip,
  isRequired,
  requiredLabel,
  customLabelProps,
  errorProps,
  defaultValue,
  disabled,
}) => {
  const [, { touched, error }] = useField(name);
  const { values, setFieldValue } = useFormikContext();
  const { t } = useTranslation();

  const options = processData(DATA);

  const defaultCode = defaultValue && options.find((code) => code.value === defaultValue.value);

  return (
    <Box>
      <StyledLabel>
        <Box display="flex" justifyContent="flex-start" alignItems="center" position="relative">
          <P
            fontSize="12px !important"
            fontWeight={600}
            {...(!tooltip && { width: '100%' })}
            textAlign="left"
            lineHeight="30px"
            {...(customLabelProps && customLabelProps)}
          >
            {t(label)}
            {(requiredLabel || isRequired) && <span className="required">*</span>}
          </P>

          {tooltip && <StyledTooltip title={tooltip} />}
        </Box>

        <StyledAutocomplete>
          <Autocomplete
            id={name}
            name={name}
            disablePortal
            options={options}
            {...(!defaultValue && { value: processData(DATA).find((option) => option.value === values[name]) || null })}
            onChange={(_, newValue) => {
              const valueToSet = newValue ? newValue.value : '';

              setFieldValue(name, valueToSet);
            }}
            defaultValue={
              defaultValue
                ? {
                    value: defaultValue.value,
                    label: defaultCode?.label,
                  }
                : {}
            }
            getOptionLabel={(option) => (option ? option.label : '')}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderInput={(params) => <TextField variant="standard" {...params} />}
            popupIcon={<ChevronDown />}
            disabled={disabled}
          />
        </StyledAutocomplete>
      </StyledLabel>

      {touched &&
        error?.map((message) => (
          <StyledError key={message} data-cy="error" color={THEME.secondaryColors.danger} {...errorProps}>
            {message}
          </StyledError>
        ))}
    </Box>
  );
};

MerchantCategoryAutocomplete.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  tooltip: string,
  isRequired: bool,
  requiredLabel: string,
  customLabelProps: shape({}),
  errorProps: shape({}),
  defaultValue: shape({}),
  disabled: bool,
};

MerchantCategoryAutocomplete.defaultProps = {
  customLabelProps: {},
  tooltip: '',
  requiredLabel: '',
  isRequired: false,
  errorProps: {},
  defaultValue: null,
  disabled: false,
};

export default MerchantCategoryAutocomplete;
