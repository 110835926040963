const STATUS_ACTION_MAP = {
  INVITED: 'deactivate',
  ACTIVE: 'deactivate',
  INACTIVE: 'reactivate',
};

const STATUS_ACTION_SUCCESS_TITLE_MAP = {
  deactivate: 'User successfully deactivated',
  reactivate: 'User successfully reactivated',
  resendInvitation: 'Invite has been resent',
};

const STATUS_ACTION_SUCCESS_MESSAGE_MAP = {
  deactivate: 'Deactivate user',
  reactivate: 'Reactivate user',
  resendInvitation: 'Resend invitation',
};

const MODAL_TEXT_TITLE_MAP = {
  deactivate: 'Are you sure you want to deactivate user?',
  reactivate: 'Are you sure you want to reactivate user?',
};

const MODAL_TEXT_SUBMIT = {
  deactivate: 'Deactivate',
  reactivate: 'Reactivate',
};

export {
  STATUS_ACTION_MAP,
  STATUS_ACTION_SUCCESS_TITLE_MAP,
  STATUS_ACTION_SUCCESS_MESSAGE_MAP,
  MODAL_TEXT_TITLE_MAP,
  MODAL_TEXT_SUBMIT,
};
