import React from 'react';
import { useTranslation } from 'react-i18next';
import { TempH4 } from '../../../../../../../components/atoms/Typography/H4';
import { P14 } from '../../../../../../../components/atoms';
import { StyledWrapper, ExternalLink } from './styled';
import ArrowUpRight from '../../../../../../../assets/icons/ArrowUpRight';

const Content = () => {
  const { t } = useTranslation();

  return (
    <StyledWrapper>
      <TempH4> {t('Dispute Submission')}</TempH4>

      <P14>
        {t(
          'You may counter the dispute by submitting evidence to support this was a legitimate purchase by the customer.'
        )}
      </P14>

      <P14>
        {t(
          ' The following evidence fields are most relevant to this dispute claim. Provide as much relevant evidence as possible to increase your likelihood of wining the dispute.'
        )}
      </P14>

      <P14>
        {t('You have 1 chance to submit your evidence to the processor but you can save a draft a review later on.')}
      </P14>

      <ExternalLink href="/" target="_blank" noUnderline fontSize="14px" color="#545A61">
        {t('Learn how to effectively handle disputes')}
        <ArrowUpRight />
      </ExternalLink>
    </StyledWrapper>
  );
};

export default Content;
