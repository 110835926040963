import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import PureLayout from '../../components/layouts/PureLayout';
import PureLayoutBox from '../../components/layouts/PureLayoutBox';
import SignUpPaymentDetailsForm from '../../components/forms/SignUpPaymentDetailsForm';
import { useSignUpBeta } from './useSignUpBeta';

const SignUpPaymentDetails = () => {
  const { t } = useTranslation();
  const { signUpBeta, loading } = useSignUpBeta();

  const handleSubmit = async (values, actions) => {
    try {
      await signUpBeta(values);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <PureLayout theme="dark">
      <PureLayoutBox theme="dark">
        <Box mb="40px">
          <h1>{t('Payment Needs')}</h1>
        </Box>
        <SignUpPaymentDetailsForm loading={loading} handleSubmit={handleSubmit} />
      </PureLayoutBox>
    </PureLayout>
  );
};

export default SignUpPaymentDetails;
