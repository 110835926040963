import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/lab/Skeleton';

const COMMON_PROPS = {
  borderRadius: '4px',
  overflow: 'hidden',
};

const EmptyState = () => {
  return (
    <Box {...COMMON_PROPS} width="60px">
      <Skeleton variant="rectangular" height={14} width="100%" animation="none" />
    </Box>
  );
};

export default EmptyState;
