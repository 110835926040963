import React, { useEffect, useState } from 'react';
import { shape, string } from 'prop-types';
import queryString from 'query-string';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InvitationForm from '../../components/forms/InvitationForm';
import OnboardingLayout from '../../components/layouts/OnboardingLayout';
import OnboardingLayoutBox from '../../components/layouts/OnboardingLayoutBox';
import { useRegistrationStorage } from '../../hooks/registration';
import { ONBOARDING_STATUS } from '../../constants/registration';
import { P14 } from '../../components/atoms';
import THEME from '../../constants/theme';
import { UI_ROUTES } from '../../constants/routes';

const Invitation = ({
  history: {
    location: { search },
  },
}) => {
  const [invitationParams, setInvitationParam] = useState({});
  const [registrationSession, setRegistrationSession] = useRegistrationStorage();
  const { t } = useTranslation();

  useEffect(() => {
    const params = queryString.parse(search);

    let inviteToken;
    let inviteEmail;

    if (params?.token && params?.email) {
      inviteEmail = params.email;
      inviteToken = params.token;

      setRegistrationSession({
        [ONBOARDING_STATUS.completeInvitation]: {
          token: params.token,
          email: params.email,
        },
        onboardingStatus: ONBOARDING_STATUS.completeInvitation,
      });
    } else {
      inviteEmail = registrationSession?.[ONBOARDING_STATUS.completeInvitation]?.email;
      inviteToken = registrationSession?.[ONBOARDING_STATUS.completeInvitation]?.token;
    }

    setInvitationParam({
      inviteToken,
      inviteEmail,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <OnboardingLayout
      navigationRight={
        <P14 color={THEME.greyColors.lightGrey}>
          {t('Already have an account?')}{' '}
          <Link to={UI_ROUTES.signIn} className="text-primary">
            {t('Sign in')}
          </Link>
        </P14>
      }
    >
      <OnboardingLayoutBox>
        <Box maxWidth="336px" margin="0 auto">
          <InvitationForm email={invitationParams.inviteEmail} token={invitationParams.inviteToken} />
        </Box>
      </OnboardingLayoutBox>
    </OnboardingLayout>
  );
};

Invitation.propTypes = {
  history: shape({
    location: shape({ search: string }).isRequired,
  }).isRequired,
};

export default Invitation;
