import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { HiddenNodeHandle, StyledGrabbableBox } from '../styled';
import { useFlowEditorContext } from '../../../context';
import THEME from '../../../../../constants/theme';
import StyledTooltip from '../../../../styled/StyledTooltip';
import RetryButton from './RetryButton';

const Retry = ({ stepId }) => {
  const { t } = useTranslation();
  const { retryInstanceLoading, isOpenRetryDialog } = useFlowEditorContext();

  return (
    <StyledGrabbableBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      width="44px"
      height="44px"
      borderRadius="50%"
      bgcolor={THEME.primaryColors.white}
      boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.04)"
    >
      {retryInstanceLoading || isOpenRetryDialog ? (
        <RetryButton stepId={stepId} />
      ) : (
        <StyledTooltip title={t('Retry from here')} placement="top">
          <RetryButton stepId={stepId} />
        </StyledTooltip>
      )}
      <HiddenNodeHandle type="source" position="right" />
    </StyledGrabbableBox>
  );
};

Retry.propTypes = {
  stepId: string,
};

Retry.defaultProps = {
  stepId: '',
};

export { Retry };
