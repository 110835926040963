import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { bool } from 'prop-types';
import { useHistory } from 'react-router-dom';

import { BlockWrap, Button } from '../../atoms';
import { formFields, formNames, initialValues } from './schema';
import { FieldsWrapper } from '../formStyles';
import Input from '../_common/Input';
import { SELECT_OPTIONS, CUSTOM_SELECT_PROPS } from './consts';
import SubmitButton from '../_common/SubmitButton';
import { isDefined } from '../../../utils/helpers';
import { useRegistrationStorage } from '../../../hooks/registration';
import { ONBOARDING_STATUS } from '../../../constants/registration';
import { UI_ROUTES } from '../../../constants/routes';
import validator from './validator';
import SignUpFormFooter from '../_common/SignUpFormFooter';
import CustomRadios from '../_common/CustomRadios';

const COMPONENTS_MAP = {
  [formNames.paymentProcessor]: CustomRadios,
};

const COMPONENTS_PROPS_MAP = {
  [formNames.paymentProcessor]: '',
};

const SignUpPaymentProcessorForm = ({ loading }) => {
  const { t } = useTranslation();
  const [registrationSession, setRegistrationSession] = useRegistrationStorage();
  const { push } = useHistory();

  const handleSubmit = useCallback(
    (values) => {
      setRegistrationSession({
        ...registrationSession,
        [ONBOARDING_STATUS.companyDetails]: values,
        onboardingStatus:
          values.paymentProcessor === 'YES' ? ONBOARDING_STATUS.paymentProcessors : ONBOARDING_STATUS.paymentMarkets,
      });
      push(values.paymentProcessor === 'YES' ? UI_ROUTES.signUpPaymentProcessors : UI_ROUTES.signUpMarkets);
    },
    [setRegistrationSession, registrationSession, push]
  );

  const handleBack = () => {
    setRegistrationSession({
      ...registrationSession,
      onboardingStatus: ONBOARDING_STATUS.acceptingPayments,
    });
    push(UI_ROUTES.signUpAcceptingPayments);
  };

  const parsedInitialValues = {
    ...initialValues,
    ...(registrationSession?.[ONBOARDING_STATUS.companyDetails] ?? {}),
  };

  return (
    <BlockWrap margin="37px 0 0">
      <FieldsWrapper>
        <Formik initialValues={parsedInitialValues} validate={validator} onSubmit={handleSubmit} validateOnBlur>
          <Form>
            {formFields.map(({ field, type, label, ...rest }, index) => {
              const Component = COMPONENTS_MAP[field] || Input;
              const addCustomMargin = field === formNames.companySize;

              return (
                <Component
                  key={field}
                  name={field}
                  {...(isDefined(SELECT_OPTIONS[field]) && {
                    options: SELECT_OPTIONS[field],
                    ...CUSTOM_SELECT_PROPS,
                  })}
                  type={type}
                  label={label}
                  index={index}
                  {...COMPONENTS_PROPS_MAP[field]}
                  {...rest}
                  {...(addCustomMargin && {
                    errorProps: {
                      margin: '6px 0 14px 0',
                    },
                  })}
                />
              );
            })}

            <SignUpFormFooter>
              <SubmitButton
                type="submit"
                likeDisabled={loading}
                showIcon={false}
                className="gradient"
                margin="28px 0 0 0"
                data-cy="submit"
                disabled={loading}
                isLoading={loading}
              >
                {t('common.continueText')}
              </SubmitButton>
              <Button className="grey" size="small" onClick={() => handleBack()}>
                {t('Back')}
              </Button>
            </SignUpFormFooter>
          </Form>
        </Formik>
      </FieldsWrapper>
    </BlockWrap>
  );
};

SignUpPaymentProcessorForm.propTypes = { loading: bool };

SignUpPaymentProcessorForm.defaultProps = { loading: false };

export default SignUpPaymentProcessorForm;
