import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';

const StyledDrawer = styled(Drawer)`
  & .MuiDrawer-paper {
    width: 520px;
    background: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
    top: 16px;
    right: 16px;
    height: calc(100% - 32px);
    border-radius: 8px;
    overflow: hidden;
  }
`;

const MainContainer = styled.div`
  padding: 32px;
  height: calc(100vh - 176px);
  overflow: auto;
`;

const Main = styled.div`
  padding: 0 32px 32px 32px;
`;

export { StyledDrawer, MainContainer, Main };
