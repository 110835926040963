const formNames = {
  email: 'email',
};

const formFields = [{ field: formNames.email, label: 'Email' }];

const initialValues = {
  [formNames.email]: '',
};

export { initialValues, formFields, formNames };
