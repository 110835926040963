import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { bool, func } from 'prop-types';
import { StyledDialog, StyledPaper } from '../../../../Dialog/styled';
import { H5New } from '../../../../atoms/Typography/H5New';
import { ButtonRounded, P14 } from '../../../../atoms';
import { stopPropagation } from './helpers';
import { useNodeContext } from '../context';

const ResolveDialog = ({ isOpen, closeModal }) => {
  const { t } = useTranslation();
  const { commentsResolving: isDataSaving, resolveThread, toggleMessagesOpened, stepId } = useNodeContext();

  const onSave = async () => {
    await resolveThread(stepId);
    closeModal();
    toggleMessagesOpened();
  };

  return (
    <StyledDialog
      open={isOpen}
      scroll="paper"
      maxWidth="xs"
      PaperComponent={StyledPaper}
      aria-labelledby="AddNicknameDialog"
      width="337px"
    >
      <Box p="24px">
        <H5New margin="0 0 16px 0">Resolve this comment ?</H5New>
        <P14 margin="0 0 24px 0">
          Are you sure you want to resolve this comment? This will remove all of it’s replies as well.
        </P14>
        <Box display="flex">
          <ButtonRounded
            type="button"
            color="secondary"
            variant="contained"
            flex={1}
            onMouseDown={stopPropagation}
            onClick={closeModal}
          >
            {t('Cancel')}
          </ButtonRounded>
          <ButtonRounded
            type="button"
            color="primary"
            variant="contained"
            onMouseDown={stopPropagation}
            onClick={onSave}
            flex={1}
            ml="8px"
            disabled={isDataSaving}
          >
            {isDataSaving ? <CircularProgress size={24} color="inherit" /> : t('Resolve')}
          </ButtonRounded>
        </Box>
      </Box>
    </StyledDialog>
  );
};

ResolveDialog.propTypes = {
  isOpen: bool.isRequired,
  closeModal: func.isRequired,
};

export default ResolveDialog;
