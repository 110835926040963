import React from 'react';
import { number, string } from 'prop-types';
import THEME from '../../../constants/theme';

const Globe = ({ size, color }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 12C1 11.4477 1.44772 11 2 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13H2C1.44772 13 1 12.5523 1 12Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00023 11.9986C9.06877 15.0735 10.1263 18.0338 12 20.4478C13.8737 18.0338 14.9312 15.0735 14.9998 11.9986C14.9312 8.9238 13.8737 5.96349 12 3.54944C10.1263 5.96349 9.06877 8.9238 9.00023 11.9986ZM12 1.99864L11.2617 1.32422C8.59689 4.24155 7.08251 8.0275 7.00022 11.9778C6.99993 11.9917 6.99993 12.0056 7.00022 12.0195C7.08251 15.9698 8.59689 19.7557 11.2617 22.6731C11.4511 22.8805 11.7191 22.9986 12 22.9986C12.2809 22.9986 12.5489 22.8805 12.7383 22.6731C15.4031 19.7557 16.9175 15.9698 16.9998 12.0195C17.0001 12.0056 17.0001 11.9917 16.9998 11.9778C16.9175 8.0275 15.4031 4.24155 12.7383 1.32422L12 1.99864Z"
      fill={color}
    />
  </svg>
);

Globe.propTypes = {
  size: number,
  color: string,
};

Globe.defaultProps = {
  size: 24,
  color: THEME.greyColors.grey17,
};

export default Globe;
