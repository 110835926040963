import React from 'react';
import FlowIdCopyToClipboard from './fields/FlowIdCopyToClipboard';
import JsonInputField from './fields/JsonInputField';

const PaymentProcessingTrigger = () => (
  <>
    <FlowIdCopyToClipboard />
    <JsonInputField title="Metadata JSON Structure (Optional)" />
  </>
);

export default PaymentProcessingTrigger;
