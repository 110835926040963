import React from 'react';
import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import { GET_AB_TEST_STATS } from '../../../../../../../utils/queries/flows/queries';
import { useFlowEditorContext } from '../../../../../context';
import { useNodeDataToSave } from '../hooks/useNodeDataToSave';
import {
  StyledBox,
  StyledInfoBox,
  StyledResultContainer,
  StyledResultTitleBox,
  StyledResultValueBox,
  StyledTestStatWrapper,
} from './styled';
import { H2B } from '../../../../../../atoms/Typography/stories/H2B';
import Info from '../../../../../../../assets/icons/Info';
import { P12B } from '../../../../../../atoms/Typography/P12B';
import LoadingState from '../../../LoadingState';
import { P14, P14M } from '../../../../../../atoms';
import { getResultColor } from './getResultColor';
import { P12M } from '../../../../../../atoms/Typography/P12M';
import THEME from '../../../../../../../constants/theme';
import StyledTooltip from '../../../../../../styled/StyledTooltip';

const ABTestStats = () => {
  const { flowId } = useFlowEditorContext();
  const [{ id: stepId }] = useNodeDataToSave();
  const { data, loading } = useQuery(GET_AB_TEST_STATS, {
    variables: {
      flowId,
      stepId,
    },
    skip: !flowId || !stepId,
    fetchPolicy: 'no-cache',
  });
  const abTestStats = data?.getABTestStats;

  return (
    <LoadingState height="172px" loading={loading}>
      {abTestStats?.map(({ key, totalValue, subLabel, helpText, aResult, bResult }) => (
        <StyledTestStatWrapper key={key}>
          <Box mb="18px">
            <StyledBox>
              <H2B>{totalValue}</H2B>
              <StyledInfoBox>
                <StyledTooltip title={helpText} placement="right" maxWidth={240}>
                  <Info />
                </StyledTooltip>
              </StyledInfoBox>
            </StyledBox>
            {subLabel && (
              <P14 color={THEME.greyColors.grey600} margin="5px 0 0 0">
                {subLabel}
              </P14>
            )}
          </Box>
          <StyledBox mb="10px">
            <StyledResultContainer $bgColor={getResultColor('container', aResult?.rank)}>
              <StyledResultTitleBox $bgColor={getResultColor('box', aResult?.rank)}>
                <P12B color={getResultColor('title', aResult?.rank)}>A</P12B>
              </StyledResultTitleBox>
              <StyledResultValueBox>
                <P14M margin="0 0 0 12px">{aResult?.primaryValue}</P14M>
                <P12M color={THEME.greyColors.grey600}>{aResult?.secondaryValue}</P12M>
              </StyledResultValueBox>
            </StyledResultContainer>
          </StyledBox>
          <StyledBox mb="10px">
            <StyledResultContainer $bgColor={getResultColor('container', bResult?.rank)}>
              <StyledResultTitleBox $bgColor={getResultColor('box', bResult?.rank)}>
                <P12B color={getResultColor('title', bResult?.rank)}>B</P12B>
              </StyledResultTitleBox>
              <StyledResultValueBox>
                <P14M margin="0 0 0 12px">{bResult?.primaryValue}</P14M>
                <P12M color={THEME.greyColors.grey600}>{bResult?.secondaryValue}</P12M>
              </StyledResultValueBox>
            </StyledResultContainer>
          </StyledBox>
        </StyledTestStatWrapper>
      ))}
    </LoadingState>
  );
};

export default ABTestStats;
