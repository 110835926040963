import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/lab/Skeleton';

const COMMON_PROPS = {
  borderRadius: '8px',
  overflow: 'hidden',
};

const LoadingState = () => {
  return (
    <Box padding="0 32px 32px 32px">
      <Box {...COMMON_PROPS} mb="6px" width="64px">
        <Skeleton variant="rectangular" height={16} width="100%" animation="wave" />
      </Box>

      <Box {...COMMON_PROPS} mb="24px">
        <Skeleton variant="rectangular" height={44} width="100%" animation="wave" />
      </Box>

      <Box {...COMMON_PROPS} mb="6px" width="100px">
        <Skeleton variant="rectangular" height={16} width="100%" animation="wave" />
      </Box>

      <Box {...COMMON_PROPS} mb="24px">
        <Skeleton variant="rectangular" height={44} width="100%" animation="wave" />
      </Box>

      <Box {...COMMON_PROPS} mb="6px" width="72px">
        <Skeleton variant="rectangular" height={16} width="100%" animation="wave" />
      </Box>

      <Box {...COMMON_PROPS} mb="24px">
        <Skeleton variant="rectangular" height={80} width="100%" animation="wave" />
      </Box>

      <Box {...COMMON_PROPS} mb="6px" width="64px">
        <Skeleton variant="rectangular" height={16} width="100%" animation="wave" />
      </Box>

      <Box {...COMMON_PROPS} mb="24px">
        <Skeleton variant="rectangular" height={44} width="100%" animation="wave" />
      </Box>
    </Box>
  );
};

export default LoadingState;
