import React from 'react';
// import styled from 'styled-components';
import { Box } from '@mui/material';
import { arrayOf, func, shape, string } from 'prop-types';
// import { useTranslation } from 'react-i18next';
import VersionItem from './VersionItem';
import THEME from '../../../constants/theme';
// import { P14M } from '../../atoms';

// const MoreButton = styled(Box)`
//   cursor: pointer;
// `;

const VersionsModal = ({ items, handlePublish }) => {
  // const { t } = useTranslation();
  return (
    <Box
      position="absolute"
      width="300px"
      maxHeight="426px"
      overflow="auto"
      bgcolor={THEME.primaryColors.white}
      borderRadius="8px"
      top="64px"
      left="160px"
      boxShadow="0px 0px 16px rgba(0, 0, 0, 0.08)"
    >
      <Box display="flex" flexDirection="column" gap="4px" p="8px">
        {items.map((item, index) => (
          <VersionItem arrayLength={items?.length} item={item} index={index} handlePublish={handlePublish} />
        ))}
      </Box>

      {/*Will be used in later version!*/}

      {/*{hasMore && (*/}
      {/*  <MoreButton*/}
      {/*    borderTop={`1px solid ${THEME.greyColors.grey22}`}*/}
      {/*    height="56px"*/}
      {/*    display="flex"*/}
      {/*    alignItems="center"*/}
      {/*    justifyContent="center"*/}
      {/*  >*/}
      {/*    <P14M>{t('Load more')}</P14M>*/}
      {/*  </MoreButton>*/}
      {/*)}*/}
    </Box>
  );
};

VersionsModal.propTypes = {
  items: arrayOf(
    shape({
      versionName: string.isRequired,
      systemUpdated: string.isRequired,
    })
  ).isRequired,
  handlePublish: func.isRequired,
};

export default VersionsModal;
