import React from 'react';

const Rates = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0H1.5V22.5H24V24H0V0ZM15 5.25C15 5.05109 15.079 4.86032 15.2197 4.71967C15.3603 4.57902 15.5511 4.5 15.75 4.5H21.75C21.9489 4.5 22.1397 4.57902 22.2803 4.71967C22.421 4.86032 22.5 5.05109 22.5 5.25V11.25C22.5 11.4489 22.421 11.6397 22.2803 11.7803C22.1397 11.921 21.9489 12 21.75 12C21.5511 12 21.3603 11.921 21.2197 11.7803C21.079 11.6397 21 11.4489 21 11.25V7.35L15.5805 13.9755C15.5142 14.0565 15.4316 14.1227 15.3381 14.1698C15.2447 14.217 15.1424 14.244 15.0378 14.2493C14.9332 14.2545 14.8288 14.2378 14.731 14.2002C14.6333 14.1626 14.5446 14.105 14.4705 14.031L10.59 10.1505L5.106 17.691C4.98603 17.8435 4.81161 17.9437 4.61938 17.9703C4.42714 17.9969 4.23207 17.948 4.07516 17.8338C3.91824 17.7196 3.8117 17.549 3.77792 17.3579C3.74413 17.1668 3.78574 16.9701 3.894 16.809L9.894 8.559C9.95769 8.47127 10.0396 8.39839 10.1342 8.34538C10.2288 8.29237 10.3337 8.26049 10.4418 8.25194C10.5498 8.24338 10.6585 8.25836 10.7602 8.29583C10.8619 8.3333 10.9543 8.39238 11.031 8.469L14.9445 12.384L20.1675 6H15.75C15.5511 6 15.3603 5.92098 15.2197 5.78033C15.079 5.63968 15 5.44891 15 5.25Z"
      fill="black"
    />
  </svg>
);

export default Rates;
