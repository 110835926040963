import React from 'react';
import { Box } from '@mui/material';
import { P14 } from '../../../atoms';
import THEME from '../../../../constants/theme';

const StepOne = () => (
  <Box>
    <P14 margin="0 0 12px 0" color={THEME.greyColors.grey23}>
      Define your ideal Payment Processing Automation using condition and action nodes from the node library. In our
      Recipes tab, you can find pre-packaged workflows that help you solve specific payment problems - simply add and
      configure.
    </P14>
    <P14 margin="0 0 12px 0" color={THEME.greyColors.grey23}>
      When you’re happy with your payment processing automation, click Start to publish your Automation. Once your
      Automation is live, follow the rest of our guide to process a payment.
    </P14>
  </Box>
);

export default StepOne;
