import React from 'react';
import { shape, bool, func } from 'prop-types';
import { Box } from '@mui/material';
import { useFormikContext } from 'formik';
import { InputField, L12 } from '../../../../../atoms';
import useActionHandlers from '../hooks/useActionHandlers';
import THEME from '../../../../../../constants/theme';

const LongText = ({ item, showErrors, setShowErrors }) => {
  const { values, errors, handleBlur } = useFormikContext();
  const { onChangeHandler } = useActionHandlers(setShowErrors);

  return (
    <Box key={item.block_id}>
      <InputField
        variant="outlined"
        type="text"
        multiline
        rows="8"
        id={item.block_id}
        name={item.block_id}
        label={item.label.text}
        value={values[item.block_id] || ''}
        onChange={onChangeHandler}
        onBlur={handleBlur}
        minheight="187px"
      />

      {showErrors && errors[item.block_id] && (
        <L12 textAlign="left" margin="0 0 24px 0" color={THEME.secondaryColors.danger}>
          {errors[item.block_id]}
        </L12>
      )}
    </Box>
  );
};

LongText.propTypes = {
  item: shape({}).isRequired,
  showErrors: bool,
  setShowErrors: func.isRequired,
};

LongText.defaultProps = {
  showErrors: false,
};

export default LongText;
