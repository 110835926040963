import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import UploadBox from './UploadBox';

const GRAPHICS_TITLE_MAP = ['logo', 'favicon'];

const Graphics = ({ handleGraphics, brandImages }) => {
  return (
    <Box display="flex">
      {GRAPHICS_TITLE_MAP.map((el) => (
        <UploadBox title={el} handleGraphics={handleGraphics} graphics={brandImages[el]} />
      ))}
    </Box>
  );
};

Graphics.propTypes = {
  handleGraphics: PropTypes.func.isRequired,
  brandImages: PropTypes.shape({
    logo: PropTypes.string,
    favicon: PropTypes.string,
  }).isRequired,
};

export default Graphics;
