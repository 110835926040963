import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText } from '@mui/material';
import { usePeopleField } from './hooks/usePeopleField';
import { selectTypes } from '../inputs/types';
import MultiSelectSection from '../sections/MultiSelectSection';
import THEME from '../../../../../../constants/theme';
import { useStyles } from './styled';

const PeopleMultiSelectField = ({ title, fieldName }) => {
  const { selectedOptionIds, options, onChange, validationMessage, loading } = usePeopleField(fieldName);
  const classes = useStyles();

  const hasError = Boolean(validationMessage);
  const border = `1px solid ${hasError ? THEME.secondaryColors.nodeError : THEME.greyColors.grey200}`;

  return (
    <FormControl variant="standard" fullWidth error={hasError}>
      <MultiSelectSection
        options={options}
        loading={loading}
        selectedOptionIds={selectedOptionIds}
        title={title}
        validationMessage={validationMessage}
        handleChange={onChange}
        selectType={selectTypes.people}
        selectAll
        isSearchBar
        showEmptyState
        addBorder
        border={border}
      />
      {hasError && <FormHelperText classes={{ root: classes.root }}>{validationMessage}</FormHelperText>}
    </FormControl>
  );
};
PeopleMultiSelectField.defaultProps = {
  title: '',
};

PeopleMultiSelectField.propTypes = {
  title: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
};

export default PeopleMultiSelectField;
