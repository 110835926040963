import React from 'react';

const SwitchIcon = (props) => (
  <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.38749 11.6447L0.802373 8.59142C0.49729 8.23218 0.745237 7.66797 1.20825 7.66797H6.37849C6.48211 7.66788 6.58357 7.69865 6.6707 7.75661C6.75783 7.81456 6.82695 7.89724 6.86978 7.99474C6.9126 8.09224 6.92733 8.20043 6.91218 8.30635C6.89703 8.41228 6.85266 8.51144 6.78437 8.59198L4.19925 11.6442C4.14866 11.704 4.08626 11.7519 4.01626 11.7848C3.94625 11.8176 3.87026 11.8346 3.79337 11.8346C3.71649 11.8346 3.64049 11.8176 3.57048 11.7848C3.50048 11.7519 3.43809 11.704 3.38749 11.6442V11.6447Z"
      fill="#333333"
    />
    <path
      d="M3.38749 0.359186L0.802373 3.41248C0.49729 3.77173 0.745237 4.33594 1.20825 4.33594H6.37849C6.48211 4.33603 6.58357 4.30526 6.6707 4.2473C6.75783 4.18935 6.82695 4.10667 6.86978 4.00917C6.9126 3.91167 6.92733 3.80348 6.91218 3.69755C6.89703 3.59163 6.85266 3.49246 6.78437 3.41193L4.19925 0.359743C4.14866 0.299919 4.08626 0.251972 4.01626 0.21912C3.94625 0.186268 3.87026 0.169271 3.79337 0.169271C3.71649 0.169271 3.64049 0.186268 3.57048 0.21912C3.50048 0.251972 3.43809 0.299919 3.38749 0.359743V0.359186Z"
      fill="#333333"
    />
  </svg>
);

export default SwitchIcon;
