import React from 'react';

const Redundancy = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22Z"
        fill="#FF6C4C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 18.0416C14.8452 18.0416 15.125 18.3214 15.125 18.6666V28.875H28.875V18.6666C28.875 18.3214 29.1548 18.0416 29.5 18.0416C29.8452 18.0416 30.125 18.3214 30.125 18.6666V29.5C30.125 29.8451 29.8452 30.125 29.5 30.125H14.5C14.1548 30.125 13.875 29.8451 13.875 29.5V18.6666C13.875 18.3214 14.1548 18.0416 14.5 18.0416Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2083 14.5C12.2083 14.1548 12.4881 13.875 12.8333 13.875H31.1666C31.5118 13.875 31.7916 14.1548 31.7916 14.5V18.6667C31.7916 19.0118 31.5118 19.2917 31.1666 19.2917H12.8333C12.4881 19.2917 12.2083 19.0118 12.2083 18.6667V14.5ZM13.4583 15.125V18.0417H30.5416V15.125H13.4583Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7083 22C19.7083 21.6548 19.9881 21.375 20.3333 21.375H23.6666C24.0118 21.375 24.2916 21.6548 24.2916 22C24.2916 22.3452 24.0118 22.625 23.6666 22.625H20.3333C19.9881 22.625 19.7083 22.3452 19.7083 22Z"
        fill="white"
      />
    </svg>
  );
};

export default Redundancy;
