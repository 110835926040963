import React from 'react';
import { string, number } from 'prop-types';
import Box from '@mui/material/Box';
import CircleImage from './CircleImage';
import { parseInitials } from '../../utils/parseInitials';
import { StyledLink } from '../../pages/Payments/Details/sections/Summary/styled';
import { OverFlowHiddenContainer } from './Cells/styled';

const Processor = ({ name, logo, borderRadius, size, vaultedCustomerId }) => (
  <Box display="flex" alignItems="center">
    <Box display="flex" mr="8px">
      <CircleImage text={parseInitials(name).toUpperCase()} logo={logo} size={size} borderRadius={borderRadius} />
    </Box>

    {name && (
      <Box
        component={vaultedCustomerId ? StyledLink : OverFlowHiddenContainer}
        {...(vaultedCustomerId ? { href: `/data-tables/customers/details/${vaultedCustomerId}` } : null)}
      >
        {name}
      </Box>
    )}
  </Box>
);

Processor.propTypes = {
  name: string,
  logo: string,
  borderRadius: string,
  size: number,
  vaultedCustomerId: string,
};

Processor.defaultProps = {
  name: '',
  logo: '',
  borderRadius: '50%',
  size: 24,
  vaultedCustomerId: undefined,
};

export default Processor;
