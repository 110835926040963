import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { MoreVert } from '@mui/icons-material';

import { IconButton } from './styled';
import Popover from './Popover';

const OptionsMenu = ({ options, lastItemDanger, width, bgcolor, color, vertical, hoverColor }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = useCallback(({ currentTarget }) => {
    return setAnchorEl(currentTarget);
  }, []);
  const handleClose = useCallback(() => setAnchorEl(null), []);

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        type="button"
        onClick={handleOpen}
        bgcolor={bgcolor}
        $color={color}
        $hoverColor={hoverColor}
        size="large"
      >
        {vertical ? <MoreVert fontSize="inherit" /> : <MoreHorizIcon />}
      </IconButton>
      {anchorEl && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          width={width}
          lastItemDanger={lastItemDanger}
          options={options}
        />
      )}
    </>
  );
};

OptionsMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      label: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  lastItemDanger: PropTypes.bool,
  width: PropTypes.string,
  bgcolor: PropTypes.string,
  color: PropTypes.string,
  vertical: PropTypes.bool,
  hoverColor: PropTypes.string,
};

OptionsMenu.defaultProps = {
  lastItemDanger: false,
  vertical: false,
  width: null,
  bgcolor: null,
  color: null,
  hoverColor: null,
};

export default OptionsMenu;
