import styled from 'styled-components';
import THEME from '../../../../constants/theme';

const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 40px;
  padding: 13px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${THEME.primaryColors.white};
  border-bottom: 1px solid ${THEME.greyColors.grey200};
  z-index: 1000;
`;

const StyledDialogTitleInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 56px;
`;

export { StyledWrapper, StyledDialogTitleInner };
