import React from 'react';
import { number, string } from 'prop-types';
import THEME from '../../../constants/theme';

const TemplatesRetry = ({ size, color }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_819_1311)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3334 2C15.7016 2 16.0001 2.29848 16.0001 2.66667V6.66667C16.0001 7.03486 15.7016 7.33333 15.3334 7.33333H11.3334C10.9652 7.33333 10.6667 7.03486 10.6667 6.66667C10.6667 6.29848 10.9652 6 11.3334 6H14.6667V2.66667C14.6667 2.29848 14.9652 2 15.3334 2Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.7516 2.72116C7.6067 2.55876 6.44002 2.77287 5.42739 3.33123C4.41476 3.88958 3.61103 4.76193 3.13732 5.81682C2.66361 6.8717 2.54559 8.05197 2.80103 9.17977C3.05647 10.3076 3.67154 11.3218 4.55356 12.0696C5.43557 12.8174 6.53674 13.2583 7.69113 13.3259C8.84553 13.3934 9.9906 13.0839 10.9538 12.444C11.917 11.8042 12.6461 10.8686 13.0313 9.77826C13.154 9.4311 13.5348 9.2491 13.882 9.37175C14.2292 9.4944 14.4112 9.87525 14.2885 10.2224C13.807 11.5853 12.8956 12.7548 11.6916 13.5546C10.4876 14.3545 9.05626 14.7413 7.61327 14.6569C6.17028 14.5725 4.79381 14.0214 3.69129 13.0866C2.58877 12.1518 1.81994 10.8841 1.50063 9.47431C1.18133 8.06456 1.32886 6.58922 1.921 5.27061C2.51314 3.95201 3.5178 2.86157 4.78359 2.16363C6.04938 1.46568 7.50772 1.19804 8.93885 1.40104C10.3666 1.60355 11.69 2.2635 12.7108 3.28176L15.7903 6.18166C16.0583 6.43408 16.071 6.856 15.8186 7.12404C15.5662 7.39209 15.1443 7.40476 14.8762 7.15235L11.7895 4.24568C11.7846 4.24106 11.7798 4.23636 11.775 4.23159C10.9576 3.41366 9.89651 2.88355 8.7516 2.72116Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_819_1311">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

TemplatesRetry.propTypes = {
  size: number,
  color: string,
};

TemplatesRetry.defaultProps = {
  size: 16,
  color: THEME.greyColors.grey17,
};

export default TemplatesRetry;
