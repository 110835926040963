import React from 'react';
import LoadingState from '../LoadingState';
import { useNodeDataToSave } from './fields/hooks/useNodeDataToSave';
import NewConditions from './fields/NewConditions';

const DataConditionStep = () => {
  const [{ unsaved }] = useNodeDataToSave();

  return (
    <LoadingState loading={unsaved}>
      <NewConditions />
    </LoadingState>
  );
};

export default DataConditionStep;
