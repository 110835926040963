import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useToggle } from 'react-use';
import { useLocalStorageState } from '../../../hooks/storage';
import { isValidUrl } from '../../../utils/isValidUrl';
import { UI_ROUTES } from '../../../constants/routes';
import { useUpdateConnectionData } from './useUpdateConnectionData';
import { useHandleConnectionForm } from './useHandleConnectionForm';
import { useConnectionWindowMessageListener } from './useConnectionWindowMessageListener';
import { useHandleOauthRedirect } from './useHandleOauthRedirect';

const STORAGE_CONNECTION_ID_KEY = 'wt-storage-connection-id-key';

const useHandleConnectionConnect = ({ connection, callback, avoidRedirect = false, closeModal }) => {
  const { connect, reconnect, saveCredentials } = useUpdateConnectionData(connection?.id);
  const [modalShown, toggleModalShown] = useToggle(false);
  const [childWindow, setChildWindow] = useState(undefined);
  const [connectData, setConnectData] = useState({});
  const [oAuthData, setOAuthData] = useState({});
  const [plaidConnectionData, setPlaidData] = useState({});
  const location = useLocation();
  const [paymentMethodDialog, setPaymentMethodDialog] = useState(false);
  const togglePaymentMethodDialog = useCallback(() => setPaymentMethodDialog((prevState) => !prevState), []);
  const [, setStorageConnectionId] = useLocalStorageState(STORAGE_CONNECTION_ID_KEY, '');

  const handleCallback = useCallback(
    (id) => {
      if (typeof callback === 'function') {
        callback(id);
        return true;
      }
      return true;
    },
    [callback]
  );

  const handleConnectFunction = useCallback(
    (method) => {
      method({
        variables: { id: connection.id },
      }).then((res) => {
        if (res && !res.errors) {
          const responseData = res.data?.startConnection || res.data?.reconnectConnection;
          const oauthUrl = responseData?.authorizationUrl;

          if (oauthUrl && isValidUrl(oauthUrl) && responseData.flowType !== 'PLAID') {
            setStorageConnectionId(responseData.id);
            // if it's paypal -- we need to open a new tab if current route is not connection details
            if (location.pathname.includes('paypal') && !location.pathname.includes(UI_ROUTES.connectionDetails)) {
              window.open(oauthUrl, '_blank', 'noreferrer, noopener')?.focus?.();
              return;
            }

            const childWindowConst = window.open(oauthUrl, 'new', 'resizable=yes,fullscreen=yes');

            setChildWindow(childWindowConst);
          } else if (responseData?.form) {
            setConnectData({
              ...responseData,
              currentStep: { form: responseData?.form || [] },
            });
            toggleModalShown();
          }
          if (responseData?.flowType === 'PLAID') {
            setPlaidData(responseData);
          }
        }
      });
    },
    [connection, toggleModalShown, location.pathname, setStorageConnectionId]
  );

  /**
   * Handes 0Auth Redirect
   */
  useHandleOauthRedirect({ connection, togglePaymentMethodDialog });

  /**
   * Window message listener
   */
  useConnectionWindowMessageListener({
    connection,
    childWindow,
    callback,
    avoidRedirect,
    closeModal,
    handleCallback,
    setOAuthData,
    togglePaymentMethodDialog,
  });

  /**
   * Handes connect
   */
  const handleConnect = useCallback(() => {
    handleConnectFunction(connect);
  }, [connect, handleConnectFunction]);

  const handleReconnect = useCallback(() => {
    handleConnectFunction(reconnect);
  }, [reconnect, handleConnectFunction]);

  /**
   * Handles the connection form  -> Form Step 1
   */
  const { renderConnectionForm } = useHandleConnectionForm({
    connection,
    callback,
    avoidRedirect,
    closeModal,
    connectData,
    setConnectData,
    handleCallback,
    setPlaidData,
    modalShown,
    toggleModalShown,
    setChildWindow,
    oAuthData,
    plaidConnectionData,
    paymentMethodDialog,
    togglePaymentMethodDialog,
  });

  return {
    plaidConnectionData,
    handleConnect,
    handleReconnect,
    saveCredentials,
    renderConnectionForm,
  };
};

export { useHandleConnectionConnect };
