import PropTypes from 'prop-types';
import React from 'react';

const ClipboardList = ({ background }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.66658 1.75V2.04167C4.66658 2.52492 5.05834 2.91667 5.54158 2.91667H8.45825C8.9415 2.91667 9.33325 2.52492 9.33325 2.04167V1.75H10.4999C11.1442 1.75 11.6666 2.27233 11.6666 2.91667V12.25C11.6666 12.8943 11.1442 13.4167 10.4999 13.4167H3.49992C2.85559 13.4167 2.33325 12.8943 2.33325 12.25V2.91667C2.33325 2.27233 2.85559 1.75 3.49992 1.75H4.66658Z"
      fill={background}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.41667 1.16634C6.41667 0.844175 6.67783 0.583008 7 0.583008C7.32217 0.583008 7.58333 0.844175 7.58333 1.16634H8.45833C8.61942 1.16634 8.75 1.29692 8.75 1.45801V2.04134C8.75 2.20242 8.61942 2.33301 8.45833 2.33301H5.54167C5.38058 2.33301 5.25 2.20242 5.25 2.04134V1.45801C5.25 1.29692 5.38058 1.16634 5.54167 1.16634H6.41667Z"
      fill={background}
    />
    <path
      d="M9.33325 5.25H6.41659C6.09442 5.25 5.83325 5.51117 5.83325 5.83333C5.83325 6.1555 6.09442 6.41667 6.41659 6.41667H9.33325C9.65542 6.41667 9.91658 6.1555 9.91658 5.83333C9.91658 5.51117 9.65542 5.25 9.33325 5.25Z"
      fill="#fff"
    />
    <path
      d="M9.33325 5.25H6.41659C6.09442 5.25 5.83325 5.51117 5.83325 5.83333C5.83325 6.1555 6.09442 6.41667 6.41659 6.41667H9.33325C9.65542 6.41667 9.91658 6.1555 9.91658 5.83333C9.91658 5.51117 9.65542 5.25 9.33325 5.25Z"
      fill="#fff"
    />
    <path
      d="M9.33325 5.25H6.41659C6.09442 5.25 5.83325 5.51117 5.83325 5.83333C5.83325 6.1555 6.09442 6.41667 6.41659 6.41667H9.33325C9.65542 6.41667 9.91658 6.1555 9.91658 5.83333C9.91658 5.51117 9.65542 5.25 9.33325 5.25Z"
      fill="#fff"
    />
    <path
      d="M5.24992 5.83333C5.24992 5.51117 4.98875 5.25 4.66659 5.25C4.34442 5.25 4.08325 5.51117 4.08325 5.83333C4.08325 6.1555 4.34442 6.41667 4.66659 6.41667C4.98875 6.41667 5.24992 6.1555 5.24992 5.83333Z"
      fill="#fff"
    />
    <path
      d="M5.24992 5.83333C5.24992 5.51117 4.98875 5.25 4.66659 5.25C4.34442 5.25 4.08325 5.51117 4.08325 5.83333C4.08325 6.1555 4.34442 6.41667 4.66659 6.41667C4.98875 6.41667 5.24992 6.1555 5.24992 5.83333Z"
      fill="#fff"
    />
    <path
      d="M5.24992 5.83333C5.24992 5.51117 4.98875 5.25 4.66659 5.25C4.34442 5.25 4.08325 5.51117 4.08325 5.83333C4.08325 6.1555 4.34442 6.41667 4.66659 6.41667C4.98875 6.41667 5.24992 6.1555 5.24992 5.83333Z"
      fill="#fff"
    />
    <path
      d="M5.24992 8.16634C5.24992 7.84417 4.98875 7.58301 4.66659 7.58301C4.34442 7.58301 4.08325 7.84417 4.08325 8.16634C4.08325 8.48851 4.34442 8.74967 4.66659 8.74967C4.98875 8.74967 5.24992 8.48851 5.24992 8.16634Z"
      fill="#fff"
    />
    <path
      d="M5.24992 8.16634C5.24992 7.84417 4.98875 7.58301 4.66659 7.58301C4.34442 7.58301 4.08325 7.84417 4.08325 8.16634C4.08325 8.48851 4.34442 8.74967 4.66659 8.74967C4.98875 8.74967 5.24992 8.48851 5.24992 8.16634Z"
      fill="#fff"
    />
    <path
      d="M5.24992 8.16634C5.24992 7.84417 4.98875 7.58301 4.66659 7.58301C4.34442 7.58301 4.08325 7.84417 4.08325 8.16634C4.08325 8.48851 4.34442 8.74967 4.66659 8.74967C4.98875 8.74967 5.24992 8.48851 5.24992 8.16634Z"
      fill="#fff"
    />
    <path
      d="M9.33325 7.58301H6.41659C6.09442 7.58301 5.83325 7.84417 5.83325 8.16634C5.83325 8.48851 6.09442 8.74967 6.41659 8.74967H9.33325C9.65542 8.74967 9.91658 8.48851 9.91658 8.16634C9.91658 7.84417 9.65542 7.58301 9.33325 7.58301Z"
      fill="#fff"
    />
    <path
      d="M9.33325 7.58301H6.41659C6.09442 7.58301 5.83325 7.84417 5.83325 8.16634C5.83325 8.48851 6.09442 8.74967 6.41659 8.74967H9.33325C9.65542 8.74967 9.91658 8.48851 9.91658 8.16634C9.91658 7.84417 9.65542 7.58301 9.33325 7.58301Z"
      fill="#fff"
    />
    <path
      d="M9.33325 7.58301H6.41659C6.09442 7.58301 5.83325 7.84417 5.83325 8.16634C5.83325 8.48851 6.09442 8.74967 6.41659 8.74967H9.33325C9.65542 8.74967 9.91658 8.48851 9.91658 8.16634C9.91658 7.84417 9.65542 7.58301 9.33325 7.58301Z"
      fill="#fff"
    />
    <path
      d="M5.24992 10.5003C5.24992 10.1782 4.98875 9.91699 4.66659 9.91699C4.34442 9.91699 4.08325 10.1782 4.08325 10.5003C4.08325 10.8225 4.34442 11.0837 4.66659 11.0837C4.98875 11.0837 5.24992 10.8225 5.24992 10.5003Z"
      fill="#fff"
    />
    <path
      d="M5.24992 10.5003C5.24992 10.1782 4.98875 9.91699 4.66659 9.91699C4.34442 9.91699 4.08325 10.1782 4.08325 10.5003C4.08325 10.8225 4.34442 11.0837 4.66659 11.0837C4.98875 11.0837 5.24992 10.8225 5.24992 10.5003Z"
      fill="#fff"
    />
    <path
      d="M5.24992 10.5003C5.24992 10.1782 4.98875 9.91699 4.66659 9.91699C4.34442 9.91699 4.08325 10.1782 4.08325 10.5003C4.08325 10.8225 4.34442 11.0837 4.66659 11.0837C4.98875 11.0837 5.24992 10.8225 5.24992 10.5003Z"
      fill="#fff"
    />
    <path
      d="M9.33325 9.91699H6.41659C6.09442 9.91699 5.83325 10.1782 5.83325 10.5003C5.83325 10.8225 6.09442 11.0837 6.41659 11.0837H9.33325C9.65542 11.0837 9.91658 10.8225 9.91658 10.5003C9.91658 10.1782 9.65542 9.91699 9.33325 9.91699Z"
      fill="#fff"
    />
    <path
      d="M9.33325 9.91699H6.41659C6.09442 9.91699 5.83325 10.1782 5.83325 10.5003C5.83325 10.8225 6.09442 11.0837 6.41659 11.0837H9.33325C9.65542 11.0837 9.91658 10.8225 9.91658 10.5003C9.91658 10.1782 9.65542 9.91699 9.33325 9.91699Z"
      fill="#fff"
    />
    <path
      d="M9.33325 9.91699H6.41659C6.09442 9.91699 5.83325 10.1782 5.83325 10.5003C5.83325 10.8225 6.09442 11.0837 6.41659 11.0837H9.33325C9.65542 11.0837 9.91658 10.8225 9.91658 10.5003C9.91658 10.1782 9.65542 9.91699 9.33325 9.91699Z"
      fill="#fff"
    />
  </svg>
);

ClipboardList.propTypes = {
  background: PropTypes.string,
};

ClipboardList.defaultProps = {
  background: '#fff',
};

export default ClipboardList;
