import styled from 'styled-components';
import Box from '@mui/material/Box';
import THEME from '../../../../constants/theme';

const Wrapper = styled(Box)`
  color: black;
  position: relative;
  padding: 32px 0;
  z-index: 1;
  box-sizing: border-box;
  max-width: 1040px;
  margin-bottom: 80px;

  & form {
    display: flex;
    flex-direction: column;
  }
`;

const GroupsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

const GroupContainer = styled(Box)`
  min-width: 320px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  border: 1px solid #e6e9ec;
  box-sizing: border-box;
  margin-bottom: 24px;
  padding: 16px 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SubmitButtonWrapper = styled(Box)`
  margin: 16px 0;

  @media (min-width: ${THEME.breakPoints.tabletLarge}px) {
    width: 300px;
    margin-left: auto;
  }
`;

export { Wrapper, GroupsWrapper, GroupContainer, SubmitButtonWrapper };
