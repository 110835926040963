import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { TempH4 } from '../../atoms/Typography/H4';
import { ButtonRounded, P14 } from '../../atoms';
import THEME from '../../../constants/theme';
import { usePropertyTextEditorContext } from '../context';
import { propertyObjectTypes } from './types';
import { StyledBox } from './styled';
import StyledTooltip from '../../styled/StyledTooltip';

const DescriptionColumn = () => {
  const { t } = useTranslation();
  const {
    setOpenPropertyModal,
    selectedPropertyObject,
    setSelectedPropertyObject,
    setSelectedPathArr,
    onPropertySelect,
  } = usePropertyTextEditorContext();
  const isItem = selectedPropertyObject?.type === propertyObjectTypes.ITEM;
  const maxValueLength = 16;

  const handleSelect = () => {
    if (selectedPropertyObject) {
      onPropertySelect(selectedPropertyObject);
    }
    setSelectedPropertyObject(null);
    setSelectedPathArr(null);
    setOpenPropertyModal(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="341px"
      height="calc(100% - 58px)"
      p="20px"
      bgcolor={THEME.primaryColors.white}
      borderLeft={`1px solid ${THEME.greyColors.grey16}`}
      marginLeft="auto"
      position="absolute"
      right="0"
    >
      <TempH4 margin="0 0 20px 0" textTransform="capitalize">
        {t(selectedPropertyObject?.label)}
      </TempH4>
      <P14 margin="0 0 24px 0">{t(selectedPropertyObject?.description)}</P14>
      {isItem && (
        <>
          <Box>
            <StyledBox justifyContent="space-between">
              <P14 color={THEME.greyColors.grey600}>{t('Type')}</P14>
              {selectedPropertyObject.dataType?.length > maxValueLength ? (
                <StyledTooltip
                  title={t(selectedPropertyObject.dataType)}
                  placement="top"
                  maxWidth={215}
                  padding="12px 16px"
                  width="auto"
                >
                  <P14 overflow="hidden" maxWidth="150px" textOverflow="ellipsis" noWrap>
                    {t(selectedPropertyObject.dataType)}
                  </P14>
                </StyledTooltip>
              ) : (
                <P14>{t(selectedPropertyObject.dataType)}</P14>
              )}
            </StyledBox>
            {selectedPropertyObject?.valueExample && (
              <>
                <Box height="1px" width="100%" bgcolor={THEME.greyColors.grey16} my="12px" />
                <StyledBox justifyContent="space-between">
                  <P14 color={THEME.greyColors.grey600}>{t('Value Example')}</P14>
                  {selectedPropertyObject?.valueExample?.length > maxValueLength ? (
                    <StyledTooltip
                      title={selectedPropertyObject?.valueExample}
                      placement="top"
                      maxWidth={215}
                      padding="12px 16px"
                      width="auto"
                    >
                      <P14 maxWidth="150px" overflow="hidden" textOverflow="ellipsis" noWrap>
                        {selectedPropertyObject?.valueExample}
                      </P14>
                    </StyledTooltip>
                  ) : (
                    <P14>{selectedPropertyObject?.valueExample}</P14>
                  )}
                </StyledBox>
              </>
            )}
          </Box>
          <ButtonRounded type="button" variant="contained" color="primary" mt="auto" onClick={handleSelect}>
            {t('Select')}
          </ButtonRounded>
        </>
      )}
    </Box>
  );
};

export default DescriptionColumn;
