import styled from 'styled-components';

const StyledVideoWrapper = styled.div`
  transform: scale(1.5);
  width: 100%;
`;

const StyledImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 33.3%;
  background-color: #f5f6f7;
  background-image: url('/img/recipes/bg-pattern.svg');
  background-position: center center;
`;

const StyledImage = styled.div`
  position: absolute;
  width: 95%;
  height: 90%;
  top: 5%;
  left: 2.5%;
  background-image: url(${({ bgImageUrl }) => bgImageUrl || 'empty'});
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
`;

export { StyledVideoWrapper, StyledImageWrapper, StyledImage };
