import * as Yup from 'yup';
import buildValidator from '../../../../utils/validators/buildValidator';

const generateValidator = (form) => {
  const schema = {};

  form.forEach((field) => {
    if (!field?.optional) {
      schema[field.block_id] = Yup.string().required('This field is required');
    }
  });

  const dynamicSchema = Yup.object().shape(schema);

  return buildValidator(dynamicSchema);
};

export default generateValidator;
