import React from 'react';
import Box from '@mui/material/Box';
import { ErrorRounded } from '@mui/icons-material';
import { StyledDetailsBox, StyledPaginationBox, StyledTableBox } from '../styled';
import LoadingState from './LoadingState';
import Pagination from '../../SmartTable/Pagination';
import { useFlowMonitorDetails } from './useFlowMonitorDetails';
import FlowMonitorDetailsHeader from './FlowMonitorDetailsHeader';
import useFilterHook from '../../../pages/InsightsPage/PaymentInsights/FilterBar/useFilterHook';
import FlowMonitorDetailsItem from './FlowMonitorDetailsItem';
import SuccessCheckmarkIcon from '../../../assets/icons/FlowMonitor/SuccessCheckmarkIcon';
import { RANGE_DICTIONARY } from '../../../pages/InsightsPage/PaymentInsights/FilterBar/constant';
import THEME from '../../../constants/theme';

const FlowMonitorDetails = () => {
  const {
    loading,
    flowInstances,
    showPagination,
    pageInfo,
    selectedStatus,
    setSelectedStatus,
    setSelectedInstanceId,
  } = useFlowMonitorDetails();
  const { handlePresetFilter, handleDateRange, handleRangeType } = useFilterHook(RANGE_DICTIONARY.sevenDays);
  return (
    <StyledDetailsBox>
      <FlowMonitorDetailsHeader
        handlePresetFilter={handlePresetFilter}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        handleDateRange={handleDateRange}
        handleRangeType={handleRangeType}
      />
      <Box p="6px 8px">
        {loading ? (
          <LoadingState />
        ) : (
          <StyledTableBox>
            {flowInstances.map(({ id, date, isInErrorStatus, retryCount }) => (
              <FlowMonitorDetailsItem
                key={id}
                // errorMessage={
                //   instance.errorMessage && (
                //     <StyledFlowInstancesListError>
                //       <span>{instance.stepWithErrorName}:</span>&nbsp;
                //       {instance.errorMessage}
                //     </StyledFlowInstancesListError>
                //   )
                // }
                isInErrorStatus={isInErrorStatus}
                label={date}
                onClick={() => setSelectedInstanceId(id)}
                retryCount={retryCount}
                width="auto"
              >
                <Box display="inline-flex" pr="12px" color={THEME.statusColors.darkRed}>
                  {isInErrorStatus ? <ErrorRounded color="inherit" /> : <SuccessCheckmarkIcon />}
                </Box>
              </FlowMonitorDetailsItem>
            ))}
            {showPagination && (
              <StyledPaginationBox>
                <Pagination loading={loading} pageInfo={pageInfo} />
              </StyledPaginationBox>
            )}
          </StyledTableBox>
        )}
      </Box>
    </StyledDetailsBox>
  );
};

export default FlowMonitorDetails;
