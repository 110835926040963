import styled, { css } from 'styled-components';
import THEME from '../../../../../constants/theme';
import { P14 } from '../../../../../components/atoms';

export const StyledContentAreaWrapper = styled.div`
  width: calc(100% - 264px);
  height: 100%;
  display: block;
  position: relative;
  box-sizing: border-box;
  min-height: 500px;
  ${({ $inModal }) =>
    $inModal &&
    css`
      margin: 0;
      padding: 50px 32px 0;
      border-left: 1px solid ${THEME.greyColors.grey5};
    `}
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const TabContainer = styled.div`
  margin-right: auto;
  display: flex;
`;

export const Tab = styled(P14)`
  margin-right: 16px;
  cursor: pointer;
  font-weight: 500;
  color: ${THEME.primaryColors.blackMain};
  opacity: ${({ activeTab }) => (activeTab ? '1' : '0.5')};

  &.active {
    opacity: 1;
  }
`;
