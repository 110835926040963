import React from 'react';
import { func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StyledHeader, IconWrapper, HeaderDetails } from './styled';
import CloseButton from '../../../../../../../components/Dialog/CloseButton';
import File from '../../../../../../../assets/icons/File';
import { P14M } from '../../../../../../../components/atoms';
import { L10UM } from '../../../../../../../components/atoms/Typography/L10UM';
import THEME from '../../../../../../../constants/theme';
import { formatDate } from '../../constant';

const Header = ({ setShouldDrawerOpen, status, responseDate }) => {
  const { t } = useTranslation();

  const toggleDrawer = (e) => {
    if (e.type === 'keydown') return;

    setShouldDrawerOpen(false);
  };

  return (
    <StyledHeader>
      <IconWrapper>
        <File />
      </IconWrapper>

      <HeaderDetails>
        <P14M lineHeight="24px"> {t(formatDate(responseDate))}</P14M>
        {status && (
          <L10UM color={THEME.secondaryColors.greenDark} className="status">
            {t(status)}
          </L10UM>
        )}
      </HeaderDetails>

      <CloseButton onClick={toggleDrawer} iconColor={THEME.greyColors.grey17} />
    </StyledHeader>
  );
};

Header.propTypes = {
  setShouldDrawerOpen: func.isRequired,
  responseDate: string.isRequired,
  status: string,
};

Header.defaultProps = {
  status: null,
};

export default Header;
