import { useCallback } from 'react';
import { useFlowEditorContext } from '../../../../../context';

const useNodeDataToSave = () => {
  const { nodeDataToSave, setNodeDataToSave } = useFlowEditorContext();

  const updateDataToSave = useCallback(
    (newData) =>
      setNodeDataToSave((prevData) => ({
        ...prevData,
        ...newData,
      })),
    [setNodeDataToSave]
  );
  return [nodeDataToSave, updateDataToSave];
};

export { useNodeDataToSave };
