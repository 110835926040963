import React, { useState } from 'react';
import { arrayOf, bool, func, oneOfType, string } from 'prop-types';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';
import { StyledSelect } from '../../FlowEditor/components/AutomationDetails/AutomationStep/fields/styled';
import { OptionsType } from '../../FlowEditor/components/AutomationDetails/AutomationStep/inputs/types';
import { StyledPaper, StyledMenuItem } from '../../atoms/Select/StyledSelect';
import CheckIcon from '../../../assets/icons/Select/CheckIcon';
import { CircleImage, SearchBar } from '../../atoms';

const ITEM_HEIGHT = 33;
const Y_MARGIN = 8;
const NONE = 'none';

const useStyles = makeStyles({
  menuPaper: {
    maxHeight: 7 * ITEM_HEIGHT + 2 * Y_MARGIN,
  },
});

const Select = ({ name, value, options, onChange, disabled, hideNone, multiple, placeholder }) => {
  const [filter, setFilter] = useState('');
  const classes = useStyles();
  const { t } = useTranslation();
  const isValid = value === multiple ? Array.isArray(value) : NONE || options?.some((option) => option.value === value);

  const selectedItem = options?.find(({ value: optionValue }) => value === optionValue);

  const icon = selectedItem?.icon;

  const filteredItems = options?.filter(
    ({ value: itemValue, title: itemTitle }) =>
      (typeof itemValue === 'string' && itemValue?.toLowerCase()?.includes(filter?.toLowerCase())) ||
      (typeof itemTitle === 'string' && itemTitle?.toLowerCase()?.includes(filter?.toLowerCase())) ||
      value === itemValue
  );

  const handleChange = (e) => {
    if (!isNil(e.target.value)) {
      onChange(e);
    }
  };

  const handleClose = () => {
    setFilter('');
  };

  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <StyledSelect
      name={name}
      value={value}
      IconComponent={ExpandMoreIcon}
      MenuProps={{
        classes: { paper: classes.menuPaper },
        getContentAnchorEl: null,
        PaperProps: {
          component: StyledPaper,
        },
      }}
      variant="standard"
      onChange={handleChange}
      onClose={handleClose}
      multiple={multiple}
      displayEmpty
      disabled={disabled}
      {...(icon && {
        renderValue: () => (
          <Box display="flex" alignItems="center">
            <CircleImage src={icon} size="28" />
            <Box ml="8px">{selectedItem.title}</Box>
          </Box>
        ),
      })}
      {...(multiple && {
        renderValue: (currentValue) =>
          currentValue
            ?.map((current) => options.find(({ value: optionValue }) => current === optionValue)?.title)
            .join(', '),
      })}
    >
      <StyledMenuItem
        onKeyDown={(e) => e.stopPropagation()}
        onClickCapture={stopImmediatePropagation}
        pt="0px"
        divider
        noBgColor
      >
        <SearchBar
          search={filter}
          setSearch={setFilter}
          bgcolor="transparent"
          width="100%"
          height="30px"
          paddingX="0"
          paddingY="0"
          noClear
        />
      </StyledMenuItem>

      {!hideNone && (
        <StyledMenuItem value={NONE} $justifyContent="space-between" margin="4px 0 0 0">
          {t(placeholder || 'None')} <CheckIcon />
        </StyledMenuItem>
      )}

      {hideNone && placeholder && (
        <StyledMenuItem value={NONE} disabled>
          {t(placeholder || 'None')}
        </StyledMenuItem>
      )}

      {!isValid && (
        <StyledMenuItem value={value} disabled>
          <Box component="span" color="red">
            {value}
          </Box>
        </StyledMenuItem>
      )}

      {filteredItems?.map(({ value: key, title }) => (
        <StyledMenuItem key={key} value={key} $justifyContent="space-between">
          {title}
          <CheckIcon />
        </StyledMenuItem>
      ))}
    </StyledSelect>
  );
};

Select.propTypes = {
  name: string,
  value: oneOfType([string, arrayOf(string)]),
  options: OptionsType,
  onChange: func,
  disabled: bool,
  hideNone: bool,
  multiple: bool,
  placeholder: string,
};

Select.defaultProps = {
  name: undefined,
  value: undefined,
  onChange: undefined,
  disabled: false,
  hideNone: false,
  multiple: false,
  options: [],
  placeholder: null,
};

export default Select;
export { NONE };
