import React from 'react';
import { number, string } from 'prop-types';
import THEME from '../../constants/theme';

const CategoryAlerts = ({ size, color }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6392 9.58341C10.9517 9.58341 9.58366 10.9514 9.58366 12.639C9.58366 14.3265 10.9517 15.6945 12.6392 15.6945C14.3268 15.6945 15.6948 14.3265 15.6948 12.639C15.6948 10.9514 14.3268 9.58341 12.6392 9.58341ZM7.91699 12.639C7.91699 10.031 10.0312 7.91675 12.6392 7.91675C15.2472 7.91675 17.3614 10.031 17.3614 12.639C17.3614 15.247 15.2472 17.3612 12.6392 17.3612C10.0312 17.3612 7.91699 15.247 7.91699 12.639Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7968 14.7961C15.1222 14.4706 15.6499 14.4706 15.9753 14.7961L18.0899 16.9107C18.4153 17.2361 18.4153 17.7637 18.0899 18.0892C17.7645 18.4146 17.2368 18.4146 16.9114 18.0892L14.7968 15.9746C14.4714 15.6492 14.4714 15.1215 14.7968 14.7961Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.833008 12.5001C0.833008 12.0398 1.2061 11.6667 1.66634 11.6667H4.99967C5.45991 11.6667 5.83301 12.0398 5.83301 12.5001C5.83301 12.9603 5.45991 13.3334 4.99967 13.3334H1.66634C1.2061 13.3334 0.833008 12.9603 0.833008 12.5001Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.833008 8.33333C0.833008 7.8731 1.2061 7.5 1.66634 7.5H6.66634C7.12658 7.5 7.49967 7.8731 7.49967 8.33333C7.49967 8.79357 7.12658 9.16667 6.66634 9.16667H1.66634C1.2061 9.16667 0.833008 8.79357 0.833008 8.33333Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.833008 4.16659C0.833008 3.70635 1.2061 3.33325 1.66634 3.33325H13.333C13.7932 3.33325 14.1663 3.70635 14.1663 4.16659C14.1663 4.62682 13.7932 4.99992 13.333 4.99992H1.66634C1.2061 4.99992 0.833008 4.62682 0.833008 4.16659Z"
      fill={color}
    />
  </svg>
);

CategoryAlerts.propTypes = {
  size: number,
  color: string,
};

CategoryAlerts.defaultProps = {
  size: 20,
  color: THEME.primaryColors.black,
};

export default CategoryAlerts;
