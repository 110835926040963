import { withStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { Handle } from 'react-flow-renderer';
import styled from 'styled-components';
import IconButtonBase from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import THEME from '../../../../constants/theme';
import { L10B } from '../../../atoms/Typography/L10B';
import { L10UM } from '../../../atoms/Typography/L10UM';
import { P14M } from '../../../atoms';

export const StyledSourceHandle = styled(Handle)`
  border: none;
  background-color: ${({ $isShow }) => ($isShow ? `${THEME.primaryColors.black}` : 'transparent')};
  width: 9px;
  height: 9px;
  z-index: 2;
`;

export const RightSourceHandle = styled(StyledSourceHandle)`
  right: -7px;
`;

export const LeftSourceHandle = styled(StyledSourceHandle)`
  left: -7px;
`;

export const TopSourceHandle = styled(StyledSourceHandle)`
  top: -7px;
`;

export const BottomSourceHandle = styled(StyledSourceHandle)`
  bottom: -7px;
`;

export const StyledTargetHandle = styled(Handle)`
  border: none;
  background-color: transparent;
  border-radius: 0;
  z-index: 2;
  visibility: ${({ $connecting }) => ($connecting ? 'visible' : 'hidden')};
  &:hover {
    & > div {
      visibility: visible;
    }
  }
`;

export const TargetDot = styled.div`
  position: absolute;
  width: 9px;
  height: 9px;
  background: ${THEME.primaryColors.black};
  border-radius: 50%;
  visibility: hidden;
  pointer-events: none;
`;

export const TopTargetDot = styled(TargetDot)`
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export const BottomTargetDot = styled(TargetDot)`
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export const LeftTargetDot = styled(TargetDot)`
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const RightTargetDot = styled(TargetDot)`
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const LeftTargetHandle = styled(StyledTargetHandle)`
  width: 76px;
  height: 92px;
  left: -7px;
`;

export const RightTargetHandle = styled(StyledTargetHandle)`
  width: 76px;
  height: 92px;
  right: -7px;
`;

export const TopTargetHandle = styled(StyledTargetHandle)`
  width: 160px;
  height: 46px;
  top: -7px;
`;

export const BottomTargetHandle = styled(StyledTargetHandle)`
  width: 160px;
  height: 46px;
  bottom: -7px;
`;

export const HiddenNodeHandle = styled(Handle)`
  width: 0;
  height: 0;
  visibility: hidden;
  right: 0;
`;

export const TestNodeTargetHandle = styled(Handle)`
  width: 0;
  height: 0;
  visibility: hidden;
  left: 0;
`;

export const NodeRowLShape = styled.div`
  margin-left: 11px;
  margin-bottom: 11px;
  height: 12px;
  width: 12px;
  border-left: 1px solid #c1c3c6;
  border-bottom: 1px solid #c1c3c6;
  margin-right: 4px;
`;

export const StyledGrabbableBox = styled(Box)`
  cursor: default;
  box-sizing: border-box;
  position: relative;
  transition: all 0.3s ease-out;
  &:active {
    cursor: grabbing;
  }
  & .ellipse-container {
    & p {
      & div {
        max-width: 140px;
        text-overflow: ellipsis;
        overflow-x: hidden;
      }
    }
  }
`;

export const StyledIconButton = withStyles({
  root: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    color: '#fff',
    '& .MuiIconButton-label': {
      width: '24px',
      height: '24px',
    },
  },
})(IconButtonBase);

export const StyledBadge = withStyles({
  root: {
    display: 'flex',
    position: 'absolute',
    bottom: '9px',
    right: '9px',
    backgroundColor: ({ bgColor }) => bgColor || THEME.primaryColors.primary,
    cursor: ({ cursor }) => cursor || 'default',
    width: '14px',
    height: '14px',
    borderRadius: '50%',
    color: '#fff',
  },
})(Box);

const createStyledIcon = (Icon) =>
  withStyles({
    root: {
      width: '100%',
      height: '100%',
    },
  })(Icon);

export const StyledMoreHorizIcon = createStyledIcon(MoreHorizIcon);

export const StyledCheckIcon = createStyledIcon(CheckIcon);

export const StyledCloseIcon = createStyledIcon(CloseIcon);

export const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f6f7;
  padding: 10px 16px;
  cursor: ${({ cursor }) => cursor || 'default'};
`;

export const StyledIconWrap = styled(Box)`
  display: flex;
  align-items: center;
  margin-right: ${({ marginRight }) => marginRight || '22px'};
`;

export const MoreWrapper = styled(Box)`
  position: absolute;
  right: 16px;
  top: 16px;
`;

export const MoreButton = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
`;

export const MessagesWrapper = styled(Box)`
  cursor: pointer;
  position: absolute;
  right: 16px;
  bottom: -16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 107px;
  height: 32px;
  border-radius: 40px;
  /* background-color: ${THEME.primaryColors.white}; */
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);
`;

export const MessagesModalWrapper = styled(Box)`
  position: absolute;
  top: calc(100% + 20px);
  left: -40px;
  width: 320px;
  background: ${THEME.primaryColors.white};
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
`;

export const StyledMessagesInput = styled.textarea`
  cursor: text;
  outline: none;
  flex: 1;
  padding: 8px;
  font-size: 14px;
`;

export const ErrorDot = styled(Box)`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: ${THEME.statusColors.failed};
  position: absolute;
  bottom: 18px;
  right: 18px;
`;

export const StyledL10B = styled(L10B)`
  letter-spacing: 0;
`;

export const NodeNameBox = styled(Box)`
  color: #787f88;
  font-size: 12px;
  line-height: 16px;
  max-width: 70%;
`;

export const StyledAnnotationMediaBox = styled(Box)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 291px;
  height: 190px;
  margin: 12px 0;
  background-image: ${({ image }) => (image ? `url(${image})` : 'none')};
  border-radius: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const StyledPlayIconBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & svg rect {
    transition: all 0.3s ease-out;
  }
  &:hover {
    svg rect {
      fill: ${THEME.primaryColors.blue};
    }
  }
`;

export const TransparentButton = styled(Box)`
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CommentsMoreButton = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
`;

export const StatusIconBox = styled(Box)`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
  color: ${THEME.statusColors.darkRed};
`;

export const RetryBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${THEME.primaryColors.blackPlain};
  background-color: ${({ $retrying }) => ($retrying ? THEME.greyColors.grey600 : THEME.primaryColors.blackPlain)};
  &:hover {
    background-color: ${({ $retrying }) => ($retrying ? THEME.greyColors.grey600 : THEME.primaryColors.primary)};
  }
`;

export const NodeTitle = styled(L10UM)`
  transition: all 1.2s ease;
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0));
  &.transition {
    transform: ${({ $diff }) => `translateX(${$diff}px)`};
    mask-image: none;
  }
`;

export const NodeLabel = styled(P14M)`
  transition: all 1.2s ease;
  mask-image: ${({ $diff }) => ($diff ? 'linear-gradient(to right, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0))' : 'none')};
  &.transition {
    transform: ${({ $diff }) => `translateX(${$diff}px)`};
    mask-image: none;
  }
`;
