import React from 'react';

const Tokenise = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="52" height="52" rx="8" fill="#F5F2FF" />
      <path
        d="M26.0229 16.8343C25.8618 16.8293 25.7049 16.8867 25.5851 16.9946C25.5851 16.9946 22.4568 19.751 18.7109 19.751C18.5452 19.751 18.3862 19.8168 18.269 19.934C18.1518 20.0512 18.086 20.2102 18.0859 20.376V25.4842C18.0859 28.2667 19.3732 32.8453 25.7601 35.5355C25.8369 35.5678 25.9193 35.5844 26.0026 35.5844C26.0859 35.5844 26.1683 35.5678 26.2451 35.5355C32.632 32.8453 33.9193 28.2667 33.9193 25.4842V20.376C33.9193 20.2102 33.8534 20.0512 33.7362 19.934C33.619 19.8168 33.46 19.751 33.2943 19.751C29.5484 19.751 26.4201 16.9946 26.4201 16.9946C26.3107 16.8961 26.1701 16.8393 26.0229 16.8343V16.8343ZM26.0026 22.6676C27.153 22.6676 28.0859 23.6005 28.0859 24.751C28.0859 25.4301 27.7559 26.0279 27.2526 26.4079V28.501C27.2526 29.1914 26.693 29.751 26.0026 29.751C25.3122 29.751 24.7526 29.1914 24.7526 28.501V26.4079C24.2493 26.0274 23.9193 25.4301 23.9193 24.751C23.9193 23.6005 24.8522 22.6676 26.0026 22.6676Z"
        fill="#4E40EF"
      />
    </svg>
  );
};

export default Tokenise;
