import React from 'react';

const Cash = () => {
  return (
    <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="34" height="24" rx="4" fill="white" />
      <rect
        x="0.25"
        y="0.25"
        width="33.5"
        height="23.5"
        rx="3.75"
        stroke="black"
        strokeOpacity="0.15"
        strokeWidth="0.5"
      />
      <path
        opacity="0.3"
        d="M22.6667 13.3335C23.2189 13.3335 23.6667 12.8858 23.6667 12.3335C23.6667 11.7812 23.2189 11.3335 22.6667 11.3335C22.1144 11.3335 21.6667 11.7812 21.6667 12.3335C21.6667 12.8858 22.1144 13.3335 22.6667 13.3335Z"
        fill="#4E40EF"
      />
      <path
        opacity="0.3"
        d="M21.5477 4.69904L11.2445 7.45978C10.8888 7.55507 10.6778 7.92063 10.7731 8.27628L11.6358 11.496C11.7311 11.8517 12.0967 12.0627 12.4523 11.9674L22.7555 9.2067C23.1112 9.1114 23.3222 8.74584 23.2269 8.3902L22.3642 5.17045C22.2689 4.8148 21.9033 4.60375 21.5477 4.69904Z"
        fill="#4E40EF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6667 10.2244C23.3636 10.0804 23.0245 9.99984 22.6667 9.99984C21.378 9.99984 20.3333 11.0445 20.3333 12.3332C20.3333 13.6218 21.378 14.6665 22.6667 14.6665C23.0245 14.6665 23.3636 14.5859 23.6667 14.442V15.9998C23.6667 16.7362 23.0697 17.3332 22.3333 17.3332H11.6667C10.9303 17.3332 10.3333 16.7362 10.3333 15.9998V7.99984C10.3333 7.26346 10.9303 6.6665 11.6667 6.6665H22.3333C23.0697 6.6665 23.6667 7.26346 23.6667 7.99984V10.2244Z"
        fill="#4E40EF"
      />
    </svg>
  );
};

export default Cash;
