import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { P16B, P14 } from '../atoms';
import ListIcon from '../../assets/icons/EmptyStates/ListIconNew';

const svgBoxStyles = {
  transform: 'scale(1.1) ',
  margin: '0 0 20px -8px',
};

const ListEmptyState = ({ title, description, position, top, left, minWidth, children }) => {
  const { t } = useTranslation();

  return (
    <Box
      position={position}
      top={top}
      left={left}
      textAlign="center"
      {...(minWidth && { minWidth })}
      sx={{ transform: 'translate(-50%, -50%)' }}
    >
      <Box sx={svgBoxStyles}>
        <ListIcon />
      </Box>
      <P16B margin="32px 0 8px 0">{t(title)}</P16B>
      <P14 color="#787F88">{t(description)}</P14>
      {children}
    </Box>
  );
};

ListEmptyState.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  position: PropTypes.string,
  top: PropTypes.string,
  left: PropTypes.string,
  minWidth: PropTypes.string,
};
ListEmptyState.defaultProps = {
  description: null,
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '',
};
export default ListEmptyState;
