import React from 'react';

const MitigateProcessorDowntime = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22Z"
        fill="#A015D1"
      />
      <g clipPath="url(#clip0_1442_5332)">
        <path
          d="M22.6438 13.2738C22.4295 13.2579 22.2148 13.25 22 13.25V12C22.2457 12.0001 22.4913 12.0093 22.7362 12.0275L22.6438 13.2738ZM25.1487 13.8363C24.7481 13.6816 24.3365 13.5567 23.9175 13.4625L24.1913 12.2425C24.67 12.35 25.1412 12.4925 25.5987 12.67L25.1487 13.8363ZM26.8612 14.7238C26.6825 14.6046 26.4995 14.492 26.3125 14.3863L26.9287 13.2988C27.3561 13.5409 27.7651 13.8142 28.1525 14.1163L27.3837 15.1025C27.2143 14.9703 27.04 14.8444 26.8612 14.725V14.7238ZM29.1537 16.9613C28.9065 16.61 28.6337 16.2774 28.3375 15.9663L29.2425 15.1038C29.58 15.46 29.8925 15.8413 30.1762 16.2413L29.1537 16.9613ZM30.0837 18.6513C30.0017 18.4531 29.9125 18.2579 29.8162 18.0663L30.9325 17.5038C31.1532 17.9426 31.3413 18.3972 31.495 18.8638L30.3075 19.255C30.2403 19.0511 30.1657 18.8497 30.0837 18.6513ZM30.7462 21.785C30.7361 21.3554 30.6944 20.9272 30.6212 20.5038L31.8525 20.2913C31.9362 20.7738 31.985 21.2638 31.9975 21.7538L30.7475 21.785H30.7462ZM30.5825 23.7075C30.6237 23.495 30.6575 23.2838 30.6837 23.07L31.925 23.2238C31.865 23.7114 31.7689 24.194 31.6375 24.6675L30.4325 24.3338C30.49 24.1275 30.54 23.9188 30.5825 23.7075ZM29.3925 26.6813C29.6225 26.3188 29.825 25.9388 30 25.5463L31.1425 26.0525C30.9425 26.5025 30.7112 26.935 30.4487 27.35L29.3925 26.6813ZM28.1875 28.1875C28.34 28.035 28.4862 27.8775 28.625 27.715L29.5725 28.5313C29.412 28.7173 29.2448 28.8974 29.0712 29.0713L28.1875 28.1875Z"
          fill="white"
        />
        <path
          d="M22 13.25C20.5611 13.2501 19.1444 13.6051 17.8755 14.2834C16.6065 14.9618 15.5244 15.9426 14.7251 17.1391C13.9257 18.3355 13.4337 19.7106 13.2927 21.1426C13.1517 22.5746 13.3661 24.0192 13.9167 25.3486C14.4674 26.6779 15.3374 27.851 16.4497 28.7638C17.5619 29.6766 18.8822 30.3011 20.2934 30.5818C21.7047 30.8626 23.1634 30.791 24.5403 30.3733C25.9173 29.9557 27.17 29.2049 28.1875 28.1875L29.0712 29.0712C27.9084 30.2347 26.4766 31.0934 24.9025 31.5712C23.3285 32.0491 21.661 32.1313 20.0476 31.8106C18.4342 31.4899 16.9248 30.7762 15.6532 29.7328C14.3815 28.6893 13.3869 27.3483 12.7574 25.8286C12.1279 24.3089 11.883 22.6573 12.0443 21.0203C12.2057 19.3833 12.7683 17.8114 13.6823 16.4438C14.5964 15.0762 15.8337 13.9551 17.2845 13.18C18.7354 12.4049 20.3551 11.9996 22 12V13.25Z"
          fill="white"
        />
        <path
          d="M21.375 15.75C21.5408 15.75 21.6997 15.8158 21.8169 15.9331C21.9342 16.0503 22 16.2092 22 16.375V22.8875L26.06 25.2075C26.1997 25.2918 26.301 25.4274 26.342 25.5854C26.3831 25.7433 26.3608 25.9111 26.2798 26.0527C26.1988 26.1944 26.0657 26.2989 25.9087 26.3437C25.7518 26.3885 25.5836 26.3701 25.44 26.2925L21.065 23.7925C20.9693 23.7379 20.8898 23.6589 20.8345 23.5636C20.7792 23.4684 20.75 23.3602 20.75 23.25V16.375C20.75 16.2092 20.8158 16.0503 20.9331 15.9331C21.0503 15.8158 21.2092 15.75 21.375 15.75Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1442_5332">
          <rect width="20" height="20" fill="white" transform="translate(12 12)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MitigateProcessorDowntime;
