import React from 'react';
import { shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StyledWrapper, StyledTitleWrapper } from '../StatusTab/styled';
import { P16B, P14 } from '../../../../../components/atoms';
import THEME from '../../../../../constants/theme';
import PaymentMethodItem from './PaymentMethodItem';
import { StyledDetailsTab, StyledPaymentMethods } from './styled';
import { CONNECTION_STATUS } from '../../../components/constant';

const TITLE = 'Payment Methods';
const DESC = 'Reach more buyers and drive higher conversion with an online payment solution that processes';

const DetailsTab = ({ connection }) => {
  const { t } = useTranslation();

  const enabledPaymentMethods = connection?.enabledPaymentMethods ?? [];
  const paymentMethods = connection?.supportedPaymentMethods ?? [];
  const isConnected =
    connection.status === CONNECTION_STATUS.CONNECTED || connection.status === CONNECTION_STATUS.ONBOARDING;

  return (
    <StyledDetailsTab>
      <StyledWrapper>
        <StyledTitleWrapper p="24px 24px 16px 24px">
          <P16B margin="0 0 8px 0">{t(TITLE)}</P16B>
          <P14 color={THEME.greyColors.grey1}>{t(DESC)}</P14>
        </StyledTitleWrapper>

        <StyledPaymentMethods isConnected={isConnected}>
          {paymentMethods.map((method, index) => {
            const isOn = enabledPaymentMethods.includes(method);
            const id = `${method}-${index}`;

            return (
              <PaymentMethodItem
                key={id}
                method={method}
                showToggle={isConnected}
                checked={isOn}
                connection={connection}
                enabledPaymentMethods={enabledPaymentMethods}
              />
            );
          })}
        </StyledPaymentMethods>
      </StyledWrapper>
    </StyledDetailsTab>
  );
};

DetailsTab.propTypes = {
  connection: shape({}).isRequired,
};

export default DetailsTab;
