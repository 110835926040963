import { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useBoolState } from '../../../hooks/useBoolState';
import { useEditorData } from '../../FlowEditor/hooks/useEditorData';
import { GQL_M_TEST_FLOW, GQL_M_USER_INPUT_REQUEST } from '../../../utils/queries/flows/mutations';
import { useGetVersionHistory } from '../../FlowHeader/VersionControl/hooks/useGetVersionHistory';

export const useFlowTest = ({ flowId }) => {
  const {
    nodes,
    setNodes,
    edges,
    setEdges,
    isDataReady,
    initialState,
    setInitialState,
    publishedData,
    firstStepId,
    flowName,
    flowInstanceCount,
    flowStatus,
    loading,
    refetch,
  } = useEditorData(flowId);

  const [hoverNodeId, setHoverNodeId] = useState(null);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const { toggle: onValidate, bool: isValidateOn } = useBoolState(false);
  const [isAutomationTest, setIsAutomationTest] = useState(true);
  const [isAutomationTestInProgress, setIsAutomationTestInProgress] = useState(false);
  const [currentTestStepId, setCurrentTestStepId] = useState(null);
  const [examplesRequired, setExamplesRequired] = useState(false);
  const [isUserDecisionRequired, setIsUserDecisionRequired] = useState(null);
  const [selectedTestExample, setSelectedTestExample] = useState(null);
  const [testFlowInstance, setTestFlowInstance] = useState(null);
  const [isOpenTestExamplesModal, setIsOpenTestExamplesModal] = useState(false);
  const [testExamples, setTestExamples] = useState([]);
  const selectedNode = nodes.find(({ id }) => selectedNodeId === id);
  const selectedNodeData = selectedNode?.data;
  const currentTestStep = nodes.find(({ id }) => currentTestStepId === id)?.data;
  const { versionHistory, loading: versionHistoryLoading } = useGetVersionHistory(flowId);

  const [testFlow, { loading: testFlowLoading }] = useMutation(GQL_M_TEST_FLOW, {
    variables: { flowId },
    onCompleted: (testFlowData) => {
      setTestFlowInstance(testFlowData?.testFlow);
    },
  });
  const [userInputRequest, { loading: userInputRequestLoading }] = useMutation(GQL_M_USER_INPUT_REQUEST, {
    skip: !testFlowInstance?.id,
    onCompleted: (userInputData) => {
      setTestFlowInstance(userInputData?.userInput);
    },
  });

  const onTestExampleConfirm = useCallback(async () => {
    setExamplesRequired(false);
    setIsOpenTestExamplesModal(false);
    await userInputRequest({
      variables: {
        instanceId: testFlowInstance?.id,
        input: selectedTestExample?.asInput,
      },
    });
  }, [selectedTestExample?.asInput, testFlowInstance?.id, userInputRequest]);

  const onUserDecisionConfirm = useCallback(
    async (isSimulate) => {
      setIsUserDecisionRequired(false);
      await userInputRequest({
        variables: {
          instanceId: testFlowInstance?.id,
          input: {
            simulate: isSimulate,
          },
        },
      });
    },
    [testFlowInstance?.id, userInputRequest]
  );

  const context = useMemo(
    () => ({
      initialState,
      setInitialState,
      nodes,
      setNodes,
      edges,
      setEdges,
      selectedNodeId,
      selectedNode,
      selectedNodeData,
      setSelectedNodeId,
      hoverNodeId,
      setHoverNodeId,
      publishedData,
      firstStepId,
      isValidateOn,
      onValidate,
      isAutomationTest,
      setIsAutomationTest,
      isAutomationTestInProgress,
      setIsAutomationTestInProgress,
      testFlow,
      testFlowInstance,
      testFlowLoading,
      currentTestStep,
      currentTestStepId,
      setCurrentTestStepId,
      examplesRequired,
      setExamplesRequired,
      testExamples,
      setTestExamples,
      selectedTestExample,
      setSelectedTestExample,
      userInputRequestLoading,
      isUserDecisionRequired,
      setIsUserDecisionRequired,
      onUserDecisionConfirm,
      isOpenTestExamplesModal,
      setIsOpenTestExamplesModal,
      isDataReady,
      onTestExampleConfirm,
      flowId,
      flowName,
      flowStatus,
      flowInstanceCount,
      loading,
      refetch,
      versionHistory,
      versionHistoryLoading,
    }),
    [
      initialState,
      setInitialState,
      nodes,
      setNodes,
      edges,
      setEdges,
      selectedNodeId,
      selectedNode,
      selectedNodeData,
      hoverNodeId,
      publishedData,
      firstStepId,
      isValidateOn,
      onValidate,
      isAutomationTest,
      isAutomationTestInProgress,
      testFlow,
      testFlowInstance,
      testFlowLoading,
      currentTestStep,
      currentTestStepId,
      examplesRequired,
      testExamples,
      selectedTestExample,
      userInputRequestLoading,
      isUserDecisionRequired,
      onUserDecisionConfirm,
      isOpenTestExamplesModal,
      isDataReady,
      onTestExampleConfirm,
      flowId,
      flowName,
      flowStatus,
      flowInstanceCount,
      loading,
      refetch,
      versionHistory,
      versionHistoryLoading,
    ]
  );

  return {
    context,
    initialState,
    nodes,
    setNodes,
    edges,
    setEdges,
    selectedNodeId,
    selectedNode,
    selectedNodeData,
    setSelectedNodeId,
    hoverNodeId,
    setHoverNodeId,
    publishedData,
    firstStepId,
    isValidateOn,
    onValidate,
    isAutomationTest,
    setIsAutomationTest,
    isAutomationTestInProgress,
    setIsAutomationTestInProgress,
    testFlow,
    testFlowInstance,
    testFlowLoading,
    currentTestStep,
    currentTestStepId,
    setCurrentTestStepId,
    examplesRequired,
    setExamplesRequired,
    testExamples,
    setTestExamples,
    selectedTestExample,
    setSelectedTestExample,
    userInputRequestLoading,
    isUserDecisionRequired,
    setIsUserDecisionRequired,
    onUserDecisionConfirm,
    isOpenTestExamplesModal,
    setIsOpenTestExamplesModal,
    isDataReady,
    onTestExampleConfirm,
    flowId,
    flowName,
    flowStatus,
    flowInstanceCount,
    loading,
    refetch,
    versionHistory,
    versionHistoryLoading,
  };
};
