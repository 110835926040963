import React, { Fragment, useState, useEffect } from 'react';
import { withFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { func, shape } from 'prop-types';
import { useQuery } from '@apollo/client';
import omit from 'lodash/omit';
import addPersonSchema, {
  addPersonFields,
  addPersonOptions,
  addPersonFieldsNames,
} from '../../../utils/schemas/addPersonSchema';
import { FieldsWrapper } from '../formStyles';
import { Button, StyledSelect, BlockWrap, L12, P } from '../../atoms';
import THEME from '../../../constants/theme';
import { useInviteUser } from '../../../hooks/userHooks';
import { useGlobalContext } from '../../../containers/App/context';
import useSetCustomerMetadata from '../../../hooks/useSetCustomerMetadata';
import Input from '../_common/Input';
import { FlexContainer } from '../../atoms/flex/FlexContainer';
import { GET_TEAM } from '../../../utils/queries/workspaces/workspacesQueries';
import useSearch from '../../../hooks/useSearch';
import { showNotification } from '../../../hooks/useNotificationManager';
import { TOAST_TIMEOUT, TOAST_TYPES } from '../../../constants/toasts';
import { ROLES, ROLE_LABEL_MAP } from '../../../permissions/constants';

const TOAST_TITLE = 'Invite user.';

const AddPersonForm = ({ values, handleChange, handleBlur, errors, setFieldValue, setShowModal, userRefetch }) => {
  const { isCustomerSupportRole } = useGlobalContext();

  const [showErrors, setShowErrors] = useState(false);
  const [InviteUser, { data, loading, error }] = useInviteUser();
  const { stepsCheckListMeta } = useGlobalContext();
  const { setMetadata } = useSetCustomerMetadata();
  const { data: teamData } = useQuery(GET_TEAM);
  const [searchParams, setSearchParams] = useSearch();

  const { t } = useTranslation();

  const roleDropdownOptions = [
    ...addPersonOptions,
    ...(isCustomerSupportRole
      ? [{ value: ROLES.customerSupport, text: { text: ROLE_LABEL_MAP[ROLES.customerSupport] } }]
      : []),
  ];

  useEffect(() => {
    if (data) {
      userRefetch();
      setShowModal();
      if (!stepsCheckListMeta.collegues) {
        setMetadata({ checklist: { ...stepsCheckListMeta, collegues: true } });
      }
    }
  }, [data, userRefetch, stepsCheckListMeta, setMetadata, setShowModal]);

  const handleSubmit = () => {
    if (!isEmpty(errors)) {
      setShowErrors(true);
    } else {
      InviteUser({
        variables: {
          ...values,
          teamId: teamData?.team?.id,
        },
        onCompleted: () => {
          setSearchParams(omit(searchParams, ['invite', 'role']), true);
        },
      });
    }
  };

  const onChange = (e) => {
    handleChange(e);
  };

  const handleCancel = () => {
    setShowModal();
    setSearchParams(omit(searchParams, ['invite', 'role']), true);
  };

  useEffect(() => {
    setFieldValue(addPersonFieldsNames.companyRole, searchParams.role);
  }, [setFieldValue, searchParams.role]);

  if (error) {
    showNotification(TOAST_TYPES.error, error?.message, TOAST_TITLE, TOAST_TIMEOUT);
  }

  return (
    <>
      <FieldsWrapper>
        {addPersonFields.map(({ field, type, label }) => (
          <Fragment key={field}>
            {field !== addPersonFieldsNames.systemUserRole ? (
              <Input
                variant="outlined"
                type={type || 'text'}
                name={field}
                key={field}
                label={label}
                value={values[field]}
                onChange={onChange}
                onBlur={handleBlur}
                autoFocus={field === 'name' || false}
                customLabel
                customLabelProps={{
                  fontSize: '12px',
                }}
                margin="0 0 12px 0"
              />
            ) : (
              <>
                <P fontSize="12px !important" fontWeight={700} width="100%" textAlign="left" lineHeight="30px">
                  {label}
                </P>
                <StyledSelect
                  name={field}
                  id={field}
                  value={values[field] || searchParams.role}
                  onChange={onChange}
                  options={roleDropdownOptions}
                />
              </>
            )}

            {showErrors && errors[field] && (
              <L12 margin="12px 0 0 0" color={THEME.secondaryColors.danger}>
                {errors[field]}
              </L12>
            )}
          </Fragment>
        ))}
      </FieldsWrapper>
      <FlexContainer margin="auto 0 0" justifyContent="flex-start">
        <BlockWrap margin="30px 16px 0 0">
          <Button onClick={handleSubmit} className="blue" loading={loading}>
            {t('buttonsText.Invite')}
          </Button>
        </BlockWrap>
        <BlockWrap margin="30px 0 0">
          <Button onClick={handleCancel} ghost>
            {t('buttonsText.Cancel')}
          </Button>
        </BlockWrap>
      </FlexContainer>
    </>
  );
};

AddPersonForm.propTypes = {
  values: shape({}).isRequired,
  errors: shape({}).isRequired,
  handleChange: func.isRequired,
  handleBlur: func.isRequired,
  setShowModal: func.isRequired,
  userRefetch: func.isRequired,
  setFieldValue: func.isRequired,
};

export default withFormik(addPersonSchema)(AddPersonForm);
