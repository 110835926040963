import { mockedPaymentDetails } from '../mockedData';
import { PAYMENT_DESCRIBED_KEYS } from '../paymentDescribedDataKeys';
import { PAYMENT_STATUS_MAP } from '../constant';
import { PAYMENT_DETAILS_DATA_LABEL_MAP } from '../paymentDescribedDataLabelMap';

const convertPaymentObjectToArray = (obj) => {
  if (!obj) return [];

  const dataToExclude = ['__typename', 'description', 'statementDescriptor'];

  const reduced = Object.keys(obj).reduce((acc, curr) => {
    if (!dataToExclude.includes(curr)) {
      acc.push({
        key: curr,
        label: PAYMENT_DETAILS_DATA_LABEL_MAP[curr] ?? curr,
        value: typeof obj[curr] === 'string' || obj[curr] === null ? obj[curr] : obj[curr]?.formattedAmount,
      });
    }

    return acc;
  }, []);

  return reduced;
};

const NOT_APPLICABLE = 'N/A';

const getPaymentDetailsSectionData = ({ isIntent, payment }) => {
  const isDeclinedPayment = payment?.status === PAYMENT_STATUS_MAP.DECLINED;
  const isFailedPayment = payment?.status === PAYMENT_STATUS_MAP.FAILED;
  const processor = payment?.paymentGateway;
  const paymentCore = payment?.paymentCore;
  const paymentOutcome = paymentCore?.outcome;

  return {
    value: [
      ...(convertPaymentObjectToArray(payment?.paymentDetails) || []),
      { ...processor, label: 'Processor', key: PAYMENT_DESCRIBED_KEYS.paymentProcessor },
      {
        value: payment?.sourceId || NOT_APPLICABLE,
        label: PAYMENT_DETAILS_DATA_LABEL_MAP.sourceId,
        key: PAYMENT_DESCRIBED_KEYS.sourceId,
      },
      ...(isIntent ? mockedPaymentDetails.value : []),
      ...(isDeclinedPayment
        ? [
            {
              value: paymentOutcome?.processorDeclineCode || NOT_APPLICABLE,
              label: PAYMENT_DETAILS_DATA_LABEL_MAP.processorDeclineCode,
              key: PAYMENT_DESCRIBED_KEYS.processorDeclineCode,
            },
            {
              value: paymentOutcome?.reason || NOT_APPLICABLE,
              label: 'Processor Decline Reason',
              key: PAYMENT_DESCRIBED_KEYS.reason,
            },
            {
              value: paymentOutcome?.declineType || NOT_APPLICABLE,
              label: PAYMENT_DETAILS_DATA_LABEL_MAP.declineType,
              key: PAYMENT_DESCRIBED_KEYS.declineType,
            },
          ]
        : [
            {
              value: paymentOutcome?.reason || NOT_APPLICABLE,
              label: PAYMENT_DETAILS_DATA_LABEL_MAP.reason,
              key: PAYMENT_DESCRIBED_KEYS.reason,
            },
          ]),

      ...(isFailedPayment
        ? [
            {
              value: paymentOutcome?.businessFailureCode || NOT_APPLICABLE,
              label: PAYMENT_DETAILS_DATA_LABEL_MAP.businessFailureCode,
              key: PAYMENT_DESCRIBED_KEYS.businessFailureCode,
            },
            {
              value: paymentOutcome?.businessFailureReason || NOT_APPLICABLE,
              label: PAYMENT_DETAILS_DATA_LABEL_MAP.businessFailureReason,
              key: PAYMENT_DESCRIBED_KEYS.businessFailureReason,
            },
          ]
        : []),
    ],
  };
};

export { getPaymentDetailsSectionData };
