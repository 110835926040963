import { useQuery } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { isNode } from 'react-flow-renderer';
import { useToggle } from 'react-use';
import isFunction from 'lodash/isFunction';
import intersectionWith from 'lodash/intersectionWith';
import { facadeStepsToNodes, repopulateEdges } from '../utils/facades';
import { GET_FLOW, GET_FLOW_CONFIGURATION } from '../../../utils/queries/flows/queries';
import { getLayoutedElements } from '../utils/layout';
import { filterEdges, filterNodes } from '../utils/filterElements';
import { useFlowStepLibrary } from './useFlowStepLibrary';
import { useSaveConfiguration } from './useSaveConfiguration';
import { getNewElements } from '../utils/getNewElements';
import { useOnConnect } from './useOnConnect';
import { useSetElementsAndSave } from './useSetElementsAndSave';
import { useGetVersionHistory } from '../../FlowHeader/VersionControl/hooks/useGetVersionHistory';
import { getIsNodeConnected } from '../utils/getIsNodeConnected';
import { CONNECTION_STATUS } from '../../../pages/ConnectionsPage/components/constant';
import { useConfirmationModal } from './useConfirmationModal';
import ToastCustomContainer from '../../ToastCustomContainer';
import { TOAST_TIMEOUT } from '../../../constants/toasts';
import { useConfigurationIssues } from './useConfigurationIssues';

const getConfig = (getFlow) => getFlow?.draftConfig || getFlow?.config;

export const useEditorData = (flowId, versionId) => {
  const { t } = useTranslation();
  const { loading, errors, data, refetch } = useQuery(GET_FLOW, {
    variables: { id: flowId },
    fetchPolicy: 'no-cache',
    skip: !flowId,
  });
  const { loading: versionLoading, data: versionData } = useQuery(GET_FLOW_CONFIGURATION, {
    variables: { id: versionId },
    fetchPolicy: 'no-cache',
    skip: !versionId,
  });
  const { versionHistory, loading: versionHistoryLoading } = useGetVersionHistory(flowId);
  const { renderConfirmationModal, getRemoveNodeConfirmation } = useConfirmationModal();

  // FLOW LIBRARY BOILERPLATE
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [initialState, setInitialState] = useState([]);

  const [isFlowEditor, setIsFlowEditor] = useState(true);
  const [drawerStatus, toggleDrawerStatus] = useToggle(true);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [hoverNodeId, setHoverNodeId] = useState(null);
  const [savedNodeId, setSavedNodeId] = useState(null);
  const [isValidateOn, setIsValidateOn] = useState(false);
  const [currentValidate, setCurrentValidate] = useState(null);
  const [nodeDataToSave, setNodeDataToSave] = useState(null);
  const [selectedInputs, setSelectedInputs] = useState(null);
  const [changesMade, setChangesMade] = useState(false);
  const [isShowUpgradeModal, toggleShowUpgradeModal] = useToggle(false);
  const [isShowVideoModal, setIsShowVideoModal] = useState(false);
  const [videoModalUrl, setVideoModalUrl] = useState(null);
  const [isOpenConnectionModal, setIsOpenConnectionModal] = useState(false);
  const [newConnectionTypes, setNewConnectionTypes] = useState(null);
  const [messagesModalId, setMessagesModalId] = useState(null);
  const preCommitFunction = useRef();
  const [currentConfig, setCurrentConfig] = useState(null);
  const [draftConfig, setDraftConfig] = useState(null);
  const [configurationErrors, setConfigurationErrors] = useState(null);
  //INTERACTIONS
  const [connectingNodeData, setConnectingNodeData] = useState(null);
  const [isUpdatingEdgeTarget, setIsUpdatingEdgeTarget] = useState(false);
  const [selectedConditionEdgeNumber, setSelectedConditionEdgeNumber] = useState(null);
  const [pushedStart, setPushedStart] = useState(false);

  const getFlow = data?.getFlow;
  const flowName = getFlow?.name ?? '';
  const flowStatus = getFlow?.status ?? '';
  const config = getFlow?.config ?? '';
  const topFlowId = getFlow?.paymentFlowId ?? '';
  const minimumPlanRequired = getFlow?.minimumPlanRequired ?? '';
  const isInstruct = getFlow?.instruct;

  const isDataReady = Boolean(!loading && getFlow);
  const steps = currentConfig?.steps;
  const firstStepId = currentConfig?.firstStep?.id;
  const errorCount = getFlow?.errorCount;
  const flowInstanceCount = getFlow?.flowInstanceCount;
  const publishedData = facadeStepsToNodes(getFlow?.config?.steps);
  const changedNodes = useMemo(() => nodes?.filter((node) => node.data?.changed)?.map(({ id }) => id) || [], [nodes]);

  const selectedNode = nodes.find(({ id }) => selectedNodeId === id);
  const selectedNodeData = selectedNode?.data;
  const findNodeDataById = useCallback((stepId) => nodes.find(({ id }) => stepId === id)?.data, [nodes]);
  const setPreCommitFunction = useCallback((fn) => {
    preCommitFunction.current = fn;
  }, []);
  const { stepLibraryData, stepLibraryError, stepLibraryLoading, stepLibraryRefetch } = useFlowStepLibrary(flowId);
  const { refetchConfigurationIssues } = useConfigurationIssues({ flowId, changedNodes, setConfigurationErrors });
  const { saveConfiguration, isDataSaving, saveConfigurationAsync } = useSaveConfiguration({
    flowId,
    initialState,
    setNodes,
    setChangesMade,
    selectedNodeData,
    nodeDataToSave,
    setNodeDataToSave,
    setDraftConfig,
    setCurrentConfig,
    setConfigurationErrors,
    selectedNodeId,
    refetchConfigurationIssues,
  });
  const setElementsAndSave = useSetElementsAndSave({ setNodes, setEdges, saveConfiguration });
  const onConnect = useOnConnect(setElementsAndSave);

  useEffect(() => {
    if (pushedStart) {
      setNodes((prevNodes) => prevNodes.map((node) => ({ ...node, data: { ...node.data, changed: true } })));
    }
  }, [pushedStart]);

  useEffect(() => {
    setCurrentConfig(versionId ? versionData?.getFlowConfiguration : getConfig(getFlow));
  }, [getFlow, versionData?.getFlowConfiguration, versionId]);

  useEffect(() => {
    setDraftConfig(getFlow?.draftConfig ?? '');
  }, [getFlow, data]);

  useEffect(() => {
    if (isDataReady) {
      const elements = facadeStepsToNodes(steps);
      if (elements.find((el) => isNode(el) && !el.position)) {
        const layoutedElements = getLayoutedElements(elements);
        setNodes(filterNodes(layoutedElements));
        setEdges(filterEdges(layoutedElements));
      } else {
        setNodes(filterNodes(elements));
        setEdges(filterEdges(elements));
      }
      setInitialState(currentConfig?.initialState);
    }
  }, [currentConfig?.initialState, data, isDataReady, steps]);

  useEffect(() => {
    if (!errors) return;
    NotificationManager.error(
      <ToastCustomContainer message="errors.errorFetchingData" title="Oops.." />,
      null,
      TOAST_TIMEOUT
    );
  }, [t, errors]);

  const onDrop = useCallback(
    async (event) => {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const newNodeDataStr = event.dataTransfer.getData('application/new-node-data');
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left - 104,
        y: event.clientY - reactFlowBounds.top - 80,
      });
      const newNodeData = JSON.parse(newNodeDataStr);
      const newElements = getNewElements(newNodeData, position);
      if (newNodeData.trigger) {
        const alreadyHasTrigger = nodes.some((el) => el?.data?.trigger);
        if (alreadyHasTrigger) {
          return NotificationManager.error(
            <ToastCustomContainer
              message="You already have a trigger defined. Please delete the trigger and add your new trigger."
              title="Error!"
            />
          );
        }
      }
      const fallbackEdges = [...edges];
      const fallbackNodes = [...nodes];
      try {
        const newNodes = filterNodes([...nodes, ...newElements]);
        const newEdges = filterEdges([...edges, ...newElements]);
        setNodes(newNodes);
        setEdges(newEdges);
        return await saveConfigurationAsync(newNodes);
      } catch (e) {
        setNodes(fallbackNodes);
        setEdges(fallbackEdges);
        return NotificationManager.error(<ToastCustomContainer message={e.message} title="Error!" />);
      }
    },
    [edges, nodes, reactFlowInstance, saveConfigurationAsync]
  );

  const nodeSuccessfullySaved = (id) => {
    setSavedNodeId(id);
    const timer = setTimeout(() => {
      setSavedNodeId(null);
      clearTimeout(timer);
    }, [3000]);
  };

  const commitNodeDataToSave = useCallback(
    // eslint-disable-next-line consistent-return
    async (event, noClose) => {
      const newNodes = nodes.map((node) => {
        if (node.id !== nodeDataToSave?.id) {
          return node;
        }
        let nodeData = {};
        if (isFunction(preCommitFunction.current) && !noClose) {
          nodeData = preCommitFunction.current(node.data);
          preCommitFunction.current = null;
        }
        if (nodeDataToSave?.inputMappings?.length) {
          // filter inputMappings to match only current element's inputs
          const inputMappings = intersectionWith(
            nodeDataToSave?.inputMappings,
            selectedInputs,
            (inputMap, input) => inputMap?.inputId === input?.id
          );
          return { ...node, data: { ...nodeDataToSave, ...nodeData, inputMappings } };
        }
        return { ...node, data: { ...nodeDataToSave, ...nodeData } };
      });
      try {
        setNodes(newNodes);
        setEdges(filterEdges(repopulateEdges(newNodes)));
        const savedData = await saveConfigurationAsync(newNodes);
        const newSteps = savedData?.saveConfiguration?.steps;
        const savedNode = newSteps?.find(({ id }) => id === selectedNodeId);
        const isNodeConnected = getIsNodeConnected(selectedNodeId, edges);
        const isConnectionNotConnected = savedNode?.connectionStatus === CONNECTION_STATUS.NOT_CONNECTED;
        setCurrentValidate(selectedNodeId);
        setTimeout(() => {
          setCurrentValidate(null);
        }, 2000);
        if (savedNode?.subLabel && isNodeConnected && !isConnectionNotConnected) {
          nodeSuccessfullySaved(savedNode?.id);
        }
      } catch (e) {
        return NotificationManager.error(<ToastCustomContainer message={e.message} title="Error!" />);
      }
      if (!noClose) {
        setSelectedNodeId(null);
      }
    },
    [edges, nodeDataToSave, nodes, saveConfigurationAsync, selectedInputs, selectedNodeId]
  );

  const openConnectionModal = useCallback(({ types }) => {
    setNewConnectionTypes(types);
    setIsOpenConnectionModal(true);
  }, []);

  const closeConnectionModal = useCallback(
    async (e, shouldRefetch) => {
      setIsOpenConnectionModal(false);
      if (shouldRefetch) {
        await stepLibraryRefetch();
      }
    },
    [stepLibraryRefetch]
  );

  const context = useMemo(
    () => ({
      flowId,
      flowName,
      flowStatus,
      config,
      draftConfig,
      topFlowId,
      minimumPlanRequired,
      isInstruct,
      loading: loading || versionLoading,
      publishedData,
      refetch,
      nodes,
      setNodes,
      edges,
      setEdges,
      changesMade,
      setChangesMade,
      initialState,
      selectedNodeId,
      selectedNode,
      selectedNodeData,
      setSelectedNodeId,
      firstStepId,
      reactFlowWrapper,
      reactFlowInstance,
      setReactFlowInstance,
      setElementsAndSave,
      setInitialState,
      setHoverNodeId,
      hoverNodeId,
      connectingNodeData,
      setConnectingNodeData,
      isUpdatingEdgeTarget,
      setIsUpdatingEdgeTarget,
      onConnect,
      drawerStatus,
      toggleDrawerStatus,
      isValidateOn,
      setIsValidateOn,
      saveConfigurationAsync,
      nodeDataToSave,
      setNodeDataToSave,
      isDataSaving,
      onDrop,
      commitNodeDataToSave,
      findNodeDataById,
      selectedInputs,
      setSelectedInputs,
      preCommitFunction,
      setPreCommitFunction,
      isShowUpgradeModal,
      toggleShowUpgradeModal,
      errorCount,
      flowInstanceCount,
      isOpenConnectionModal,
      setIsOpenConnectionModal,
      openConnectionModal,
      closeConnectionModal,
      stepLibraryData,
      stepLibraryError,
      stepLibraryLoading,
      stepLibraryRefetch,
      isShowVideoModal,
      setIsShowVideoModal,
      videoModalUrl,
      setVideoModalUrl,
      newConnectionTypes,
      setNewConnectionTypes,
      messagesModalId,
      setMessagesModalId,
      versionHistory,
      versionHistoryLoading,
      savedNodeId,
      setSavedNodeId,
      isFlowEditor,
      setIsFlowEditor,
      selectedConditionEdgeNumber,
      setSelectedConditionEdgeNumber,
      getRemoveNodeConfirmation,
      configurationErrors,
      currentValidate,
      setCurrentValidate,
      refetchConfigurationIssues,
      setPushedStart,
      setDraftConfig,
    }),
    [
      flowId,
      flowName,
      flowStatus,
      config,
      draftConfig,
      topFlowId,
      minimumPlanRequired,
      isInstruct,
      loading,
      versionLoading,
      publishedData,
      refetch,
      nodes,
      edges,
      changesMade,
      initialState,
      selectedNodeId,
      selectedNode,
      selectedNodeData,
      firstStepId,
      reactFlowInstance,
      setElementsAndSave,
      hoverNodeId,
      connectingNodeData,
      isUpdatingEdgeTarget,
      onConnect,
      drawerStatus,
      toggleDrawerStatus,
      isValidateOn,
      saveConfigurationAsync,
      nodeDataToSave,
      isDataSaving,
      onDrop,
      commitNodeDataToSave,
      findNodeDataById,
      selectedInputs,
      setPreCommitFunction,
      isShowUpgradeModal,
      toggleShowUpgradeModal,
      errorCount,
      flowInstanceCount,
      isOpenConnectionModal,
      openConnectionModal,
      closeConnectionModal,
      stepLibraryData,
      stepLibraryError,
      stepLibraryLoading,
      stepLibraryRefetch,
      isShowVideoModal,
      videoModalUrl,
      newConnectionTypes,
      messagesModalId,
      versionHistory,
      versionHistoryLoading,
      savedNodeId,
      isFlowEditor,
      selectedConditionEdgeNumber,
      getRemoveNodeConfirmation,
      configurationErrors,
      currentValidate,
      setCurrentValidate,
      refetchConfigurationIssues,
      setPushedStart,
      setDraftConfig,
    ]
  );

  return {
    context,
    nodes,
    setNodes,
    edges,
    setEdges,
    isInstruct,
    topFlowId,
    flowName,
    flowStatus,
    isDataReady,
    initialState,
    setInitialState,
    publishedData,
    firstStepId,
    refetch,
    config,
    draftConfig,
    loading: loading || versionLoading,
    onDrop,
    onConnect,
    commitNodeDataToSave,
    minimumPlanRequired,
    errorCount,
    reactFlowWrapper,
    reactFlowInstance,
    setReactFlowInstance,
    flowInstanceCount,
    toggleShowUpgradeModal,
    changesMade,
    setChangesMade,
    setConnectingNodeData,
    setElementsAndSave,
    setIsUpdatingEdgeTarget,
    setPreCommitFunction,
    selectedNodeId,
    setSelectedNodeId,
    setIsValidateOn,
    isDataSaving,
    isShowUpgradeModal,
    isOpenConnectionModal,
    closeConnectionModal,
    newConnectionTypes,
    isShowVideoModal,
    setIsShowVideoModal,
    videoModalUrl,
    hoverNodeId,
    setHoverNodeId,
    messagesModalId,
    savedNodeId,
    setSavedNodeId,
    isFlowEditor,
    setIsFlowEditor,
    selectedConditionEdgeNumber,
    setSelectedConditionEdgeNumber,
    renderConfirmationModal,
    getRemoveNodeConfirmation,
  };
};
