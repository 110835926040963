import React from 'react';

const Merchant = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="4" fill="#B2B2FF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8524 12.3894L16.3584 15.3537C16.3578 15.3571 16.3572 15.3605 16.3566 15.3639C16.2866 15.7488 15.9179 16.0041 15.533 15.9341L7.48005 14.47C7.2147 14.4217 6.99978 14.227 6.92568 13.9676L5.63312 9.44367C5.61504 9.38039 5.60587 9.31489 5.60587 9.24908C5.60587 8.85787 5.923 8.54074 6.3142 8.54074H16.0367L16.471 6.67928C16.5593 6.30102 16.8965 6.03342 17.2849 6.03342H18.7475C19.2091 6.03342 19.5833 6.40761 19.5833 6.86919C19.5833 7.33078 19.2091 7.70497 18.7475 7.70497H17.9481L16.8524 12.3894Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.60416 18.875C8.01735 18.875 7.54166 18.3993 7.54166 17.8125C7.54166 17.2257 8.01735 16.75 8.60416 16.75C9.19096 16.75 9.66666 17.2257 9.66666 17.8125C9.66666 18.3993 9.19096 18.875 8.60416 18.875ZM14.9792 18.875C14.3924 18.875 13.9167 18.3993 13.9167 17.8125C13.9167 17.2257 14.3924 16.75 14.9792 16.75C15.566 16.75 16.0417 17.2257 16.0417 17.8125C16.0417 18.3993 15.566 18.875 14.9792 18.875Z"
      fill="black"
    />
  </svg>
);

export default Merchant;
