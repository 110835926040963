import styled from 'styled-components';
import THEME from '../../../../../../../constants/theme';

const StyledFooter = styled.div`
  height: 72px;
  border-top: 1px solid ${THEME.greyColors.grey16};
  padding: 16px 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: ${THEME.primaryColors.white};
`;

export { StyledFooter };
