import styled from 'styled-components';
import THEME from '../../../constants/theme';

const StyledHeaderWrapper = styled.div`
  padding: 16px 56px;

  @media (min-width: ${THEME.breakPoints.desktop}px) {
    padding-left: 76px;
  }
`;

export { StyledHeaderWrapper };
