import React from 'react';
import { string } from 'prop-types';
import THEME from '../../../constants/theme';

const CheckIcon = ({ width, height, fill }) => {
  return (
    <svg
      className="check-icon"
      width={width}
      height={height}
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8047 0.528636C12.0651 0.788986 12.0651 1.2111 11.8047 1.47145L4.4714 8.80478C4.21106 9.06513 3.78894 9.06513 3.5286 8.80478L0.195262 5.47145C-0.0650874 5.2111 -0.0650874 4.78899 0.195262 4.52864C0.455612 4.26829 0.877722 4.26829 1.13807 4.52864L4 7.39057L10.8619 0.528636C11.1223 0.268287 11.5444 0.268287 11.8047 0.528636Z"
        fill={fill}
      />
    </svg>
  );
};

CheckIcon.propTypes = {
  fill: string,
  width: string,
  height: string,
};

CheckIcon.defaultProps = {
  fill: THEME.primaryColors.primary,
  width: '12',
  height: '9',
};

export default CheckIcon;
