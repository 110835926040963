import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';

import OnboardingLayout from '../../components/layouts/OnboardingLayout';
import OnboardingLayoutBox from '../../components/layouts/OnboardingLayoutBox';
import SignUpPaymentProcessorForm from '../../components/forms/SignUpPaymentProcessorForm';
import { useHandleRegistrationFlowRedirect } from '../../hooks/registration';
// import StepIndicatorOnboarding from '../../components/common/StepIndicatorOnboarding/StepIndicatorOnboarding';

const Fallback = () => (
  <Box display="flex" justifyContent="center">
    <CircularProgress size={24} />
  </Box>
);

const SignUpPaymentProcessor = () => {
  const { t } = useTranslation();
  useHandleRegistrationFlowRedirect();

  return (
    <OnboardingLayout theme="dark">
      <OnboardingLayoutBox theme="dark">
        <Box mb="40px">
          {/* <StepIndicatorOnboarding currentStep={4} stepsCount={9} /> */}
          <h1>{t('Have you selected a payment processor yet?')}</h1>
        </Box>
        <React.Suspense fallback={<Fallback />}>
          <SignUpPaymentProcessorForm />
        </React.Suspense>
      </OnboardingLayoutBox>
    </OnboardingLayout>
  );
};

export default SignUpPaymentProcessor;
