import React from 'react';
import { Box } from '@mui/material';
import Automations from './Automations/Automations';
import { usePaymentFlowContext } from '../../FlowDetailsPage/paymentFlowContext';
import { useGlobalContext } from '../../../containers/App/context';
import HeroSection from '../../GettingStarted/HeroSection';

const Overview = () => {
  const { getMeData } = useGlobalContext();
  const { flow, loading } = usePaymentFlowContext();

  const instructFlowId = flow?.instructFlowId;

  return (
    <Box mb="40px">
      {!(getMeData?.we?.acceptingPayments === 'YES') && !loading && !instructFlowId && (
        <Box mb="40px">
          <HeroSection />
        </Box>
      )}
      <Automations />
    </Box>
  );
};

export default Overview;
