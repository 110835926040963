export const DISPUTES_SORT_KEYS = {
  processor_status: 'processor_status',
  disputed_on: 'dispute_date',
  respond_by: 'response_date',
  source_id: 'source_id',
  customer_id: 'customer_id',
  team_id: 'team_id',
  payment_id: 'payment_id',
  processor_name: 'processor',
};
