import React from 'react';
import { bool, func, number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MessagesWrapper } from '../styled';
import THEME from '../../../../../constants/theme';
import { P12M } from '../../../../atoms/Typography/P12M';

const Messages = ({ handleClick, newMessages, commentsQty }) => {
  const { t } = useTranslation();

  return (
    <MessagesWrapper
      backgroundColor={newMessages ? THEME.primaryColors.primary : THEME.primaryColors.grey24}
      onMouseDown={(event) => {
        event.stopPropagation();
      }}
      onClick={handleClick}
    >
      <P12M color={newMessages ? THEME.primaryColors.white : THEME.greyColors.grey800}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {commentsQty > 0
          ? commentsQty === 1
            ? `${commentsQty} ${t('comment')}`
            : `${commentsQty} ${t('comments')}`
          : t('Add Comment')}
      </P12M>
    </MessagesWrapper>
  );
};

Messages.propTypes = {
  handleClick: func.isRequired,
  newMessages: bool.isRequired,
  commentsQty: number,
};

Messages.defaultProps = {
  commentsQty: 0,
};

export default Messages;
