import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import Box from '@mui/material/Box';
import { useMutation } from '@apollo/client';
import { NotificationManager } from 'react-notifications';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  StyledPaper,
} from '../../../../components/Dialog/styled';
import { usePaymentsDetailsContext } from '../../../DataTables/PaymentDetails/PaymentDetailsContext';

import FormControl from '../../../../components/forms/_common/FormControl';
import { validationSchema } from './formSettings';
import { CANCEL_PAYMENT } from '../../../../utils/queries/payments/paymentsMutation';
import SuccessScreen from './SuccessScreen';
import ReasonInput from './ReasonInput';
import { isDefined } from '../../../../utils/helpers';
import CloseButton from '../../../../components/Dialog/CloseButton';
import ToastCustomContainer from '../../../../components/ToastCustomContainer';

const ID = 'cancel-payment';
const TOAST_TIMEOUT = 5000;

const CancelDialog = ({ isOpen, toggleIsOpen, amount }) => {
  const { t } = useTranslation();
  const [successScreen, setSuccessScreen] = useState(false);

  const [cancelPaymentInternal] = useMutation(CANCEL_PAYMENT, {
    context: { skipGlobalHandling: true },
  });
  const { pageContentRefetch } = usePaymentsDetailsContext();

  const params = useParams();
  const toggleSuccessScreen = useCallback(() => {
    setSuccessScreen((prevState) => !prevState);
  }, []);

  const onSubmit = useCallback(
    ({ reason }, actions) => {
      cancelPaymentInternal({
        variables: {
          id: params?.detailsId,
          reason,
        },
      })
        .then((res) => {
          if (!isEmpty(res?.errors)) {
            NotificationManager.error(
              <ToastCustomContainer
                message={res.errors[0]?.message?.replaceAll(':', '_')} //react-i18next parses ":" and only returns the text after
                title="Cancel Payment Error."
              />,
              null,
              TOAST_TIMEOUT
            );

            return;
          }

          if (isDefined(res?.data?.cancelPaymentInternal?.id)) {
            toggleSuccessScreen();
            pageContentRefetch();
          }
        })
        .catch((e) => {
          return NotificationManager.error(
            <ToastCustomContainer message={e?.message} title={e?.name} />,
            null,
            TOAST_TIMEOUT
          );
        })
        .finally(() => {
          actions.setSubmitting(false);
          actions.resetForm();
        });
    },
    [pageContentRefetch, cancelPaymentInternal, params, toggleSuccessScreen]
  );

  return (
    <StyledDialog
      open={isOpen}
      scroll="paper"
      maxWidth="xs"
      PaperComponent={StyledPaper}
      onClose={toggleIsOpen}
      aria-labelledby={ID}
      width="496px"
    >
      <CloseButton onClick={toggleIsOpen} />

      <SuccessScreen showSuccess={successScreen} dialogToggleIsOpen={toggleIsOpen} />

      <Formik
        initialValues={{ reason: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnBlur
        enableReinitialize
      >
        <Form>
          <StyledDialogTitle id={`${ID}-title`} disableTypography>
            {t('Cancel Payment')}
          </StyledDialogTitle>
          <StyledDialogContent>
            <Box mt="16px" pb="12px">
              {t('Are you sure you want to cancel this payment for')} {amount}?
            </Box>
            <Box pb="24px" mt="16px">
              <ReasonInput />
            </Box>
          </StyledDialogContent>

          <StyledDialogActions>
            <FormControl toggleIsOpen={toggleIsOpen} primaryText={t('yes')} secondaryText={t('no')} />
          </StyledDialogActions>
        </Form>
      </Formik>
    </StyledDialog>
  );
};

CancelDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  amount: PropTypes.string,
  toggleIsOpen: PropTypes.func.isRequired,
};

CancelDialog.defaultProps = {
  amount: undefined,
};

export default CancelDialog;
