import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import LoadingState from '../../../FlowEditor/components/AutomationDetails/LoadingState';
import { StyledAutomationBox, StyledAutomationsContentBox, StyledFormControlLabel } from '../../styled';
import { L14B } from '../../../atoms/Typography/L14B';
import { L10UM } from '../../../atoms/Typography/L10UM';
import THEME from '../../../../constants/theme';
import { CheckboxOnboarding } from '../../../atoms';

const AutomationsContent = ({ automations, selectedAutomationIds, loading, onSelect }) => {
  const { t } = useTranslation();
  const isChecked = (value) => (selectedAutomationIds ?? []).includes(value);

  return (
    <StyledAutomationsContentBox>
      <LoadingState loading={loading}>
        {automations.map(({ id: value, name, instruct }) => (
          <StyledAutomationBox key={value} $checked={isChecked(value)}>
            <StyledFormControlLabel
              key={value}
              value={value}
              control={<Checkbox value={value} name={value} hidden />}
              label={
                <>
                  <Box display="flex" alignItems="center">
                    <L14B maxWidth="300px" overflow="hidden" textOverflow="ellipsis" noWrap>
                      {name}
                    </L14B>
                    {instruct && (
                      <L10UM
                        margin="0 0 0 12px"
                        color={THEME.primaryColors.primary}
                        textTransform="uppercase !important"
                      >
                        {t('Payment Processing')}
                      </L10UM>
                    )}
                  </Box>
                  <CheckboxOnboarding
                    transform="scale(1.5) translateX(-0.4px)"
                    width="20px"
                    height="20px"
                    value={value}
                    name={value}
                    checked={isChecked(value)}
                    onChange={onSelect}
                  />
                </>
              }
              checked={isChecked(value)}
              onChange={onSelect}
            />
          </StyledAutomationBox>
        ))}
      </LoadingState>
    </StyledAutomationsContentBox>
  );
};

AutomationsContent.propTypes = {
  automations: arrayOf(shape({})),
  selectedAutomationIds: arrayOf(string),
  loading: bool,
  onSelect: func,
};

AutomationsContent.defaultProps = {
  automations: [],
  selectedAutomationIds: [],
  loading: false,
  onSelect: () => {},
};

export default AutomationsContent;
