import { useEffect, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { SEARCH_DISPUTES_GQL } from '../../utils/queries/disputes/searchInDisputes';
import { useNotificationManager } from '../useNotificationManager';
import { getPageInfo } from '../useGetPaymentList/getPageInfo';
import { getRows } from '../useGetPaymentList/getRows';
import { transformNodeToRow } from '../../pages/DataTables/Disputes/tableData';

const useGetDisputesListWithSearch = ({ variables }) => {
  const { t } = useTranslation();
  const [searchPromise, { loading, error, data }] = useMutation(SEARCH_DISPUTES_GQL);
  useNotificationManager('error', error?.message, t('Disputes Search'), 5000);

  useEffect(() => {
    const { input, searchFilter } = variables;
    if (!input && isEmpty(searchFilter)) {
      return undefined;
    }

    let controller = null;
    const timerId = setTimeout(() => {
      controller = window.AbortController && new AbortController();
      const options = {
        variables,
        ...(controller && { context: { fetchOptions: { signal: controller.signal } } }),
      };

      searchPromise(options).finally(() => {
        controller = null;
        clearTimeout(timerId);
      });
    }, 300);

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }

      if (controller) {
        controller.abort();
      }
    };
  }, [variables, searchPromise]);

  const edges = data?.searchInDisputes?.edges;
  const pageInfoRef = data?.searchInDisputes?.pageInfo;
  const availableKnownValues = data?.searchInDisputes?.availableKnownValues;

  const rows = useMemo(() => getRows({ edges, transformFn: transformNodeToRow }), [edges]);
  const pageInfo = useMemo(() => getPageInfo({ pageInfo: pageInfoRef }), [pageInfoRef]);

  return {
    loading,
    error,
    rows,
    availableKnownValues,
    source: 'SEARCH',
    pageInfo: pageInfo,
    totalSize: pageInfoRef?.totalSize,
  };
};

export { useGetDisputesListWithSearch };
