import React from 'react';
import Box from '@mui/material/Box';
import { P16B, P14, ButtonRounded } from '../../../../components/atoms';
import { StyledWrapper } from './styled';

const devZoneUrl = process.env.REACT_APP_DEV_ZONE_URL;

const BLURBS = [
  {
    title: 'Learn about API authentication',
    description: 'Learn more about using public keys, private keys and client tokens when using our APIs.',
    link: { text: 'Learn more', to: `${devZoneUrl}/api-reference/authentication` },
  },
  {
    title: 'Explore our API Documentation',
    description: "Explore WhenThen's API documentation to build fast and flexible payment logic.",
    link: { text: 'Learn more', to: `${devZoneUrl}/api-reference` },
  },
];

const DeveloperBlurbs = () => {
  return (
    <StyledWrapper>
      {BLURBS.map(({ title, description, link }) => (
        <Box
          key={title}
          bgcolor="#F5F6F7"
          borderRadius="6px"
          p="24px"
          minHeight="180px"
          flex="1"
          display="flex"
          flexDirection="column"
        >
          <P16B>{title}</P16B>
          <P14 color="#787F88" margin="5px 0 0 0">
            {description}
          </P14>

          <Box mt="auto" mb="-10px">
            <ButtonRounded component="a" href={link.to} target="_blank" rel="noopener noreferrer" color="primary">
              {link?.text}
            </ButtonRounded>
          </Box>
        </Box>
      ))}
    </StyledWrapper>
  );
};

export default DeveloperBlurbs;
