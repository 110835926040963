import React from 'react';
import { string, shape } from 'prop-types';
import NodeBase from '../NodeBase';
import { Retry } from './Retry';

const RetryNode = ({ id, data }) => {
  return (
    <NodeBase id={id}>
      <Retry stepId={data.targetId} />
    </NodeBase>
  );
};

RetryNode.propTypes = {
  id: string.isRequired,
  data: shape({
    targetId: string,
  }),
};

RetryNode.defaultProps = {
  data: {
    targetId: '',
  },
};

export { RetryNode };
