const formNames = {
  productsServices: 'productsServices',
  productsServicesOther: 'productsServicesOther',
};

const formFields = [{ field: formNames.productsServices, label: '' }];

const initialValues = {
  [formNames.productsServices]: [],
};

export { initialValues, formFields, formNames };
