import React, { useCallback } from 'react';
import { string, oneOf, func, shape, bool } from 'prop-types';
import { useField } from 'formik';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { InputField, P } from '../../atoms';
import THEME from '../../../constants/theme';
import { StyledError, StyledLabel } from './styled';
import StyledTooltip from '../../atoms/StyledTooltip';

const Input = ({
  name,
  type,
  label,
  customLabel,
  customLabelProps,
  normalize,
  normalizeProps,
  customErrorProps,
  skipError,
  width,
  margin,
  tooltip,
  isRequired,
  requiredLabel, // For dispute form where (optional) or (required) label also added to the label
  disabled,
  ...rest
}) => {
  const [{ value, onBlur }, { touched, error }, { setValue }] = useField(name);
  const { t } = useTranslation();
  const handleOnChange = useCallback(
    (e) => {
      const { value: _value } = e.target;

      if (normalize) {
        setValue(normalize(_value, normalizeProps));
        return;
      }
      setValue(_value);
    },
    [setValue, normalize, normalizeProps]
  );

  return (
    <Box margin={margin ?? '0 0 16px 0'} width={width}>
      <StyledLabel>
        {customLabel && (
          <Box display="flex" justifyContent="flex-start" alignItems="center" position="relative">
            <P
              fontSize="12px !important"
              fontWeight={600}
              {...(!tooltip && { width: '100%' })}
              textAlign="left"
              lineHeight="30px"
              {...(customLabelProps && customLabelProps)}
            >
              {t(label)}
              {(requiredLabel || isRequired) && <span className="required">*</span>}
            </P>

            {tooltip && <StyledTooltip title={tooltip} />}
          </Box>
        )}

        <InputField
          {...rest}
          variant="outlined"
          type={type}
          autoComplete="new-password"
          name={name}
          label={!customLabel ? label : null}
          value={value}
          disabled={disabled}
          onChange={handleOnChange}
          onBlur={onBlur}
        />
      </StyledLabel>

      {touched && !skipError && Array.isArray(error)
        ? error?.map((message) => (
            <StyledError
              key={message}
              data-cy="error"
              color={THEME.secondaryColors.danger}
              {...(customErrorProps && customErrorProps)}
            >
              {t(message)}
            </StyledError>
          ))
        : touched &&
          !skipError && (
            <StyledError
              data-cy="error"
              color={THEME.secondaryColors.danger}
              {...(customErrorProps && customErrorProps)}
            >
              {t(error)}
            </StyledError>
          )}
    </Box>
  );
};

Input.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  customLabel: bool,
  type: oneOf(['text', 'password', 'email']).isRequired,
  customLabelProps: shape({}),
  normalize: func,
  customErrorProps: shape({}),
  normalizeProps: shape({}),
  skipError: bool,
  width: string,
  margin: string,
  tooltip: string,
  requiredLabel: string,
  isRequired: bool,
  disabled: bool,
};

Input.defaultProps = {
  customLabel: null,
  customLabelProps: {},
  normalize: undefined,
  customErrorProps: {},
  normalizeProps: {},
  skipError: false,
  width: undefined,
  margin: undefined,
  tooltip: '',
  requiredLabel: '',
  isRequired: false,
  disabled: false,
};

export default Input;
