import styled from 'styled-components';
import { Box, TableBody, TableCell, TableRow } from '@mui/material';
import THEME from '../../../constants/theme';

export const StyledWrapper = styled(Box)`
  margin-top: 16px;
  overflow: visible;
  position: relative;
  border: 1px solid ${THEME.greyColors.grey200};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-width: 1040px;
`;

export const StyledTableBody = styled(TableBody)`
  & .MuiTableRow-root:first-child {
    & .MuiTableCell-root {
      padding-top: 28px;
    }
  }

  & .MuiTableRow-root:last-child {
    & .MuiTableCell-root {
      padding-bottom: 28px;
    }
  }
`;

export const StyledTableRow = styled(TableRow)`
  &.MuiTableRow-head {
    border-bottom: 1px solid ${THEME.greyColors.grey200};
  }
`;

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    border: none;
    padding: 22px 16px;

    &:first-of-type {
      padding-left: 24px;
    }

    &:last-of-type {
      padding-right: 24px;
    }

    &.MuiTableCell-head {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
`;
