import React from 'react';
import { Box } from '@mui/material';
import OnboardingLayout from '../../components/layouts/OnboardingLayout';
import OnboardingLayoutBox from '../../components/layouts/OnboardingLayoutBox';
import ForgotPasswordForm from '../../components/forms/ForgotPasswordForm';

const ForgotPassword = () => (
  <OnboardingLayout>
    <OnboardingLayoutBox>
      <Box maxWidth="336px" margin="0 auto">
        <ForgotPasswordForm />
      </Box>
    </OnboardingLayoutBox>
  </OnboardingLayout>
);

export default ForgotPassword;
