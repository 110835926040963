import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { func } from 'prop-types';
import Box from '@mui/material/Box';
import { initialValues, fields } from './schema';
import Input from '../../../../components/forms/_common/Input';
import TagInput from '../../../../components/forms/_common/TagInput';
import { Wrapper, GroupsWrapper, GroupContainer } from './styled';
import { CountrySelectAlpha2 } from '../../../DataTables/components/FormComponents/CountrySelect';
import BusinessTypeSelect from '../../../../components/forms/selects/BusinessTypeSelect';
import SectorTypeSelect from '../../../../components/forms/selects/SectorTypeSelect';
import MerchantCategoryAutocomplete from '../../../../components/forms/selects/MerchantCategoryAutocomplete';
import { useGlobalContext } from '../../../../containers/App/context';
import { P16B } from '../../../../components/atoms';
import { countryList } from '../../../../constants/countryList';

const COMPONENT_MAP = {
  input: Input,
  countrySelect: CountrySelectAlpha2,
  tagInput: TagInput,
  businessTypeSelect: BusinessTypeSelect,
  sectorTypeSelect: SectorTypeSelect,
  merchantCategoryAutocomplete: MerchantCategoryAutocomplete,
};

const transformToAlpha2 = (country) => {
  if (!country) return '';

  if (country && country.length > 2) {
    return countryList.find((item) => country === item.name)['alpha-2'];
  }

  return country;
};

const CustomerDetailsForm = () => {
  const { t } = useTranslation();
  const {
    getMeData: { we },
  } = useGlobalContext();

  const initialValuesMapping = Object.keys(initialValues).reduce(
    (acc, curr) => {
      if (we[curr] && (typeof we[curr] === 'string' || Array.isArray(we[curr]))) {
        acc[curr] = we[curr];
      }

      return acc;
    },
    { ...initialValues }
  );

  const updatedInitialValues = {
    ...initialValuesMapping,
    businessType: initialValuesMapping.businessType || '',
    sectorType: initialValuesMapping.sectorType || '',
    currencyCode: we?.currency?.id,
    addressLine1: we?.billingDetails?.billingAddress?.line1,
    addressLine2: we?.billingDetails?.billingAddress?.line2,
    city: we?.billingDetails?.billingAddress?.locality,
    country: transformToAlpha2(we?.billingDetails?.billingAddress?.country),
    state: we?.billingDetails?.billingAddress?.region,
    billingEmail: we?.otherBillingEmails[0],
    taxId: we?.billingDetails?.taxId,
  };

  return (
    <Wrapper>
      <Formik initialValues={updatedInitialValues}>
        <Form>
          <GroupsWrapper>
            {fields.map(({ id, groups }) => {
              return (
                <Box key={id} display="flex" flexDirection="column" flex="1">
                  {groups.map(({ title, inputs }) => {
                    return (
                      <GroupContainer key={title}>
                        <P16B margin="0 0 8px 0">{t(title)}</P16B>

                        {inputs.map(({ field, type, componentType, label, isRequired, tooltip }) => {
                          const Component = COMPONENT_MAP[componentType] ?? COMPONENT_MAP.input;
                          const isDropdownComponent = componentType.includes('Select');

                          const componentProps = {
                            name: field,
                            type,
                            label,
                            isRequired,
                            disabled: true,
                          };

                          return (
                            <React.Fragment key={label}>
                              <Component
                                {...componentProps}
                                tooltip={tooltip}
                                margin="0 0 12px 0"
                                {...(isDropdownComponent && {
                                  labelHeight: '30px',
                                })}
                                {...(componentType === 'input' && { customLabel: true })}
                              />
                            </React.Fragment>
                          );
                        })}
                      </GroupContainer>
                    );
                  })}
                </Box>
              );
            })}
          </GroupsWrapper>
        </Form>
      </Formik>
    </Wrapper>
  );
};

CustomerDetailsForm.propTypes = {
  setIncorrectLoginCount: func,
};

CustomerDetailsForm.defaultProps = {
  setIncorrectLoginCount: () => {},
};
export default CustomerDetailsForm;
