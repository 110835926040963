import Box from '@mui/material/Box';
import { bool, func, shape, string } from 'prop-types';
import React from 'react';
import { DragHandleIcon } from '../../../../../../assets/icons';
import { PAYMENT_METHOD_ICON_MAP } from '../../../../../../assets/icons/PaymentMethods';
import THEME from '../../../../../../constants/theme';
import { CustomSwitch, P14M } from '../../../../../atoms';
import { StyledBox, StyledPaymentItemBox } from './styled';

const PAYMENT_METHOD_LABELS = {
  FINICITY_ACH: 'ACH (via Finicity)',
  CARD: 'Credit/Debit Card',
  AIIA: 'aiia',
  APPLE_PAY: 'Apple Pay',
  GOOGLE_PAY: 'Google Pay',
  KLARNA: 'Klarna',
  PAYPAL: 'PayPal',
  OPENBANKING: 'Open Banking',
};

const PaymentMethodWithSwitch = ({ provided, item, handleSwitch, checked }) => {
  const Icon = PAYMENT_METHOD_ICON_MAP[item] ?? PAYMENT_METHOD_ICON_MAP.UNKNOWN_CARD;
  return (
    <StyledPaymentItemBox
      {...(provided && { ...provided.draggableProps, ...provided.dragHandleProps })}
      ref={provided?.innerRef}
    >
      <StyledBox>
        <Icon />
        <P14M margin="0 0 0 14px">{PAYMENT_METHOD_LABELS[item]}</P14M>
      </StyledBox>
      <Box display="flex" alignItems="center">
        <CustomSwitch onClick={() => handleSwitch(!checked, item)} checked={checked} />
        <Box className="dragHandler" display="flex" alignItems="center" ml="8px">
          <DragHandleIcon fill={THEME.greyColors.grey1} />
        </Box>
      </Box>
    </StyledPaymentItemBox>
  );
};

PaymentMethodWithSwitch.propTypes = {
  provided: shape({}),
  snapshot: shape({}),
  item: string.isRequired,
  handleSwitch: func.isRequired,
  checked: bool.isRequired,
};

PaymentMethodWithSwitch.defaultProps = {
  provided: null,
  snapshot: null,
};

export default PaymentMethodWithSwitch;
