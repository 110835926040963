import styled from 'styled-components';
import Paper from '@mui/material/Paper';

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    background-color: #f5f6f7;
  }
`;

export { StyledPaper };
