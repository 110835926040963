import React from 'react';
import PropTypes from 'prop-types';

const Cart = ({ size }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66667 3.33341C2.66667 2.96523 2.96515 2.66675 3.33334 2.66675H5.33334C5.59046 2.66675 5.82468 2.81462 5.93523 3.04677L6.70672 4.66675H16.6667C16.8689 4.66675 17.0601 4.75853 17.1867 4.91626C17.3132 5.07399 17.3613 5.28063 17.3175 5.47803L16.1953 10.5275C16.0066 11.3769 15.2905 12.0067 14.4241 12.0855L9.18994 12.5613C8.21317 12.6501 7.31613 12.0175 7.07188 11.0676L5.6562 5.56186L4.91242 4.00008H3.33334C2.96515 4.00008 2.66667 3.7016 2.66667 3.33341ZM7.14558 6.00008L8.36321 10.7355C8.44462 11.0522 8.74367 11.2631 9.0692 11.2335L14.3033 10.7576C14.5921 10.7313 14.8309 10.5214 14.8937 10.2383L15.8356 6.00008H7.14558Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.33333 17.3333C8.4379 17.3333 9.33333 16.4379 9.33333 15.3333C9.33333 14.2287 8.4379 13.3333 7.33333 13.3333C6.22876 13.3333 5.33333 14.2287 5.33333 15.3333C5.33333 16.4379 6.22876 17.3333 7.33333 17.3333ZM7.33333 15.9999C7.70151 15.9999 7.99999 15.7015 7.99999 15.3333C7.99999 14.9651 7.70151 14.6666 7.33333 14.6666C6.96514 14.6666 6.66666 14.9651 6.66666 15.3333C6.66666 15.7015 6.96514 15.9999 7.33333 15.9999Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6667 15.3333C16.6667 16.4379 15.7713 17.3333 14.6667 17.3333C13.5621 17.3333 12.6667 16.4379 12.6667 15.3333C12.6667 14.2287 13.5621 13.3333 14.6667 13.3333C15.7713 13.3333 16.6667 14.2287 16.6667 15.3333ZM15.3333 15.3333C15.3333 15.7015 15.0349 15.9999 14.6667 15.9999C14.2985 15.9999 14 15.7015 14 15.3333C14 14.9651 14.2985 14.6666 14.6667 14.6666C15.0349 14.6666 15.3333 14.9651 15.3333 15.3333Z"
      fill="white"
    />
  </svg>
);

Cart.propTypes = {
  size: PropTypes.number,
};

Cart.defaultProps = {
  size: 20,
};

export default Cart;
