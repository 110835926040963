import * as Yup from 'yup';
import buildValidator from '../../../../../../utils/validators/buildValidator';
import { DISPUT_FORM_INPUT_TYPE } from '../constant';

const validationSchema = (type, requiredMessage) => {
  const validationType = type === DISPUT_FORM_INPUT_TYPE.file ? Yup.mixed() : Yup.string();
  return validationType.required(requiredMessage);
};

const generateDynamicValidator = (form) => {
  if (form) {
    const requiredFields = form?.filter((field) => field.required);
    const requiredFieldsObject = requiredFields.reduce((obj, curr) => {
      const { id: key, name, type } = curr;
      const requiredMessage = `${name} is required`;

      return { ...obj, [key]: validationSchema(type, requiredMessage) };
    }, {});

    const validator = Yup.object().shape(requiredFieldsObject);

    return buildValidator(validator);
  }

  return {};
};

export default generateDynamicValidator;
