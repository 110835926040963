import React from 'react';

const Contact = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="15.5" fill="white" stroke="#E6E9EC" />
      <path
        d="M12.6667 14.0002C12.6667 13.632 12.9652 13.3335 13.3334 13.3335H18.6667C19.0349 13.3335 19.3334 13.632 19.3334 14.0002C19.3334 14.3684 19.0349 14.6668 18.6667 14.6668H13.3334C12.9652 14.6668 12.6667 14.3684 12.6667 14.0002Z"
        fill="#4E40EF"
      />
      <path
        d="M13.3334 16C12.9652 16 12.6667 16.2985 12.6667 16.6667C12.6667 17.0349 12.9652 17.3333 13.3334 17.3333H16.0001C16.3683 17.3333 16.6667 17.0349 16.6667 16.6667C16.6667 16.2985 16.3683 16 16.0001 16H13.3334Z"
        fill="#4E40EF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.91627 10.6011C11.0207 9.3461 12.9262 8.6665 16.0001 8.6665C19.0739 8.6665 20.9795 9.3461 22.0839 10.6011C23.1759 11.8421 23.3334 13.4851 23.3334 14.9998C23.3334 16.518 23.1718 18.1036 22.3949 19.3176C21.6195 20.5292 20.3141 21.2536 18.338 21.327L17.0667 23.022C16.5334 23.7332 15.4667 23.7332 14.9334 23.022L13.6621 21.327C11.6861 21.2536 10.3807 20.5292 9.60523 19.3176C8.82835 18.1036 8.66675 16.518 8.66675 14.9998C8.66675 13.4851 8.82423 11.8421 9.91627 10.6011ZM10.9172 11.4819C10.1759 12.3243 10.0001 13.5146 10.0001 14.9998C10.0001 16.4816 10.1718 17.7294 10.7283 18.5988C11.2427 19.4027 12.1663 19.9998 14.0001 19.9998C14.2099 19.9998 14.4075 20.0986 14.5334 20.2665L16.0001 22.222L17.4667 20.2665C17.5927 20.0986 17.7902 19.9998 18.0001 19.9998C19.8338 19.9998 20.7574 19.4027 21.2719 18.5988C21.8283 17.7294 22.0001 16.4816 22.0001 14.9998C22.0001 13.5146 21.8242 12.3243 21.0829 11.4819C20.354 10.6536 18.9262 9.99984 16.0001 9.99984C13.0739 9.99984 11.6462 10.6536 10.9172 11.4819Z"
        fill="#4E40EF"
      />
    </svg>
  );
};

export default Contact;
