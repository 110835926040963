import React from 'react';

const ShieldCheck = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.676 3.18C8.77119 3.70497 6.8793 4.2758 5.00201 4.892C4.83059 4.94753 4.67821 5.05009 4.56223 5.18799C4.44625 5.32589 4.37133 5.4936 4.34601 5.672C3.23801 13.986 5.79801 20.052 8.85201 24.048C10.145 25.7568 11.6868 27.2622 13.426 28.514C14.118 29.002 14.73 29.354 15.212 29.58C15.452 29.694 15.648 29.77 15.798 29.816C15.8636 29.839 15.9312 29.8558 16 29.866C16.068 29.855 16.1349 29.8382 16.2 29.816C16.352 29.77 16.548 29.694 16.788 29.58C17.268 29.354 17.882 29 18.574 28.514C20.3132 27.2621 21.855 25.7568 23.148 24.048C26.202 20.054 28.762 13.986 27.654 5.672C27.6289 5.49351 27.5541 5.3257 27.4381 5.18776C27.3221 5.04982 27.1695 4.94732 26.998 4.892C25.696 4.466 23.498 3.772 21.324 3.182C19.104 2.58 17.062 2.134 16 2.134C14.94 2.134 12.896 2.58 10.676 3.182V3.18ZM10.144 1.12C12.314 0.53 14.62 0 16 0C17.38 0 19.686 0.53 21.856 1.12C24.076 1.72 26.314 2.43 27.63 2.86C28.1802 3.0417 28.668 3.37493 29.0374 3.8214C29.4067 4.26787 29.6426 4.80948 29.718 5.384C30.91 14.338 28.144 20.974 24.788 25.364C23.3649 27.242 21.668 28.8957 19.754 30.27C19.0922 30.7456 18.391 31.1637 17.658 31.52C17.098 31.784 16.496 32 16 32C15.504 32 14.904 31.784 14.342 31.52C13.609 31.1638 12.9078 30.7456 12.246 30.27C10.3321 28.8957 8.63517 27.2419 7.21201 25.364C3.85601 20.974 1.09001 14.338 2.28201 5.384C2.35739 4.80948 2.5933 4.26787 2.96264 3.8214C3.33199 3.37493 3.81979 3.0417 4.37001 2.86C6.28039 2.23362 8.20562 1.65345 10.144 1.12Z"
      fill="black"
    />
    <path
      d="M21.7078 10.2938C21.8009 10.3867 21.8748 10.497 21.9252 10.6185C21.9756 10.74 22.0016 10.8703 22.0016 11.0018C22.0016 11.1333 21.9756 11.2636 21.9252 11.3851C21.8748 11.5065 21.8009 11.6169 21.7078 11.7098L15.7078 17.7098C15.6149 17.8029 15.5046 17.8768 15.3831 17.9272C15.2616 17.9776 15.1313 18.0036 14.9998 18.0036C14.8683 18.0036 14.738 17.9776 14.6165 17.9272C14.495 17.8768 14.3847 17.8029 14.2918 17.7098L11.2918 14.7098C11.1988 14.6168 11.1251 14.5064 11.0748 14.385C11.0244 14.2635 10.9985 14.1333 10.9985 14.0018C10.9985 13.8703 11.0244 13.7401 11.0748 13.6186C11.1251 13.4971 11.1988 13.3868 11.2918 13.2938C11.3848 13.2008 11.4952 13.1271 11.6166 13.0767C11.7381 13.0264 11.8683 13.0005 11.9998 13.0005C12.1313 13.0005 12.2615 13.0264 12.383 13.0767C12.5044 13.1271 12.6148 13.2008 12.7078 13.2938L14.9998 15.5878L20.2918 10.2938C20.3847 10.2007 20.495 10.1268 20.6165 10.0764C20.738 10.0259 20.8683 10 20.9998 10C21.1313 10 21.2616 10.0259 21.3831 10.0764C21.5046 10.1268 21.6149 10.2007 21.7078 10.2938Z"
      fill="black"
    />
  </svg>
);

export default ShieldCheck;
