import React from 'react';

const Slack = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="15.5" fill="white" stroke="#E6E9EC" />
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2216 9.3335C13.4846 9.33404 12.8881 9.93225 12.8886 10.6694C12.8881 11.4065 13.4851 12.0047 14.2222 12.0053H15.5557V10.6699C15.5562 9.9328 14.9592 9.33459 14.2216 9.3335C14.2222 9.3335 14.2222 9.3335 14.2216 9.3335V9.3335ZM14.2216 12.8966H10.6666C9.92951 12.8971 9.33246 13.4954 9.33301 14.2325C9.33192 14.9696 9.92897 15.5678 10.666 15.5689H14.2216C14.9587 15.5684 15.5557 14.9702 15.5552 14.233C15.5557 13.4954 14.9587 12.8971 14.2216 12.8966Z"
          fill="#36C5F0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.6665 14.2325C22.667 13.4954 22.07 12.8971 21.3329 12.8966C20.5959 12.8971 19.9988 13.4954 19.9994 14.2325V15.5689H21.3329C22.07 15.5684 22.667 14.9702 22.6665 14.2325ZM19.1109 14.2325V10.6694C19.1114 9.9328 18.5149 9.33459 17.7779 9.3335C17.0408 9.33404 16.4438 9.93225 16.4443 10.6694V14.2325C16.4432 14.9696 17.0403 15.5678 17.7773 15.5689C18.5144 15.5684 19.1114 14.9702 19.1109 14.2325Z"
          fill="#2EB67D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.7774 22.6949C18.5145 22.6944 19.1115 22.0962 19.111 21.3591C19.1115 20.6219 18.5145 20.0237 17.7774 20.0232H16.4438V21.3591C16.4433 22.0956 17.0404 22.6939 17.7774 22.6949ZM17.7774 19.1313H21.333C22.0701 19.1308 22.6671 18.5326 22.6666 17.7954C22.6676 17.0583 22.0706 16.4601 21.3335 16.459H17.7779C17.0409 16.4595 16.4438 17.0577 16.4444 17.7949C16.4438 18.5326 17.0404 19.1308 17.7774 19.1313Z"
          fill="#ECB22E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.33325 17.7954C9.33271 18.5325 9.92975 19.1307 10.6668 19.1313C11.4039 19.1307 12.0009 18.5325 12.0004 17.7954V16.4595H10.6668C9.92975 16.46 9.33271 17.0582 9.33325 17.7954ZM12.8888 17.7954V21.3585C12.8878 22.0956 13.4848 22.6939 14.2218 22.6949C14.9589 22.6944 15.5559 22.0962 15.5554 21.359V17.7965C15.5565 17.0593 14.9594 16.4611 14.2224 16.46C13.4848 16.46 12.8883 17.0582 12.8888 17.7954C12.8888 17.7954 12.8888 17.7959 12.8888 17.7954Z"
          fill="#E01E5A"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="13.3333" height="13.3616" fill="white" transform="translate(9.33325 9.3335)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Slack;
