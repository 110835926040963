import React from 'react';
import { string, bool, node } from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LayoutWrapper, Header, Content, NavigationRight } from './styled';
import { Logo, ButtonRounded } from '../../atoms';
import { UI_ROUTES } from '../../../constants/routes';
import { useGlobalContext } from '../../../containers/App/context';

const OnboardingLayout = ({ children, theme, image, fullPage, navigationRight, ...restProps }) => {
  const { t } = useTranslation();
  const { logOut, isLogin } = useGlobalContext();
  return (
    <LayoutWrapper {...restProps} theme={theme}>
      <Header>
        <Link to={UI_ROUTES.signIn}>
          <Logo />
        </Link>
        {navigationRight && <NavigationRight>{navigationRight}</NavigationRight>}
        {isLogin && (
          <ButtonRounded type="button" variant="contained" color="primary" onClick={() => logOut()}>
            {t('Logout')}
          </ButtonRounded>
        )}
      </Header>
      <Content>{children}</Content>
    </LayoutWrapper>
  );
};

OnboardingLayout.propTypes = {
  theme: string,
  fullPage: bool,
  image: node,
  navigationRight: node,
  children: node.isRequired,
};

OnboardingLayout.defaultProps = {
  theme: 'light',
  fullPage: false,
  image: null,
  navigationRight: null,
};

export default OnboardingLayout;
