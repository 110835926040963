import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import { flowStepTypes } from '../../../types';
import { rightOperandShownArray } from '../AutomationStep/fields/Test/constants';
import { NONE } from '../../../../forms/_common/Select';

export const getIsRulesConfigured = (nodeDataToSave) =>
  every(
    nodeDataToSave?.__typename === flowStepTypes.IfElseStep &&
      nodeDataToSave?.tests?.map(({ left, op, right }) =>
        rightOperandShownArray.includes(op)
          ? !isEmpty(left) && left !== NONE
          : !isEmpty(left) && left !== NONE && !isEmpty(right) && right !== NONE
      )
  );
