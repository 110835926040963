import React from 'react';
import { string, bool } from 'prop-types';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import capitalize from '@mui/material/utils/capitalize';
import Title from '../../../Title';
import SelectPropertyInput from './SelectPropertyInput';
import TextPropertyInput from './TextPropertyInput';
import EmailMessageInput from './EmailMessageInput';
import ActionInputProvider from './ActionInputProvider';
import LookupInput from './LookupInput';
import UserOrGroupInput from './UserOrGroupInput';
import ConnectionInputField from '../ConnectionInputField';
import SubTitle from '../../../SubTitle';
import { CONNECTION_STATUS } from '../../../../../../../pages/ConnectionsPage/components/constant';
import { useNodeDataToSave } from '../hooks/useNodeDataToSave';
import ReadOnly from './ReadOnly';
import StyledTooltip from '../../../../../../atoms/StyledTooltip';

const INPUTS = {
  EMAIL_MESSAGE: EmailMessageInput,
  ID: SelectPropertyInput,
  LOOKUP: LookupInput,
  USER_OR_GROUP_LIST: UserOrGroupInput,
};

const ActionInputField = ({
  fieldId,
  fieldName,
  fieldType,
  placeholder,
  validationMessage,
  required,
  selectedActionType,
  subTitle,
  tooltip,
  readOnly,
}) => {
  const { t } = useTranslation();
  const Input = INPUTS[fieldType] || TextPropertyInput;
  const [{ connectionStatus }] = useNodeDataToSave();
  const isLookupNotConnected = fieldType === 'LOOKUP' && connectionStatus === CONNECTION_STATUS.NOT_CONNECTED;

  return (
    <ActionInputProvider
      fieldId={fieldId}
      fieldName={fieldName}
      fieldType={fieldType}
      placeholder={placeholder}
      validationMessage={validationMessage}
    >
      {fieldType === 'CONNECTION_ID_PAYMENT_GATEWAY' ? (
        <ConnectionInputField />
      ) : (
        <Box component="section" width="100%">
          <Title display="flex">
            <Box display="flex" flexGrow="1" alignItems="center">
              <Box component="span">
                {isLookupNotConnected && <>Connect app to configure </>}
                {t(capitalize(fieldName?.toLowerCase()) ?? '')}
              </Box>
              {tooltip && <StyledTooltip title={tooltip} />}
            </Box>

            {required && (
              <Box component="span" color="#4e40ef">
                *
              </Box>
            )}
          </Title>
          {subTitle && <SubTitle>{t(subTitle)}</SubTitle>}
          {readOnly ? <ReadOnly value={placeholder} /> : <Input selectedActionType={selectedActionType} />}
        </Box>
      )}
    </ActionInputProvider>
  );
};

ActionInputField.propTypes = {
  fieldId: string.isRequired,
  fieldName: string.isRequired,
  fieldType: string.isRequired,
  placeholder: string,
  validationMessage: string,
  required: bool,
  selectedActionType: string,
  subTitle: string,
  tooltip: string,
  readOnly: bool,
};

ActionInputField.defaultProps = {
  validationMessage: '',
  required: false,
  selectedActionType: null,
  placeholder: null,
  subTitle: null,
  tooltip: null,
  readOnly: false,
};

export default ActionInputField;
