import React, { useMemo, useEffect, useCallback } from 'react';
import { string } from 'prop-types';
import { Background, ConnectionLineType, ReactFlowProvider } from 'react-flow-renderer';
import { useToggle } from 'react-use';
import Box from '@mui/material/Box';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AutomationDetails } from './components/AutomationDetails';
import { NodeLibrary } from './components/NodeLibrary';
import FlowEmpty from './components/FlowEmpty';
import { FlowEditorContextProvider } from './context';
import { CanvasContainer, StyledReactFlow } from './styled';
import { CircularLoader } from '../atoms';
import { SavingIndicator } from './components/SavingIndicator';
import { nodeTypesMap } from './utils/nodeTypesMap';
import { edgeTypes } from './utils/edgeTypes';
import { useEditorData } from './hooks/useEditorData';
import { FlowHeader } from '../FlowHeader';
import PremiumPreviewDialog from '../../pages/AutomationTemplatePage/PremiumPreviewDialog';
import { useGlobalContext } from '../../containers/App/context';
import { EXCEPTION_DICTIONARY } from '../../pages/AutomationTemplatePage/constant';
import NewConnectionModal from '../../pages/FlowDetailsPage/FlowSettings/PaymentProcessorPage/ConnectedSection/NewConnectionModal';
import useIsDemo from '../../hooks/useIsDemo';
import SetupGuide from './components/SetupGuide';
import { StyledIconButton } from './components/SetupGuide/styled';
import THEME from '../../constants/theme';
import { useOnEdgeUpdate } from './hooks/useOnEdgeUpdate';
import { useOnNodesRemove } from './hooks/useOnNodesRemove';
import { getElementsToRemove } from './utils/getElementsToRemove';
import { useOnEdgesRemove } from './hooks/useOnEdgesRemove';
import { repopulateEdges } from './utils/facades';
import { flowStepTypes } from './types';
import AnnotationVideoModal from './components/AnnotationVideoModal';
import { useLocalStorageState } from '../../hooks/storage';
import { SETUP_GUIDE_KEY } from './constants';
import { getFlowNodes } from './utils/getFlowNodes';

const onDragOver = (event) => {
  event.preventDefault();
  // eslint-disable-next-line no-param-reassign
  event.dataTransfer.dropEffect = 'move';
};

const PLAN_MAP = ['business_individual', 'business_team', 'enterprise'];

const FlowEditor = ({ flowId }) => {
  const {
    context,
    nodes,
    edges,
    isDataReady,
    publishedData,
    loading,
    reactFlowWrapper,
    setReactFlowInstance,
    onDrop,
    minimumPlanRequired,
    toggleShowUpgradeModal,
    onConnect,
    setConnectingNodeData,
    setElementsAndSave,
    setIsUpdatingEdgeTarget,
    selectedNodeId,
    setSelectedNodeId,
    setIsValidateOn,
    isDataSaving,
    isShowUpgradeModal,
    isOpenConnectionModal,
    newConnectionTypes,
    closeConnectionModal,
    isShowVideoModal,
    setIsShowVideoModal,
    videoModalUrl,
    isInstruct,
    messagesModalId,
    getRemoveNodeConfirmation,
    renderConfirmationModal,
  } = useEditorData(flowId);

  const { getMeData } = useGlobalContext();
  const isDemo = useIsDemo();
  const activePlan = getMeData?.we?.activePlan?.plan?.uiCode;
  const [setupGuideDefaultState] = useLocalStorageState(SETUP_GUIDE_KEY, false);
  const [setupGuide, toggleSetupGuide] = useToggle(!setupGuideDefaultState);

  useEffect(() => {
    if (PLAN_MAP.indexOf(minimumPlanRequired) > PLAN_MAP.indexOf(activePlan) && !loading) {
      toggleShowUpgradeModal();
    }
  }, [minimumPlanRequired, activePlan, loading, toggleShowUpgradeModal]);

  const isEmpty = isDataReady && nodes.length === 0;

  const onConnectHandle = (connection) => {
    if (connection?.source !== connection?.target) {
      onConnect(connection);
    }
  };

  const onConnectStart = (_, nodeData) => {
    setConnectingNodeData(nodeData);
  };
  const onConnectEnd = () => setConnectingNodeData(null);
  const onEdgeUpdate = useOnEdgeUpdate(setElementsAndSave);
  const onEdgeUpdateStart = (_, nodeData, handleType) => {
    setIsUpdatingEdgeTarget(handleType === 'source' ? !!nodeData : false);
  };
  const onEdgeUpdateEnd = () => setIsUpdatingEdgeTarget(null);
  const onNodesRemove = useOnNodesRemove(getRemoveNodeConfirmation);
  const onEdgesRemove = useOnEdgesRemove();
  const onNodesChange = useCallback(
    (changes) => {
      const nodesToRemove = getElementsToRemove(changes, nodes);
      if (nodesToRemove?.length) {
        onNodesRemove({ nodesToRemove, selectedNodeId, setSelectedNodeId, setElementsAndSave });
      }
    },
    [nodes, onNodesRemove, selectedNodeId, setElementsAndSave, setSelectedNodeId]
  );

  const onEdgesChange = useCallback(
    (changes) => {
      const edgesToRemove = getElementsToRemove(changes, edges);
      if (edgesToRemove?.length) {
        onEdgesRemove({ edgesToRemove, onConnect });
      }
    },
    [edges, onConnect, onEdgesRemove]
  );

  const onNodeClick = (_, { id, data }) => {
    if (data?.__typename !== flowStepTypes.AnnotationStep) {
      setSelectedNodeId(id);
    }
  };

  const onNodeDragStop = (_, node) => {
    const newNodes = nodes.map((nd) => (nd.id === node.id ? { ...nd, position: node.position } : nd));
    setElementsAndSave(repopulateEdges(newNodes), true);
  };

  const onSelectionDragStop = (_, selectedNodes) => {
    const updatedNodes = nodes.reduce((acc, node) => {
      const updatedNode = selectedNodes.find((selectedNode) => node.id === selectedNode.id);
      return updatedNode ? [...acc, { ...node, position: updatedNode.position }] : [...acc, node];
    }, []);
    setElementsAndSave(repopulateEdges(updatedNodes));
  };

  const reactFlowNodes = useMemo(() => getFlowNodes(nodes), [nodes]);

  return (
    <FlowEditorContextProvider value={context}>
      <ReactFlowProvider>
        <CanvasContainer ref={reactFlowWrapper}>
          <NodeLibrary />
          <AutomationDetails />
          <FlowHeader flowId={flowId} publishedData={publishedData} onValidate={() => setIsValidateOn(true)} />
          {!isDataReady && (
            <Box position="absolute" left="50%" top="45%">
              <CircularLoader />
            </Box>
          )}
          <SavingIndicator isDataSaving={isDataSaving} />
          <StyledReactFlow
            onInit={setReactFlowInstance}
            defaultNodes={reactFlowNodes}
            defaultEdges={edges}
            nodeTypes={nodeTypesMap}
            edgeTypes={edgeTypes}
            onConnect={isDemo ? null : onConnectHandle}
            onConnectStart={onConnectStart}
            onConnectEnd={onConnectEnd}
            onEdgeUpdate={isDemo ? null : onEdgeUpdate}
            onEdgeUpdateStart={onEdgeUpdateStart}
            onEdgeUpdateEnd={onEdgeUpdateEnd}
            nodesDraggable={!isDemo}
            onNodeClick={onNodeClick}
            onNodesChange={isDemo ? null : onNodesChange}
            onEdgesChange={isDemo ? null : onEdgesChange}
            onNodeDragStart={(e) => e.stopPropagation()}
            onNodeDragStop={onNodeDragStop}
            onDragOver={isDemo ? null : onDragOver}
            onDrop={isDemo ? null : onDrop}
            onSelectionDragStop={onSelectionDragStop}
            connectionLineType={ConnectionLineType.SmoothStep}
            minZoom={0.5}
            maxZoom={2}
            $selectedId={messagesModalId}
          >
            <Background variant="dots" gap={24} size={0.9} color={THEME.greyColors.grey8} />
          </StyledReactFlow>
        </CanvasContainer>
        {flowId && isInstruct && (
          <Box
            borderRadius="8px"
            boxShadow="0px 0px 16px rgba(0, 0, 0, 0.04)"
            bgcolor={THEME.primaryColors.white}
            p="8px"
            position="absolute"
            right={24}
            bottom={24}
            zIndex={10}
          >
            <StyledIconButton
              backgroundColor={setupGuide && THEME.greyColors.greyButton}
              $activeColor={THEME.greyColors.greyButton}
              onClick={toggleSetupGuide}
            >
              <InfoOutlinedIcon />
            </StyledIconButton>
          </Box>
        )}
        {setupGuide && flowId && isInstruct && <SetupGuide closeModal={toggleSetupGuide} />}
      </ReactFlowProvider>
      {isShowUpgradeModal && (
        <PremiumPreviewDialog contentType={EXCEPTION_DICTIONARY.upgradePlan} toggleIsOpen={toggleShowUpgradeModal} />
      )}
      {isOpenConnectionModal && (
        <NewConnectionModal
          isOpen={isOpenConnectionModal}
          closeModal={closeConnectionModal}
          types={newConnectionTypes}
        />
      )}
      {isShowVideoModal && !!videoModalUrl && (
        <AnnotationVideoModal open={isShowVideoModal} setOpen={setIsShowVideoModal} videoUrl={videoModalUrl} />
      )}
      {renderConfirmationModal()}
      {isEmpty && <FlowEmpty />}
    </FlowEditorContextProvider>
  );
};

FlowEditor.propTypes = {
  flowId: string,
};
FlowEditor.defaultProps = {
  flowId: null,
};

export { FlowEditor };
