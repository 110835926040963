import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { GET_CONFIGURATION_ISSUES } from '../../../utils/queries/flows/queries';

export const useConfigurationIssues = ({ flowId, changedNodes, setConfigurationErrors }) => {
  const { data, refetch } = useQuery(GET_CONFIGURATION_ISSUES, { variables: { flowId, stepIds: changedNodes } });

  useEffect(() => {
    setConfigurationErrors(data?.getConfigurationIssues);
  }, [data?.getConfigurationIssues, setConfigurationErrors]);

  return { refetchConfigurationIssues: refetch };
};
