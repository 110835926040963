import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { string, arrayOf, shape, bool, func, oneOfType } from 'prop-types';
import Tag from '../Tag';
import { generateLabel } from '../constant';
import useSearch from '../../../../../../hooks/useSearch';
import ChevronDown2 from '../../../../../../assets/icons/ChevronDown2';
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyleAccordionDetails,
  StyledFilterBodyTags,
  AppCategory,
  SelectedFiltersWrapper,
  SelectedFiltersWrapperInner,
} from './styled';
import { L14M } from '../../../../../../components/atoms';

const Filter = ({ label, items, isApps, activeFilter, setActiveFilter, selectedFilters, setSelectedFilters }) => {
  const [searchParams] = useSearch();
  const isActive = activeFilter === label;
  const showSelectedFilters = selectedFilters[label]?.length > 0;

  const handleToggle = () => {
    setActiveFilter(!isActive ? label : null);
  };

  useEffect(() => {
    if (!isEmpty(searchParams.filters)) {
      const filtersKeys = Object.keys(searchParams.filters);
      const filters = filtersKeys.reduce((obj, key) => {
        // eslint-disable-next-line no-param-reassign
        obj = { ...obj, [key]: searchParams.filters[key].split(',') };

        return obj;
      }, {});

      setSelectedFilters(filters);
    }
  }, [searchParams, setSelectedFilters]);

  return (
    <>
      <StyledAccordion
        expanded={isActive}
        onChange={handleToggle}
        className={showSelectedFilters ? 'showSelectedFilters' : ''}
      >
        <StyledAccordionSummary expandIcon={<ChevronDown2 />} aria-controls={`${label}-content`} id={`${label}-header`}>
          <L14M>{label}</L14M>
        </StyledAccordionSummary>

        <StyleAccordionDetails>
          <StyledFilterBodyTags>
            {isApps &&
              items.map(({ subLabel, data }) => (
                <AppCategory key={subLabel}>
                  <p>{generateLabel(subLabel, true)}</p>

                  {data.sort().map((tag) => (
                    <Tag
                      key={tag}
                      label={label}
                      tag={tag}
                      selectedFilters={selectedFilters}
                      setSelectedFilters={setSelectedFilters}
                    />
                  ))}
                </AppCategory>
              ))}

            {!isApps &&
              items.sort().map((tag) => {
                return (
                  <Tag
                    key={tag}
                    label={label}
                    tag={tag}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                  />
                );
              })}
          </StyledFilterBodyTags>
        </StyleAccordionDetails>
      </StyledAccordion>

      {showSelectedFilters && (
        <SelectedFiltersWrapper>
          <SelectedFiltersWrapperInner className={isActive ? 'close' : ''}>
            {selectedFilters[label]?.sort().map((tag, index) => {
              return (
                <Tag
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${tag}-${index}`}
                  label={label}
                  tag={tag}
                  selectedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                  persistent
                />
              );
            })}
          </SelectedFiltersWrapperInner>
        </SelectedFiltersWrapper>
      )}
    </>
  );
};

Filter.propTypes = {
  label: string.isRequired,
  items: arrayOf(oneOfType([string, shape({})])).isRequired,
  isApps: bool.isRequired,
  activeFilter: string,
  setActiveFilter: func.isRequired,
  selectedFilters: shape({}).isRequired,
  setSelectedFilters: func.isRequired,
};

Filter.defaultProps = {
  activeFilter: null,
};

export default Filter;
