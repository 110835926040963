import React, { useEffect, useState } from 'react';
import { string, arrayOf, shape, number } from 'prop-types';
import Group from './Group';
import { StyledColumn } from './styled';
import Item from './Item';
import { usePropertyTextEditorContext } from '../context';
import { propertyObjectTypes } from './types';

const Column = ({ groups, items, nestingLevel }) => {
  const {
    setSelectedPropertyObject,
    selectedPathArr,
    setSelectedPathArr,
    selectedPropertyObject,
  } = usePropertyTextEditorContext();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedItem, setSelectedItem] = useState(selectedPropertyObject);
  // only the last element of the path array can be an ITEM, rest of elements are GROUPS
  const foundGroup = groups?.find((group) =>
    selectedPathArr?.length - 1 === nestingLevel ? null : group.label === selectedPathArr?.[nestingLevel]
  );
  const foundItem = items?.find((item) =>
    selectedPathArr?.length - 1 === nestingLevel ? item.label === selectedPathArr?.[nestingLevel] : null
  );

  useEffect(() => {
    if (selectedGroup) {
      setSelectedPropertyObject(selectedGroup);
    }
  }, [selectedGroup, setSelectedPropertyObject]);

  useEffect(() => {
    if (selectedItem && selectedItem.type === propertyObjectTypes.ITEM) {
      setSelectedPropertyObject(selectedItem);
    }
  }, [selectedItem, setSelectedPathArr, setSelectedPropertyObject]);

  useEffect(() => {
    if (foundItem) {
      setSelectedItem(foundItem);
    }
  }, [foundItem, setSelectedItem]);

  useEffect(() => {
    if (foundGroup) {
      setSelectedGroup(foundGroup);
    }
  }, [foundGroup, setSelectedGroup]);

  const onSelectGroup = (group) => {
    setSelectedGroup(group);
    setSelectedItem(null);
  };

  const onSelectItem = (item) => {
    setSelectedItem(item);
    setSelectedGroup(null);
  };

  return (
    <>
      <StyledColumn>
        {groups?.map((group) => (
          <Group
            key={group.label}
            icon={group.icon}
            label={group.label}
            stepGroup={group.stepGroup}
            nickname={group.subLabel}
            selected={group?.label === selectedGroup?.label}
            onSelect={() => onSelectGroup(group)}
            nestingLevel={nestingLevel}
          />
        ))}
        {items?.map((item) => (
          <Item
            key={item.key}
            label={item.label}
            selected={item.key === selectedItem?.key}
            onSelect={() => onSelectItem(item)}
          />
        ))}
      </StyledColumn>
      {selectedGroup?.subgroups || selectedGroup?.items ? (
        <Column
          key={selectedGroup?.label}
          groups={selectedGroup.subgroups}
          items={selectedGroup.items}
          nestingLevel={nestingLevel + 1}
        />
      ) : null}
    </>
  );
};

Column.propTypes = {
  groups: arrayOf(
    shape({
      icon: shape({
        key: string,
        url: string,
      }),
      label: string,
      type: string,
    })
  ),
  items: arrayOf(
    shape({
      key: string,
      label: string,
      path: string,
      type: string,
    })
  ),
  nestingLevel: number,
};

Column.defaultProps = {
  groups: [],
  items: [],
  nestingLevel: 0,
};

export default Column;
