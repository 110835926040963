import * as Yup from 'yup';
import { formNames } from './schema';

const arrayValidationSchema = (requiredMessage, minMessage) =>
  Yup.array()
    .of(Yup.string())
    .min(1, minMessage)
    .required(requiredMessage);

const stringValidationSchema = (requiredMessage) =>
  Yup.string()
    .trim()
    .required(requiredMessage);

export const validator = (showOtherInput) => {
  return Yup.object().shape({
    [formNames.paymentModel]: arrayValidationSchema('Revenue model is required', 'Revenue model is required'),
    ...(showOtherInput
      ? { [formNames.paymentModelOther]: stringValidationSchema('Please specify revenue model') }
      : {}),
  });
};
