import { saveGraphics } from '../constants';
import { useBrandCenterMutation } from '../../../../hooks/useBrandCenterMutation';

export const useBrandCenterEvents = ({ data, graphics, masterTemplate, socialNetworks }) => {
  const { mutateData } = useBrandCenterMutation();

  const handleButtonStyle = (e) => {
    mutateData(
      {
        ...data?.we?.brand,
        actionButton: e.target.value,
        socialNetworks,
        ...graphics,
        ...masterTemplate,
      },
      'Button Shape'
    );
  };

  const handleAccentColor = (color) => {
    mutateData(
      {
        ...data?.we?.brand,
        accentColor: color,
        socialNetworks,
        ...graphics,
        ...masterTemplate,
      },
      'Primary color'
    );
  };

  const handleGraphics = ({ uploadUrl, objectKey }, file, type) => {
    const toS3 = { uploadUrl, objectKey, file, type };
    const toBackend = { data, mutateData, socialNetworks };

    saveGraphics(toS3, toBackend, masterTemplate);
  };
  /* Disabled Feature - PT-1081 */
  /* const handleSocialEdit =
    (newArr) => {
      const filterType = () => newArr.map((el) => omit(el, ['__typename']));
      mutateData({
        ...data?.we?.brand,
        socialNetworks: filterType(),
        ...graphics,
        ...masterTemplate,
      });
    };

  const handleLogoConfig =
    ({ type, value }) => {
      mutateData({
        ...data?.we?.brand,
        socialNetworks,
        ...graphics,
        logoConfig: {
          ...masterTemplate.logoConfig,
          [type]: value.toUpperCase(),
        },
        footerText: masterTemplate.footerText,
        signOffContent: masterTemplate.signOffContent,
      });
    }

  const handleLogoText =
    (value) => {
      mutateData({
        ...data?.we?.brand,
        socialNetworks,
        ...graphics,
        logoConfig: {
          ...masterTemplate.logoConfig,
          logoText: value,
          logoType: 'TEXT',
        },
        footerText: masterTemplate.footerText,
        signOffContent: masterTemplate.signOffContent,
      });
    };

  const handleSaveFooterText =
    (parsedHTML) => {
      mutateData({
        ...data?.we?.brand,
        socialNetworks,
        ...graphics,
        logoConfig: {
          ...masterTemplate.logoConfig,
        },
        footerText: parsedHTML,
        signOffContent: masterTemplate.signOffContent,
      });
    };

  const handleSaveSignOffContent =
    (parsedHTML) => {
      mutateData({
        ...data?.we?.brand,
        socialNetworks,
        ...graphics,
        logoConfig: {
          ...masterTemplate.logoConfig,
        },
        footerText: masterTemplate.footerText,
        signOffContent: parsedHTML,
      });
    };
*/
  return {
    handleButtonStyle,
    handleAccentColor,
    handleGraphics,

    /* Disabled Feature */
    // handleSocialEdit,
    // handleLogoConfig,
    // handleSaveFooterText,
    // handleSaveSignOffContent,
    // handleLogoText,
  };
};
