import PropTypes from 'prop-types';
import React from 'react';
import ChevronDown from '../../../assets/icons/Select/ChevronDown';
import { StyledSelectIconWrapper } from './styled';
import Switch from '../../../assets/icons/Switch';
import THEME from '../../../constants/theme';

const MUI_SELECT_OPEN_CLASS_NAME = 'MuiSelect-iconOpen';

const SelectDropdownIcon = ({ className, switchIcon }) => {
  const isOpen = className?.includes(MUI_SELECT_OPEN_CLASS_NAME);

  return (
    <StyledSelectIconWrapper className={className} $isOpen={isOpen} $switchIcon={switchIcon}>
      {switchIcon ? <Switch fill={THEME.primaryColors.black} size={20} /> : <ChevronDown />}
    </StyledSelectIconWrapper>
  );
};

SelectDropdownIcon.propTypes = {
  className: PropTypes.string,
  switchIcon: PropTypes.bool,
};

SelectDropdownIcon.defaultProps = {
  className: null,
  switchIcon: false,
};

export default SelectDropdownIcon;
