import { useCallback, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { GET_CONFIG_FORM } from '../../../../components/WebhookConfigurationModal/queries';
import { TOAST_TYPES, TOAST_TIMEOUT } from '../../../../../../constants/toasts';
import { showNotification } from '../../../../../../hooks/useNotificationManager';
import { useGlobalContext } from '../../../../../../containers/App/context';
import { globalLoadingConst } from '../../../../../../constants/globalLoadingConsts';

const TOAST_TITLE = 'Configuration.';
const TOAST_MESSAGE = 'No further configuration is required.';

const useHandleViewConfiguration = (connection, setConfigForm, setShouldModalOpen) => {
  const { setGlobalLoading } = useGlobalContext();

  const [configForm, { loading }] = useLazyQuery(GET_CONFIG_FORM);

  const getConfigForm = useCallback(async () => {
    const { data } = await configForm({
      variables: {
        id: connection?.id,
      },
    });

    const formData = data?.getConfigForm?.form;

    if (isEmpty(formData)) {
      showNotification(TOAST_TYPES.info, TOAST_MESSAGE, TOAST_TITLE, TOAST_TIMEOUT);
    }

    setConfigForm(formData);
  }, [configForm, connection, setConfigForm]);

  const handleViewConfiguration = () => {
    getConfigForm();
    setShouldModalOpen(true);
  };

  useEffect(() => {
    if (loading) {
      setGlobalLoading(globalLoadingConst.webhookConfigurationForm, true);
    } else {
      setGlobalLoading(globalLoadingConst.webhookConfigurationForm, false);
    }
  }, [loading, setGlobalLoading]);

  return handleViewConfiguration;
};

export { useHandleViewConfiguration };
