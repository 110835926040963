export const DISPUTE_FIELDS = {
  amount: 'amount',
  reason: 'reason',
  status: 'status',
  connectionId: 'processor',
  disputeDate: 'disputed on',
  responseDate: 'respond by',

  // additional columns
  processorStatus: 'processor status',
  currency: 'currency',
  processor: 'processor name',
  sourceId: 'source id',
  paymentId: 'payment id',
  teamId: 'team id',
  id: 'id',
};
