import PropTypes from 'prop-types';
import React from 'react';
import ConnectionFees from './ConnectionFees';
import HistoricPayments from './HistoricPayments';
import PaymentMethods from './PaymentMethods';
import { StyledStatusWrapper, Left, Right } from './styled';
import { useFeature } from '../../../../../hooks/useFeature';
import { FEATURE_TOGGLES_KEYS } from '../../../../../constants/featureToggles';

const StatusTab = ({ connection }) => {
  const [multipleFlowEnabled] = useFeature(FEATURE_TOGGLES_KEYS.MULTIPLE_FLOW);
  const showHistoricPayments = !multipleFlowEnabled && connection.historicalPayments;
  const hidden = !multipleFlowEnabled && !showHistoricPayments;

  return (
    <StyledStatusWrapper>
      <Left hidden={hidden}>
        {showHistoricPayments && <HistoricPayments connectionId={connection.id} />}

        {multipleFlowEnabled && (
          <PaymentMethods
            connectionId={connection.id}
            supportedPaymentMethods={connection.supportedPaymentMethods}
            enabledPaymentMethods={connection.enabledPaymentMethods}
          />
        )}
      </Left>
      <Right>
        <ConnectionFees
          connectionId={connection.id}
          pricingUrl={connection.company?.pricingUrl}
          connectionName={connection.company?.name}
        />
        {multipleFlowEnabled && connection.historicalPayments && <HistoricPayments connectionId={connection.id} />}
      </Right>
    </StyledStatusWrapper>
  );
};

StatusTab.propTypes = {
  connection: PropTypes.shape({
    name: PropTypes.string,
    company: PropTypes.shape({
      name: PropTypes.string,
      pricingUrl: PropTypes.string,
    }),
    id: PropTypes.string,
    supportedPaymentMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
    enabledPaymentMethods: PropTypes.arrayOf(PropTypes.string),
    historicalPayments: PropTypes.bool.isRequired,
  }).isRequired,
};

export default StatusTab;
