import { useQuery } from '@apollo/client';
import { GET_VERSION_HISTORY_FOR_FLOW } from '../../../../utils/queries/flows/queries';

export const useGetVersionHistory = (flowId) => {
  const { data, loading } = useQuery(GET_VERSION_HISTORY_FOR_FLOW, {
    variables: {
      id: flowId,
    },
    skip: !flowId,
  });

  const versionHistory = data?.getVersionHistoryForFlow || [];

  return { versionHistory, loading };
};
