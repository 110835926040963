import React from 'react';
import { string } from 'prop-types';

const ApplePay = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="black" />
    <path
      d="M14.9372 4.00701C14.9058 3.97376 13.775 4.02013 12.791 5.0325C11.807 6.044 11.9584 7.20424 11.9806 7.23399C12.0027 7.26374 13.3836 7.31011 14.2652 6.13325C15.1467 4.95638 14.9686 4.04113 14.9372 4.00701ZM17.9963 14.2733C17.952 14.1893 15.8501 13.1936 16.0458 11.2791C16.2415 9.36373 17.592 8.83873 17.6132 8.78186C17.6344 8.72498 17.0621 8.09061 16.4556 7.76949C16.0104 7.54308 15.5176 7.41337 15.0129 7.38974C14.9132 7.38711 14.567 7.30661 13.8553 7.49124C13.3864 7.61286 12.3295 8.00661 12.0387 8.02236C11.747 8.03811 10.8793 7.56561 9.94609 7.44049C9.34886 7.33111 8.71563 7.55511 8.2624 7.72749C7.81009 7.89899 6.94978 8.38723 6.34793 9.68485C5.74608 10.9816 6.06085 13.0361 6.28609 13.6748C6.51132 14.3127 6.86301 15.3583 7.46117 16.1213C7.99286 16.9823 8.69809 17.5799 8.99255 17.7829C9.28702 17.9859 10.1178 18.1207 10.6938 17.8416C11.1572 17.5721 11.9935 17.4172 12.3239 17.4286C12.6535 17.4399 13.3033 17.5633 13.9689 17.9002C14.496 18.0726 14.9944 18.0008 15.4938 17.8083C15.9932 17.6149 16.716 16.8817 17.5597 15.3951C17.88 14.7038 18.0258 14.3302 17.9963 14.2733Z"
      fill="white"
    />
  </svg>
);

ApplePay.propTypes = {
  width: string,
  height: string,
};

ApplePay.defaultProps = {
  width: '24',
  height: '24',
};

export default ApplePay;
