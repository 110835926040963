import React, { useState } from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';
import TableCell from '@mui/material/TableCell/TableCell';
import { NotificationManager } from 'react-notifications';
import { Button, CopyToClipboard, P14 } from '../../../../components/atoms';
import THEME from '../../../../constants/theme';
import ToastCustomContainer from '../../../../components/ToastCustomContainer';
import { TOAST_TIMEOUT } from '../../../../constants/toasts';

const StyledTableCell = styled(TableCell)`
  width: 350px;
`;

export const PrivateKeyCell = ({ data }) => {
  const [showPrivateKey, setShowTableKey] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    setShowTableKey(true);
  };
  const handleCopy = () => {
    NotificationManager.success(
      <ToastCustomContainer message="Copied private API key to clipboard." title="Private API Key." />,
      null,
      TOAST_TIMEOUT
    );
  };

  return (
    <StyledTableCell onMouseLeave={() => setShowTableKey(false)} padding="none" align="left">
      {showPrivateKey ? (
        <CopyToClipboard className="small" text={data} onCopy={handleCopy}>
          <P14 padding="0" color={THEME.primaryColors.purpleMain} fontFamily="Source Code Pro, monospace">
            {data}
          </P14>
        </CopyToClipboard>
      ) : (
        <Button padding="0 0 0 10px" className="link" margin="0" onClick={handleClick} minHeight="0">
          <P14 color={THEME.primaryColors.blue} padding="0">
            View
          </P14>
        </Button>
      )}
    </StyledTableCell>
  );
};

PrivateKeyCell.propTypes = {
  data: string.isRequired,
};
