import React from 'react';
import { useTranslation } from 'react-i18next';
import { string, bool, func } from 'prop-types';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { P14, P16M } from '../../../components/atoms';
import THEME from '../../../constants/theme';
import { StyledStepLink, IconContainer, CheckedStatusBox } from './styled';
import CircularLoader from '../../../components/atoms/CircularLoader/CircularLoader';
import Check from '../../../assets/icons/Check';

const Step = ({ url, title, desc, icon, completed, isStrokeSvg, isExternalUrl, loading, onClickFunction }) => {
  const { t } = useTranslation();
  const Icon = icon;
  const color = THEME.primaryColors.orange;

  //External link uses html a tag and internal uses the Link component
  const linkProps = isExternalUrl
    ? {
        href: url,
        target: '_blank',
        rel: 'noreferrer noopener',
      }
    : {
        as: Link,
        to: url,
      };

  return (
    <StyledStepLink className="step" $disabled={completed} onClick={onClickFunction} {...linkProps}>
      <IconContainer completed={completed}>
        {icon && <Icon {...(isStrokeSvg ? { stroke: color } : { fill: color })} viewBox="-2 0 26 18" />}
      </IconContainer>

      <Box mr="8px">
        <P16M className="title" lineHeight="26px">
          {t(title)}
        </P16M>
        <P14 color={THEME.greyColors.grey600}>{t(desc)}</P14>
      </Box>

      <Box marginLeft="auto" minWidth="40px" display="flex" justifyContent="flex-end" alignItems="center">
        {loading && <CircularLoader bgcolor={THEME.primaryColors.primary} size={16} />}
        {!loading &&
          (completed ? (
            <CheckedStatusBox>
              <Check fill={THEME.primaryColors.white} width={11} height={8} />
            </CheckedStatusBox>
          ) : (
            <Box />
          ))}
      </Box>
    </StyledStepLink>
  );
};

Step.propTypes = {
  dataKey: string.isRequired,
  url: string,
  title: string.isRequired,
  desc: string.isRequired,
  icon: func.isRequired,
  completed: bool.isRequired,
  loading: bool.isRequired,
  isStrokeSvg: bool,
  isExternalUrl: bool,
  onClickFunction: func,
};

Step.defaultProps = {
  isStrokeSvg: false,
  isExternalUrl: false,
  url: '/',
  onClickFunction: () => {},
};

export default Step;
