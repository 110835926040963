import styled from 'styled-components';
import THEME from '../../../../../../../constants/theme';

const StyledHeader = styled.div`
  height: 72px;
  border-bottom: 1px solid ${THEME.greyColors.grey16};
  padding: 16px 32px;
  display: flex;
  align-items: center;

  width: 100%;
  position: absolute;
  top: 0;
  z-index: 100;
`;

const IconWrapper = styled.div`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: ${THEME.greyColors.grey16};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`;

const HeaderDetails = styled.div`
  .status {
    text-transform: uppercase;
  }
`;

export { StyledHeader, IconWrapper, HeaderDetails };
