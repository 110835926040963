import React from 'react';
import { useTranslation } from 'react-i18next';

import capitalize from '@mui/material/utils/capitalize';
import isEmpty from 'lodash/isEmpty';
import { P } from '../../atoms';
import { formNames } from './schema';
import { CURRENCY_LIST } from '../../../constants/currencyList';

const OTHERS_VALUE = 'OTHER';

const CUSTOM_SELECT_PROPS = {
  placeholder: 'Select',
};

const currencyOptions = Object.keys(CURRENCY_LIST).map((currencyKey) => {
  return {
    value: currencyKey,
    // text: { text: `${CURRENCY_LIST[currencyKey].name} ${CURRENCY_LIST[currencyKey].code}` },
    label: `${CURRENCY_LIST[currencyKey].name} ${CURRENCY_LIST[currencyKey].code}`,
  };
});

const SELECT_OPTIONS = {
  [formNames.currency]: currencyOptions,
};

const SelectPlaceHolder = () => {
  const { t } = useTranslation();
  return <P color="#8e8f8f">{t('Select')}</P>;
};

const renderValue = (values, max) => {
  if (!Array.isArray(values)) return values;
  if (Array.isArray(values) && values.some((value) => typeof value !== 'string')) return values;

  if (isEmpty(values)) {
    return <SelectPlaceHolder />;
  }
  return (
    values
      ?.slice(0, max || values.length)
      ?.map((value) => capitalize(value?.toLowerCase()?.replace(/_/g, ' ') || ''))
      ?.join(', ') + (values?.length > (max || values.length) ? `...` : '')
  );
};

const RENDER_VALUE_MAP = {
  [formNames.paymentProcessors]: (value) => renderValue(value, 2),
};

export { SELECT_OPTIONS, CUSTOM_SELECT_PROPS, OTHERS_VALUE, RENDER_VALUE_MAP, renderValue };
