import styled from 'styled-components';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const StyledHeader = styled.div`
  padding: 0 24px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-color: #e6e9ec;
  }
`;

const StyledTab = styled(Tab)`
  height: 56px;
`;

const CloseButton = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export { StyledHeader, StyledTab, CloseButton };
