import styled, { keyframes } from 'styled-components';
import { StyledBox } from '../OnboardingModal/styled';

export const StyledAnimationBox = styled(StyledBox)`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
`;

const spin = keyframes`
  0%, 100% {
    transform:translate(0)
  }
  25% {
    transform:translate(160%)
  }
  50% {
    transform:translate(160%, 160%)
  }
  75% {
    transform:translate(0, 160%)
  }
`;

export const StyledLoadingAnimationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledLoadingAnimation = styled.div`
  position: relative;
  width: 110px;
  height: 110px;
`;

export const StyledLoadingItem = styled.div`
  margin: 0;
  width: 45px;
  height: 45px;
  position: absolute;
  animation: ${spin} 2s ease infinite;
  border: 2px solid transparent;
  border-radius: 8px;
  &:first-child {
    background: #4347d0;
    animation-delay: -1.5s;
    border-radius: 50%;
  }
  &:nth-child(2) {
    background: #f36b47;
    animation-delay: -1s;
  }
  &:nth-child(3) {
    background: #f36b47;
    border-radius: 50%;
    animation-delay: -0.5s;
  }
  &:last-child {
    background: #4347d0;
  }
`;
