import styled from 'styled-components';
import { withStyles } from '@mui/styles';
import { ButtonRounded } from '../../../atoms';
import THEME from '../../../../constants/theme';

export const StyledUpload = styled.div`
  color: #787f88;
  width: 260px;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: 32px;
  }

  label {
    display: inline-block;
    transition: all 0.3s ease-out;

    input[type='file'] {
      display: none;
    }
  }

  input[type='checkbox'] {
    position: relative;
    z-index: 100;
    opacity: 0 !important;
    transform: scale(1.2);
    margin-top: 5px;
    cursor: pointer;

    & ~ svg {
      position: absolute;
      display: block;
      top: 3px;
      right: 0;
      transition: all 0.2s ease-out;
    }
    &:checked ~ svg {
      path {
        fill: ${THEME.primaryColors.primary};
      }
    }

    &:checked ~ .Specs {
      opacity: 1;
      visibility: visible;
    }
  }
  .Specs {
    opacity: 0;
    visibility: hidden;
    transition: all 200ms cubic-bezier(0, 0.84, 0.61, 1.01);
  }
`;

export const StyledWrapper = styled.div`
  background-color: ${THEME.greyColors.grey12};

  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  height: 208px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    cursor: pointer;
  }
`;

export const StyledDropZone = styled.div`
  background-color: ${THEME.primaryColors.white};
  border-radius: 8px;
  width: 80px;
  height: 80px;
  position: relative;
  cursor: pointer;
  border: 1px dashed ${THEME.greyColors.grey8};

  &:focus {
    outline: none;
  }

  ::after {
    content: '';
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${THEME.greyColors.grey12};
    opacity: ${({ dragging }) => (dragging ? 1 : 0)};
    transition: all 0.3s ease-out;
  }
`;

export const StyledButton = withStyles({
  root: {
    marginTop: '16px',
    backgroundColor: 'rgba(0,0,0, 0)',
    borderColor: '#c1c5cb',
    width: ({ modal }) => (modal ? '90%' : '150px'),
    transform: ({ modal, favicon }) => (!modal && favicon ? 'translateX(26px)' : 'translateX(0)'),
    '&:hover': {
      backgroundColor: 'rgba(0,0,0, 0)',
      color: THEME.primaryColors.primary,
      '& svg': {
        '& path': {
          fill: THEME.primaryColors.primary,
        },
      },
    },

    '& svg': {
      width: '14.7px',
      marginRight: '10px',
    },
  },
})(ButtonRounded);
