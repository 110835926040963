import React from 'react';

const File = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.58579 1.25253C2.96086 0.877462 3.46957 0.666748 4 0.666748H8.66667C8.84348 0.666748 9.01305 0.736986 9.13807 0.86201L13.8047 5.52868C13.9298 5.6537 14 5.82327 14 6.00008V13.3334C14 13.8638 13.7893 14.3726 13.4142 14.7476C13.0391 15.1227 12.5304 15.3334 12 15.3334H4C3.46957 15.3334 2.96086 15.1227 2.58579 14.7476C2.21071 14.3726 2 13.8638 2 13.3334V2.66675C2 2.13632 2.21071 1.62761 2.58579 1.25253ZM4 2.00008C3.82319 2.00008 3.65362 2.07032 3.5286 2.19534C3.40357 2.32037 3.33333 2.48994 3.33333 2.66675V13.3334C3.33333 13.5102 3.40357 13.6798 3.5286 13.8048C3.65362 13.9298 3.82319 14.0001 4 14.0001H12C12.1768 14.0001 12.3464 13.9298 12.4714 13.8048C12.5964 13.6798 12.6667 13.5102 12.6667 13.3334V6.27622L8.39052 2.00008H4Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66667 0.666748C9.03486 0.666748 9.33333 0.965225 9.33333 1.33341V5.33341H13.3333C13.7015 5.33341 14 5.63189 14 6.00008C14 6.36827 13.7015 6.66675 13.3333 6.66675H8.66667C8.29848 6.66675 8 6.36827 8 6.00008V1.33341C8 0.965225 8.29848 0.666748 8.66667 0.666748Z"
      fill="black"
    />
  </svg>
);

export default File;
