import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { StyledDropZone, StyledWrapper } from './styled';
import UploadIcon from '../../../../assets/icons/UploadIcon';
import { FlexContainer } from '../../../atoms/flex/FlexContainer';

const Uploader = ({ title, graphics, handleClick, dragging, getRootProps }) => {
  const { t } = useTranslation();

  return (
    <StyledWrapper $image={graphics}>
      {graphics ? (
        <Box
          onClick={handleClick}
          component="img"
          width="80px"
          height="80px"
          borderRadius="8px"
          overflow="hidden"
          src={graphics}
          alt={t(`Your ${title}`)}
        />
      ) : (
        <StyledDropZone dragging={dragging} {...getRootProps()}>
          <FlexContainer height="100%" flexDirection="column" textAlign="center">
            <Box>
              <UploadIcon />
            </Box>
          </FlexContainer>
        </StyledDropZone>
      )}
    </StyledWrapper>
  );
};

Uploader.propTypes = {
  title: PropTypes.string.isRequired,
  graphics: PropTypes.string,
  modal: PropTypes.bool,
  dragging: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  getRootProps: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool,
};
Uploader.defaultProps = {
  modal: false,
  modalIsOpen: false,
  graphics: '',
};

export default Uploader;
