import React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Skeleton from '@mui/lab/Skeleton';
import useSingleRecord from './hooks/useSingleRecord';
import FormControlWithLabel from './FormControlWithLabel';
import { useNodeDataToSave } from './hooks/useNodeDataToSave';

const SingleRecordField = () => {
  const [{ filters }, updateDataToSave] = useNodeDataToSave();

  const { records, loading } = useSingleRecord();

  const onChange = ({ target: { value } }) => {
    updateDataToSave({
      filters: { [value]: { value: null, selectedVerb: 'IS_EQUAL' } },
      singleRecordUniquenessOperator: null,
    });
  };

  const singleRecordProperty = filters ? Object.keys(filters)[0] : null;

  return (
    // TODO: will require changes after after backend will be done
    <FormControl variant="standard" component="fieldset" fullWidth>
      <RadioGroup
        aria-label="singleRecordField"
        name="singleRecordField"
        value={singleRecordProperty === 'dateRange' ? 'date' : singleRecordProperty}
        onChange={onChange}
      >
        {records?.map((record) => (
          <Box key={record.key}>
            {loading ? (
              <Box borderRadius="50%" width="100%" height="40px" overflow="hidden" mr="16px">
                <Skeleton variant="rectangular" height={90} width={90} animation="wave" />
              </Box>
            ) : (
              <FormControlWithLabel record={record} currentValue={singleRecordProperty} />
            )}
          </Box>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default SingleRecordField;
