import React from 'react';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';
import { UI_ROUTES } from '../../constants/routes';
import { useFlowEditorContext } from '../FlowEditor/context';
import RestoreVersionModal from './RestoreVersionModal';
import ButtonWithIcon from '../atoms/Buttons/ButtonWithIcon';
import THEME from '../../constants/theme';

const VersionControls = () => {
  const { t } = useTranslation();
  const { flowId, versionId, config } = useFlowEditorContext();
  const { push } = useHistory();
  const [openModal, toggleOpenModal] = useToggle(false);

  const isActive = config?.id === versionId;

  const redirect = () => {
    push(`${UI_ROUTES.automations}/${flowId}/editor`);
  };
  return (
    <Box display="flex" flexDirection="row" alignItems="center" ml="8px" gap="1px">
      {isActive ? (
        <ButtonWithIcon
          text={t('View Draft')}
          color={THEME.primaryColors.primaryD20}
          bgColor={THEME.primaryColors.primaryLight}
          onClick={redirect}
          p="8px 18px"
        />
      ) : (
        <>
          <ButtonWithIcon
            text={t('Restore')}
            color={THEME.primaryColors.primaryD20}
            bgColor={THEME.primaryColors.primaryLight}
            onClick={toggleOpenModal}
            p="8px 18px"
            $rightFlat
          />
          <ButtonWithIcon
            text={t('Exit')}
            color={THEME.primaryColors.black}
            bgColor={THEME.greyColors.greyButton}
            onClick={redirect}
            p="8px 18px"
            $leftFlat
          />
        </>
      )}
      <RestoreVersionModal isOpen={openModal} closeModal={toggleOpenModal} redirect={redirect} />
    </Box>
  );
};

export default VersionControls;
