import React from 'react';
import { string } from 'prop-types';
import TableCell from '@mui/material/TableCell/TableCell';
import { NotificationManager } from 'react-notifications';
import Box from '@mui/material/Box';
import { CopyToClipboard, P14B } from '../../../../components/atoms';
import { TableCellInner } from './styled';
import ToastCustomContainer from '../../../../components/ToastCustomContainer';
import { TOAST_TIMEOUT } from '../../../../constants/toasts';

const TITLE = 'Flow Id.';
const MESSAGE = 'Copied flow id to clipboard.';

export const FlowIdCell = ({ data }) => {
  const handleCopy = () => {
    NotificationManager.success(<ToastCustomContainer message={MESSAGE} title={TITLE} />, null, TOAST_TIMEOUT);
  };

  return (
    <TableCell align="left" padding="none">
      <TableCellInner>
        <Box padding="14px 0" display="flex">
          <CopyToClipboard className="small" text={data} onCopy={handleCopy}>
            <P14B padding="0 0 0 10px" color="#232629" fontFamily="Source Code Pro, monospace">
              {data}
            </P14B>
          </CopyToClipboard>
        </Box>
      </TableCellInner>
    </TableCell>
  );
};

FlowIdCell.propTypes = {
  data: string.isRequired,
};
