import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import { StyledWrapper } from './styled';
import { L10UM } from '../../atoms/Typography/L10UM';
import THEME from '../../../constants/theme';
import { APP_ENVIRONMENT } from '../../../constants/api';
import AccountName from './AccountName';
import CustomerListModal from './CustomerListModal';

const TopNav = () => {
  const { t } = useTranslation();
  const env = APP_ENVIRONMENT.toUpperCase();
  const [shouldModalOpen, toggleModal] = useToggle(false);

  return (
    <>
      <StyledWrapper>
        <Box display="flex" alignItems="center">
          <L10UM color={THEME.greyColors.grey600} margin="0 14px 0 0">
            {t('VIEW AS')}
          </L10UM>

          <AccountName toggleModal={toggleModal} />
        </Box>

        <L10UM color={THEME.greyColors.grey400} margin="0 4px 0 0">
          {t(env)}
        </L10UM>
      </StyledWrapper>
      {shouldModalOpen && <CustomerListModal shouldModalOpen={shouldModalOpen} toggleModal={toggleModal} />}{' '}
    </>
  );
};

export default TopNav;
