import styled from 'styled-components';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import THEME from '../../../../../../constants/theme';

const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    box-shadow: none;

    &.MuiPaper-elevation1:not(.MuiCard-root) {
      transform: translateX(0);
      border-radius: 0;
      border-top: 1px solid transparent;
    }

    &:last-child {
      border-bottom-left-radius: 12px !important;
      border-bottom-right-radius: 12px !important;
    }

    &.Mui-expanded {
      margin: 0;
    }

    & .MuiCollapse-root {
      position: relative;
      z-index: 1;
    }

    &.showSelectedFilters {
      border-bottom: 0px;
    }
  }

  &:before {
    display: none;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    min-height: 52px !important;
    height: 52px !important;
    padding: 0 24px;
    display: flex;
    align-items: center;

    position: relative;
    z-index: 10;

    &:hover {
      svg {
        path {
          fill: black;
        }
      }
    }
  }
`;

const StyleAccordionDetails = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    overflow-y: auto;
    max-height: 400px;
    padding-left: 24px;
  }
`;

const StyledFilterBodyTags = styled.div`
  display: flex;
  flex-direction: column;
`;

const AppCategory = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: ${THEME.greyColors.grey20};
  }
`;

const SelectedFiltersWrapper = styled.div`
  position: relative;
  background-color: white;
`;

const SelectedFiltersWrapperInner = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  max-height: 200px;
  transition: max-height 0.2s;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 20px;

  border: 1px solid #e8e8e8;
  border-top: none;

  &.close {
    max-height: 0;
    padding-bottom: 0;
  }

  /* &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: calc(100% - 48px);
    height: 40px;
    background: linear-gradient(180deg, rgba(28, 28, 30, 0) 0%, rgba(28, 28, 30, 1) 100%);
  } */
`;

export {
  StyledAccordion,
  StyledAccordionSummary,
  StyleAccordionDetails,
  StyledFilterBodyTags,
  AppCategory,
  SelectedFiltersWrapper,
  SelectedFiltersWrapperInner,
};
