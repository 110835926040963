import styled from 'styled-components';
import { RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { withStyles } from '@mui/styles';
import THEME from '../../../constants/theme';

export const StyledRadioGroup = withStyles({
  root: {
    display: 'flex',
    flexFlow: 'row wrap',
    margin: '0 0 0 24px',

    '&>:not(:last-child)': {
      marginRight: '16px',
    },
    '& > *': {
      margin: '0',
    },
  },
})(RadioGroup);

export const StyledLabel = styled.label`
  display: flex;
  input {
    text-transform: uppercase;
    border: none;
    display: inline-block;
    width: 100%;
    text-align: center;
    background-color: ${THEME.primaryColors.white};
    font-weight: 600;
    font-size: 14px;
    color: ${THEME.primaryColors.blackPlain};

    &:focus {
      outline: none;
      border-bottom: 1px solid currentColor;
    }
  }
`;

export const StyledPaletteContainer = styled.div`
  width: 553px;
  height: 288px;
  flex-shrink: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  background-color: ${THEME.greyColors.grey12};
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin: 0 0 16px 0;
  }
`;

export const StyledRadio = styled(Radio)`
  &.MuiRadio-root {
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }
`;

export const StyledRadioLabel = styled.span`
  background-color: ${({ color }) => color};
  display: block;
  width: 53px;
  height: 53px;
  text-align: center;
  border: 1.1px solid #fff;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  position: relative;
  backface-visibility: hidden;
  box-shadow: 0px 0px 1.5px 1.5px rgba(155, 159, 171, 0.2);

  &:hover {
    transform: scale(1.1);
  }
`;
