import styled, { css } from 'styled-components';
import THEME from '../../../constants/theme';
import { P14 } from '../../atoms';

/**
 * Calendar component had to be styled this way to match design system,
 * Comments are made on each sections styled
 * library - materialui-daterange-picker does not provided a style props to override styles
 */

const DATE_AND_MONTH_DROPDOWN_CSS = (nthChild3) => css`
  & div[class*='MuiPopover-paper MuiPaper-elevation'] {
    position: absolute !important;
    top: 12px !important;
    left: ${nthChild3 ? '-80px !important' : '-60px !important'};
    transform: none !important;
    min-width: 100px !important;
    min-height: 220px;

    li {
      font-size: 12px !important;
      font-weight: 600 !important;

      &:not(:last-child) {
        margin-bottom: 4px !important;
      }
    }
  }
`;

export const DATE_COMPONENT_CSS = css`
  & .wt-date-range-picker {
    & > div[class*='MuiPaper-root'] div div:last-child {
      & div[class*='MuiPaper-root materialui-daterange-picker-makeStyles-root'] {
        & div div[class*='materialui-daterange-picker-MuiGrid-justify-xs-space-between']:first-child {
          //calendar header

          & > div:first-child,
          > div:last-child {
            opacity: 0 !important;
          }

          & > div:nth-child(2),
          & > div:nth-child(3) {
            & div {
              &::before,
              &::after {
                border: none !important;
              }

              & div[class*='MuiPopover-root'] {
                position: relative !important;

            
                  background-color: red !important;
                
                  ${DATE_AND_MONTH_DROPDOWN_CSS(true)};
                } 
              }
            }
          }
        }

        & div div[class*='materialui-daterange-picker-MuiGrid-justify-xs-space-between']:nth-child(2) {
          & span {
            color: ${THEME.greyColors.grey8} !important;
            font-size: 0 !important;

            &:first-letter {
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }
`;

export const StyledFlowMonitorDetailsHeader = styled.div`
  padding: 18px 24px;
  display: flex;
  border-top: 1px solid ${THEME.greyColors.grey200};
  border-bottom: 1px solid ${THEME.greyColors.grey200};
`;

export const StyledErrorMessage = styled(P14)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${THEME.secondaryColors.nodeError};
`;

export const StyledFlowMonitorDetailsItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: ${({ $height }) => $height || '56px'};
  padding: ${({ $px }) => ($px ? `0 ${$px}` : '0 16px')};
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${THEME.greyColors.grey14};
    & .status-dot {
      border-color: ${THEME.greyColors.grey14};
    }
  }
  &:first-child {
    & .status-top-line {
      visibility: hidden;
    }
  }
  &:last-child {
    & .status-bottom-line {
      visibility: hidden;
    }
  }
`;
export const StyledTimelineStatusWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-right: ${({ $isError }) => ($isError ? '-2px' : '0')};
`;

export const StyledStatusLine = styled.div`
  width: 1px;
  height: ${({ $isError }) => ($isError ? 'calc(50% - 8px)' : 'calc(50% - 6px)')};
  background-color: ${THEME.greyColors.grey300};
`;

export const StyledStatusDot = styled.div`
  width: ${({ $isError }) => ($isError ? '17px' : '13px')};
  height: ${({ $isError }) => ($isError ? '17px' : '13px')};
  background-color: ${({ $isError }) => ($isError ? THEME.secondaryColors.nodeError : THEME.greyColors.grey19)};
  border: 2px solid ${THEME.primaryColors.white};
  border-radius: 50%;
`;
