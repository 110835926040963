import React from 'react';

const GettingStarted = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.4987C5.85787 2.4987 2.50001 5.85656 2.50001 9.9987C2.50001 14.1408 5.85787 17.4987 10 17.4987C14.1421 17.4987 17.5 14.1408 17.5 9.9987C17.5 5.85656 14.1421 2.4987 10 2.4987ZM0.833344 9.9987C0.833344 4.93609 4.9374 0.832031 10 0.832031C15.0626 0.832031 19.1667 4.93609 19.1667 9.9987C19.1667 15.0613 15.0626 19.1654 10 19.1654C4.9374 19.1654 0.833344 15.0613 0.833344 9.9987Z"
        fill="#787F88"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99999 9.16797C10.4602 9.16797 10.8333 9.54106 10.8333 10.0013V13.3346C10.8333 13.7949 10.4602 14.168 9.99999 14.168C9.53975 14.168 9.16666 13.7949 9.16666 13.3346V10.0013C9.16666 9.54106 9.53975 9.16797 9.99999 9.16797Z"
        fill="#787F88"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16666 6.66536C9.16666 6.20513 9.53975 5.83203 9.99999 5.83203H10.0083C10.4686 5.83203 10.8417 6.20513 10.8417 6.66536C10.8417 7.1256 10.4686 7.4987 10.0083 7.4987H9.99999C9.53975 7.4987 9.16666 7.1256 9.16666 6.66536Z"
        fill="#787F88"
      />
    </svg>
  );
};

export default GettingStarted;
