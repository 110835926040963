import React from 'react';
import { string } from 'prop-types';

const DraggingIcon = ({ width, height, className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 60 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2201_8526)">
        <path
          d="M28.2694 7.41217L12.5125 10.1905C11.4677 10.3748 10.5389 10.9665 9.93046 11.8355C9.32197 12.7045 9.08362 13.7796 9.26783 14.8244L14.8246 46.3382C15.0088 47.383 15.6005 48.3117 16.4695 48.9202C17.3385 49.5287 18.4137 49.7671 19.4584 49.5829L43.0938 45.4153C44.1385 45.2311 45.0673 44.6394 45.6758 43.7704C46.2843 42.9014 46.5226 41.8262 46.3384 40.7815L42.1709 17.1461L28.2694 7.41217Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.269 7.41211L30.3528 19.2298L42.1705 17.146"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.0289 28.3834L20.272 31.1617"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.4181 36.2618L21.6611 39.0402"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.822 22.5886L20.8524 22.9359L18.8828 23.2832"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M40.4033 16.8028C40.9033 16.6028 41.8033 16.7028 42.1033 17.3028C42.3033 17.8028 42.5033 18.5028 42.5033 18.4028C42.5033 18.0028 42.5033 17.2028 42.6033 16.8028C42.7033 16.5028 42.9033 16.2028 43.3033 16.1028C43.6033 16.0028 43.9033 16.0028 44.2033 16.0028C44.5033 16.1028 44.8033 16.3028 45.0033 16.5028C45.4033 17.1028 45.4033 18.4028 45.4033 18.3028C45.5033 18.0028 45.5033 17.1028 45.7033 16.7028C45.8033 16.5028 46.2033 16.3028 46.4033 16.2028C46.7033 16.1028 47.1033 16.1028 47.4033 16.2028C47.6033 16.2028 48.0033 16.5028 48.1033 16.7028C48.3033 17.0028 48.4033 18.0028 48.5033 18.4028C48.5033 18.5028 48.6033 18.0028 48.8033 17.7028C49.2033 17.1028 50.6033 16.9028 50.7033 18.3028C50.7033 19.0028 50.7033 18.9028 50.7033 19.4028C50.7033 19.9028 50.7033 20.2028 50.7033 20.6028C50.7033 21.0028 50.6033 21.9028 50.5033 22.3028C50.4033 22.6028 50.1033 23.3028 49.8033 23.7028C49.8033 23.7028 48.7033 24.9028 48.6033 25.5028C48.5033 26.1028 48.5033 26.1028 48.5033 26.5028C48.5033 26.9028 48.6033 27.4028 48.6033 27.4028C48.6033 27.4028 47.8033 27.5028 47.4033 27.4028C47.0033 27.3028 46.5033 26.6028 46.4033 26.3028C46.2033 26.0028 45.9033 26.0028 45.7033 26.3028C45.5033 26.7028 45.0033 27.4028 44.7033 27.4028C44.0033 27.5028 42.6033 27.4028 41.6033 27.4028C41.6033 27.4028 41.8033 26.4028 41.4033 26.0028C41.1033 25.7028 40.6033 25.2028 40.3033 24.9028L39.5033 24.0028C39.2033 23.6028 38.5033 23.1028 38.3033 22.0028C38.1033 21.1028 38.1033 20.6028 38.3033 20.2028C38.5033 19.8028 39.0033 19.6028 39.2033 19.6028C39.4033 19.6028 39.9033 19.6028 40.1033 19.7028C40.3033 19.8028 40.4033 19.9028 40.6033 20.1028C40.8033 20.4028 40.9033 20.6028 40.8033 20.2028C40.7033 19.9028 40.5033 19.6028 40.4033 19.2028C40.3033 18.8028 40.0033 18.3028 40.0033 17.7028C39.5033 17.7028 39.6033 17.1028 40.4033 16.8028Z"
        fill="white"
      />
      <path
        d="M40.4033 16.8028C40.9033 16.6028 41.8033 16.7028 42.1033 17.3028C42.3033 17.8028 42.5033 18.5028 42.5033 18.4028C42.5033 18.0028 42.5033 17.2028 42.6033 16.8028C42.7033 16.5028 42.9033 16.2028 43.3033 16.1028C43.6033 16.0028 43.9033 16.0028 44.2033 16.0028C44.5033 16.1028 44.8033 16.3028 45.0033 16.5028C45.4033 17.1028 45.4033 18.4028 45.4033 18.3028C45.5033 18.0028 45.5033 17.1028 45.7033 16.7028C45.8033 16.5028 46.2033 16.3028 46.4033 16.2028C46.7033 16.1028 47.1033 16.1028 47.4033 16.2028C47.6033 16.2028 48.0033 16.5028 48.1033 16.7028C48.3033 17.0028 48.4033 18.0028 48.5033 18.4028C48.5033 18.5028 48.6033 18.0028 48.8033 17.7028C49.2033 17.1028 50.6033 16.9028 50.7033 18.3028C50.7033 19.0028 50.7033 18.9028 50.7033 19.4028C50.7033 19.9028 50.7033 20.2028 50.7033 20.6028C50.7033 21.0028 50.6033 21.9028 50.5033 22.3028C50.4033 22.6028 50.1033 23.3028 49.8033 23.7028C49.8033 23.7028 48.7033 24.9028 48.6033 25.5028C48.5033 26.1028 48.5033 26.1028 48.5033 26.5028C48.5033 26.9028 48.6033 27.4028 48.6033 27.4028C48.6033 27.4028 47.8033 27.5028 47.4033 27.4028C47.0033 27.3028 46.5033 26.6028 46.4033 26.3028C46.2033 26.0028 45.9033 26.0028 45.7033 26.3028C45.5033 26.7028 45.0033 27.4028 44.7033 27.4028C44.0033 27.5028 42.6033 27.4028 41.6033 27.4028C41.6033 27.4028 41.8033 26.4028 41.4033 26.0028C41.1033 25.7028 40.6033 25.2028 40.3033 24.9028L39.5033 24.0028C39.2033 23.6028 38.5033 23.1028 38.3033 22.0028C38.1033 21.1028 38.1033 20.6028 38.3033 20.2028C38.5033 19.8028 39.0033 19.6028 39.2033 19.6028C39.4033 19.6028 39.9033 19.6028 40.1033 19.7028C40.3033 19.8028 40.4033 19.9028 40.6033 20.1028C40.8033 20.4028 40.9033 20.6028 40.8033 20.2028C40.7033 19.9028 40.5033 19.6028 40.4033 19.2028C40.3033 18.8028 40.0033 18.3028 40.0033 17.7028C39.5033 17.7028 39.6033 17.1028 40.4033 16.8028Z"
        stroke="black"
        strokeWidth="0.75"
        strokeLinejoin="round"
      />
      <path d="M47.4033 24.5028V21.1028" stroke="black" strokeWidth="0.75" strokeLinecap="round" />
      <path d="M45.4032 24.5028L45.3032 21.1028" stroke="black" strokeWidth="0.75" strokeLinecap="round" />
      <path d="M43.4033 21.1028V24.5028" stroke="black" strokeWidth="0.75" strokeLinecap="round" />
      <defs>
        <clipPath id="clip0_2201_8526">
          <rect width="48" height="48" fill="white" transform="translate(0 8.33508) rotate(-10)" />
        </clipPath>
      </defs>
    </svg>
  );
};

DraggingIcon.propTypes = {
  width: string,
  height: string,
  className: string,
};
DraggingIcon.defaultProps = {
  width: '60',
  height: '56',
  className: null,
};

export default DraggingIcon;
