import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import {
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
  StyledPaper,
  StyledDialogActions,
} from '../../Dialog/styled';
import CloseButton from '../../Dialog/CloseButton';
import { ButtonRounded } from '../../atoms/Buttons/ButtonRounded';
import Palette from './Palette';
import Swatches from './Swatches';

const ID = 'wt-edit-brand-color';

const EditBrandColorDialog = ({ isOpen, handleCancel, handleSave, initialColor, setInitialColor }) => {
  const { t } = useTranslation();
  return (
    <StyledDialog
      open={isOpen}
      scroll="paper"
      maxWidth="md"
      $customWidth="920px"
      PaperComponent={StyledPaper}
      onClose={handleCancel}
      aria-labelledby={ID}
    >
      <CloseButton onClick={handleCancel} />
      <StyledDialogTitle id={`${ID}-title`} disableTypography>
        {t('Pick your primary color')}
      </StyledDialogTitle>

      <StyledDialogContent>
        <Box display="flex" flexWrap="nowrap" pt="32px">
          <Box>
            <Palette color={initialColor} editable setInitialColor={setInitialColor} />
          </Box>
          <Swatches handleChange={setInitialColor} />
        </Box>
      </StyledDialogContent>

      <StyledDialogActions $justifyContent="flex-end">
        <ButtonRounded type="button" color="secondary" variant="contained" onClick={handleCancel}>
          {t('Cancel')}
        </ButtonRounded>
        <ButtonRounded type="button" color="primary" variant="contained" onClick={handleSave}>
          {t('Save')}
        </ButtonRounded>
      </StyledDialogActions>
    </StyledDialog>
  );
};

EditBrandColorDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  initialColor: PropTypes.string.isRequired,
  setInitialColor: PropTypes.func.isRequired,
};

export default EditBrandColorDialog;
