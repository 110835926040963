import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/lab/Skeleton';

const COMMON_PROPS = {
  borderRadius: '8px',
  overflow: 'hidden',
};

const LoadingState = () => {
  return (
    <Box width="100%" mt="24px">
      <Box {...COMMON_PROPS} mb="4px">
        <Skeleton variant="rectangular" height={48} width="100%" animation="wave" />
      </Box>
    </Box>
  );
};

export default LoadingState;
