import React from 'react';

const Tokenization = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9 6.75C9 7.34674 8.76295 7.91903 8.34099 8.34099C7.91903 8.76295 7.34674 9 6.75 9C6.15326 9 5.58097 8.76295 5.15901 8.34099C4.73705 7.91903 4.5 7.34674 4.5 6.75C4.5 6.15326 4.73705 5.58097 5.15901 5.15901C5.58097 4.73705 6.15326 4.5 6.75 4.5C7.34674 4.5 7.91903 4.73705 8.34099 5.15901C8.76295 5.58097 9 6.15326 9 6.75ZM7.5 6.75C7.5 6.55109 7.42098 6.36032 7.28033 6.21967C7.13968 6.07902 6.94891 6 6.75 6C6.55109 6 6.36032 6.07902 6.21967 6.21967C6.07902 6.36032 6 6.55109 6 6.75C6 6.94891 6.07902 7.13968 6.21967 7.28033C6.36032 7.42098 6.55109 7.5 6.75 7.5C6.94891 7.5 7.13968 7.42098 7.28033 7.28033C7.42098 7.13968 7.5 6.94891 7.5 6.75Z"
      fill="black"
    />
    <path
      d="M3 1.5H9.879C10.2768 1.50008 10.6583 1.65818 10.9395 1.9395L21.4395 12.4395C21.7207 12.7208 21.8787 13.1023 21.8787 13.5C21.8787 13.8977 21.7207 14.2792 21.4395 14.5605L14.5605 21.4395C14.2792 21.7207 13.8977 21.8787 13.5 21.8787C13.1023 21.8787 12.7208 21.7207 12.4395 21.4395L1.9395 10.9395C1.65818 10.6583 1.50008 10.2768 1.5 9.879V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5V1.5ZM3 9.879L13.5 20.379L20.379 13.5L9.879 3H3V9.879Z"
      fill="black"
    />
  </svg>
);

export default Tokenization;
