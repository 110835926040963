import Bolt from '../assets/icons/Bolt';
import Capture from '../assets/icons/Templates/New/Capture';
import Finance from '../assets/icons/Templates/New/Finance';
import Fraud from '../assets/icons/Templates/New/Fraud';
import Operations from '../assets/icons/Templates/New/Operations';
import ReducePaymentFees from '../assets/icons/Templates/New/ReducePaymentFees';
import ReduceDeclines from '../assets/icons/Templates/New/ReduceDeclines';
import Orchestration from '../assets/icons/Templates/New/Orchestration';
import SupportMultipleProcessors from '../assets/icons/Templates/New/SupportMultipleProcessors';
import MitigateProcessorDowntime from '../assets/icons/Templates/New/MitigateProcessorDowntime';
import IncreaseAuthRates from '../assets/icons/Templates/New/IncreaseAuthRates';
import Reporting from '../assets/icons/Templates/New/Reporting';
import Alerts from '../assets/icons/Templates/New/Alerts';
import Communication from '../assets/icons/Templates/New/Communication';
import NewMarketExpansion from '../assets/icons/Templates/New/NewMarketExpansion';
import CheckoutOptimisation from '../assets/icons/Templates/New/CheckoutOptimisation';
import ModerateDisputesAndRefund from '../assets/icons/Templates/New/ModerateDisputesAndRefund';
import Reconciliation from '../assets/icons/Templates/New/Reconciliation';
import Tokenisation from '../assets/icons/Templates/New/Tokenisation';
import Redundancy from '../assets/icons/Templates/New/Redundancy';

const CATEGORY_ICON_MAP = {
  CAPTURE: { Icon: Capture },
  FINANCIAL_PLANNING: { Icon: Finance },
  FRAUD_PREVENTION: { Icon: Fraud },
  OPERATIONS_MANAGEMENT: { Icon: Operations },
  OPERATIONS: { Icon: Operations },
  REDUCE_PAYMENT_FEES: { Icon: ReducePaymentFees },
  REDUCE_DECLINES: { Icon: ReduceDeclines },
  ORCHESTRATION: { Icon: Orchestration },
  SUPPORT_MULTIPLE_PROCESSORS: { Icon: SupportMultipleProcessors },
  MITIGATE_PROCESSOR_DOWNTIME: { Icon: MitigateProcessorDowntime },
  INCREASE_AUTH_RATES: { Icon: IncreaseAuthRates },
  REPORTING: { Icon: Reporting },
  ALERTS: { Icon: Alerts },
  ENGAGE: { Icon: Communication },
  COMMUNICATION: { Icon: Communication },
  NEW_MARKET_EXPANSION: { Icon: NewMarketExpansion },
  CHECKOUT_OPTIMISATION: { Icon: CheckoutOptimisation },
  MODERATE_DISPUTES_AND_REFUNDS: { Icon: ModerateDisputesAndRefund },
  RECONCILIATION: { Icon: Reconciliation },
  TOKENISATION: { Icon: Tokenisation },
  REDUNDANCY: { Icon: Redundancy },
};

const getAutomationTemplateIcon = (useCases) => {
  if (!Array.isArray(useCases)) throw new Error('useCases must be an array');

  return useCases
    .map((useCase) => ({
      Icon: CATEGORY_ICON_MAP[useCase]?.Icon || Bolt,
      key: useCase,
    }))
    .filter(Boolean);
};

export { getAutomationTemplateIcon };
