import { string, number } from 'prop-types';
import React from 'react';
import THEME from '../../constants/theme';

const Download = ({ size, fill }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.6665 14.0026C2.6665 13.6344 2.96498 13.3359 3.33317 13.3359H12.6665C13.0347 13.3359 13.3332 13.6344 13.3332 14.0026C13.3332 14.3708 13.0347 14.6693 12.6665 14.6693H3.33317C2.96498 14.6693 2.6665 14.3708 2.6665 14.0026Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.19526 6.19526C4.45561 5.93491 4.87772 5.93491 5.13807 6.19526L8 9.05719L10.8619 6.19526C11.1223 5.93491 11.5444 5.93491 11.8047 6.19526C12.0651 6.45561 12.0651 6.87772 11.8047 7.13807L8.4714 10.4714C8.21106 10.7318 7.78894 10.7318 7.5286 10.4714L4.19526 7.13807C3.93491 6.87772 3.93491 6.45561 4.19526 6.19526Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00016 1.33594C8.36835 1.33594 8.66683 1.63441 8.66683 2.0026V10.0026C8.66683 10.3708 8.36835 10.6693 8.00016 10.6693C7.63197 10.6693 7.3335 10.3708 7.3335 10.0026V2.0026C7.3335 1.63441 7.63197 1.33594 8.00016 1.33594Z"
        fill={fill}
      />
    </svg>
  );
};

Download.propTypes = {
  size: number,
  fill: string,
};
Download.defaultProps = {
  size: 16,
  fill: THEME.primaryColors.primary,
};

export default Download;
