import styled from 'styled-components';
import { ButtonRounded } from '../../../../../../components/atoms';
import THEME from '../../../../../../constants/theme';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 7.5px;

  @media (max-width: 1180px) {
    margin-top: 64px;
    max-width: 100%;
  }
`;

const StyledSecondaryButton = styled(ButtonRounded)`
  &.MuiButton-containedSecondary {
    background-color: ${THEME.greyColors.grey16};
    margin-left: 4px;

    &:hover {
      background-color: ${THEME.greyColors.grey300};
    }
  }

  &.MuiButton-containedPrimary {
    margin-left: 4px;
  }
`;

export { StyledWrapper, StyledSecondaryButton };
