const formNames = {
  transactionsAmount: 'transactionsAmount',
};

const formFields = [{ field: formNames.transactionsAmount, label: '' }];

const initialValues = {
  [formNames.transactionsAmount]: '',
};

export { initialValues, formFields, formNames };
