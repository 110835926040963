import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/lab/Skeleton';

const Styled = {
  transform: 'translate(-50%, -50%)',
};

const ConnectSkeleton = () => (
  <Box
    style={Styled}
    borderRadius="24px"
    overflow="hidden"
    width="500px"
    height="716px"
    position="absolute"
    top="50%"
    left="50%"
    translate="yes"
  >
    <Skeleton variant="rectangular" animation="wave" height="100%" width="100%" />
  </Box>
);
export default ConnectSkeleton;
