import React from 'react';
import { number, string } from 'prop-types';
import THEME from '../../../constants/theme';

const Help = ({ size, color }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2581 8.02489C11.7926 7.94504 11.3138 8.03252 10.9066 8.27184C10.4994 8.51115 10.1901 8.88686 10.0333 9.33242C9.85006 9.8534 9.27914 10.1272 8.75815 9.94391C8.23716 9.76063 7.96339 9.18972 8.14666 8.66873C8.46013 7.77762 9.07886 7.0262 9.89327 6.54757C10.7077 6.06894 11.6652 5.89398 12.5962 6.05368C13.5273 6.21338 14.3717 6.69743 14.9801 7.4201C15.5883 8.14262 15.9213 9.05705 15.92 10.0015C15.9195 11.5318 14.7849 12.5425 13.9747 13.0826C13.5391 13.373 13.1105 13.5866 12.7949 13.7269C12.6356 13.7977 12.5015 13.8514 12.4049 13.8882C12.3565 13.9066 12.3173 13.9209 12.2887 13.9311L12.254 13.9432L12.2429 13.947L12.239 13.9483L12.2375 13.9488C12.2372 13.9489 12.2362 13.9493 11.92 13.0006L12.2362 13.9493C11.7123 14.1239 11.146 13.8407 10.9713 13.3168C10.7968 12.7932 11.0794 12.2273 11.6027 12.0522L11.6014 12.0527C11.6015 12.0526 11.6016 12.0526 11.6027 12.0522L11.6187 12.0466C11.6341 12.0412 11.6593 12.032 11.6929 12.0192C11.7603 11.9935 11.8606 11.9535 11.9826 11.8993C12.2294 11.7896 12.5509 11.6281 12.8653 11.4185C13.5549 10.9588 13.92 10.4697 13.92 10.0006L13.92 9.99908C13.9207 9.52676 13.7542 9.06944 13.45 8.7081C13.1459 8.34676 12.7236 8.10474 12.2581 8.02489Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 17C11 16.4477 11.4477 16 12 16H12.01C12.5623 16 13.01 16.4477 13.01 17C13.01 17.5523 12.5623 18 12.01 18H12C11.4477 18 11 17.5523 11 17Z"
      fill={color}
    />
  </svg>
);

Help.propTypes = {
  size: number,
  color: string,
};

Help.defaultProps = {
  size: 24,
  color: THEME.primaryColors.black,
};

export default Help;
