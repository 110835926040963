import React from 'react';
import { bool, func } from 'prop-types';
import { useMutation } from '@apollo/client';
import { RESTORE_FLOW_VERSION } from '../../utils/queries/flows/mutations';
import { useFlowEditorContext } from '../FlowEditor/context';
import ConfirmModal from '../FlowEditor/components/ConfirmModal';
import { getOperationNameForQuery } from '../../utils/graphql';
import { GET_FLOW } from '../../utils/queries/flows/queries';

const RestoreVersionModal = ({ isOpen, closeModal, redirect }) => {
  const { versionId } = useFlowEditorContext();
  const [restoreVersion, { loading: isDataSaving }] = useMutation(RESTORE_FLOW_VERSION, {
    refetchQueries: [getOperationNameForQuery(GET_FLOW)],
  });
  const handleSubmit = async () => {
    await restoreVersion({
      variables: {
        id: versionId,
      },
    });
    closeModal();
    redirect();
  };
  return (
    <ConfirmModal
      text="This version will become your draft automation, and any previous draft will be replaced and unretrievable."
      confirmActionText="Restore"
      isDataSaving={isDataSaving}
      closeModal={closeModal}
      title="Are you sure you want to restore this version?"
      isOpen={isOpen}
      handleSubmit={handleSubmit}
    />
  );
};

RestoreVersionModal.propTypes = {
  isOpen: bool.isRequired,
  closeModal: func.isRequired,
  redirect: func.isRequired,
};

export default RestoreVersionModal;
