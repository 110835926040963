import React from 'react';
import { string, func, bool, number, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { StyledBox, StyledItemBox } from './styled';
import { P12, P14 } from '../../atoms';
import ChevronRight from '../../../assets/icons/Elements/ChevronRight';
import THEME from '../../../constants/theme';
import NodeIcon from '../../FlowEditor/components/NodeLibrary/NodeIcon';

const Group = ({ icon, label, nickname, selected, onSelect, nestingLevel, stepGroup }) => {
  const { t } = useTranslation();
  return (
    <StyledItemBox $selected={selected} onClick={onSelect}>
      <StyledBox>
        {icon && nestingLevel === 0 && (
          <NodeIcon nodeData={{ icon, group: stepGroup }} size="40" type={stepGroup?.toLowerCase()} />
        )}
        <Box flexDirection="column" maxWidth="156px">
          <P14 overflow="hidden" textOverflow="ellipsis" textTransform="capitalize" noWrap>
            {t(label)}
          </P14>
          {nickname && nestingLevel === 0 && (
            <P12 color={THEME.greyColors.grey600} overflow="hidden" textOverflow="ellipsis" noWrap>
              {t(nickname)}
            </P12>
          )}
        </Box>
      </StyledBox>
      <ChevronRight />
    </StyledItemBox>
  );
};

Group.propTypes = {
  nestingLevel: number.isRequired,
  stepGroup: string,
  icon: shape({
    key: string,
    url: string,
  }),
  label: string,
  nickname: string,
  selected: bool,
  onSelect: func,
};

Group.defaultProps = {
  stepGroup: '',
  icon: null,
  label: '',
  nickname: '',
  selected: false,
  onSelect: () => {},
};

export default Group;
