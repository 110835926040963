import React from 'react';
import PropTypes from 'prop-types';

const BrandCenter = ({ stroke, ...props }) => {
  return (
    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.2704 13.6738L8.8111 12.8326L8.2704 13.6738ZM7.7296 13.6738L7.1889 12.8326L7.7296 13.6738ZM1.77038 17.5048L2.31114 18.3459L1.77038 17.5048ZM14.2296 17.5048L13.6889 18.3459L14.2296 17.5048ZM3.5 2H12.5V0H3.5V2ZM14 3.5V17.0842H16V3.5H14ZM2 17.0842V3.5H0V17.0842H2ZM14.7704 16.6636L8.8111 12.8326L7.7296 14.515L13.6889 18.3459L14.7704 16.6636ZM7.1889 12.8326L1.22962 16.6636L2.31114 18.3459L8.2704 14.515L7.1889 12.8326ZM8.8111 12.8326C8.317 12.515 7.683 12.515 7.1889 12.8326L8.2704 14.515C8.1057 14.6209 7.8943 14.6209 7.7296 14.515L8.8111 12.8326ZM0 17.0842C0 18.2709 1.31287 18.9877 2.31114 18.3459L1.22962 16.6636C1.56238 16.4497 2 16.6886 2 17.0842H0ZM14 17.0842C14 16.6886 14.4376 16.4497 14.7704 16.6636L13.6889 18.3459C14.6871 18.9877 16 18.2709 16 17.0842H14ZM12.5 2C13.3284 2 14 2.67157 14 3.5H16C16 1.567 14.433 0 12.5 0V2ZM3.5 0C1.567 0 0 1.567 0 3.5H2C2 2.67157 2.67157 2 3.5 2V0Z"
        fill={stroke}
      />
    </svg>
  );
};

BrandCenter.propTypes = {
  stroke: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
BrandCenter.defaultProps = {
  stroke: '#4E40EF',
};

export default BrandCenter;
