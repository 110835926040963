import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledPercentBox, StyledTestSlider } from './styled';
import ABTestSliderThumb from './ABTestSliderThumb';
import Title from '../../../Title';
import { P12B } from '../../../../../../atoms/Typography/P12B';
import { useNodeDataToSave } from '../hooks/useNodeDataToSave';

const ABTestSlider = () => {
  const { t } = useTranslation();
  const [{ aPercent, aBranchCondition, bBranchCondition }, updateDataToSave] = useNodeDataToSave();
  const [aPercentValue, setAPercentValue] = useState(() => aPercent ?? 50);

  const handleChange = (event, newValue) => {
    setAPercentValue(newValue);
  };

  const handleChangeCommitted = (event, newValue) => {
    const bValue = 100 - newValue;

    updateDataToSave({
      aPercent: newValue,
      aBranchCondition: {
        ...aBranchCondition,
        right: newValue,
        label: newValue,
        tests: [
          {
            right: `${newValue}`,
          },
        ],
      },
      bBranchCondition: {
        ...bBranchCondition,
        right: bValue,
        label: bValue,
        tests: [
          {
            right: `${bValue}`,
          },
        ],
      },
    });
  };

  return (
    <>
      <Title mb="32px">{t('Segmentation')}</Title>
      <StyledPercentBox>
        <P12B>A {aPercentValue}%</P12B>
        <P12B>B {100 - aPercentValue}%</P12B>
      </StyledPercentBox>
      <StyledTestSlider
        ThumbComponent={ABTestSliderThumb}
        valueLabelDisplay="off"
        aria-label="test-slider"
        value={aPercentValue}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
      />
    </>
  );
};

export default ABTestSlider;
