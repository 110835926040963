import styled from 'styled-components';
import Box from '@mui/material/Box';
import THEME from '../../../constants/theme';

export const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
`;

export const StyledItemBox = styled(StyledBox)`
  width: 100%;
  min-height: 48px;
  justify-content: space-between;
  border-radius: 8px;
  padding: 8px 16px 8px 12px;
  margin-bottom: 4px;
  cursor: pointer;
  background-color: ${({ $selected }) => ($selected ? THEME.greyColors.grey14 : THEME.primaryColors.white)};
  &:hover {
    background-color: ${THEME.greyColors.grey14};
  }
`;

export const StyledColumnsBox = styled(StyledBox)`
  width: calc(100% - 340px);
  max-width: calc(100% - 340px);
  overflow: auto;
`;

export const StyledSearchColumnBox = styled(StyledBox)`
  flex-direction: column;
  width: ${({ $selected }) => ($selected ? 'calc(100% - 340px)' : '100%')};
  max-width: ${({ $selected }) => ($selected ? 'calc(100% - 340px)' : '100%')};
  height: 100%;
  padding: 8px;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 255px;
  min-width: 255px;
  height: 100%;
  padding: 8px;
  border-right: 1px solid ${THEME.greyColors.grey16};
  overflow: auto;
`;

export const StyledNoDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
