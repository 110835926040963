import React from 'react';

const Percent = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M26.884 5.116C27.0001 5.23208 27.0922 5.36989 27.1551 5.52157C27.2179 5.67325 27.2502 5.83582 27.2502 6C27.2502 6.16418 27.2179 6.32675 27.1551 6.47843C27.0922 6.63011 27.0001 6.76792 26.884 6.884L6.884 26.884C6.76791 27.0001 6.63009 27.0922 6.47842 27.155C6.32674 27.2178 6.16417 27.2502 6 27.2502C5.83583 27.2502 5.67326 27.2178 5.52158 27.155C5.36991 27.0922 5.23209 27.0001 5.116 26.884C4.99991 26.7679 4.90782 26.6301 4.845 26.4784C4.78217 26.3267 4.74984 26.1642 4.74984 26C4.74984 25.8358 4.78217 25.6733 4.845 25.5216C4.90782 25.3699 4.99991 25.2321 5.116 25.116L25.116 5.116C25.2321 4.99989 25.3699 4.90779 25.5216 4.84495C25.6732 4.78211 25.8358 4.74977 26 4.74977C26.1642 4.74977 26.3268 4.78211 26.4784 4.84495C26.6301 4.90779 26.7679 4.99989 26.884 5.116ZM9 12C8.20435 12 7.44129 11.6839 6.87868 11.1213C6.31607 10.5587 6 9.79565 6 9C6 8.20435 6.31607 7.44129 6.87868 6.87868C7.44129 6.31607 8.20435 6 9 6C9.79565 6 10.5587 6.31607 11.1213 6.87868C11.6839 7.44129 12 8.20435 12 9C12 9.79565 11.6839 10.5587 11.1213 11.1213C10.5587 11.6839 9.79565 12 9 12ZM9 14C10.3261 14 11.5979 13.4732 12.5355 12.5355C13.4732 11.5979 14 10.3261 14 9C14 7.67392 13.4732 6.40215 12.5355 5.46447C11.5979 4.52678 10.3261 4 9 4C7.67392 4 6.40215 4.52678 5.46447 5.46447C4.52678 6.40215 4 7.67392 4 9C4 10.3261 4.52678 11.5979 5.46447 12.5355C6.40215 13.4732 7.67392 14 9 14ZM23 26C22.2044 26 21.4413 25.6839 20.8787 25.1213C20.3161 24.5587 20 23.7956 20 23C20 22.2044 20.3161 21.4413 20.8787 20.8787C21.4413 20.3161 22.2044 20 23 20C23.7956 20 24.5587 20.3161 25.1213 20.8787C25.6839 21.4413 26 22.2044 26 23C26 23.7956 25.6839 24.5587 25.1213 25.1213C24.5587 25.6839 23.7956 26 23 26ZM23 28C24.3261 28 25.5979 27.4732 26.5355 26.5355C27.4732 25.5979 28 24.3261 28 23C28 21.6739 27.4732 20.4021 26.5355 19.4645C25.5979 18.5268 24.3261 18 23 18C21.6739 18 20.4021 18.5268 19.4645 19.4645C18.5268 20.4021 18 21.6739 18 23C18 24.3261 18.5268 25.5979 19.4645 26.5355C20.4021 27.4732 21.6739 28 23 28Z"
      fill="black"
    />
  </svg>
);

export default Percent;
