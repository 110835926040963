import React, { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { NotificationManager } from 'react-notifications';
import isEmpty from 'lodash/isEmpty';

import { GQL_SET_PROCESSOR_AS_DEFAULT } from '../../../../utils/queries/flows/flowSettings/mutations';
import { usePaymentFlowContext } from '../../paymentFlowContext';
import { TOAST_TIMEOUT, TOAST_TITLE } from './useHandleAddToFlow';
import { isDefined } from '../../../../utils/helpers';
import ToastCustomContainer from '../../../../components/ToastCustomContainer';

const useSetPaymentProcessAsDefault = () => {
  const { flow, setSaving, setFlow, refetch, refetchLoading } = usePaymentFlowContext();

  const [setAsDefault, { loading: _loading }] = useMutation(GQL_SET_PROCESSOR_AS_DEFAULT);

  const loading = _loading || refetchLoading;

  const handleSetAsDefault = useCallback(
    async (gatewayConnectionId, connectionName, callback) => {
      setSaving(true);

      try {
        const res = await setAsDefault({
          variables: {
            flowId: flow?.id,
            gatewayConnectionId,
          },
        });

        if (!isEmpty(res?.errors)) {
          NotificationManager.error(
            <ToastCustomContainer message={res?.errors[0]?.message} title={TOAST_TITLE} />,
            null,
            TOAST_TIMEOUT
          );

          return;
        }

        if (isDefined(res?.data?.setDefaultGatewayForPaymentFlow?.id)) {
          const successMessage = `Successfully set ${connectionName ?? 'payment processor'} as default on ${
            flow?.name
          }`;

          const refetchData = await refetch();

          if (!isEmpty(refetchData?.data?.getPaymentFlow)) {
            setFlow(refetchData?.data?.getPaymentFlow);
          }

          NotificationManager.success(
            <ToastCustomContainer message={successMessage} title={TOAST_TITLE} />,
            null,
            TOAST_TIMEOUT
          );
          return;
        }

        NotificationManager.error(
          <ToastCustomContainer message="uiMessages.error" title={TOAST_TITLE} />,
          null,
          TOAST_TIMEOUT
        );
      } catch {
        NotificationManager.error(
          <ToastCustomContainer message="uiMessages.error" title={TOAST_TITLE} />,
          null,
          TOAST_TIMEOUT
        );
      } finally {
        setSaving(false);
        if (typeof callback === 'function') {
          callback();
        }
      }
    },
    [setAsDefault, flow, setSaving, setFlow, refetch]
  );

  return { handleSetAsDefault, loading };
};

export { useSetPaymentProcessAsDefault };
