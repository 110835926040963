import { PAYMENT_DESCRIBED_KEYS } from './paymentDescribedDataKeys';

export const mockedPaymentMethod = {
  key: 'paymentMethodDetails',
  type: 'OBJECT',
  label: 'Method Details',
  isEditable: false,
  value: [
    {
      key: PAYMENT_DESCRIBED_KEYS.id,
      type: 'STRING',
      label: 'Id',
      isEditable: false,
      value: 'N/A',
    },
    {
      key: PAYMENT_DESCRIBED_KEYS.number,
      type: 'STRING',
      label: 'Number',
      isEditable: false,
      value: 'N/A',
    },
    {
      key: PAYMENT_DESCRIBED_KEYS.expires,
      type: 'DATE',
      label: 'Expires',
      isEditable: false,
      value: 'N/A',
    },
    {
      key: PAYMENT_DESCRIBED_KEYS.country,
      type: 'STRING',
      label: 'Country',
      isEditable: false,
      value: 'N/A',
    },
    {
      key: PAYMENT_DESCRIBED_KEYS.fingerprint,
      type: 'STRING',
      label: 'Fingerprint',
      isEditable: false,
    },
    {
      key: PAYMENT_DESCRIBED_KEYS.paymentMethod,
      type: 'STRING',
      label: 'Payment Method',
      isEditable: false,
      value: 'N/A',
    },
  ],
  __typename: 'FieldDefinition',
};

export const mockedPaymentDetails = {
  key: 'paymentDetails',
  type: 'OBJECT',
  label: 'Details',
  isEditable: false,
  value: [
    {
      key: PAYMENT_DESCRIBED_KEYS.sourceId,
      type: 'STRING',
      label: 'Payment Processor Id',
      isEditable: false,
      value: 'N/A',
    },
    {
      key: PAYMENT_DESCRIBED_KEYS.statementDescriptor,
      type: 'STRING',
      label: 'Statement Descriptor',
      isEditable: false,
      value: 'N/A',
    },
    {
      key: PAYMENT_DESCRIBED_KEYS.localCurrency,
      type: 'STRING',
      label: 'Local Currency',
      isEditable: false,
      value: 'N/A',
    },
    {
      key: PAYMENT_DESCRIBED_KEYS.localAmount,
      type: 'AMOUNT',
      label: 'Local Amount',
      isEditable: false,
      value: 'N/A',
    },
    {
      key: PAYMENT_DESCRIBED_KEYS.exchangeRate,
      type: 'STRING',
      label: 'Exchange Rate',
      isEditable: false,
    },
    {
      key: PAYMENT_DESCRIBED_KEYS.fee,
      type: 'AMOUNT',
      label: 'Fee',
      isEditable: false,
    },
    {
      key: PAYMENT_DESCRIBED_KEYS.tax,
      type: 'AMOUNT',
      label: 'Tax',
      isEditable: false,
    },
    {
      key: PAYMENT_DESCRIBED_KEYS.processingFee,
      type: 'AMOUNT',
      label: 'Processing Fee',
      isEditable: false,
    },
    {
      key: PAYMENT_DESCRIBED_KEYS.net,
      type: 'AMOUNT',
      label: 'Net',
      isEditable: false,
    },
    {
      key: PAYMENT_DESCRIBED_KEYS.description,
      type: 'STRING',
      label: 'Description',
      isEditable: false,
    },
    {
      key: PAYMENT_DESCRIBED_KEYS.processorDeclineCode,
      type: 'STRING',
      label: 'Processor Decline Code',
      isEditable: false,
      value: 'N/A',
    },
    {
      key: PAYMENT_DESCRIBED_KEYS.reason,
      type: 'STRING',
      label: 'Processor Decline Reason',
      isEditable: false,
      value: 'N/A',
    },
    {
      key: PAYMENT_DESCRIBED_KEYS.declineType,
      type: 'STRING',
      label: 'Hard/Soft Decline',
      isEditable: false,
      value: 'N/A',
    },
    {
      key: PAYMENT_DESCRIBED_KEYS.invoiceNumber,
      type: 'STRING',
      label: 'Invoice Number',
      value: '{}',
    },
  ],
  __typename: 'FieldDefinition',
};

export const MOCK_FRAUD_DATA = {
  key: 'fraud',
  type: 'OBJECT',
  label: 'Fraud',
  value: [
    {
      key: PAYMENT_DESCRIBED_KEYS.fraudDate,
      type: 'STRING',
      label: 'Date',
      value: null,
    },
    {
      key: PAYMENT_DESCRIBED_KEYS.fraudScore,
      type: 'STRING',
      label: 'Score',
      value: 'N/A',
    },
    {
      key: PAYMENT_DESCRIBED_KEYS.fraudProvider,
      type: 'DATE',
      label: 'Fraud Provider',
      value: 'N/A',
    },
    {
      key: PAYMENT_DESCRIBED_KEYS.fraudAdditionalData,
      type: 'STRING',
      label: 'Additional data',
      value: '{}',
    },
  ],
  __typename: 'FieldDefinition',
};

export const MOCK_DISPUTE_DATA = {
  key: 'dispute',
  type: 'OBJECT',
  label: 'Dispute',
  isEditable: false,
  value: [
    {
      key: 'id',
      type: 'CONNECTION_ID_PAYMENT_DISPUTE',
      label: 'Id',
      isEditable: false,
      value: 'N/A',
      isCondition: false,
      isFilterable: true,
      canProvideUniqueRecord: false,
    },
    {
      key: 'reason',
      type: 'STRING',
      label: 'Reason',
      isEditable: false,
      value: 'N/A',
      isCondition: false,
      isFilterable: false,
      canProvideUniqueRecord: false,
    },
    {
      key: 'processorReason',
      type: 'STRING',
      label: 'Processor Reason',
      isEditable: false,
      value: 'N/A',
      isCondition: false,
      isFilterable: false,
      canProvideUniqueRecord: false,
    },
    {
      key: 'status',
      type: 'ENUM',
      label: 'Status',
      isEditable: false,
      value: 'N/A',
      isCondition: false,
      isFilterable: false,
      canProvideUniqueRecord: false,
    },
    {
      key: 'processorStatus',
      type: 'STRING',
      label: 'Processor Status',
      isEditable: false,
      value: 'N/A',
      isCondition: false,
      isFilterable: false,
      canProvideUniqueRecord: false,
    },
    {
      key: 'disputeDate',
      type: 'DATE',
      label: 'Dispute Date',
      isEditable: false,
      value: 'N/A',
      isCondition: false,
      isFilterable: false,
      canProvideUniqueRecord: false,
    },
    {
      key: 'responseDate',
      type: 'DATE',
      label: 'Response Date',
      isEditable: false,
      value: 'N/A',
      isCondition: false,
      isFilterable: false,
      canProvideUniqueRecord: false,
    },
    {
      key: 'amount',
      type: 'LONG',
      label: 'Amount',
      isEditable: false,
      value: {
        rawAmount: 'N/A',
        currency: 'N/A',
        formattedAmount: 'N/A',
      },
      isCondition: false,
      isFilterable: false,
      canProvideUniqueRecord: false,
    },
    {
      key: 'currency',
      type: 'STRING',
      label: 'Currency',
      isEditable: false,
      value: 'N/A',
      isCondition: false,
      isFilterable: false,
      canProvideUniqueRecord: false,
    },
    {
      key: 'processor',
      type: 'STRING',
      label: 'Processor',
      isEditable: false,
      value: 'N/A',
      isCondition: false,
      isFilterable: false,
      canProvideUniqueRecord: false,
    },
    {
      key: 'sourceId',
      type: 'STRING',
      label: 'Source Id',
      isEditable: false,
      value: 'N/A',
      isCondition: false,
      isFilterable: false,
      canProvideUniqueRecord: false,
    },
    {
      key: 'customerId',
      type: 'UUID',
      label: 'Customer Id',
      isEditable: false,
      value: 'N/A',
      isCondition: false,
      isFilterable: false,
      canProvideUniqueRecord: false,
    },
    {
      key: 'teamId',
      type: 'UUID',
      label: 'Team Id',
      isEditable: false,
      value: 'N/A',
      isCondition: false,
      isFilterable: false,
      canProvideUniqueRecord: false,
    },
    {
      key: 'paymentId',
      type: 'UUID',
      label: 'Payment Id',
      isEditable: false,
      value: 'N/A',
      isCondition: false,
      isFilterable: false,
      canProvideUniqueRecord: false,
    },
    {
      key: 'date',
      type: 'DATE',
      label: 'Date',
      isEditable: false,
      value: 'N/A',
      isCondition: false,
      isFilterable: false,
      canProvideUniqueRecord: false,
    },
    {
      key: 'connectionId',
      type: 'CONNECTION_ID',
      label: 'Connection Id',
      isEditable: false,
      value: 'N/A',
      isCondition: false,
      isFilterable: false,
      canProvideUniqueRecord: false,
    },
  ],
  __typename: 'FieldDefinition',
};
