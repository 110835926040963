import { useMemo } from 'react';
import { isFlowInstanceCompleted } from '../../FlowEditor/utils/flowInstanceStatus';
import { filterEdges, filterNodes } from '../../FlowEditor/utils/filterElements';
import { getFlowNodes } from '../../FlowEditor/utils/getFlowNodes';
import { EDGE_TYPE_ID, NODE_TYPE, NODE_TYPE_ID } from '../../FlowEditor/components/Node/types';

export const useFlowTestElements = ({
  nodes,
  edges,
  isAutomationTestInProgress,
  isUserDecisionRequired,
  testFlowInstance,
  currentTestStepId,
  firstStepId,
}) => {
  const reactFlowElements = useMemo(() => {
    const flowNodes = getFlowNodes(nodes);
    if (!flowNodes?.length) {
      return [];
    }
    const firstNode = flowNodes.find((node) => node.id === firstStepId);
    const startNodeX = firstNode?.position?.x - 120;
    const startNodeY = firstNode?.position?.y + 58;
    const startNode = {
      id: NODE_TYPE_ID.testNode,
      type: NODE_TYPE.startTest,
      data: {},
      position: { x: startNodeX, y: startNodeY },
    };
    const startEdge = {
      id: EDGE_TYPE_ID.testEdge,
      source: startNode?.id,
      target: firstStepId,
    };
    const baseNodes = [...flowNodes, { ...startNode }];
    const baseEdges = [...edges, { ...startEdge }];
    if (isAutomationTestInProgress) {
      const currentStepElement = baseNodes.find((node) => node?.id === currentTestStepId);
      if (currentStepElement && isUserDecisionRequired) {
        const userDecisionNode = {
          id: NODE_TYPE_ID.decisionNode,
          type: NODE_TYPE.userDecision,
          data: {},
          position: { x: currentStepElement?.position?.x + 225 || 500, y: currentStepElement?.position?.y - 2 || 500 },
        };
        return [...baseNodes, { ...userDecisionNode }, ...baseEdges];
      }
      if (isFlowInstanceCompleted(testFlowInstance)) {
        const lastNode = currentStepElement || flowNodes?.find((element) => element?.data?.nextStepId === null);
        const completedNodeX = lastNode?.position?.x + 320;
        const completedNodeY = lastNode?.position?.y + 66;
        const completedNode = {
          id: NODE_TYPE_ID.completedNode,
          type: NODE_TYPE.completedTest,
          data: {},
          position: { x: completedNodeX, y: completedNodeY },
        };
        const completedEdge = {
          id: EDGE_TYPE_ID.completedEdge,
          source: lastNode?.id,
          target: completedNode?.id,
        };
        return [...baseNodes, { ...completedNode }, ...baseEdges, { ...completedEdge }];
      }
    }
    return [...baseNodes, ...baseEdges];
  }, [
    nodes,
    firstStepId,
    edges,
    isAutomationTestInProgress,
    isUserDecisionRequired,
    testFlowInstance,
    currentTestStepId,
  ]);

  const reactFlowNodes = useMemo(() => filterNodes(reactFlowElements), [reactFlowElements]);
  const reactFlowEdges = useMemo(() => filterEdges(reactFlowElements), [reactFlowElements]);

  return {
    reactFlowNodes,
    reactFlowEdges,
  };
};
