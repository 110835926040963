import { TIMELINE_STATUS_MAP } from './constant';

const FAILED_TIMELINE_KEY = 'failed';
const DECLINED_TIMELINE_KEY = 'declined';
const REQUIRED_TIMELINE_KEY = 'required';

export const getPaymentTimelineStatus = (timelineTitle) => {
  if (typeof timelineTitle !== 'string') return timelineTitle;
  const title = timelineTitle.toLowerCase();

  if (title.includes(FAILED_TIMELINE_KEY) || title.includes(DECLINED_TIMELINE_KEY)) {
    return TIMELINE_STATUS_MAP.FAILED;
  }

  if (title.includes(REQUIRED_TIMELINE_KEY)) {
    return TIMELINE_STATUS_MAP.NOT_STARTED;
  }

  return TIMELINE_STATUS_MAP.COMPLETE;
};
