import React, { useCallback } from 'react';
import { NotificationManager } from 'react-notifications';
import PropTypes from 'prop-types';
import { StyledCopyToClipboard, StyledCopyValue } from './styled';
import { CopyToClipboard } from '../../../../../atoms';
import Copy from '../../../../../../assets/icons/Copy';
import THEME from '../../../../../../constants/theme';
import ToastCustomContainer from '../../../../../ToastCustomContainer';
import { TOAST_TIMEOUT } from '../../../../../../constants/toasts';

const WebhookCopyToClipboard = ({ value, desc }) => {
  const handleCopy = useCallback(() => {
    NotificationManager.success(
      <ToastCustomContainer message={`Copied ${desc?.toLowerCase()} to clipboard`} title={desc} />,
      null,
      TOAST_TIMEOUT
    );
  }, [desc]);

  return (
    <CopyToClipboard className="smallUrl" text={value} onCopy={handleCopy}>
      <StyledCopyToClipboard>
        <StyledCopyValue>{value}</StyledCopyValue>
        <Copy fill={THEME.greyColors.grey1} />
      </StyledCopyToClipboard>
    </CopyToClipboard>
  );
};

WebhookCopyToClipboard.propTypes = {
  value: PropTypes.string,
  desc: PropTypes.string.isRequired,
};

WebhookCopyToClipboard.defaultProps = {
  value: '',
};

export default WebhookCopyToClipboard;
