import React from 'react';
import { string } from 'prop-types';
import DropDownMenu from '../../../menus/DropDownMenu';
import { MoreButton, MoreWrapper } from './styled';
import More from '../../../../assets/icons/More';
import { useFlowEditorContext } from '../../context';
import useIsDemo from '../../../../hooks/useIsDemo';
import { useOnNodesRemove } from '../../hooks/useOnNodesRemove';

const MoreBlock = ({ id }) => {
  const isDemo = useIsDemo();
  const {
    selectedNodeId,
    setSelectedNodeId,
    setElementsAndSave,
    nodes,
    getRemoveNodeConfirmation,
  } = useFlowEditorContext();
  const selectedNode = nodes.find((node) => node.id === id);
  const onNodesRemove = useOnNodesRemove(getRemoveNodeConfirmation);

  const options = [
    {
      Edit: () => setSelectedNodeId(id),
    },
    !isDemo && {
      Delete: () =>
        onNodesRemove({ nodesToRemove: [selectedNode], selectedNodeId, setSelectedNodeId, setElementsAndSave }),
    },
  ];

  return (
    <MoreWrapper>
      <DropDownMenu
        id={`Node-${id}`}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        button={
          <MoreButton onMouseDown={(event) => event.stopPropagation()}>
            <More />
          </MoreButton>
        }
        options={options}
      />
    </MoreWrapper>
  );
};

MoreBlock.propTypes = {
  id: string.isRequired,
};

export default MoreBlock;
