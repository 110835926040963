import React from 'react';
import { gql, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import capitalize from '@mui/material/utils/capitalize';
import FormHelperText from '@mui/material/FormHelperText';
import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import { useValidationMessage } from './fields/hooks';
import Title from '../Title';
import { StyledFormControl } from './fields/styled';
import Select, { NONE } from '../../../../forms/_common/Select';
import { useNotificationManager } from '../../../../../hooks/useNotificationManager';
import LoadingState from '../LoadingState';
import { useNodeDataToSave } from './fields/hooks/useNodeDataToSave';
import SubTitle from '../SubTitle';
import { StyledFormControlLabel } from './styled';

const DataExportStep = () => {
  const { t } = useTranslation();
  const validationMessage = useValidationMessage('export');
  const [{ viewId, includeAttachments }, updateDataToSave] = useNodeDataToSave();
  const { loading, error, data } = useQuery(gql`
    query listPaymentViews {
      listPaymentViews {
        views {
          id
          name
        }
      }
    }
  `);
  const options = data?.listPaymentViews?.views.map((view) => ({ value: view.id, title: view.name }));
  const handleIncludeAttachmentsChange = ({ target }) => updateDataToSave({ includeAttachments: target.checked });
  const onChange = ({ target: { value } }) =>
    updateDataToSave({
      viewId: value !== NONE ? value : null,
      includeAttachments: value !== NONE ? includeAttachments : null,
    });

  useNotificationManager('error', error?.message, 'Fetch Payment Views');

  return (
    <LoadingState loading={loading}>
      <Box>
        <SubTitle>
          {t('You can export a saved view from Insights. Customize a report to Export in Insights to export it here')}
        </SubTitle>
        <Title>{t('Select which report to export')}</Title>
        {options && (
          <StyledFormControl fullWidth error={Boolean(validationMessage)}>
            <Select value={viewId || NONE} options={options} onChange={onChange} />

            <FormHelperText>{capitalize(t(validationMessage) || '')}</FormHelperText>
          </StyledFormControl>
        )}
      </Box>
      {!!viewId && (
        <Box mt="16px">
          <StyledFormControlLabel
            value={includeAttachments ?? false}
            control={
              <Checkbox
                checked={includeAttachments ?? false}
                onChange={handleIncludeAttachmentsChange}
                name="includeAttachments"
                color="primary"
              />
            }
            label={
              <Box mt="4px" fontSize="14px" fontWeight="600">
                {t('Include Attachments')}
              </Box>
            }
          />
        </Box>
      )}
    </LoadingState>
  );
};

export default DataExportStep;
