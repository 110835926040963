import isEmpty from 'lodash/isEmpty';
import reduceValue from './reduceValue';

const getDisputesTimeline = ({ payment }) => {
  if (isEmpty(payment?.dispute?.value)) return null;

  const disputesValues = reduceValue(payment.dispute.value);

  if (isEmpty(disputesValues)) return null;

  const { disputeTimeline } = disputesValues;

  const reducedTimeline = reduceValue(disputeTimeline);

  if (isEmpty(reducedTimeline.events) || !Array.isArray(reducedTimeline.events)) return null;

  const disputeTimelineWithKeys = reducedTimeline.events.map((timelineObj) => ({
    ...(timelineObj || {}),
    key: `${timelineObj.title}-${timelineObj.time}`,
  }));

  return disputeTimelineWithKeys;
};

export { getDisputesTimeline };
