import capitalize from '@mui/material/utils/capitalize';
import { formNames } from './schema';

const OTHERS_VALUE = 'OTHER';

const CUSTOM_SELECT_PROPS = {
  placeholder: 'Select',
};

const generateOptions = (values) =>
  values.map((value) => ({
    value: value
      .split(' ')
      .join('_')
      .toUpperCase(),
    text: { text: value },
  }));

const PAYMENT_PROCESSORS_OPTIONS = generateOptions([
  'Adyen',
  'Authorize.net',
  'Bambora',
  'Braintree',
  'Checkout.com',
  'Global Payments',
  'Moneris',
  'PayPal',
  'Stripe',
  'Worldline',
  'WorldPay',
  'Other',
]);

const SELECT_OPTIONS = {
  [formNames.paymentProcessors]: PAYMENT_PROCESSORS_OPTIONS,
};

const OTHERS_INPUT_NAME_MAP = {
  [formNames.paymentProcessors]: formNames.paymentProcessorsOther,
};

const renderValue = (values, max) => {
  if (!Array.isArray(values)) return values;
  if (Array.isArray(values) && values.some((value) => typeof value !== 'string')) return values;

  return (
    values
      ?.slice(0, max || values.length)
      ?.map((value) => capitalize(value?.toLowerCase()?.replace(/_/g, ' ') || ''))
      ?.join(', ') + (values?.length > (max || values.length) ? `...` : '')
  );
};

const RENDER_VALUE_MAP = {
  [formNames.paymentProcessors]: (value) => renderValue(value, 3),
};

export { SELECT_OPTIONS, CUSTOM_SELECT_PROPS, OTHERS_VALUE, OTHERS_INPUT_NAME_MAP, RENDER_VALUE_MAP, renderValue };
