import { bool, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../../assets/icons';
import { isDefined } from '../../utils/helpers';

const ToastCustomContainer = ({ title, message, skipDot }) => {
  const { t } = useTranslation();

  const tempTitle = t(title);
  const tempMessage = t(message);
  const toastTitle = tempTitle.endsWith('.') || tempTitle.endsWith('!') ? tempTitle : `${tempTitle}.`;
  const toastMessage = tempMessage.endsWith('.') || tempMessage.endsWith('!') ? tempMessage : `${tempMessage}.`;

  return (
    <div className="customContainer">
      <p className="customContainer__message">
        <span className="customContainer__message__title">{skipDot ? title : toastTitle}</span>{' '}
        {isDefined(message) && toastMessage}
      </p>
      <div className="customContainer__closIcon">
        <CloseIcon stroke="white" width="30" height="30" />
      </div>
    </div>
  );
};

ToastCustomContainer.propTypes = {
  title: string.isRequired,
  message: string.isRequired,
  skipDot: bool,
};

ToastCustomContainer.defaultProps = {
  skipDot: false,
};

export default ToastCustomContainer;
