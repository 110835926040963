import styled from 'styled-components';
import { Snackbar } from '@mui/material';
import THEME from '../../constants/theme';
import { ButtonRounded } from '../../components/atoms';

const StyledExternalLink = styled.a`
  margin-left: 6px;
  color: ${THEME.primaryColors.primary};
`;

const DividerLine = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  margin-bottom: 20px;
  background: ${THEME.greyColors.grey5};
`;

const Logo = styled.img`
  position: relative;
`;

const SnackbarWrapper = styled.div`
  transform: ${({ open }) => (open ? 'translateX(0)' : `translateX(100%)`)};
  transition: all 0.3s ease-in-out;
`;

const StyledSnackbar = styled(Snackbar)`
  &.MuiSnackbar-root {
    width: 380px;
    border-radius: 8px;
    right: 16px;
    left: auto;
    bottom: 16px;
    transform: none;
  }

  & .MuiSnackbarContent-root {
    background-color: ${THEME.greyColors.grey900};
    padding: 0;
  }

  & .MuiSnackbarContent-message {
    padding: 16px;

    p {
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;

      .greyText {
        color: ${THEME.greyColors.grey300};
      }
    }
  }

  & .MuiSnackbarContent-action {
    width: 100%;
    display: flex;
    margin: 0;
    padding: 0 16px 16px 16px;
  }
`;

const StyledButton = styled(ButtonRounded)`
  &.MuiButtonBase-root {
    border-radius: 6px;

    &:hover {
      cursor: pointer;
    }

    &:first-child {
      margin-right: 8px;
    }
  }

  &.MuiButton-containedPrimary {
    background-color: ${THEME.greyColors.grey800};
    flex: 2;

    &:hover {
      background-color: ${THEME.greyColors.grey23};
    }
  }

  &.MuiButton-textSecondary {
    color: ${THEME.greyColors.grey300};
    border: 1px solid transparent;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    min-width: 84px;

    &:hover {
      color: ${THEME.primaryColors.white};
      border: 1px solid ${THEME.greyColors.grey23};
    }
  }
`;

export { StyledExternalLink, DividerLine, Logo, SnackbarWrapper, StyledSnackbar, StyledButton };
