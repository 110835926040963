import ProcessorComponent from './_common/ProcessorComponent';
import DateComponent from './_common/DateComponent';
import JsonComponent from './_common/JsonComponent';
import CountryIconLabelComponent from './_common/CountryIconLabelComponent';
import LongStringComponent from './_common/LongStringComponent';
import AmountComponent from './_common/AmountComponent';
import IndentedTextComponent from './_common/IndentedTextComponent';
import StatusComponent from './_common/StatusComponent';

import { PAYMENT_DESCRIBED_KEYS } from './paymentDescribedDataKeys';

const PAYMENT_DETAILS_SECTION_COMPONENT_MAP = {
  [PAYMENT_DESCRIBED_KEYS.paymentProcessor]: ProcessorComponent,
  [PAYMENT_DESCRIBED_KEYS.fraudDate]: DateComponent,
  [PAYMENT_DESCRIBED_KEYS.disputeDate]: DateComponent,
  [PAYMENT_DESCRIBED_KEYS.responseDate]: DateComponent,
  [PAYMENT_DESCRIBED_KEYS.fraudAdditionalData]: JsonComponent,
  [PAYMENT_DESCRIBED_KEYS.intentLocation]: CountryIconLabelComponent,
  [PAYMENT_DESCRIBED_KEYS.country]: CountryIconLabelComponent,
  [PAYMENT_DESCRIBED_KEYS.sourceId]: LongStringComponent,
  [PAYMENT_DESCRIBED_KEYS.id]: LongStringComponent,
  [PAYMENT_DESCRIBED_KEYS.customerId]: LongStringComponent,
  [PAYMENT_DESCRIBED_KEYS.teamId]: LongStringComponent,
  [PAYMENT_DESCRIBED_KEYS.paymentId]: LongStringComponent,
  [PAYMENT_DESCRIBED_KEYS.connectionId]: LongStringComponent,
  [PAYMENT_DESCRIBED_KEYS.amount]: AmountComponent,
  [PAYMENT_DESCRIBED_KEYS.processingFee]: IndentedTextComponent,
  [PAYMENT_DESCRIBED_KEYS.tax]: IndentedTextComponent,
  [PAYMENT_DESCRIBED_KEYS.status]: StatusComponent,
  [PAYMENT_DESCRIBED_KEYS.processorStatus]: StatusComponent,
};

export { PAYMENT_DETAILS_SECTION_COMPONENT_MAP };
