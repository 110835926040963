import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { NotificationManager } from 'react-notifications';
import { getFirstErrorMessage } from '../../../../../utils/graphql';
import { messages } from '../../../../../client/links/errorLink';
import { TOAST_TIMEOUT, TOAST_UNEXPECTED_ERROR_MESSAGE } from '../../../../../constants/toasts';
import { useFlowEditorContext } from '../../../context';
import ToastCustomContainer from '../../../../ToastCustomContainer';

const TOAST_TITLE = 'Retry flow instance';

export const useHandleRetry = () => {
  const { selectedRetryStepId, setSelectedRetryStepId } = useFlowEditorContext();
  const {
    selectedInstance,
    retryInstancePromise,
    setRetryingStepId,
    setSelectedInstance,
    setSelectedInstanceId,
  } = useFlowEditorContext();

  const handleRetry = async () => {
    try {
      setRetryingStepId(selectedRetryStepId);
      const res = await retryInstancePromise({
        variables: {
          stepId: selectedRetryStepId,
          originalInstanceId: selectedInstance.originalInstanceId || selectedInstance.id,
        },
      });
      if (!isEmpty(res?.errors) || !res?.data?.retryInstance) {
        // Error handling
        const error = getFirstErrorMessage(res?.errors);
        const errorMessage = messages[error] ?? error ?? TOAST_UNEXPECTED_ERROR_MESSAGE;
        NotificationManager.error(
          <ToastCustomContainer message={errorMessage} title={TOAST_TITLE} />,
          null,
          TOAST_TIMEOUT
        );

        return;
      }
      // Success handling
      const retryInstance = res?.data?.retryInstance;
      setSelectedInstanceId(retryInstance?.id);
      setSelectedInstance(retryInstance);
    } catch {
      // Error handling
      NotificationManager.error(
        <ToastCustomContainer message={TOAST_UNEXPECTED_ERROR_MESSAGE} title={TOAST_TITLE} />,
        null,
        TOAST_TIMEOUT
      );
    } finally {
      setRetryingStepId(null);
      setSelectedRetryStepId(null);
    }
  };

  return {
    handleRetry,
  };
};
