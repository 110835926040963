import React from 'react';

const ChevronDown = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 10L12 13L16 10" stroke="#787F88" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
    </svg>
  );
};

export default ChevronDown;
