import React from 'react';
import PropTypes from 'prop-types';

const Upload = ({ fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1715_697)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.02794 7.52851C8.28829 7.26816 8.7104 7.26816 8.97075 7.52851L11.6374 10.1952C11.8978 10.4555 11.8978 10.8776 11.6374 11.138C11.3771 11.3983 10.955 11.3983 10.6946 11.138L8.49935 8.94273L6.30409 11.138C6.04374 11.3983 5.62163 11.3983 5.36128 11.138C5.10093 10.8776 5.10093 10.4555 5.36128 10.1952L8.02794 7.52851Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.50065 7.33325C8.86884 7.33325 9.16732 7.63173 9.16732 7.99992V13.9999C9.16732 14.3681 8.86884 14.6666 8.50065 14.6666C8.13246 14.6666 7.83398 14.3681 7.83398 13.9999V7.99992C7.83398 7.63173 8.13246 7.33325 8.50065 7.33325Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.27518 1.33903C7.18151 1.30564 8.08361 1.47814 8.91367 1.84357C9.74373 2.20901 10.4801 2.75786 11.0676 3.44886C11.5419 4.00681 11.909 4.64572 12.1524 5.33332H12.4996C13.3883 5.33391 14.252 5.63043 14.9534 6.17608C15.6549 6.72173 16.1546 7.48544 16.3739 8.34667C16.5931 9.20791 16.5192 10.1176 16.164 10.9322C15.8088 11.7468 15.1924 12.4199 14.4121 12.8453C14.0888 13.0216 13.6839 12.9024 13.5076 12.5791C13.3314 12.2558 13.4506 11.8509 13.7739 11.6747C14.2941 11.3911 14.705 10.9423 14.9418 10.3993C15.1786 9.85618 15.2279 9.24971 15.0817 8.67556C14.9356 8.1014 14.6024 7.59226 14.1348 7.22849C13.6671 6.86473 13.0917 6.66705 12.4992 6.66666H11.6596C11.3557 6.66666 11.0903 6.46111 11.0142 6.16686C10.8376 5.48392 10.5085 4.84989 10.0517 4.31244C9.5948 3.77499 9.02203 3.34811 8.37643 3.06388C7.73083 2.77966 7.02919 2.64549 6.32427 2.67146C5.61935 2.69743 4.92949 2.88287 4.30656 3.21383C3.68362 3.5448 3.14382 4.01267 2.72773 4.58228C2.31165 5.1519 2.03011 5.80842 1.90428 6.50251C1.77844 7.19659 1.8116 7.91017 2.00124 8.5896C2.19089 9.26902 2.5321 9.89662 2.9992 10.4252C3.24302 10.7011 3.21701 11.1224 2.94111 11.3662C2.66521 11.61 2.24391 11.584 2.00009 11.3081C1.39952 10.6285 0.960833 9.82161 0.717002 8.94806C0.473171 8.07452 0.430545 7.15706 0.592327 6.26466C0.75411 5.37227 1.11609 4.52816 1.65106 3.7958C2.18603 3.06344 2.88006 2.46189 3.68098 2.03637C4.48189 1.61084 5.36886 1.37242 6.27518 1.33903Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.02794 7.52851C8.28829 7.26816 8.7104 7.26816 8.97075 7.52851L11.6374 10.1952C11.8978 10.4555 11.8978 10.8776 11.6374 11.138C11.3771 11.3983 10.955 11.3983 10.6946 11.138L8.49935 8.94273L6.30409 11.138C6.04374 11.3983 5.62163 11.3983 5.36128 11.138C5.10093 10.8776 5.10093 10.4555 5.36128 10.1952L8.02794 7.52851Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1715_697">
        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
Upload.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};
Upload.defaultProps = {
  fill: '#000',
  height: '16',
  width: '17',
};

export default Upload;
