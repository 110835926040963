import React from 'react';

const Giropay = () => {
  return (
    <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="34" height="24" rx="4" fill="white" />
      <rect
        x="0.25"
        y="0.25"
        width="33.5"
        height="23.5"
        rx="3.75"
        stroke="black"
        strokeOpacity="0.15"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.18104 7.74278C5.57821 7.74376 5.09047 8.1883 5.0893 8.73765V8.73766V15.3427V15.3427C5.08944 15.4114 5.09719 15.4784 5.11182 15.5431C5.13015 15.6242 5.15929 15.7017 5.19781 15.7743C5.37437 16.1072 5.74801 16.3368 6.18104 16.3376H17.5402V16.3376V7.74279V7.74278H6.18104ZM6.18104 7H27.9207C28.9739 7.00016 29.8271 7.77758 29.8275 8.73765V15.3427C29.8271 16.3024 28.9739 17.0799 27.9207 17.0803H6.18104C5.12781 17.0799 4.27465 16.3024 4.27429 15.3427V8.73765C4.27465 7.77758 5.12781 7.00016 6.18104 7Z"
        fill="#0F3365"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3992 14.2624H18.4456V10.7322H19.3251V11.1862H19.3358C19.5265 10.8337 19.9293 10.6743 20.3638 10.6743C21.127 10.6743 21.5453 11.336 21.5453 11.9443C21.5453 12.6881 21.1055 13.3062 20.2842 13.3062C19.9504 13.3062 19.5901 13.1953 19.4098 12.8959H19.3992V14.2624ZM19.9824 12.6398C20.3425 12.6398 20.5598 12.3647 20.5598 11.9637C20.5598 11.6304 20.3532 11.3407 19.9824 11.3407C19.6325 11.3407 19.3992 11.6209 19.3992 11.9975C19.3992 12.3647 19.6644 12.6398 19.9824 12.6398ZM25.1997 14.2624C25.3959 14.3012 25.5918 14.3205 25.7933 14.3205C26.6182 14.3205 26.8487 13.7745 27.0989 13.182C27.1139 13.1463 27.129 13.1106 27.1443 13.0747L28.1566 10.7322H27.2028L26.6357 12.3741H26.6251L26.0316 10.7322H25.004L26.1215 13.3062C26.053 13.5284 25.8728 13.654 25.64 13.654C25.5071 13.654 25.3905 13.6398 25.2636 13.6008L25.1997 14.2624ZM24.8443 13.3301C24.802 13.1321 24.7911 12.9343 24.7911 12.7361V11.7993C24.7911 11.0314 24.1819 10.7563 23.4771 10.7563C23.0689 10.7563 22.714 10.8092 22.3749 10.935L22.3906 11.5241C22.6558 11.389 22.963 11.3357 23.2702 11.3357C23.6148 11.3357 23.8958 11.4275 23.901 11.7705C23.7791 11.751 23.6097 11.7363 23.456 11.7363C22.9473 11.7363 22.0306 11.8284 22.0306 12.5962C22.0306 13.1421 22.5181 13.3883 23.0639 13.3883C23.456 13.3883 23.7209 13.2481 23.9381 12.9343H23.9484C23.9484 13.0108 23.954 13.0874 23.9597 13.165L23.9597 13.165C23.9637 13.2195 23.9677 13.2744 23.9698 13.3301H24.8443ZM22.9206 12.5335C22.9206 12.292 23.1749 12.2003 23.4982 12.2003C23.5996 12.2003 23.6982 12.2051 23.7904 12.2096L23.7904 12.2096C23.8285 12.2115 23.8654 12.2133 23.901 12.2147C23.901 12.5092 23.6733 12.809 23.3126 12.809C23.0902 12.809 22.9206 12.7071 22.9206 12.5335Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1565 13.248H11.1102V10.7321H10.1565V13.248ZM10.1565 10.3215H11.1102V9.68408H10.1565V10.3215ZM13.4429 10.6742C13.554 10.6742 13.6705 10.6887 13.766 10.7029L13.7182 11.4273C13.6124 11.3985 13.5063 11.3985 13.3952 11.3985C12.9501 11.3985 12.7009 11.6931 12.7009 12.1858V13.248H11.7472V10.7321H12.6162V11.1956H12.6267C12.7911 10.877 13.0296 10.6742 13.4429 10.6742ZM17.0136 11.9926C17.0136 12.8233 16.3194 13.3061 15.4082 13.3061C14.4969 13.3061 13.8027 12.8233 13.8027 11.9926C13.8027 11.157 14.4969 10.6742 15.4082 10.6742C16.3194 10.6742 17.0136 11.157 17.0136 11.9926ZM14.7882 11.9926C14.7882 12.3645 14.9684 12.6976 15.4082 12.6976C15.848 12.6976 16.0282 12.3645 16.0282 11.9926C16.0282 11.6159 15.848 11.2827 15.4082 11.2827C14.9684 11.2827 14.7882 11.6159 14.7882 11.9926ZM8.63449 10.7321H9.49838V12.9537C9.49838 13.987 8.94732 14.3204 7.83455 14.3204C7.37893 14.3204 7.00268 14.2332 6.78006 14.1611L6.82787 13.4752C7.11923 13.6057 7.36296 13.6828 7.7604 13.6828C8.31155 13.6828 8.60815 13.4463 8.60815 12.9537V12.8182H8.59741C8.36974 13.1132 8.05186 13.248 7.67038 13.248C6.91257 13.248 6.44098 12.7269 6.44098 11.9829C6.44098 11.2342 6.82787 10.6742 7.68617 10.6742C8.09407 10.6742 8.42269 10.877 8.62411 11.1859H8.63449V10.7321ZM7.96709 11.3406C7.61205 11.3406 7.42659 11.6255 7.42659 11.9683C7.42659 12.3593 7.68096 12.5817 7.96709 12.5817C8.30608 12.5817 8.57643 12.321 8.57643 11.9345C8.57643 11.6542 8.3962 11.3406 7.96709 11.3406Z"
        fill="#D8232A"
      />
    </svg>
  );
};

export default Giropay;
