import React from 'react';
import Box from '@mui/material/Box';
import { useToggle } from 'react-use';
import OptionsMenu from '../../../atoms/OptionsMenu';
import THEME from '../../../../constants/theme';
import { useFlowEditorContext } from '../../context';
import { useOnNodesRemove } from '../../hooks/useOnNodesRemove';
import AddNickname from './dialogs/AddNickname';

const MoreBlock = () => {
  const {
    selectedNodeId,
    setSelectedNodeId,
    selectedNode,
    setElementsAndSave,
    getRemoveNodeConfirmation,
  } = useFlowEditorContext();
  const onNodesRemove = useOnNodesRemove(getRemoveNodeConfirmation);
  const [isModalOpen, toggleModal] = useToggle(false);

  const options = [
    {
      onClick: toggleModal,
      label: 'Add nickname',
    },
    {
      onClick: () =>
        onNodesRemove({
          nodesToRemove: [selectedNode],
          selectedNodeId,
          setSelectedNodeId,
          setElementsAndSave,
        }),
      label: 'Delete',
    },
  ];

  return (
    <Box ml="8px">
      <OptionsMenu
        options={options}
        bgcolor={THEME.primaryColors.white}
        color={THEME.primaryColors.black}
        lastItemDanger
        vertical
      />
      {isModalOpen && <AddNickname open={isModalOpen} closeModal={toggleModal} />}
    </Box>
  );
};

export default MoreBlock;
