import React, { useState } from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, capitalize } from '@mui/material';
import ChevronDown2 from '../../../assets/icons/ChevronDown2';
import THEME from '../../../constants/theme';
import { SwitcherWrapper } from './styled';
import { PROD_URL, SANDBOX_URL } from '../../../constants/api';
import Popover from './Popover';
import { ENVIRONMENT } from '../../../constants/environment';

const Switcher = ({ enviornment }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = ({ currentTarget }) => {
    return setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = [
    {
      onClick: () => {
        if (enviornment !== ENVIRONMENT.sandbox) window.open(SANDBOX_URL, '_self');
      },
      label: ENVIRONMENT.sandbox,
    },
    {
      onClick: () => {
        if (enviornment !== ENVIRONMENT.prod) window.open(PROD_URL, '_self');
      },
      label: ENVIRONMENT.prod,
    },
  ];

  if (enviornment === ENVIRONMENT.prod) {
    options.reverse();
  }

  return (
    <SwitcherWrapper open={open}>
      <Box onClick={handleOpen} display="flex" alignItems="center">
        <h1>{t(capitalize(enviornment))}</h1>
        <ChevronDown2 width="28" height="28" color={THEME.primaryColors.primary} />
      </Box>

      {anchorEl && (
        <Popover
          open={true}
          anchorEl={anchorEl}
          options={options}
          handleClose={handleClose}
          enviornment={enviornment}
        />
      )}
    </SwitcherWrapper>
  );
};

Switcher.propTypes = {
  enviornment: string.isRequired,
};
export default Switcher;
