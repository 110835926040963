import { UI_ROUTES } from './routes';

export const REG_SESSION_STORAGE_KEY = 'wt-registration';
export const GOOGLE_EMAIL_SWITCHER_PARAM_KEY = 'signUpState';

export const ONBOARDING_STATUS = {
  personalDetails: 'PERSONAL_DETAILS',
  companyDetails: 'COMPANY_DETAILS',
  paymentDetails: 'PAYMENT_DETAILS',
  acceptingPayments: 'ACCEPTING_PAYMENTS',
  paymentProcessor: 'PAYMENT_PROCESSOR',
  paymentProcessors: 'PAYMENT_PROCESSORS',
  paymentMarkets: 'PAYMENT_MARKETS',
  paymentCurrency: 'PAYMENT_CURRENCY',
  transactionsAmount: 'TRANSACTIONS_AMOUNT',
  revenueModel: 'REVENUE_MOREL',
  productsServices: 'PRODUCTS_SERVICES',
  welcomeToBeta: 'WELCOME_TO_BETA',
  thanksForInterest: 'THANKS_FOR_INTEREST',
  completeRegistration: 'COMPLETE_REGISTRATION',
  completeInvitation: 'COMPLETE_INVITATION',
};

export const ONBOARDING_STATUS_WITH_ROUTES = {
  personalDetails: { name: 'PERSONAL_DETAILS', route: UI_ROUTES.signUpPersonalDetails },
  companyDetails: { name: 'COMPANY_DETAILS', route: UI_ROUTES.signUpCompanyDetails },
  paymentDetails: { name: 'PAYMENT_DETAILS', route: UI_ROUTES.signUpPaymentDetails },
  acceptingPayments: { name: 'ACCEPTING_PAYMENTS', route: UI_ROUTES.signUpAcceptingPayments },
  paymentProcessor: { name: 'PAYMENT_PROCESSOR', route: UI_ROUTES.signUpPaymentProcessor },
  paymentProcessors: { name: 'PAYMENT_PROCESSORS', route: UI_ROUTES.signUpPaymentProcessors },
  paymentMarkets: { name: 'PAYMENT_MARKETS', route: UI_ROUTES.signUpMarkets },
  paymentCurrency: { name: 'PAYMENT_CURRENCY', route: UI_ROUTES.signUpCurrency },
  transactionsAmount: { name: 'TRANSACTIONS_AMOUNT', route: UI_ROUTES.signUpTransactionsAmount },
  revenueModel: { name: 'REVENUE_MOREL', route: UI_ROUTES.signUpRevenueModel },
  productsServices: { name: 'PRODUCTS_SERVICES', route: UI_ROUTES.signUpProductsServices },
  welcomeToBeta: { name: 'WELCOME_TO_BETA', route: UI_ROUTES.welcomeToBeta },
  thanksForInterest: { name: 'THANKS_FOR_INTEREST', route: null },
  completeRegistration: { name: 'COMPLETE_REGISTRATION', route: UI_ROUTES.completeSignUp },
  completeInvitation: { name: 'COMPLETE_INVITATION', route: UI_ROUTES.invite },
  checkYourEmail: { name: 'CHECK_YOUR_EMAIL', route: UI_ROUTES.checkYourEmail },
};

export const ROUTE_BY_ONBOARDING_STATUS_MAP = {
  [ONBOARDING_STATUS.personalDetails]: UI_ROUTES.signUpPersonalDetails,
  [ONBOARDING_STATUS.companyDetails]: UI_ROUTES.signUpCompanyDetails,
  [ONBOARDING_STATUS.paymentDetails]: UI_ROUTES.signUpPaymentDetails,
  [ONBOARDING_STATUS.acceptingPayments]: UI_ROUTES.signUpAcceptingPayments,
  [ONBOARDING_STATUS.paymentProcessor]: UI_ROUTES.signUpPaymentProcessor,
  [ONBOARDING_STATUS.paymentProcessors]: UI_ROUTES.signUpPaymentProcessors,
  [ONBOARDING_STATUS.paymentMarkets]: UI_ROUTES.signUpMarkets,
  [ONBOARDING_STATUS.paymentCurrency]: UI_ROUTES.signUpCurrency,
  [ONBOARDING_STATUS.transactionsAmount]: UI_ROUTES.signUpTransactionsAmount,
  [ONBOARDING_STATUS.revenueModel]: UI_ROUTES.signUpRevenueModel,
  [ONBOARDING_STATUS.productsServices]: UI_ROUTES.signUpProductsServices,
  [ONBOARDING_STATUS.welcomeToBeta]: UI_ROUTES.welcomeToBeta,
  [ONBOARDING_STATUS.thanksForInterest]: UI_ROUTES.thanksForInterest,
};

export const REG_METHOD = {
  email: 'email',
  google: 'google',
  googleEmail: 'google-email', // FE only -  signed up with email but completing registration with password
};

export const SIGN_UP_REG_METHOD_MAP = {
  [REG_METHOD.email]: 'EMAIL',
  [REG_METHOD.google]: 'GOOGLE',
};

// TODO: Update BE to use the same registration methods both for SignUp and CompleteSignUp
export const COMPLETE_SIGN_UP_REG_METHOD_MAP = {
  [REG_METHOD.email]: 'email',
  [REG_METHOD.google]: 'google',
  [REG_METHOD.googleEmail]: 'google-email', // FE only -  signed up with email but completing registration with password
};
