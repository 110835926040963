import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { func } from 'prop-types';
import { isEmpty } from 'lodash';
import Box from '@mui/material/Box';
import { initialValues, fields, validator } from './schema';
import { DEFAULT_LOGIN } from '../../../../utils/queries/public/publicMutations';
import useMutationSetTokens from '../../../../hooks/useMutationSetTokens';
import { MUTATION_NAMES } from '../../../../constants/api';
import SubmitButton from '../../../../components/forms/_common/SubmitButton';
import Input from '../../../../components/forms/_common/Input';
import TagInput from '../../../../components/forms/_common/TagInput';
import { Wrapper, GroupsWrapper, GroupContainer, SubmitButtonWrapper } from './styled';
import { CountrySelectAlpha2 } from '../../../DataTables/components/FormComponents/CountrySelect';
import BusinessTypeSelect from '../../../../components/forms/selects/BusinessTypeSelect';
import SectorTypeSelect from '../../../../components/forms/selects/SectorTypeSelect';
import MerchantCategoryAutocomplete from '../../../../components/forms/selects/MerchantCategoryAutocomplete';
import { SIGNUP_MANGOPAY_INTERNAL } from '../query';
import { useGlobalContext } from '../../../../containers/App/context';
import { transformDataToCustomerObject, showErrorNotification, TOAST_TITLE } from './constant';
import { showNotification } from '../../../../hooks/useNotificationManager';
import { TOAST_TYPES, TOAST_TIMEOUT } from '../../../../constants/toasts';
import { P16B } from '../../../../components/atoms';

const COMPONENT_MAP = {
  input: Input,
  countrySelect: CountrySelectAlpha2,
  tagInput: TagInput,
  businessTypeSelect: BusinessTypeSelect,
  sectorTypeSelect: SectorTypeSelect,
  merchantCategoryAutocomplete: MerchantCategoryAutocomplete,
};

const TOAST_MESSAGE_SUCCESS = 'Your registration has been successfully completed';

const MangopaySignupForm = () => {
  const { t } = useTranslation();
  const [, { loading }] = useMutationSetTokens(DEFAULT_LOGIN, MUTATION_NAMES.login);
  const [signupMangoPayInternal] = useMutation(SIGNUP_MANGOPAY_INTERNAL);
  const { setGlobalLoading } = useGlobalContext();
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    setGlobalLoading('signupMangoPayInternalSubmission', true);

    const customer = transformDataToCustomerObject(values);

    const { data, errors } = await signupMangoPayInternal({
      variables: { customer },
      context: { skipGlobalHandling: true },
    });

    if (!isEmpty(data) && isEmpty(errors)) {
      setGlobalLoading('signupMangoPayInternalSubmission', false);
      showNotification(TOAST_TYPES.success, TOAST_MESSAGE_SUCCESS, TOAST_TITLE, TOAST_TIMEOUT);
      resetForm();
      setSubmitted(true);
    } else {
      showErrorNotification(errors);
      setGlobalLoading('signupMangoPayInternalSubmission', false);
    }
  };

  return (
    <Wrapper>
      <Formik
        initialValues={initialValues}
        validate={validator}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnMount
      >
        <Form>
          <GroupsWrapper>
            {fields.map(({ id, groups }) => {
              return (
                <Box key={id} display="flex" flexDirection="column" flex="1">
                  {groups.map(({ title, inputs }) => {
                    return (
                      <GroupContainer key={title}>
                        <P16B margin="0 0 8px 0">{t(title)}</P16B>

                        {inputs.map(
                          ({ field, type, componentType, label, isRequired, placeholder, tooltip, disabled }) => {
                            const Component = COMPONENT_MAP[componentType] ?? COMPONENT_MAP.input;
                            const isDropdownComponent = componentType.includes('Select');

                            const componentProps = {
                              name: field,
                              type,
                              label,
                              isRequired,
                              placeholder: t(placeholder),
                              disabled,
                            };

                            return (
                              <React.Fragment key={label}>
                                <Component
                                  {...componentProps}
                                  tooltip={tooltip}
                                  margin="0 0 12px 0"
                                  {...(isDropdownComponent && { labelHeight: '30px' })}
                                  {...(componentType === 'tagInput' && { submitted, validateEmails: true })}
                                  {...(componentType === 'input' && { customLabel: true })}
                                />
                              </React.Fragment>
                            );
                          }
                        )}
                      </GroupContainer>
                    );
                  })}
                </Box>
              );
            })}
          </GroupsWrapper>

          <SubmitButtonWrapper>
            <SubmitButton isLoading={loading} width="100%" showIcon={false}>
              {t('Sign Up')}
            </SubmitButton>
          </SubmitButtonWrapper>
        </Form>
      </Formik>
    </Wrapper>
  );
};

MangopaySignupForm.propTypes = {
  setIncorrectLoginCount: func,
};

MangopaySignupForm.defaultProps = {
  setIncorrectLoginCount: () => {},
};
export default MangopaySignupForm;
