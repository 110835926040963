import { isNode } from 'react-flow-renderer';
import { flowStepTypes } from '../types';
import { edgeMarkers } from './edgeMarkers';

export const repopulateEdges = (elements, isFlowMonitor = false) => {
  /* filter nodes */
  const { nodes, set } = elements.reduce(
    (acc, element) => {
      if (!isNode(element)) {
        return acc;
      }
      acc.nodes.push(element);
      acc.set.add(element.id);
      return acc;
    },
    {
      nodes: [],
      set: new Set(), // unique node ID
    }
  );
  return nodes.reduce(
    (acc, node) => {
      const { data } = node;
      const {
        id,
        __typename: typename,
        group,
        layout,
        nextStepId,
        trueStepId,
        falseStepId,
        elseStepId,
        aBranchCondition,
        bBranchCondition,
        conditions,
      } = data;
      const uiMetadata = layout?.uiMetadata;

      if (set.has(elseStepId)) {
        let type = 'default';
        if (isFlowMonitor) {
          type = 'edgeWithRetryButton';
        } else if (
          (group === 'Conditions' && typename === flowStepTypes.DataConditionStep) ||
          (data.group === 'Conditions' && typename === flowStepTypes.CountConditionStep)
        ) {
          type = 'edgeWithCondition';
        } else if (group === 'Conditions') {
          type = 'edgeWithButton';
        }
        acc.push({
          id: `e-else-${id}-${elseStepId}`,
          source: id,
          sourceHandle: uiMetadata?.[elseStepId]?.sourceHandle,
          targetHandle: uiMetadata?.[elseStepId]?.targetHandle,
          target: elseStepId,
          type,
        });
      }

      if (
        set.has(nextStepId) &&
        !(typename === flowStepTypes.DataConditionStep || typename === flowStepTypes.CountConditionStep)
      ) {
        let type = 'default';
        if (isFlowMonitor) {
          type = 'edgeWithRetryButton';
        } else if (group === 'Conditions') {
          type = 'edgeWithButton';
        } else if (typename === 'IfElseStep') {
          type = 'edgeWithIfElseButton';
        }
        acc.push({
          id: `e-${id}-${nextStepId}`,
          source: id,
          target: nextStepId,
          sourceHandle: uiMetadata?.[nextStepId]?.sourceHandle,
          targetHandle: uiMetadata?.[nextStepId]?.targetHandle,
          type,
        });
      }

      if (set.has(trueStepId)) {
        acc.push({
          id: `e-true-${id}-${trueStepId}`,
          source: id,
          sourceHandle: uiMetadata?.[trueStepId]?.sourceHandle,
          targetHandle: uiMetadata?.[trueStepId]?.targetHandle,
          target: trueStepId,
          type: isFlowMonitor ? 'edgeWithRetryButton' : 'edgeWithIfElseButton',
        });
      }

      if (set.has(falseStepId)) {
        acc.push({
          id: `e-false-${id}-${falseStepId}`,
          source: id,
          sourceHandle: uiMetadata?.[falseStepId]?.sourceHandle,
          targetHandle: uiMetadata?.[falseStepId]?.targetHandle,
          target: falseStepId,
          type: isFlowMonitor ? 'edgeWithRetryButton' : 'edgeWithIfElseButton',
        });
      }

      // handle conditions multiple branches
      if (
        (typename === flowStepTypes.DataConditionStep || typename === flowStepTypes.CountConditionStep) &&
        conditions?.length
      ) {
        conditions.forEach((condition) => {
          if (set.has(condition.nextStepId)) {
            let type = 'default';
            if (isFlowMonitor) {
              type = 'edgeWithRetryButton';
            } else if (
              (data.group === 'Conditions' && typename === flowStepTypes.DataConditionStep) ||
              (data.group === 'Conditions' && typename === flowStepTypes.CountConditionStep)
            ) {
              type = 'edgeWithCondition';
            } else if (data.group === 'Conditions' && typename === flowStepTypes.CountConditionStep) {
              type = 'edgeWithButton';
            }
            acc.push({
              id: `e-${id}-${condition.nextStepId}`,
              source: id,
              target: condition.nextStepId,
              sourceHandle: uiMetadata?.[condition.nextStepId]?.sourceHandle,
              targetHandle: uiMetadata?.[condition.nextStepId]?.targetHandle,
              type,
            });
          }
        });
      }

      // handle AB test condition
      if (typename === flowStepTypes.ABTestConditionStep) {
        if (set.has(aBranchCondition?.nextStepId)) {
          acc.push({
            id: `e-${id}-${aBranchCondition?.nextStepId}`,
            source: id,
            target: aBranchCondition?.nextStepId,
            sourceHandle: uiMetadata?.[aBranchCondition?.nextStepId]?.sourceHandle,
            targetHandle: uiMetadata?.[aBranchCondition?.nextStepId]?.targetHandle,
            type: isFlowMonitor ? 'edgeWithRetryButton' : 'edgeWithLabel',
          });
        }
        if (set.has(bBranchCondition?.nextStepId)) {
          acc.push({
            id: `e-${id}-${bBranchCondition?.nextStepId}`,
            source: id,
            target: bBranchCondition?.nextStepId,
            sourceHandle: uiMetadata?.[bBranchCondition?.nextStepId]?.sourceHandle,
            targetHandle: uiMetadata?.[bBranchCondition?.nextStepId]?.targetHandle,
            type: isFlowMonitor ? 'edgeWithRetryButton' : 'edgeWithLabel',
          });
        }
      }
      return acc;
    },
    [...edgeMarkers, ...nodes]
  );
};

export const facadeStepsToNodes = (steps = [], isFlowMonitor = false) => {
  const nodes = steps.map((step) => ({
    id: step.id,
    data: {
      ...step,
    },
    position: step?.layout?.location
      ? {
          x: step.layout.location.left,
          y: step.layout.location.top,
        }
      : null,
  }));

  return repopulateEdges(nodes, isFlowMonitor);
};

export const facadeNodesToSteps = (nodes) => {
  return nodes.filter(isNode).map((node) => {
    return {
      ...node.data,
      id: node.id,
      layout: {
        location: {
          left: node.position.x,
          top: node.position.y,
        },
        uiMetadata: {
          ...node.data.layout?.uiMetadata,
        },
      },
      validationErrors: undefined,
    };
  });
};
