import React, { useRef } from 'react';
import { string, bool, func } from 'prop-types';
import { useClickAway } from 'react-use';
import omit from 'lodash/omit';
import { StyledBox, StyledItemBox } from './styled';
import { L12B } from '../../../atoms/Typography/L12B';
import { useFlowEditorContext } from '../../context';
import { repopulateEdges } from '../../utils/facades';
import THEME from '../../../../constants/theme';
import { filterEdges, filterNodes } from '../../utils/filterElements';

const conditionItems = ['true', 'false', 'delete'];

const IfElseConditionModal = ({
  stepId,
  targetId,
  sourceHandleId,
  targetHandleId,
  isOpen,
  setIsShowIfElseConditionModal,
}) => {
  const ref = useRef(null);
  const { nodes, saveConfigurationAsync, setNodes, setEdges, setSelectedNodeId } = useFlowEditorContext();

  useClickAway(ref, () => {
    setSelectedNodeId(null);
  });

  const handleClick = async (condition) => {
    let newNodes;
    switch (condition) {
      case 'true':
      case 'false':
        newNodes = nodes.map((el) => {
          if (el.id !== stepId) {
            return el;
          }
          let trueStepId = el.data?.trueStepId;
          let falseStepId = el.data?.falseStepId;
          const uiMetadata = {
            ...el.data?.layout?.uiMetadata,
            [targetId]: {
              sourceHandle: sourceHandleId,
              targetHandle: targetHandleId,
            },
          };
          if (condition === 'true') {
            if (falseStepId === targetId) {
              falseStepId = trueStepId;
            }
            trueStepId = targetId;
          } else {
            if (trueStepId === targetId) {
              trueStepId = falseStepId;
            }
            falseStepId = targetId;
          }
          return {
            ...el,
            data: { ...el.data, nextStepId: null, trueStepId, falseStepId, layout: { uiMetadata } },
          };
        });
        break;
      case 'delete':
        newNodes = nodes.map((el) => {
          if (el.id !== stepId) {
            return el;
          }
          const trueStepId = targetId === el?.data?.trueStepId ? null : el?.data?.trueStepId;
          const falseStepId = targetId === el?.data?.falseStepId ? null : el?.data?.falseStepId;
          return {
            ...el,
            data: {
              ...el.data,
              nextStepId: null,
              trueStepId,
              falseStepId,
              layout: {
                ...el.data?.layout,
                uiMetadata: omit(el.data?.layout?.uiMetadata, targetId),
              },
            },
          };
        });
        break;
      default:
        newNodes = nodes;
    }
    const elements = repopulateEdges(newNodes);
    const filteredNodes = filterNodes(elements);
    setNodes(filteredNodes);
    setEdges(filterEdges(elements));
    setIsShowIfElseConditionModal(false);
    await saveConfigurationAsync(filteredNodes);
  };

  return (
    isOpen && (
      <StyledBox width="112px" ref={ref}>
        {conditionItems.map((item) => (
          <StyledItemBox key={item} onClick={() => handleClick(item)}>
            <L12B textTransform="capitalize" color={item === 'delete' ? THEME.secondaryColors.nodeError : undefined}>
              {item}
            </L12B>
          </StyledItemBox>
        ))}
      </StyledBox>
    )
  );
};

IfElseConditionModal.propTypes = {
  stepId: string.isRequired,
  targetId: string.isRequired,
  sourceHandleId: string.isRequired,
  targetHandleId: string.isRequired,
  isOpen: bool.isRequired,
  setIsShowIfElseConditionModal: func.isRequired,
};

export { IfElseConditionModal };
