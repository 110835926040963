import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import { P14B } from '../../../../atoms';
import CheckSquare from '../../../../../assets/icons/CheckSquare';
import { TransparentButton } from '../styled';
import THEME from '../../../../../constants/theme';
import GrowingInput from './GrowingInput';
import SubmitMessageButton from './SubmitMessageButton';
import Comment from './Comment';
import Remove from '../../../../../assets/icons/Remove';
import NewCheck from '../../../../../assets/icons/NewCheck';
import ResolveDialog from './ResolveDialog';
import { stopPropagation } from './helpers';
import { useNodeContext } from '../context';

const CommentsBlock = ({ toggleMessagesOpened }) => {
  const { t } = useTranslation();
  const [openedResolve, setOpenedResolve] = useState(false);
  const {
    stepId,
    message,
    setMessage: onMessageChange,
    handleSubmitComment: onSubmit,
    currentNodeComments: comments,
    commentsSaving,
    editComment,
    deleteComment,
  } = useNodeContext();
  const [selectedCommentId, setSelectedComment] = useState(null);
  const [editingText, setEditingText] = useState('');

  const onCommentSelect = (id, text) => {
    setSelectedComment(id);
    setEditingText(text);
  };

  const onCancelEditing = () => {
    setSelectedComment(null);
    setEditingText('');
  };

  const onSubmitEditing = async () => {
    const text = editingText;
    setEditingText('');
    await editComment(selectedCommentId, text, stepId);
    setSelectedComment(null);
  };

  const removeComment = (id) => {
    deleteComment(id, stepId);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p="16px"
        borderBottom={`1px solid ${THEME.greyColors.grey200}`}
      >
        <P14B>{t('Comments')}</P14B>
        <Box display="flex">
          <TransparentButton onMouseDown={stopPropagation} mr="4px" onClick={() => setOpenedResolve(true)}>
            <CheckSquare />
          </TransparentButton>
          <TransparentButton onMouseDown={stopPropagation} onClick={toggleMessagesOpened}>
            <Remove size={16} />
          </TransparentButton>
        </Box>
      </Box>
      <Box overflow="auto" p="4px">
        {comments.map((comment, index) => (
          <Comment
            key={comment.id}
            length={comments.length}
            index={index}
            selected={comment.id === selectedCommentId}
            setSelectedComment={onCommentSelect}
            removeComment={removeComment}
            {...comment}
          />
        ))}
      </Box>
      <Box display="flex" p="12px" borderTop={`1px solid ${THEME.greyColors.grey200}`}>
        <GrowingInput
          value={selectedCommentId ? editingText : message}
          onChange={selectedCommentId ? setEditingText : onMessageChange}
          placeholder="Reply"
        />
        {selectedCommentId ? (
          <Box display="flex">
            <SubmitMessageButton
              onClick={onCancelEditing}
              iconColor={THEME.greyColors.grey17}
              Icon={Remove}
              bgcolor={THEME.greyColors.greyButton}
              margin="0 4px 0 0"
              disabled={commentsSaving}
            />
            <SubmitMessageButton
              onClick={onSubmitEditing}
              Icon={NewCheck}
              iconColor={THEME.primaryColors.white}
              disabled={commentsSaving || !editingText}
            />
          </Box>
        ) : (
          <SubmitMessageButton onClick={onSubmit} active={!!message} disabled={commentsSaving || !message.trim()} />
        )}
      </Box>
      {openedResolve && <ResolveDialog isOpen={openedResolve} closeModal={() => setOpenedResolve(false)} />}
    </Box>
  );
};

CommentsBlock.propTypes = {
  toggleMessagesOpened: func.isRequired,
};

export default CommentsBlock;
