import React from 'react';

const Security = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.91452 4.50798C5.91452 3.514 6.72028 2.70822 7.71429 2.70822C8.70829 2.70822 9.51405 3.514 9.51405 4.50798V4.61298V5.67282H5.91452V4.61298V4.50798ZM12.0537 7.68258V7.78758V11.3871V11.4921C12.0537 12.4861 11.248 13.2919 10.254 13.2919H10.149H5.2796H5.1746C4.18062 13.2919 3.37484 12.4861 3.37484 11.4921V11.3871V7.78758V7.68258C3.37484 7.3899 3.6121 7.15266 3.90476 7.15266H4.00976H5.1746H10.254H11.4188H11.5238C11.8165 7.15266 12.0537 7.3899 12.0537 7.68258ZM4.43468 4.61298V5.67282H4.00976H3.90476H3.79976V5.67551C2.77338 5.72834 1.95052 6.55117 1.8977 7.57758H1.895V7.68258V7.78758V11.3871V11.4921V11.5971H1.89665C1.95099 13.3249 3.34179 14.7157 5.0696 14.7701V14.7717H5.1746H5.2796H10.149H10.254H10.359V14.7701C12.0868 14.7157 13.4776 13.3249 13.5319 11.5971H13.5336V11.4921V11.3871V7.78758V7.68258V7.57758H13.5309C13.4781 6.55117 12.6552 5.72834 11.6288 5.67551V5.67282H11.5238H11.4188H10.9939V4.61298V4.50798V4.40298H10.9922C10.9379 2.67516 9.54709 1.28437 7.81929 1.23002V1.22837H7.71429H7.60929V1.23002C5.88147 1.28437 4.49067 2.67516 4.43633 4.40298H4.43468V4.50798V4.61298Z"
        fill="#787F88"
        stroke="#787F88"
        strokeWidth="0.21"
      />
    </svg>
  );
};

export default Security;
