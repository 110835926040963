import React from 'react';
import { string } from 'prop-types';

const XSmall = ({ color }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2325 4.76764C11.5449 5.08006 11.5449 5.58659 11.2325 5.89901L5.89913 11.2323C5.58671 11.5448 5.08018 11.5448 4.76776 11.2323C4.45534 10.9199 4.45534 10.4134 4.76776 10.101L10.1011 4.76764C10.4135 4.45522 10.92 4.45522 11.2325 4.76764Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.76776 4.76764C5.08018 4.45522 5.58671 4.45522 5.89913 4.76764L11.2325 10.101C11.5449 10.4134 11.5449 10.9199 11.2325 11.2323C10.92 11.5448 10.4135 11.5448 10.1011 11.2323L4.76776 5.89901C4.45534 5.58659 4.45534 5.08006 4.76776 4.76764Z"
        fill={color}
      />
    </svg>
  );
};

XSmall.propTypes = {
  color: string,
};

XSmall.defaultProps = {
  color: '#1C1C1E',
};

export default XSmall;
