import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import toPairs from 'lodash/toPairs';
import { func } from 'prop-types';
import IssuesGroup from './IssuesGroup';
import { useFlowEditorContext } from '../FlowEditor/context';

const MANDATORY_NODES_REQUIRED = 'MANDATORY_NODES_REQUIRED';

const IssuesModal = ({ onClick }) => {
  const { configurationErrors } = useFlowEditorContext();
  const ref = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClick, ref]);

  const groupEntries = toPairs(configurationErrors?.errors);

  return (
    <Box position="absolute" top="0" left="0" width="100vw" height="100vh">
      <Box
        position="absolute"
        top="60px"
        bgcolor="white"
        width={340}
        boxShadow="0px 0px 16px rgba(0, 0, 0, 0.08)"
        borderRadius="8px"
        ref={ref}
      >
        {groupEntries?.map((item) => (
          <IssuesGroup
            label={item[0].split('_').join(' ')}
            items={item[1]}
            subLabel={
              item[0] === MANDATORY_NODES_REQUIRED
                ? 'Your automation requires additional nodes in order to work properly'
                : null
            }
          />
        ))}
      </Box>
    </Box>
  );
};

IssuesModal.propTypes = {
  onClick: func.isRequired,
};

export default IssuesModal;
