import React from 'react';
import { bool, func } from 'prop-types';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledDropZone } from './styled';
import { P14 } from '../../../../../../../components/atoms';
import THEME from '../../../../../../../constants/theme';
import { FlexContainer } from '../../../../../../../components/atoms/flex/FlexContainer';
import DraggingIcon from '../../../../../../../assets/icons/DraggingIcon';

const DragAndDropZone = ({ dragging, getRootProps, defaultOnClick, disabled }) => {
  const { t } = useTranslation();

  return (
    <StyledDropZone
      dragging={dragging}
      {...getRootProps({ onClick: defaultOnClick })}
      disabled={disabled}
      className={disabled ? 'disabled' : ''}
    >
      <FlexContainer flexDirection="column" alignItems="center" justifyContent="center">
        <Box display="flex">
          <P14 textDecoration="underline" color={disabled ? THEME.greyColors.grey18 : THEME.primaryColors.primary}>
            {t('Browse')}
          </P14>{' '}
          <P14 margin="0 0 0 2px">{t(`or drop your files here`)}</P14>
        </Box>
      </FlexContainer>

      {dragging && <DraggingIcon className="draggingIcon" />}
    </StyledDropZone>
  );
};

DragAndDropZone.propTypes = {
  getRootProps: func.isRequired,
  dragging: bool.isRequired,
  defaultOnClick: func,
  disabled: bool.isRequired,
};
DragAndDropZone.defaultProps = {
  defaultOnClick: () => {},
};

export default DragAndDropZone;
