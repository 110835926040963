import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Slide from '@mui/material/Slide';
import isEqual from 'lodash/isEqual';
import isNumber from 'lodash/isNumber';
import Box from '@mui/material/Box';
import noop from 'lodash/noop';
import { useFlowEditorContext } from '../../context';
import { StyledDialog, StyledDialogTitle, StyledDialogContent, StyledBox } from '../../styled';
import { ButtonRounded, P12, P14M } from '../../../atoms';
import { StyledDialogActions } from '../../../Dialog/styled';
import AutomationStep from './AutomationStep';
import NodeIcon from '../NodeLibrary/NodeIcon';
import { flowStepTypes } from '../../types';
import Logical from './AutomationStep/fields/Logical';
import UnsavedChangesDialog from './dialogs/UnsavedChangesDialog';
import { useNodeDataToSave } from './AutomationStep/fields/hooks/useNodeDataToSave';
import useIsDemo from '../../../../hooks/useIsDemo';
import ABTestConfigureDialog from './dialogs/ABTestConfigureDialog';
import { getIsRulesConfigured } from './utils/getIsRulesConfigured';
import MoreBlock from './MoreBlock';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AutomationDetails = () => {
  const { t } = useTranslation();
  const isDemo = useIsDemo();
  const {
    selectedNodeData,
    setSelectedNodeId,
    isDataSaving,
    commitNodeDataToSave,
    versionId,
    config,
    setSelectedConditionEdgeNumber,
  } = useFlowEditorContext();
  const [nodeDataToSave] = useNodeDataToSave();
  const [changesModalOpen, setChangesModalOpen] = useState(false);
  const [isABTestConfiguring, setIsABTestConfiguring] = useState(false);
  const [configureABTestModalOpen, setConfigureABTestModalOpen] = useState(false);
  const open = Boolean(selectedNodeData && selectedNodeData?.__typename !== flowStepTypes.LinkFlowStep);
  const group = selectedNodeData?.group;
  const selectedNodeAPercentConfig = config?.steps?.find(
    (step) => step?.id === selectedNodeData?.id && step?.__typename === flowStepTypes.ABTestConditionStep
  )?.aPercent;
  const isABTestConfigured =
    !isABTestConfiguring &&
    isNumber(selectedNodeAPercentConfig) &&
    isNumber(nodeDataToSave?.aPercent) &&
    selectedNodeAPercentConfig === nodeDataToSave?.aPercent;
  const isRulesConfigured = getIsRulesConfigured(nodeDataToSave);

  const handleClose = () => {
    if (!isDemo && !isEqual(selectedNodeData, nodeDataToSave)) {
      setChangesModalOpen(true);
      return;
    }
    setSelectedNodeId(null);
    setIsABTestConfiguring(false);
    setSelectedConditionEdgeNumber(null);
  };

  const handleCancel = () => {
    setSelectedNodeId(null);
  };

  const cancelABTestConfiguring = () => {
    setConfigureABTestModalOpen(false);
    setIsABTestConfiguring(false);
  };

  const continueABTestConfiguring = () => {
    setConfigureABTestModalOpen(false);
    setIsABTestConfiguring(true);
  };

  const isIfElse = selectedNodeData?.__typename === flowStepTypes.IfElseStep;

  return (
    <>
      <StyledDialog open={open} fullWidth={true} maxWidth="sm" TransitionComponent={Transition} onClose={handleClose}>
        <StyledDialogTitle>
          <StyledBox>
            <StyledBox>
              {selectedNodeData && <NodeIcon nodeData={selectedNodeData} type={group?.toLowerCase()} mr="0" />}
            </StyledBox>
            <Box />
            <Box
              display="flex"
              flex={1}
              flexDirection={isIfElse ? 'row' : 'column'}
              alignItems={isIfElse ? 'center' : 'flex-start'}
              justifyContent={isIfElse ? 'space-between' : 'center'}
              ml="12px"
            >
              <P14M>{t(selectedNodeData?.name)}</P14M>
              <StyledBox justifyContent="center">
                {isIfElse ? <Logical /> : <P12 color="#787F88">{selectedNodeData?.nickname || group}</P12>}
              </StyledBox>
            </Box>
            {!versionId && <MoreBlock />}
          </StyledBox>
        </StyledDialogTitle>
        <StyledDialogContent $versionId={versionId}>
          {versionId && (
            <Box position="absolute" width="100%" height="100%" left="0" top="-32px" onClick={noop} zIndex="4" />
          )}
          <AutomationStep isABTestConfigured={isABTestConfigured} />
        </StyledDialogContent>
        <StyledDialogActions px="16px" py="24px" $buttonMargin="16px" $justifyContent="flex-end" $fixed $borderTop>
          {isABTestConfigured
            ? !versionId && (
                <ButtonRounded
                  type="button"
                  color="secondary"
                  variant="contained"
                  onClick={() => setConfigureABTestModalOpen(true)}
                >
                  {t('Configure')}
                </ButtonRounded>
              )
            : !versionId && (
                <>
                  <ButtonRounded type="button" color="secondary" variant="contained" onClick={handleCancel}>
                    {t('Cancel')}
                  </ButtonRounded>
                  <ButtonRounded
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={commitNodeDataToSave}
                    disabled={isDataSaving || isDemo || !isRulesConfigured}
                  >
                    {isDataSaving ? <CircularProgress size={24} color="inherit" /> : t('Save')}
                  </ButtonRounded>
                </>
              )}
          {versionId && (
            <ButtonRounded type="button" color="primary" variant="contained" onClick={handleCancel}>
              {t('Close')}
            </ButtonRounded>
          )}
        </StyledDialogActions>
      </StyledDialog>
      <UnsavedChangesDialog open={changesModalOpen} setOpen={setChangesModalOpen} />
      <ABTestConfigureDialog
        open={configureABTestModalOpen}
        cancelABTestConfiguring={cancelABTestConfiguring}
        continueABTestConfiguring={continueABTestConfiguring}
      />
    </>
  );
};

export { AutomationDetails };
