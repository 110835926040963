import { ROLES } from '../../permissions/constants';

const CUSTOMER_SUPPORT_PERMISSIONS_IDS = {
  customerSupport: 'customerSupport', // Customer Support
};

const CUSTOMER_SUPPORT_PERMISSIONS = [
  {
    // only customer support role can see customer support page
    id: CUSTOMER_SUPPORT_PERMISSIONS_IDS.customerSupport,
    roles: {
      [ROLES.customerSupport]: true,
    },
  },
];

export { CUSTOMER_SUPPORT_PERMISSIONS_IDS, CUSTOMER_SUPPORT_PERMISSIONS };
