import React from 'react';
import { string, func, shape, number, arrayOf, bool } from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import HeadCell from './HeadCell';

const Draggables = ({ headerGroup, dragIndex, headCellDropdownOptions, noDnd }) => {
  return (
    <>
      {headerGroup.headers.map((column, index) => {
        return (
          <Draggable key={column.id} draggableId={column.id} index={index} isDragDisabled={!column.accessor || noDnd}>
            {(provided, snapshot) => (
              <HeadCell
                provided={provided}
                id={column?.id}
                disablePadding={column?.disablePadding}
                snapshot={snapshot}
                index={index}
                resizerProps={column?.getResizerProps}
                dragIndex={dragIndex}
                headCellDropdownOptions={headCellDropdownOptions}
                noDnd={noDnd}
              >
                {column?.render('Header')}
              </HeadCell>
            )}
          </Draggable>
        );
      })}
    </>
  );
};

Draggables.propTypes = {
  headerGroup: shape({
    headers: arrayOf(
      shape({
        id: string,
        accessor: func,
      })
    ),
  }).isRequired,
  dragIndex: shape({
    destination: number,
    source: number,
  }).isRequired,
  headCellDropdownOptions: arrayOf(shape({})),
  noDnd: bool,
};

Draggables.defaultProps = {
  headCellDropdownOptions: null,
  noDnd: false,
};

export default Draggables;
