import React from 'react';
import { shape, bool } from 'prop-types';
import { BlockWrap } from '../../../../../atoms';
import { StyledReactMarkdown } from '../../../styled';
import THEME from '../../../../../../constants/theme';
import LinkRenderer from '../../MarkdownRenderers/LinkRenderer';

const Section = ({ item, animatedLabel }) => {
  return (
    <BlockWrap margin="10px 0 36px">
      <StyledReactMarkdown
        fontSize="14px"
        color={THEME.greyColors.grey11}
        textAlign={animatedLabel ? 'inherit' : 'left'}
        components={{ a: LinkRenderer }}
      >
        {item.text.text}
      </StyledReactMarkdown>
    </BlockWrap>
  );
};

Section.propTypes = {
  item: shape({}).isRequired,
  animatedLabel: bool,
};

Section.defaultProps = {
  animatedLabel: false,
};
export default Section;
