import React from 'react';

const Tokenisation = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22Z"
        fill="#42B16E"
      />
      <path
        d="M18.6725 13.9875C17.482 14.3156 16.2996 14.6724 15.1263 15.0575C15.0191 15.0922 14.9239 15.1563 14.8514 15.2425C14.7789 15.3287 14.7321 15.4335 14.7163 15.545C14.0238 20.7413 15.6238 24.5325 17.5325 27.03C18.3407 28.098 19.3043 29.0389 20.3913 29.8213C20.8238 30.1262 21.2063 30.3463 21.5075 30.4875C21.6575 30.5588 21.78 30.6063 21.8738 30.635C21.9148 30.6494 21.957 30.6598 22 30.6663C22.0425 30.6593 22.0843 30.6489 22.125 30.635C22.22 30.6063 22.3425 30.5588 22.4925 30.4875C22.7925 30.3463 23.1763 30.125 23.6088 29.8213C24.6957 29.0388 25.6593 28.098 26.4675 27.03C28.3763 24.5338 29.9763 20.7413 29.2838 15.545C29.2681 15.4334 29.2213 15.3286 29.1488 15.2423C29.0763 15.1561 28.981 15.0921 28.8738 15.0575C28.06 14.7913 26.6863 14.3575 25.3275 13.9887C23.94 13.6125 22.6638 13.3338 22 13.3338C21.3375 13.3338 20.06 13.6125 18.6725 13.9887V13.9875ZM18.34 12.7C19.6963 12.3313 21.1375 12 22 12C22.8625 12 24.3038 12.3313 25.66 12.7C27.0475 13.075 28.4463 13.5187 29.2688 13.7875C29.6126 13.9011 29.9175 14.1093 30.1484 14.3884C30.3792 14.6674 30.5266 15.0059 30.5738 15.365C31.3188 20.9613 29.59 25.1088 27.4925 27.8525C26.6031 29.0262 25.5425 30.0598 24.3463 30.9188C23.9326 31.216 23.4944 31.4773 23.0363 31.7C22.6863 31.865 22.31 32 22 32C21.69 32 21.315 31.865 20.9638 31.7C20.5056 31.4773 20.0674 31.216 19.6538 30.9188C18.4575 30.0598 17.397 29.0262 16.5075 27.8525C14.41 25.1088 12.6813 20.9613 13.4263 15.365C13.4734 15.0059 13.6208 14.6674 13.8517 14.3884C14.0825 14.1093 14.3874 13.9011 14.7313 13.7875C15.9252 13.396 17.1285 13.0334 18.34 12.7Z"
        fill="white"
      />
      <path
        d="M23.875 20.125C23.8752 20.5129 23.7551 20.8913 23.5312 21.2081C23.3073 21.5249 22.9907 21.7644 22.625 21.8937L23.1063 24.3812C23.1238 24.4717 23.121 24.5649 23.0983 24.6542C23.0756 24.7435 23.0333 24.8267 22.9747 24.8978C22.916 24.9689 22.8424 25.0261 22.759 25.0654C22.6757 25.1047 22.5847 25.125 22.4925 25.125H21.5075C21.4155 25.1248 21.3246 25.1043 21.2414 25.065C21.1582 25.0256 21.0847 24.9684 21.0262 24.8973C20.9676 24.8263 20.9255 24.7432 20.9029 24.6539C20.8802 24.5647 20.8775 24.4716 20.895 24.3812L21.375 21.8937C21.051 21.7792 20.7646 21.5777 20.5473 21.3114C20.33 21.0452 20.1901 20.7243 20.1428 20.3838C20.0955 20.0434 20.1428 19.6965 20.2793 19.3811C20.4159 19.0657 20.6365 18.7938 20.9171 18.5953C21.1977 18.3968 21.5274 18.2792 21.8703 18.2555C22.2132 18.2317 22.556 18.3026 22.8613 18.4605C23.1666 18.6184 23.4226 18.8571 23.6014 19.1507C23.7802 19.4442 23.8748 19.7813 23.875 20.125Z"
        fill="white"
      />
    </svg>
  );
};

export default Tokenisation;
