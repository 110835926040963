import { gql } from '@apollo/client';
import { paymentFilter } from '../../fragments/publicFragments';
import { paymentTimelineFields } from '../paymentIntents/intentsQueries';

const issueQueryString =
  'getPaymentIssue(paymentIssueId: $issueId) {      id      teamId      userId      customerId      paymentId      userAssigneeName      userCreatorName      assigneeUser {        id        name        email        avatar      }      userCreator {        id        name        email        avatar      }      comments {        id        teamId        paymentId        paymentIssueId        message        systemCreated        systemUpdated        userCreator {          id          name        }      }      attachments      status      type      priority      systemCreated      systemUpdated      customer {        id        email      }    }';

export const GET_PAYMENT = (issueId) => gql`
query getPayment($id: ID! $customerId: String!, $teamId: String!, $sourceId: String! ${
  issueId ? ',$issueId: ID!' : ''
}) {
  getPaymentInternal(customerId: $customerId, teamId: $teamId, sourceId: $sourceId) {
    id
    teamId
    flowId
    orderId
    sourceId
    connectionId
    date
    status
    amount {
        rawAmount
        currency
        formattedAmount
    }
    refundedAmount {
        rawAmount
        currency
        formattedAmount
    }
    currency
    country
    eci
    paymentProcessingOrigin
    threeDSecureVersion
    threeDSecureAuthenticationResult
    paymentGateway {
        id
        name
        logo
    } 
    paymentCore {
        processorStatus
        paymentMethod
        outcome {
            networkStatus
            reason
            riskLevel
            riskScore
            type
            declineCode
            declineType
            processorDeclineCode
            processorDeclineType
            businessFailureCode
            businessFailureReason
            
        }
    }
    paymentDetails {
        statementDescriptor
        localCurrency
        localAmount {
            rawAmount
            currency
            formattedAmount
        }
        exchangeRate
            fee {
            rawAmount
            currency
            formattedAmount
        }
        tax {
            rawAmount
            currency
            formattedAmount
        }
        processingFee {
            rawAmount
            currency
            formattedAmount
        }
        net {
            rawAmount
            currency
            formattedAmount
        }
        description
    }
    paymentTimeline {
        overview {
            title
            time
            message
        }
    }
    paymentConnections {
        latestCharge
        invoice
        payout
    }
    paymentCustomer {
        id
        email
        name
        phone
        description
        billingAddress {
            line1
            line2
            city
            postalCode
            state
            country
        }
        shippingAddress {
            address {
                line1
                line2
                city
                postalCode
                state
                country
            }
            name
            phone
        }
        company {
            name
            number
            taxId
            vatId
        }
    }
    metadata
    paymentMethodDetails {
        id
        number
        expires
        fingerprint
        paymentMethod
        type
        issuingBank
        country
        brand
        name
        additionalData
    }
    paymentReceipt {
        type
        message
        date
        receiptUrl
    }
    fraud {
        fraudProvider
        score
        date
        fraudDecision
        additionalData
    }
    attachments {
        id
        fileName
        objectKey
        contentType
        accessUrl
    }
    flowInstanceId
  }

  getLinkedIntent(id: $id) {
    id
    trackingId      
    currency
    amount {
        formattedAmount
    }
    location {
        country
    }
    customer {
        id
        name
        email
        isGuest
    }
    linkedPayments {
        paymentId
        status
        time
    }
    timeline { 
        ...f_PaymentIntentTimeline
    }
    
  }
  listPaymentComments(paymentId: $id) {
      id
      teamId
    paymentId
    paymentIssueId
    message
    systemCreated
    systemUpdated
      userCreator {
          id
          name
          email
          avatar
      }
  }
  listPaymentIssues(paymentId: $id) {
    id
    systemCreated
    type
    status
    priority
    paymentId
    assigneeUser {
      id
      name
      email
      avatar
    }
    userCreator {
      id
      name
      email
      avatar
    }
    customer {
      email
    }
  }
  
  ${(issueId && issueQueryString) || ''}
}
${paymentTimelineFields}
`;

export const GET_PAYMENT_LIST = gql`
  query listPaymentsInternal($first: Int, $after: String, $last: Int, $before: String, $data: PaymentFilterInput) {
    listPaymentsInternal(first: $first, after: $after, last: $last, before: $before, data: $data) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        endCursor
        totalSize
      }
      edges {
        cursor
        node {
          id
          status
          date
          currency
          country
          sourceId
          amount {
            formattedAmount
          }
          paymentCore {
            processorStatus
            paymentMethod
            outcome {
              networkStatus
              reason
              riskLevel
              riskScore
              declineCode
            }
          }
          paymentGateway {
            id
            name
            logo
          }
          paymentCustomer {
            id
            email
            phone
          }
          paymentMethodDetails {
            paymentMethod
            product
            type
            issuingBank
            category
          }
          paymentDetails {
            net {
              formattedAmount
            }
            fee {
              formattedAmount
            }
            processingFee {
              formattedAmount
            }
            tax {
              formattedAmount
            }
            exchangeRate
            localCurrency
            localAmount {
              formattedAmount
            }
            statementDescriptor
            description
          }
          paymentReceipt {
            message
            type
            date
            receiptUrl
          }
          paymentConnections {
            latestCharge
            invoice
            payout
          }
        }
      }
    }
  }
`;

export const GQL_Q_LIST_PAYMENT_VIEW = gql`
  query listPaymentViews {
    listPaymentViews {
      defaultVisibleFields
      defaultHiddenFields
      views {
        id
        teamId
        customerId
        userId
        isOwner
        name
        sharedWith
        sharedWithUsers {
          id
          name
          email
          avatar
        }
        tableState
        filter {
          ...paymentFilter
        }
        visibleFields
        hiddenFields
        sort {
          fieldName
          order
        }
      }
    }
  }
  ${paymentFilter}
`;

export const GQL_Q_LIST_PAYMENT_EXPORT_COLUMNS = gql`
  query getPaymentColumns {
    getPaymentColumns {
      name
      label
      defaultColumn
    }
  }
`;
