import React from 'react';
import { string } from 'prop-types';

const Ideal = ({ width, height, lineColor, bodyColor }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#F4F4F4" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 6H13.2141C18.1162 6 20 8.75782 20 12.2437C20 16.4135 17.367 18.5095 13.2141 18.5095H6V6ZM6.68491 6.75033V17.8037H13.214C17.1528 17.8037 19.2935 15.9063 19.2935 12.266C19.2935 8.51534 16.9815 6.75033 13.214 6.75033H6.68491ZM8.61147 12.4643C9.33265 12.4643 9.91728 11.8617 9.91728 11.1185C9.91728 10.3752 9.33265 9.77265 8.61147 9.77265C7.8903 9.77265 7.30566 10.3752 7.30566 11.1185C7.30566 11.8617 7.8903 12.4643 8.61147 12.4643ZM7.56254 13.0381H9.6604V16.877H7.56254V13.0381Z"
      fill={lineColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9175 9.8828H17.5169V11.7361H18.3945C18.1591 8.36048 15.5688 7.61035 13.2141 7.61035H10.7095V9.8828H11.0734C11.7584 9.8828 12.1652 10.3461 12.1652 11.1183C12.1652 11.8905 11.7584 12.3538 11.0948 12.3538H10.7095V16.8766H13.2141C17.0459 16.8766 18.3517 15.0454 18.4159 12.3538H16.9175V9.8828ZM14.0276 11.7361V12.3538H12.5291V9.88281H13.9848V10.5006H13.1285V10.7874H13.942V11.4051H13.1499V11.7361H14.0276ZM14.2844 12.3538L15.0122 9.88281H15.8685L16.5963 12.3538H15.9755L15.8471 11.8905H15.055L14.9266 12.3538H14.2844ZM15.2329 11.2509H15.2262V11.2729L15.2329 11.2509ZM15.2329 11.2509H15.6543L15.4617 10.5669H15.4403L15.2329 11.2509ZM10.7095 11.7362V10.5007H11.0734C11.3089 10.5007 11.5657 10.5669 11.5657 11.1184C11.5657 11.67 11.3303 11.7362 11.0734 11.7362H10.7095Z"
      fill={bodyColor}
    />
  </svg>
);

Ideal.propTypes = {
  width: string,
  height: string,
  lineColor: string,
  bodyColor: string,
};

Ideal.defaultProps = {
  width: '24',
  height: '24',
  lineColor: 'black',
  bodyColor: '#D50072',
};

export default Ideal;
