import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Skeleton from '@mui/lab/Skeleton';
import dropRight from 'lodash/dropRight';
import { ArrowBackRounded, ErrorRounded } from '@mui/icons-material';
import { stringify } from 'qs';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import { StyledIssuesButton, StyledRunsBox, TopButtonGroupContainer, VerticalLine } from './styled';
import { RenameFlow } from './RenameFlow';
import { UI_ROUTES } from '../../constants/routes';
import { useFlowEditorContext } from '../FlowEditor/context';
import { StyledIconButton } from '../../pages/FlowDetailsPage/Header/styled';
import THEME from '../../constants/theme';
import { L14M, P14M } from '../atoms';
import ActionButtons from './ActionButtons';
import { FLOW_INSTANCE_STATUSES } from '../FlowEditor/utils/flowInstanceStatus';
import { transformSortToSearchParams } from '../../utils/transformSortToSearchParams';
import { STRINGIFY_OPTIONS } from '../../hooks/useSearch';
import StyledTooltip from '../styled/StyledTooltip';
import useIsDemo from '../../hooks/useIsDemo';
import { generateUserPilotAttribute, generateUserPilotLabel } from '../../constants/generateUserPilotLabel';
import { USER_PILOT_SECTION_ID } from '../FlowEditor/components/constant';
import { prepareFlowInstances } from '../FlowMonitor/FlowMonitorDetailsDrawer/helpers';
import SuccessCheckmarkIcon from '../../assets/icons/FlowMonitor/SuccessCheckmarkIcon';
import FlowMonitorDetailsItem from '../FlowMonitor/FlowMonitorDetailsDrawer/FlowMonitorDetailsItem';
import LoaderIcon from '../../assets/icons/FlowMonitor/LoaderIcon';
import { LoaderIconBox } from '../FlowMonitor/styled';
import InstanceRetriesSelect from '../FlowMonitor/InstanceRetriesSelect';
import IssuesModal from './IssuesModal';

const FlowHeader = ({ onValidate }) => {
  const { t } = useTranslation();
  const { push, location } = useHistory();
  const isDemo = useIsDemo();
  const [showIssuesModal, toggleIssuesModal] = useToggle(false);
  const [timeoutId, setTimeoutId] = useState();
  const {
    loading,
    topFlowId,
    flowId,
    flowName,
    errorCount,
    flowInstanceCount,
    isFlowMonitor,
    selectedInstanceId,
    selectedInstance,
    selectedInstanceRetries,
    selectedInstanceLoading,
    selectedInstanceRetriesLoading,
    retryInstanceLoading,
    versionId,
    configurationErrors,
    setIsValidateOn,
    setPushedStart,
  } = useFlowEditorContext();
  const [preparedSelectedFlowInstance] = prepareFlowInstances(selectedInstance ? [selectedInstance] : []);
  const isShowFlowInstanceInfo = isFlowMonitor && !!selectedInstanceId;

  const onClickBack = () => {
    const pathnameArray = location?.pathname?.split('/');
    if (pathnameArray[pathnameArray.length - 1] === 'test') {
      const path = dropRight(pathnameArray, 1)?.join('/');
      return push(`${path}/editor`);
    }
    if (pathnameArray[pathnameArray.length - 1] === 'monitor') {
      const path = dropRight(pathnameArray, 1)?.join('/');
      return push(`${path}/editor`);
    }
    if (pathnameArray[pathnameArray.length - 1]) {
      const path = dropRight(pathnameArray, 1)?.join('/');
      return push(path);
    }
    if (location?.state?.prevRoute === 'flow' && !!topFlowId) {
      return push(`/flows/${topFlowId}/details/automations`);
    }
    /**If no  history stack  */
    return push(`/${UI_ROUTES.automations}`);
  };

  const openMonitor = () => {
    const sort = transformSortToSearchParams([{ fieldName: 'date', order: 'desc' }]);
    const filter = errorCount
      ? { inStatus: [FLOW_INSTANCE_STATUSES.IN_ERROR] }
      : { inStatus: FLOW_INSTANCE_STATUSES.ALL };
    push({
      pathname: `/automations/${flowId}/monitor`,
      search: `?${stringify({ ...(filter && { filter }), ...(sort && { sort: sort }) }, STRINGIFY_OPTIONS)}`,
    });
  };

  const openErrors = () => {
    clearTimeout(timeoutId);
    if (!showIssuesModal) {
      setIsValidateOn(true);
    } else {
      setPushedStart(false);
    }

    toggleIssuesModal();
    const id = setTimeout(() => {
      setIsValidateOn(false);
    }, 2000);
    setTimeoutId(id);
  };

  return (
    <Box>
      <Box
        position="fixed"
        top={16}
        left={16}
        bgcolor={THEME.primaryColors.white}
        zIndex={5}
        borderRadius="8px"
        boxShadow="0 0 8px rgba(0, 0, 0, 0.05)"
        p="8px"
      >
        <TopButtonGroupContainer>
          <Box
            borderRadius="8px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mr={flowName || isShowFlowInstanceInfo ? '8px' : 0}
            data-userpilot={generateUserPilotLabel(USER_PILOT_SECTION_ID, 'back-button')}
          >
            <StyledIconButton
              type="button"
              onClick={onClickBack}
              $width="40px"
              $height="40px"
              $strokeWidth="0"
              $color={THEME.greyColors.grey17}
              $fontSize="20px"
            >
              <ArrowBackRounded fontSize="large" />
            </StyledIconButton>
          </Box>
          {flowName && <VerticalLine />}
          {loading && !isFlowMonitor ? (
            <Box borderRadius="6px" mx="24px" overflow="hidden">
              <Skeleton variant="rectangular" height="20px" width="215px" animation="wave" />
            </Box>
          ) : (
            flowName && (
              <>
                <Box
                  px="8px"
                  maxWidth={215}
                  data-userpilot={generateUserPilotLabel(USER_PILOT_SECTION_ID, 'flow-name')}
                >
                  <RenameFlow fontSize="14px" />
                </Box>
                {!versionId && <VerticalLine />}
              </>
            )
          )}
          {flowName && !versionId && (
            <Box marginLeft="8px">
              <StyledTooltip title="Click here to monitor the runs of your automation" placement="bottom">
                <StyledRunsBox
                  $errorCount={errorCount}
                  onClick={openMonitor}
                  py="8px"
                  {...generateUserPilotAttribute(USER_PILOT_SECTION_ID, 'automation_runs')}
                >
                  <L14M color={THEME.greyColors.grey17} noWrap>
                    {flowInstanceCount} {flowInstanceCount === 1 ? 'run' : 'runs'}
                  </L14M>
                  {!!errorCount && (
                    <L14M color={THEME.statusColors.failed} margin="0 0 0 4px" noWrap>
                      with {errorCount} errors
                    </L14M>
                  )}
                </StyledRunsBox>
              </StyledTooltip>
            </Box>
          )}
          {isShowFlowInstanceInfo && (
            <>
              <VerticalLine />
              {selectedInstanceLoading ? (
                <Box borderRadius="6px" mx="24px" overflow="hidden">
                  <Skeleton variant="rectangular" height="20px" width="210px" animation="wave" />
                </Box>
              ) : (
                <Box mx="24px" maxWidth={344}>
                  <FlowMonitorDetailsItem
                    key={preparedSelectedFlowInstance?.id}
                    isInErrorStatus={preparedSelectedFlowInstance?.isInErrorStatus}
                    label={preparedSelectedFlowInstance?.date}
                    height="40px"
                    px="0"
                    onClick={() => null}
                  >
                    <Box display="inline-flex" pr="12px" color={THEME.statusColors.darkRed}>
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {retryInstanceLoading ? (
                        <LoaderIconBox>
                          <LoaderIcon />
                        </LoaderIconBox>
                      ) : preparedSelectedFlowInstance?.isInErrorStatus ? (
                        <ErrorRounded color="inherit" />
                      ) : (
                        <SuccessCheckmarkIcon />
                      )}
                    </Box>
                  </FlowMonitorDetailsItem>
                </Box>
              )}
              <VerticalLine />
              {selectedInstanceRetriesLoading ? (
                <Box borderRadius="6px" mx="24px" overflow="hidden">
                  <Skeleton variant="rectangular" height="20px" width="137px" animation="wave" />
                </Box>
              ) : (
                <Box mx="8px">
                  {selectedInstanceRetries?.length ? (
                    <InstanceRetriesSelect />
                  ) : (
                    <P14M color={THEME.greyColors.grey19} padding="0 16px">
                      {t('No retries')}
                    </P14M>
                  )}
                </Box>
              )}
            </>
          )}
        </TopButtonGroupContainer>
      </Box>
      {isDemo ? null : (
        <Box position="fixed" top={16} right={16} zIndex={5} display="flex">
          {configurationErrors?.totalCount ? (
            <Box
              p="8px"
              bgcolor={THEME.primaryColors.white}
              borderRadius="8px"
              boxShadow="0 0 8px rgba(0, 0, 0, 0.05)"
              minHeight="56px"
              boxSizing="border-box"
              display="flex"
              alignItems="center"
              mr="8px"
            >
              <StyledIssuesButton onClick={openErrors} bgcolor={showIssuesModal && THEME.secondaryColors.negativeRank}>
                <P14M color={THEME.secondaryColors.nodeError} margin="0 8px 0 0">
                  {configurationErrors?.totalCount} {t('configuration issues')}
                </P14M>
              </StyledIssuesButton>
            </Box>
          ) : null}
          {showIssuesModal && <IssuesModal onClick={openErrors} />}
          <Box
            bgcolor={THEME.primaryColors.white}
            borderRadius="8px"
            boxShadow="0 0 8px rgba(0, 0, 0, 0.05)"
            minHeight="56px"
            boxSizing="border-box"
            display="flex"
            alignItems="center"
          >
            <TopButtonGroupContainer>
              {loading ? (
                <Box borderRadius="6px" overflow="hidden" m="0 8px">
                  <Skeleton variant="rectangular" height="40px" width="250px" animation="wave" />
                </Box>
              ) : (
                <ActionButtons onValidate={onValidate} topFlowId={topFlowId} openErrors={openErrors} />
              )}
            </TopButtonGroupContainer>
          </Box>
        </Box>
      )}
    </Box>
  );
};

FlowHeader.propTypes = {
  onValidate: PropTypes.func.isRequired,
};

export { FlowHeader };
