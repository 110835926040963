import React from 'react';
import Box from '@mui/material/Box';
import Switch from '../../../../../../../assets/icons/Switch';

const ABTestSliderThumb = (props) => {
  return (
    <Box {...props}>
      <Switch fill="#fff" />
    </Box>
  );
};

export default ABTestSliderThumb;
