import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { bool, func } from 'prop-types';
import Box from '@mui/material/Box';
import { StyledDialog, StyledDialogActions, StyledPaper } from '../Dialog/styled';
import { ButtonRounded, P14M, P16M } from '../atoms';
import THEME from '../../constants/theme';
import ArrowRight from '../../assets/arrows/arrowRight';
import { StyledDialogBox, StyledDialogContent } from './styled';
import { H1B } from '../atoms/Typography/H1B';
import { UI_ROUTES } from '../../constants/routes';
import { PAYMENT_NEEDS_LIST } from './constant';
import PaymentNeeds from './PaymentNeeds';
import { GQL_M_UPDATE_PAYMENT_NEEDS } from '../../utils/queries/public/publicMutations';
import { useGlobalContext } from '../../containers/App/context';
import LoadingAnimation from '../LoadingAnimation';
import { GET_RECOMMENDED_TEMPLATES } from '../../utils/queries/automation/queries';
import { getOperationNameForQuery } from '../../utils/graphql';
import { GET_ME_AND_WE } from '../../utils/queries/public/publicQueries';
import { StyledAnimationBox } from '../LoadingAnimation/styled';

const OnboardingModal = ({ setOpen, isOpen }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { getMeData } = useGlobalContext();
  const [updatePaymentNeeds, { loading }] = useMutation(GQL_M_UPDATE_PAYMENT_NEEDS, {
    refetchQueries: [getOperationNameForQuery(GET_RECOMMENDED_TEMPLATES), getOperationNameForQuery(GET_ME_AND_WE)],
    awaitRefetchQueries: true,
  });
  const customerId = getMeData?.we?.id;
  const paymentNeeds = getMeData?.we?.paymentNeeds;
  const [isShowAnimation, setIsShowAnimation] = useState(false);
  const [isTimeOutPassed, setIsTimeOutPassed] = useState(false);
  const [selectedPaymentNeeds, setSelectedPaymentNeeds] = useState(paymentNeeds ?? []);
  const loadingAnimationText = t('We’re creating your custom onboarding experience');

  const onSelectPaymentNeeds = (_selectedOptionValues) => {
    setSelectedPaymentNeeds(_selectedOptionValues);
  };

  const onSubmit = async () => {
    setIsShowAnimation(true);
    setTimeout(() => setIsTimeOutPassed(true), 3000);
    await updatePaymentNeeds({
      variables: {
        customerId,
        paymentNeeds: selectedPaymentNeeds,
      },
    });
  };

  useEffect(() => {
    if (isTimeOutPassed && !loading) {
      setOpen(false);
      setIsShowAnimation(false);
      history.push(UI_ROUTES.home);
      setIsTimeOutPassed(false);
    }
  }, [history, isTimeOutPassed, loading, setOpen]);

  return (
    <StyledDialog
      open={isOpen}
      scroll="paper"
      maxWidth="xl"
      PaperComponent={StyledPaper}
      PaperProps={{ $borderRadius: '8px' }}
      aria-labelledby="onboarding-modal"
      $bgColor="rgba(0, 0, 0, 0.2)"
      $height="706px"
      fullWidth
    >
      <StyledDialogBox>
        {isShowAnimation ? (
          <StyledAnimationBox>
            <LoadingAnimation text={loadingAnimationText} />
          </StyledAnimationBox>
        ) : (
          <>
            <StyledDialogContent>
              <Box width="40%" height="100%" padding="40px 40px 0 40px">
                <H1B padding="0 0 32px 0">{t('Select your payment needs.')}</H1B>
                <P16M color={THEME.greyColors.grey800} margin="0 0 24px 0">
                  {t(
                    `We help Global internet companies and merchants solve their most important payment issues in just a few clicks.`
                  )}
                </P16M>
                <P16M color={THEME.greyColors.grey800} margin="0 0 24px 0">
                  {t(
                    `To help you get started, select your payment needs and we’ll auto generate solutions that you that you can you launch in minutes and enjoy the revenue, cost savings and better customer experience results instantly.`
                  )}
                </P16M>
                <P16M color={THEME.greyColors.grey800} margin="0 0 24px 0">
                  {t(`Don’t worry you can always change these later.`)}
                </P16M>
              </Box>
              <Box width="60%" height="100%" overflow="scroll">
                <PaymentNeeds
                  options={PAYMENT_NEEDS_LIST}
                  selectedPaymentNeeds={selectedPaymentNeeds}
                  onSelect={onSelectPaymentNeeds}
                />
              </Box>
            </StyledDialogContent>
            <StyledDialogActions $justifyContent="space-between" px="32px" py="16px" $borderTop>
              <Box>
                <P14M color={THEME.greyColors.grey23}>
                  {selectedPaymentNeeds.length} {t('needs selected')}
                </P14M>
              </Box>
              <ButtonRounded
                type="button"
                variant="contained"
                color="primary"
                borderRadius="6px"
                endIcon={<ArrowRight size={24} color={`${THEME.primaryColors.white}`} />}
                disabled={!selectedPaymentNeeds?.length}
                onClick={onSubmit}
              >
                {t('Let’s go')}
              </ButtonRounded>
            </StyledDialogActions>
          </>
        )}
      </StyledDialogBox>
    </StyledDialog>
  );
};

OnboardingModal.propTypes = {
  setOpen: func.isRequired,
  isOpen: bool.isRequired,
};

export default OnboardingModal;
