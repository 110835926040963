import styled from 'styled-components';

export const StyledIconWrapper = styled.span`
  width: 24px;
  height: 24px;
  transform: ${({ $right }) => ($right ? 'rotate(90deg)' : 'rotate(-90deg)')} !important;
  display: flex;
  justify-content: center;
  align-items: center;
`;
