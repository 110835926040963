import { TextField, Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import { ToggleButton, ToggleButtonGroup } from '@mui/lab';
import { ButtonRounded } from '../atoms';
import THEME from '../../constants/theme';

export const TopButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  .MuiButton-containedSecondary {
    background-color: ${THEME.greyColors.grey5} !important;
  }
`;

export const StyledRoundedTextField = styled(TextField)`
  & .MuiOutlinedInput-notchedOutline {
    border-radius: 8px !important;
    border-width: 2px !important;
    border-color: #e6e9ec !important;
    color: #232629;
  }
  & .MuiInputBase-input {
    border-radius: 8px !important;
    border-width: 2px !important;
    line-height: 14px;
    background-color: #e6e9ec !important;
  }
  & .Mui-focused {
    .MuiInputBase-input {
      background-color: #fff !important;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: rgba(105, 68, 255, 0.28) !important;
    }
  }
`;

StyledRoundedTextField.defaultProps = {
  variant: 'outlined',
  size: 'small',
};

export const StyledBox = withStyles({
  root: {
    minWidth: 'min-content',
    margin: '0 auto',
    position: 'absolute',
    backgroundColor: '#fff',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    outline: 'none',
    padding: '32px',
    borderRadius: '24px',
    boxShadow: '0px 0px 0px 2px rgba(155, 159, 171, 0.17)',
  },
})(Box);

export const StyledButton = styled(ButtonRounded)`
  margin: 24px;
  span {
    padding: 0 10px;
  }
`;

export const StyledLink = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 100%;
  color: ${THEME.greyColors.grey1};
  font-size: 14px;
  font-weight: 500;
  margin-right: 24px;
  &.active {
    color: ${THEME.primaryColors.primary};
    border-bottom: 2px solid ${THEME.primaryColors.primary};
  }
`;

export const StyledPopover = styled(Popover)`
  & .MuiPopover-paper {
    border: 1px solid ${THEME.greyColors.grey5} !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
  }
`;

export const VerticalLine = styled(Box)`
  margin-right: ${({ $marginRight }) => $marginRight || '0'};
  width: 1px;
  height: 24px;
  background: ${THEME.greyColors.grey5};
`;

export const StyledToggleGroup = styled(ToggleButtonGroup)`
  padding: ${({ $hasPadding }) => ($hasPadding ? '0 8px' : '0px')};
  height: 100%;
`;

export const StyledToggleButton = styled(ToggleButton)`
  &.MuiToggleButton-root.MuiToggleButtonGroup-grouped {
    font-weight: 500;
    color: ${THEME.greyColors.grey600};
    padding: 0;
    margin: 2px 8px 0 16px;
    border: none;
    border-radius: 0;
    font-size: 14px;
    text-transform: capitalize;
    transition: all 0.3s ease-out;
    border-bottom: 2px solid transparent;
    &:last-child {
      margin: 2px 16px 0 8px;
    }

    &:hover {
      background-color: transparent;
      color: ${THEME.primaryColors.blackMain};
    }
  }

  &.MuiToggleButton-root.Mui-selected {
    background-color: transparent;
    color: ${THEME.primaryColors.blackMain};
    border-bottom: 2px solid black;
  }

  &:first-child {
    margin-right: 4px;
  }
`;

export const StyledRunsBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 18px;
  padding-right: ${({ $errorCount }) => ($errorCount ? '24px' : '18px')};
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.3s ease-out;
  cursor: pointer;
  &:hover {
    background-color: ${THEME.greyColors.grey24};
  }
`;

export const StyledErrorsButton = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledIssuesButton = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  padding: 8px 10px;
  border-radius: 6px;
  transition: all 0.3s ease-out;

  & > svg {
    margin-right: 8.8px;
  }

  &:hover {
    background: ${THEME.secondaryColors.negativeRank};
  }
`;
