import { bool, shape, string } from 'prop-types';

const flowStep = {
  __typename: string.isRequired,
  id: string.isRequired,
  name: string.isRequired,
  group: string.isRequired,
  isValid: bool.isRequired,
  nextStepId: string,
  subLabel: string,
  trigger: bool.isRequired,
  locked: bool.isRequired,
  entered: string,
  exited: string,
};

export const flowStepPropType = shape(flowStep);

export const flowStepTypes = {
  CompositeFlowStep: 'CompositeFlowStep',
  WebhookActionStep: 'WebhookActionStep',
  DelayActionStep: 'DelayActionStep',
  WebhookTriggerStep: 'WebhookTriggerStep',
  ConnectionEventTriggerStep: 'ConnectionEventTriggerStep',
  DataLookupStep: 'DataLookupStep',
  DataExportStep: 'DataExportStep',
  IfElseStep: 'IfElseStep',
  DataEventTriggerStep: 'DataEventTriggerStep',
  CheckoutFormStep: 'CheckoutFormStep',
  DataConnectionActionStep: 'DataConnectionActionStep',
  DataActionStep: 'DataActionStep',
  DataConditionStep: 'DataConditionStep',
  ScheduledTriggerStep: 'ScheduledTriggerStep',
  UserActionStep: 'UserActionStep',
  CountConditionStep: 'CountConditionStep',
  ABTestConditionStep: 'ABTestConditionStep',
  AnnotationStep: 'AnnotationStep',
  PaymentProcessingTrigger: 'PaymentProcessingTrigger',
};

export const flowStepConditionTypes = [flowStepTypes.DataConditionStep, flowStepTypes.CountConditionStep];
export const flowStepTriggerTypes = [
  flowStepTypes.WebhookTriggerStep,
  flowStepTypes.ConnectionEventTriggerStep,
  flowStepTypes.DataEventTriggerStep,
  flowStepTypes.ScheduledTriggerStep,
  flowStepTypes.PaymentProcessingTrigger,
];

export const requiredTestUserDecisionStepTypes = [
  flowStepTypes.DataActionStep,
  flowStepTypes.DataConnectionActionStep,
  flowStepTypes.WebhookActionStep,
];
