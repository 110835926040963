import { gql } from '@apollo/client';

export const FLOW_CONDITION_FRAGMENT = gql`
  fragment f_Condition on DataPropertyCondition {
    id
    tests {
      left
      leftLabel
      op
      right
      rightLabel
    }
    logicalOperator
    label
    nextStepId
    op
    right
  }
`;

export const PUB_FLOW_STEPS_FRAGMENT = gql`
  fragment pub_FlowStep on FlowStep {
    __typename
    id
    name
    nickname
    group
    subLabel
    isValid
    entered
    exited
    trigger
    nextStepId
    locked
    empty
    changed
    layout {
      location {
        top
        left
      }
      uiMetadata
    }
    icon {
      key
      url
    }
    contributes {
      key
      type
    }
    validationErrors {
      key
      label
      category
      group
      icon {
        key
        url
      }
      name
    }
    needsExamplesToTest
    # triggers
    ... on ConnectionEventTriggerStep {
      event
      connectionId
      connectionType
      connection {
        id
        name
        company {
          logo
        }
      }
      eventDetails {
        eventType
        label
        icon {
          key
          url
        }
        contributes {
          key
          type
        }
      }
      availableEvents {
        eventType
        label
        icon {
          key
          url
        }
        contributes {
          key
          type
        }
      }
      connectionStatus
    }
    ... on DataEventTriggerStep {
      dataType
      event
      connectionIds
      connectionType
      primaryConnectionType
      connections {
        id
        name
        company {
          logo
        }
      }
      eventDetails {
        eventType
        label
        icon {
          key
          url
        }
        contributes {
          key
          type
        }
      }
      availableEvents {
        eventType
        label
        icon {
          key
          url
        }
        contributes {
          key
          type
        }
      }
    }
    ... on DataLookupStep {
      nickname
      dataSetVariable
      dataSetProjection
      singleRecordUniquenessOperator
      selectedDataProvider
      selectedQueryType
      filters
    }
    ... on ScheduledTriggerStep {
      fixedRate
      cron
    }
    ... on WebhookTriggerStep {
      webhookUrl
      useBasicAuthentication
      username
      password
      payload
    }
    ... on PaymentProcessingTrigger {
      payload
    }
    # actions
    ... on DataExportStep {
      viewId
      includeAttachments
    }
    ... on DataActionStep {
      actionId
      dataType
      inputMappings {
        inputId
        key
      }
    }
    ... on DataConnectionActionStep {
      actionId
      connectionId
      originConnectionId
      connectionLabel
      inputMappings {
        inputId
        key
      }
      connectionStatus
    }
    ... on UserActionStep {
      actions
      newOwners
      prompt
    }
    ... on CheckoutFormStep {
      form
      submitText
      cancelText
      title
      notify
      enabledPaymentMethods
    }
    ... on WebhookActionStep {
      method
      url
      payloadType
      payload
      username
      password
      authenticationType
      headers {
        key
        label
      }
    }
    ... on DelayActionStep {
      delayTypes
      webhookUrl
      methods
      selectedType
      selectedMethod
      useBasicAuthentication
      username
      password
      payload
      selectedInputType
      delayInputTypes
      delayTime {
        days
        hours
        minutes
        seconds
      }
    }
    ... on ThreeDsStep {
      performThreeDs
      threeDsOptions {
        label
        value
      }
    }
    # conditions
    ... on DataConditionStep {
      selectedDataType
      conditionProperty
      propertyDataType
      conditions {
        ...f_Condition
      }
      elseStepId
      propertyToEval
      isPresetDataType
    }
    ... on IfElseStep {
      tests {
        left
        op
        right
      }
      logicalOperator
      trueStepId
      falseStepId
    }
    ... on CountConditionStep {
      selectedDataType
      conditions {
        ...f_Condition
      }
      elseStepId
      countScope
      countAction
    }
    ... on ABTestConditionStep {
      aPercent
      aBranchCondition {
        ...f_Condition
      }
      bBranchCondition {
        ...f_Condition
      }
    }
    # annotations
    ... on AnnotationStep {
      title
      text
      multimediaUrl
      multimediaType
    }
  }
  ${FLOW_CONDITION_FRAGMENT}
`;

export const PUB_FLOW_COMPOSITE_STEPS_FRAGMENT = gql`
  fragment pub_CompositeFlowStep on CompositeFlowStep {
    __typename
    id
    name
    group
    isValid
    entered
    exited
    trigger
    nextStepId
    empty
    layout {
      location {
        top
        left
      }
    }
    icon {
      key
      url
    }
    contributes {
      key
      type
    }
    children {
      ...pub_FlowStep
    }
  }
`;

export const GET_FLOW_STATE_FRAGMENT = gql`
  fragment f_FlowState on FlowState {
    # IMPORTANT: DO NOT COMMENT ANY FIELDS
    key
    source
    value
    display
    label
    type
  }
`;

export const GET_FLOW_TEMPLATE_CONFIG_FRAGMENT = gql`
  fragment f_FlowTemplateConfiguration on FlowTemplateConfiguration {
    initialState {
      ...f_FlowState
    }
  }
  ${GET_FLOW_STATE_FRAGMENT}
`;

export const PUB_FLOW_CONFIG_FRAGMENT = gql`
  fragment pub_FlowConfiguration on FlowConfiguration {
    id
    systemCreated
    systemUpdated
    firstStep {
      id
    }
    steps {
      ...pub_FlowStep
    }
    initialState {
      ...f_FlowState
    }
  }
  ${PUB_FLOW_STEPS_FRAGMENT}
  ${GET_FLOW_STATE_FRAGMENT}
`;

const filterDataInput = gql`
  fragment filterDataInput on FilterData {
    value
    selectedVerb
  }
`;

export const paymentFilter = gql`
  fragment paymentFilter on PaymentFilterData {
    dateRange {
      ...filterDataInput
    }
    date {
      ...filterDataInput
    }
    amount {
      ...filterDataInput
    }
    currency {
      ...filterDataInput
    }
    status {
      ...filterDataInput
    }
    method {
      ...filterDataInput
    }
    gateway {
      ...filterDataInput
    }
    type {
      ...filterDataInput
    }
    country {
      ...filterDataInput
    }
  }

  ${filterDataInput}
`;

export const FLOW_FRAGMENT = gql`
  fragment f_FLow on Flow {
    id
    __typename
    name
    status
    paymentFlowId
    minimumPlanRequired
    errorCount
    flowInstanceCount
    config {
      ...pub_FlowConfiguration
    }
    draftConfig {
      ...pub_FlowConfiguration
    }
  }
  ${PUB_FLOW_CONFIG_FRAGMENT}
`;

export const CONFIGURATION_ERRORS = gql`
  fragment f_Errors on ConfigurationErrors {
    totalCount
    errors
  }
`;

export const FLOW_FRAGMENT_SMALL = gql`
  fragment f_Flow_Small on Flow {
    __typename
    id
    name
    status
    instruct
  }
`;

export const GET_FLOW_LOGS_FRAGMENT = gql`
  fragment f_FlowLog on FlowLog {
    id
    stepId
    ts
    level
    message
  }
`;

export const GET_FLOW_STEP_LOGS_FRAGMENT = gql`
  fragment f_FlowStepLog on FlowStepLog {
    id
    stepId
    ts
    input {
      ...f_FlowState
    }
    output {
      ...f_FlowState
    }
    logs {
      ...f_FlowLog
    }
  }
  ${GET_FLOW_STATE_FRAGMENT}
  ${GET_FLOW_LOGS_FRAGMENT}
`;

export const GET_FLOW_INSTANCE_FRAGMENT = gql`
  fragment f_FlowInstance on FlowInstance {
    id
    flow {
      id
      name
    }
    status
    requester {
      id
      name
    }
    created
    currentStep {
      ...pub_FlowStep
    }
    stepLogs {
      ...f_FlowStepLog
    }
    steps {
      ...pub_FlowStep
    }
    logs {
      ...f_FlowLog
    }
    state {
      ...f_FlowState
    }
    isRetry
    originalInstanceId
  }
  ${PUB_FLOW_STEPS_FRAGMENT}
  ${GET_FLOW_LOGS_FRAGMENT}
  ${GET_FLOW_STEP_LOGS_FRAGMENT}
  ${GET_FLOW_STATE_FRAGMENT}
`;

export const KEYED_LABEL_FRAGMENT = gql`
  fragment f_KeyedLabel on KeyedLabel {
    key
    label
  }
`;

export const AB_STAT_BRANCH_RESULT_FRAGMENT = gql`
  fragment f_ABStatBranchResult on ABStatBranchResult {
    primaryValue
    secondaryValue
    rank
  }
`;

export const PROPERTY_ITEM = gql`
  fragment propertyItem on PropertyItem {
    key
    label
    path
    type
    description
    dataType
    valueExample
  }
`;

export const PROPERTY_GROUP = gql`
  fragment propertySubgroup on PropertyGroup {
    label
    icon {
      key
      url
    }
    type
    items {
      ...propertyItem
    }
    description
  }
`;

export const PROPERTY_SUBGROUP_RECURSIVE = gql`
  fragment propertySubgroupRecursive on PropertyGroup {
    ...propertySubgroup
    subgroups {
      ...propertySubgroup
      subgroups {
        ...propertySubgroup
        subgroups {
          ...propertySubgroup
          subgroups {
            ...propertySubgroup
            subgroups {
              ...propertySubgroup
              subgroups {
                ...propertySubgroup
              }
            }
          }
        }
      }
    }
  }
  ${PROPERTY_GROUP}
`;

export const STEP_COMMENT = gql`
  fragment f_StepComment on StepComment {
    id
    authorName
    authorId
    flowId
    stepId
    text
    timestamp
  }
`;
