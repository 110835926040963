import { gql } from '@apollo/client';

export const LIST_DISPUTES_GQL = gql`
  query listPaymentDisputes($data: DisputeFilterInput, $first: Int, $after: String, $last: Int, $before: String) {
    listPaymentDisputes(data: $data, first: $first, after: $after, last: $last, before: $before) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        totalSize
      }
      edges {
        node {
          id
          reason
          processorReason
          status
          processorStatus
          disputeDate
          responseDate
          amount {
            formattedAmount
            currency
          }
          processor
          sourceId
          paymentId
          connectionId
          teamId
        }
      }
    }
  }
`;

export const LIST_DISPUTES_STATS_GQL = gql`
  query listDisputeStats {
    listDisputeStats {
      amountStats {
        amount {
          formattedAmount
          currency
        }
        percentage
        label
      }
      countStats {
        count
        percentage
        label
      }
    }
  }
`;

export const LIST_DISPUTES_EVIDENCE_SUBMISSION = gql`
  query listDisputeEvidenceSubmissions($disputeId: ID!) {
    listDisputeEvidenceSubmissions(disputeId: $disputeId) {
      id
      disputeId
      formData
      status
      systemCreated
      systemUpdated
      attachmentDetails {
        id
        evidenceCategory
        evidenceName
        fileName
        objectKey
        contentType
        submissionId
        accessUrl
      }
    }
  }
`;

export const GET_DISPUTE_EVIDENCE_FORM = gql`
  query getDisputeEvidenceForm($connectionId: String!, $sourceDisputeId: String) {
    getDisputeEvidenceForm(connectionId: $connectionId, sourceDisputeId: $sourceDisputeId) {
      form
    }
  }
`;

export const SAVE_DISPUTE_EVIDENCE_SUBMISSION = gql`
  mutation saveDisputeEvidenceSubmission($submission: DisputeEvidenceSubmissionInput!) {
    saveDisputeEvidenceSubmission(submission: $submission) {
      id
      disputeId
      formData
      status
      attachmentDetails {
        id
        evidenceCategory
        fileName
        objectKey
        contentType
        accessUrl
        submissionId
      }
    }
  }
`;

export const SUBMIT_DISPUTE_EVIDENCE_SUBMISSION = gql`
  mutation submitDisputeEvidenceSubmission($submissionId: ID!) {
    submitDisputeEvidenceSubmission(submissionId: $submissionId) {
      id
      disputeId
      formData
      status
      systemCreated
      systemUpdated
      attachmentDetails {
        id
        evidenceCategory
        fileName
        objectKey
        contentType
        accessUrl
        submissionId
        systemCreated
        systemUpdated
      }
    }
  }
`;

export const ACCEPT_DISPUTE = gql`
  mutation acceptDispute($connectionId: String!, $disputeId: String!) {
    acceptDispute(connectionId: $connectionId, disputeId: $disputeId) {
      id
      reason
      processorReason
      status
      processorStatus
      disputeDate
      responseDate
      amount {
        rawAmount
        currency
      }
      processor
      rawDispute
      sourceId
      sourceDisputeId
      connectionId
      teamId
      customerId
    }
  }
`;

export const GET_DISPUTE_EVIDENCE_FILES_CONFIG = gql`
  query getDisputeEvidenceFilesConfig($connectionId: String!, $sourceDisputeId: String) {
    getDisputeEvidenceFilesConfig(connectionId: $connectionId, sourceDisputeId: $sourceDisputeId) {
      fileTypes {
        fileTypeCategory
        fileTypeName
        fileTypeCode
        required
      }
      fileFormats {
        contentType
        maxFileSizeMB
      }
    }
  }
`;
