/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, useEffect } from 'react';
import { string, shape, bool } from 'prop-types';
import { isEmpty } from 'lodash';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import StyledTooltip from '../../../atoms/StyledTooltip';
import { StyledLabel, StyledError } from '../styled';
import { P } from '../../../atoms';
import THEME from '../../../../constants/theme';
import { ContainerBox, StyledChip, StyledTextField } from './styled';
import { validateEmail } from '../../../../utils/helpers';

const TagInput = ({
  name,
  label,
  customErrorProps,
  skipError,
  width,
  margin,
  tooltip,
  submitted,
  validateEmails,
  disabled,
}) => {
  const [{ value }, { touched, error }, { setValue }] = useField(name);

  const [tags, setTags] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const { t } = useTranslation();

  const addTag = useCallback(
    (value) => {
      if (value && !tags.find((tag) => tag.value === value)) {
        const newTags = [...tags, { value, duplicate: false }];
        setTags(newTags);
      } else if (value) {
        const newTags = [...tags, { value, duplicate: true }];
        setTags(newTags);
      }
    },
    [tags]
  );

  const handleKeyDown = useCallback(
    (e) => {
      const value = e.target.value.trim();

      if (value && (e.key === 'Enter' || e.key === ' ')) {
        e.preventDefault();
        addTag(value);
        e.target.value = '';
      }

      if (!isEmpty(tags) && e.key === 'Backspace' && !e.target.value) {
        const newTags = tags.slice(0, tags.length - 1);
        setTags(newTags);
      }

      if (e.key === 'Escape') {
        setTags([]);
      }
    },
    [addTag, tags]
  );

  const handleDelete = useCallback(
    (tagToDelete) => () => {
      const newTags = tags.filter((tag) => tag.value !== tagToDelete.value);
      setTags(newTags);
    },
    [tags]
  );

  const handleBlur = useCallback(
    (e) => {
      setIsFocused(false);
      addTag(e.target.value.trim());
      e.target.value = '';
    },
    [addTag]
  );

  useEffect(() => {
    const duplicateRemovedTags = tags.filter((tag) => !tag.duplicate).map((tag) => tag.value);

    let finalTags;

    // Add validations
    if (validateEmails) {
      finalTags = duplicateRemovedTags.filter(validateEmail);
    } else {
      finalTags = duplicateRemovedTags;
    }

    setValue(finalTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  useEffect(() => {
    if (submitted) setTags([]);
  }, [submitted]);

  useEffect(() => {
    if (value) {
      const removedDuplicates = [...new Set(value)];
      const formattedValues = removedDuplicates.reduce((acc, curr) => {
        const obj = {};

        obj.value = curr;
        obj.duplicate = false;

        acc.push(obj);

        return acc;
      }, []);

      setTags(formattedValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box margin={margin ?? '0 0 16px 0'} width={width}>
      <StyledLabel>
        <Box display="flex" justifyContent="flex-start" alignItems="center" position="relative">
          <P
            fontSize="12px !important"
            fontWeight={600}
            {...(!tooltip && { width: '100%' })}
            textAlign="left"
            lineHeight="30px"
          >
            {t(`${label}`)}
          </P>

          {tooltip && <StyledTooltip title={tooltip} />}
        </Box>

        <ContainerBox $isFocused={isFocused}>
          {tags.map((tag, index) => (
            <StyledChip
              key={`${tag}-${index}`}
              label={tag.value}
              variant="outlined"
              color="primary"
              onDelete={handleDelete(tag)}
              $hasTag={tags.length > 0}
              disabled={disabled}
            />
          ))}

          <StyledTextField
            onKeyDown={handleKeyDown}
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur}
            disabled={disabled}
            variant="standard"
          />
        </ContainerBox>
      </StyledLabel>

      {touched && !skipError && Array.isArray(error)
        ? error?.map((message) => (
            <StyledError
              key={message}
              data-cy="error"
              color={THEME.secondaryColors.danger}
              {...(customErrorProps && customErrorProps)}
            >
              {t(message)}
            </StyledError>
          ))
        : touched &&
          !skipError && (
            <StyledError
              data-cy="error"
              color={THEME.secondaryColors.danger}
              {...(customErrorProps && customErrorProps)}
            >
              {t(error)}
            </StyledError>
          )}
    </Box>
  );
};

TagInput.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  customErrorProps: shape({}),
  skipError: bool,
  width: string,
  margin: string,
  tooltip: string,
  submitted: bool,
  validateEmails: bool,
  disabled: bool,
};

TagInput.defaultProps = {
  customErrorProps: {},
  skipError: false,
  width: undefined,
  margin: undefined,
  tooltip: '',
  submitted: false,
  validateEmails: false,
  disabled: false,
};

export default TagInput;
