import React from 'react';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { StyledDialog, StyledDialogActions, StyledPaper } from '../../../../Dialog/styled';
import { ButtonRounded, P14 } from '../../../../atoms';
import { L16B } from '../../../../atoms/Typography/L16B';
import THEME from '../../../../../constants/theme';
import { useHandleRetry } from '../hooks/useHandleRetry';
import { useFlowEditorContext } from '../../../context';

const RetryConfirmDialog = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const { setSelectedRetryStepId } = useFlowEditorContext();
  const { handleRetry } = useHandleRetry();

  const handleLeave = () => {
    setSelectedRetryStepId(null);
    setOpen(false);
  };

  const handleConfirm = () => {
    handleRetry();
    setOpen(false);
  };

  return (
    <StyledDialog
      open={open}
      scroll="paper"
      maxWidth="xs"
      PaperComponent={StyledPaper}
      aria-labelledby="retry-confirm-dialog"
      width="337px"
      PaperProps={{
        $borderRadius: '8px',
      }}
      $bgColor="rgba(0, 0, 0, 0.2)"
    >
      <Box padding="24px">
        <L16B padding="0 0 12px 0">{t('Attention')}</L16B>
        <P14 color={THEME.greyColors.grey11} padding="0 0 24px 0">
          {t('Are you sure you want to retry the run from this node?')}
        </P14>
        <StyledDialogActions $justifyContent="center" px="0" py="0">
          <ButtonRounded type="button" variant="contained" color="secondary" width="50%" onClick={handleLeave}>
            {t('Cancel')}
          </ButtonRounded>
          <ButtonRounded type="button" variant="contained" color="primary" width="50%" onClick={handleConfirm}>
            {t('Proceed')}
          </ButtonRounded>
        </StyledDialogActions>
      </Box>
    </StyledDialog>
  );
};

RetryConfirmDialog.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default RetryConfirmDialog;
