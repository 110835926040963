import styled, { css, keyframes } from 'styled-components';
import { withStyles } from '@mui/styles';
import IconButtonBase from '@mui/material/IconButton';
import { EdgeText } from 'react-flow-renderer';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import THEME from '../../../../constants/theme';

const dash = keyframes`
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
`;

const PathAnimation = css`
  animation: ${dash} 3s linear forwards;
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
`;

export const Path = styled.path`
  &.react-flow__edge-path-selector {
    fill: none;
    stroke: transparent;
    stroke-width: 12;
  }
`;

export const AnimatedPath = styled.path`
  stroke: ${({ $stroke }) => $stroke || THEME.primaryColors.purpleMain};
  stroke-width: 1.5;
  ${PathAnimation};
`;

export const StyledIconButton = withStyles({
  root: {
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    visibility: 'visible',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    padding: '0',
    backgroundColor: '#000',
    '&:hover': {
      backgroundColor: '#000',
    },
  },
})(IconButtonBase);

export const StyledEditButton = styled(StyledIconButton)`
  &.MuiButtonBase-root {
    visibility: hidden;
    width: 16px;
    height: 16px;
  }
`;

export const StyledMoreHorizIcon = withStyles({
  root: {
    width: '100%',
    height: '100%',
    fontSize: '16px',
  },
})(MoreHorizIcon);

export const StyledWrapper = styled(Box)`
  display: flex;
  align-items: center;
  position: relative;
`;

export const StyledEdgeText = styled(EdgeText)`
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  color: #787f88;
  letter-spacing: 0.1px;
  text-transform: uppercase;
`;

export const StyledLabel = styled.div`
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  color: ${({ $color }) => $color || `${THEME.greyColors.grey1}`};
  letter-spacing: 0.1px;
  text-align: center;
  text-transform: uppercase;
  padding: 5px 8px;
  background-color: #f5f6f7;
  cursor: default;
`;

export const OpBox = styled.span`
  text-transform: uppercase;
`;

export const StyledPopover = styled(Popover)`
  & .MuiPaper-root {
    background-color: transparent;
    border: none !important;
    border-radius: 6px !important;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04), 0 0 14px rgba(0, 0, 0, 0.04) !important;
  }
`;

export const StyledLabelWrapper = styled(Box)`
  display: flex;
  align-items: center;
  &:hover {
    .MuiButtonBase-root {
      visibility: visible;
    }
  }
`;

export const StyledConditionBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ConditionsBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 8px 12px 4px;
`;

export const ConditionInnerBox = styled(StyledConditionBox)`
  width: ${({ $width }) => ($width ? `${$width}` : '100%')};
  height: ${({ $height }) => ($height ? `${$height}` : '100%')};
  background-color: ${THEME.secondaryColors.inputBg};
  border: ${({ $edgeColor }) => ($edgeColor ? `2px solid ${$edgeColor}` : `${THEME.greyColors.grey500}`)};
  border-radius: 6px;
  position: relative;
  padding-left: ${({ $isElse }) => ($isElse ? '0' : '40px')};
`;

export const InitialConditionBox = styled(StyledConditionBox)`
  width: 40px;
  border-radius: 6px 0 0 6px;
  color: ${THEME.primaryColors.white};
  background-color: ${({ $edgeColor }) => $edgeColor || `${THEME.greyColors.grey500}`};
  ${({ $variant }) => {
    if ($variant === 'standalone') {
      return css`
        height: 40px;
        border-radius: 6px;
      `;
    }
    return css`
      position: absolute;
      left: -2px;
      top: -2px;
      bottom: -2px;
    `;
  }}
`;

export const TestOpBox = styled.span`
  text-transform: lowercase;
`;
