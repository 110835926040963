import React from 'react';

const BarTwo = () => {
  return (
    <svg width="44" height="4" viewBox="0 0 44 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="44" height="4" fill="#E6E9EC" />
    </svg>
  );
};

export default BarTwo;
