import React, { useRef } from 'react';
import { bool, string } from 'prop-types';
import { NodeLabel } from './styled';

const ScrollingRow = ({ children, isHover, textColor, textFontSize, ...props }) => {
  const textContainerRef = useRef(null);
  const textRef = useRef(null);
  const textContainerWidth = textContainerRef?.current?.offsetWidth;
  const textWidth = textRef?.current?.offsetWidth;
  const isTextOverflow = textWidth > textContainerWidth;
  const textOverflowDiff = textContainerWidth - textWidth;

  return (
    <NodeLabel
      className={isHover && isTextOverflow && 'transition'}
      ref={textContainerRef}
      width="100%"
      height="100%"
      lineHeight="18px"
      color={textColor}
      fontSize={textFontSize}
      $diff={textOverflowDiff}
      {...props}
      noWrap
    >
      <span ref={textRef}>{children}</span>
    </NodeLabel>
  );
};

ScrollingRow.propTypes = {
  isHover: bool.isRequired,
  textColor: string,
  textFontSize: string,
};

ScrollingRow.defaultProps = {
  textColor: null,
  textFontSize: null,
};

export default ScrollingRow;
