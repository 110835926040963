import addPersonValidator from '../validators/addPersonValidator';
import { ROLES, ROLE_LABEL_MAP } from '../../permissions/constants';

export const addPersonFieldsNames = {
  name: 'name',
  email: 'email',
  systemUserRole: 'systemUserRole',
};

export const addPersonFields = [
  { field: addPersonFieldsNames.name, label: 'Name' },
  { field: addPersonFieldsNames.email, label: 'Email address' },
  { field: addPersonFieldsNames.systemUserRole, label: 'Role' },
];

export const addPersonOptions = [
  { value: ROLES.owner, text: { text: ROLE_LABEL_MAP[ROLES.owner] } },
  { value: ROLES.admin, text: { text: ROLE_LABEL_MAP[ROLES.admin] } },
  { value: ROLES.developer, text: { text: ROLE_LABEL_MAP[ROLES.developer] } },
  { value: ROLES.operator, text: { text: ROLE_LABEL_MAP[ROLES.operator] } },
  // { value: ROLES.customerSupport, text: { text: ROLE_LABEL_MAP[ROLES.customerSupport] } }, // add back when BE updates are made
];

const addPersonSchema = {
  mapPropsToValues: () => ({
    [addPersonFieldsNames.name]: '',
    [addPersonFieldsNames.email]: '',
    [addPersonFieldsNames.systemUserRole]: '',
  }),
  validationSchema: addPersonValidator,
  displayName: 'addPersonForm',
};

export default addPersonSchema;
