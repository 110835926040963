import React, { useCallback, useEffect, useRef, useState } from 'react';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StyledDialog, StyledDialogContent, StyledPaper } from '../../Dialog/styled';
import CloseButton from '../../Dialog/CloseButton';
import THEME from '../../../constants/theme';
import { P14, SearchBar } from '../../atoms';
import { StyledBox, StyledColumnsBox, StyledNoDataWrapper } from './styled';
import Column from './Column';
import DescriptionColumn from './DescriptionColumn';
import { usePropertyTextEditorContext } from '../context';
import SearchColumn from './SearchColumn';
import NoData from '../../../assets/icons/AvailableProperties/NoData';
import NoSearchData from '../../../assets/icons/AvailableProperties/NoSearchData';
import { propertyObjectTypes } from './types';

const PropertyTextEditorModal = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const {
    availableProperties,
    selectedPropertyObject,
    setSelectedPropertyObject,
    setSelectedPathArr,
  } = usePropertyTextEditorContext();
  const columnsBoxRef = useRef(null);
  const searchValue = search?.toLowerCase()?.trim();
  const groups = availableProperties?.groups ?? [];
  const items = availableProperties?.items ?? [];
  const filteredItems =
    searchValue === '' ? items : items?.filter((item) => item.label?.toLowerCase().includes(searchValue));

  useEffect(() => {
    if (selectedPropertyObject?.type === propertyObjectTypes.ITEM) {
      const pathArr = selectedPropertyObject?.path?.split(' / ');
      setSelectedPathArr(pathArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const width = columnsBoxRef?.current?.clientWidth;
    if (width) {
      columnsBoxRef.current.scrollLeft = width;
    }
  }, [selectedPropertyObject]);
  const handleClose = useCallback(() => {
    setSelectedPropertyObject(null);
    setSelectedPathArr(null);
    setOpen(false);
  }, [setOpen, setSelectedPathArr, setSelectedPropertyObject]);

  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    document.addEventListener('keyup', escFunction, false);

    return () => {
      document.removeEventListener('keyup', escFunction, false);
    };
  }, [escFunction]);

  return (
    <StyledDialog
      open={open}
      scroll="paper"
      maxWidth="xs"
      PaperComponent={StyledPaper}
      aria-labelledby="video-player-modal"
      width="1104px"
      $height="689px"
      PaperProps={{
        $borderRadius: '8px',
        $overflowY: 'visible',
      }}
    >
      <>
        <CloseButton
          onClick={handleClose}
          $bgcolor={THEME.primaryColors.white}
          $hoverBg={THEME.primaryColors.white}
          $borderRadius="50%"
          $boxShadow="0 0 16px rgba(0, 0, 0, 0.08)"
          top="-20px"
          right="-20px"
        />
        <SearchBar search={search} setSearch={setSearch} bgcolor={THEME.primaryColors.white} height="57px" noClear />
        <StyledDialogContent px="0" $borderTop={`1px solid ${THEME.greyColors.grey16}`}>
          <StyledBox height="100%">
            {groups?.length && !searchValue ? (
              <StyledColumnsBox height="100%" ref={columnsBoxRef}>
                <Column groups={groups} />
              </StyledColumnsBox>
            ) : null}
            {groups?.length && searchValue && filteredItems?.length ? <SearchColumn items={filteredItems} /> : null}
            {groups?.length && filteredItems?.length && !!selectedPropertyObject ? <DescriptionColumn /> : null}
            {groups?.length && searchValue && !filteredItems?.length ? (
              <StyledNoDataWrapper>
                <NoSearchData />
                <P14 margin="16px 0 0 0" color={THEME.greyColors.grey600}>
                  {t('No results matching your search query.')}
                </P14>
              </StyledNoDataWrapper>
            ) : null}
            {!groups?.length ? (
              <StyledNoDataWrapper>
                <NoData />
                <P14 margin="16px 0 0 0" color={THEME.greyColors.grey600}>
                  {t('No objects available for this field.')}
                </P14>
              </StyledNoDataWrapper>
            ) : null}
          </StyledBox>
        </StyledDialogContent>
      </>
    </StyledDialog>
  );
};

PropertyTextEditorModal.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default PropertyTextEditorModal;
