import { gql } from '@apollo/client';

const SIGNUP_MANGOPAY_INTERNAL = gql`
  mutation signupMangoPayInternal($customer: MangoPayCustomerInput) {
    signupMangoPayInternal(customer: $customer) {
      customer {
        clientId
      }
      isLoggedIn
      status
    }
  }
`;

export { SIGNUP_MANGOPAY_INTERNAL };
