import React from 'react';

const PaymentProcessing = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="52" height="52" rx="8" fill="#F5F2FF" />
      <path
        d="M19.1224 18.5C18.3182 18.5 17.6641 19.1542 17.6641 19.9583V22.4583C17.6641 23.2625 18.3182 23.9167 19.1224 23.9167H21.6224C21.7552 23.9167 21.8817 23.8931 22.0041 23.8597L25.2845 28.9639C25.2076 29.1412 25.1641 29.3363 25.1641 29.5417V32.0417C25.1641 32.8458 25.8182 33.5 26.6224 33.5H29.1224C29.9266 33.5 30.5807 32.8458 30.5807 32.0417V29.5417C30.5807 28.7375 29.9266 28.0833 29.1224 28.0833H26.6224C26.4895 28.0833 26.3631 28.1069 26.2407 28.1403L22.9603 23.0361C23.0372 22.8588 23.0807 22.6637 23.0807 22.4583V21.8333H28.9141V22.4583C28.9141 23.2625 29.5682 23.9167 30.3724 23.9167H32.8724C33.6766 23.9167 34.3307 23.2625 34.3307 22.4583V19.9583C34.3307 19.1542 33.6766 18.5 32.8724 18.5H30.3724C29.5682 18.5 28.9141 19.1542 28.9141 19.9583V20.5833H23.0807V19.9583C23.0807 19.1542 22.4266 18.5 21.6224 18.5H19.1224Z"
        fill="#4E40EF"
      />
    </svg>
  );
};

export default PaymentProcessing;
