import React from 'react';

const Comment = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16669 3.33329C3.94567 3.33329 3.73371 3.42109 3.57743 3.57737C3.42115 3.73365 3.33335 3.94561 3.33335 4.16663V15.4881L5.2441 13.5774C5.40038 13.4211 5.61234 13.3333 5.83335 13.3333H15.8334C16.0544 13.3333 16.2663 13.2455 16.4226 13.0892C16.5789 12.9329 16.6667 12.721 16.6667 12.5V4.16663C16.6667 3.94561 16.5789 3.73365 16.4226 3.57737C16.2663 3.42109 16.0544 3.33329 15.8334 3.33329H4.16669ZM2.39892 2.39886C2.86776 1.93002 3.50365 1.66663 4.16669 1.66663H15.8334C16.4964 1.66663 17.1323 1.93002 17.6011 2.39886C18.07 2.8677 18.3334 3.50358 18.3334 4.16663V12.5C18.3334 13.163 18.07 13.7989 17.6011 14.2677C17.1323 14.7366 16.4964 15 15.8334 15H6.17853L3.08928 18.0892C2.85094 18.3275 2.49251 18.3988 2.18112 18.2699C1.86972 18.1409 1.66669 17.837 1.66669 17.5V4.16663C1.66669 3.50358 1.93008 2.8677 2.39892 2.39886Z"
        fill="#000"
      />
    </svg>
  );
};

export default Comment;
