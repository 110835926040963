import styled from 'styled-components';
import { IconButton } from '@mui/material';

const CustomIconButton = styled(IconButton)`
  svg {
    color: ${({ fill }) => fill || 'inherit'};
  }
`;

export default CustomIconButton;
