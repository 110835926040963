import React from 'react';
import Box from '@mui/material/Box';
import isEmpty from 'lodash/isEmpty';
import CustomerBlock from './sections/Customer/CustomerBlock';
import Metadata from './sections/Metadata';
import FlowsBlock from './sections/FlowsBlock';
import Summary from './sections/Summary';
import Timeline from './sections/Timeline';
import PaymentAttemptSection from './sections/PaymentAttemptSection';
import PaymentDetailsIssueBlock from '../Issues/PaymentDetailsIssueBlock';

import LoadingState from './LoadingState';
import { MainContent, SideLeft, SideRight } from '../../../components/GridSystem/styled';
import TableSection from '../../DataTables/components/Details/TableSection';
import RiskSection from './sections/RiskSection';
import Disputes from './sections/Disputes';
import { isDefined } from '../../../utils/helpers';
import { parseJSON } from '../../../utils/parseJSON';
import { INTENT_STATUSES } from './constant';
import {
  PAYMENT_DESCRIBED_KEYS,
  PAYMENT_DETAILS_DATA_KEYS,
  PAYMENT_METHOD_DATA_KEYS,
  FRAUD_DATA_KEYS,
  INTENT_SECTION_DATA_KEYS,
} from './paymentDescribedDataKeys';
import { PAYMENT_DETAILS_SECTION_COMPONENT_MAP } from './paymentDetailsComponentMap';
import usePermission from '../../../permissions/hooks/usePermission';
import { PAYMENTS_PERMISSIONS_IDS } from '../permissions';
import { useGetPaymentDetailsData } from './hooks/useGetPaymentDetailsData';
import { useFeature } from '../../../hooks/useFeature';
import { FEATURE_TOGGLES_KEYS } from '../../../constants/featureToggles';

import {
  getIntentSectionData,
  getPaymentDetailsSectionData,
  getPaymentMethodSectionData,
  getDisputesTimeline,
  getDisputesSectionData,
  getFraudData,
} from './_utils';

import { StyledWrapper } from './styled';

const Details = () => {
  const [hasPaymentManagementPermission] = usePermission(PAYMENTS_PERMISSIONS_IDS.paymentsManagement);
  const [paymentIssuesNavEnabled] = useFeature(FEATURE_TOGGLES_KEYS.PAYMENT_ISSUES_SUB_NAV);

  const { payment, intent, loading, error, id } = useGetPaymentDetailsData();

  const isIntent = INTENT_STATUSES.includes(payment?.status);

  /*Intent Section */
  const intentSectionData = getIntentSectionData(intent);

  /*Payment Details Section */
  const paymentAttempts =
    intent.linkedPayments
      ?.filter((linkedPayment) => linkedPayment.paymentId !== id)
      ?.filter((linkedPayment) => !INTENT_STATUSES.includes(linkedPayment.status)) || [];

  /* Payment Flow Section */
  const flowId = payment?.flowId;

  /* Fraud Section */
  const paymentFraudData = getFraudData(payment);

  /*Payment Details Section - Merging paymentGateway with paymentDetails */
  const paymentDetailsData = getPaymentDetailsSectionData({ isIntent, payment });

  /*Payment Method Section */
  const paymentMethodData = getPaymentMethodSectionData({ isIntent, payment });

  /*Customer Section */
  const customerData = payment?.paymentCustomer || {};

  /* Disputes Section */
  const isDisputedPayment = isDefined(payment?.dispute);
  const disputes = isDisputedPayment ? getDisputesSectionData({ payment }) : null;

  /*Metadata */
  const metadata = parseJSON(payment?.metadata);

  /*Timeline */
  const timelineData = {
    paymentData: payment?.paymentTimeline,
    intentData: intent?.timeline,
    disputeData: getDisputesTimeline({ payment }), // Needs refactoring
  };

  if (error && !loading) {
    return (
      <Box p={2} textAlign="center" bgcolor="error.main" color="error.contrastText">
        {error.message}
      </Box>
    );
  }

  if (loading) {
    return <LoadingState />;
  }

  /* Summary and CustomerBlock sections */
  const vaultedCustomer = payment?.vaultedCustomer;
  const index = vaultedCustomer?.indexOf(':');
  const vaultedCustomerId = vaultedCustomer?.slice(index + 1);

  const connectionId = payment?.connectionId?.value;

  return (
    <StyledWrapper>
      <Summary
        payment={payment}
        intentTrackingId={intent.trackingId}
        customer={customerData}
        vaultedCustomerId={vaultedCustomerId}
      />

      {isDisputedPayment && <Disputes dispute={disputes} connectionId={connectionId} />}

      <MainContent>
        <SideLeft>
          <FlowsBlock flowId={flowId} />

          {!isEmpty(intent) && (
            <TableSection
              header="Intent"
              data={intentSectionData}
              dataKey={INTENT_SECTION_DATA_KEYS}
              customComponentsMap={PAYMENT_DETAILS_SECTION_COMPONENT_MAP}
            />
          )}

          {!isEmpty(paymentAttempts) && <PaymentAttemptSection paymentAttempts={paymentAttempts} />}

          <RiskSection payment={payment} />

          {paymentFraudData && (
            <TableSection
              header="Fraud"
              data={paymentFraudData}
              dataKey={FRAUD_DATA_KEYS}
              customComponentsMap={PAYMENT_DETAILS_SECTION_COMPONENT_MAP}
              hideLabelKeys={[PAYMENT_DESCRIBED_KEYS.fraudAdditionalData]}
            />
          )}

          <CustomerBlock data={customerData} vaultedCustomerId={vaultedCustomerId} />

          <TableSection
            header="Payment Details"
            data={paymentDetailsData}
            dataKey={PAYMENT_DETAILS_DATA_KEYS}
            customComponentsMap={PAYMENT_DETAILS_SECTION_COMPONENT_MAP}
          />

          <TableSection
            header="Payment Method"
            data={paymentMethodData}
            dataKey={PAYMENT_METHOD_DATA_KEYS}
            customComponentsMap={PAYMENT_DETAILS_SECTION_COMPONENT_MAP}
          />

          {hasPaymentManagementPermission && paymentIssuesNavEnabled && <PaymentDetailsIssueBlock />}

          <Metadata json={metadata} />

          {/* Temporarily hide as we don't currently have attachments with MGP */}
          {/* <Attachments list={payment?.attachments || []} /> */}
        </SideLeft>
        <SideRight>
          <Timeline timelineData={timelineData} />
        </SideRight>
      </MainContent>
    </StyledWrapper>
  );
};

export default Details;
