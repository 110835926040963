export const propertyObjectTypes = {
  ITEM: 'ITEM',
  GROUP: 'GROUP',
};

export const availablePropertiesMockData = {
  items: [
    {
      key: '{{@score}}',
      label: 'Raw Score',
      path: 'Signifyd / Score / Raw Score',
      type: 'ITEM',
      __typename: 'PropertyItem',
    },
    {
      key: '{{@score2}}',
      label: 'Raw Score 2',
      path: 'Signifyd / Score / Raw Score 2',
      type: 'ITEM',
      __typename: 'PropertyItem',
    },
    {
      key: '{{@property}}',
      label: 'Property',
      path: 'Signifyd / Score / Raw Score',
      type: 'ITEM',
      __typename: 'PropertyItem',
    },
    {
      key: '{{@property2}}',
      label: 'Property 2',
      path: 'Signifyd / Score / Raw Score',
      type: 'ITEM',
      __typename: 'PropertyItem',
    },
    {
      key: '{{@property3}}',
      label: 'Property 3',
      path: 'Signifyd / Score / Raw Score',
      type: 'ITEM',
      __typename: 'PropertyItem',
    },
    {
      key: '{{@selection}}',
      label: 'Selection',
      path: 'Signifyd / Score / Raw Score',
      type: 'ITEM',
      __typename: 'PropertyItem',
    },
  ],
  groups: [
    {
      label: 'Signifyd',
      icon: 'https://media.graphcms.com/l1KPGoelTLS4eJoAJkwt',
      type: 'GROUP',
      subgroups: [
        {
          label: 'Score',
          items: [
            {
              key: '{{@score}}',
              label: 'Raw Score',
              path: 'Signifyd / Score / Raw Score',
              type: 'ITEM',
              __typename: 'PropertyItem',
            },
            {
              key: '{{@score2}}',
              label: 'Raw Score 2',
              path: 'Signifyd / Score / Raw Score 2',
              type: 'ITEM',
              __typename: 'PropertyItem',
            },
          ],
          __typename: 'PropertySubgroup',
          subgroups: null,
        },
        {
          label: 'Score 2',
          items: [
            {
              key: '{{@score3}}',
              label: 'Raw Score 3',
              path: 'Signifyd / Score / Raw Score 3',
              type: 'ITEM',
              __typename: 'PropertyItem',
            },
            {
              key: '{{@score4}}',
              label: 'Raw Score 4',
              path: 'Signifyd / Score / Raw Score',
              type: 'ITEM',
              __typename: 'PropertyItem',
            },
          ],
          __typename: 'PropertySubgroup',
          subgroups: null,
        },
      ],
      __typename: 'PropertyItemStructure',
    },
    {
      label: 'Signifyd 2',
      icon: 'https://media.graphcms.com/l1KPGoelTLS4eJoAJkwt',
      type: 'GROUP',
      subgroups: [
        {
          label: 'Score qqq',
          items: [
            {
              key: '{{@score2}}',
              label: 'Raw Score 8',
              path: 'Signifyd / Score / Raw Score',
              type: 'ITEM',
              __typename: 'PropertyItem',
            },
          ],
          __typename: 'PropertySubgroup',
          subgroups: [
            {
              label: 'Score rty',
              items: [
                {
                  key: '{{@score}}',
                  label: 'Raw Score gfh',
                  path: 'Signifyd / Score / Raw Score',
                  type: 'ITEM',
                  __typename: 'PropertyItem',
                },
                {
                  key: '{{@score2}}',
                  label: 'Raw Score cvb',
                  path: 'Signifyd / Score / Raw Score',
                  type: 'ITEM',
                  __typename: 'PropertyItem',
                },
              ],
              __typename: 'PropertySubgroup',
              subgroups: null,
            },
            {
              label: 'Score zxc',
              items: [
                {
                  key: '{{@score3}}',
                  label: 'Raw Score nnn',
                  path: 'Signifyd / Score / Raw Score',
                  type: 'ITEM',
                  __typename: 'PropertyItem',
                },
                {
                  key: '{{@score4}}',
                  label: 'Raw Score mlm',
                  path: 'Signifyd / Score / Raw Score',
                  type: 'ITEM',
                  __typename: 'PropertyItem',
                },
              ],
              __typename: 'PropertySubgroup',
              subgroups: null,
            },
          ],
        },
        {
          label: 'Score zzz',
          items: [
            {
              key: '{{@score3}}',
              label: 'Raw Score 9',
              path: 'Signifyd / Score / Raw Score',
              type: 'ITEM',
              __typename: 'PropertyItem',
            },
          ],
          __typename: 'PropertySubgroup',
          subgroups: null,
        },
      ],
      items: [
        {
          key: '{{@scorefff}}',
          label: 'Raw Score FFF',
          path: 'Signifyd / Score / Raw Score',
          type: 'ITEM',
          __typename: 'PropertyItem',
        },
        {
          key: '{{@scorettt}}',
          label: 'Raw Score TTT',
          path: 'Signifyd / Score / Raw Score',
          type: 'ITEM',
          __typename: 'PropertyItem',
        },
      ],
      __typename: 'PropertyItemStructure',
    },
  ],
};
