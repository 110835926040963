import { useQuery } from '@apollo/client';
import { GET_PROJECTIONS_FOR } from '../../../../../../../utils/queries/properties/editorPropertiesQueries';
import useGetAvailableDataProviders from '../../hooks/useGetAvailableDataProviders';
import { useNodeDataToSave } from './useNodeDataToSave';

const useGetProjectionsFor = () => {
  const [{ selectedDataProvider, dataSetVariable }] = useNodeDataToSave();

  const { availableDataProviders, loading } = useGetAvailableDataProviders();

  const dataProvider = availableDataProviders?.find(({ type }) => type === selectedDataProvider);
  const { data } = useQuery(GET_PROJECTIONS_FOR, {
    variables: {
      type: dataProvider?.type,
      property: dataSetVariable,
    },
  });

  const projections = data?.getProjectionsFor;

  const options = projections?.map((item) => ({ value: item, title: item }));

  return { projections, projectionsOptions: options, loading };
};

export default useGetProjectionsFor;
