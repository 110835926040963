import React from 'react';

const Number = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="4" fill="#F6CE4B" />
    <path
      d="M6.87 16V8.88H5.78L4.18 9.71V10.81L5.78 10.01V16H6.87ZM11.5988 13.25C12.4188 12.55 12.9688 11.9 12.9688 10.93C12.9688 9.54 11.9288 8.78 10.5288 8.78C9.25883 8.78 8.14883 9.59 8.08883 11.06H9.18883C9.21883 10.31 9.77883 9.79 10.5288 9.79C11.3488 9.79 11.8288 10.25 11.8188 11.03C11.8088 11.58 11.5088 11.98 10.8488 12.58L8.15883 15V16H12.9588V14.96H9.66883L11.5988 13.25ZM17.6429 12.33C18.3229 12.02 18.6929 11.43 18.6929 10.75C18.6929 9.51 17.7229 8.78 16.3129 8.78C14.7329 8.78 13.8129 9.69 13.8129 11.05H14.8829C14.8729 10.18 15.4329 9.72 16.2829 9.72C17.0429 9.72 17.5429 10.11 17.5429 10.8C17.5429 11.48 17.0929 11.91 16.2429 11.91H15.7329V12.82H16.2829C17.2029 12.82 17.6829 13.27 17.6829 13.98C17.6829 14.68 17.1629 15.15 16.2929 15.15C15.4429 15.15 14.8829 14.68 14.8829 13.82H13.7829C13.7829 15.18 14.7529 16.1 16.3329 16.1C17.8429 16.1 18.8629 15.31 18.8629 14.02C18.8629 13.23 18.4229 12.57 17.6429 12.33Z"
      fill="black"
    />
  </svg>
);

export default Number;
