import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import ErrorBoundary from '../../../utils/errorBoundary';
import BrandCenter from '../../../components/BrandCenter';
import BrandCenterSkeleton from './BrandCenterSkeleton';
import { H3 } from '../../../components/atoms';
import { useBrandCenterData, useBrandCenterEvents } from './hooks';

const BrandCenterPage = () => {
  const { t } = useTranslation();
  const { data, loading, graphics, masterTemplate, socialNetworks } = useBrandCenterData();
  const { handleButtonStyle, handleAccentColor, handleGraphics } = useBrandCenterEvents({
    data,
    graphics,
    masterTemplate,
    socialNetworks,
  });

  return (
    <ErrorBoundary>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" mb="18px">
        <H3 fontWeight="600" textAlign="left">
          {t('Brand Center')}
        </H3>
      </Box>
      {loading ? (
        <BrandCenterSkeleton />
      ) : (
        <BrandCenter
          data={data?.we?.brand}
          handleButtonStyle={handleButtonStyle}
          handleAccentColor={handleAccentColor}
          handleGraphics={handleGraphics}

          /* Disabled Feature - PT-1081 */
          /*handleSocialEdit={handleSocialEdit}
          we={data?.we?.name}
          handleLogoConfig={handleLogoConfig}
          handleSaveFooterText={handleSaveFooterText}
          handleSaveSignOffContent={handleSaveSignOffContent}
          handleLogoText={handleLogoText}*/
        />
      )}
    </ErrorBoundary>
  );
};

export default BrandCenterPage;
