import { string, number, arrayOf, elementType, shape, func, bool } from 'prop-types';
import React from 'react';
import { useTableStarterContext } from '../hooks/useTableStarter';
import SmartTable from '../../../components/SmartTable';

const Table = ({
  data,
  loading,
  isEmpty,
  pageInfo,
  headCells,
  initialTableState,
  transformHiddenColumnsFn,
  emptyStateComponent,
  customTableNegativeHeight,
  customDetailsId,
  customDetailsPathname,
  customDetailsPathParam,
  canExportTable,
  noCheckBox,
  width,
  height,
  margin,
  disableRowClick,
  headCellDropdownOptions,
  noLastRowBorder,
  noPagination,
  overflowY,
  loadingStateStyles,
  trHeight,
  noDnd,
}) => {
  const {
    shouldSaveTable,
    setShouldSaveTable,
    saveViewDialog,
    toggleSaveViewDialog,
    selected,
    setSelected,
    exportDialog,
    toggleExportDialog,
    searchSelectRef,
    checkboxEditMode,
    toggleCheckboxEditMode,
    setActiveDrawer,
    activeDrawer,
    drawerOpen,
  } = useTableStarterContext();

  return (
    <SmartTable
      data={data}
      isEmpty={isEmpty}
      loading={loading}
      setActiveDrawer={setActiveDrawer}
      activeDrawer={activeDrawer}
      drawerOpen={drawerOpen}
      shouldSaveTable={shouldSaveTable}
      setShouldSaveTable={setShouldSaveTable}
      exportDialog={exportDialog}
      toggleExportDialog={toggleExportDialog}
      selected={selected}
      setSelected={setSelected}
      checkboxEditMode={checkboxEditMode}
      toggleCheckboxEditMode={toggleCheckboxEditMode}
      pageInfo={pageInfo}
      globalTableSaveDialog={saveViewDialog}
      toggleSaveDialog={toggleSaveViewDialog}
      headCells={headCells}
      searchSelectRef={searchSelectRef}
      initialState={initialTableState}
      transformHiddenColumnsFn={transformHiddenColumnsFn}
      emptyStateComponent={emptyStateComponent}
      customTableNegativeHeight={customTableNegativeHeight}
      customDetailsPathname={customDetailsPathname}
      customDetailsId={customDetailsId}
      customDetailsPathParam={customDetailsPathParam}
      canExportTable={canExportTable}
      noCheckBox={noCheckBox}
      width={width}
      height={height}
      margin={margin}
      disableRowClick={disableRowClick}
      headCellDropdownOptions={headCellDropdownOptions}
      noLastRowBorder={noLastRowBorder}
      noPagination={noPagination}
      overflowY={overflowY}
      loadingStateStyles={loadingStateStyles}
      trHeight={trHeight}
      noDnd={noDnd}
    />
  );
};

Table.propTypes = {
  data: arrayOf(shape({})).isRequired,
  headCells: arrayOf(shape({})).isRequired,
  loading: bool.isRequired,
  isEmpty: bool.isRequired,
  pageInfo: shape({
    endCursor: number,
    totalPages: number,
    hasNextPage: bool,
    hasPreviousPage: bool,
  }).isRequired,
  initialTableState: shape({
    tableState: shape({
      columnResizing: shape({
        columnWidths: arrayOf(shape({})),
      }),
      columnOrder: arrayOf(string),
    }),
  }).isRequired,
  transformHiddenColumnsFn: func.isRequired,
  emptyStateComponent: elementType.isRequired,
  customTableNegativeHeight: string,
  customDetailsPathname: string,
  customDetailsId: string,
  customDetailsPathParam: string,
  canExportTable: bool,
  noCheckBox: bool,
  width: string,
  height: string,
  margin: string,
  disableRowClick: bool,
  headCellDropdownOptions: arrayOf(shape({})),
  noLastRowBorder: bool,
  noPagination: bool,
  overflowY: string,
  loadingStateStyles: shape({}),
  trHeight: string,
  noDnd: bool,
};

Table.defaultProps = {
  customTableNegativeHeight: null,
  customDetailsPathname: null,
  customDetailsId: null,
  customDetailsPathParam: null,
  canExportTable: false,
  noCheckBox: false,
  width: null,
  height: null,
  margin: null,
  disableRowClick: false,
  headCellDropdownOptions: null,
  noLastRowBorder: false,
  noPagination: false,
  overflowY: undefined,
  loadingStateStyles: {},
  trHeight: undefined,
  noDnd: false,
};

export default Table;
