import PropTypes from 'prop-types';
import React from 'react';
import { StyledMenuItem } from '../styled';
import UserItem from '../UserItem';
import AddIcon from '../../../assets/icons/Plus';
import THEME from '../../../constants/theme';

const CreateTeam = ({ toggleCreateTeamDialog }) => {
  return (
    <StyledMenuItem onClick={toggleCreateTeamDialog}>
      <UserItem
        bgColor={THEME.greyColors.grey9}
        fullName="Create New Team"
        icon={<AddIcon fill="#fff" size={24} />}
        info=""
        showInitials={false}
      />
    </StyledMenuItem>
  );
};

CreateTeam.propTypes = {
  toggleCreateTeamDialog: PropTypes.func.isRequired,
};

export default CreateTeam;
