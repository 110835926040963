import { gql } from '@apollo/client';

export const GET_AUTOMATION_TEMPLATES = gql`
  query getAvailableFlowTemplates {
    getAvailableFlowTemplates {
      showOnWebApp
      blogReady
      videoOnWebApp
      imageWebApp
      name
      description
      integrations
      recommended
      categories
      plan
      slug
      difficultyLevel
      difficultyDescription
      template {
        id
        paymentNeeds
      }
      isPopular
      apps
      useCases
      appCategories
      roles
    }
  }
`;

export const GET_RECOMMENDED_TEMPLATES = gql`
  query getRecommendedTemplates($optionalMaxResults: Int) {
    getRecommendedTemplates(optionalMaxResults: $optionalMaxResults) {
      name
      categories
      slug
      showOnWebApp
      blogReady
      description
      videoOnWebApp
      imageWebApp
      plan
      difficultyLevel
      difficultyDescription
      integrations
      template {
        id
        paymentNeeds
      }
    }
  }
`;
