import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import OnboardingLayoutBox from '../../components/layouts/OnboardingLayoutBox';
import OnboardingLayout from '../../components/layouts/OnboardingLayout';
import GoogleAuth from '../../components/GoogleAuth';
import { LineOr } from '../SignUp/styled';
import SubmitButton from '../../components/forms/_common/SubmitButton';
import { BlockWrap, P14 } from '../../components/atoms';
import { useCompleteGoogleSignUpCallback } from './useCompleteGoogleSignUpCallback';
import { GOOGLE_AUTH_TYPE } from '../../hooks/useGoogleOAuth';
import { UI_ROUTES } from '../../constants/routes';
import { GOOGLE_EMAIL_SWITCHER_PARAM_KEY, REG_METHOD } from '../../constants/registration';
import THEME from '../../constants/theme';

const TITLE = 'Complete your account set up';

const CompleteSignUp = () => {
  const { t } = useTranslation();
  const {
    location: { search },
    push,
  } = useHistory();
  const { loading } = useCompleteGoogleSignUpCallback();

  const handleSignInWithEmail = () => {
    push(`${UI_ROUTES.enterPassword}${search || ''}&${GOOGLE_EMAIL_SWITCHER_PARAM_KEY}=${REG_METHOD.googleEmail}`);
  };

  return (
    <OnboardingLayout
      theme="dark"
      navigationRight={
        <P14 color={THEME.greyColors.lightGrey}>
          {t('Already have an account?')}{' '}
          <Link to={UI_ROUTES.signIn} className="text-primary">
            {t('Sign in')}
          </Link>
        </P14>
      }
    >
      <OnboardingLayoutBox>
        <Box maxWidth="336px" margin="0 auto">
          <h1>{t(TITLE)}</h1>
          <BlockWrap margin="40px 0">
            <GoogleAuth type={GOOGLE_AUTH_TYPE.completeSignUp} loading={loading}>
              {t('Sign in with Google')}
            </GoogleAuth>
            <LineOr>{t('or')}</LineOr>
            <Box mt="24px">
              <SubmitButton width="100%" className="gradient" onClick={handleSignInWithEmail}>
                {t('Sign in with email')}
              </SubmitButton>
            </Box>
          </BlockWrap>
        </Box>
      </OnboardingLayoutBox>
    </OnboardingLayout>
  );
};

export default CompleteSignUp;
