/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
// import { Form } from 'formik';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
// import PropTypes from 'prop-types';

import AcceptTermsAndCondition from './AcceptTermsAndCondition';
import AcceptNewsletter from './AcceptNewsletter';

import { BlockWrap, Button } from '../../atoms';
import { signUpPaymentDetailsFields, signUpPaymentDetailsNames, initialValues } from './signUpPaymentDetailsSchema';
import CustomSelect from './CustomSelect';
import { FieldsWrapper } from '../formStyles';
import Input from '../_common/Input';
import { SELECT_OPTIONS, CUSTOM_SELECT_PROPS } from './consts';
import SubmitButton from '../_common/SubmitButton';
import { isDefined } from '../../../utils/helpers';
import SelectWithOthers from './SelectWithOthers';
import { useRegistrationStorage } from '../../../hooks/registration';
import { ONBOARDING_STATUS } from '../../../constants/registration';
import { UI_ROUTES } from '../../../constants/routes';
import signUpPaymentDetailsValidator from './signUpPaymentDetailsValidator';
import CustomRadios from '../_common/CustomRadios';

const COMPONENTS_MAP = {
  [signUpPaymentDetailsNames.paymentModel]: SelectWithOthers,
  [signUpPaymentDetailsNames.paymentServiceProvider]: SelectWithOthers,
  [signUpPaymentDetailsNames.paymentTransactions]: CustomSelect,
  [signUpPaymentDetailsNames.paymentGoals]: SelectWithOthers,
  [signUpPaymentDetailsNames.paymentProcessor]: CustomRadios,
  [signUpPaymentDetailsNames.paymentProcessors]: SelectWithOthers,
};

const COMPONENTS_PROPS_MAP = {
  [signUpPaymentDetailsNames.paymentModel]: {},
  [signUpPaymentDetailsNames.paymentServiceProvider]: {},
  [signUpPaymentDetailsNames.paymentTransactions]: {},
  [signUpPaymentDetailsNames.paymentGoals]: {},
  [signUpPaymentDetailsNames.paymentProcessor]: '',
  [signUpPaymentDetailsNames.paymentProcessors]: {},
  [signUpPaymentDetailsNames.paymentProcessorsOther]: '',
};

const SignUpPaymentDetailsForm = ({ loading, handleSubmit }) => {
  const { t } = useTranslation();
  const [registrationSession, setRegistrationSession] = useRegistrationStorage();
  const { push } = useHistory();

  // console.log('loading', loading);
  // console.log('registrationSession', registrationSession);

  // console.log(loading);

  const handleBack = () => {
    setRegistrationSession({
      ...registrationSession,
      // [ONBOARDING_STATUS.paymentDetails]: values,
      onboardingStatus: ONBOARDING_STATUS.transactionsAmount,
    });
    push(UI_ROUTES.signUpTransactionsAmount);
    // setRegistrationSession({
    //   ...registrationSession,
    //   // [ONBOARDING_STATUS.paymentDetails]: values,
    //   onboardingStatus: ONBOARDING_STATUS.companyDetails,
    // });
    // push(UI_ROUTES.signUpCompanyDetails);
  };

  // eslint-disable-next-line no-unused-vars
  // const handleSubmit = useCallback(
  //   (values) => {
  //     // console.log('submit');
  //     // console.log(values);
  //     setRegistrationSession({
  //       ...registrationSession,
  //       [ONBOARDING_STATUS.paymentDetails]: values,
  //       onboardingStatus: ONBOARDING_STATUS.paymentDetails,
  //     });
  //     push(UI_ROUTES.signUpPaymentDetails);
  //   },
  //   [setRegistrationSession, registrationSession, push]
  // );
  // const handleSubmit = () => {
  //   console.log('submit');
  // };

  const parsedInitialValues = {
    ...initialValues,
    ...(registrationSession?.[ONBOARDING_STATUS.paymentDetails] ?? {}),
  };

  return (
    <BlockWrap margin="37px 0 0">
      <FieldsWrapper>
        <Formik
          initialValues={parsedInitialValues}
          validate={signUpPaymentDetailsValidator}
          // disabled={loading}
          onSubmit={handleSubmit}
          validateOnBlur
        >
          {({ values }) => (
            <Form>
              {signUpPaymentDetailsFields.map(({ field, type, label, ...rest }, index) => {
                const Component = COMPONENTS_MAP[field] || Input;
                const addCustomMargin = field === signUpPaymentDetailsNames.companySize;
                const { acceptingPayments } = registrationSession[ONBOARDING_STATUS.companyDetails];
                // console.log('acceptingPayments ', acceptingPayments);
                // console.log('values ', values);

                if (acceptingPayments === 'NO') {
                  if (field === signUpPaymentDetailsNames.paymentServiceProvider) {
                    return null;
                  }
                  if (field === signUpPaymentDetailsNames.paymentTransactions) {
                    return null;
                  }
                  if (field === signUpPaymentDetailsNames.paymentGoals) {
                    return null;
                  }
                  if (field === signUpPaymentDetailsNames.paymentProcessors && values.paymentProcessor !== 'YES') {
                    return null;
                  }
                } else if (acceptingPayments === 'YES') {
                  if (field === signUpPaymentDetailsNames.paymentProcessor) {
                    return null;
                  }
                  if (field === signUpPaymentDetailsNames.paymentProcessors) {
                    return null;
                  }
                }

                return (
                  <Component
                    key={field}
                    name={field}
                    {...(isDefined(SELECT_OPTIONS[field]) && {
                      options: SELECT_OPTIONS[field],
                      ...CUSTOM_SELECT_PROPS,
                    })}
                    type={type}
                    label={label}
                    index={index}
                    {...COMPONENTS_PROPS_MAP[field]}
                    {...rest}
                    {...(addCustomMargin && {
                      margin: '12px 0 0 0',
                      errorProps: {
                        margin: '6px 0 14px 0',
                      },
                    })}
                  />
                );
              })}

              <Box mb="24px">
                <AcceptTermsAndCondition name={signUpPaymentDetailsNames.acceptedTC} />

                <AcceptNewsletter name={signUpPaymentDetailsNames.acceptedNewsletter} />
              </Box>

              <Box mt="12px" display="flex">
                <Box mr="8px" display="flex">
                  <Button className="grey" size="small" onClick={() => handleBack()}>
                    {t('Back')}
                  </Button>
                </Box>
                <SubmitButton
                  type="submit"
                  // likeDisabled={loading}
                  showIcon={false}
                  className="gradient"
                  margin="28px 0 0 0"
                  data-cy="submit"
                  // disabled={loading}
                  // isLoading={loading}
                >
                  {t('Complete')}
                </SubmitButton>
              </Box>
            </Form>
          )}
        </Formik>
      </FieldsWrapper>
    </BlockWrap>
  );
};

SignUpPaymentDetailsForm.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

SignUpPaymentDetailsForm.defaultProps = { loading: false };

export default SignUpPaymentDetailsForm;
