import styled, { css, keyframes } from 'styled-components';
import { LinearProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import THEME from '../../../../../../../constants/theme';
import { FlexContainer } from '../../../../../../../components/atoms/flex/FlexContainer';

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to{
      transform: rotate(360deg);
    }
`;

const spinner = css`
  animation: ${rotate} 1s linear infinite;
`;

const StyledDropZoneHoverCSS = css`
  // TODO - Investigate : using variable like ${THEME.primaryColors.purpleMain} doesn't work here
  background-color: #f4f4fe;
  border: 1px dashed #1f25f4;
  cursor: pointer;
`;

const StyledDropZone = styled.div`
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  height: 80px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease-out;
  border: 1px solid #e8e8e8;
  position: relative;

  .disableMessage {
    color: ${THEME.greyColors.grey18};
  }

  :hover {
    ${StyledDropZoneHoverCSS};
  }

  ${({ dragging }) => dragging && `${StyledDropZoneHoverCSS}`};

  .draggingIcon {
    position: absolute;
    bottom: -20px;
    right: 0;
    z-index: 100;
  }

  &.disabled {
    background-color: white;
    border: 1px dashed ${THEME.greyColors.grey200};
    cursor: default;
  }

  &:focus {
    outline: none;
  }
`;

const StyledUploadIndicator = styled.div`
  position: fixed;
  bottom: 20px;
  right: 30px;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  z-index: 9999;
  box-shadow: 0 0 2px 2px rgba(155, 159, 171, 0.11);
  transition: all 0.2s ease-out;
  opacity: ${({ isLoading }) => (isLoading ? '1' : '0')};
  transform: ${({ isLoading }) => (isLoading ? 'translateX(0)' : 'translateX(400px)')};
  > :not(:first-child, :last-child) {
    margin-bottom: 8px;
  }
`;

const StyledProgress = withStyles({
  root: {
    backgroundColor: 'rgba(105,68,255,0.2)',
    borderRadius: '4px',
    marginTop: '4px',
  },
  bar: {
    backgroundColor: THEME.primaryColors.primary,
  },
})(LinearProgress);

const StyledSpinner = styled.div`
  width: 20px;
  height: 20px;
  color: #fff;
  box-sizing: border-box;
  padding: 2px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  transition: background-color 0.2s linear;
  ${({ isLoading }) => !isLoading && 'background-color: #1cce6a'};
  img {
    width: 100%;
    ${spinner};
  }
`;

const StyledAttachmentWrapper = styled(FlexContainer)`
  width: 100%;
  min-height: 32px;
  margin-bottom: 8px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid ${THEME.greyColors.grey200};
  }

  svg {
    &:hover {
      cursor: pointer;

      path {
        fill: ${THEME.secondaryColors.secondary};
      }
    }
  }
`;

const StyledDownloadLink = styled.a`
  margin-right: 12px;

  svg {
    &:hover {
      cursor: pointer;
    }
  }
`;

export {
  StyledDropZone,
  StyledUploadIndicator,
  StyledProgress,
  StyledSpinner,
  StyledAttachmentWrapper,
  StyledDownloadLink,
};
