import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { FormControl, FormHelperText } from '@mui/material';
import THEME from '../../../../../../constants/theme';
import Title from '../../Title';
import { useValidationMessage } from './hooks';
import { SearchBar } from '../../../../../atoms';
import { useNodeDataToSave } from './hooks/useNodeDataToSave';
import MultiSelectSection from '../sections/MultiSelectSection';
import { useFlowEditorContext } from '../../../../context';
import useGetConnections from '../hooks/useGetConnections';
import AddNewConnectionButton from '../../../AddNewConnectionButton';
import { useStyles } from './styled';

const ConnectionsField = () => {
  const { t } = useTranslation();
  // do not destructure nodeDataToSave
  const [nodeDataToSave, updateDataToSave] = useNodeDataToSave();
  const { topFlowId } = useFlowEditorContext();
  const { connected, loading } = useGetConnections({ topFlowId });
  const [search, setSearch] = useState('');
  const classes = useStyles();

  const onChangeConnection = (_connectionIds) => {
    updateDataToSave({ connectionIds: _connectionIds, connectionType: null });
  };

  const connectionOptions = useMemo(() => {
    return connected?.map(({ id, name, company }) => ({
      value: id,
      title: name ?? company?.name,
      company,
    }));
  }, [connected]);

  const validationMessage = useValidationMessage('connectionIds', true);
  const hasError = Boolean(validationMessage);
  const showError = hasError && nodeDataToSave?.changed;
  const border = `1px solid ${showError ? THEME.secondaryColors.nodeError : THEME.greyColors.grey200}`;

  return (
    <Box component="section">
      <Title>{t('Connections')}</Title>
      {loading || connectionOptions?.length ? (
        <FormControl variant="standard" fullWidth error={showError}>
          <Box border={border} borderRadius="4px">
            <Box borderBottom={`1px solid ${THEME.greyColors.grey12}`}>
              <SearchBar search={search} setSearch={setSearch} bgcolor="transparent" noIcon paddingX="4px" />
            </Box>
            <MultiSelectSection
              options={connectionOptions}
              selectedOptionIds={nodeDataToSave?.connectionIds}
              validationMessage={validationMessage}
              handleChange={onChangeConnection}
              loading={loading}
              selectType="connections"
              showEmptyState
              selectAll
            />
          </Box>
          {showError && <FormHelperText classes={{ root: classes.root }}>{validationMessage}</FormHelperText>}
        </FormControl>
      ) : (
        <AddNewConnectionButton
          connectionTypes={['PAYMENT_GATEWAY']}
          btnHeight="48px"
          mr="10px"
          ml="10px"
          iconSize="24px"
          fontSize="12px"
          border={`1px solid ${THEME.primaryColors.primaryLight}`}
          borderRadius="8px"
          mt="8px"
        />
      )}
    </Box>
  );
};

export default ConnectionsField;
