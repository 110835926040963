import styled, { css } from 'styled-components';
import { TextField } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

export const StyledWrapper = styled.div`
  margin-top: -20px;
  padding-bottom: 32px;
  min-height: 100%;
  ${({ $isEmpty }) =>
    $isEmpty &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

export const StyledTimeInput = styled(TextField)`
  flex: 1;
  & .MuiInputBase-root {
    justify-content: center;
  }
  & .MuiInput-input {
    font-size: 14px;
    width: ${({ $width }) => $width};
    color: ${({ $color }) => $color};
  }
  & .MuiInputAdornment-root {
    margin-left: 4px;
    p {
      font-size: 14px;
      color: ${({ $color }) => $color};
    }
  }
  & .MuiInput-root {
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin: 0;
    align-items: flex-start;
    & .MuiCheckbox-root {
      padding: 0;
      margin: 2px 8px 0 0;
    }
  }
`;
