import React from 'react';
import { useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next/src/index';
import { TOAST_TIMEOUT } from '../../../../../../../constants/toasts';
import { GET_CONNECTION_DETAILS } from './get-connection-query';

import { StyledTableCell } from '../../../../../../../components/table/Cells/styled';
import { useNotificationManager } from '../../../../../../../hooks/useNotificationManager';
import { PROPTYPES } from '../../../../../../../components/table/Cells/constant';
import Processor from '../../../../../../../components/table/Processor';
import LoadingState from './LoadingState';
import { parseSmartId } from '../../../../../../../utils/parseSmartId';

const ConnectionCell = ({ row, cell }) => {
  const header = cell?.column?.Header;
  const genericId = row?.original?.[header];

  const id = parseSmartId(genericId)[1] || genericId;

  const { t } = useTranslation();
  const { loading, error, data } = useQuery(GET_CONNECTION_DETAILS, { variables: { id }, skip: !id });
  useNotificationManager('error', error?.message, t('Card details'), TOAST_TIMEOUT);

  const connectionName = data?.getConnection?.name || data?.getConnection?.company?.name;
  const connectionLogoUrl = data?.getConnection?.company?.logo;

  return (
    <StyledTableCell customWidth={70}>
      {loading && <LoadingState />}

      {!loading && !isEmpty(data) && <Processor name={connectionName} logo={connectionLogoUrl} />}
    </StyledTableCell>
  );
};
ConnectionCell.propTypes = PROPTYPES;
export default ConnectionCell;
