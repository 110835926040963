import React from 'react';
import { useMutation } from '@apollo/client';
import { NotificationManager } from 'react-notifications';
import isEmpty from 'lodash/isEmpty';

import { GQL_ADD_PROCESSOR_TO_FLOW } from '../../../../utils/queries/flows/flowSettings/mutations';
import { usePaymentFlowContext } from '../../paymentFlowContext';
import { isDefined } from '../../../../utils/helpers';
import { GET_PAYMENT_FLOW_FOR_GATEWAYS } from '../../../../utils/queries/flows/flowsQueries';
import { GET_FLOW, GET_FLOW_STEP_LIBRARY } from '../../../../utils/queries/flows/queries';
import { GET_CONNECTIONS } from '../../../../utils/queries/connections/connectionsQueries';
import { getOperationNameForQuery } from '../../../../utils/graphql';
import ToastCustomContainer from '../../../../components/ToastCustomContainer';

const TOAST_TIMEOUT = 5000;
const TOAST_TITLE = 'Flow Settings.';
const CONNECTION_ID_PREFIX = 'connection-provider:';

const getConnections = getOperationNameForQuery(GET_CONNECTIONS);
const flowStepLibrary = getOperationNameForQuery(GET_FLOW_STEP_LIBRARY);
const paymentFlowForGateways = getOperationNameForQuery(GET_PAYMENT_FLOW_FOR_GATEWAYS);
const getFlow = getOperationNameForQuery(GET_FLOW);

const useAddProcessor = (refetchQueries = []) => {
  const [addToFlow, { loading }] = useMutation(GQL_ADD_PROCESSOR_TO_FLOW, {
    refetchQueries,
    context: {
      skipGlobalHandling: true,
    },
  });

  const prepareParamsAndRun = async (params, flowId) => {
    const id = typeof params === 'string' ? params : params?.currentTarget?.dataset?.id;
    const parsedId = id.includes(CONNECTION_ID_PREFIX) ? id : `${CONNECTION_ID_PREFIX}${id}`;
    const res = await addToFlow({
      variables: {
        flowId,
        gatewayConnectionId: parsedId,
      },
    });

    if (!isEmpty(res?.errors)) {
      NotificationManager.error(
        <ToastCustomContainer message={res?.errors[0]?.message} title={TOAST_TITLE} />,
        null,
        TOAST_TIMEOUT
      );

      return null;
    }
    return res;
  };

  return {
    prepareParamsAndRun,
    loading,
  };
};

const useHandleNoEditorAddConnection = ({ flowId, closeModal }) => {
  const refetchQueries = [getConnections, paymentFlowForGateways];
  const { prepareParamsAndRun, loading } = useAddProcessor(refetchQueries);

  const handleAddToFlow = async (params) => {
    /**
     * id - scenarios
     * 1 - if id is passed directly <string> - use the Id
     * 2 - If it's not a string - definitely triggered by a button so get Id from event object
     *
     * parseID - scenarios
     * id from connect mutation doesn't have CONNECTION_ID_PREFIX
     * add the prefix if it not currently included
     */

    params?.stopPropagation?.();
    try {
      closeModal(params, true);
      await prepareParamsAndRun(params, flowId);
    } catch (e) {
      NotificationManager.error(
        <ToastCustomContainer message="uiMessages.error" title={TOAST_TITLE} />,
        null,
        TOAST_TIMEOUT
      );
    }
  };

  return { handleAddToFlow, loading };
};

const useHandleAddToFlow = () => {
  const { flow, setSaving, setFlow, refetch, refetchLoading } = usePaymentFlowContext();
  const refetchQueries = [getConnections, flowStepLibrary, paymentFlowForGateways, getFlow];
  const { prepareParamsAndRun, loading: _loading } = useAddProcessor(refetchQueries);
  const loading = _loading || refetchLoading;

  const handleAddToFlow = async (params) => {
    /**
     * id - scenarios
     * 1 - if id is passed directly <string> - use the Id
     * 2 - If it's not a string - definitely triggered by a button so get Id from event object
     *
     * parseID - scenarios
     * id from connect mutation doesn't have CONNECTION_ID_PREFIX
     * add the prefix if it not currently included
     */

    params?.stopPropagation?.();
    setSaving(true);

    try {
      const res = await prepareParamsAndRun(params, flow?.id);

      if (isDefined(res?.data?.enableGatewayForPaymentFlow?.id)) {
        const successMessage = `Successfully added payment processor to ${flow?.name}`;

        const refetchData = await refetch();

        if (!isEmpty(refetchData?.data?.getPaymentFlow)) {
          setFlow(refetchData?.data?.getPaymentFlow);
        }
        NotificationManager.success(
          <ToastCustomContainer message={successMessage} title={TOAST_TITLE} />,
          null,
          TOAST_TIMEOUT
        );

        return;
      }

      NotificationManager.error(
        <ToastCustomContainer message="uiMessages.error" title={TOAST_TITLE} />,
        null,
        TOAST_TIMEOUT
      );
    } catch (e) {
      NotificationManager.error(
        <ToastCustomContainer message="uiMessages.error" title={TOAST_TITLE} />,
        null,
        TOAST_TIMEOUT
      );
    } finally {
      setSaving(false);
    }
  };

  return { handleAddToFlow, loading };
};

export { useHandleAddToFlow, useHandleNoEditorAddConnection, TOAST_TIMEOUT, TOAST_TITLE };
