import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import THEME from '../../constants/theme';

const StyledRoundedTextField = styled(TextField)`
  & .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${THEME.primaryColors.primary} !important;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-radius: 8px !important;
    border: ${({ $borderColor }) => `1px solid ${$borderColor ?? THEME.primaryColors.purpleLight} !important`};
  }
  & .MuiInputBase-input {
    padding: 11.5px 45px 11.5px 14px !important;
  }
`;

StyledRoundedTextField.defaultProps = {
  variant: 'outlined',
  size: 'small',
};

export { StyledRoundedTextField };
