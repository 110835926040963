import { useEffect, useState } from 'react';
import omit from 'lodash/omit';
import { useDebounce } from 'react-use';
import isEmpty from 'lodash/isEmpty';

import useSearch from '../../../../../../hooks/useSearch';

/**
 * @param {string} [key=search]
 *
 * @return {[string, function]}
 * */
const useSearchInput = (key = 'search', searchParam) => {
  const [searchParams, setSearchParams] = useSearch();
  const search = searchParams?.[searchParam]?.[key] ?? '';
  const [value, setValue] = useState('');

  useDebounce(
    () => {
      setSearchParams((prevSearchParams) => {
        if (isEmpty(value)) {
          return { ...prevSearchParams, [searchParam]: omit(prevSearchParams?.[searchParam], [key]) };
        }

        return { ...prevSearchParams, [searchParam]: { ...prevSearchParams?.[searchParam], [key]: value } };
      });
    },
    500,
    [value]
  );

  useEffect(() => {
    setValue(search);
  }, [search]);

  return [value, setValue];
};

export { useSearchInput };
