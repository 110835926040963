import React from 'react';
import { typeHandler } from '../../../../utils/fieldsConsts';
import { CustomDivider } from '../../../atoms';
import Text from '../components/Inputs/Text';
import Section from '../components/Inputs/Section';
import CopyField from '../components/Inputs/CopyField';
import Select from '../components/Inputs/Select';
import LongText from '../components/Inputs/LongText';
import Number from '../components/Inputs/Number';
import Amount from '../components/Inputs/Amount';
import Date from '../components/Inputs/Date';
import ExternalSelectWrapper from '../components/Inputs/ExternalSelectWrapper';
import Checkboxes from '../components/Inputs/Checkboxes';
import Url from '../components/Inputs/Url';

const useGenerateBlock = () => {
  const generateBlockFn = (item) => {
    // NOTE: "blocksList" was created about 2 years ago
    // and now looks like we're not using most of these input types except SECTION, TEXT and COPY_FIELD
    // we can clean up further on these deprecated components once fully confirmed.

    const blocksList = {
      SECTION: Section,
      CHECKBOXES: Checkboxes,
      DATE: Date,
      EXTERNAL_SELECT: ExternalSelectWrapper,
      LONG_TEXT: LongText,
      SELECT: Select,
      TEXT: Text,
      COPY_FIELD: CopyField,
      NUMBER: Number,
      AMOUNT: Amount,
      URL: Url,
      plain_text: () => <div>item.text</div>,
      divider: () => <CustomDivider fullWidth />,
    };

    const fieldType = item.element ? typeHandler(item) : item.type.toUpperCase();

    return blocksList[fieldType];
  };

  return { generateBlockFn };
};

export default useGenerateBlock;
