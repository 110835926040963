import ReactFlow, { ReactFlowProvider } from 'react-flow-renderer';
import React, { useRef } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { string } from 'prop-types';
import { nodeTypesMap } from '../FlowEditor/utils/nodeTypesMap';
import { edgeTypes } from '../FlowEditor/utils/edgeTypes';
import { FlowEditorContextProvider } from '../FlowEditor/context';
import { CanvasContainer } from '../FlowEditor/styled';
import { FlowHeader } from '../FlowHeader';
import { CircularLoader } from '../atoms';
import { useFlowMonitor } from './hooks/useFlowMonitor';
import FlowMonitorDetailsDrawer from './FlowMonitorDetailsDrawer';
import FlowEmptyState from '../FlowEmptyState/FlowEmptyState';
import RetryConfirmDialog from '../FlowEditor/components/Node/RetryNode/RetryConfirmDialog';

const FlowMonitor = ({ flowId }) => {
  const reactFlowWrapper = useRef(null);
  const {
    context,
    setSelectedNodeId,
    onValidate,
    isDataReady,
    reactFlowNodes,
    reactFlowEdges,
    isOpenFlowMonitorDetails,
    isOpenRetryDialog,
    setIsOpenRetryDialog,
  } = useFlowMonitor({
    flowId,
  });

  const handleClick = (_, element) => {
    setSelectedNodeId(element.id);
  };

  if (!reactFlowNodes) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        bgcolor="#f5f6f7"
      >
        {isDataReady ? <CircularProgress /> : <FlowEmptyState />}
      </Box>
    );
  }

  return (
    <Box width="100%" height="100%" bgcolor="#f5f6f7">
      <FlowEditorContextProvider value={{ ...context, flowId }}>
        <ReactFlowProvider>
          <CanvasContainer ref={reactFlowWrapper}>
            <FlowHeader onValidate={onValidate} />
            {!isDataReady && (
              <Box position="absolute" left="50%" top="45%">
                <CircularLoader />
              </Box>
            )}
            <ReactFlow
              defaultNodes={reactFlowNodes}
              defaultEdges={reactFlowEdges}
              nodeTypes={nodeTypesMap}
              edgeTypes={edgeTypes}
              nodesDraggable={false}
              nodesConnectable={false}
              minZoom={0.5}
              maxZoom={2}
              onNodeClick={handleClick}
            />
          </CanvasContainer>
        </ReactFlowProvider>
        {isOpenFlowMonitorDetails && <FlowMonitorDetailsDrawer />}
        {isOpenRetryDialog && <RetryConfirmDialog open={isOpenRetryDialog} setOpen={setIsOpenRetryDialog} />}
      </FlowEditorContextProvider>
    </Box>
  );
};

FlowMonitor.propTypes = {
  flowId: string.isRequired,
};

export { FlowMonitor };
