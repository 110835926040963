import React from 'react';

const Dankort = () => {
  return (
    <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" fill="white" stroke="#E8E8E8" />
      <path
        d="M11.8355 4.15662C7.5998 4.15662 4.15674 7.59968 4.15674 11.8291C4.15674 16.0585 7.5998 19.5047 11.8355 19.5047H22.171C26.4004 19.5047 29.8497 16.0617 29.8497 11.826C29.8497 7.59655 26.4066 4.14722 22.171 4.14722H11.8355V4.15662Z"
        fill="white"
      />
      <path
        d="M22.174 19.6645H11.8354C7.51825 19.6645 4 16.1525 4 11.8291C4 7.51199 7.51199 4 11.8354 4H22.1709C26.488 4 30 7.51199 30 11.8291C30.0031 16.1494 26.4911 19.6645 22.174 19.6645ZM11.8354 4.15665C7.59971 4.15665 4.15665 7.59971 4.15665 11.8291C4.15665 16.0586 7.59971 19.5048 11.8354 19.5048H22.1709C26.4003 19.5048 29.8496 16.0617 29.8496 11.826C29.8496 7.59658 26.4066 4.14725 22.1709 4.14725H11.8354V4.15665Z"
        fill="black"
      />
      <path
        d="M23.9785 11.3623L27.4153 15.4539C28.1296 14.4263 28.5432 13.1794 28.5432 11.8322C28.5432 10.4162 28.0795 9.10348 27.29 8.04456L23.9785 11.3623Z"
        fill="#E31F28"
      />
      <path
        d="M14.2352 7.69372C16.801 7.69372 18.7372 8.3485 18.9377 10.5102L21.6382 7.69372H27.0206C25.8457 6.32151 24.1132 5.45056 22.174 5.45056H11.8354C9.89611 5.45056 8.15421 6.32151 6.98877 7.69372C6.98877 7.69372 14.2352 7.69372 14.2352 7.69372Z"
        fill="#E31F28"
      />
      <path
        d="M10.009 10.4225L9.09106 12.9413H12.791C13.8813 12.9413 14.2165 12.4871 14.4609 11.6161C14.7052 10.7514 14.0943 10.4225 13.3487 10.4225H10.009Z"
        fill="#E31F28"
      />
      <path
        d="M21.6633 15.7953L18.8061 12.1862C18.2767 14.6486 16.5473 15.7953 13.3047 15.7953H6.84155C8.01013 17.2646 9.81468 18.2045 11.8354 18.2045H22.1709C24.1916 18.2045 25.9899 17.2583 27.1585 15.7953H21.6633Z"
        fill="#E31F28"
      />
    </svg>
  );
};

export default Dankort;
