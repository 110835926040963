import React from 'react';
import styled from 'styled-components';
import { func, string } from 'prop-types';
import THEME from '../../../../../constants/theme';
import { stopPropagation } from './helpers';

const Wrapper = styled.div`
  flex: 1;
  display: inline-grid;
  align-items: stretch;
  padding: 8px;
  border-radius: 4px;
`;

const Input = styled.textarea`
  grid-area: 1 / 1 / 2 / 2;
  width: 100%;
  max-width: 240px;
  padding: 0;
  border: none;
  resize: none;
  background: none;
  appearance: none;
  outline: none;
  &::placeholder {
    color: ${THEME.greyColors.grey19};
  }
`;

const HiddenSpan = styled.span`
  grid-area: 1 / 1 / 2 / 2;
  max-width: 240px;
  visibility: hidden;
  white-space: pre-wrap;
`;

const GrowingInput = ({ value, onChange, placeholder }) => (
  <Wrapper className="nowheel nodrag">
    <Input
      rows="1"
      value={value}
      onMouseDown={stopPropagation}
      onChange={(event) => onChange(event.target.value)}
      placeholder={placeholder}
    />
    <HiddenSpan>{value} </HiddenSpan>
  </Wrapper>
);

GrowingInput.propTypes = {
  value: string,
  onChange: func.isRequired,
  placeholder: string,
};

GrowingInput.defaultProps = {
  value: '',
  placeholder: '',
};

export default GrowingInput;
