import React from 'react';
import { bool } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useFlowEditorContext } from '../../../context';
import CheckoutFormStep from './CheckoutFormStep';
import ConnectionEventTriggerStep from './ConnectionEventTriggerStep';
import DataActionStep from './DataActionStep';
import DataConditionStep from './DataConditionStep';
import DataConnectionActionStep from './DataConnectionActionStep';
import DataEventTriggerStep from './DataEventTriggerStep';
import DataExportStep from './DataExportStep';
import DataLookupStep from './DataLookupStep';
import DelayActionStep from './DelayActionStep';
import FixedConnectionActionStep from './FixedConnectionActionStep';
import IfElseStep from './IfElseStep';
import PaymentProcessingTrigger from './PaymentProcessingTrigger';
import ScheduledTriggerStep from './ScheduledTriggerStep';
import { StyledWrapper } from './styled';
import UndefinedStep from './UndefinedStep';
import UserActionStep from './UserActionStep';
import WebhookActionStep from './WebhookActionStep';
import WebhookTriggerStep from './WebhookTriggerStep';
import NodeHelper from './fields/NodeHelper';
import ThreeDsStep from './ThreeDsStep';
import CountConditionStep from './CountConditionStep';
import ABTestConditionStep from './ABTestConditionStep';
import EmptyState from './EmptyState';
import NodeIssues from './fields/NodeIssues';
import { flowStepTypes } from '../../../types';

const AB_TEST_CONDITION = 'ABTestConditionStep';

const FLOW_STEPS = {
  // triggers
  ConnectionEventTriggerStep,
  DataEventTriggerStep,
  ScheduledTriggerStep,
  WebhookTriggerStep,
  PaymentProcessingTrigger,
  // actions
  DataExportStep,
  DataLookupStep,
  DataActionStep,
  FixedConnectionActionStep,
  UserActionStep,
  WebhookActionStep,
  DelayActionStep,
  CheckoutFormStep,
  ThreeDsStep,
  // conditions
  IfElseStep,
  DataConditionStep,
  CountConditionStep,
  ABTestConditionStep,
  // services
  DataConnectionActionStep,
};

const AutomationStep = ({ isABTestConfigured }) => {
  const { selectedNodeData } = useFlowEditorContext();
  if (!selectedNodeData) {
    return null;
  }
  const { conditions } = selectedNodeData;
  const isEmptyCondition = selectedNodeData.group?.toLowerCase() === 'conditions' && isEmpty(conditions);
  const isABTest = selectedNodeData.__typename === AB_TEST_CONDITION;
  const isValidationErrors = !isEmpty(selectedNodeData?.validationErrors);

  const isShowNodeIssues =
    (isValidationErrors && !isABTestConfigured && !isEmptyCondition) ||
    (isValidationErrors &&
      !isABTestConfigured &&
      isABTest &&
      (selectedNodeData?.changed || selectedNodeData?.__typename === flowStepTypes.DataConnectionActionStep));

  const SelectedStep = FLOW_STEPS[selectedNodeData.__typename] || UndefinedStep;

  return (
    <StyledWrapper $isEmpty={isEmptyCondition && !isABTest}>
      {isShowNodeIssues ? <NodeIssues /> : null}
      {!isABTestConfigured && !isEmptyCondition && <NodeHelper />}
      {isEmptyCondition && !isABTest ? <EmptyState /> : <SelectedStep isABTestConfigured={isABTestConfigured} />}
    </StyledWrapper>
  );
};

AutomationStep.propTypes = {
  isABTestConfigured: bool,
};

AutomationStep.defaultProps = {
  isABTestConfigured: false,
};

export default AutomationStep;
