import styled from 'styled-components';
import THEME from '../../../constants/theme';

const StyledWrapper = styled.div`
  padding: 0 0 24px 32px;

  @media (min-width: ${THEME.breakPoints.desktop}px) {
    padding-left: 52px;
  }
`;

export { StyledWrapper };
