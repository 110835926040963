import React from 'react';

const Phone = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 3H14M8 22H16C17.1046 22 18 21.1046 18 20V4C18 2.89543 17.1046 2 16 2H8C6.89543 2 6 2.89543 6 4V20C6 21.1046 6.89543 22 8 22Z"
        stroke="#787F88"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Phone;
