import { AUTOMATION_PERMISSIONS } from '../pages/MVPAutomation/permissions';
import { SETTINGS_PERMISSIONS } from '../pages/Settings/permissions';
import { GETTING_STARTED_PERMISSIONS } from '../pages/GettingStarted/permissions';
import { CUSTOMER_SUPPORT_PERMISSIONS } from '../pages/CustomerSupport/permissions';

const APPLICATION_PERMISSIONS = [
  ...SETTINGS_PERMISSIONS,
  ...AUTOMATION_PERMISSIONS,
  ...GETTING_STARTED_PERMISSIONS,
  ...CUSTOMER_SUPPORT_PERMISSIONS,
];

export { APPLICATION_PERMISSIONS };
