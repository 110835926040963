import React, { useCallback } from 'react';
import { Form, Formik } from 'formik';
import { bool } from 'prop-types';
import { useHistory } from 'react-router-dom';

import { BlockWrap } from '../../atoms';
import {
  signUpAcceptingPaymentsFields,
  signUpAcceptingPaymentsNames,
  initialValues,
} from './signUpAcceptingPaymentsSchema';
import { FieldsWrapper } from '../formStyles';
import Input from '../_common/Input';
import { SELECT_OPTIONS, CUSTOM_SELECT_PROPS } from './consts';
import { isDefined } from '../../../utils/helpers';
import { useRegistrationStorage } from '../../../hooks/registration';
import { ONBOARDING_STATUS, ONBOARDING_STATUS_WITH_ROUTES } from '../../../constants/registration';
import { UI_ROUTES } from '../../../constants/routes';
import signUpAcceptingPaymentsValidator from './signUpAcceptingPaymentsValidator';
import CustomRadios from '../_common/CustomRadios';
import SignUpFooter from '../SignUpPersonalDetailsForm/SignUpFooter';

const COMPONENTS_MAP = {
  [signUpAcceptingPaymentsNames.acceptingPayments]: CustomRadios,
};

const COMPONENTS_PROPS_MAP = {
  [signUpAcceptingPaymentsNames.acceptingPayments]: '',
};

const SignUpAcceptingPaymentsForm = () => {
  const [registrationSession, setRegistrationSession] = useRegistrationStorage();
  const { push } = useHistory();

  const handleSubmit = useCallback(
    (values) => {
      setRegistrationSession({
        ...registrationSession,
        [ONBOARDING_STATUS.companyDetails]: values,
        onboardingStatus:
          values.acceptingPayments === 'YES' ? ONBOARDING_STATUS.paymentProcessors : ONBOARDING_STATUS.paymentProcessor,
      });
      push(values.acceptingPayments === 'YES' ? UI_ROUTES.signUpPaymentProcessors : UI_ROUTES.signUpPaymentProcessor);
    },
    [setRegistrationSession, registrationSession, push]
  );

  const parsedInitialValues = {
    ...initialValues,
    ...(registrationSession?.[ONBOARDING_STATUS.companyDetails] ?? {}),
  };

  return (
    <BlockWrap margin="37px 0 0">
      <FieldsWrapper>
        <Formik
          initialValues={parsedInitialValues}
          validate={signUpAcceptingPaymentsValidator}
          onSubmit={handleSubmit}
          validateOnBlur
        >
          <Form>
            {signUpAcceptingPaymentsFields.map(({ field, type, label, ...rest }, index) => {
              const Component = COMPONENTS_MAP[field] || Input;
              const addCustomMargin = field === signUpAcceptingPaymentsNames.companySize;

              return (
                <Component
                  key={field}
                  name={field}
                  {...(isDefined(SELECT_OPTIONS[field]) && {
                    options: SELECT_OPTIONS[field],
                    ...CUSTOM_SELECT_PROPS,
                  })}
                  type={type}
                  label={label}
                  index={index}
                  {...COMPONENTS_PROPS_MAP[field]}
                  {...rest}
                  {...(addCustomMargin && {
                    errorProps: {
                      margin: '6px 0 14px 0',
                    },
                  })}
                />
              );
            })}

            <SignUpFooter
              primaryButtonText="common.continueText"
              previousRoute={ONBOARDING_STATUS_WITH_ROUTES.companyDetails}
            />
          </Form>
        </Formik>
      </FieldsWrapper>
    </BlockWrap>
  );
};

SignUpAcceptingPaymentsForm.propTypes = { loading: bool };

SignUpAcceptingPaymentsForm.defaultProps = { loading: false };

export default SignUpAcceptingPaymentsForm;
