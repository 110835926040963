import React from 'react';

const Finance = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 22C0 9.84974 9.84974 0 22 0V0C34.1503 0 44 9.84974 44 22V22C44 34.1503 34.1503 44 22 44V44C9.84974 44 0 34.1503 0 22V22Z"
        fill="#5350FA"
      />
      <g clipPath="url(#clip0_1868_13642)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.75 32.75C27.0761 32.75 28.3479 32.2232 29.2855 31.2855C30.2232 30.3479 30.75 29.0761 30.75 27.75C30.75 26.4239 30.2232 25.1521 29.2855 24.2145C28.3479 23.2768 27.0761 22.75 25.75 22.75C24.4239 22.75 23.1521 23.2768 22.2145 24.2145C21.2768 25.1521 20.75 26.4239 20.75 27.75C20.75 29.0761 21.2768 30.3479 22.2145 31.2855C23.1521 32.2232 24.4239 32.75 25.75 32.75ZM32 27.75C32 29.4076 31.3415 30.9973 30.1694 32.1694C28.9973 33.3415 27.4076 34 25.75 34C24.0924 34 22.5027 33.3415 21.3306 32.1694C20.1585 30.9973 19.5 29.4076 19.5 27.75C19.5 26.0924 20.1585 24.5027 21.3306 23.3306C22.5027 22.1585 24.0924 21.5 25.75 21.5C27.4076 21.5 28.9973 22.1585 30.1694 23.3306C31.3415 24.5027 32 26.0924 32 27.75Z"
          fill="white"
        />
        <path
          d="M23.7974 28.93C23.8561 29.675 24.4449 30.255 25.5011 30.325V30.875H25.9699V30.3212C27.0636 30.245 27.7024 29.66 27.7024 28.8125C27.7024 28.04 27.2149 27.6425 26.3399 27.4375L25.9699 27.35V25.85C26.4399 25.9037 26.7374 26.16 26.8086 26.515H27.6311C27.5724 25.7963 26.9561 25.235 25.9699 25.1738V24.625H25.5011V25.1875C24.5674 25.2787 23.9324 25.84 23.9324 26.635C23.9324 27.3375 24.4049 27.785 25.1911 27.9675L25.5011 28.0437V29.6337C25.0211 29.5612 24.7024 29.2963 24.6311 28.93H23.7961H23.7974ZM25.4974 27.2375C25.0361 27.1313 24.7861 26.9125 24.7861 26.585C24.7861 26.2175 25.0561 25.9425 25.5011 25.8625V27.2375H25.4974ZM26.0374 28.17C26.5986 28.3 26.8561 28.51 26.8561 28.8812C26.8561 29.305 26.5349 29.595 25.9699 29.6488V28.155L26.0374 28.17Z"
          fill="white"
        />
        <path
          d="M13.25 14C12.9185 14 12.6005 14.1317 12.3661 14.3661C12.1317 14.6005 12 14.9185 12 15.25V25.25C12 25.5815 12.1317 25.8995 12.3661 26.1339C12.6005 26.3683 12.9185 26.5 13.25 26.5H18.3538C18.4263 26.07 18.535 25.6525 18.6762 25.25H15.75C15.75 24.587 15.4866 23.9511 15.0178 23.4822C14.5489 23.0134 13.913 22.75 13.25 22.75V17.75C13.913 17.75 14.5489 17.4866 15.0178 17.0178C15.4866 16.5489 15.75 15.913 15.75 15.25H28.25C28.25 15.913 28.5134 16.5489 28.9822 17.0178C29.4511 17.4866 30.087 17.75 30.75 17.75V22.16C31.225 22.585 31.6462 23.07 32 23.6025V15.25C32 14.9185 31.8683 14.6005 31.6339 14.3661C31.3995 14.1317 31.0815 14 30.75 14H13.25Z"
          fill="white"
        />
        <path
          d="M24.4975 20.3537L24.5 20.25C24.4997 19.8195 24.3883 19.3963 24.1765 19.0215C23.9647 18.6467 23.6597 18.3329 23.291 18.1106C22.9224 17.8883 22.5026 17.7649 22.0722 17.7525C21.6419 17.7401 21.2156 17.839 20.8348 18.0396C20.4539 18.2403 20.1313 18.5359 19.8982 18.8979C19.6651 19.2598 19.5294 19.6758 19.5043 20.1056C19.4791 20.5354 19.5654 20.9643 19.7548 21.351C19.9441 21.7376 20.2301 22.0688 20.585 22.3125C21.6647 21.2841 23.0272 20.6019 24.4975 20.3537Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1868_13642">
          <rect width="20" height="20" fill="white" transform="translate(12 14)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Finance;
