import React, { useEffect, useMemo } from 'react';
import { string, number, arrayOf, oneOf, shape, func, bool } from 'prop-types';
import Close from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import { TestBox, InputBox, SelectBox, DeleteBox, StyledOperatorControl, WrapperBox, LogicalOpBox } from './styled';
import { ComparisonOperator, initialComparisonOperator, LogicalOperator } from '../constants';
import { operationOptions, rightOperandShownArray, showDefaultRightOperand } from './constants';
import Select from '../../../../../../forms/_common/Select';
import InputByType, { TYPES } from '../InputByType';
import LoadingState from '../../../LoadingState';
import useValidOperatorsFor from '../../../../../hooks/useGetValidOperatorsFor';
import { useFlowPropertiesList } from '../hooks';
import TextEditorInput from '../InputByType/TextEditorInput';
import { useNodeDataToSave } from '../hooks/useNodeDataToSave';
import { P12M } from '../../../../../../atoms/Typography/P12M';
import THEME from '../../../../../../../constants/theme';
import { flowStepTypes } from '../../../../../types';

const Test = ({ test, tests, logicalOperator, updateTest, removeTest, index, hasMoreThanOneCondition }) => {
  const { t } = useTranslation();
  const { id, left: leftOperand, op: operation, right: rightOperand } = test;
  const [{ __typename }] = useNodeDataToSave();
  const { loading, getAvailableProperties: flatProperties } = useFlowPropertiesList([], 'no-cache');
  const isShowLogicalOp = index + 1 < tests?.length;
  const isRulesStep = __typename === flowStepTypes.IfElseStep;
  const propertyType = useMemo(
    () => flatProperties?.items?.find((property) => property?.key === leftOperand)?.dataType,
    [flatProperties, leftOperand]
  );
  const { validOperatorsOptions } = useValidOperatorsFor({ type: propertyType });
  const operatorSelectOptions = useMemo(
    () => (validOperatorsOptions?.length ? validOperatorsOptions : operationOptions),
    [validOperatorsOptions]
  );
  const operatorSelectValue = useMemo(() => {
    const selectedOption = operatorSelectOptions.find((selectOption) => selectOption.value === operation);
    if (selectedOption) {
      return operation;
    }
    return operatorSelectOptions[0]?.value || initialComparisonOperator;
  }, [operation, operatorSelectOptions]);

  const inputType = useMemo(() => {
    if (operation === ComparisonOperator.IN_LIST) {
      return TYPES.IN_LIST_INPUT;
    }
    if (rightOperandShownArray.includes(operation)) {
      return undefined;
    }
    if (!showDefaultRightOperand.includes(operation)) {
      return TYPES.TEXT_EDITOR_INPUT;
    }
    if (!Array.isArray(flatProperties?.items)) {
      return undefined;
    }
    return propertyType?.includes('ENUM_') || propertyType === 'USER_ACTION' || propertyType === 'BOOLEAN'
      ? TYPES.ENUM_SELECT_INPUT
      : TYPES.TEXT_EDITOR_INPUT;
  }, [operation, flatProperties, propertyType]);

  const operatorSelect = useMemo(
    () => ({
      name: 'op',
      value: operatorSelectValue,
      options: operatorSelectOptions,
    }),
    [operatorSelectOptions, operatorSelectValue]
  );

  const isRange =
    operatorSelect?.value === ComparisonOperator.BETWEEN_INCLUSIVE ||
    operatorSelect?.value === ComparisonOperator.BETWEEN_EXCLUSIVE;

  const handleChange = ({ target: { name, value, leftLabel, rightLabel } }, type) => {
    if (!isRange || !type || name === operatorSelect.name) {
      updateTest({ id, name, value, leftLabel, rightLabel });
    } else {
      updateTest({
        id,
        name,
        value: { ...rightOperand, [type]: value },
        leftLabel,
        rightLabel,
      });
    }
  };

  useEffect(() => {
    updateTest({ id, name: 'op', value: operatorSelectValue });
    // do not change dependencies below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operatorSelectValue]);

  const normalizedLeftOperand = leftOperand?.replace('}}', '').split('.');
  const inputValue =
    (rightOperand && isObject(rightOperand) && !isArray(rightOperand) ? rightOperand.from : rightOperand) ?? '';

  return (
    <WrapperBox $isRulesStep={isRulesStep}>
      <TestBox>
        <LoadingState loading={loading} height={42} mt={0}>
          <TextEditorInput value={leftOperand} name="left" onChange={handleChange} properties={flatProperties} />
        </LoadingState>
      </TestBox>
      <SelectBox>
        <StyledOperatorControl>
          <Select
            value={operatorSelect.value}
            name={operatorSelect.name}
            options={operatorSelect.options}
            onChange={handleChange}
            hideNone
          />
        </StyledOperatorControl>
      </SelectBox>
      {inputType && (
        <InputBox>
          {/* right https://www.notion.so/whenthen/Conditions-236a2f7ac14140d3bedabd47858f986c#368d2c9485924f469fa13d7a73200caa */}
          <InputByType
            name="right"
            value={
              (rightOperand && isObject(rightOperand) && Object.hasOwn(rightOperand, 'from')
                ? rightOperand.from
                : rightOperand) ?? ''
            }
            onChange={(value) => handleChange(value, 'from')}
            inputType={inputType}
            propertyType={propertyType}
            property={normalizedLeftOperand ? normalizedLeftOperand[normalizedLeftOperand?.length - 1] : null}
          />
          {isRange && (
            <InputByType
              name="right"
              value={inputValue}
              onChange={(value) => handleChange(value, 'to')}
              inputType={inputType}
              propertyType={propertyType}
              property={normalizedLeftOperand ? normalizedLeftOperand[normalizedLeftOperand?.length - 1] : null}
            />
          )}
        </InputBox>
      )}
      {isShowLogicalOp && (
        <LogicalOpBox>
          <P12M color={THEME.greyColors.grey600} padding="0 8px">
            {t(logicalOperator?.toLowerCase())}
          </P12M>
        </LogicalOpBox>
      )}
      {hasMoreThanOneCondition && (
        <DeleteBox>
          <IconButton type="button" color="inherit" size="small" onClick={() => removeTest(id)}>
            <Close size="small" />
          </IconButton>
        </DeleteBox>
      )}
    </WrapperBox>
  );
};

Test.propTypes = {
  test: shape({
    id: string,
    left: string,
    op: oneOf(Object.keys(ComparisonOperator)),
    right: string,
  }),
  tests: arrayOf(
    shape({
      id: string,
      left: string,
      op: string,
      right: string,
    })
  ),
  logicalOperator: oneOf(Object.values(LogicalOperator)),
  hasMoreThanOneCondition: bool.isRequired,
  updateTest: func,
  removeTest: func,
  index: number,
};

Test.defaultProps = {
  test: {},
  tests: [],
  logicalOperator: LogicalOperator.AND,
  updateTest: () => null,
  removeTest: () => null,
  index: 0,
};

export default Test;
