import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import omit from 'lodash/omit';
import { isDefined } from '../../../utils/helpers';
import { useFeature } from '../../../hooks/useFeature';
import { FEATURE_TOGGLES_KEYS } from '../../../constants/featureToggles';
import { useGlobalContext } from '../../../containers/App/context';
import { globalLoadingConst } from '../../../constants/globalLoadingConsts';
import { UI_ROUTES } from '../../../constants/routes';
import useSearch from '../../../hooks/useSearch';
import { useUpdateConnectionData } from './useUpdateConnectionData';
import { CONNECTION_TOAST_TITLE } from './constant';
import { TOAST_TIMEOUT } from '../../../constants/toasts';
import { showNotification } from '../../../hooks/useNotificationManager';
import { CONNECTION_STATUS } from '../components/constant';
import PlaidModal from '../components/PlaidModal';
import ConnectionFormModal from '../components/ConnectionFormModal';
import PaymentMethodDialog from '../components/PaymentMethodDialog';

const INVALID_CREDENTIAL_TOAST_TITLE = 'Connection Error.';
const INVALID_CREDENTIAL_TOAST_MESSAGE = 'We couldn’t authenticate your connection. Check credentials and try again.';
const PAYPAL_APM_KEY = 'wt-apm';

const useHandleConnectionForm = ({
  connection,
  callback,
  avoidRedirect = false,
  closeModal,
  connectData,
  setConnectData,
  handleCallback,
  setPlaidData,
  modalShown,
  toggleModalShown,
  setChildWindow,
  oAuthData,
  plaidConnectionData,
  paymentMethodDialog,
  togglePaymentMethodDialog,
}) => {
  const [multipleFlowEnabled] = useFeature(FEATURE_TOGGLES_KEYS.MULTIPLE_FLOW);
  const { setGlobalLoading } = useGlobalContext();
  const [redirectUrl, setRedirectUrl] = useState(undefined);

  const [searchParams, setSearchParams] = useSearch();
  const isPlaid = connection?.name?.toLowerCase() === 'plaid' && connection.canConnect === true;
  const { saveForm } = useUpdateConnectionData(connection?.id);
  const { push } = useHistory();

  const togglePaymentsModals = () => {
    if (closeModal) {
      closeModal();
      if (modalShown) {
        toggleModalShown();
      }
    } else {
      toggleModalShown();
    }
  };

  const handleRedirect = useCallback(
    (url) => {
      if (url) {
        if (!avoidRedirect) {
          push(url);
        }
        handleCallback(connectData?.id);
      }
    },
    [avoidRedirect, handleCallback, connectData?.id, push]
  );

  const handleClose = useCallback(
    (url) => {
      toggleModalShown();
      handleRedirect(url);
    },
    [toggleModalShown, handleRedirect]
  );

  const handleConnectionForm = useCallback(
    (formInput) => {
      setGlobalLoading(globalLoadingConst.saveConnectionForm, true);
      saveForm({
        variables: {
          id: connectData.id,
          formInput,
        },
      })
        .then((res) => {
          if (res && !res.errors) {
            const responseData = res.data?.saveConnectionForm;

            if (!responseData) {
              showNotification(
                'error',
                INVALID_CREDENTIAL_TOAST_MESSAGE,
                INVALID_CREDENTIAL_TOAST_TITLE,
                TOAST_TIMEOUT
              );

              return;
            }

            if (responseData.id && responseData.status === CONNECTION_STATUS.CONNECTED) {
              showNotification(
                'success',
                `${connection?.name || connection?.company?.name} has been successfully connected.`,
                CONNECTION_TOAST_TITLE,
                TOAST_TIMEOUT
              );

              setRedirectUrl(`${UI_ROUTES.connectionDetails}/${responseData?.id}`);
            }

            // For PPRO onboarding status
            if (responseData.id && responseData.status === CONNECTION_STATUS.ONBOARDING) {
              showNotification(
                'success',
                `${connection?.name || connection?.company?.name} is now in onboarding state.`,
                CONNECTION_TOAST_TITLE,
                TOAST_TIMEOUT
              );

              handleClose(`${UI_ROUTES.connectionDetails}/${responseData?.id}`);
            }

            if (responseData.state === 'NEEDS_CONFIG' && responseData.status === CONNECTION_STATUS.CONNECTED) {
              const hasApmForm =
                multipleFlowEnabled && isDefined(responseData?.steps?.find((el) => el?.type === 'APM'));

              setConnectData({
                ...responseData,
                currentStep: {
                  form: hasApmForm ? 'APM' : responseData?.form || [],
                },
                hideClose: true,
                isComplete: true,
              });
            } else if (responseData.status === CONNECTION_STATUS.CONNECTED && responseData.state !== 'NEEDS_CONFIG') {
              if (!avoidRedirect) {
                setTimeout(() => push(`${UI_ROUTES.connectionDetails}/${responseData?.id}`), 1000);
              }
              if (callback) {
                setTimeout(() => handleCallback(responseData?.id), 1000);
              }
              if (closeModal) {
                closeModal(responseData?.id);
              }
              toggleModalShown();
            }
          } else {
            showNotification('error', 'Server error.', 'uiMessages.error', TOAST_TIMEOUT);
          }
        })
        .finally(() => setGlobalLoading(globalLoadingConst.saveConnectionForm, false));
    },
    [
      setGlobalLoading,
      saveForm,
      connectData.id,
      connection?.name,
      connection?.company?.name,
      multipleFlowEnabled,
      avoidRedirect,
      callback,
      closeModal,
      toggleModalShown,
      push,
      handleCallback,
      handleClose,
      setConnectData,
      setRedirectUrl,
    ]
  );

  const handleResetPlaid = () => {
    setPlaidData({});
  };

  const PAYPAL_APM_PROPS = {
    connectData: connection,
    avoidRedirect: true,
    handleCallback: () => {
      setSearchParams(omit(searchParams, PAYPAL_APM_KEY));
    },
  };

  const renderConnectionForm = () => {
    if (isPlaid && plaidConnectionData.authorizationUrl) {
      return (
        <PlaidModal
          handleResetPlaid={handleResetPlaid}
          plaidConnectionData={plaidConnectionData}
          setChildWindow={setChildWindow}
        />
      );
    }

    if (connectData?.id) {
      return (
        <ConnectionFormModal
          connectData={connectData}
          connectionDetails={connection}
          toggleModalShown={togglePaymentsModals}
          modalShown={modalShown}
          handleConnectionForm={handleConnectionForm}
          redirectUrl={redirectUrl}
          avoidRedirect={avoidRedirect}
          handleCallback={handleCallback}
          setConnectData={setConnectData}
          goBack={toggleModalShown}
        />
      );
    }

    if (paymentMethodDialog && multipleFlowEnabled) {
      return (
        <PaymentMethodDialog
          toggleIsOpen={togglePaymentMethodDialog}
          connectData={oAuthData}
          connection={connection}
          avoidRedirect={avoidRedirect}
          {...(isDefined(callback) && { handleCallback })}
          //props for paypal APM experience
          {...(connection?.company?.name?.toLowerCase() === 'paypal' && PAYPAL_APM_PROPS)}
        />
      );
    }

    return null;
  };

  return {
    renderConnectionForm,
  };
};

export { useHandleConnectionForm };
