import React from 'react';
import { string } from 'prop-types';
import TableCell from '@mui/material/TableCell/TableCell';
import { NotificationManager } from 'react-notifications';
import { CopyToClipboard, P14B } from '../../../../components/atoms';
import { TableCellInner } from './styled';
import ToastCustomContainer from '../../../../components/ToastCustomContainer';
import { TOAST_TIMEOUT } from '../../../../constants/toasts';

export const PublicKeyCell = ({ data }) => {
  const handleCopy = () => {
    NotificationManager.success(
      <ToastCustomContainer message="Copied public API key to clipboard." title="Public API Key." />,
      null,
      TOAST_TIMEOUT
    );
  };
  return (
    <TableCell align="left" padding="none">
      <TableCellInner>
        <CopyToClipboard className="small" text={data} onCopy={handleCopy}>
          <P14B padding="0 0 0 10px" color="#232629" fontFamily="Source Code Pro, monospace">
            {data}
          </P14B>
        </CopyToClipboard>
      </TableCellInner>
    </TableCell>
  );
};

PublicKeyCell.propTypes = {
  data: string.isRequired,
};
