const DATA = `
0742	Veterinary Services
0743	Wine Producers
0744	Champagne Producers
0763	Agricultural Cooperatives
0780	Landscaping and Horticultural Services
1520	General Contractors - Residential and Commercial
1711	Heating, Plumbing, and Air Conditioning Contractors
1731	Electrical Contractors
1740	Masonry, Stonework, Tile-Setting, Plastering, and Insulation Contractors
1750	Carpentry Contractors
1761	Roofing, Siding, and Sheet Metal Work Contractors
1771	Concrete Work Contractors
1799	Special Trade Contractors - Not Elsewhere Classified
2741	Miscellaneous Publishing and Printing Services
2791	Typesetting, Plate Making, and Related Services
2842	Specialty Cleaning, Polishing, and Sanitation Preparations
4011	Railroads
4111	Local and Suburban Commuter Passenger Transportation, including Ferries
4112	Passenger Railways
4119	Ambulance Services
4121	Taxicabs and Limousines
4131	Bus Lines
4214	Motor Freight Carriers and Trucking - Local and Long Distance, Moving and Storage Companies, and Local Delivery
4215	Courier Services - Air and Ground, and Freight Forwarders
4225	Public Warehousing and Storage - Farm Products, Refrigerated Goods, and Household Goods
4411	Steamship and Cruise Lines
4457	Boat Rentals and Leasing
4468	Marinas, Marine Service, and Supplies
4511	Airlines and Air Carriers
4582	Airports, Flying Fields, and Airport Terminals
4722	Travel Agencies and Tour Operators
4784	Tolls and Bridge Fees
4789	Transportation Services - Not Elsewhere Classified
4812	Telecommunication Equipment and Telephone Sales
4814	Telecommunications Services, including Local and Long Distance Calls, Credit Card Calls, Calls through use of Magnetic-Stripe-Reading Telephones, and Fax Services
4815	Monthly Summary Telephone Charges
4816	Comp Network/Information Services
4821	Telegraph Services
4829	Wire Transfers and Money Orders
4899	Cable and Other Pay Television Services
4900	Utilities - Electric, Gas, Water, and Sanitary
5013	Motor Vehicle Supplies and New Parts
5021	Office and Commercial Furniture
5039	Construction Materials - Not Elsewhere Classified
5044	Office, Photographic, Photocopy, and Microfilm Equipment
5045	Computers, Computer Peripheral Equipment, and Software
5046	Commercial Equipment - Not Elsewhere Classified
5047	Dental/Laboratory/Medical/ Ophthalmic Hospital Equipment and Supplies
5051	Metal Service Centers and Offices
5065	Electrical Parts and Equipment
5072	Hardware Equipment and Supplies
5074	Plumbing and Heating Equipment and Supplies
5085	Industrial Supplies - Not Elsewhere Classified
5094	Precious Stones and Metals, Watches, and Jewelry
5099	Durable Goods - Not Elsewhere Classified
5111	Stationery, Office Supplies, Printing and Writing Paper
5122	Drugs, Drug Proprietors, and Druggists’ Sundries
5131	Piece Goods, Notions, and Other Dry Goods
5137	Men’s, Women’s, and Children’s Uniforms and Commercial Clothing
5139	Commercial Footwear
5169	Chemicals and Allied Products - Not Elsewhere Classified
5172	Petroleum and Petroleum Products
5192	Books, Periodicals, and Newspapers
5193	Florists’ Supplies, Nursery Stock, and Flowers
5198	Paints, Varnishes, and Supplies
5199	Non-Durable Goods - Not Elsewhere Classified
5200	Home Supply Warehouse Stores
5211	Lumber and Building Materials Stores
5231	Glass, Paint, and Wallpaper Stores
5251	Hardware Stores
5261	Lawn and Garden Supply Stores, including Nurseries
5271	Mobile Home Dealers
5300	Wholesale Clubs
5309	Duty Free Stores
5310	Discount Stores
5311	Department Stores
5331	Variety Stores
5399	Miscellaneous General Merchandise
5411	Grocery Stores and Supermarkets
5422	Freezer and Locker Meat Provisioners
5441	Candy, Nut, and Confectionery Stores
5451	Dairy Products Stores
5462	Bakeries
5499	Miscellaneous Food Stores - Convenience Stores and Specialty Markets
5511	Car and Truck Dealers (New and Used) Sales, Service, Repairs, Parts, and Leasing
5521	Car and Truck Dealers (Used Only) Sales, Service, Repairs, Parts, and Leasing
5531	Auto and Home Supply Stores
5532	Automotive Tire Stores
5533	Auto Parts and Accessories Stores
5541	Service Stations (with or without Ancillary Services)
5542	Automated Fuel Dispensers
5551	Boat Dealers
5561	Camper, Recreational, and Utility Trailer Dealers
5571	Motorcycle Shops and Dealers
5592	Motor Home Dealers
5598	Snowmobile Dealers
5599	Miscellaneous Automotive, Aircraft, and Farm Equipment Dealers - Not Elsewhere Classified
5611	Men’s and Boys Clothing and Accessory Stores
5621	Women’s Ready To Wear Stores
5631	Women’s Accessory and Specialty Stores
5641	Children’s and Infants’ Wear Stores
5651	Family Clothing Stores
5655	Sports and Riding Apparel Stores
5661	Shoe Stores
5681	Furriers and Fur Shops
5691	Men’s and Women’s Clothing Stores
5697	Tailors, Seamstresses, Mending, and Alterations
5698	Wig and Toupee Stores
5699	Miscellaneous Apparel and Accessory Stores
5712	Furniture, Home Furnishings and Equipment Stores, and Manufacturers, except Appliances
5713	Floor Covering Stores
5714	Drapery, Window Covering, and Upholstery Stores
5715	Alcoholic Beverage Wholesalers
5718	Fireplaces, Fireplace Screens, and Accessories Stores
5719	Miscellaneous Home Furnishings Specialty Stores
5722	Household Appliance Stores
5732	Electronics Stores
5733	Music Stores - Musical Instruments, Pianos, and Sheet Music
5734	Computer Software Stores
5735	Record Strores
5811	Caterers
5812	Eating Places and Restaurants
5813	Drinking Places (Alcoholic Beverages) - Bars, Taverns, Nightclubs, Cocktail Lounges, and Discotheques
5814	Fast Food Restaurants
5815	Digitial Goods - Media: Books, Movies, Music
5816	Digital Goods - Games
5817	Digital Goods - Apps (Excludes Games)
5818	Large Digital Goods Merchants
5912	Drug Stores and Pharmacies
5921	Package Stores - Beer, Wine, and Liquor
5931	Used Merchandise and Secondhand Stores
5932	Antique Shops - Sales, Repairs, and Restoration Services
5933	Pawn Shops
5935	Wrecking and Salvage Yards
5937	Antique Reproduction Stores
5940	Bicycle Shops - Sales and Service
5941	Sporting Goods Stores
5942	Book Stores
5943	Stationery, Office, and School Supply Stores
5944	Jewelry, Watch, Clock, and Silverware Stores
5945	Hobby, Toy, and Game Stores
5946	Camera and Photographic Supply Stores
5947	Gift, Card, Novelty, and Souvenir Stores
5948	Luggage and Leather Goods Stores
5949	Sewing, Needlework, Fabric, and Piece Goods Stores
5950	Glassware and Crystal Stores
5960	Direct Marketing - Insurance Services
5962	Telemarketing - Travel-Related Arrangement Services
5963	Door-to-Door Sales
5964	Direct Marketing Catalog Merchants
5965	Direct Marketing - Combination Catalog and Retail Merchants
5966	Direct Marketing - Outbound Telemarketing Merchants
5967	Direct Marketing - Inbound Telemarketing Merchants
5968	Direct Marketing - Continuity/Subscription Merchants
5969	Direct Marketing/Direct Marketers - Not Elsewhere Classified
5970	Artist Supply and Craft Stores
5971	Art Dealers and Galleries
5972	Stamp and Coin Stores
5973	Religious Goods Stores
5975	Hearing Aids Sales, Service, and Supplies
5976	Orthopedic Goods and Prosthetic Devices
5977	Cosmetic Stores
5978	Typewriter Stores - Sales, Service, and Rentals
5983	Fuel Dealers - Fuel Oil, Wood, Coal, and Liquefied Petroleum
5992	Florists
5993	Cigar Stores and Stands
5994	News Dealers and Newsstands
5995	Pet Shops, Pet Food and Supplies
5996	Swimming Pools - Sales, Supplies, and Services
5997	Electric Razor Stores - Sales and Service
5998	Tent and Awning Stores
5999	Miscellaneous and Specialty Retail
6010	Financial Institutions - Manual Cash Disbursements
6011	Financial Institutions - Automated Cash Disbursements
6012	Financial Institutions - Merchandise and Services
6051	Non Financial Institutions - Foreign Currency, Money Orders, (not Wire Transfer), Scrip, and Travelers Cheques
6211	Securities - Brokers and Dealers
6300	Insurance Sales, Underwriting, and Premiums
6513	Real Estate (e.g., rent)
7011	Lodging Hotels, Motels, and Resorts
7012	Timeshares
7032	Sporting and Recreational Camps
7033	Trailer Parks and Campgrounds
7210	Laundry, Cleaning and Garment Services
7211	Laundry Services - Family and Commercial
7216	Dry Cleaners
7217	Carpet and Upholstery Cleaning
7221	Photographic Studios
7230	Beauty and Barber Shops
7251	Shoe Repair Shops, Shoe Shine Parlors, and Hat Cleaning Shops
7261	Funeral Services and Crematories
7273	Dating and Escort Services
7276	Tax Preparation Services
7277	Counseling Services - Debt, Marriage, and Personal
7278	Buying and Shopping Services and Clubs
7296	Clothing Rental - Costumes, Uniforms, and Formal Wear
7297	Massage Parlors
7298	Health and Beauty Spas
7299	Miscellaneous Personal Services - Not Elsewhere Classified
7311	Advertising Services
7321	Consumer Credit Reporting Agencies
7322	Debt Collection Agencies
7333	Commercial Photography, Art, and Graphics
7338	Quick Copy, Reproduction, and Blueprinting Services
7339	Stenographic and Secretarial Support Services
7342	Exterminating and Disinfecting Services
7349	Cleaning, Maintenance, and Janitorial Services
7361	Employment Agencies and Temporary Help Services
7372	Computer Programming, Data Processing, and Integrated Systems Design Services
7375	Information Retrieval Services
7379	Computer Maintenance and Repair Services - Not Elsewhere Classified
7392	Management, Consulting, and Public Relations Services
7393	Detective Agencies, Protective Agencies, and Security Services, including Armored Cars and Guard Dogs
7394	Equipment, Tool, Furniture, and Appliance Rental and Leasing
7395	Photo Finishing Laboratories and Photo Developing
7399	Business Services -Not Elsewhere Classified
7512	Automobile Rental Agency
7513	Truck and Utility Trailer Rentals
7519	Motor Home and Recreational Vehicle Rentals
7523	Parking Lots and Garages
7531	Automotive Body Repair Shops
7534	Tire Re-Treading and Repair Shops
7535	Automotive Paint Shops
7538	Automotive Service Shops (Non-Dealer)
7542	Car Washes
7549	Towing Services
7622	Electronics Repair Shops
7623	Air Conditioning and Refrigeration Repair Shops
7629	Electrical and Small Appliance Repairs
7631	Watch, Clock, and Jewelry Repair Shops
7641	Furniture - Re-Upholstery, Repair, and Refinishing
7692	Welding Services
7699	Miscellaneous Repair Shops and Related Services
7829	Motion Picture and Video Tape Production and Distribution
7832	Motion Picture Theaters
7841	Video Tape Rental Stores
7911	Dance Halls, Studios, and Schools
7922	Theatrical Producers (except Motion Pictures) and Ticket Agencies
7929	Bands, Orchestras, and Miscellaneous Entertainers - Not Elsewhere Classified
7932	Billiard and Pool Establishments
7933	Bowling Alleys
7941	Commercial Sports, Professional Sports Clubs, Athletic Fields, and Sports Promoters
7991	Tourist Attractions and Exhibits
7992	Public Golf Courses
7993	Video Amusement Game Supplies
7994	Video Game Arcades and Establishments
7995	Betting, including Lottery Tickets, Casino Gaming Chips, Off-Track Betting, and Wagers at Race Tracks
7996	Amusement Parks, Circuses, Carnivals, and Fortune Tellers
7997	Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses
7998	Aquariums, Seaquariums, and Dolphinariums
7999	Recreation Services - Not Elsewhere Classified
8011	Doctors and Physicians - Not Elsewhere Classified
8021	Dentists and Orthodontists
8031	Osteopaths
8041	Chiropractors
8042	Optometrists and Ophthalmologists
8043	Opticians, Optical Goods, and Eyeglasses
8049	Podiatrists and Chiropodists
8050	Nursing and Personal Care Facilities
8062	Hospitals
8071	Medical and Dental Laboratories
8099	Medical Services and Health Practitioners - Not Elsewhere Classified
8111	Legal Services and Attorneys
8211	Elementary and Secondary Schools
8220	Colleges, Universities, Professional Schools, and Junior Colleges
8241	Correspondence Schools
8244	Business and Secretarial Schools
8249	Trade and Vocational Schools
8299	Schools and Educational Services - Not Elsewhere Classified
8351	Child Care Services
8398	Charitable and Social Service Organizations
8641	Civic, Social, and Fraternal Associations
8651	Political Organizations
8661	Religious Organizations
8675	Automobile Associations
8699	Membership Organizations - Not Elsewhere Classified
8734	Testing Laboratories (Non-Medical)
8911	Architectural, Engineering, and Surveying Services
8931	Accounting, Auditing, and Bookkeeping Services
8999	Professional Services - Not Elsewhere Classified
9211	Court Costs, including Alimony and Child Support
9222	Fines
9223	Bail and Bond Payments
9311	Tax Payments
9399	Government Services - Not Elsewhere Classified
9402	Postal Services Government Only
`;

export { DATA };
