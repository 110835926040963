import React from 'react';

const Support = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.39999 6.39999C6.40005 6.10667 6.48074 5.81901 6.63323 5.56845C6.78573 5.31789 7.00417 5.11407 7.26468 4.97927C7.52518 4.84447 7.81773 4.78387 8.11035 4.8041C8.40297 4.82434 8.6844 4.92462 8.92388 5.09399C9.16336 5.26336 9.35167 5.4953 9.46823 5.76446C9.58479 6.03363 9.62512 6.32965 9.58481 6.62019C9.5445 6.91072 9.4251 7.18459 9.23966 7.41185C9.05422 7.63911 8.80987 7.81102 8.53333 7.90879C8.37729 7.96396 8.2422 8.06617 8.14668 8.20133C8.05116 8.33649 7.99991 8.49795 7.99999 8.66346V9.19999"
        stroke="#787F88"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
        stroke="#787F88"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <ellipse cx="8" cy="11.3333" rx="0.666667" ry="0.666667" fill="#787F88" />
    </svg>
  );
};

export default Support;
