import PropTypes from 'prop-types';
import React from 'react';
import THEME from '../../constants/theme';

const CaretUpDown = ({ fill, ...props }) => {
  return (
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.38615 11.6433L0.80103 8.58996C0.495947 8.23071 0.743894 7.6665 1.20691 7.6665H6.37715C6.48077 7.66641 6.58222 7.69719 6.66935 7.75514C6.75649 7.81309 6.8256 7.89577 6.86843 7.99327C6.91126 8.09077 6.92598 8.19896 6.91083 8.30489C6.89569 8.41081 6.85131 8.50998 6.78303 8.59051L4.19791 11.6427C4.14731 11.7025 4.08492 11.7505 4.01492 11.7833C3.94491 11.8162 3.86891 11.8332 3.79203 11.8332C3.71514 11.8332 3.63915 11.8162 3.56914 11.7833C3.49914 11.7505 3.43674 11.7025 3.38615 11.6427V11.6433Z"
        fill={fill}
      />
      <path
        d="M3.38615 0.356256L0.80103 3.40955C0.495947 3.7688 0.743894 4.33301 1.20691 4.33301H6.37715C6.48077 4.3331 6.58222 4.30233 6.66935 4.24437C6.75649 4.18642 6.8256 4.10374 6.86843 4.00624C6.91126 3.90874 6.92598 3.80055 6.91083 3.69462C6.89569 3.5887 6.85131 3.48953 6.78303 3.409L4.19791 0.356813C4.14731 0.296989 4.08492 0.249042 4.01492 0.21619C3.94491 0.183338 3.86891 0.166341 3.79203 0.166341C3.71514 0.166341 3.63915 0.183338 3.56914 0.21619C3.49914 0.249042 3.43674 0.296989 3.38615 0.356813V0.356256Z"
        fill={fill}
      />
    </svg>
  );
};

CaretUpDown.propTypes = {
  fill: PropTypes.string,
};
CaretUpDown.defaultProps = {
  fill: THEME.statusColors.succeeded,
};

export default CaretUpDown;
