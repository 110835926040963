import React, { useEffect, useRef } from 'react';
import { string, shape, oneOfType } from 'prop-types';
import { useToggle } from 'react-use';
import isObject from 'lodash/isObject';
import isNil from 'lodash/isNil';
import Box from '@mui/material/Box';
import ReactJson from 'react-json-view';
import THEME from '../../constants/theme';
import { L14M } from '../atoms';
import { StyledLogsValue } from './styled';
import ArrowUp from '../../assets/icons/ArrowUp';

const TEXT_ROW_HEIGHT = 16;
const LogsRow = ({ name, value }) => {
  const [isOpen, toggleIsOpen] = useToggle(false);
  const [isExpandable, toggleIsExpandable] = useToggle(false);
  const blockProps = isExpandable ? { $isExpandable: isExpandable, $isOpen: isOpen, onClick: toggleIsOpen } : {};
  const textRef = useRef(null);
  const jsonStyles = {
    backgroundColor: isOpen ? THEME.greyColors.grey17 : THEME.primaryColors.black,
    cursor: 'pointer',
    zIndex: -2,
  };

  useEffect(() => {
    if (textRef.current.offsetHeight > TEXT_ROW_HEIGHT || isObject(value)) {
      toggleIsExpandable(true);
    }
  }, [toggleIsExpandable, value]);

  return (
    <Box mb="12px" px="24px">
      <L14M color={THEME.primaryColors.white} margin="0 0 8px 0">
        {name}
      </L14M>
      <StyledLogsValue {...blockProps}>
        <Box ref={textRef} lineHeight="16px">
          {isObject(value) ? (
            <ReactJson
              src={value}
              name={false}
              enableClipboard={false}
              displayDataTypes={false}
              style={jsonStyles}
              collapsed={!isOpen}
              theme="monokai"
            />
          ) : (
            <L14M color={`${THEME.primaryColors.white}50`}>
              {!isNil(value) && value?.toString()}
              {isNil(value) && 'null'}
            </L14M>
          )}
        </Box>
        {isExpandable && <ArrowUp />}
      </StyledLogsValue>
    </Box>
  );
};

LogsRow.propTypes = {
  name: string,
  value: oneOfType([string, shape({})]),
};

LogsRow.defaultProps = {
  name: null,
  value: null,
};

export default LogsRow;
