import styled from 'styled-components';
import THEME from '../../../../../../../constants/theme';

const StyledSelectWrapper = styled.div`
  .MuiFormControl-root {
    margin: 6px 0 24px 0;
  }

  .MuiSelect-root {
    border: 1px solid ${THEME.greyColors.grey200} !important;
  }
`;

export { StyledSelectWrapper };
