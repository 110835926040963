import React from 'react';

const DisputeIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM8.59387 5.01819H11.378L11.1194 12.0182H8.85239L8.59387 5.01819ZM11.378 13.9671C11.368 14.7426 10.7167 15.3591 9.98591 15.3591C9.21531 15.3591 8.58392 14.7426 8.59387 13.9671C8.58392 13.2114 9.21531 12.5949 9.98591 12.5949C10.7167 12.5949 11.368 13.2114 11.378 13.9671Z"
        fill="black"
      />
    </svg>
  );
};

export default DisputeIcon;
