import React from 'react';

const Window = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 9H23H1ZM3.7 21H20.3C21.7912 21 23 19.7912 23 18.3V5.7C23 4.20883 21.7912 3 20.3 3H3.7C2.20883 3 1 4.20883 1 5.7V18.3C1 19.7912 2.20883 21 3.7 21Z"
        stroke="#787F88"
        strokeWidth="2"
      />
      <path
        d="M5 6C5 6.55228 4.55228 7 4 7C3.44772 7 3 6.55228 3 6C3 5.44772 3.44772 5 4 5C4.55228 5 5 5.44772 5 6Z"
        fill="#787F88"
      />
      <path
        d="M8 6C8 6.55228 7.55228 7 7 7C6.44772 7 6 6.55228 6 6C6 5.44772 6.44772 5 7 5C7.55228 5 8 5.44772 8 6Z"
        fill="#787F88"
      />
      <path
        d="M11 6C11 6.55228 10.5523 7 10 7C9.44772 7 9 6.55228 9 6C9 5.44772 9.44772 5 10 5C10.5523 5 11 5.44772 11 6Z"
        fill="#787F88"
      />
    </svg>
  );
};

export default Window;
