import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { H4, P14 } from '../../../../../components/atoms';
import DocsItem from './DocsItem';
import { Insights, PaymentProcessing, Optimise, Tokenise } from '../../../../../assets/icons/Developers';

const devZoneUrl = process.env.REACT_APP_DEV_ZONE_URL;

const docsConsts = [
  {
    title: 'vault',
    description: 'Use our Vault to securely tokenise data and remain PCI compliant.',
    link: `${devZoneUrl}/tokenisation`,
    icon: Tokenise,
  },
  {
    title: 'checkout',
    description: 'Build a custom checkout form, and securely capture payment information with our Checkout SDKs.',
    link: `${devZoneUrl}/checkout`,
    icon: Optimise,
  },
  {
    title: 'payment processing',
    description: 'Our unified API processes payments across all of your gateways and methods.',
    link: `${devZoneUrl}/payments`,
    icon: PaymentProcessing,
  },
  {
    title: 'intent',
    description: 'Monitor and optimise your checkout for payment intents, attempts, acceptance, fees and much more.',
    link: `${devZoneUrl}/intent`,
    icon: Insights,
  },
];

const HEADING = 'Which documentation are you looking for?';
const DESCRIPTION = 'Read WhenThen API Reference documentation to understand all of our APIs.';

const DocumentationBlock = () => {
  const { t } = useTranslation();
  return (
    <Box pt="40px" pb="80px">
      <H4>{t(HEADING)}</H4>
      <P14 color="#787F88" margin="8px 0 0 0">
        {t(DESCRIPTION)}
      </P14>
      <Box width="100%" display="flex" justifyContent="space-between" mt="40px">
        {docsConsts.map((data) => (
          <DocsItem key={data?.title} data={data} />
        ))}
      </Box>
    </Box>
  );
};

export default DocumentationBlock;
