import React from 'react';
import { string } from 'prop-types';

const Klarna = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3927_7239)">
      <rect width="24" height="24" rx="12" fill="#FEB3C7" />
      <circle cx="12" cy="12" r="12" fill="#FEB3C7" />
      <path
        d="M7.67867 6.16663V17.8333H5V6.16663H7.67867ZM14.3637 6.16663C14.3637 8.61313 13.4402 10.8945 11.7882 12.623L11.6261 12.788L15.3262 17.8333H12.0198L7.99892 12.35L9.03667 11.573C10.7038 10.3246 11.6821 8.43579 11.7416 6.36729L11.7445 6.16663H14.3637ZM17.5417 14.9166C17.9284 14.9166 18.2994 15.0703 18.5729 15.3438C18.8464 15.6173 19 15.9882 19 16.375C19 16.7617 18.8464 17.1327 18.5729 17.4062C18.2994 17.6796 17.9284 17.8333 17.5417 17.8333C17.1549 17.8333 16.784 17.6796 16.5105 17.4062C16.237 17.1327 16.0833 16.7617 16.0833 16.375C16.0833 15.9882 16.237 15.6173 16.5105 15.3438C16.784 15.0703 17.1549 14.9166 17.5417 14.9166Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_3927_7239">
        <rect width="24" height="24" rx="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Klarna.propTypes = {
  width: string,
  height: string,
};

Klarna.defaultProps = {
  width: '24',
  height: '24',
};

export default Klarna;
