import React from 'react';

const Link = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="4" fill="#DDECF5" />
    <path
      d="M11.0572 10.1144L10.1144 9.17156L12.4714 6.81454C13.7732 5.51279 15.8837 5.51279 17.1855 6.81454C18.4872 8.11629 18.4872 10.2268 17.1855 11.5286L14.8284 13.8856L13.8856 12.9428L16.2426 10.5858C17.0237 9.80473 17.0237 8.5384 16.2426 7.75735C15.4616 6.9763 14.1953 6.9763 13.4142 7.75735L11.0572 10.1144ZM10.1144 11.0572L7.75736 13.4142C6.97631 14.1953 6.97631 15.4616 7.75736 16.2426C8.53841 17.0237 9.80474 17.0237 10.5858 16.2426L12.9428 13.8856L13.8856 14.8284L11.5286 17.1854C10.2269 18.4872 8.1163 18.4872 6.81455 17.1854C5.51281 15.8837 5.51281 13.7731 6.81455 12.4714L9.17158 10.1144L10.1144 11.0572ZM13.8856 9.17156L14.8284 10.1144L10.1144 14.8284L9.17158 13.8856L13.8856 9.17156Z"
      fill="black"
    />
  </svg>
);

export default Link;
