import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import compact from 'lodash/compact';
import Masonry from 'react-masonry-css';
import Checkbox from '@mui/material/Checkbox';
import { CheckboxOnboarding, P14, P16M } from '../atoms';
import THEME from '../../constants/theme';
import { StyledBox, StyledContainer, StyledFormControlLabel, StyledGoalBox } from './styled';

const breakpointColumnsObj = {
  default: 2,
  [THEME.breakPoints.tabletLarge]: 1,
};

const PaymentNeeds = ({ options, selectedPaymentNeeds, onSelect }) => {
  const isChecked = (value) => (selectedPaymentNeeds ?? []).includes(value);

  const _onSelect = ({ target }) => {
    const { name, checked } = target;
    if (checked) {
      onSelect(compact([...(selectedPaymentNeeds ?? []), name]));
    } else {
      onSelect((selectedPaymentNeeds ?? []).filter((id) => id !== name));
    }
  };

  return (
    <StyledContainer>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="pg-masonry-grid"
        columnClassName="pg-masonry-grid_column"
      >
        {options.map(({ value, title, text, Icon }) => (
          <StyledGoalBox key={value} $checked={isChecked(value)}>
            <StyledFormControlLabel
              key={value}
              value={value}
              control={<Checkbox value={value} name={value} hidden />}
              label={
                <>
                  <StyledBox mb="16px" justifyContent="space-between">
                    <Icon />
                    <CheckboxOnboarding
                      transform="scale(1.5) translateX(-0.4px)"
                      width="20px"
                      height="20px"
                      value={value}
                      name={value}
                      checked={isChecked(value)}
                      onChange={_onSelect}
                    />
                  </StyledBox>
                  <P16M margin="0 0 12px 0">{title}</P16M>
                  <P14 color={THEME.greyColors.grey23}>{text}</P14>
                </>
              }
              checked={isChecked(value)}
              onChange={_onSelect}
            >
              <P16M>{title}</P16M>
              <P14 color={THEME.greyColors.grey23}>{text}</P14>
            </StyledFormControlLabel>
          </StyledGoalBox>
        ))}
      </Masonry>
    </StyledContainer>
  );
};

PaymentNeeds.propTypes = {
  options: arrayOf(shape({})),
  selectedPaymentNeeds: arrayOf(string),
  onSelect: func,
};

PaymentNeeds.defaultProps = {
  options: [],
  selectedPaymentNeeds: [],
  onSelect: () => {},
};

export default PaymentNeeds;
