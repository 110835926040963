import React, { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import 'react-phone-input-2/lib/material.css';
import isEmpty from 'lodash/isEmpty';
import { func } from 'prop-types';
import { NotificationManager } from 'react-notifications';
import SetPhone from './SetPhone';
import { VERIFY_AUTHENTIFICATOR, ADD_AUTHENTIFICATOR } from '../../../utils/queries/public/publicMutations';
import { useGlobalContext } from '../../../containers/App/context';
import { STORAGE_KEYS } from '../../../constants/storage';
import { localStorageService } from '../../../utils/localStorageService';
import { FlexContainer } from '../../atoms/flex/FlexContainer';
import { TOAST_UNEXPECTED_ERROR_MESSAGE, TOAST_TIMEOUT } from '../../../constants/toasts';
import ToastCustomContainer from '../../ToastCustomContainer';

const SmsAuth = ({ setSetupSuccess }) => {
  const [phone, setPhone] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [code, setCode] = useState('');
  const [addAuth, { loading: addingLoading }] = useMutation(ADD_AUTHENTIFICATOR);
  const [verifyAuth, { loading: submitLoading }] = useMutation(VERIFY_AUTHENTIFICATOR);
  const { setToken, setRefreshToken, getMeRefetch } = useGlobalContext();

  const loading = addingLoading || submitLoading;

  const handleCodeRequest = useCallback(async () => {
    const phoneNumber = phone.startsWith('+') ? phone : `+${phone}`;
    setCode('');

    try {
      const { data } = await addAuth({
        variables: {
          phoneNumber: phoneNumber,
          oobChannel: 'sms',
          mfaType: 'oob',
        },
      });

      if (data && data?.addAuthenticator) {
        setCodeSent(true);
        setToken(data.addAuthenticator.token);
        setRefreshToken(data.addAuthenticator.refreshToken);
        if (data.addAuthenticator?.recoveryCodes) {
          localStorageService.setItem(STORAGE_KEYS.recoveryPass, data.addAuthenticator.recoveryCodes);
        }
        getMeRefetch();
        return;
      }
    } catch (error) {
      NotificationManager.error(
        <ToastCustomContainer message={TOAST_UNEXPECTED_ERROR_MESSAGE} title="2 Factor Authentication" />,
        null,
        TOAST_TIMEOUT
      );
    }
  }, [phone, addAuth, setToken, setRefreshToken, getMeRefetch]);

  const submitCode = useCallback(() => {
    verifyAuth({
      variables: {
        bindingCode: code,
      },
    }).then((res) => {
      if (res && res.data?.verifyAuthenticator && isEmpty(res.errors)) {
        setToken(res.data.verifyAuthenticator.token);
        setRefreshToken(res.data.verifyAuthenticator.refreshToken);
        setSetupSuccess(true);
      }
    });
  }, [setRefreshToken, setSetupSuccess, code, setToken, verifyAuth]);

  return (
    <FlexContainer flexDirection="column" alignItems="flex-start">
      <SetPhone
        handleCodeSent={handleCodeRequest}
        phone={phone}
        setPhone={setPhone}
        loading={loading}
        codeSent={codeSent}
        code={code}
        setCode={setCode}
        submitCode={submitCode}
      />
    </FlexContainer>
  );
};

SmsAuth.propTypes = {
  setSetupSuccess: func.isRequired,
};

export default SmsAuth;
