import { useCallback, useEffect, useState } from 'react';
import { useIntercom as useIntercoms } from 'react-use-intercom';
import { gql, useQuery } from '@apollo/client';
import { useNotificationManager } from './useNotificationManager';

const useIntercom = (dontShutdown) => {
  const [isOpen, setIsOpen] = useState(false);
  const [intercomCloseButton, setIntercomCloseButton] = useState(false);
  const [unreadMessagesCounter, setUnreadMessagesCounter] = useState(0);

  const toggleIntercomCloseButton = useCallback(() => setIntercomCloseButton((prevState) => !prevState), []);
  const { error, data } = useQuery(gql`
    query getIntercomUser {
      me {
        id
      }
    }
  `);

  useNotificationManager('error', error?.message, 'Fetch Intercom User');
  const userId = data?.me?.id;
  const { show, boot, shutdown, hide, startTour } = useIntercoms();

  const closeIntercom = () => {
    setIsOpen(false);
    hide();
    toggleIntercomCloseButton();
  };

  const openIntercom = useCallback(() => {
    setIsOpen(true);
    show();
    setIntercomCloseButton(true);
  }, [show]);

  useEffect(() => {
    if (userId) {
      boot({ userId });
      if (window.Intercom) {
        window.Intercom('onShow', () => {
          openIntercom();
        });
        window.Intercom('onUnreadCountChange', (unreadCount) => {
          setUnreadMessagesCounter(unreadCount);
        });
      }
    }
    if (!dontShutdown && !data) {
      return () => shutdown();
    }
    return null;
  }, [userId, boot, shutdown, openIntercom, dontShutdown, data]);
  const startDemoTour = () => startTour(process.env.REACT_APP_INTERCOM_TOUR_ID);

  return {
    toggleIntercom: openIntercom,
    intercomCloseButton,
    closeIntercom,
    intercomIsOpen: isOpen,
    startDemoTour,
    unreadMessagesCounter,
  };
};

export { useIntercom };
