import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { useToggle } from 'react-use';
import ArrowRight from '../../../assets/arrows/arrowRight';
import THEME from '../../../constants/theme';
import {
  AutomationTemplatesWrap,
  AutomationTemplatesLine,
  TemplateButtonSwipe,
  AutomationTemplatesWrapWindow,
  Buttons,
  Bullet,
} from './styled';
import LoadingState from '../../../components/FlowEditor/components/AutomationDetails/LoadingState';
import useGetRecommendedTemplates from './useGetRecommendedTemplates';
import useOverviewDialogData from '../../MVPAutomation/useOverviewDialogData';
import RecommendedOverviewDialog from '../../FlowDetailsPage/Automation/RecommendedAutomations/RecommendedOverviewDialog';
import Template from './Template';
import NewConnectionModal from '../../FlowDetailsPage/FlowSettings/PaymentProcessorPage/ConnectedSection/NewConnectionModal';
import tokenize from '../../../assets/img/tokenize.png';
import paymentProcessors from '../../../assets/img/paymentProcessors.png';
import { useGlobalContext } from '../../../containers/App/context';

const TOKENIZATION = 'TOKENIZATION';
const ACCESS_GLOBAL_PROCESSORS = 'ACCESS_GLOBAL_PROCESSORS';
const DOCUMENTATION_URL = process.env.REACT_APP_DEV_ZONE_URL;

const TOKENIZE = {
  categories: ['TOKENIZATION'],
  description:
    'Integrate the WhenThen Vault in days to own your payments data and tokenise personal customer data and payment information across global payment processors and card networks.',
  name: 'Securely tokenize data and remain PCI complaint',
  descName: 'Tokenize',
  slug: 'tokenize',
  paymentNeeds: 'Tokenisation',
  buttonText: 'View Documentation',
  mappedFeatures: [
    'PCI DSS Level 1 Compliant ',
    'Network tokens and account updater',
    'Own your payment data, enable Orchestration',
  ],
  margin: '0px',
  template: {
    id: 'template.payment-processing-base',
  },
  imageWebApp: tokenize,
  customHandleActionFn: `${DOCUMENTATION_URL}/tokenisation`,
};

const CONNECT_PROCESSORS = {
  categories: ['ACCESS_GLOBAL_PROCESSORS'],
  description:
    'Use the WhenThen Universal Payments API to authenticate Payment Processros of your choice without complicated integration work. Simply call the WhenThen authorize payment API and specify which Processor to use, or create an orchestration payments automation using our no-code canvas',
  name: 'Connect Payment Processors through universal payments API',
  descName: 'Connect Payment Processors',
  slug: 'connectProcessors',
  paymentNeeds: 'Access global processors',
  buttonText: 'View Documentation',
  mappedFeatures: [
    'PCI DSS Level 1 Compliant ',
    'Network tokens and account updater',
    'Own your payment data, enable Orchestration',
  ],
  margin: '0px',
  template: {
    id: 'template.payment-processing-base',
  },
  secondaryButtonText: 'Connect Processors',
  imageWebApp: paymentProcessors,
  customHandleActionFn: `${DOCUMENTATION_URL}/payments`,
};

const NewAutomationTemplates = () => {
  const [swiperPosition, setSwiperPosition] = useState(0);
  const [isOpenConnectionModal, toggleIsOpenConnectionModal] = useToggle(false);
  const {
    overviewData,
    onSetOverviewData,
    onCloseDialog,
    actionButtonText,
    isAddToExistingAutomationButton,
    isShowAddYourRecipeContent,
    setIsShowAddYourRecipeContent,
    onlyOneInstructAutomationId,
  } = useOverviewDialogData();
  const { getMeData } = useGlobalContext();

  const paymentNeeds = getMeData?.we?.paymentNeeds;

  const { recommendedTemplates, loading } = useGetRecommendedTemplates();

  useEffect(() => {
    setSwiperPosition(0);
  }, [recommendedTemplates]);

  const mappedTemplates = recommendedTemplates.map((template) => ({
    ...template,
    paymentNeeds: template.template.paymentNeeds
      .map((item) =>
        item
          .toLowerCase()
          .split('_')
          .join(' ')
      )
      .join(', '),
  }));

  const openConnectProcessors = () => {
    onCloseDialog();
    toggleIsOpenConnectionModal();
  };

  const extendedPaymentProcessors = {
    ...CONNECT_PROCESSORS,
    secondaryButtonFn: openConnectProcessors,
  };

  const allTemplates = [
    ...mappedTemplates,
    ...(paymentNeeds.includes(TOKENIZATION) ? [TOKENIZE] : []),
    ...(paymentNeeds.includes(ACCESS_GLOBAL_PROCESSORS) ? [extendedPaymentProcessors] : []),
  ];

  const handleSwipe = (amount) => {
    if (swiperPosition + amount > -1 && swiperPosition + amount < allTemplates.length) {
      setSwiperPosition(swiperPosition + amount);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe(1),
    onSwipedRight: () => handleSwipe(-1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <>
      <AutomationTemplatesLine>
        <AutomationTemplatesWrapWindow>
          <div {...swipeHandlers}>
            <AutomationTemplatesWrap position={swiperPosition}>
              <LoadingState width="420px" height="292px" loading={loading}>
                {allTemplates.map((template) => (
                  <Template key={template.slug} templateData={template} onSetOverviewData={onSetOverviewData} />
                ))}
              </LoadingState>
            </AutomationTemplatesWrap>
          </div>
        </AutomationTemplatesWrapWindow>

        <Box display="flex" justifyContent="space-between" alignItems="center" margin="0 24px 24px 0">
          <Box p="4px" display="flex">
            {allTemplates.map((item, index) => (
              <Bullet
                key={`bullet-${item.slug}`}
                $active={swiperPosition === index}
                onClick={() => setSwiperPosition(index)}
              />
            ))}
          </Box>
          <Buttons>
            <TemplateButtonSwipe onClick={() => handleSwipe(-1)} disabled={swiperPosition === 0}>
              <ArrowRight size={20} color={THEME.primaryColors.white} />
            </TemplateButtonSwipe>
            <TemplateButtonSwipe onClick={() => handleSwipe(1)} disabled={swiperPosition >= allTemplates.length - 1}>
              <ArrowRight size={20} color={THEME.primaryColors.white} />
            </TemplateButtonSwipe>
          </Buttons>
        </Box>
      </AutomationTemplatesLine>
      {overviewData ? (
        <RecommendedOverviewDialog
          isOpen={Boolean(overviewData)}
          closeDialog={onCloseDialog}
          overviewData={overviewData}
          actionButtonText={actionButtonText}
          isAddToExistingAutomationButton={isAddToExistingAutomationButton}
          isShowAddYourRecipeContent={isShowAddYourRecipeContent}
          setIsShowAddYourRecipeContent={setIsShowAddYourRecipeContent}
          onlyOneInstructAutomationId={onlyOneInstructAutomationId}
        />
      ) : null}
      {isOpenConnectionModal && (
        <NewConnectionModal
          isOpen={isOpenConnectionModal}
          closeModal={toggleIsOpenConnectionModal}
          types={['PAYMENT_GATEWAY']}
        />
      )}
    </>
  );
};

export default NewAutomationTemplates;
