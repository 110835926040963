import React, { useCallback } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { NotificationManager } from 'react-notifications';
import { SWITCH_TEAM } from '../utils/queries/workspaces/workspacesMutations';
import { useGlobalContext } from '../containers/App/context';
import { setStorageValue } from '../client/links/demoLink/storage';
import { useUserDispatch } from '../providers/User/UserContext';
import { setIsDemo } from '../providers/User/state/actions';
import ToastCustomContainer from '../components/ToastCustomContainer';
import { TOAST_TIMEOUT } from '../constants/toasts';

const TITLE = 'Team switch error';

const useSwitchTeam = () => {
  const userDispatch = useUserDispatch();
  const client = useApolloClient();
  const [switchTeamPromise] = useMutation(SWITCH_TEAM);
  const { setRefreshToken, setToken, logOut } = useGlobalContext();

  return useCallback(
    async (id) => {
      setStorageValue(false);
      let token;
      let refreshToken;
      // get new tokens
      try {
        const { errors, data } = await switchTeamPromise({ variables: { id: id } });
        const switchTeams = data?.switchTeams;
        token = switchTeams?.token;
        refreshToken = switchTeams?.refreshToken;

        if (errors) {
          NotificationManager.error(
            <ToastCustomContainer message="uiMessages.error" title={TITLE} />,
            null,
            TOAST_TIMEOUT
          );
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      if (!token || !refreshToken) {
        NotificationManager.error(
          <ToastCustomContainer message="Tokens are corrupted." title={TITLE} />,
          null,
          TOAST_TIMEOUT
        );
        return;
      }

      setToken(token);
      setRefreshToken(refreshToken);
      userDispatch(setIsDemo(false));
      // resetStore
      try {
        await client.resetStore();
      } catch (e) {
        logOut();
        NotificationManager.error(
          <ToastCustomContainer message="Failed reset store." title={TITLE} />,
          null,
          TOAST_TIMEOUT
        );
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    [client, logOut, setRefreshToken, setToken, switchTeamPromise, userDispatch]
  );
};

export { useSwitchTeam };
