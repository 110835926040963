import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import { L10UM } from '../../../../../components/atoms/Typography/L10UM';
import THEME from '../../../../../constants/theme';

const StyledWrapper = styled.div`
  max-width: 1119px;
  background-color: ${THEME.greyColors.grey14};
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 40px 40px 40px 0;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -40px;
    width: 40px;
    height: 100%;
    background-color: ${THEME.greyColors.grey14};
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;

const Main = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1180px) {
    flex-direction: column;
  }
`;

const StatusLabel = styled(L10UM)`
  border-radius: 4px;
  padding: 5px 8px;
  margin-left: 16px;
  text-transform: uppercase;
  color: white;
  background-color: ${({ color }) => color || THEME.secondaryColors.nodeError};
`;

const Left = styled.div`
  width: 100%;
  min-width: 335px;
  /* max-width: 600px; */
  /* flex: 8; */

  @media (max-width: 1180px) {
    max-width: 100%;
  }
`;

const Right = styled.div`
  margin-left: 8.33%;
  flex: 5.5;
  min-width: 334px;
  max-width: 334px;

  @media (max-width: 1180px) {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }
`;

const StyledDrawer = styled(Drawer)`
  & .MuiDrawer-paper {
    width: 520px;
    background: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
    top: 16px;
    right: 16px;
    height: calc(100% - 32px);
    border-radius: 8px;
  }
`;

export { StyledWrapper, Header, StatusLabel, Left, Right, Main, StyledDrawer };
