import React from 'react';
import { Box } from '@mui/material';
import { string } from 'prop-types';
import styled from 'styled-components';
import THEME from '../../../../../../../constants/theme';
import { P14, P14M } from '../../../../../../atoms';
import ArrowUpRight from '../../../../../../../assets/icons/ArrowUpRight';

const Link = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const DOCUMENTATION_URL = process.env.REACT_APP_DEV_ZONE_URL;

const LINK_ADDRESS = `${DOCUMENTATION_URL}/payments/disputes`;

const ReadOnly = ({ value }) => (
  <Box
    height="48px"
    display="flex"
    alignItems="center"
    px="12px"
    border={`1px solid ${THEME.greyColors.grey8}`}
    borderRadius="4px"
    bgcolor={THEME.greyColors.grey21}
    justifyContent="space-between"
  >
    <P14 color={THEME.greyColors.grey500} margin="0 6px 0 0">
      {value}
    </P14>
    <Link as="a" href={LINK_ADDRESS} target="_blank" rel="noopener noreferrer">
      <P14M color={THEME.primaryColors.primary}>More info</P14M>
      <ArrowUpRight color={THEME.primaryColors.primary} />
    </Link>
  </Box>
);

ReadOnly.propTypes = {
  value: string,
};

ReadOnly.defaultProps = {
  value: null,
};

export default ReadOnly;
