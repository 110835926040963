import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import has from 'lodash/has';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import Box from '@mui/material/Box';
import ArrowUpRight from '../../../../../../../assets/icons/ArrowUpRight';
import { STORAGE_KEYS } from '../../../../../../../constants/storage';
import THEME from '../../../../../../../constants/theme';
import { ExternalLink } from '../../../../../../../pages/FlowDetailsPage/Sidebar/styled';
import { localStorageService } from '../../../../../../../utils/localStorageService';
import { GET_NODE_HELPER } from '../../../../../../../utils/queries/flows/queries';
import { L14M } from '../../../../../../atoms';
import { useNodeDataToSave } from '../hooks/useNodeDataToSave';
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledContentBox,
  StyledHelperBox,
  StyledLinkBox,
  StyledSpinnerBox,
} from './styled';

const NodeHelper = () => {
  const { t } = useTranslation();
  const [nodeDataToSave] = useNodeDataToSave();
  const dataType = nodeDataToSave?.selectedDataType || nodeDataToSave?.dataType || null;
  const connectionId = nodeDataToSave?.connectionId || null;
  let typename = dataType ? `${nodeDataToSave?.__typename}.${dataType}` : nodeDataToSave?.__typename;
  if (connectionId) {
    typename = `${nodeDataToSave?.__typename}.${connectionId}`;
  }
  const { loading, data } = useQuery(GET_NODE_HELPER, {
    variables: {
      nodeId: typename,
    },
    skip: !typename,
  });
  const nodeHelperData = data?.getNodeHelper;
  const [expanded, setExpanded] = useState(false);
  const [helperObj, setHelperObj] = useState({});

  useEffect(() => {
    const helper = localStorageService.getItem(STORAGE_KEYS.noCodeEditorHelper);
    setHelperObj(helper);
    if (has(helper, typename)) {
      setExpanded(!!helper[typename]);
    } else {
      setExpanded(false);
    }
  }, [typename]);

  const onChangeExpanded = () => {
    localStorageService.setItem('noCodeEditorHelper', { ...helperObj, [typename]: !expanded });
    setExpanded(!expanded);
  };

  return (
    <StyledAccordion expanded={expanded} onChange={onChangeExpanded} $expanded={expanded}>
      <StyledAccordionSummary
        $expanded={expanded}
        expandIcon={<ExpandMoreIcon color={expanded ? 'action' : 'primary'} />}
        aria-controls="helper-field-content"
        id="helper-field-content"
      >
        {t('Help')}
      </StyledAccordionSummary>
      <StyledHelperBox>
        {loading ? (
          <StyledSpinnerBox>
            <CircularProgress size={24} color="inherit" />
          </StyledSpinnerBox>
        ) : (
          <>
            <L14M color={THEME.greyColors.grey17} margin="0 0 10px 0">
              {nodeHelperData?.title}
            </L14M>
            <StyledContentBox>
              <ReactMarkdown>{nodeHelperData?.content}</ReactMarkdown>
            </StyledContentBox>
            {nodeHelperData?.docsUrls?.map(({ url, label }) => (
              <StyledLinkBox key={label}>
                <ExternalLink href={url} target="_blank" noUnderline fontSize="14px" color={THEME.greyColors.grey11}>
                  <L14M color={THEME.primaryColors.primary}>
                    {label}
                    <Box position="relative" top="3px" display="inline-block">
                      <ArrowUpRight />
                    </Box>
                  </L14M>
                </ExternalLink>
              </StyledLinkBox>
            ))}
          </>
        )}
      </StyledHelperBox>
    </StyledAccordion>
  );
};

export default NodeHelper;
