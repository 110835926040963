import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import THEME from '../../../../../../../constants/theme';

export const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    border: none !important;
    box-shadow: none !important;
    margin: 0 !important;
    background-color: ${THEME.secondaryColors.nodeError};
    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.15) !important;
    }
    & .MuiAccordionDetails-root {
      color: rgba(255, 255, 255, 0.8);
      padding: 0 20px 20px 20px;
    }
    &.MuiAccordion-rounded:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    &.MuiAccordion-rounded:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    & .MuiCollapse-root {
      & .MuiCollapse-wrapper {
        padding: 0 24px 24px 24px;
      }
    }
    &.Mui-expanded {
      &:first-child {
        margin-top: 10px;
      }
    }
    & .MuiSvgIcon-root {
      color: ${THEME.primaryColors.white};
    }
    &:before {
      height: 0;
    }
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    min-height: 58px;
    padding: 0 20px;
    font-weight: 500;
    &.Mui-expanded {
      min-height: 58px;
    }
    & .MuiAccordionSummary-content {
      margin: 0;
      color: ${THEME.primaryColors.white};
    }
  }
`;

export const StyledBox = styled(Box)`
  display: flex;
  align-items: self-start;
  flex-direction: column;
  p {
    margin-top: 8px;
    &:first-child {
      margin-top: 0;
    }
  }
`;
