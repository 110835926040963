import React from 'react';

const Bell = () => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.66663 12.3333H7.33329C7.33329 12.687 7.19282 13.0261 6.94277 13.2761C6.69272 13.5262 6.35358 13.6667 5.99996 13.6667C5.64634 13.6667 5.3072 13.5262 5.05715 13.2761C4.8071 13.0261 4.66663 12.687 4.66663 12.3333ZM11.14 9.47333L10.5866 8.92C10.2114 8.54529 10.0004 8.03692 9.99996 7.50667V5.66667C9.99996 4.80118 9.71925 3.95905 9.19996 3.26667L8.59996 2.46667C8.41366 2.21827 8.1721 2.01667 7.89439 1.87781C7.61667 1.73896 7.31045 1.66667 6.99996 1.66667H6.66663V0.666665C6.66663 0.57826 6.63151 0.493475 6.56899 0.430963C6.50648 0.368451 6.4217 0.333332 6.33329 0.333332H5.66663C5.57822 0.333332 5.49344 0.368451 5.43092 0.430963C5.36841 0.493475 5.33329 0.57826 5.33329 0.666665V1.66667H4.99996C4.68947 1.66667 4.38324 1.73896 4.10553 1.87781C3.82782 2.01667 3.58625 2.21827 3.39996 2.46667L2.79996 3.26667C2.28067 3.95905 1.99996 4.80118 1.99996 5.66667V7.50667C1.99949 8.03692 1.78847 8.54529 1.41329 8.92L0.859959 9.47333C0.736797 9.59751 0.667362 9.76511 0.666626 9.94V10.3333C0.666626 10.5101 0.736864 10.6797 0.861888 10.8047C0.986912 10.9298 1.15648 11 1.33329 11H10.6666C10.8434 11 11.013 10.9298 11.138 10.8047C11.2631 10.6797 11.3333 10.5101 11.3333 10.3333V9.94C11.3326 9.76511 11.2631 9.59751 11.14 9.47333Z"
      fill="white"
    />
  </svg>
);
export default Bell;
