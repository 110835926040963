import * as Yup from 'yup';
import buildValidator from '../../../utils/validators/buildValidator';
import { formNames } from './schema';

const arrayValidationSchema = (requiredMessage, minMessage) =>
  Yup.array()
    .of(Yup.string())
    .min(1, minMessage)
    .required(requiredMessage);

const validator = Yup.object().shape({
  [formNames.companyMarkets]: arrayValidationSchema('Market is required', 'Market is required'),
});

export default buildValidator(validator);
