import React from 'react';
import PropTypes from 'prop-types';

const List = ({ size = 20 }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9 6.3H7.29999V5.7H11.9V6.3ZM11.9 8.7H7.29999V8.1H11.9V8.7ZM11.9 11.1H7.29999V10.5H11.9V11.1ZM9.49999 13.5H7.29999V12.9H9.49999V13.5ZM4.79962 16.1C4.93134 16.4159 5.18412 16.6686 5.49999 16.8004V17.4335C4.85253 17.2574 4.34261 16.7475 4.16646 16.1H4.79962ZM13.5 17.5H6.49999V16.9H13.5V17.5ZM4.09999 15.1V4.9H4.69999V15.1H4.09999ZM15.2004 3.9C15.0686 3.58413 14.8159 3.33136 14.5 3.19963V2.56647C15.1475 2.74261 15.6574 3.25254 15.8335 3.9H15.2004ZM15.3 4.9H15.9V15.1H15.3V4.9ZM15.2004 16.1H15.8335C15.6574 16.7475 15.1475 17.2574 14.5 17.4335V16.8004C14.8159 16.6686 15.0686 16.4159 15.2004 16.1ZM13.5 3.1H6.49999V2.5H13.5V3.1ZM5.49999 3.19963C5.18412 3.33136 4.93135 3.58413 4.79962 3.9H4.16647C4.34261 3.25255 4.85254 2.74262 5.49999 2.56648V3.19963Z"
      fill="#787F88"
      stroke="white"
    />
  </svg>
);

List.propTypes = {
  size: PropTypes.number,
};

List.defaultProps = {
  size: 20,
};

export default List;
