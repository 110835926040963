import React from 'react';
import PropTypes from 'prop-types';
import THEME from '../../constants/theme';

const Chat = ({ size, color }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="chat">
    <g clipPath="url(#clip0)">
      <path
        d="M6 7.5998C6 7.15798 6.35818 6.7998 6.8 6.7998H13.2C13.6418 6.7998 14 7.15798 14 7.5998C14 8.04164 13.6418 8.3998 13.2 8.3998H6.8C6.35818 8.3998 6 8.04164 6 7.5998Z"
        fill={color}
      />
      <path
        d="M6.8 10C6.35818 10 6 10.3582 6 10.8C6 11.2418 6.35818 11.6 6.8 11.6H10C10.4418 11.6 10.8 11.2418 10.8 10.8C10.8 10.3582 10.4418 10 10 10H6.8Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.69943 3.52145C4.02469 2.01546 6.31137 1.19995 10 1.19995C13.6886 1.19995 15.9753 2.01546 17.3006 3.52145C18.611 5.01061 18.8 6.98224 18.8 8.79995C18.8 10.6218 18.6061 12.5245 17.6738 13.9812C16.7433 15.4352 15.1768 16.3045 12.8055 16.3925L11.28 18.4266C10.64 19.28 9.36 19.28 8.72 18.4266L7.19445 16.3925C4.82322 16.3045 3.25673 15.4352 2.32618 13.9812C1.39392 12.5245 1.2 10.6218 1.2 8.79995C1.2 6.98224 1.38897 5.01061 2.69943 3.52145ZM3.90056 4.57846C3.01102 5.5893 2.8 7.01766 2.8 8.79995C2.8 10.5781 3.00607 12.0754 3.67381 13.1187C4.2912 14.0834 5.39951 14.8 7.6 14.8C7.85181 14.8 8.08892 14.9185 8.24 15.12L10 17.4666L11.76 15.12C11.9111 14.9185 12.1482 14.8 12.4 14.8C14.6005 14.8 15.7088 14.0834 16.3262 13.1187C16.9939 12.0754 17.2 10.5781 17.2 8.79995C17.2 7.01766 16.989 5.5893 16.0994 4.57846C15.2247 3.58444 13.5114 2.79995 10 2.79995C6.48863 2.79995 4.77529 3.58444 3.90056 4.57846Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="19.2" height="19.2" fill="white" transform="translate(0.399994 0.399902)" />
      </clipPath>
    </defs>
  </svg>
);

Chat.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

Chat.defaultProps = {
  size: 20,
  color: THEME.primaryColors.white,
};

export default Chat;
