import { gql } from '@apollo/client';
import { PROPERTY_ITEM, PROPERTY_SUBGROUP_RECURSIVE } from '../../fragments/publicFragments';

export const GQL_Q_GET_AVAILABLE_PROPERTIES = gql`
  query GetAvailableProperties($context: PropertyContextInput!, $matchingTypes: [DataType]) {
    getAvailableProperties(context: $context, matchingTypes: $matchingTypes) {
      group
      properties {
        key
        label
        type
      }
    }
  }
`;

export const GQL_Q_GET_AVAILABLE_PROPERTIES_NEW = gql`
  query getAvailablePropertiesNew(
    $context: PropertyContextInput!
    $matchingTypes: [DataType]
    $parentDataType: DataType
  ) {
    getAvailablePropertiesNew(context: $context, matchingTypes: $matchingTypes, parentDataType: $parentDataType) {
      items {
        ...propertyItem
      }
      groups {
        label
        subLabel
        stepGroup
        type
        description
        items {
          ...propertyItem
        }
        icon {
          key
          url
        }
        subgroups {
          ...propertySubgroupRecursive
        }
      }
    }
  }
  ${PROPERTY_ITEM}
  ${PROPERTY_SUBGROUP_RECURSIVE}
`;

export const GET_FILTER_VALUES = gql`
  query getFilterValues($type: DataType!, $property: String!) {
    getFilterValues(type: $type, property: $property)
  }
`;

export const GET_FILTER_FIELDS = gql`
  query getFilterFields($type: DataType!) {
    getFilterFields(type: $type) {
      key
      label
      type
      canProvideUniqueRecord
      possibleValues
    }
  }
`;

export const GET_AVAILABLE_DATA_PROVIDERS = gql`
  query getAvailableDataProviders {
    getAvailableDataProviders {
      type
      displayName
      supportedSearches
      logo
    }
  }
`;

export const GET_PROJECTIONS_FOR = gql`
  query getProjectionsFor($type: DataType!, $property: String) {
    getProjectionsFor(type: $type, property: $property)
  }
`;

export const GET_SINGLE_RECORD_UNIQUENESS_OPERATOR = gql`
  query getSingleRecordUniquenessOperators($type: DataType!, $connectionId: String, $subType: String) {
    getSingleRecordUniquenessOperators(type: $type, connectionId: $connectionId, subType: $subType) {
      key
      label
    }
  }
`;

export const GET_EXAMPLE_SCHEMA_FOR_TYPE = gql`
  query getExampleSchemaForType($type: DataType!) {
    getExampleSchemaForType(type: $type) {
      label
      key
    }
  }
`;
