import React from 'react';

const ListIconNew = () => {
  return (
    <svg width="147" height="115" viewBox="0 0 147 115" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_3996_110173)">
        <rect
          width="91.4456"
          height="27.7603"
          rx="6"
          transform="matrix(0.866044 -0.499967 0.866044 0.499967 42 92.7197)"
          fill="white"
        />
        <rect
          x="0.519033"
          width="90.8463"
          height="27.161"
          rx="5.70034"
          transform="matrix(0.866044 -0.499967 0.866044 0.499967 42.0695 92.9792)"
          stroke="#E6E9EC"
          strokeWidth="0.599315"
        />
      </g>
      <rect
        width="33.4756"
        height="6.53183"
        rx="3.26592"
        transform="matrix(0.866044 -0.499967 0.866044 0.499967 75.2339 84.1472)"
        fill="#E6E9EC"
      />
      <path
        d="M130.405 61.2774L119.747 67.4302C119.194 67.7497 118.466 67.9428 117.725 67.9669C116.984 67.9909 116.29 67.8441 115.796 67.5586L109.641 64.0055C109.392 63.8647 109.201 63.6935 109.076 63.5019C108.952 63.3103 108.898 63.1021 108.918 62.8892C108.937 62.6762 109.029 62.4629 109.189 62.2614C109.348 62.0599 109.572 61.8743 109.848 61.7152L120.529 55.5493C121.082 55.2311 121.809 55.0393 122.549 55.0158C123.288 54.9924 123.981 55.1392 124.475 55.4242L130.629 58.9773C130.879 59.1189 131.072 59.2911 131.196 59.4839C131.32 59.6766 131.373 59.8862 131.352 60.1003C131.331 60.3144 131.237 60.5287 131.074 60.7308C130.912 60.9329 130.684 61.1187 130.405 61.2774ZM110.867 62.3035C110.616 62.4484 110.464 62.6389 110.445 62.8329C110.426 63.027 110.542 63.2087 110.766 63.3382L116.921 66.8913C117.145 67.0208 117.46 67.0874 117.796 67.0765C118.132 67.0656 118.462 66.9781 118.713 66.8331L129.394 60.6672C129.642 60.5221 129.792 60.3321 129.81 60.1389C129.828 59.9456 129.712 59.7647 129.489 59.6357L123.349 56.0915C123.126 55.9625 122.813 55.8959 122.478 55.9062C122.143 55.9164 121.814 56.0028 121.563 56.1464L110.867 62.3035Z"
        fill="#E6E9EC"
      />
      <path
        d="M118.532 63.2847L116.884 64.2361C116.732 64.3234 116.534 64.3762 116.331 64.3827C116.129 64.3893 115.939 64.3492 115.804 64.2712C115.669 64.1932 115.599 64.0837 115.611 63.9668C115.622 63.8499 115.713 63.7352 115.865 63.6479L117.513 62.6965C117.664 62.6092 117.863 62.5564 118.065 62.5498C118.268 62.5433 118.457 62.5834 118.592 62.6614C118.728 62.7394 118.797 62.8489 118.786 62.9658C118.774 63.0827 118.683 63.1974 118.532 63.2847Z"
        fill="#E6E9EC"
      />
      <path
        d="M122.145 63.2638L118.672 65.2686C118.521 65.3559 118.322 65.4086 118.119 65.4152C117.917 65.4218 117.727 65.3817 117.592 65.3037C117.457 65.2256 117.387 65.1162 117.399 64.9993C117.41 64.8824 117.502 64.7676 117.653 64.6803L121.126 62.6755C121.277 62.5882 121.475 62.5354 121.678 62.5289C121.88 62.5223 122.07 62.5624 122.205 62.6404C122.34 62.7184 122.41 62.8279 122.399 62.9448C122.387 63.0617 122.296 63.1764 122.145 63.2638Z"
        fill="#E6E9EC"
      />
      <circle r="7.34831" transform="matrix(0.866044 -0.499967 0.866044 0.499967 66.0419 92.7197)" fill="#E6E9EC" />
      <path
        d="M64.5444 93.5841L66.807 93.5026L66.7905 90.9908"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g filter="url(#filter1_d_3996_110173)">
        <rect
          width="91.4456"
          height="27.7603"
          rx="6"
          transform="matrix(0.866044 -0.499967 0.866044 0.499967 22 70.0894)"
          fill="white"
        />
        <rect
          x="0.519033"
          width="90.8463"
          height="27.161"
          rx="5.70034"
          transform="matrix(0.866044 -0.499967 0.866044 0.499967 22.0695 70.3489)"
          stroke="#E6E9EC"
          strokeWidth="0.599315"
        />
      </g>
      <rect
        width="33.4756"
        height="6.53183"
        rx="3.26592"
        transform="matrix(0.866044 -0.499967 0.866044 0.499967 55.2339 61.5168)"
        fill="#E6E9EC"
      />
      <path
        d="M110.406 38.6471L99.7478 44.7998C99.1943 45.1194 98.467 45.3124 97.7259 45.3365C96.9848 45.3606 96.2906 45.2137 95.796 44.9282L89.6413 41.3751C89.3929 41.2343 89.2011 41.0631 89.077 40.8715C88.9528 40.68 88.8988 40.4717 88.9181 40.2588C88.9373 40.0459 89.0294 39.8325 89.1891 39.631C89.3488 39.4296 89.5729 39.2439 89.8484 39.0849L100.529 32.919C101.083 32.6007 101.809 32.4089 102.549 32.3854C103.289 32.362 103.981 32.5089 104.475 32.7939L110.63 36.347C110.88 36.4885 111.072 36.6607 111.196 36.8535C111.32 37.0463 111.373 37.2558 111.353 37.4699C111.332 37.684 111.237 37.8983 111.075 38.1004C110.912 38.3025 110.685 38.4883 110.406 38.6471ZM90.8674 39.6731C90.6163 39.8181 90.4647 40.0085 90.4457 40.2026C90.4268 40.3966 90.5422 40.5784 90.7665 40.7079L96.9212 44.261C97.1455 44.3904 97.4604 44.4571 97.7965 44.4462C98.1326 44.4352 98.4625 44.3477 98.7136 44.2027L109.394 38.0368C109.643 37.8917 109.793 37.7017 109.81 37.5085C109.828 37.3152 109.713 37.1344 109.489 37.0054L103.35 33.4611C103.127 33.3321 102.813 33.2655 102.478 33.2758C102.144 33.2861 101.815 33.3725 101.563 33.516L90.8674 39.6731Z"
        fill="#E6E9EC"
      />
      <path
        d="M98.5321 40.6546L96.8841 41.606C96.7329 41.6933 96.5342 41.746 96.3317 41.7526C96.1292 41.7592 95.9395 41.7191 95.8044 41.6411C95.6693 41.563 95.5998 41.4536 95.6112 41.3367C95.6226 41.2198 95.7139 41.105 95.8652 41.0177L97.5132 40.0663C97.6644 39.979 97.8631 39.9263 98.0656 39.9197C98.2681 39.9131 98.4578 39.9533 98.5929 40.0313C98.728 40.1093 98.7975 40.2188 98.7861 40.3357C98.7747 40.4526 98.6834 40.5673 98.5321 40.6546Z"
        fill="#E6E9EC"
      />
      <path
        d="M102.145 40.6334L98.6722 42.6382C98.521 42.7255 98.3223 42.7783 98.1198 42.7848C97.9173 42.7914 97.7276 42.7513 97.5925 42.6733C97.4574 42.5953 97.3879 42.4858 97.3993 42.3689C97.4107 42.252 97.502 42.1373 97.6532 42.0499L101.126 40.0451C101.277 39.9578 101.476 39.9051 101.678 39.8985C101.881 39.8919 102.071 39.932 102.206 40.01C102.341 40.0881 102.41 40.1975 102.399 40.3144C102.388 40.4313 102.296 40.5461 102.145 40.6334Z"
        fill="#E6E9EC"
      />
      <circle r="7.34831" transform="matrix(0.866044 -0.499967 0.866044 0.499967 46.0419 70.0894)" fill="#E6E9EC" />
      <path
        d="M44.5444 70.9538L46.807 70.8722L46.7905 68.3604"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g filter="url(#filter2_d_3996_110173)">
        <rect
          width="91.4456"
          height="27.7603"
          rx="6"
          transform="matrix(0.866044 -0.499967 0.866044 0.499967 2 46.7197)"
          fill="white"
        />
        <rect
          x="0.519033"
          width="90.8463"
          height="27.161"
          rx="5.70034"
          transform="matrix(0.866044 -0.499967 0.866044 0.499967 2.06953 46.9792)"
          stroke="#E6E9EC"
          strokeWidth="0.599315"
        />
      </g>
      <rect
        width="33.4756"
        height="6.53183"
        rx="3.26592"
        transform="matrix(0.866044 -0.499967 0.866044 0.499967 35.2339 38.1472)"
        fill="#E6E9EC"
      />
      <path
        d="M90.4056 15.2774L79.7478 21.4302C79.1943 21.7497 78.467 21.9428 77.7259 21.9669C76.9848 21.9909 76.2906 21.8441 75.796 21.5586L69.6413 18.0055C69.3929 17.8647 69.2011 17.6935 69.077 17.5019C68.9528 17.3103 68.8988 17.1021 68.9181 16.8892C68.9373 16.6762 69.0294 16.4629 69.1891 16.2614C69.3488 16.0599 69.5729 15.8743 69.8484 15.7152L80.529 9.54932C81.0829 9.23112 81.8094 9.03925 82.5491 9.01581C83.2888 8.99237 83.9815 9.13924 84.4751 9.42425L90.6298 12.9773C90.8795 13.1189 91.0721 13.2911 91.1961 13.4839C91.3202 13.6766 91.3734 13.8862 91.3525 14.1003C91.3317 14.3144 91.2372 14.5287 91.0746 14.7308C90.9121 14.9329 90.6846 15.1187 90.4056 15.2774ZM70.8674 16.3035C70.6163 16.4484 70.4647 16.6389 70.4457 16.8329C70.4268 17.027 70.5422 17.2087 70.7665 17.3382L76.9212 20.8913C77.1455 21.0208 77.4604 21.0874 77.7965 21.0765C78.1326 21.0656 78.4625 20.9781 78.7136 20.8331L89.3942 14.6672C89.6429 14.5221 89.7925 14.3321 89.8103 14.1389C89.8282 13.9456 89.7128 13.7647 89.4893 13.6357L83.3499 10.0915C83.1265 9.96249 82.8132 9.89587 82.4785 9.90616C82.1437 9.91645 81.8147 10.0028 81.5633 10.1464L70.8674 16.3035Z"
        fill="#E6E9EC"
      />
      <path
        d="M78.5317 17.2847L76.8837 18.2361C76.7324 18.3234 76.5337 18.3762 76.3312 18.3827C76.1287 18.3893 75.9391 18.3492 75.8039 18.2712C75.6688 18.1932 75.5993 18.0837 75.6107 17.9668C75.6221 17.8499 75.7134 17.7352 75.8647 17.6479L77.5127 16.6965C77.6639 16.6092 77.8626 16.5564 78.0651 16.5498C78.2676 16.5433 78.4573 16.5834 78.5924 16.6614C78.7275 16.7394 78.797 16.8489 78.7856 16.9658C78.7743 17.0827 78.6829 17.1974 78.5317 17.2847Z"
        fill="#E6E9EC"
      />
      <path
        d="M82.145 17.2638L78.6722 19.2686C78.521 19.3559 78.3223 19.4086 78.1198 19.4152C77.9173 19.4218 77.7276 19.3817 77.5925 19.3037C77.4574 19.2256 77.3879 19.1162 77.3993 18.9993C77.4107 18.8824 77.502 18.7676 77.6532 18.6803L81.126 16.6755C81.2773 16.5882 81.476 16.5354 81.6785 16.5289C81.881 16.5223 82.0706 16.5624 82.2058 16.6404C82.3409 16.7184 82.4104 16.8279 82.399 16.9448C82.3876 17.0617 82.2962 17.1764 82.145 17.2638Z"
        fill="#E6E9EC"
      />
      <circle r="7.34831" transform="matrix(0.866044 -0.499967 0.866044 0.499967 26.0414 46.7197)" fill="#6D61FF" />
      <path
        d="M24.544 47.5841L26.8065 47.5026L26.7901 44.9908"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_3996_110173"
          x="40.2494"
          y="46.3598"
          width="106.739"
          height="65.6738"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.39726" />
          <feGaussianBlur stdDeviation="2.39726" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3996_110173" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3996_110173" result="shape" />
        </filter>
        <filter
          id="filter1_d_3996_110173"
          x="20.2494"
          y="23.7294"
          width="106.739"
          height="65.6738"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.39726" />
          <feGaussianBlur stdDeviation="2.39726" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3996_110173" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3996_110173" result="shape" />
        </filter>
        <filter
          id="filter2_d_3996_110173"
          x="0.249424"
          y="0.35982"
          width="106.739"
          height="65.6738"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.39726" />
          <feGaussianBlur stdDeviation="2.39726" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3996_110173" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3996_110173" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default ListIconNew;
