import { useCallback } from 'react';
import { filterEdges, filterNodes } from '../utils/filterElements';

export const useSetElementsAndSave = ({ setNodes, setEdges, saveConfiguration }) =>
  useCallback(
    (newState, skipOnSaveComplete = false) => {
      if (typeof newState === 'function') {
        setNodes((prevState) => {
          const _newState = newState(prevState);
          const newNodes = filterNodes(_newState);
          setEdges(filterEdges(_newState));
          saveConfiguration(newNodes, skipOnSaveComplete);
          return newNodes;
        });
      } else {
        setNodes(filterNodes(newState));
        setEdges(filterEdges(newState));
        saveConfiguration(newState, skipOnSaveComplete);
      }
    },
    [saveConfiguration, setEdges, setNodes]
  );
