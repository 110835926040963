import React from 'react';
import { NotificationManager } from 'react-notifications';
import capitalize from '@mui/material/utils/capitalize';
import { useCopyToClipboard } from 'react-use';
import { StyledTableCell } from './styled';
import { PROPTYPES } from './constant';
import CopyIcon from '../../../assets/icons/Copy';
import THEME from '../../../constants/theme';
import { useNotificationManager } from '../../../hooks/useNotificationManager';
import { StyledCopyComponent } from '../../../pages/Payments/Details/_common/LongStringComponent/styled';
import ToastCustomContainer from '../../ToastCustomContainer';
import { TOAST_TIMEOUT } from '../../../constants/toasts';

const MAX_TEXT_LENGTH = 22;

const TextCellWithCopyIcon = ({ row: { original }, cell: { column } }) => {
  const header = column?.Header;
  const text = original[header];
  const customColumnMaxTextLength = column?.maxTextLength;

  const [{ error }, copyToClipboard] = useCopyToClipboard();
  useNotificationManager('error', error?.message, `${header ?? 'Copy text'}.`);

  const handleCopy = (e) => {
    e.stopPropagation();

    copyToClipboard(text);

    NotificationManager.success(
      <ToastCustomContainer
        message={capitalize(`Successfully copied ${header ?? 'text'} to clipboard.`)}
        title={capitalize(`${header ?? 'Copy text'}.`)}
      />,
      null,
      TOAST_TIMEOUT
    );
  };

  const maxLength = customColumnMaxTextLength ?? MAX_TEXT_LENGTH;
  const canCopy = text && text.length > maxLength;
  const truncatedValue = canCopy ? `${text.substring(0, maxLength)}...` : text;

  return (
    <StyledTableCell column={column} id={original?.id}>
      {canCopy ? (
        <StyledCopyComponent onClick={handleCopy}>
          {truncatedValue}
          <CopyIcon fill={THEME.greyColors.grey1} />
        </StyledCopyComponent>
      ) : (
        text
      )}
    </StyledTableCell>
  );
};
TextCellWithCopyIcon.propTypes = PROPTYPES;
export default TextCellWithCopyIcon;
