import { v4 } from 'uuid';
import {
  AmountCell,
  DateCell,
  ProcessorCell,
  StatusCell,
  TextCell,
  TextUppercaseCell,
  MethodCell,
} from '../../../components/table/ReactTableCells';
import { PAYMENT_FIELDS } from '../../../constants/paymentFields';

const CELL_SETTINGS = {
  numeric: false,
  disablePadding: false,
};

const WIDTH = 150;
const MD_WIDTH = 200;
const CUSTOM_MAX_COPY_TEXT_LENGTH = 15;

const headCells = [
  {
    accessor: PAYMENT_FIELDS.amount,
    disablePadding: false,
    Header: PAYMENT_FIELDS.amount,
    Cell: AmountCell,
    width: WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.currency,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.currency,
    Cell: TextUppercaseCell,
    width: WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.status,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.status,
    Cell: StatusCell,
    width: MD_WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.method,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.method,
    Cell: MethodCell,
    width: MD_WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.customer,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.customer,
    Cell: TextCell,
    width: MD_WIDTH,
    maxTextLength: CUSTOM_MAX_COPY_TEXT_LENGTH,
  },
  {
    accessor: PAYMENT_FIELDS.processor,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.processor,
    Cell: ProcessorCell,
    width: MD_WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.date,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.date,
    Cell: DateCell,
    width: MD_WIDTH,
  },
  /**Extra Data Starts here */ {
    accessor: PAYMENT_FIELDS.country,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.country,
    Cell: TextCell,
    width: WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.id,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.id,
    width: MD_WIDTH,
    Cell: TextCell,
    maxTextLength: CUSTOM_MAX_COPY_TEXT_LENGTH,
  },
  {
    accessor: PAYMENT_FIELDS.sourceId,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.sourceId,
    width: MD_WIDTH,
    Cell: TextCell,
    maxTextLength: CUSTOM_MAX_COPY_TEXT_LENGTH,
  },
  {
    accessor: PAYMENT_FIELDS.paymentServiceId,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.paymentServiceId,
    width: MD_WIDTH,
    Cell: TextCell,
    maxTextLength: 12,
  },
  {
    accessor: PAYMENT_FIELDS.corePaymentMethod,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.corePaymentMethod,
    Cell: TextCell,
    width: WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.phone,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.phone,
    Cell: TextCell,
    width: WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.net,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.net,
    Cell: TextCell,
    width: WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.fee,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.fee,
    Cell: TextCell,
    width: WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.processingFee,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.processingFee,
    Cell: TextCell,
    width: WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.tax,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.tax,
    Cell: TextCell,
    width: WIDTH,
  },

  {
    accessor: PAYMENT_FIELDS.description,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.description,
    width: MD_WIDTH,
    Cell: TextCell,
    maxTextLength: CUSTOM_MAX_COPY_TEXT_LENGTH,
  },

  {
    accessor: PAYMENT_FIELDS.statementDescriptor,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.statementDescriptor,
    width: MD_WIDTH,
    Cell: TextCell,
    maxTextLength: CUSTOM_MAX_COPY_TEXT_LENGTH,
  },
  {
    accessor: PAYMENT_FIELDS.exchangeRate,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.exchangeRate,
    Cell: TextCell,
    width: WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.localCurrency,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.localCurrency,
    Cell: TextCell,
    width: WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.localAmount,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.localAmount,
    Cell: TextCell,
    width: WIDTH,
  },
  // {
  //   accessor: COLUMN_NAMES.customerId,
  //   ...CELL_SETTINGS,
  //   Header: COLUMN_NAMES.customerId,
  //   Cell: TextCell,
  //   width: WIDTH,
  // },
  {
    accessor: PAYMENT_FIELDS.receiptDate,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.receiptDate,
    Cell: TextCell,
    width: MD_WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.receiptType,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.receiptType,
    Cell: TextCell,
    width: WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.receiptMessage,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.receiptMessage,
    Cell: TextCell,
    width: WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.receiptUrl,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.receiptUrl,
    width: MD_WIDTH,
    Cell: TextCell,
    maxTextLength: CUSTOM_MAX_COPY_TEXT_LENGTH,
  },
  {
    accessor: PAYMENT_FIELDS.reason,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.reason,
    Cell: TextCell,
    width: WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.riskLevel,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.riskLevel,
    Cell: TextCell,
    width: WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.riskScore,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.riskScore,
    Cell: TextCell,
    width: WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.declineCode,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.declineCode,
    Cell: TextCell,
    width: WIDTH,
  },

  {
    accessor: PAYMENT_FIELDS.latestCharge,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.latestCharge,
    Cell: DateCell,
    width: WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.invoice,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.invoice,
    Cell: TextCell,
    width: WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.payout,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.payout,
    Cell: TextCell,
    width: WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.cardType,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.cardType,
    Cell: TextCell,
    width: WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.cardProduct,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.cardProduct,
    Cell: TextCell,
    width: WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.cardIssuingBank,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.cardIssuingBank,
    Cell: TextCell,
    width: WIDTH,
  },
  {
    accessor: PAYMENT_FIELDS.category,
    ...CELL_SETTINGS,
    Header: PAYMENT_FIELDS.category,
    Cell: TextCell,
    width: WIDTH,
  },
];

const transformNodeToRow = (node) => ({
  id: node?.id ?? v4(),
  [PAYMENT_FIELDS.id]: node?.id ?? v4(),
  [PAYMENT_FIELDS.amount]: node?.amount?.formattedAmount ?? 'N/A',
  [PAYMENT_FIELDS.currency]: node?.currency ?? 'N/A',
  [PAYMENT_FIELDS.status]: {
    status: node?.status,
    processorStatus: node?.paymentCore?.processorStatus,
  },
  [PAYMENT_FIELDS.method]: node?.paymentMethodDetails?.paymentMethod ?? 'N/A',
  [PAYMENT_FIELDS.customer]: node?.paymentCustomer?.email ?? 'N/A',
  [PAYMENT_FIELDS.processor]: {
    name: node?.paymentGateway?.name ?? 'N/A',
    logo: node?.paymentGateway?.logo ?? 'N/A',
  },
  [PAYMENT_FIELDS.date]: node?.date ?? 'N/A',
  /**Extra Data Starts here */
  [PAYMENT_FIELDS.country]: node?.country ?? 'N/A',
  [PAYMENT_FIELDS.sourceId]: node?.sourceId ?? 'N/A',
  [PAYMENT_FIELDS.net]: node?.paymentDetails?.net?.formattedAmount ?? 'N/A',
  [PAYMENT_FIELDS.fee]: node?.paymentDetails?.fee?.formattedAmount ?? 'N/A',
  [PAYMENT_FIELDS.processingFee]: node?.paymentDetails?.processingFee?.formattedAmount ?? 'N/A',
  [PAYMENT_FIELDS.tax]: node?.paymentDetails?.tax?.formattedAmount ?? 'N/A',
  [PAYMENT_FIELDS.exchangeRate]: node?.paymentDetails?.exchangeRate ?? 'N/A',
  [PAYMENT_FIELDS.localCurrency]: node?.paymentDetails?.localCurrency ?? 'N/A',
  [PAYMENT_FIELDS.statementDescriptor]: node?.paymentDetails?.statementDescriptor ?? 'N/A',
  [PAYMENT_FIELDS.description]: node?.paymentDetails?.description ?? 'N/A',
  [PAYMENT_FIELDS.localAmount]: node?.paymentDetails?.localAmount?.formattedAmount ?? 'N/A',
  [PAYMENT_FIELDS.customerId]: node?.paymentCustomer?.id ?? 'N/A',
  [PAYMENT_FIELDS.phone]: node?.paymentCustomer?.phone ?? 'N/A',
  [PAYMENT_FIELDS.receiptDate]: node?.paymentReceipt?.date ?? 'N/A',
  [PAYMENT_FIELDS.receiptMessage]: node?.paymentReceipt?.message ?? 'N/A',
  [PAYMENT_FIELDS.receiptType]: node?.paymentReceipt?.type ?? 'N/A',
  [PAYMENT_FIELDS.receiptUrl]: node?.paymentReceipt?.receiptUrl ?? 'N/A',
  [PAYMENT_FIELDS.networkStatus]: node?.paymentCore?.outcome?.networkStatus ?? 'N/A',
  [PAYMENT_FIELDS.corePaymentMethod]: node?.paymentCore?.paymentMethod ?? 'N/A',
  [PAYMENT_FIELDS.reason]: node?.paymentCore?.outcome?.reason ?? 'N/A',
  [PAYMENT_FIELDS.riskLevel]: node?.paymentCore?.outcome?.riskLevel ?? 'N/A',
  [PAYMENT_FIELDS.riskScore]: node?.paymentCore?.outcome?.riskScore ?? 'N/A',
  [PAYMENT_FIELDS.declineCode]: node?.paymentCore?.outcome?.declineCode ?? 'N/A',
  [PAYMENT_FIELDS.latestCharge]: node?.paymentConnections?.latestCharge ?? 'N/A',
  [PAYMENT_FIELDS.invoice]: node?.paymentConnections?.invoice ?? 'N/A',
  [PAYMENT_FIELDS.payout]: node?.paymentConnections?.payout ?? 'N/A',
  [PAYMENT_FIELDS.paymentServiceId]: node?.paymentGateway?.id ?? 'N/A',
  [PAYMENT_FIELDS.cardType]: node?.paymentMethodDetails?.type ?? 'N/A',
  [PAYMENT_FIELDS.cardProduct]: node?.paymentMethodDetails?.product ?? 'N/A',
  [PAYMENT_FIELDS.cardIssuingBank]: node?.paymentMethodDetails?.issuingBank ?? 'N/A',
  [PAYMENT_FIELDS.category]: node?.paymentMethodDetails?.category ?? 'N/A',
});

const transformPaymentToRow = (payment) => ({
  id: payment.id,
  [PAYMENT_FIELDS.amount]: payment.amount?.formattedAmount ?? 'N/A',
  [PAYMENT_FIELDS.currency]: payment.currency ?? 'N/A',
  [PAYMENT_FIELDS.status]: {
    status: payment.status ?? 'N/A',
    processorStatus: payment.paymentCore?.processorStatus,
  },
  [PAYMENT_FIELDS.method]: payment.paymentCore?.paymentMethod ?? 'N/A',
  [PAYMENT_FIELDS.customer]: payment.paymentCustomer?.email ?? 'N/A',
  [PAYMENT_FIELDS.processor]: {
    name: payment.paymentGateway?.name ?? 'N/A',
    logo: payment.paymentGateway?.logo ?? 'N/A',
  },
  [PAYMENT_FIELDS.date]: payment.date ?? 'N/A',
  /**Extra Data Starts here */
  [PAYMENT_FIELDS.country]: payment?.country ?? 'N/A',
  [PAYMENT_FIELDS.sourceId]: payment?.sourceId ?? 'N/A',
  [PAYMENT_FIELDS.net]: payment?.paymentDetails?.net?.formattedAmount ?? 'N/A',
  [PAYMENT_FIELDS.fee]: payment?.paymentDetails?.fee?.formattedAmount ?? 'N/A',
  [PAYMENT_FIELDS.processingFee]: payment?.paymentDetails?.processingFee?.formattedAmount ?? 'N/A',
  [PAYMENT_FIELDS.tax]: payment?.paymentDetails?.tax?.formattedAmount ?? 'N/A',
  [PAYMENT_FIELDS.exchangeRate]: payment?.paymentDetails?.exchangeRate ?? 'N/A',
  [PAYMENT_FIELDS.localCurrency]: payment?.paymentDetails?.localCurrency ?? 'N/A',
  [PAYMENT_FIELDS.statementDescriptor]: payment?.paymentDetails?.statementDescriptor ?? 'N/A',
  [PAYMENT_FIELDS.description]: payment?.paymentDetails?.description ?? 'N/A',
  [PAYMENT_FIELDS.localAmount]: payment?.paymentDetails?.localAmount?.formattedAmount ?? 'N/A',
  [PAYMENT_FIELDS.customerId]: payment?.paymentCustomer?.id ?? 'N/A',
  [PAYMENT_FIELDS.phone]: payment?.paymentCustomer?.phone ?? 'N/A',
  [PAYMENT_FIELDS.receiptDate]: payment?.paymentReceipt?.date ?? 'N/A',
  [PAYMENT_FIELDS.receiptMessage]: payment?.paymentReceipt?.message ?? 'N/A',
  [PAYMENT_FIELDS.receiptType]: payment?.paymentReceipt?.type ?? 'N/A',
  [PAYMENT_FIELDS.receiptUrl]: payment?.paymentReceipt?.receiptUrl ?? 'N/A',
  [PAYMENT_FIELDS.networkStatus]: payment?.paymentCore?.outcome?.networkStatus ?? 'N/A',
  [PAYMENT_FIELDS.corePaymentMethod]: payment?.paymentCore?.paymentMethod ?? 'N/A',
  [PAYMENT_FIELDS.reason]: payment?.paymentCore?.outcome?.reason ?? 'N/A',
  [PAYMENT_FIELDS.riskLevel]: payment?.paymentCore?.outcome?.riskLevel ?? 'N/A',
  [PAYMENT_FIELDS.riskScore]: payment?.paymentCore?.outcome?.riskScore ?? 'N/A',
  [PAYMENT_FIELDS.declineCode]: payment?.paymentCore?.outcome?.declineCode ?? 'N/A',
  [PAYMENT_FIELDS.latestCharge]: payment?.paymentConnections?.latestCharge ?? 'N/A',
  [PAYMENT_FIELDS.invoice]: payment?.paymentConnections?.invoice ?? 'N/A',
  [PAYMENT_FIELDS.payout]: payment?.paymentConnections?.payout ?? 'N/A',
  [PAYMENT_FIELDS.paymentServiceId]: payment?.paymentGateway?.id ?? 'N/A',
  [PAYMENT_FIELDS.cardType]: payment?.paymentMethodDetails?.type ?? 'N/A',
  [PAYMENT_FIELDS.cardProduct]: payment?.paymentMethodDetails?.product ?? 'N/A',
  [PAYMENT_FIELDS.cardIssuingBank]: payment?.paymentMethodDetails?.issuingBank ?? 'N/A',
  [PAYMENT_FIELDS.category]: payment?.paymentMethodDetails?.category ?? 'N/A',
});

export { transformNodeToRow, transformPaymentToRow, headCells };
