import { Edge } from '../components/Edge/Edge';
import { EdgeWithLabel } from '../components/Edge/EdgeWithLabel';
import { EdgeWithButton } from '../components/Edge/EdgeWithButton';
import { EdgeWithIfElseButton } from '../components/Edge/EdgeWithIfElseButton';
import { EdgeWithRetryButton } from '../components/Edge/EdgeWithRetryButton';
import { EdgeWithCondition } from '../components/Edge/EdgeWithCondition';

const edgeTypes = {
  default: Edge,
  edgeWithLabel: EdgeWithLabel,
  edgeWithButton: EdgeWithButton,
  edgeWithIfElseButton: EdgeWithIfElseButton,
  edgeWithRetryButton: EdgeWithRetryButton,
  edgeWithCondition: EdgeWithCondition,
};

export { edgeTypes };
