import React from 'react';
import PropTypes from 'prop-types';
import CopyToClipboardComponent from './CopyToClipboardComponent';
import { MAX_STRING_LENGTH } from './constant';

const LongStringComponent = ({ dataObj }) => {
  const value = dataObj?.value || 'N/A';
  const showCopyComponent = value.length > MAX_STRING_LENGTH;

  return showCopyComponent ? <CopyToClipboardComponent value={value} label={dataObj.label} /> : <span>{value}</span>;
};

LongStringComponent.propTypes = {
  dataObj: PropTypes.shape({
    value: PropTypes.string,
    key: PropTypes.string,
    label: PropTypes.string,
  }),
};
LongStringComponent.defaultProps = {
  dataObj: {},
};
export default LongStringComponent;
