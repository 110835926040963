import React from 'react';
import { number, string } from 'prop-types';
import THEME from '../../constants/theme';

const Pencil = ({ size, color }) => (
  <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6667 26.6667C14.6667 25.9303 15.2636 25.3333 16 25.3333H28C28.7364 25.3333 29.3333 25.9303 29.3333 26.6667C29.3333 27.403 28.7364 28 28 28H16C15.2636 28 14.6667 27.403 14.6667 26.6667Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 5.17158C23.6035 5.17158 23.2232 5.3291 22.9428 5.60948L6.53728 22.015L5.83249 24.8342L8.65167 24.1294L25.0572 7.72386C25.196 7.58503 25.3062 7.42021 25.3813 7.23882C25.4564 7.05743 25.4951 6.86301 25.4951 6.66667C25.4951 6.47034 25.4564 6.27592 25.3813 6.09453C25.3062 5.91313 25.196 5.74832 25.0572 5.60948C24.9184 5.47065 24.7535 5.36052 24.5721 5.28539C24.3908 5.21025 24.1963 5.17158 24 5.17158ZM21.0572 3.72386C21.8377 2.94338 22.8962 2.50491 24 2.50491C24.5465 2.50491 25.0877 2.61256 25.5926 2.82171C26.0976 3.03086 26.5564 3.33741 26.9428 3.72386C27.3293 4.11032 27.6358 4.56911 27.845 5.07404C28.0541 5.57896 28.1618 6.12014 28.1618 6.66667C28.1618 7.2132 28.0541 7.75438 27.845 8.25931C27.6358 8.76424 27.3293 9.22303 26.9428 9.60948L10.2761 26.2762C10.1053 26.447 9.89116 26.5683 9.65671 26.6269L4.32338 27.9602C3.86901 28.0738 3.38836 27.9407 3.05719 27.6095C2.72602 27.2783 2.59288 26.7977 2.70648 26.3433L4.03981 21.01C4.09842 20.7755 4.21964 20.5614 4.39052 20.3905L21.0572 3.72386Z"
      fill={color}
    />
  </svg>
);

Pencil.propTypes = {
  size: number,
  color: string,
};

Pencil.defaultProps = {
  size: 32,
  color: THEME.primaryColors.blackMain,
};

export default Pencil;
