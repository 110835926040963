import { confirmDetailsNames } from '../../utils/schemas/confirmDetailsSchema';
import { OTHERS_VALUE } from '../../components/forms/SignUpCompanyDetailsForm/consts';
import { REG_METHOD, ONBOARDING_STATUS, SIGN_UP_REG_METHOD_MAP } from '../../constants/registration';

/**
 *
 * @param {<Object>} data
 * @returns {Boolean}
 *
 * Validation of data is handled by the ConfirmDetailsForm
 */
const checkIsMeetCriteria = (values) => {
  const paymentProcessorValue = values?.[confirmDetailsNames.paymentProcessors];

  const isFailedCriteria = paymentProcessorValue?.length === 1 && paymentProcessorValue?.[0] === OTHERS_VALUE;

  return !isFailedCriteria;
};

/**
 * Validation of values is handled by the ConfirmDetailsForm
 */

const transformSignUpBetaValues = (values) => {
  // eslint-disable-next-line prettier/prettier
  const { name, lastName, companyEmail, companyRole, acceptedTC, promoCode } = values?.[
    ONBOARDING_STATUS.personalDetails
  ];

  const {
    companyName,
    companySize,
    currency,
    acceptingPayments,
    companyIndustry,
    companyIndustryOther,
    companyMarkets,
  } = values?.[ONBOARDING_STATUS.companyDetails];

  const {
    paymentModel,
    paymentModelOther,
    transactionsAmount,
    paymentProcessor,
    paymentProcessors,
    paymentProcessorsOther,
    acceptedNewsletter,
    productsServices,
    productsServicesOther,
  } = values?.[ONBOARDING_STATUS.paymentDetails];

  const registrationMethod = values.googleToken ? REG_METHOD.google : REG_METHOD.email;

  const companyMarketsEnums = companyMarkets.map((market) =>
    market === 'MIDDLE_EAST_/_NORTH_AFRICA' ? 'MIDDLE_EAST' : market
  );

  const transformedData = {
    // Personal
    firstName: name,
    lastName: lastName,
    email: companyEmail,
    companyRole,
    promoCode: promoCode || '',

    // Company
    companyName,
    companySize,
    companyIndustry,
    otherCompanyIndustry: companyIndustryOther || '',
    companyMarkets: companyMarketsEnums,
    currency,
    acceptingPayments,

    // Payment
    paymentModel,
    otherPaymentModel: paymentModelOther || '',
    transactionCount: transactionsAmount || '',
    chosePaymentProcessor: paymentProcessor || 'NO',
    paymentProcessors: paymentProcessors || [],
    otherPaymentProcessors: paymentProcessorsOther || '',
    registrationMethod: SIGN_UP_REG_METHOD_MAP[registrationMethod],
    productServiceTypes: productsServices,
    otherProductServiceTypes: productsServicesOther || '',
    acceptedTC,
    acceptedNewsletter: acceptedNewsletter || false,
  };

  // console.log('%c# transformedData', 'color: lime');
  // console.log(transformedData);
  return transformedData;
};

export { checkIsMeetCriteria, transformSignUpBetaValues };
