import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import capitalize from '@mui/material/utils/capitalize';
import { P16B, P14 } from '../../../../components/atoms';
import THEME from '../../../../constants/theme';
import { StyledTable } from '../../../../components/GridSystem/styled';
import { isDefined } from '../../../../utils/helpers';
import { PAYMENTS_DETAILS_LABEL_MAP } from './constant';
import { StyledLink } from '../../../Payments/Details/sections/Summary/styled';
import { PAYMENT_DESCRIBED_KEYS } from '../../../Payments/Details/paymentDescribedDataKeys';

const STRINGS_TO_IGNORE = ['OBJECT', '{}'];
const transformUiLabel = (str) => {
  if (typeof str !== 'string') return str;

  return STRINGS_TO_IGNORE.includes(str) ? 'N/A' : str;
};

const generateSectionId = (str) => {
  if (typeof str !== 'string') return '';
  return str
    .toLowerCase()
    .split(' ')
    .join('-');
};

const TableSection = ({ header, data, dataKey, customComponentsMap, hideLabelKeys }) => {
  const { t } = useTranslation();
  const hasObjectValues = !isEmpty(data.value);
  const sectionId = generateSectionId(header);
  const sectionRef = useRef(null);

  const vaultedCardValue = data?.value?.find((obj) => obj.type === 'CORE_CARD')?.value;

  useEffect(() => {
    if (window.location.hash === `#${sectionId}`) {
      sectionRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [sectionId]);

  return (
    <Box component="section" mt="24px" id={sectionId} ref={sectionRef}>
      <P16B>{t(header)}</P16B>

      {!hasObjectValues && (
        <StyledTable>
          {dataKey.map((key) => (
            <div key={key}>
              <P14 color={THEME.greyColors.grey1}>{capitalize(key || '')}</P14>
              <P14>{isDefined(data[key]) ? transformUiLabel(data[key]) : <span>N/A</span>}</P14>
            </div>
          ))}
        </StyledTable>
      )}

      {hasObjectValues && (
        <StyledTable>
          {dataKey.map((key) => {
            const line = data.value.find((item) => item.key === key);
            const Component = customComponentsMap[key];

            const isCardNumber = line?.key === PAYMENT_DESCRIBED_KEYS.number;

            if (line) {
              return (
                <div key={key}>
                  {!hideLabelKeys.includes(key) && (
                    <P14 minWidth="120px" color={THEME.greyColors.grey1}>
                      {PAYMENTS_DETAILS_LABEL_MAP[key] ?? capitalize(line.label || '')}
                    </P14>
                  )}

                  {Component && <Component dataObj={line} />}

                  {!Component && !isCardNumber && (
                    <P14 padding="4px 0 4px 0" textAlign="right">
                      {isDefined(line.value) ? (
                        transformUiLabel(line.value)?.replace?.('**** **** ****', t('ending in'))
                      ) : (
                        <span>N/A</span>
                      )}
                    </P14>
                  )}

                  {!Component && isCardNumber && (
                    <Box
                      component={vaultedCardValue ? StyledLink : 'span'}
                      {...(vaultedCardValue ? { href: `/data-tables/cards/details/${vaultedCardValue}` } : null)}
                    >
                      {t(isDefined(line.value) ? `ending in ${line.value}` : '')}
                    </Box>
                  )}
                </div>
              );
            }

            return null;
          })}
        </StyledTable>
      )}
    </Box>
  );
};

TableSection.propTypes = {
  header: PropTypes.string.isRequired,
  data: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
    type: PropTypes.string,
  }).isRequired,
  dataKey: PropTypes.arrayOf(PropTypes.string).isRequired,
  customComponentsMap: PropTypes.shape({}),
  hideLabelKeys: PropTypes.arrayOf(PropTypes.string),
};

TableSection.defaultProps = {
  customComponentsMap: {},
  hideLabelKeys: [],
};

export default TableSection;
