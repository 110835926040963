import React from 'react';
import ConnectionsField from './fields/ConnectionsField';
import EventField from './fields/EventField';

const DataEventTriggerStep = () => {
  return (
    <>
      <ConnectionsField />

      <EventField />
    </>
  );
};

export default DataEventTriggerStep;
