import * as Yup from 'yup';
import buildValidator from '../../../utils/validators/buildValidator';
import { signUpAcceptingPaymentsNames } from './signUpAcceptingPaymentsSchema';

const stringValidationSchema = (requiredMessage) => Yup.string().required(requiredMessage);

const signUpAcceptingPaymentsValidator = Yup.object().shape({
  [signUpAcceptingPaymentsNames.acceptingPayments]: stringValidationSchema('This field is required'),
});

export default buildValidator(signUpAcceptingPaymentsValidator);
