import React from 'react';

const IncreaseAuthRates = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22Z"
        fill="#3EB16C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12H13.25V30.75H32V32H12V12ZM24.5 16.375C24.5 16.2092 24.5658 16.0503 24.6831 15.9331C24.8003 15.8158 24.9592 15.75 25.125 15.75H30.125C30.2908 15.75 30.4497 15.8158 30.5669 15.9331C30.6842 16.0503 30.75 16.2092 30.75 16.375V21.375C30.75 21.5408 30.6842 21.6997 30.5669 21.8169C30.4497 21.9342 30.2908 22 30.125 22C29.9592 22 29.8003 21.9342 29.6831 21.8169C29.5658 21.6997 29.5 21.5408 29.5 21.375V18.125L24.9838 23.6463C24.9285 23.7137 24.8597 23.7689 24.7818 23.8082C24.7039 23.8475 24.6186 23.87 24.5315 23.8744C24.4444 23.8787 24.3573 23.8648 24.2759 23.8335C24.1945 23.8022 24.1205 23.7541 24.0588 23.6925L20.825 20.4587L16.255 26.7425C16.155 26.8696 16.0097 26.953 15.8495 26.9752C15.6893 26.9974 15.5267 26.9567 15.396 26.8615C15.2652 26.7663 15.1764 26.6242 15.1483 26.4649C15.1201 26.3057 15.1548 26.1417 15.245 26.0075L20.245 19.1325C20.2981 19.0594 20.3664 18.9987 20.4452 18.9545C20.524 18.9103 20.6114 18.8837 20.7015 18.8766C20.7915 18.8695 20.8821 18.882 20.9668 18.9132C21.0516 18.9444 21.1286 18.9937 21.1925 19.0575L24.4538 22.32L28.8062 17H25.125C24.9592 17 24.8003 16.9342 24.6831 16.8169C24.5658 16.6997 24.5 16.5408 24.5 16.375Z"
        fill="white"
      />
    </svg>
  );
};

export default IncreaseAuthRates;
