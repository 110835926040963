import { shape, string, arrayOf } from 'prop-types';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { capitalize } from '@mui/material';
import { NodeLibraryItem } from './NodeLibraryItem';
import { HeaderWrapper, ShowAll } from './styled';
import { L12, L12M } from '../../../atoms';
import THEME from '../../../../constants/theme';
import { generateUserPilotAttribute } from '../../../../constants/generateUserPilotLabel';
import { USER_PILOT_SECTION_ID } from '../constant';
import { CONNECTION_STATUS } from '../../../../pages/ConnectionsPage/components/constant';
import { L10UM } from '../../../atoms/Typography/L10UM';

const NodeLibraryGroup = ({ name, items, extraItems }) => {
  const [shownAll, setShownAll] = useState(false);

  const connectedServices =
    (name === 'services' && items?.filter((item) => item.connectionStatus === CONNECTION_STATUS.CONNECTED)) ?? [];
  const availableServices =
    (name === 'services' && items?.filter((item) => item.connectionStatus !== CONNECTION_STATUS.CONNECTED)) ?? [];

  const renderLibraryItems = (paletteItems) =>
    paletteItems.map((paletteItem) => <NodeLibraryItem key={paletteItem.id} nodeData={paletteItem} section={name} />);

  return (
    <>
      {name !== 'ungrouped' && (
        <HeaderWrapper
          display="flex"
          justifyContent="space-between"
          {...generateUserPilotAttribute(USER_PILOT_SECTION_ID, 'section_header', name)}
        >
          <L12M color={THEME.greyColors.grey9}>{capitalize(name)}</L12M>
          {extraItems && extraItems.length > 0 && (
            <ShowAll>
              <L12 onClick={() => setShownAll(!shownAll)}>{shownAll ? 'Hide extras' : 'Show all'}</L12>
            </ShowAll>
          )}
        </HeaderWrapper>
      )}
      <Box mt="8px" display="flex" flexDirection="column">
        {name === 'services' && !!connectedServices?.length && (
          <>
            <L10UM margin="0 24px 6px 24px" color={THEME.secondaryColors.greenDark}>
              CONNECTED
            </L10UM>
            {renderLibraryItems(connectedServices)}
          </>
        )}
        {name === 'services' && !!availableServices?.length && (
          <>
            <L10UM margin="16px 24px 6px 24px" color={THEME.greyColors.grey600}>
              AVAILABLE
            </L10UM>
            {renderLibraryItems(availableServices)}
          </>
        )}
        {name !== 'services' && renderLibraryItems(items)}
        {shownAll && extraItems && renderLibraryItems(extraItems)}
      </Box>
    </>
  );
};

NodeLibraryGroup.propTypes = {
  name: string.isRequired,
  extraItems: arrayOf(
    shape({
      id: string.isRequired,
    }).isRequired
  ),
  items: arrayOf(
    shape({
      id: string.isRequired,
    }).isRequired
  ).isRequired,
};

NodeLibraryGroup.defaultProps = {
  extraItems: [],
};

export { NodeLibraryGroup };
