import React from 'react';
import { bool, shape } from 'prop-types';
import { Box } from '@mui/material';
import { useFormikContext } from 'formik';
import { P, InputField } from '../../../../../atoms';
import { StyledLabel } from '../../../../_common/styled';

const CopyField = ({ item, animatedLabel }) => {
  const { handleBlur } = useFormikContext();

  const hasValue = item?.initial_value?.length > 0;

  return (
    <Box margin="0 0 12px 0" key={item.block_id}>
      <StyledLabel>
        {!animatedLabel && (
          <Box display="flex" justifyContent="flex-start" alignItems="center" position="relative">
            <P fontSize="12px !important" fontWeight={600} textAlign="left" lineHeight="30px">
              {item.label?.text}
            </P>
          </Box>
        )}

        <InputField
          variant="outlined"
          type="text"
          id={item.block_id}
          name={item.block_id}
          label={animatedLabel ? item.label.text : ''}
          padding={animatedLabel ? undefined : '10px 16px'}
          value={item.initial_value}
          onBlur={handleBlur}
          disabled
          showCopyIcon={hasValue}
          valueToCopy={item.initial_value}
        />
      </StyledLabel>
    </Box>
  );
};

CopyField.propTypes = {
  item: shape({}).isRequired,
  animatedLabel: bool,
};

CopyField.defaultProps = {
  animatedLabel: false,
};
export default CopyField;
