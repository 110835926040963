import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
// import { useQuery } from '@apollo/client';
import { TableContainer, Table, TableHead } from '@mui/material';

import { H3, L16B, L10UB, L14M } from '../../../components/atoms';
// import { LIST_PAYMENT_ROUTES } from './query';
import { StyledWrapper, StyledTableRow, StyledTableCell, StyledTableBody } from './styled';
import { HEAD_CELL_KEYS, HEAD_CELLS } from './constant';
import { generateLabel } from '../../../utils/helpers';
import THEME from '../../../constants/theme';

const MOCK_DATA = [
  {
    id: '0268a1f6-c203-4697-bf6f-a90e261f0111',
    systemCreated: '2021-11-12T16:33:08Z',
    systemUpdated: '2021-11-12T16:33:08Z',
    paymentMethod: ['MASTERCARD', 'VISA'],
    merchantCategoryCode: ['1155', '1224'],
    currencyCode: ['EUR', 'USD'],
    bankName: ['Chase', 'Bank of America'],
    countryCode: ['USA', 'GBR'],
    cardBrand: ['VISA'],
    cardType: ['CREDIT', 'DEBIT'],
    cardProduct: ['Premium', 'Silver', 'Basic'],
    binCode: ['4235', '5431'],
    destination: 'Monext',
  },
  {
    id: '02sd68a1f6-c203-4697-bf6f-a90e261f0111',
    systemCreated: '2021-11-12T16:33:08Z',
    systemUpdated: '2021-11-12T16:33:08Z',
    paymentMethod: ['CASH'],
    merchantCategoryCode: null,
    currencyCode: null,
    bankName: null,
    countryCode: ['ESP', 'PRT'],
    cardBrand: null,
    cardType: null,
    cardProduct: null,
    binCode: null,
    destination: 'Ppro',
  },
  {
    id: '0268a1f6-c203-46sdf97-bf6f-a90e261f0111',
    systemCreated: '2021-11-12T16:33:08Z',
    systemUpdated: '2021-11-12T16:33:08Z',
    paymentMethod: ['MASTERCARD', 'VISA'],
    merchantCategoryCode: ['1155', '1224'],
    currencyCode: ['EUR', 'USD'],
    bankName: ['Chase', 'Bank of America'],
    countryCode: ['IT'],
    cardBrand: ['VISA'],
    cardType: ['CREDIT', 'DEBIT'],
    cardProduct: ['Premium', 'Silver', 'Basic'],
    binCode: ['4235', '5431'],
    destination: 'Monext',
  },
];

const generateComma = (arr, item, index) => {
  const appendComma = index !== arr.length - 1;

  return `${item}${appendComma ? ', ' : ''}`;
};

const RoutingTable = () => {
  const { t } = useTranslation();

  // const { data, loading, error } = useQuery(LIST_PAYMENT_ROUTES);

  return (
    <Box>
      <H3 fontWeight="600" margin="0 0 16px 0">
        {t('Routing Table')}
      </H3>

      <Box border="1px" mt="40px">
        <Box>
          <L16B>{t('Routes')}</L16B>
        </Box>

        <StyledWrapper>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="Routing table">
              <TableHead>
                <StyledTableRow>
                  {HEAD_CELLS.map(({ id, name }) => (
                    <StyledTableCell key={id}>
                      <L10UB color={THEME.greyColors.grey600}>{name}</L10UB>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>

              <StyledTableBody>
                {MOCK_DATA.map((route) => {
                  return (
                    <StyledTableRow key={route.id}>
                      {HEAD_CELLS.map(({ id }) => {
                        const data = route[id] ?? 'Any';
                        const isArray = Array.isArray(data);
                        const shouldUppercase = id === HEAD_CELL_KEYS.currencyCode || id === HEAD_CELL_KEYS.countryCode;
                        const isBankName = id === HEAD_CELL_KEYS.bankName;

                        return (
                          <StyledTableCell key={id} scope="row">
                            <L14M>
                              {isArray &&
                                data.map((item, index) => {
                                  let label;

                                  if (shouldUppercase) label = item.toUpperCase();
                                  else if (isBankName) label = item;
                                  else label = generateLabel(item);

                                  return generateComma(data, label, index);
                                })}

                              {!isArray && data}
                            </L14M>
                          </StyledTableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                })}
              </StyledTableBody>
            </Table>
          </TableContainer>
        </StyledWrapper>
      </Box>
    </Box>
  );
};

export default RoutingTable;
