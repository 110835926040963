import React from 'react';
import { useTranslation } from 'react-i18next';

import capitalize from '@mui/material/utils/capitalize';
import isEmpty from 'lodash/isEmpty';
import { P } from '../../atoms';
import { formNames } from './schema';

const OTHERS_VALUE = 'OTHER';

const CUSTOM_SELECT_PROPS = {
  placeholder: 'Select',
  errorProps: {
    margin: '-6px 0 14px 0',
  },
};

const generateOptions = (values) =>
  values.map((value) => ({
    value: value
      .split(' ')
      .join('_')
      .toUpperCase(),
    text: { text: value },
  }));

const productsServicesOptions = generateOptions(['Physical', 'Service', 'Digital', 'Other']);

const SELECT_OPTIONS = {
  [formNames.productsServices]: productsServicesOptions,
};

const OTHERS_INPUT_NAME_MAP = {
  [formNames.productsServices]: formNames.productsServicesOther,
};

const SelectPlaceHolder = () => {
  const { t } = useTranslation();
  return <P color="#8e8f8f">{t('Select')}</P>;
};

const renderValue = (values, max) => {
  if (!Array.isArray(values)) return values;
  if (Array.isArray(values) && values.some((value) => typeof value !== 'string')) return values;

  if (isEmpty(values)) {
    return <SelectPlaceHolder />;
  }
  return (
    values
      ?.slice(0, max || values.length)
      ?.map((value) => capitalize(value?.toLowerCase()?.replace(/_/g, ' ') || ''))
      ?.join(', ') + (values?.length > (max || values.length) ? `...` : '')
  );
};

const RENDER_VALUE_MAP = {
  [formNames.productsServices]: (value) => renderValue(value, 2),
};

export { SELECT_OPTIONS, CUSTOM_SELECT_PROPS, OTHERS_VALUE, OTHERS_INPUT_NAME_MAP, RENDER_VALUE_MAP, renderValue };
