import React from 'react';

const PaymentTrigger = () => (
  <svg width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.3999 8.5H6.1999C5.7999 8.5 5.3999 8.2 5.3999 7.7C5.3999 7.2 5.7999 7 6.1999 7H8.3999C8.7999 7 9.1999 7.3 9.1999 7.8C9.1999 8.3 8.7999 8.5 8.3999 8.5Z"
      fill="#787F88"
    />
    <path
      d="M9.2999 16.5H6.1999C5.7999 16.5 5.3999 16.2 5.3999 15.7C5.3999 15.2 5.7999 15 6.1999 15H9.2999C9.6999 15 10.0999 15.3 10.0999 15.8C10.0999 16.3 9.6999 16.5 9.2999 16.5Z"
      fill="#787F88"
    />
    <path
      d="M11.6999 12.5H6.1999C5.7999 12.5 5.3999 12.2 5.3999 11.7C5.3999 11.2 5.7999 11 6.1999 11H11.6999C12.0999 11 12.4999 11.3 12.4999 11.8C12.4999 12.3 12.0999 12.5 11.6999 12.5Z"
      fill="#787F88"
    />
    <path
      d="M18.1 23.5H3.4C1.5 23.5 0 22 0 20.1V3.4C0 1.5 1.5 0 3.4 0H18.1C20 0 21.5 1.5 21.5 3.4C21.5 3.8 21.2 4.2 20.7 4.2C20.2 4.2 20 3.8 20 3.4C20 2.3 19.1 1.5 18.1 1.5H3.4C2.3 1.5 1.5 2.4 1.5 3.4V20.1C1.5 21.2 2.4 22 3.4 22H18.1C19.2 22 20 21.1 20 20.1C20 19.7 20.3 19.3 20.8 19.3C21.3 19.3 21.6 19.6 21.6 20.1C21.5 22 20 23.5 18.1 23.5Z"
      fill="#787F88"
    />
    <path
      d="M18.7001 15.3999C18.5001 15.3999 18.3001 15.2999 18.1001 15.0999C17.8001 14.7999 17.9001 14.2999 18.2001 13.9999L21.2001 11.6999L18.2001 9.39993C17.9001 9.09993 17.8001 8.69993 18.1001 8.29993C18.4001 7.99993 18.8001 7.99993 19.2001 8.19993L22.4001 10.6999C22.7001 10.9999 22.9001 11.2999 22.9001 11.7999C22.9001 12.2999 22.7001 12.5999 22.4001 12.8999L19.2001 15.3999C19.0001 15.3999 18.9001 15.3999 18.7001 15.3999Z"
      fill="#787F88"
    />
    <path
      d="M24.7001 15.3999C24.5001 15.3999 24.3001 15.2999 24.1001 15.0999C23.8001 14.7999 23.9001 14.2999 24.2001 13.9999L27.2001 11.6999L24.2001 9.39993C23.9001 9.09993 23.8001 8.69993 24.1001 8.29993C24.4001 7.99993 24.8001 7.99993 25.2001 8.19993L28.4001 10.6999C28.7001 10.9999 28.9001 11.2999 28.9001 11.7999C28.9001 12.2999 28.7001 12.5999 28.4001 12.8999L25.2001 15.3999C25.0001 15.3999 24.9001 15.3999 24.7001 15.3999Z"
      fill="#787F88"
    />
  </svg>
);
export default PaymentTrigger;
