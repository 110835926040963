import React from 'react';
import CommunicationSection from './CommunicationSection';
import ElementsSection from './ElementsSection';

const EmailsPage = () => {
  return (
    <>
      <CommunicationSection />
      <ElementsSection />
    </>
  );
};

export default EmailsPage;
