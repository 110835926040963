import React from 'react';
import PropTypes from 'prop-types';

const Webhook = ({ size }) => (
  <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.85 24.55C5.65 24.55 4.45 24.05 3.55 23.15L1.35 20.95C-0.45 19.15 -0.45 16.15 1.35 14.25L4.25 11.35C4.55 11.05 5.05 11.05 5.35 11.35C5.65 11.65 5.65 12.15 5.35 12.45L2.45 15.35C1.15 16.65 1.15 18.65 2.45 19.95L4.65 22.15C5.95 23.45 7.95 23.45 9.25 22.15L12.15 19.25C12.45 18.95 12.95 18.95 13.25 19.25C13.55 19.55 13.55 20.05 13.25 20.35L10.35 23.25C9.25 24.05 8.05 24.55 6.85 24.55Z"
      fill="#787F88"
    />
    <path
      d="M19.65 13.45C19.45 13.45 19.25 13.35 19.15 13.25C18.85 12.95 18.85 12.45 19.15 12.15L22.05 9.25C23.35 7.95 23.35 5.95 22.05 4.65L19.85 2.45C18.55 1.15 16.55 1.15 15.25 2.45L12.35 5.35C12.05 5.65 11.55 5.65 11.25 5.35C10.95 5.05 10.95 4.55 11.25 4.25L14.15 1.35C15.95 -0.45 18.95 -0.45 20.85 1.35L23.05 3.55C24.85 5.35 24.85 8.35 23.05 10.25L20.15 13.15C20.05 13.35 19.85 13.45 19.65 13.45Z"
      fill="#787F88"
    />
    <path
      d="M6.44985 16.55C6.24985 16.55 6.04985 16.45 5.94985 16.35C5.64985 16.05 5.64985 15.55 5.94985 15.25L15.1499 6.04995C15.4499 5.74995 15.9499 5.74995 16.2499 6.04995C16.5499 6.34995 16.5499 6.84995 16.2499 7.14995L7.04985 16.35C6.84985 16.45 6.64985 16.55 6.44985 16.55Z"
      fill="#787F88"
    />
    <path
      d="M8.6498 18.7499C8.4498 18.7499 8.2498 18.6499 8.1498 18.5499C7.8498 18.2499 7.8498 17.7499 8.1498 17.4499L17.3498 8.2499C17.6498 7.9499 18.1498 7.9499 18.4498 8.2499C18.7498 8.5499 18.7498 9.0499 18.4498 9.3499L9.24981 18.5499C9.04981 18.6499 8.8498 18.7499 8.6498 18.7499Z"
      fill="#787F88"
    />
  </svg>
);

Webhook.propTypes = {
  size: PropTypes.number,
};

Webhook.defaultProps = {
  size: 20,
};

export default Webhook;
