import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import uniqBy from 'lodash/uniqBy';
import Box from '@mui/material/Box';
import capitalize from 'lodash/capitalize';
import { ContentBox, FooterBox, StyledBox, StyledTitleWrapper } from './styled';
import { L12B } from '../../../atoms/Typography/L12B';
import { useFlowEditorContext } from '../../context';
import { usePossibleConditionValues } from './hooks/usePossibleConditionValues';
import { L16B } from '../../../atoms/Typography/L16B';
import { StyledFormControl } from '../../../FlowTest/TestExamplesModal/styled';
import Select, { NONE } from '../../../forms/_common/Select';
import { ButtonRounded } from '../../../atoms';
import useValidOperatorsFor from '../../hooks/useGetValidOperatorsFor';
import { getIsInputFieldDataType } from './constant';
import useIsDemo from '../../../../hooks/useIsDemo';
import { ComparisonOperator } from '../AutomationDetails/AutomationStep/fields/constants';
import { useFlowPropertiesList } from '../AutomationDetails/AutomationStep/fields/hooks';
import PropertyTextEditor from '../../../PropertyTextEditor';
import THEME from '../../../../constants/theme';
import LoadingState from '../AutomationDetails/LoadingState';
import { cutLabels, getQuillTextValue } from '../../../PropertyTextEditor/utils';

const ChooseValueConditionModal = ({ isOpen, stepId, targetId, property, setIsShowChooseValueConditionModal }) => {
  const { t } = useTranslation();
  const isDemo = useIsDemo();
  const { nodes, setNodes, saveConfigurationAsync, setSelectedNodeId } = useFlowEditorContext();
  const { possibleValues, condition, setCondition, conditionNode } = usePossibleConditionValues({
    stepId,
    targetId,
  });
  const displayedProperty = property
    ?.replaceAll('.', ' ')
    ?.split(/(?=[A-Z])/)
    ?.map((s, i) => (i === 0 ? capitalize(s) : s.toLowerCase()))
    ?.join(' ');
  const { getAvailableProperties, loading } = useFlowPropertiesList(
    [conditionNode?.propertyDataType],
    'no-cache',
    !conditionNode,
    conditionNode?.id
  );
  const { validOperatorsOptions } = useValidOperatorsFor({
    type: conditionNode?.selectedDataType,
    property: conditionNode?.conditionProperty,
  });
  const isInputFieldDataType = getIsInputFieldDataType(conditionNode?.propertyDataType);

  const cutLabelsArr = cutLabels(getAvailableProperties);

  const isRange =
    condition?.op === ComparisonOperator.BETWEEN_INCLUSIVE || condition?.op === ComparisonOperator.BETWEEN_EXCLUSIVE;

  const conditionValueOptions = useMemo(() => {
    if (!possibleValues?.length) {
      return [];
    }
    return uniqBy(
      possibleValues?.map((value) => ({
        ...value,
        value: value?.key,
        title: value?.label,
      })),
      'value'
    );
  }, [possibleValues]);

  const getIsEmptyOrNotEmptyOp = (op) => op === ComparisonOperator.IS_EMPTY || op === ComparisonOperator.NOT_EMPTY;

  const handleConditionOptionChange = (e) => {
    const op = e.target.value;

    const range = op === ComparisonOperator.BETWEEN_INCLUSIVE || op === ComparisonOperator.BETWEEN_EXCLUSIVE;

    if (getIsEmptyOrNotEmptyOp(op)) {
      setCondition((prevValue) => ({
        ...prevValue,
        op,
        right: NONE,
        label: NONE,
      }));
    } else {
      let right;
      let label;

      if (range && typeof condition?.right !== 'object') {
        right = { from: condition?.right };
        label = { from: condition?.label };
      } else if (!range && typeof condition?.right === 'object') {
        right = condition?.right?.from;
        label = condition?.label?.from;
      } else {
        right = condition?.right;
        label = condition?.label;
      }

      setCondition((prevValue) => ({
        ...prevValue,
        op,
        right,
        label,
      }));
    }
  };

  const handleSelectedPossibleValueChange = (e) => {
    e.stopPropagation();
    const { value, name } = e.target;
    const label = conditionValueOptions.find((option) => option?.value === value)?.label;

    if (isRange) {
      setCondition((prevValue) => ({
        ...prevValue,
        right: {
          ...prevValue?.right,
          [name]: value,
        },
        label: {
          ...prevValue?.label,
          [name]: label || value,
        },
      }));
    } else {
      setCondition((prevValue) => ({
        ...prevValue,
        right: value,
        label: label || value,
      }));
    }
  };

  const handleInputChange = (value, type) => {
    const label = getQuillTextValue(value, cutLabelsArr);
    if (!isRange) {
      setCondition((prevValue) => ({
        ...prevValue,
        right: value,
        label,
      }));
    } else {
      const checkedType = type || 'from';
      setCondition((prevValue) => ({
        ...prevValue,
        right: {
          ...prevValue?.right,
          [checkedType]: value,
        },
        label: {
          ...prevValue?.label,
          [checkedType]: label || value,
        },
      }));
    }
  };

  const handleAddCondition = async () => {
    const updatedElements = nodes.map((el) => {
      if (el.id !== stepId) {
        return el;
      }
      if (targetId === el?.data?.elseStepId) {
        const conditions = conditionNode?.conditions?.length
          ? [...conditionNode?.conditions, { ...condition, nextStepId: targetId }]
          : [{ ...condition, nextStepId: targetId }];
        return { ...el, data: { ...el.data, conditions, elseStepId: null } };
      }
      const conditions = conditionNode?.conditions?.length
        ? conditionNode?.conditions?.map((c) => (c?.nextStepId === condition?.nextStepId ? { ...c, ...condition } : c))
        : [condition];
      return { ...el, data: { ...el.data, conditions } };
    });
    setNodes(updatedElements);
    setIsShowChooseValueConditionModal(false);
    return saveConfigurationAsync(updatedElements);
  };

  const onCancel = (e) => {
    e.stopPropagation();
    setSelectedNodeId(null);
    setIsShowChooseValueConditionModal(false);
  };

  return (
    isOpen && (
      <StyledBox width="328px">
        <StyledTitleWrapper>
          <L16B color="#232629">{t('Choose value')}</L16B>
        </StyledTitleWrapper>
        <ContentBox>
          <>
            <L12B margin="20px 0 8px 0">{t('If value')}</L12B>
            <StyledFormControl fullWidth>
              <Select
                value={condition?.op || NONE}
                name="ifValue"
                options={validOperatorsOptions}
                onChange={handleConditionOptionChange}
                overrideMenuProps={{ anchorEl: undefined }}
              />
            </StyledFormControl>
          </>
          {!getIsEmptyOrNotEmptyOp(condition?.op) && (
            <>
              <L12B margin="20px 0 8px 0">{displayedProperty}</L12B>
              {isInputFieldDataType || conditionValueOptions.length === 0 ? (
                <LoadingState loading={loading}>
                  <Box display="flex" width="100%">
                    <Box width={isRange ? '136px' : '100%'} mr={isRange ? '8px' : '0'}>
                      <PropertyTextEditor
                        handleSavePlainText={(value) => handleInputChange(value, 'from')}
                        properties={getAvailableProperties}
                        initialEditorText={isRange ? condition?.right?.from : condition?.right}
                        minHeight="0"
                        maxHeight="40px"
                        border={`1px solid ${THEME.greyColors.grey8}`}
                        placeholder="From"
                        singleProperty
                      />
                    </Box>
                    {isRange && (
                      <Box width="136px">
                        <PropertyTextEditor
                          handleSavePlainText={(value) => handleInputChange(value, 'to')}
                          properties={getAvailableProperties}
                          initialEditorText={condition?.right?.to}
                          minHeight="0"
                          maxHeight="40px"
                          border={`1px solid ${THEME.greyColors.grey8}`}
                          placeholder="To"
                          singleProperty
                        />
                      </Box>
                    )}
                  </Box>
                </LoadingState>
              ) : (
                <Box display="flex">
                  <StyledFormControl mr="8px" fullWidth>
                    <Select
                      value={(isRange ? condition?.right?.from : condition?.right) || NONE}
                      name="from"
                      options={conditionValueOptions}
                      onChange={handleSelectedPossibleValueChange}
                      overrideMenuProps={{ anchorEl: undefined }}
                    />
                  </StyledFormControl>
                  {isRange && (
                    <StyledFormControl fullWidth>
                      <Select
                        value={condition?.right?.to || NONE}
                        name="to"
                        options={conditionValueOptions}
                        onChange={handleSelectedPossibleValueChange}
                        overrideMenuProps={{ anchorEl: undefined }}
                      />
                    </StyledFormControl>
                  )}
                </Box>
              )}
            </>
          )}
        </ContentBox>
        <FooterBox>
          <ButtonRounded
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAddCondition}
            disabled={
              (!getIsEmptyOrNotEmptyOp(condition?.op) &&
                (!condition?.op ||
                  !condition?.right ||
                  condition?.op === NONE ||
                  condition?.right === NONE ||
                  isDemo)) ||
              (isRange &&
                (!condition?.right?.from ||
                  !condition?.right?.to ||
                  condition?.right?.from === NONE ||
                  condition?.right?.to === NONE))
            }
          >
            {t('Add')}
          </ButtonRounded>
          <ButtonRounded type="button" color="secondary" variant="contained" onClick={onCancel}>
            {t('Cancel')}
          </ButtonRounded>
        </FooterBox>
      </StyledBox>
    )
  );
};

ChooseValueConditionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  stepId: PropTypes.string.isRequired,
  targetId: PropTypes.string.isRequired,
  setIsShowChooseValueConditionModal: PropTypes.func.isRequired,
  property: PropTypes.string,
};

ChooseValueConditionModal.defaultProps = {
  property: '',
};

export { ChooseValueConditionModal };
