import React, { useMutation } from '@apollo/client';
import { NotificationManager } from 'react-notifications';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';

import { GQL_M_SIGN_UP_BETA } from '../../utils/queries/public/publicMutations';
import { ONBOARDING_STATUS } from '../../constants/registration';
import { transformSignUpBetaValues } from './constant';
import { UI_ROUTES } from '../../constants/routes';
import { initialValues } from '../../utils/schemas/confirmDetailsSchema';
import { isDefined } from '../../utils/helpers';
import { useHandleRegistrationFlowRedirect, useRegistrationStorage } from '../../hooks/registration';
import { messages } from '../../client/links/errorLink';
import { TOAST_TIMEOUT, TOAST_UNEXPECTED_ERROR_MESSAGE } from '../../constants/toasts';
import { ERROR_CODES } from '../../constants/api';
import { findErrorByCode, getFirstErrorMessage } from '../../utils/graphql';
import ToastCustomContainer from '../../components/ToastCustomContainer';

const TOAST_TITLE = 'Sign Up.';
const MUTATION_OPTION = { context: { skipGlobalHandling: true } };

//TOD: CHeck exception to avoid multiple toast messages
const useSignUpBeta = () => {
  const { push } = useHistory();
  const [signUpBeta, { loading }] = useMutation(GQL_M_SIGN_UP_BETA, MUTATION_OPTION);
  const [registrationSession, setRegistrationSession] = useRegistrationStorage();
  useHandleRegistrationFlowRedirect();

  const parsedInitialValues = {
    ...initialValues,
    ...(isDefined(registrationSession?.[ONBOARDING_STATUS.companyDetails]) &&
      registrationSession[ONBOARDING_STATUS.companyDetails]),
  };

  const handleSubmit = async (values) => {
    let latestValues = {
      ...registrationSession,
      [ONBOARDING_STATUS.paymentDetails]: values,
      onboardingStatus: ONBOARDING_STATUS.welcomeToBeta,
    };

    try {
      //Returns Boolean
      const res = await signUpBeta({
        variables: { ...transformSignUpBetaValues(latestValues) },
      });

      //Error handling
      const isFailed = !isEmpty(res?.errors) || !res?.data?.signUpBeta;

      if (isFailed) {
        const error = getFirstErrorMessage(res?.errors);
        const errorMessage = messages[error] ?? error ?? TOAST_UNEXPECTED_ERROR_MESSAGE;

        NotificationManager.error(
          <ToastCustomContainer message={errorMessage} title={TOAST_TITLE} />,
          null,
          TOAST_TIMEOUT
        );

        const isDuplicatedUser = !!findErrorByCode(res?.errors, ERROR_CODES.duplicatedUser);

        if (isDuplicatedUser) {
          latestValues = {
            ...latestValues,
            onboardingStatus: ONBOARDING_STATUS.personalDetails,
          };
          setRegistrationSession(latestValues);

          push(UI_ROUTES.signUpPersonalDetails);
        }

        return;
      }

      //Success handling
      setRegistrationSession(latestValues);

      //Redirect user to the right page
      push(UI_ROUTES.welcomeToBeta);
      return;
    } catch {
      NotificationManager.error(
        <ToastCustomContainer message={TOAST_UNEXPECTED_ERROR_MESSAGE} title={TOAST_TITLE} />,
        null,
        TOAST_TIMEOUT
      );
    }
  };

  return { signUpBeta: handleSubmit, parsedInitialValues, loading };
};

export { useSignUpBeta };
