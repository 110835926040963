import React from 'react';
import PropTypes from 'prop-types';

const Popups = ({ stroke, ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.5 16V15.5V9.5H7.5V18.5H6.5V18V16ZM6.55001 8.5C6.74885 7.52044 7.52044 6.74885 8.5 6.55001V7.58535C8.0738 7.736 7.736 8.0738 7.58535 8.5H6.55001ZM20.4146 8.5C20.264 8.07379 19.9262 7.73599 19.5 7.58535V6.55001C20.4796 6.74884 21.2512 7.52043 21.45 8.5H20.4146ZM20.5 9.5H21.5V18.5H20.5V9.5ZM20.4146 19.5H21.45C21.2512 20.4796 20.4796 21.2512 19.5 21.45V20.4146C19.9262 20.264 20.264 19.9262 20.4146 19.5ZM18.5 20.5V21.5H9.5V20.5H18.5ZM8.5 21.45C7.52043 21.2512 6.74884 20.4796 6.55001 19.5H7.58535C7.73599 19.9262 8.07379 20.264 8.5 20.4146V21.45ZM18.5 7.5H9.5V6.5H15.5H16H18H18.5V7.5ZM16.4146 4.5C16.264 4.07379 15.9262 3.73599 15.5 3.58535V2.55001C16.4796 2.74884 17.2512 3.52043 17.45 4.5H16.4146ZM14.5 3.5H5.5V2.5H14.5V3.5ZM4.5 3.58535C4.0738 3.736 3.736 4.0738 3.58535 4.5H2.55001C2.74885 3.52044 3.52044 2.74885 4.5 2.55001V3.58535ZM3.5 5.5V14.5H2.5V5.5H3.5ZM4.5 16.4146V17.45C3.52043 17.2512 2.74884 16.4796 2.55001 15.5H3.58535C3.73599 15.9262 4.07379 16.264 4.5 16.4146Z"
      fill={stroke}
      stroke={stroke}
    />
  </svg>
);

Popups.propTypes = {
  stroke: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Popups.defaultProps = {
  stroke: '#787F88',
};

export default Popups;
