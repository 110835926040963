import React from 'react';
import Autocomplete from '@mui/lab/Autocomplete';
import { arrayOf, string, func } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { StyledChip, StyledInput } from './styled';
import Remove from '../../assets/icons/Remove';
import THEME from '../../constants/theme';
import CheckIcon from '../../assets/icons/Select/CheckIcon';

const useStyledAutocomplete = makeStyles(() => ({
  root: {
    flex: 1,
  },
  popper: {
    display: ({ $isEmpty }) => ($isEmpty ? 'none' : 'block'),
  },
  option: {
    fontSize: '12px',
    fontWeight: '600',
    height: '34px',
    '&[aria-selected="true"]': {
      background: `${THEME.primaryColors.white}`,
      color: `${THEME.primaryColors.purpleMain}`,
    },
  },
}));

const AutocompleteMultiple = ({ options, onChange, value }) => {
  const classes = useStyledAutocomplete({ $isEmpty: isEmpty(options) });
  return (
    <Autocomplete
      multiple
      id="tags-filled"
      freeSolo
      disableClearable
      classes={classes}
      value={value}
      fullWidth
      renderTags={(inputValue, getTagProps) =>
        inputValue.map((option, index) => (
          <StyledChip
            label={option}
            {...getTagProps({ index })}
            deleteIcon={<Remove color={THEME.greyColors.grey600} size={16} />}
          />
        ))
      }
      options={options}
      renderInput={(params) => <StyledInput {...params} lineHeight="100px" />}
      renderOption={(option, state) =>
        state.selected ? (
          <Box display="flex" alignItems="center">
            <Box mr="8px">{option}</Box>
            <CheckIcon />
          </Box>
        ) : (
          option
        )
      }
      onChange={onChange}
    />
  );
};

AutocompleteMultiple.propTypes = {
  options: arrayOf(string),
  onChange: func,
  value: arrayOf(string),
};

AutocompleteMultiple.defaultProps = {
  options: [],
  onChange: () => null,
  value: [],
};

export default AutocompleteMultiple;
