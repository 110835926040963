import React from 'react';
import { bool, func, shape } from 'prop-types';
import { useFormikContext } from 'formik';
import { Box } from '@mui/material';
import { StyledSelect, P14B, L12 } from '../../../../../atoms';
import useActionHandlers from '../hooks/useActionHandlers';
import THEME from '../../../../../../constants/theme';

const Select = ({ item, showErrors, setShowErrors, animatedLabel }) => {
  const { values, errors } = useFormikContext();
  const { onChangeHandler } = useActionHandlers(setShowErrors);

  return (
    <Box key={item.block_id}>
      {!animatedLabel && <P14B textAlign="left">{item.label.text}</P14B>}
      <StyledSelect
        name={item.block_id}
        id={item.block_id}
        value={values[item.block_id] || (item.options && item.options[0].value)}
        onChange={onChangeHandler}
        options={item.element.options}
        label={animatedLabel ? item.label.text : ''}
        padding={animatedLabel ? undefined : '8px 16px 8px 32px'}
      />

      {showErrors && errors[item.block_id] && (
        <L12 textAlign="left" margin="0 0 24px 0" color={THEME.secondaryColors.danger}>
          {errors[item.block_id]}
        </L12>
      )}
    </Box>
  );
};

Select.propTypes = {
  item: shape({}).isRequired,
  showErrors: bool,
  setShowErrors: func.isRequired,
  animatedLabel: bool,
};

Select.defaultProps = {
  showErrors: false,
  animatedLabel: false,
};

export default Select;
