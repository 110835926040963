import React, { useEffect, useState } from 'react';
import { arrayOf, shape } from 'prop-types';
import { StyledSearchColumnBox } from './styled';
import Item from './Item';
import { usePropertyTextEditorContext } from '../context';
import { propertyObjectTypes } from './types';

const SearchColumn = ({ items }) => {
  const { selectedPropertyObject, setSelectedPropertyObject } = usePropertyTextEditorContext();
  const [selectedItem, setSelectedItem] = useState(
    selectedPropertyObject?.type === propertyObjectTypes.ITEM ? selectedPropertyObject : null
  );

  useEffect(() => {
    if (selectedItem) {
      setSelectedPropertyObject(selectedItem);
    }
  }, [selectedItem, setSelectedPropertyObject]);

  return (
    <StyledSearchColumnBox $selected={selectedPropertyObject}>
      {items?.map((item) => (
        <Item
          key={item.key}
          label={item.label}
          path={item.path}
          selected={item.key === selectedItem?.key}
          onSelect={() => setSelectedItem(item)}
        />
      ))}
    </StyledSearchColumnBox>
  );
};

SearchColumn.propTypes = {
  items: arrayOf(shape({})),
};

SearchColumn.defaultProps = {
  items: [],
};

export default SearchColumn;
