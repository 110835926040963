import React from 'react';
import Box from '@mui/material/Box';
import { FormHelperText } from '@mui/material';
import { bool, shape, string } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Skeleton from '@mui/lab/Skeleton';
import useFieldsValues from './hooks/useFieldsValues';
import { StyledFormControl, StyledFormControlLabel } from './styled';
import { Radio } from '../../../../../atoms';
import Select, { NONE } from '../../../../../forms/_common/Select';
import { useNodeDataToSave } from './hooks/useNodeDataToSave';
import useGetOptionsForSelect from './hooks/useGetOptionsForSelect';
import { useValidationMessages } from './hooks';
import THEME from '../../../../../../constants/theme';
import TextEditorInput from './InputByType/TextEditorInput';

const FormControlWithLabel = ({ record, currentValue }) => {
  const [{ singleRecordUniquenessOperator, filters }, updateDataToSave] = useNodeDataToSave();
  const { label, key, type } = record;
  const { items, options, getAvailableProperties, loading: valuesLoading } = useFieldsValues(key, type);
  const { options: uniquenessOperators, loading } = useGetOptionsForSelect('getSingleRecordUniquenessOperators');

  const { [`uniquenessOperator.${key}`]: validationMessage } = useValidationMessages(`uniquenessOperator.${key}`);

  const currentItemKey = currentValue === 'date' ? 'dateRange' : currentValue;

  const currentFieldValue =
    currentItemKey === 'dateRange'
      ? filters && filters[currentItemKey]?.selectedVerb
      : filters && filters[currentItemKey]?.value;

  const onChange = ({ target: { value } }) => {
    updateDataToSave(
      currentItemKey === 'dateRange'
        ? {
            filters: {
              dateRange: {
                selectedVerb: value,
                value: Intl.DateTimeFormat().resolvedOptions().timeZone,
              },
            },
          }
        : {
            filters: {
              [currentItemKey]: {
                value,
                selectedVerb: 'IS_EQUAL',
              },
            },
          }
    );
  };

  const onChangeUniquenessOperator = ({ target: { value } }) => {
    updateDataToSave({ singleRecordUniquenessOperator: value });
  };

  return (
    <>
      <StyledFormControlLabel
        key={key}
        value={key}
        control={<Radio color="primary" size="small" />}
        label={label}
        name="singleRecordField"
        selected={(key === 'date' && currentItemKey === 'dateRange') || key === currentItemKey}
      />
      {(key === 'date' && currentItemKey === 'dateRange') || key === currentItemKey ? (
        <Box ml="32px" mb="16px">
          <StyledFormControl $mt="8px" $padding={(valuesLoading || isEmpty(items)) && '0px'}>
            {valuesLoading && (
              <Box borderRadius="8px">
                <Skeleton height="40px" />
              </Box>
            )}
            {!isEmpty(items) && !valuesLoading ? (
              <Select value={currentFieldValue || NONE} options={options} onChange={onChange} />
            ) : (
              !valuesLoading && (
                <TextEditorInput
                  name={currentItemKey}
                  value={currentFieldValue}
                  border={`1px solid ${THEME.greyColors.grey8}`}
                  onChange={onChange}
                  properties={getAvailableProperties}
                  showToolbar={false}
                  minHeight="none"
                />
              )
            )}
          </StyledFormControl>
          {record.canProvideUniqueRecord || loading || (
            <StyledFormControl $mt="8px" error={!!validationMessage}>
              <Select
                value={singleRecordUniquenessOperator || NONE}
                options={uniquenessOperators}
                onChange={onChangeUniquenessOperator}
              />
              <FormHelperText>{validationMessage}</FormHelperText>
            </StyledFormControl>
          )}
        </Box>
      ) : null}
    </>
  );
};

FormControlWithLabel.propTypes = {
  record: shape({
    key: string.isRequired,
    label: string.isRequired,
    canProvideUniqueRecord: bool.isRequired,
    type: string.isRequired,
  }).isRequired,
  currentValue: string,
};

FormControlWithLabel.defaultProps = {
  currentValue: null,
};

export default FormControlWithLabel;
