import PropTypes from 'prop-types';
import React from 'react';
import { P14 } from '../../../../components/atoms';

const IndentedTextComponent = ({ dataObj }) => {
  const IndentedText = dataObj.value || 'N/A';

  return <P14>{IndentedText}</P14>;
};

IndentedTextComponent.propTypes = {
  dataObj: PropTypes.shape({
    value: PropTypes.string,
  }),
};
IndentedTextComponent.defaultProps = {
  dataObj: {},
};

export default IndentedTextComponent;
