import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { StyledLabel, StyledRadio, StyledFormControlLabel } from './styled';

const InputGroup = ({ value, color, currentValue }) => {
  const { t } = useTranslation();

  const isActive = currentValue === value;
  return (
    <StyledFormControlLabel
      isActive={isActive}
      value={value}
      control={<StyledRadio color="default" brandcolor={color} />}
      label={
        <StyledLabel brandcolor={color} type={value}>
          {t('Action')}
        </StyledLabel>
      }
      labelPlacement="top"
    />
  );
};

InputGroup.propTypes = {
  value: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  currentValue: PropTypes.string.isRequired,
};

export default InputGroup;
