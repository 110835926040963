import React, { useCallback, useMemo, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import pick from 'lodash/pick';

import VisibilityOffIcon from '../../../assets/icons/VisibilityOffIcon';
import VisibilityIcon from '../../../assets/icons/VisibilityIcon';
import { UI_ROUTES } from '../../../constants/routes';
import THEME from '../../../constants/theme';
import { StyledPasswordVisibilityButton } from './styled';

import { InputFieldOnboarding, MaterialIconStyler, StyledMaterialLink } from '../../atoms';
import { MESSAGES } from '../../../utils/validators/passwordValidator';
import { FlexContainer } from '../../atoms/flex/FlexContainer';

const messages = Object.values(pick(MESSAGES, ['required', 'emailEqual']));

const PasswordOnboarding = ({ name, label, animateLabel, forgotPassword, signIn, getMeLoading, loading }) => {
  const [isMasked, setMasked] = useState(true);
  const { isSubmitting } = useFormikContext();
  const [{ value, onChange, onBlur }, { touched, error }] = useField(name);
  const toggleIsMasked = useCallback(() => {
    setMasked((prev) => !prev);
  }, []);
  const { t } = useTranslation();

  const messageData = useMemo(
    () => error?.filter((message) => (signIn ? messages.includes(message) : !messages.includes(message))) || [],
    [error, signIn]
  );

  const hasError = touched && messageData?.length > 0;

  return (
    <Box>
      {!animateLabel && (
        <FlexContainer justifyContent="space-between">
          {forgotPassword && (
            <StyledMaterialLink
              to={UI_ROUTES.forgotPassword}
              textDecoration="none"
              background={THEME.gradient}
              backgroundClip="text"
              textFill="transparent"
              margin="0 0 0 6px"
              color="transparent"
              cursor="pointer"
            >
              {t('signInForm.forgotPassword')}
            </StyledMaterialLink>
          )}
        </FlexContainer>
      )}
      <InputFieldOnboarding
        variant="outlined"
        type={isMasked ? 'password' : 'text'}
        autoComplete="new-password"
        name={name}
        label={hasError ? messageData[0] : label}
        value={value}
        InputProps={{
          endAdornment: (
            <StyledPasswordVisibilityButton onClick={toggleIsMasked} type="button">
              <MaterialIconStyler
                icon={isMasked ? VisibilityIcon : VisibilityOffIcon}
                cursor="pointer"
                width="20px"
                height="20px"
              />
            </StyledPasswordVisibilityButton>
          ),
        }}
        disabled={getMeLoading || loading || isSubmitting}
        onChange={onChange}
        onBlur={onBlur}
        className={hasError ? 'has-error' : ''}
      />
    </Box>
  );
};

PasswordOnboarding.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  animateLabel: PropTypes.bool,
  forgotPassword: PropTypes.bool,
  signIn: PropTypes.bool,
  loading: PropTypes.bool,
  getMeLoading: PropTypes.bool,
};

PasswordOnboarding.defaultProps = {
  animateLabel: false,
  forgotPassword: false,
  signIn: false,
  label: '',
  loading: false,
  getMeLoading: false,
};

export default PasswordOnboarding;
