import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { OTHERS_VALUE, OTHERS_INPUT_NAME_MAP } from './consts';

import InputOnboarding from '../_common/InputOnboarding';
import CustomSelectOnboarding from './CustomSelectOnboarding';

const SelectWithOthers = ({ name, label, setShowOtherInput, ...rest }) => {
  const { t } = useTranslation();

  const { values } = useFormikContext();
  const showOtherInput = values[name]?.includes(OTHERS_VALUE);

  useEffect(() => {
    if (showOtherInput) {
      setShowOtherInput(showOtherInput);
    }
  }, [showOtherInput, setShowOtherInput]);

  return (
    <>
      <CustomSelectOnboarding name={name} label={label} {...rest} />
      {showOtherInput && (
        <Box>
          <InputOnboarding type="text" name={OTHERS_INPUT_NAME_MAP[name]} label={t('Please specify')} placeholder="" />
        </Box>
      )}
    </>
  );
};

SelectWithOthers.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setShowOtherInput: PropTypes.func.isRequired,
};

export default SelectWithOthers;
