import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { string } from 'prop-types';
import SignUpFormFooter from '../_common/SignUpFormFooter';
import SubmitButton from '../_common/SubmitButton';
import { ButtonRounded } from '../../atoms';
import { useRegistrationStorage } from '../../../hooks/registration';

const SignUpFooter = ({ primaryButtonText, previousRoute }) => {
  const { t } = useTranslation();
  const [registrationSession, setRegistrationSession] = useRegistrationStorage();
  const { push } = useHistory();

  const handleBack = () => {
    setRegistrationSession({
      ...registrationSession,
      onboardingStatus: previousRoute.name,
    });
    push(previousRoute.route);
  };

  return (
    <SignUpFormFooter>
      <SubmitButton showIcon={false} width="160px">
        {t(primaryButtonText)}
      </SubmitButton>
      {previousRoute && (
        <ButtonRounded
          type="button"
          color="secondary"
          variant="contained"
          height="48px"
          fontWeight="500"
          onClick={() => handleBack()}
        >
          {t('Back')}
        </ButtonRounded>
      )}
    </SignUpFormFooter>
  );
};

SignUpFooter.propTypes = {
  primaryButtonText: string.isRequired,
  previousRoute: string,
};

SignUpFooter.defaultProps = {
  previousRoute: null,
};

export default SignUpFooter;
