import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import isEmpty from 'lodash/isEmpty';
import { useGetDisputeStats } from '../../../../hooks/payment-disputes/useGetDisputesStats';
import StaItem from './StatItem';
import DisputeStatsLoadingState from './LoadingState';
import useSearch from '../../../../hooks/useSearch';

const DisputeStats = () => {
  const { loading, disputeStats } = useGetDisputeStats();
  const isLoading = loading && isEmpty(disputeStats);
  const [searchParams, setSearchParams] = useSearch();

  const handleStatSelect = (e) => {
    e.preventDefault();
    const { label } = e.target.dataset;

    const alreadyChecked = searchParams?.filter?.paymentStatus.includes(label);

    if (alreadyChecked) {
      setSearchParams((prevSearchParams) => {
        const newPaymentStatus = prevSearchParams?.filter?.paymentStatus.filter((status) => status !== label);

        return { ...prevSearchParams, filter: { ...prevSearchParams?.filter, paymentStatus: newPaymentStatus } };
      });

      return;
    }

    setSearchParams((prevSearchParams) => {
      const currentStatusFilter = prevSearchParams?.filter?.paymentStatus || [];

      return {
        ...prevSearchParams,
        filter: { ...prevSearchParams?.filter, paymentStatus: [...currentStatusFilter, label] },
      };
    });
  };

  return (
    <>
      {isLoading && <DisputeStatsLoadingState />}
      {!isLoading && (
        <>
          <Grid container spacing={2} alignItems="flex-start">
            {disputeStats.amountStats?.map((stat) => (
              <StaItem key={stat.label} stat={stat} handleStatSelect={handleStatSelect} />
            ))}
          </Grid>
          <Box width="100%" mt="8px">
            <Grid container spacing={2} alignItems="flex-start">
              {disputeStats.countStats?.map((stat) => (
                <StaItem key={stat.label} stat={stat} handleStatSelect={handleStatSelect} />
              ))}
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default DisputeStats;
