import styled from 'styled-components';
import THEME from '../../../../constants/theme';

const StyledButton = styled.div`
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
  padding: 9px 8px 9px 10px;
  height: 32px;
  border-radius: 4px;

  &:hover {
    background-color: ${THEME.primaryColors.primaryLight};
    cursor: pointer;
  }
`;

export { StyledButton };
