import React, { useMemo } from 'react';
import { useToggle } from 'react-use';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import noop from 'lodash/noop';
import { Box } from '@mui/material';
import { useMutation } from '@apollo/client';
import THEME from '../../../constants/theme';
import StyledTooltip from '../../styled/StyledTooltip';
import VersionsModal from './VersionsModal';
import { useFlowEditorContext } from '../../FlowEditor/context';
import ButtonWithIcon from '../../atoms/Buttons/ButtonWithIcon';
import CaretUpDown from '../../../assets/icons/CaretUpDown';
import { VerticalLine } from '../styled';
import Play from '../../../assets/icons/VersionControl/Play';
import { GQL_M_DISCARD_FLOW_CHANGES } from '../../../utils/queries/flows/mutations';
import { getOperationNameForQuery } from '../../../utils/graphql';
import { GET_FLOW, GET_VERSION_HISTORY_FOR_FLOW } from '../../../utils/queries/flows/queries';
import ConfirmModal from '../../FlowEditor/components/ConfirmModal';
import VersionControls from '../VersionControls';

const VersionControl = ({ handlePublish }) => {
  const { t } = useTranslation();
  const [modalOpened, toggleModalOpened] = useToggle(false);
  const [isDiscardModalOpen, toggleDiscardModalOpen] = useToggle(false);
  const { versionHistory, versionId, draftConfig, config, flowId } = useFlowEditorContext();
  const [discardFlow, { loading: discardFlowLoading }] = useMutation(GQL_M_DISCARD_FLOW_CHANGES, {
    variables: { flowId },
    refetchQueries: [getOperationNameForQuery(GET_FLOW), getOperationNameForQuery(GET_VERSION_HISTORY_FOR_FLOW)],
  });
  const hasOnlyOne = versionHistory?.length === 1;

  const isDraft = !isEmpty(versionHistory) && !hasOnlyOne && draftConfig;

  const versionName = useMemo(
    () =>
      t(
        `Version ${
          draftConfig || versionId
            ? versionHistory.length - versionHistory.findIndex((item) => item.id === versionId)
            : versionHistory.length
        }`
      ),
    [draftConfig, t, versionHistory, versionId]
  );

  const isActive = draftConfig ? config?.id === versionId : !versionId;

  return (
    <>
      <Box mr="8px" display="flex">
        <StyledTooltip title={!isEmpty(versionHistory) && !hasOnlyOne ? '' : t('No other versions available now')}>
          {isDraft && !versionId ? (
            <>
              <ButtonWithIcon
                text={`Version ${versionHistory.length} (Draft)`}
                endIcon={<CaretUpDown fill={THEME.primaryColors.black} />}
                color={THEME.primaryColors.black}
                bgColor={THEME.primaryColors.white}
                onClick={toggleModalOpened}
                p="8px 18px"
              />
              {!versionId && (
                <Box display="flex" gap="1px" ml="4px">
                  <ButtonWithIcon
                    text="Publish"
                    color={THEME.primaryColors.primaryD20}
                    bgColor={THEME.primaryColors.primaryLight}
                    onClick={handlePublish}
                    p="8px 18px"
                    $rightFlat
                  />
                  <ButtonWithIcon
                    text="Discard"
                    color={THEME.secondaryColors.nodeError}
                    bgColor={THEME.secondaryColors.negativeRank}
                    onClick={toggleDiscardModalOpen}
                    p="8px 18px"
                    $leftFlat
                  />
                </Box>
              )}
            </>
          ) : (
            (!isEmpty(versionHistory) && !hasOnlyOne && (
              <ButtonWithIcon
                text={versionName}
                startIcon={isActive && <Play color={THEME.secondaryColors.greenDark} />}
                endIcon={<CaretUpDown fill={THEME.primaryColors.black} />}
                color={THEME.primaryColors.black}
                bgColor={THEME.primaryColors.white}
                onClick={toggleModalOpened}
                p="8px 18px"
              />
            )) || (
              <ButtonWithIcon
                text={draftConfig ? t('Draft') : t('Version 1')}
                color={THEME.primaryColors.black}
                bgColor={THEME.primaryColors.white}
                startIcon={config && <Play size="17" color={THEME.secondaryColors.greenDark} />}
                onClick={noop}
                p="8px 18px"
              />
            )
          )}
          {versionId && <VersionControls />}
        </StyledTooltip>
      </Box>
      <VerticalLine $marginRight="8px" />
      <ConfirmModal
        isOpen={isDiscardModalOpen}
        title="Confirm Change"
        text="Are you sure you want to discard your Draft Automation?"
        confirmActionText="Confirm"
        closeModal={toggleDiscardModalOpen}
        handleSubmit={discardFlow}
        isDataSaving={discardFlowLoading}
      />
      {modalOpened && <VersionsModal items={versionHistory} handlePublish={handlePublish} />}
    </>
  );
};

VersionControl.propTypes = {
  handlePublish: func.isRequired,
};

export default VersionControl;
