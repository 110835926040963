import React, { useCallback, useState } from 'react';
import { Formik, Form } from 'formik';
import { Box } from '@mui/material';
import { useMutation } from '@apollo/client';
import { func, bool, string, arrayOf, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CUSTOM_BUTTON_PROPS, WT_CONNECTION_FORM_ID } from '../constant';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledPaper,
} from '../../../../components/Dialog/styled';
import ConnectionFormModalHeader from '../ConnectionFormModalHeader';
import { ButtonRounded, CircularLoader } from '../../../../components/atoms';
import ConnectionForm from '../../../../components/forms/GenerateForm/components/ConnectionForm';
import { getConnectionModalDescription } from '../../../../components/forms/GenerateForm/constant';
import { FlexContainer } from '../../../../components/atoms/flex/FlexContainer';
import { UPDATE_CONNECTION_CONFIG } from './queries';
import { showNotification } from '../../../../hooks/useNotificationManager';
import { TOAST_TIMEOUT, TOAST_TYPES } from '../../../../constants/toasts';
import generateValidator from './validator';
import { useGlobalContext } from '../../../../containers/App/context';

const TOAST_MESSAGE_ERROR = 'We couldn’t authenticate your connection. Check credentials and try again.';
const TOAST_MESSAGE_SUCCESS = 'Configuration is successfully updated.';
const TOAST_TITLE = 'Configuration.';

const WebhookConfigurationModal = ({ setShouldModalOpen, shouldModalOpen, connection, goBack, form }) => {
  const { t } = useTranslation();
  const [, setShowErrors] = useState(false);
  const { setGlobalLoading } = useGlobalContext();
  const [updateConnectionConfig, { loading }] = useMutation(UPDATE_CONNECTION_CONFIG);

  const connectionCategory = connection?.company?.categories?.[0] ?? '';
  const connectionName = connection?.company?.name;
  const connectionLogo = connection?.company?.logo;

  const description = getConnectionModalDescription(connectionName, connectionCategory);

  const inputs = form.filter((element) => element.type === 'input');
  const initialValues = inputs.reduce((acc, curr) => {
    acc[curr.block_id] = curr.initial_value;

    return acc;
  }, {});

  const handleDisallowBubbling = (e) => {
    e.stopPropagation();
  };

  const handleGoBack = () => {
    goBack();
  };

  const handleClose = useCallback(() => {
    setShouldModalOpen();
  }, [setShouldModalOpen]);

  const handleModalClose = () => {
    setShouldModalOpen(false);
  };

  const handleSubmit = async (values) => {
    setGlobalLoading('updateConnectionConfig', true);

    const id = connection?.id;
    const formInput = values;

    const { data, errors } = await updateConnectionConfig({
      variables: { id, formInput },
      context: { skipGlobalHandling: true },
    });

    const updateConnectionConfigData = data?.updateConnectionConfig;

    if (errors && !updateConnectionConfigData) {
      showNotification(TOAST_TYPES.error, TOAST_MESSAGE_ERROR, TOAST_TITLE, TOAST_TIMEOUT);
    }

    if (!errors && updateConnectionConfigData) {
      handleModalClose();
      showNotification(TOAST_TYPES.success, TOAST_MESSAGE_SUCCESS, TOAST_TITLE, TOAST_TIMEOUT);
    }

    setGlobalLoading('updateConnectionConfig', false);
  };

  return (
    <StyledDialog
      scroll="paper"
      maxWidth="xl"
      $height="700px"
      PaperComponent={StyledPaper}
      aria-labelledby={WT_CONNECTION_FORM_ID}
      open={shouldModalOpen}
      onClick={handleDisallowBubbling}
      onClose={handleClose}
    >
      <>
        <ConnectionFormModalHeader id={WT_CONNECTION_FORM_ID} handleClose={handleClose} handleGoBack={handleGoBack} />

        <StyledDialogContent px="0 0 32px">
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={generateValidator(inputs)}>
            <Form>
              <ConnectionForm
                connectionName={connectionName}
                connectionLogo={connectionLogo}
                description={description}
                form={form}
                showErrors
                setShowErrors={setShowErrors}
                loading={loading}
              />

              <FlexContainer {...CUSTOM_BUTTON_PROPS}>
                <Box mr="16px">
                  <ButtonRounded type="button" variant="contained" color="secondary" onClick={handleModalClose}>
                    {t('Close')}
                  </ButtonRounded>
                </Box>

                <ButtonRounded type="submit" variant="contained" color="primary" disabled={loading}>
                  {loading ? <CircularLoader size={20} bgcolor="#fff" /> : t('Submit')}
                </ButtonRounded>
              </FlexContainer>
            </Form>
          </Formik>
        </StyledDialogContent>

        <StyledDialogActions px="24px" py="42px" $borderTop>
          &nbsp;
        </StyledDialogActions>
      </>
    </StyledDialog>
  );
};

WebhookConfigurationModal.propTypes = {
  setShouldModalOpen: func.isRequired,
  shouldModalOpen: bool.isRequired,
  connection: shape({}).isRequired,
  goBack: func.isRequired,
  form: arrayOf(
    shape({
      type: string.isRequired,
      text: shape({ type: string, text: string.isRequired }),
    })
  ).isRequired,
};

export default WebhookConfigurationModal;
