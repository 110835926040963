const signUpPaymentDetailsNames = {
  paymentModel: 'paymentModel',
  paymentModelOther: 'paymentModelOther',
  paymentServiceProvider: 'paymentServiceProvider',
  paymentServiceProviderOther: 'paymentServiceProviderOther',
  paymentTransactions: 'paymentTransactions',
  paymentTransactionsOther: 'paymentTransactionsOther',
  paymentGoals: 'paymentGoals',
  paymentGoalsOther: 'paymentGoalsOther',
  paymentProcessor: 'paymentProcessor',
  paymentProcessors: 'paymentProcessors',
  paymentProcessorsOther: 'paymentProcessorsOther',
  acceptedTC: 'acceptedTC',
  acceptedNewsletter: 'acceptedNewsletter',
};

const signUpPaymentDetailsFields = [
  { field: signUpPaymentDetailsNames.paymentModel, label: 'What Is Your Payment Model?' },
  { field: signUpPaymentDetailsNames.paymentServiceProvider, label: 'Who Are Your Current Payment Service Providers?' },
  { field: signUpPaymentDetailsNames.paymentTransactions, label: 'How Many Transactions Do You Process Per Month?' },
  { field: signUpPaymentDetailsNames.paymentGoals, label: 'What Are Your Payment Goals?' },
  { field: signUpPaymentDetailsNames.paymentProcessor, label: 'Have You Selected a Payment Processor Yet?' },
  { field: signUpPaymentDetailsNames.paymentProcessors, label: 'Which Payment Processor(s) have you selected?' },
];

const initialValues = {
  [signUpPaymentDetailsNames.paymentModel]: [],
  [signUpPaymentDetailsNames.paymentModelOther]: '',
  [signUpPaymentDetailsNames.paymentServiceProvider]: [],
  [signUpPaymentDetailsNames.paymentServiceProviderOther]: '',
  [signUpPaymentDetailsNames.paymentTransactions]: [],
  [signUpPaymentDetailsNames.paymentTransactionsOther]: '',
  [signUpPaymentDetailsNames.paymentGoals]: [],
  [signUpPaymentDetailsNames.paymentGoalsOther]: '',
  [signUpPaymentDetailsNames.paymentProcessor]: '',
  [signUpPaymentDetailsNames.paymentProcessors]: [],
  [signUpPaymentDetailsNames.paymentProcessorsOther]: '',
};

export { initialValues, signUpPaymentDetailsFields, signUpPaymentDetailsNames };
