import React from 'react';
import Box from '@mui/material/Box';
import { string } from 'prop-types';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Skeleton from '@mui/lab/Skeleton';
import Title from '../../Title';
import PropertyTextEditor from '../../../../../PropertyTextEditor';
import { useValidationMessage, useFlowPropertiesList } from './hooks';
import THEME from '../../../../../../constants/theme';
import { useNodeDataToSave } from './hooks/useNodeDataToSave';

const MultilineTemplateField = ({ property, label }) => {
  const [{ actionId, layout, ...nodeProperties }, updateDataToSave] = useNodeDataToSave();
  const propertyValue = nodeProperties[property];
  const propertyObject = layout?.uiMetadata?.[property];
  const { getAvailableProperties, loading } = useFlowPropertiesList([], 'network-only');

  const handleSave = (text, object) => {
    updateDataToSave({
      [property]: text,
      layout: { uiMetadata: { ...layout?.uiMetadata, [property]: object } },
    });
  };

  const validationMessage = useValidationMessage(property);

  const borderColor = validationMessage ? THEME.secondaryColors.invalid : '#C1C3C6';

  return (
    <Box>
      <Title>{label}</Title>

      {loading && (
        <Box borderRadius="4px" overflow="hidden" height="100px" mb="12px" width="100%">
          <Skeleton height="100px" width="100%" animation="wave" />
        </Box>
      )}

      {!loading && (
        <FormControl variant="standard" fullWidth error={!!validationMessage}>
          <PropertyTextEditor
            showToolbar={false}
            initialEditorText={propertyValue}
            border={`1px solid ${borderColor}`}
            handleSavePlainText={handleSave}
            properties={getAvailableProperties}
            initialOpsObject={propertyObject}
          />

          <FormHelperText>{validationMessage}</FormHelperText>
        </FormControl>
      )}
    </Box>
  );
};

MultilineTemplateField.propTypes = {
  property: string.isRequired,
  label: string.isRequired,
};

export default MultilineTemplateField;
