import PropTypes from 'prop-types';
import React from 'react';

const Info = ({ fill }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.33314 6.8889C6.33314 6.58207 6.58188 6.33334 6.8887 6.33334H7.99981C8.30664 6.33334 8.55537 6.58207 8.55537 6.8889V9.66668H9.66648C9.97331 9.66668 10.222 9.9154 10.222 10.2222C10.222 10.5291 9.97331 10.7778 9.66648 10.7778H6.33314C6.02632 10.7778 5.77759 10.5291 5.77759 10.2222C5.77759 9.9154 6.02632 9.66668 6.33314 9.66668H7.44425V7.44445H6.8887C6.58188 7.44445 6.33314 7.19573 6.33314 6.8889Z"
        fill={fill}
      />
      <path
        d="M7.72225 5.77787C8.18247 5.77787 8.55558 5.40478 8.55558 4.94454C8.55558 4.4843 8.18247 4.11121 7.72225 4.11121C7.26203 4.11121 6.88892 4.4843 6.88892 4.94454C6.88892 5.40478 7.26203 5.77787 7.72225 5.77787Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33325 8.00001C1.33325 4.31811 4.31802 1.33334 7.99992 1.33334C11.6818 1.33334 14.6666 4.31811 14.6666 8.00001C14.6666 11.6819 11.6818 14.6667 7.99992 14.6667C4.31802 14.6667 1.33325 11.6819 1.33325 8.00001ZM7.99992 2.44445C4.93167 2.44445 2.44436 4.93176 2.44436 8.00001C2.44436 11.0682 4.93167 13.5556 7.99992 13.5556C11.0681 13.5556 13.5555 11.0682 13.5555 8.00001C13.5555 4.93176 11.0681 2.44445 7.99992 2.44445Z"
        fill={fill}
      />
    </svg>
  );
};

Info.propTypes = {
  fill: PropTypes.string,
};
Info.defaultProps = {
  fill: '#787F88',
};

export default Info;
