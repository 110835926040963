import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import isEmpty from 'lodash/isEmpty';
import { TOAST_TIMEOUT } from '../constants/toasts';
import ToastCustomContainer from '../components/ToastCustomContainer';

export function checkIfMessageExist() {
  return NotificationManager.listNotify.length !== 0;
  //TODO: decide if needed to have few stacked errors.
  // current library has a bug if shows more then one toast at time
  // delete this code if only 1 error at time needed

  // return (
  // NotificationManager.listNotify.filter((i) => {
  //   console.log(i.message, String(message));
  //   if (message) {
  //     return i.message === String(message);
  //   }
  //   return i.title === title;
  // }).length !== 0
  // );
}

/**
 * @description https://www.npmjs.com/package/react-notifications
 * @param {'info'|'success'|'warning'|'error'} type
 * @param {string} message
 * @param {string} title
 * @param {number?} timeOut
 * @param {function?} callback
 * @param {boolean?} priority
 * */

/**
 * TODO
 * Use "showNotification" instead of defining "NotificationManager" in each places
 * ie. NotificationManager.success(<ToastCustomContainer message={message} title={title} />, null, TOAST_TIMEOUT) in each components.
 * At the moment we're calling <ToastCustomContainer/> in multiple places in our code.
 */

export const showNotification = (type, message, title, timeOut = TOAST_TIMEOUT, callback, priority) => {
  NotificationManager[type](
    <ToastCustomContainer message={message} title={title} />,
    null,
    timeOut,
    callback,
    priority
  );
};

export const useNotificationManager = (type, message, title, timeOut, callback, priority) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!message) {
      return;
    }
    if (checkIfMessageExist()) {
      return;
    }
    NotificationManager[type](
      <ToastCustomContainer message={message} title={title} />,
      null,
      timeOut,
      callback,
      priority
    );
  }, [type, message, title, timeOut, callback, priority, t]);
};

/**
 * @param {string} maxFileSize
 * @param {string} title
 * @param errors
 * */

export const useFileUploadNotification = (maxFileSize, title, errors) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!errors) {
      return;
    }
    const fileTooLarge = errors[0]?.errors[0]?.code === 'file-too-large';
    const errorMessage = errors[0]?.errors[0]?.message;

    if (errors?.length && fileTooLarge) {
      NotificationManager.error(
        <ToastCustomContainer
          message={`File is larger than ${isEmpty(maxFileSize) ? errorMessage : maxFileSize}`}
          title={title}
        />,
        null,
        TOAST_TIMEOUT
      );
    } else if (errors?.length && !fileTooLarge) {
      NotificationManager.error(
        <ToastCustomContainer message={errors[0]?.errors[0]?.message} title={title} />,
        null,
        TOAST_TIMEOUT
      );
    }
  }, [maxFileSize, title, errors, t]);
};
