import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import {
  GET_DISPUTE_EVIDENCE_FORM,
  GET_DISPUTE_EVIDENCE_FILES_CONFIG,
} from '../../../../../../../utils/queries/disputes/listDisputes';
import { getUuid } from '../../constant';
import { GET_DISPUTE_EVIDENCE_FORM_TOAST_TITLE, showErrorNotification } from './constant';

const useGetDisputeEvidenceFormAndFilesConfig = (connectionId, sourceDisputeId) => {
  const { loading: filesConfigLoading, data: filesConfigData } = useQuery(GET_DISPUTE_EVIDENCE_FILES_CONFIG, {
    variables: { connectionId: getUuid(connectionId), sourceDisputeId },
  });

  const { loading: formLoading, data: formData, error } = useQuery(GET_DISPUTE_EVIDENCE_FORM, {
    variables: { connectionId: getUuid(connectionId), sourceDisputeId },
    context: { skipGlobalHandling: true },
    fetchPolicy: 'cache-and-network',
  });

  if (!isEmpty(error)) {
    showErrorNotification(error.graphQLErrors, GET_DISPUTE_EVIDENCE_FORM_TOAST_TITLE);
  }

  // FilesConfig : used for file upload restriction
  const filesConfig = filesConfigData?.getDisputeEvidenceFilesConfig?.fileFormats;

  // FileTypes : only used for Adyen dispute
  const fileTypes = filesConfigData?.getDisputeEvidenceFilesConfig?.fileTypes;

  const defenseReasons = useMemo(() => [...new Set(fileTypes?.map((item) => item.fileTypeCategory))], [fileTypes]);
  const defenseReasonOptions = useMemo(() => defenseReasons?.map((reason) => ({ value: reason, title: reason })), [
    defenseReasons,
  ]);

  const form = formData?.getDisputeEvidenceForm?.form;

  return {
    filesConfigLoading,
    loading: formLoading,
    form,
    filesConfig,
    defenseReasonOptions,
    fileTypes,
  };
};

export default useGetDisputeEvidenceFormAndFilesConfig;
