import React from 'react';
import { bool, func, string } from 'prop-types';
import { StyledDialog, StyledPaper } from '../../../Dialog/styled';
import THEME from '../../../../constants/theme';
import CloseButton from '../../../Dialog/CloseButton';
import { StyledVideoWrapper } from './styled';
import { StyledIframe } from '../../../atoms/StyledIframe/StyledIframe';

const AnnotationVideoModal = ({ open, setOpen, videoUrl, setVideoModalUrl }) => {
  const handleClose = () => {
    setVideoModalUrl(null);
    setOpen(false);
  };

  return (
    <StyledDialog
      open={open}
      scroll="paper"
      maxWidth="xs"
      PaperComponent={StyledPaper}
      aria-labelledby="video-player-modal"
      width="1040px"
      $height="585px"
      PaperProps={{
        $borderRadius: '8px',
        $overflowY: 'visible',
      }}
    >
      <>
        <CloseButton
          onClick={handleClose}
          $bgcolor={THEME.primaryColors.white}
          $hoverBg={THEME.primaryColors.white}
          $borderRadius="50%"
          top="-20px"
          right="-20px"
        />
        <StyledVideoWrapper>
          <StyledIframe title="embed-video" src={videoUrl} frameBorder="0" allowFullScreen />
        </StyledVideoWrapper>
      </>
    </StyledDialog>
  );
};

AnnotationVideoModal.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
  videoUrl: string.isRequired,
  setVideoModalUrl: func,
};

AnnotationVideoModal.defaultProps = {
  setVideoModalUrl: () => {},
};

export default AnnotationVideoModal;
