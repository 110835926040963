import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledMenuItem, StyledPaper } from '../atoms/Select/StyledSelect';
import { StyledSelect } from '../FlowEditor/components/AutomationDetails/AutomationStep/fields/Logical/styled';
import SwitchIcon from '../../assets/icons/FlowMonitor/SwitchIcon';
import THEME from '../../constants/theme';
import { useFlowEditorContext } from '../FlowEditor/context';

const InstanceRetriesSelect = () => {
  const { t } = useTranslation();
  const {
    selectedInstanceRetries,
    selectedInstanceId,
    setSelectedInstanceId,
    selectedInstance,
  } = useFlowEditorContext();

  const originalRun = useMemo(
    () => ({
      title: 'Original Run',
      value: selectedInstance?.originalInstanceId || selectedInstanceId,
    }),
    [selectedInstance?.originalInstanceId, selectedInstanceId]
  );

  const retriesWithoutOriginalRun = selectedInstanceRetries?.filter((retry) => retry.id !== originalRun.value);
  const retriesCount = retriesWithoutOriginalRun?.length;

  const retryOptions = selectedInstanceRetries
    ?.filter((retry) => retry.id !== originalRun.value)
    .map((retry, i) => ({
      title: `Retry ${i + 1} of ${retriesCount}`,
      value: retry.id,
    }));

  const options = useMemo(() => [originalRun, ...retryOptions], [originalRun, retryOptions]);

  const handleChange = ({ target: { value } }) => setSelectedInstanceId(value);

  return (
    <StyledSelect
      name="instance-retries"
      value={selectedInstanceId}
      IconComponent={SwitchIcon}
      MenuProps={{
        getContentAnchorEl: null,
        PaperProps: {
          component: StyledPaper,
        },
      }}
      $color={THEME.primaryColors.blackPlain}
      $bgColor={THEME.primaryColors.white}
      $padding="12px 32px 12px 22px"
      $iconTop="14px"
      $iconRight="10px"
      onChange={handleChange}
    >
      {options.map((option) => (
        <StyledMenuItem key={option.title} value={option.value}>
          {t(option.title)}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  );
};

export default InstanceRetriesSelect;
