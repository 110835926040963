import styled from 'styled-components';

export const StyledWrapper = styled.div`
  position: absolute;
  box-sizing: border-box;
  display: flex;
  top: 26px;
  left: 50%;
  transform: translateX(-50%);
`;
