import React from 'react';
import Box from '@mui/material/Box';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { L16B } from '../../../../components/atoms/Typography/L16B';
import { UI_ROUTES } from '../../../../constants/routes';
import { usePaymentFlowContext } from '../../../FlowDetailsPage/paymentFlowContext';
import AutomationsEmptyState from '../AutomationsEmptyState';
import { getRows } from './tableData';
import LoadingState from './LoadingState';
import { StyledBox, StyledWrapper } from './styled';
import AutomationRow from './AutomationRow';
import { getComparator, stableSort } from '../../../../utils/helpers';
import useSearchSort from '../../../../hooks/useSearchSort';
import { getOrderDataFromSearchParamsSort } from '../../../../components/SortTable/helpers';
import DropDownMenu from '../../../../components/menus/DropDownMenu';
import { P14M } from '../../../../components/atoms';
import THEME from '../../../../constants/theme';
import Switch from '../../../../assets/icons/Switch';
import Download from '../../../../assets/icons/Download';
import { useGlobalContext } from '../../../../containers/App/context';
import { SWITCH_ENV_STEPS } from '../../../../components/SwitchEnv/constant';
import { APP_ENVIRONMENT } from '../../../../constants/api';
import StyledTooltip from '../../../../components/styled/StyledTooltip';

const templateId = 'template.freeform-automation';

const options = [
  { label: 'alphabetical', value: 'name', selectedLabel: 'name' },
  { label: 'newest', value: 'systemCreated', selectedLabel: 'date created', reverse: true },
  { label: 'Last updated', value: 'systemUpdated', selectedLabel: 'date updated' },
  { label: 'Running', value: 'running', selectedLabel: 'status', reverse: true },
  { label: 'Draft', value: 'draft', selectedLabel: 'draft', reverse: true },
  { label: 'Advanced', value: 'payment processing', selectedLabel: 'advanced', reverse: true },
  { label: 'Errors', value: 'errors', selectedLabel: 'errors count', reverse: true },
];

const Automations = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { flow, flowId, loading } = usePaymentFlowContext();
  const { setIsOpenSwitchEnvModal, setCurrentSwitchEnvStep, canMigrateCustomer } = useGlobalContext();
  const isProd = APP_ENVIRONMENT === 'production';
  const isAlreadyMigrated = canMigrateCustomer?.isAlreadyMigrated;
  const automationsList = flow?.automations ?? [];
  const automationsRows = getRows(automationsList, push);
  const helpText = t('An account owner must create a production account before you can export transactions');

  const handleExploreAutomations = () => {
    push(UI_ROUTES.automationsDirectory);
  };

  const handleAddTemplate = () => {
    push(`${UI_ROUTES.automationsTemplatesBasic}/${templateId}/${flowId}`);
  };

  const handleAutomationClick = (automationId) => {
    push({
      pathname: `/automations/${automationId}/editor`,
      state: { prevRoute: 'flow' },
    });
  };

  const handleExportClick = () => {
    if (automationsList?.length) {
      setCurrentSwitchEnvStep(SWITCH_ENV_STEPS.MIGRATE_AUTOMATIONS_ONLY);
      setIsOpenSwitchEnvModal(true);
    }
  };

  const [order, setOrder] = useSearchSort(true);

  const { order: orderKey, orderBy } = getOrderDataFromSearchParamsSort(order);

  const currentSort = options.find((item) => item.value === orderBy);

  const isReverse = currentSort?.reverse;

  const _rows = isReverse
    ? stableSort(automationsRows, getComparator(orderKey, orderBy))?.reverse()
    : stableSort(automationsRows, getComparator(orderKey, orderBy));

  const optionsWithFunc = options.map(({ label, value }) => ({ [label]: () => setOrder(value) }));

  const label = currentSort?.selectedLabel || 'status';

  return (
    <Box border="1px">
      <StyledBox>
        <L16B>Automations</L16B>
        <StyledBox>
          <DropDownMenu
            id="sort"
            button={
              <StyledBox $cursor="pointer">
                <P14M color={THEME.primaryColors.primary} display="flex" alignItems="center" margin="0 8px 0 0">
                  {t('Sort by')} {label}
                </P14M>
                <Switch />
              </StyledBox>
            }
            options={optionsWithFunc}
          />
          {!isProd && isAlreadyMigrated ? (
            <StyledBox ml="20px" $cursor="pointer" onClick={handleExportClick}>
              <P14M color={THEME.primaryColors.primary} display="flex" alignItems="center" margin="0 8px 0 0">
                {t('Export')}
              </P14M>
              <Download fill={THEME.primaryColors.primary} />
            </StyledBox>
          ) : null}
          {!isProd && !isAlreadyMigrated ? (
            <StyledTooltip title={helpText} placement="top" maxWidth={230}>
              <StyledBox ml="20px">
                <P14M color={THEME.primaryColors.primaryDisabled} display="flex" alignItems="center" margin="0 8px 0 0">
                  {t('Export')}
                </P14M>
                <Download fill={THEME.primaryColors.primaryDisabled} />
              </StyledBox>
            </StyledTooltip>
          ) : null}
        </StyledBox>
      </StyledBox>
      <Box component="section" mt="16px">
        <StyledWrapper $hideSeparator>
          {loading && <LoadingState />}
          {!loading && isEmpty(automationsList) && (
            <AutomationsEmptyState
              handleAddTemplate={handleAddTemplate}
              handleExploreAutomations={handleExploreAutomations}
            />
          )}
          {!loading &&
            !isEmpty(automationsList) &&
            _rows?.map((row) => <AutomationRow data={row} handleClick={handleAutomationClick} key={row?.id} />)}
        </StyledWrapper>
      </Box>
    </Box>
  );
};

export default Automations;
