import PropTypes from 'prop-types';
import React from 'react';

const Notification = ({ hasNotification }) => {
  return (
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-outside-1" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="24" fill="black">
        <rect fill="white" width="20" height="24" />
        <path d="M8 20H12C12 20.5304 11.7893 21.0391 11.4142 21.4142C11.0391 21.7893 10.5304 22 10 22C9.46957 22 8.96086 21.7893 8.58579 21.4142C8.21071 21.0391 8 20.5304 8 20ZM17.71 15.71L16.88 14.88C16.3172 14.3179 16.0007 13.5554 16 12.76V10C16 8.70178 15.5789 7.43858 14.8 6.4L13.9 5.2C13.6206 4.82741 13.2582 4.525 12.8416 4.31672C12.4251 4.10844 11.9657 4 11.5 4H11V2.5C11 2.36739 10.9473 2.24021 10.8536 2.14645C10.7598 2.05268 10.6326 2 10.5 2H9.5C9.36739 2 9.24021 2.05268 9.14645 2.14645C9.05268 2.24021 9 2.36739 9 2.5V4H8.5C8.03426 4 7.57493 4.10844 7.15836 4.31672C6.74179 4.525 6.37944 4.82741 6.1 5.2L5.2 6.4C4.42107 7.43858 4 8.70178 4 10V12.76C3.9993 13.5554 3.68277 14.3179 3.12 14.88L2.29 15.71C2.10526 15.8963 2.0011 16.1477 2 16.41V17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18H17C17.2652 18 17.5196 17.8946 17.7071 17.7071C17.8946 17.5196 18 17.2652 18 17V16.41C17.9989 16.1477 17.8947 15.8963 17.71 15.71Z" />
      </mask>
      <path
        d="M8 20H12C12 20.5304 11.7893 21.0391 11.4142 21.4142C11.0391 21.7893 10.5304 22 10 22C9.46957 22 8.96086 21.7893 8.58579 21.4142C8.21071 21.0391 8 20.5304 8 20ZM17.71 15.71L16.88 14.88C16.3172 14.3179 16.0007 13.5554 16 12.76V10C16 8.70178 15.5789 7.43858 14.8 6.4L13.9 5.2C13.6206 4.82741 13.2582 4.525 12.8416 4.31672C12.4251 4.10844 11.9657 4 11.5 4H11V2.5C11 2.36739 10.9473 2.24021 10.8536 2.14645C10.7598 2.05268 10.6326 2 10.5 2H9.5C9.36739 2 9.24021 2.05268 9.14645 2.14645C9.05268 2.24021 9 2.36739 9 2.5V4H8.5C8.03426 4 7.57493 4.10844 7.15836 4.31672C6.74179 4.525 6.37944 4.82741 6.1 5.2L5.2 6.4C4.42107 7.43858 4 8.70178 4 10V12.76C3.9993 13.5554 3.68277 14.3179 3.12 14.88L2.29 15.71C2.10526 15.8963 2.0011 16.1477 2 16.41V17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18H17C17.2652 18 17.5196 17.8946 17.7071 17.7071C17.8946 17.5196 18 17.2652 18 17V16.41C17.9989 16.1477 17.8947 15.8963 17.71 15.71Z"
        stroke="#787F88"
        strokeWidth="4"
        mask="url(#path-1-outside-1)"
        className="path"
      />
      {hasNotification && (
        <circle className="has-notification" cx="19" cy="6" r="5" fill="#DF5B5B" stroke="white" strokeWidth="2" />
      )}
    </svg>
  );
};

Notification.propTypes = {
  hasNotification: PropTypes.bool.isRequired,
};

export default Notification;
