import React from 'react';
import { NotificationManager } from 'react-notifications';
import ToastCustomContainer from '../../../../../../../components/ToastCustomContainer';
import { TOAST_TIMEOUT } from '../../../../../../../constants/toasts';

/* 
  Error messages from BE that are not readable to the users.

  TODO : Communicate with BE on this. Ideally, we should get a better error message from API call itself.
 */
const EXCEPTION_DICTIONARY = {
  disputeSubmissionError: 'dispute.submission.error',
  adyenReasonsError: 'error.get.applicable.reasons.adyen',
};

const CUSTOM_ERRORS = [EXCEPTION_DICTIONARY.disputeSubmissionError, EXCEPTION_DICTIONARY.adyenReasonsError];
const CUSTOM_ERROR_MESSAGE = 'An unexpected error occurred.';

/*
  For useDisputeEvidenceSubmissionActions
*/
const SAVE_DISPUTE_TOAST_TITLE = 'Save dispute evidence submission.';
const SUBMIT_DISPUTE_TOAST_TITLE = 'Submit dispute evidence.';

/*
  For useGetDisputeEvidenceFormAndFilesConfig
*/
const GET_DISPUTE_EVIDENCE_FORM_TOAST_TITLE = 'Fetch dispute form.';

const showErrorNotification = (errors, toastTitle) => {
  let errorMessage;
  if (!errors[0]?.extension) {
    errorMessage = CUSTOM_ERROR_MESSAGE;
  } else {
    const { code } = errors[0]?.extensions;
    errorMessage = CUSTOM_ERRORS.includes(code) ? CUSTOM_ERROR_MESSAGE : errors[0]?.message;
  }

  NotificationManager.error(<ToastCustomContainer message={errorMessage} title={toastTitle} />, null, TOAST_TIMEOUT);
};

export {
  CUSTOM_ERRORS,
  CUSTOM_ERROR_MESSAGE,
  SAVE_DISPUTE_TOAST_TITLE,
  SUBMIT_DISPUTE_TOAST_TITLE,
  GET_DISPUTE_EVIDENCE_FORM_TOAST_TITLE,
  showErrorNotification,
};
