import React, { useCallback } from 'react';
import { useField } from 'formik';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { InputFieldOnboarding, L12 } from '../../atoms';

const Error = styled(L12)`
  margin-top: 8px;
  font-weight: 500;

  & + & {
    margin-top: 0;
  }
`;

const Label = styled.label`
  display: block;
  text-align: left;
  width: 100%;

  > p:nth-child(1) {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
  }
`;

const InputOnboarding = ({
  name,
  type,
  label,
  customLabel,
  customLabelProps,
  normalize,
  normalizeProps,
  customErrorProps,
  skipError,
  width,
  skipTouched,
  height,
  ...rest
}) => {
  const [{ value, onBlur }, { touched, error }, { setValue }] = useField(name);
  const { t } = useTranslation();
  const hasError = !skipTouched
    ? touched && !skipError && Array.isArray(error) && error.length
    : !skipError && Array.isArray(error) && error.length;

  const handleOnChange = useCallback(
    (e) => {
      const { value: _value } = e.target;

      if (normalize) {
        setValue(normalize(_value, normalizeProps));
        return;
      }
      setValue(_value);
    },
    [setValue, normalize, normalizeProps]
  );

  return (
    <Box mb="16px" width={width}>
      <Label>
        <InputFieldOnboarding
          {...rest}
          height={height}
          variant="outlined"
          type={type}
          autoComplete="new-password"
          name={name}
          label={hasError ? t(error[0]) : label}
          value={value}
          onChange={handleOnChange}
          onBlur={onBlur}
          className={hasError ? 'has-error' : ''}
        />
      </Label>
    </Box>
  );
};

InputOnboarding.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  customLabel: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'password', 'email']).isRequired,
  customLabelProps: PropTypes.shape({}),
  normalize: PropTypes.func,
  customErrorProps: PropTypes.shape({}),
  normalizeProps: PropTypes.shape({}),
  skipError: PropTypes.bool,
  width: PropTypes.string,
  skipTouched: PropTypes.string,
  height: PropTypes.string,
};

InputOnboarding.defaultProps = {
  customLabel: null,
  customLabelProps: {},
  normalize: undefined,
  customErrorProps: {},
  normalizeProps: {},
  skipError: false,
  width: undefined,
  skipTouched: false,
  height: undefined,
};

export default InputOnboarding;
export { Label, Error };
