const useDropZoneFileConfig = (filesConfig) => {
  const acceptedFileTypes = filesConfig?.map((file) => file.contentType);

  const dropZoneConfig = {
    accept: acceptedFileTypes,
    multiple: false,
  };

  const fileSizeMap = filesConfig?.reduce((acc, curr) => {
    const key = curr.contentType;

    return { ...acc, [key]: curr.maxFileSizeMB };
  }, {});

  const customValidator = (file) => {
    const [, extension] = file.path.split('.');
    const size = file.size / 1000000;

    if (fileSizeMap[extension] < size) {
      return {
        code: 'file-too-large',
        message: `File is larger than ${fileSizeMap[extension]}mb.`,
      };
    }

    return null;
  };

  return { dropZoneConfig, customValidator };
};

export default useDropZoneFileConfig;
