import { v4 } from 'uuid';
import { TextCell, CustomerNameCell, SwitchCustomerCell } from '../../../table/ReactTableCells';

const CELL_SETTINGS = {
  disablePadding: false,
  numeric: false,
  width: 150,
};

const CUSTOMER_COLUMNS = {
  id: 'id',
  customerName: 'customer name',
  clientId: 'client id',
  teamName: 'team name',
  teamMembers: 'team members',
};

const HEAD_CELLS = [
  {
    accessor: CUSTOMER_COLUMNS.customerName,
    Header: CUSTOMER_COLUMNS.customerName,
    Cell: CustomerNameCell,
    ...CELL_SETTINGS,
  },
  {
    accessor: CUSTOMER_COLUMNS.clientId,
    Header: CUSTOMER_COLUMNS.clientId,
    Cell: TextCell,
    ...CELL_SETTINGS,
  },
  {
    accessor: CUSTOMER_COLUMNS.teamName,
    Header: CUSTOMER_COLUMNS.teamName,
    Cell: TextCell,
    ...CELL_SETTINGS,
  },
  {
    accessor: CUSTOMER_COLUMNS.teamMembers,
    Header: CUSTOMER_COLUMNS.teamMembers,
    Cell: SwitchCustomerCell,
    ...CELL_SETTINGS,
  },
];

const transformNodeToRow = (customers) => {
  return customers?.map((team) => ({
    [CUSTOMER_COLUMNS.id]: team?.id ?? v4(),
    [CUSTOMER_COLUMNS.customerName]: {
      name: team?.customer?.name ?? '',
      email: team?.customer?.billingDetails?.billingEmail ?? '',
    },
    [CUSTOMER_COLUMNS.clientId]: team?.customer?.clientId ?? '',
    [CUSTOMER_COLUMNS.teamName]: team?.name ?? '',
    [CUSTOMER_COLUMNS.teamMembers]: team?.membersCount ?? '',
  }));
};

export { HEAD_CELLS, transformNodeToRow };
