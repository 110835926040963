import React from 'react';
import PropTypes from 'prop-types';

const DataTables = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4.99994L4 5L4 5.00006C4 5.00095 3.99998 5.00521 4.00305 5.01529C4.00645 5.02644 4.01483 5.04845 4.03507 5.08143C4.07752 5.15057 4.16408 5.25453 4.32624 5.38232C4.65642 5.64253 5.19616 5.9206 5.95227 6.17264C7.45402 6.67322 9.59229 7 12 7C14.4077 7 16.546 6.67322 18.0477 6.17264C18.8038 5.9206 19.3436 5.64253 19.6738 5.38232C19.8359 5.25453 19.9225 5.15057 19.9649 5.08143C19.9852 5.04845 19.9935 5.02644 19.9969 5.01529C20 5.00521 20 5.00095 20 5.00006V5C20 4.99927 20.0001 4.99506 19.9969 4.98471C19.9935 4.97356 19.9852 4.95155 19.9649 4.91857C19.9225 4.84943 19.8359 4.74547 19.6738 4.61768C19.3436 4.35747 18.8038 4.0794 18.0477 3.82736C16.546 3.32678 14.4077 3 12 3C9.59229 3 7.45402 3.32678 5.95227 3.82736C5.19616 4.0794 4.65642 4.35747 4.32624 4.61768C4.16408 4.74547 4.07752 4.84943 4.03507 4.91857C4.01483 4.95155 4.00645 4.97356 4.00305 4.98471C3.99998 4.99479 4 4.99905 4 4.99994ZM5.31981 1.93C7.07541 1.3448 9.43715 1 12 1C14.5629 1 16.9246 1.3448 18.6802 1.93C19.5528 2.22085 20.331 2.58923 20.9117 3.04685C21.4811 3.49559 22 4.14805 22 5C22 5.85195 21.4811 6.50441 20.9117 6.95315C20.331 7.41077 19.5528 7.77915 18.6802 8.07C16.9246 8.6552 14.5629 9 12 9C9.43715 9 7.07541 8.6552 5.31981 8.07C4.44724 7.77915 3.66896 7.41077 3.08829 6.95315C2.51889 6.50441 2 5.85195 2 5C2 4.14805 2.51889 3.49559 3.08829 3.04685C3.66896 2.58923 4.44724 2.22085 5.31981 1.93Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 11C3.55228 11 4 11.4477 4 12C4 12.0019 4.00008 12.007 4.0034 12.018C4.00695 12.0298 4.01544 12.0521 4.03558 12.0851C4.07772 12.1543 4.16348 12.258 4.32419 12.3853C4.65159 12.6447 5.18788 12.9223 5.94167 13.174C7.43891 13.6738 9.57676 14 12 14C14.4232 14 16.5611 13.6738 18.0583 13.174C18.8121 12.9223 19.3484 12.6447 19.6758 12.3853C19.8365 12.258 19.9223 12.1543 19.9644 12.0851C19.9846 12.0521 19.993 12.0298 19.9966 12.018C19.9999 12.007 20 12.0019 20 12C20 11.4477 20.4477 11 21 11C21.5523 11 22 11.4477 22 12C22 12.8494 21.4868 13.502 20.9179 13.9528C20.3391 14.4115 19.5629 14.7802 18.6917 15.071C16.9389 15.6562 14.5768 16 12 16C9.42324 16 7.06109 15.6562 5.30833 15.071C4.43712 14.7802 3.66091 14.4115 3.08206 13.9528C2.51319 13.502 2 12.8494 2 12C2 11.4477 2.44772 11 3 11Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 4C3.55228 4 4 4.44772 4 5V19C4 19.0019 4.00008 19.007 4.0034 19.018C4.00695 19.0298 4.01544 19.0521 4.03558 19.0851C4.07772 19.1543 4.16348 19.258 4.32419 19.3853C4.65159 19.6447 5.18788 19.9223 5.94167 20.174C7.43891 20.6738 9.57676 21 12 21C14.4232 21 16.5611 20.6738 18.0583 20.174C18.8121 19.9223 19.3484 19.6447 19.6758 19.3853C19.8365 19.258 19.9223 19.1543 19.9644 19.0851C19.9846 19.0521 19.993 19.0298 19.9966 19.018C19.9999 19.007 20 19.0019 20 19V5C20 4.44772 20.4477 4 21 4C21.5523 4 22 4.44772 22 5V19C22 19.8494 21.4868 20.502 20.9179 20.9528C20.3391 21.4115 19.5629 21.7802 18.6917 22.071C16.9389 22.6562 14.5768 23 12 23C9.42324 23 7.06109 22.6562 5.30833 22.071C4.43712 21.7802 3.66091 21.4115 3.08206 20.9528C2.51319 20.502 2 19.8494 2 19V5C2 4.44772 2.44772 4 3 4Z"
      fill={fill}
    />
  </svg>
);
DataTables.propTypes = {
  fill: PropTypes.string,
};
DataTables.defaultProps = {
  fill: '#6A6A6A',
};
export default DataTables;
