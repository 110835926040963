import React, { useCallback } from 'react';
import { shape, number, string, bool } from 'prop-types';
import { getSmoothStepPath } from 'react-flow-renderer';
import isNil from 'lodash/isNil';
import { useFlowEditorContext } from '../../context';
import { getEdgeColor } from '../../utils/getEdgeColor';
import { AnimatedPath, Path } from './styled';
import { useFlowTestStep } from '../../../FlowTest/hooks/useFlowTestStep';
import THEME from '../../../../constants/theme';
import { TEST_NODE_TYPES } from '../../../FlowTest/testNodeTypes';
import { NODE_TYPE_ID } from '../Node/types';
import { useMouseEventHandler } from '../../hooks/useMouseEventHandler';

const Edge = (props) => {
  const {
    id,
    source,
    sourceHandleId,
    sourceX,
    sourceY,
    target,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    children,
    edgeError,
    selected,
  } = props;
  const {
    selectedNodeId,
    hoverNodeId,
    isAutomationTest,
    isAutomationTestInProgress,
    isFlowMonitor,
    selectedInstance,
  } = useFlowEditorContext();
  const edgeColor = getEdgeColor({
    edge: { source, id, sourceHandleId },
    selectedNodeId,
    hoverNodeId,
    isAutomationTest,
    edgeError,
    selected,
  });
  const { mouseEventHandler } = useMouseEventHandler();
  const { testNodeType } = useFlowTestStep({ stepId: source });
  const { testNodeType: targetNodeType } = useFlowTestStep({ stepId: target });
  const newMarkerEnd = `url(#color=${edgeColor}&height=22&type=arrow&width=22)`;
  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius: 0,
  });

  const pathStyle = { stroke: edgeColor, strokeWidth: 2, ...style };

  const showStyledPath = useCallback(() => {
    const findLog = (stepId) => !isNil(selectedInstance?.stepLogs?.find((item) => item.stepId === stepId));
    if (isFlowMonitor) {
      const isSourceLog = findLog(source);
      const isTargetLog = findLog(target);
      return (isSourceLog || source === NODE_TYPE_ID.retryNode) && isTargetLog;
    }
    if (!isAutomationTestInProgress) {
      return false;
    }
    return (
      (testNodeType === TEST_NODE_TYPES.startTest || testNodeType === TEST_NODE_TYPES.completed) &&
      (targetNodeType === TEST_NODE_TYPES.inProgress || targetNodeType === TEST_NODE_TYPES.completed)
    );
  }, [
    isFlowMonitor,
    isAutomationTestInProgress,
    testNodeType,
    targetNodeType,
    selectedInstance?.stepLogs,
    source,
    target,
  ]);

  return (
    <>
      <Path id={id} style={pathStyle} className="react-flow__edge-path" d={edgePath} markerEnd={newMarkerEnd} />
      <Path
        id={id}
        style={style}
        className="react-flow__edge-path-selector"
        d={edgePath}
        onMouseEnter={() => mouseEventHandler(id)}
        onMouseLeave={() => mouseEventHandler(null)}
      />
      {showStyledPath() && (
        <AnimatedPath
          id={id}
          className="react-flow__edge-path"
          d={edgePath}
          $stroke={isFlowMonitor ? THEME.primaryColors.blackPlain : THEME.primaryColors.purpleMain}
        />
      )}
      {children}
    </>
  );
};

Edge.propTypes = {
  id: string.isRequired,
  source: string.isRequired,
  sourceHandleId: string,
  sourceX: number.isRequired,
  sourceY: number.isRequired,
  target: string.isRequired,
  targetX: number.isRequired,
  targetY: number.isRequired,
  sourcePosition: string.isRequired,
  targetPosition: string.isRequired,
  style: shape({}),
  edgeError: bool,
  selected: bool,
};

Edge.defaultProps = {
  style: {},
  sourceHandleId: null,
  edgeError: false,
  selected: false,
};

export { Edge };
