import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { func } from 'prop-types';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import THEME from '../../../constants/theme';
import { initialValues, signInFields } from '../../../utils/schemas/signInSchema';
import { DEFAULT_LOGIN } from '../../../utils/queries/public/publicMutations';
import signInValidator from '../../../utils/validators/signInValidator';
import useMutationSetTokens from '../../../hooks/useMutationSetTokens';
import { MUTATION_NAMES } from '../../../constants/api';
import { UI_ROUTES } from '../../../constants/routes';
import SubmitButton from '../_common/SubmitButton';
import { StyledMaterialLink } from '../../atoms';
import InputOnboarding from '../_common/InputOnboarding';
import StatusSpy from '../_common/StatusSpy';
import { useGlobalContext } from '../../../containers/App/context';
import { linkStyle } from './styled';
import PasswordOnboarding from '../_common/PasswordOnboarding';

const SignInForm = ({ setIncorrectLoginCount }) => {
  const { t } = useTranslation();
  const [login, { loading }] = useMutationSetTokens(DEFAULT_LOGIN, MUTATION_NAMES.login);
  const { getMeRefetch } = useGlobalContext();
  const { push } = useHistory();
  const connectPath = sessionStorage.getItem('connect');

  const handleSubmit = useCallback(
    (values, actions) => {
      let value;

      actions.setStatus(undefined);
      login({ variables: values })
        .then((res) => {
          value = res;
          if (!res.data?.login.isLoggedIn) {
            actions.setStatus(t('uiMessages.loginFail'));
          }
        })
        .finally(() => {
          actions.setSubmitting(false);
          getMeRefetch();
          if (connectPath) {
            push(connectPath);
            sessionStorage.removeItem('connect');
          }

          if (!value.data?.login.isLoggedIn) {
            setIncorrectLoginCount((prev) => prev + 1);
          }
        });
    },
    [t, login, getMeRefetch, connectPath, push, setIncorrectLoginCount]
  );

  return (
    <Formik
      initialValues={initialValues}
      validate={signInValidator}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnMount
    >
      <Form>
        {signInFields.map(({ field, type, label, placeholder }) => {
          const Component = type === 'password' ? PasswordOnboarding : InputOnboarding;
          const componentProps = {
            name: field,
            type,
            label,
            placeholder: t(placeholder),
            ...(type === 'password' && { signIn: true }),
            ...(type !== 'password' && { customLabel: true }),
          };

          return (
            <React.Fragment key={label}>
              <Component {...componentProps} />
            </React.Fragment>
          );
        })}

        <StatusSpy setIncorrectLoginCount={setIncorrectLoginCount} />
        <Box mt="16px" mb="16px">
          <SubmitButton isLoading={loading} width="100%" showIcon={false}>
            {t('Sign In')}
          </SubmitButton>
        </Box>
        <Box textAlign="center">
          <StyledMaterialLink
            to={UI_ROUTES.forgotPassword}
            textDecoration="none"
            color={THEME.primaryColors.primary}
            margin="0 0 6px 0"
            className="text-primary"
            cursor="pointer"
            style={linkStyle}
            fontSize="12px"
          >
            {t('signInForm.forgotPassword')}
          </StyledMaterialLink>
        </Box>
      </Form>
    </Formik>
  );
};

SignInForm.propTypes = {
  setIncorrectLoginCount: func,
};

SignInForm.defaultProps = {
  setIncorrectLoginCount: () => {},
};
export default SignInForm;
