import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import { useTranslation } from 'react-i18next';
import TabHeader from './TabHeader';
import { StyledWrapper, StyledDrawer, StyledPanel } from './styled';
import Actions from './Actions';
import Notifications from './Notifications';
import { H5New } from '../atoms/Typography/H5New';
import Remove from '../../assets/icons/Remove';
import THEME from '../../constants/theme';
import { CloseButton } from './TabHeader/styled';

const NotificationCenter = ({ toggleNotification, isOpen }) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState('0');

  return (
    <StyledDrawer anchor="right" open={isOpen} onClose={toggleNotification}>
      <Box width="440px">
        <Box display="flex" p="22px 24px" mb="16px" alignItems="center" justifyContent="space-between">
          <H5New lineHeight="20px">{t('Notifications center')}</H5New>
          <CloseButton onClick={toggleNotification}>
            <Remove color={THEME.primaryColors.black} />
          </CloseButton>
        </Box>
        <TabContext value={tabValue}>
          <StyledWrapper>
            <TabHeader setTabValue={setTabValue} toggleNotification={toggleNotification} />

            <StyledPanel value="0">
              <Notifications toggleNotification={toggleNotification} />
            </StyledPanel>

            <StyledPanel value="1">
              <Actions toggleNotification={toggleNotification} />
            </StyledPanel>
          </StyledWrapper>
        </TabContext>
      </Box>
    </StyledDrawer>
  );
};

NotificationCenter.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleNotification: PropTypes.func.isRequired,
};

export default NotificationCenter;
