const formNames = {
  paymentProcessor: 'paymentProcessor',
};

const formFields = [{ field: formNames.paymentProcessor, label: '' }];

const initialValues = {
  [formNames.paymentProcessor]: '',
};

export { initialValues, formFields, formNames };
