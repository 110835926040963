import last from 'lodash/last';
import isEmpty from 'lodash/isEmpty';
import { useFlowEditorContext } from '../../../context';
import { getNodeColorFromGroup } from '../../../utils/getNodeColor';
import { isNodeConnectable } from '../../../utils/isNodeConnectable';
import { getNodeBorder } from '../../../utils/getNodeBorder';

export const useNodeProperties = ({ id, group, isValid, entered, exited, isErrorState, validationErrors, changed }) => {
  const {
    connectingNodeData,
    nodes,
    selectedNodeId,
    hoverNodeId,
    isValidateOn,
    isAutomationTest,
    isFlowMonitor,
    selectedInstance,
    savedNodeId,
    currentValidate,
  } = useFlowEditorContext();

  const _isValid = !isValidateOn || isValid;
  const stepLog = selectedInstance?.stepLogs?.find(({ stepId }) => stepId === id);
  const isLogsError = stepLog?.logs?.find((log) => log.level === 'ERROR');
  const isLogsSuccess = !isLogsError && last(selectedInstance?.stepLogs)?.stepId === id;
  const isSavedNode = savedNodeId === id;
  const isCurrentError = currentValidate === id && !isEmpty(validationErrors);

  const highlightColor = getNodeColorFromGroup(group);
  const nodeConnectable = connectingNodeData && isNodeConnectable(connectingNodeData, id, nodes);
  const border = getNodeBorder({
    nodeId: id,
    isValid: _isValid,
    selectedNodeId,
    entered,
    exited,
    isAutomationTest,
    isFlowMonitor,
    isStepLog: !!stepLog,
    isLogsError,
    isLogsSuccess,
    isSavedNode,
    isErrorState,
    isCurrentError,
    changed,
  });
  const isHover = hoverNodeId === id;
  const isSelected = selectedNodeId === id;
  const isHighlighted = isHover || isSelected;
  const occupiedHandleIds = nodes.filter((el) => el.source === id).map((el) => el.sourceHandle ?? 'next');

  return {
    highlightColor,
    nodeConnectable,
    border,
    isHover,
    isHighlighted,
    occupiedHandleIds,
    isValid: _isValid,
    isLogsError,
    isLogsSuccess,
    isSavedNode,
  };
};
