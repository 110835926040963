import PropTypes from 'prop-types';
import React from 'react';

const Lock = ({ fill }) => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.80952 7.61905C3.80952 8.14505 4.23592 8.57143 4.7619 8.57143C5.28789 8.57143 5.71429 8.14505 5.71429 7.61905H3.80952ZM11.4286 7.61905C11.4286 8.14505 11.855 8.57143 12.381 8.57143C12.907 8.57143 13.3333 8.14505 13.3333 7.61905H11.4286ZM5.71429 7.61905V4.7619H3.80952V7.61905H5.71429ZM11.4286 4.7619V7.61905H13.3333V4.7619H11.4286ZM8.57143 1.90476C10.1494 1.90476 11.4286 3.18395 11.4286 4.7619H13.3333C13.3333 2.13198 11.2013 0 8.57143 0V1.90476ZM8.57143 0C5.9415 0 3.80952 2.13198 3.80952 4.7619H5.71429C5.71429 3.18395 6.99343 1.90476 8.57143 1.90476V0ZM2.85714 8.57143H14.2857V6.66667H2.85714V8.57143ZM15.2381 9.52381V15.2381H17.1429V9.52381H15.2381ZM12.381 18.0952H4.7619V20H12.381V18.0952ZM1.90476 15.2381V9.52381H0V15.2381H1.90476ZM4.7619 18.0952C3.18395 18.0952 1.90476 16.8161 1.90476 15.2381H0C0 17.868 2.13198 20 4.7619 20V18.0952ZM15.2381 15.2381C15.2381 16.8161 13.959 18.0952 12.381 18.0952V20C15.0109 20 17.1429 17.868 17.1429 15.2381H15.2381ZM14.2857 8.57143C14.8117 8.57143 15.2381 8.99781 15.2381 9.52381H17.1429C17.1429 7.94581 15.8637 6.66667 14.2857 6.66667V8.57143ZM2.85714 6.66667C1.27919 6.66667 0 7.94581 0 9.52381H1.90476C1.90476 8.99781 2.33116 8.57143 2.85714 8.57143V6.66667Z"
        fill={fill}
      />
    </svg>
  );
};

Lock.propTypes = {
  fill: PropTypes.string,
};
Lock.defaultProps = {
  fill: '#787F88',
};

export default Lock;
