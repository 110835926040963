import React from 'react';
import PropTypes from 'prop-types';

const BigBell = ({ size = 20 }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M7.39999 15.8V16.4C7.39999 17.0896 7.67392 17.7509 8.16151 18.2385C8.64911 18.7261 9.31043 19 9.99999 19C10.6896 19 11.3509 18.7261 11.8385 18.2385C12.3261 17.7509 12.6 17.0896 12.6 16.4V15.8H15.6C16.0774 15.8 16.5352 15.6104 16.8728 15.2728C17.2104 14.9352 17.4 14.4774 17.4 14L17.4 13.528L17.4 13.5238C17.398 13.0516 17.2105 12.5991 16.878 12.2638L16.878 12.2638L16.8751 12.2609L16.2111 11.5969L16.2107 11.5965C15.948 11.3342 15.8003 10.9783 15.8 10.6071V8.4C15.8 7.14505 15.393 5.92396 14.64 4.92L13.92 3.96C13.6033 3.53773 13.1926 3.195 12.7205 2.95895L12.2733 3.85337L12.7205 2.95895C12.4289 2.81315 12.1188 2.71053 11.8 2.65336V2.4C11.8 2.0287 11.6525 1.6726 11.3899 1.41005L10.684 2.11603L11.3899 1.41005C11.1274 1.1475 10.7713 1 10.4 1H9.59999C9.22869 1 8.87259 1.1475 8.61004 1.41005L9.31715 2.11716L8.61004 1.41005C8.34749 1.6726 8.19999 2.0287 8.19999 2.4V2.65336C7.88113 2.71053 7.57105 2.81315 7.27946 2.95895C6.80736 3.195 6.39669 3.53773 6.07999 3.96L5.35999 4.92C4.60702 5.92396 4.19999 7.14505 4.19999 8.4V10.6075C4.19956 10.9786 4.05185 11.3342 3.78932 11.5965L3.78888 11.5969L3.12488 12.2609L3.12488 12.2609L3.122 12.2638C2.78946 12.5991 2.60199 13.0516 2.6 13.5238H2.59999V13.528V14C2.59999 14.4774 2.78963 14.9352 3.1272 15.2728C3.46477 15.6104 3.9226 15.8 4.39999 15.8H7.39999Z"
        stroke="white"
        strokeWidth="2"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="19.2" height="19.2" fill="white" transform="translate(0.399994 0.399902)" />
      </clipPath>
    </defs>
  </svg>
);

BigBell.propTypes = {
  size: PropTypes.number,
};

BigBell.defaultProps = {
  size: 20,
};

export default BigBell;
