import React, { useMemo, useState } from 'react';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { StyledDialog, StyledDialogTitle, StyledPaper } from '../../Dialog/styled';
import CloseButton from '../../Dialog/CloseButton';
import THEME from '../../../constants/theme';
import { SWITCH_ENV_STEPS } from '../constant';
import VideoStep from './VideoStep';
import MigrateStep from './MigrateStep';
import LoadingStep from './LoadingStep';
import SuccessStep from './SuccessStep';
import useGetPaymentFlow from '../../../hooks/useGetPaymentFlow';
import { useGlobalContext } from '../../../containers/App/context';
import {
  GQL_M_MIGRATE_CUSTOMER_TO_PRODUCTION,
  GQL_M_MIGRATE_FLOWS_TO_PRODUCTION,
} from '../../../utils/queries/public/publicMutations';
import { getOperationNameForQuery } from '../../../utils/graphql';
import { GQL_Q_CAN_MIGRATE_CUSTOMER } from '../../../utils/queries/public/publicQueries';

const canMigrateCustomer = getOperationNameForQuery(GQL_Q_CAN_MIGRATE_CUSTOMER);

const SwitchEnvModal = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const { currentSwitchEnvStep, setCurrentSwitchEnvStep } = useGlobalContext();
  const { flow, loading } = useGetPaymentFlow();
  const automations = flow?.automations ?? [];
  const [migrateCustomerToProduction, { loading: migrateCustomerLoading }] = useMutation(
    GQL_M_MIGRATE_CUSTOMER_TO_PRODUCTION,
    {
      refetchQueries: [canMigrateCustomer],
    }
  );
  const [migrateFlowsToProduction, { loading: migrateFlowsLoading }] = useMutation(GQL_M_MIGRATE_FLOWS_TO_PRODUCTION);
  const [selectedAutomationIds, setSelectedAutomationIds] = useState([]);
  const isVideoStep = currentSwitchEnvStep === SWITCH_ENV_STEPS.VIDEO;
  const isMigrateStep = currentSwitchEnvStep === SWITCH_ENV_STEPS.MIGRATE;
  const isMigrateAutomationOnly = currentSwitchEnvStep === SWITCH_ENV_STEPS.MIGRATE_AUTOMATIONS_ONLY;
  const isLoadingStep = currentSwitchEnvStep === SWITCH_ENV_STEPS.LOADING;
  const isSuccessStep = currentSwitchEnvStep === SWITCH_ENV_STEPS.SUCCESS;

  const handleClose = () => {
    setIsOpen();
    setCurrentSwitchEnvStep(SWITCH_ENV_STEPS.VIDEO);
  };

  const selectTitle = useMemo(() => {
    let title;

    if (isMigrateAutomationOnly) {
      title = t('Export Automations');
    } else if (isSuccessStep) {
      title = t('Successfully Exported Automations');
    } else {
      title = t('Before you switch');
    }

    return title;
  }, [isMigrateAutomationOnly, isSuccessStep, t]);

  return (
    <StyledDialog
      open={isOpen}
      scroll="paper"
      maxWidth="xl"
      PaperComponent={StyledPaper}
      PaperProps={{ $borderRadius: '8px' }}
      aria-labelledby="switch-env-modal"
      $bgColor={THEME.greyColors.grey300}
      $height="700px"
    >
      <CloseButton onClick={handleClose} />
      {!isLoadingStep && (
        <StyledDialogTitle id="switch-env-modal-content" $fontSize="16px" disableTypography>
          {selectTitle}
        </StyledDialogTitle>
      )}
      {isVideoStep && (
        <VideoStep
          automations={automations}
          migrateCustomerToProduction={migrateCustomerToProduction}
          loading={loading}
        />
      )}
      {(isMigrateStep || isMigrateAutomationOnly) && (
        <MigrateStep
          automations={automations}
          selectedAutomationIds={selectedAutomationIds}
          setSelectedAutomationIds={setSelectedAutomationIds}
          loading={loading}
        />
      )}
      {isLoadingStep && (
        <LoadingStep
          selectedAutomationIds={selectedAutomationIds}
          migrateFlowsToProduction={migrateFlowsToProduction}
          loading={migrateFlowsLoading || migrateCustomerLoading}
        />
      )}
      {isSuccessStep && <SuccessStep automationsNumber={selectedAutomationIds?.length} />}
    </StyledDialog>
  );
};

SwitchEnvModal.propTypes = {
  isOpen: bool.isRequired,
  setIsOpen: func.isRequired,
};

export default SwitchEnvModal;
