import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { FormControl } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { string, bool, func, oneOf, arrayOf, shape } from 'prop-types';
import Title from '../../Title';
import SubTitle from '../../SubTitle';
import RadioButtons from '../inputs/RadioButtons';
import { SearchBar } from '../../../../../atoms';
import { selectTypes } from '../inputs/types';
import THEME from '../../../../../../constants/theme';
import AddNewConnectionButton from '../../../AddNewConnectionButton';
import { useStyles } from '../fields/styled';

const RadioButtonsSection = ({
  name,
  title,
  subTitle,
  options,
  validationMessage,
  value,
  handleChange,
  selectType,
  isSearchBar,
  isAddConnection,
  mb,
  subTitleMB,
}) => {
  const [search, setSearch] = useState('');
  const classes = useStyles();

  const filteredOptions = useMemo(
    () => options?.filter((option) => !search.toLowerCase() || option?.title?.toLowerCase().search(search) >= 0),
    [options, search]
  );

  const hasError = Boolean(validationMessage);

  return (
    <Box component="section" marginBottom={mb}>
      {title && <Title>{title}</Title>}
      {subTitle && <SubTitle {...(subTitleMB && { mb: subTitleMB })}>{subTitle}</SubTitle>}
      {isAddConnection && (
        <AddNewConnectionButton
          connectionTypes={['PAYMENT_GATEWAY']}
          btnHeight="48px"
          mb="16px"
          mr="10px"
          ml="10px"
          iconSize="24px"
          fontSize="12px"
          border={`1px solid ${THEME.primaryColors.primaryLight}`}
          borderRadius="8px"
        />
      )}
      <FormControl variant="standard" fullWidth error={!!validationMessage}>
        <Box
          border={`1px solid ${validationMessage ? THEME.secondaryColors.danger : THEME.greyColors.grey200}`}
          borderRadius="4px"
        >
          {isSearchBar && (
            <Box borderBottom={`1px solid ${THEME.greyColors.grey12}`}>
              <SearchBar search={search} setSearch={setSearch} bgcolor="transparent" noIcon paddingX="4px" />
            </Box>
          )}
          <RadioButtons
            options={filteredOptions}
            name={name}
            value={value}
            onChange={handleChange}
            selectType={selectType}
          />
        </Box>
        {hasError && <FormHelperText classes={{ root: classes.root }}>{validationMessage}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

RadioButtonsSection.propTypes = {
  name: string,
  title: string,
  subTitle: string,
  options: arrayOf(
    shape({
      value: string.isRequired,
      title: string.isRequired,
    })
  ).isRequired,
  validationMessage: string.isRequired,
  value: string,
  handleChange: func.isRequired,
  selectType: oneOf([selectTypes.connections, selectTypes.people, selectTypes.none]),
  isSearchBar: bool,
  isAddConnection: bool,
  mb: string,
  subTitleMB: string,
};

RadioButtonsSection.defaultProps = {
  name: '',
  title: '',
  subTitle: '',
  value: '',
  selectType: selectTypes.none,
  isSearchBar: false,
  isAddConnection: false,
  mb: '0',
  subTitleMB: null,
};

export default RadioButtonsSection;
