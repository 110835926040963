import React, { useState } from 'react';
import { StyledDialogContent, StyledDialogTitle } from '../../../../components/Dialog/styled';
import ContentArea from '../../Directory/DirectoryList/ContentArea';
import { StyledDirectoryWrapper } from './styled';
import SideFilter from '../../Directory/DirectoryList/SideFilter';
import { TABS } from '../../Directory/DirectoryList/constant';

const ID = 'choose-template';

const ChooseTemplateScreen = () => {
  const [activeTab, setActiveTab] = useState(TABS[1].label);

  return (
    <>
      <StyledDialogTitle id={`${ID}-title`} disableTypography>
        Automations
      </StyledDialogTitle>
      <StyledDialogContent>
        <StyledDirectoryWrapper>
          <SideFilter inModal setActiveTab={setActiveTab} />
          <ContentArea inModal activeTab={activeTab} setActiveTab={setActiveTab} />
        </StyledDirectoryWrapper>
      </StyledDialogContent>
    </>
  );
};
export default ChooseTemplateScreen;
