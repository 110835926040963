import { string, arrayOf, shape, bool } from 'prop-types';
import React, { useState } from 'react';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { v4 as uniqueID } from 'uuid';
import Box from '@mui/material/Box';
import { useRouteMatch } from 'react-router-dom';
import { StyledWrapper, StyledDropdownWrapper } from './styled';
import MenuItem from './MenuItem';
import useSearchSort from '../../../../hooks/useSearchSort';
import useTableContext from '../../TableContext';
import { usePaymentsHandler } from '../../hooks';
import { SORT_DICTIONARY_UI, getPageDropdownOptions, handleSort } from './constant';
import { TOAST_TIMEOUT } from '../../../../constants/toastTimeout';
import { showNotification } from '../../../../hooks/useNotificationManager';

const HeadCellDropDown = ({ id, headCellDropdownOptions, noDnd }) => {
  const [subMenu, setSubMenu] = useState(null);
  const [order, handleRequestSort] = useSearchSort();
  const matchDataTables = useRouteMatch('/data-tables/:page');
  const matchPaymentDisputes = useRouteMatch('/payments/:page');
  const page = matchDataTables?.params?.page ?? matchPaymentDisputes?.params?.page;
  const {
    toggleCheckboxEditMode,
    setLocalHiddenColumn,
    setShouldSaveTable,
    shouldSaveTable,
    disableHideColumns,
  } = useTableContext();
  const { handleHideColumn } = usePaymentsHandler({
    setLocalHiddenColumn,
  });

  const isActive = SORT_DICTIONARY_UI[order[id]];
  const dropdownOptions = headCellDropdownOptions ?? getPageDropdownOptions(page);

  const handleSubMenu = (event) => {
    event.stopPropagation();
    const { label } = event.currentTarget.dataset;
    setSubMenu(label);
  };

  const subMenuItems = () => dropdownOptions.filter((option) => option?.label === subMenu)[0]?.subMenuLabels ?? [];

  const handleEditMode = () => {
    toggleCheckboxEditMode();
  };

  const hideColumn = () => {
    if (disableHideColumns) {
      showNotification('info', 'At least one visible column is required.', 'Edit columns.', TOAST_TIMEOUT);

      return;
    }
    handleHideColumn(id);

    if (!shouldSaveTable) {
      setShouldSaveTable(true);
    }
  };

  const controlObj = {
    none: (event) => handleSort({ handleRequestSort, id, event }),
    ascending: (event) => handleSort({ handleRequestSort, id, event }),
    descending: (event) => handleSort({ handleRequestSort, id, event }),
    hide: hideColumn,
    'edit columns': handleEditMode,
  };

  return (
    <StyledWrapper noDnd={noDnd}>
      <span>
        <ArrowDownIcon color="inherit" fontSize="inherit" />
      </span>

      <StyledDropdownWrapper className="HeadCell_DropdownMenu">
        <Box
          className="dropdown_facade"
          component="i"
          position="absolute"
          top="-4px"
          bgcolor="transparent"
          left="0"
          width="30px"
          height="6px"
        >
          &nbsp;
        </Box>
        <ul>
          {subMenu ? (
            <>
              <MenuItem className="subMenuBack" label={subMenu} withIcon={true} subMenu handleSelect={handleSubMenu} />

              {subMenuItems() &&
                subMenuItems()?.map((subMenuItem) => (
                  <MenuItem
                    isActive={isActive}
                    key={uniqueID()}
                    label={subMenuItem}
                    handleSelect={controlObj[subMenuItem]}
                  />
                ))}
            </>
          ) : (
            dropdownOptions.map(({ label, withIcon }) => (
              <MenuItem
                key={uniqueID()}
                label={label}
                withIcon={withIcon}
                handleSelect={withIcon ? handleSubMenu : controlObj[label]}
              />
            ))
          )}
        </ul>
      </StyledDropdownWrapper>
    </StyledWrapper>
  );
};

HeadCellDropDown.propTypes = {
  id: string.isRequired,
  headCellDropdownOptions: arrayOf(shape({})),
  noDnd: bool,
};

HeadCellDropDown.defaultProps = {
  headCellDropdownOptions: null,
  noDnd: false,
};

export default HeadCellDropDown;
