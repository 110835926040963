import React from 'react';

const User = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5 7C20.5 10.0376 18.0376 12.5 15 12.5C11.9625 12.5 9.5 10.0376 9.5 7C9.5 3.96244 11.9625 1.5 15 1.5C18.0376 1.5 20.5 3.96243 20.5 7ZM15 11.5C17.4853 11.5 19.5 9.48529 19.5 7C19.5 4.51471 17.4853 2.5 15 2.5C12.5147 2.5 10.5 4.51472 10.5 7C10.5 9.48528 12.5147 11.5 15 11.5Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M8.19893 3.07982C8.74018 2.96995 9.08988 2.44212 8.98001 1.90087C8.87015 1.35963 8.34232 1.00992 7.80107 1.11979C5.06196 1.6758 3 4.09587 3 6.99979C3 9.9037 5.06196 12.3238 7.80107 12.8798C8.34232 12.9896 8.87015 12.6399 8.98001 12.0987C9.08988 11.5575 8.74018 11.0296 8.19893 10.9198C6.37356 10.5492 5 8.93379 5 6.99979C5 5.06578 6.37356 3.45035 8.19893 3.07982Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.69779 15.5735C10.9306 14.6272 12.6489 14 14.9999 14C17.3509 14 19.0692 14.6272 20.302 15.5736C21.5257 16.5128 22.1914 17.705 22.5524 18.7086C22.8701 19.592 22.6475 20.4567 22.1147 21.0695C21.6001 21.6613 20.821 22 19.9999 22H9.99992C9.17877 22 8.39967 21.6613 7.88509 21.0695C7.35225 20.4566 7.12965 19.5919 7.44741 18.7086C7.80844 17.705 8.47414 16.5128 9.69779 15.5735ZM10.9156 17.16C10.0734 17.8065 9.59833 18.6378 9.32935 19.3856C9.27323 19.5416 9.3041 19.6534 9.39435 19.7572C9.50286 19.882 9.71647 20 9.99992 20H19.9999C20.2833 20 20.4969 19.882 20.6054 19.7572C20.6957 19.6534 20.7265 19.5416 20.6704 19.3856C20.4015 18.6379 19.9264 17.8065 19.0842 17.16C18.2513 16.5207 16.9788 16 14.9999 16C13.021 16 11.7485 16.5207 10.9156 17.16Z"
        fill="white"
      />
      <path
        d="M3.25075 19.2769C3.45545 18.4886 3.8068 17.6598 4.38662 17.0404C4.93771 16.4517 5.74034 16 6.99982 16C7.55211 16 7.99982 15.5523 7.99982 15C7.99982 14.4477 7.55211 14 6.99982 14C5.17915 14 3.85036 14.6867 2.92653 15.6736C2.03144 16.6298 1.56437 17.8138 1.31496 18.7741C0.831762 20.6347 2.41432 22 3.99979 22H4.76901C5.3213 22 5.76901 21.5523 5.76901 21C5.76901 20.4477 5.3213 20 4.76901 20H3.99979C3.71726 20 3.49227 19.8839 3.36436 19.7426C3.24851 19.6146 3.20242 19.4629 3.25075 19.2769Z"
        fill="white"
      />
    </svg>
  );
};

export default User;
