import { useCallback, useEffect, useMemo, useState } from 'react';
import { isNode } from 'react-flow-renderer';
import isEmpty from 'lodash/isEmpty';
import { gql, useMutation } from '@apollo/client';
import { getLayoutedElements } from '../../FlowEditor/utils/layout';
import { useGetFlowInstances } from './useGetFlowInstances';
import { facadeStepsToNodes } from '../../FlowEditor/utils/facades';
import useSearch from '../../../hooks/useSearch';
import { useBoolState } from '../../../hooks/useBoolState';
import { prepareFlowInstance, ROWS_PER_PAGE } from '../FlowMonitorDetailsDrawer/helpers';
import { getOrderDataFromSearchParamsSort } from '../../SortTable/helpers';
import { ALL_FLOW_INSTANCE_STATUSES, FLOW_INSTANCE_STATUSES } from '../../FlowEditor/utils/flowInstanceStatus';
import { filterEdges, filterNodes } from '../../FlowEditor/utils/filterElements';
import { useEditorData } from '../../FlowEditor/hooks/useEditorData';
import { getFlowNodes } from '../../FlowEditor/utils/getFlowNodes';
import { EDGE_TYPE_ID, NODE_TYPE, NODE_TYPE_ID } from '../../FlowEditor/components/Node/types';
import { RETRY_INSTANCE } from '../../../utils/queries/flows/queries';
import { useGetVersionHistory } from '../../FlowHeader/VersionControl/hooks/useGetVersionHistory';

export const useFlowMonitor = ({ flowId }) => {
  const { firstStepId, loading: getFlowLoading } = useEditorData(flowId);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const { toggle: onValidate, bool: isValidateOn } = useBoolState(true);
  const [{ instanceId: selectedInstanceId, sort, page, filter }, setSearchParams] = useSearch();
  const [isFlowMonitor, setIsFlowMonitor] = useState(true);
  const [isOpenFlowMonitorDetails, setIsOpenFlowMonitorDetails] = useState(true);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [selectedInstance, setSelectedInstance] = useState(null);
  const [selectedInstanceLoading, setSelectedInstanceLoading] = useState(false);
  const [selectedInstanceRetries, setSelectedInstanceRetries] = useState([]);
  const [selectedInstanceRetriesLoading, setSelectedInstanceRetriesLoading] = useState(false);
  const [retryingStepId, setRetryingStepId] = useState(null);
  const [isOpenRetryDialog, setIsOpenRetryDialog] = useState(false);
  const [selectedRetryStepId, setSelectedRetryStepId] = useState(null);
  const selectedNode = nodes.find(({ id }) => selectedNodeId === id);
  const selectedNodeData = selectedNode?.data;
  const { order, orderBy } = getOrderDataFromSearchParamsSort(sort);
  const findNodeDataById = useCallback((stepId) => nodes.find(({ id }) => stepId === id)?.data, [nodes]);
  const { versionHistory, loading: versionHistoryLoading } = useGetVersionHistory(flowId);
  const options = {
    variables: {
      flowId,
      inStatus:
        filter?.inStatus === FLOW_INSTANCE_STATUSES.ALL || isEmpty(filter?.inStatus)
          ? ALL_FLOW_INSTANCE_STATUSES
          : filter?.inStatus,
      ...(!isEmpty(filter?.date?.gt) && { startDate: filter?.date?.gt }),
      ...(!isEmpty(filter?.date?.lt) && { endDate: filter?.date?.lt }),
      cursor: {
        page: page ? page - 1 : 0,
        max: ROWS_PER_PAGE,
        orderBy: orderBy ?? null,
        ...(orderBy && { orderBy }),
        ...(order && { asc: order === 'asc' }),
      },
    },
    skip: !flowId,
    fetchPolicy: 'no-cache',
  };
  const {
    flowInstances,
    pageInfo,
    loading: getFlowInstancesLoading,
    isDataReady: isFlowInstancesReady,
  } = useGetFlowInstances(options);
  const isDataReady = isFlowInstancesReady && !getFlowLoading;
  const [retryInstancePromise, { loading: retryInstanceLoading }] = useMutation(RETRY_INSTANCE, {
    update(cache, { data: { retryInstance } }) {
      cache.modify({
        fields: {
          getRetriesForInstance(retries = []) {
            const newRetryRef = cache.writeFragment({
              data: retryInstance,
              fragment: gql`
                fragment flowInstanceFragment on FlowInstance {
                  id
                }
              `,
            });
            return [...retries, newRetryRef];
          },
        },
      });
    },
  });

  const setSelectedInstanceId = useCallback(
    (id) => {
      setSearchParams((prevState) => ({ ...prevState, instanceId: id }));
    },
    [setSearchParams]
  );

  useEffect(() => {
    if (!selectedInstanceId) {
      setSelectedInstance(null);
    }
  }, [selectedInstanceId]);

  useEffect(() => {
    if (isDataReady && selectedInstance) {
      const elements = facadeStepsToNodes(selectedInstance.steps, true);
      if (elements.find((el) => isNode(el) && !el.position)) {
        setNodes(getLayoutedElements(elements));
      } else {
        setNodes(filterNodes(elements));
        setEdges(filterEdges(elements));
      }
    } else if (!selectedInstance) {
      setNodes([]);
    }
  }, [isDataReady, selectedInstance]);

  const reactFlowElements = useMemo(() => {
    const flowNodes = getFlowNodes(nodes);
    if (!flowNodes?.length) {
      return [];
    }
    const { isInErrorStatus } = prepareFlowInstance(selectedInstance);
    // add retry node and edge only for instances with error status
    if (!isInErrorStatus) {
      return [...flowNodes, ...edges];
    }
    const firstNode = flowNodes.find((node) => node.id === firstStepId);
    const retryNodeX = firstNode?.position?.x - 120;
    const retryNodeY = firstNode?.position?.y + 58;
    const retryNode = {
      id: NODE_TYPE_ID.retryNode,
      type: NODE_TYPE.retry,
      data: {
        targetId: firstStepId,
      },
      position: { x: retryNodeX, y: retryNodeY },
    };
    const retryEdge = {
      id: EDGE_TYPE_ID.retryEdge,
      source: retryNode?.id,
      target: firstStepId,
    };
    return [retryNode, ...flowNodes, retryEdge, ...edges];
  }, [edges, firstStepId, nodes, selectedInstance]);

  const reactFlowNodes = useMemo(() => filterNodes(reactFlowElements), [reactFlowElements]);
  const reactFlowEdges = useMemo(() => filterEdges(reactFlowElements), [reactFlowElements]);

  const loading = getFlowLoading || getFlowInstancesLoading;
  const context = useMemo(
    () => ({
      flowInstances,
      pageInfo,
      loading,
      isDataReady,
      nodes,
      selectedNodeId,
      selectedNode,
      selectedNodeData,
      setSelectedNodeId,
      reactFlowNodes,
      reactFlowEdges,
      isFlowMonitor,
      setIsFlowMonitor,
      isOpenFlowMonitorDetails,
      setIsOpenFlowMonitorDetails,
      selectedInstanceId,
      setSelectedInstanceId,
      onValidate,
      isValidateOn,
      selectedInstance,
      setSelectedInstance,
      selectedInstanceLoading,
      setSelectedInstanceLoading,
      findNodeDataById,
      retryInstancePromise,
      retryInstanceLoading,
      retryingStepId,
      setRetryingStepId,
      selectedInstanceRetries,
      setSelectedInstanceRetries,
      selectedInstanceRetriesLoading,
      setSelectedInstanceRetriesLoading,
      isOpenRetryDialog,
      setIsOpenRetryDialog,
      selectedRetryStepId,
      setSelectedRetryStepId,
      versionHistory,
      versionHistoryLoading,
    }),
    [
      flowInstances,
      pageInfo,
      loading,
      isDataReady,
      nodes,
      selectedNodeId,
      selectedNode,
      selectedNodeData,
      reactFlowNodes,
      reactFlowEdges,
      isFlowMonitor,
      isOpenFlowMonitorDetails,
      selectedInstanceId,
      setSelectedInstanceId,
      onValidate,
      isValidateOn,
      selectedInstance,
      selectedInstanceLoading,
      findNodeDataById,
      retryInstancePromise,
      retryInstanceLoading,
      retryingStepId,
      selectedInstanceRetries,
      selectedInstanceRetriesLoading,
      isOpenRetryDialog,
      selectedRetryStepId,
      versionHistory,
      versionHistoryLoading,
    ]
  );

  return {
    context,
    flowInstances,
    pageInfo,
    loading,
    isDataReady,
    nodes,
    selectedNodeData,
    selectedNodeId,
    setSelectedNodeId,
    reactFlowNodes,
    reactFlowEdges,
    isFlowMonitor,
    setIsFlowMonitor,
    isOpenFlowMonitorDetails,
    setIsOpenFlowMonitorDetails,
    selectedInstanceId,
    setSelectedInstanceId,
    onValidate,
    isValidateOn,
    selectedInstance,
    setSelectedInstance,
    selectedInstanceLoading,
    setSelectedInstanceLoading,
    findNodeDataById,
    retryInstancePromise,
    retryInstanceLoading,
    retryingStepId,
    setRetryingStepId,
    selectedInstanceRetries,
    setSelectedInstanceRetries,
    selectedInstanceRetriesLoading,
    setSelectedInstanceRetriesLoading,
    isOpenRetryDialog,
    setIsOpenRetryDialog,
    selectedRetryStepId,
    setSelectedRetryStepId,
    versionHistory,
    versionHistoryLoading,
  };
};
