const PAYMENT_DESCRIBED_KEYS = {
  name: 'name',
  statementDescriptor: 'statementDescriptor',
  localCurrency: 'localCurrency',
  localAmount: 'localAmount',
  exchangeRate: 'exchangeRate',
  fee: 'fee',
  tax: 'tax',
  processingFee: 'processingFee',
  net: 'net',
  description: 'description',
  id: 'id',
  number: 'number',
  expires: 'expires',
  fingerprint: 'fingerprint',
  paymentMethod: 'paymentMethod',
  type: 'type',
  product: 'product',
  issuingBank: 'issuingBank',
  category: 'category',
  paymentProcessor: 'paymentProcessor',
  amount: 'amount',
  currency: 'currency',
  sourceId: 'sourceId',
  sourceDisputeId: 'sourceDisputeId',
  invoiceNumber: 'invoiceNumber',
  country: 'country',
  reason: 'reason',
  processorReason: 'processorReason',
  status: 'status',
  processorStatus: 'processorStatus',
  disputeDate: 'disputeDate',
  responseDate: 'responseDate',
  customerId: 'customerId',
  teamId: 'teamId',
  connectionId: 'connectionId',
  paymentId: 'paymentId',
  date: 'date',
  processor: 'processor',
  processorDeclineCode: 'processorDeclineCode',
  declineType: 'declineType',
  businessFailureCode: 'businessFailureCode',
  businessFailureReason: 'businessFailureReason',
  brand: 'brand',
  additionalData: 'additionalData',

  //Fraud
  fraudScore: 'score',
  fraudDate: 'date',
  fraudProvider: 'fraudProvider',
  fraudDecision: 'fraudDecision',
  fraudAdditionalData: 'additionalData',

  //Intent
  intentLocation: 'intentLocation',

  //Vault
  vaultedCard: 'vaultedCard',
};

const PAYMENT_DETAILS_DATA_KEYS = [
  PAYMENT_DESCRIBED_KEYS.sourceId,
  PAYMENT_DESCRIBED_KEYS.paymentProcessor,
  PAYMENT_DESCRIBED_KEYS.statementDescriptor,
  PAYMENT_DESCRIBED_KEYS.invoiceNumber,
  PAYMENT_DESCRIBED_KEYS.description,
  PAYMENT_DESCRIBED_KEYS.reason,
  PAYMENT_DESCRIBED_KEYS.processorDeclineCode,
  PAYMENT_DESCRIBED_KEYS.businessFailureCode,
  PAYMENT_DESCRIBED_KEYS.businessFailureReason,
  PAYMENT_DESCRIBED_KEYS.declineType,
  PAYMENT_DESCRIBED_KEYS.localCurrency,
  PAYMENT_DESCRIBED_KEYS.exchangeRate,
  PAYMENT_DESCRIBED_KEYS.localAmount,
  PAYMENT_DESCRIBED_KEYS.tax,
  PAYMENT_DESCRIBED_KEYS.processingFee,
  PAYMENT_DESCRIBED_KEYS.fee,
  PAYMENT_DESCRIBED_KEYS.net,
];

const PAYMENT_METHOD_DATA_KEYS = [
  PAYMENT_DESCRIBED_KEYS.id,
  PAYMENT_DESCRIBED_KEYS.number,
  PAYMENT_DESCRIBED_KEYS.expires,
  PAYMENT_DESCRIBED_KEYS.country,
  PAYMENT_DESCRIBED_KEYS.fingerprint,
  PAYMENT_DESCRIBED_KEYS.paymentMethod,
  PAYMENT_DESCRIBED_KEYS.type,
  PAYMENT_DESCRIBED_KEYS.product,
  PAYMENT_DESCRIBED_KEYS.issuingBank,
  PAYMENT_DESCRIBED_KEYS.category,
];

const FRAUD_DATA_KEYS = [
  PAYMENT_DESCRIBED_KEYS.fraudDate,
  PAYMENT_DESCRIBED_KEYS.fraudScore,
  PAYMENT_DESCRIBED_KEYS.fraudProvider,
  PAYMENT_DESCRIBED_KEYS.fraudDecision,
  PAYMENT_DESCRIBED_KEYS.fraudAdditionalData,
];

const INTENT_SECTION_DATA_KEYS = [
  PAYMENT_DESCRIBED_KEYS.amount,
  PAYMENT_DESCRIBED_KEYS.currency,
  PAYMENT_DESCRIBED_KEYS.intentLocation,
];

const DISPUTES_DATA_KEYS = [
  PAYMENT_DESCRIBED_KEYS.id,
  PAYMENT_DESCRIBED_KEYS.sourceDisputeId,
  PAYMENT_DESCRIBED_KEYS.disputeDate,
  PAYMENT_DESCRIBED_KEYS.responseDate,
  // PAYMENT_DESCRIBED_KEYS.currency,
  PAYMENT_DESCRIBED_KEYS.amount,
  PAYMENT_DESCRIBED_KEYS.status,
  PAYMENT_DESCRIBED_KEYS.processorStatus,
  PAYMENT_DESCRIBED_KEYS.reason,
  PAYMENT_DESCRIBED_KEYS.processorReason,
];

export {
  PAYMENT_DESCRIBED_KEYS,
  PAYMENT_DETAILS_DATA_KEYS,
  PAYMENT_METHOD_DATA_KEYS,
  FRAUD_DATA_KEYS,
  INTENT_SECTION_DATA_KEYS,
  DISPUTES_DATA_KEYS,
};
