import React from 'react';
import { string } from 'prop-types';

const CreditCard = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_1191_3639"
      style={{ 'mask-type': 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <circle cx="12" cy="12" r="12" fill="#F4F4F4" />
    </mask>
    <g mask="url(#mask0_1191_3639)">
      <circle cx="12" cy="12" r="12" fill="#F4F4F4" />
      <rect y="6" width="24" height="4" fill="#C1C3C6" />
      <rect x="4" y="15" width="6" height="3" rx="1.5" fill="#C1C3C6" />
      <rect x="12" y="15" width="3" height="3" rx="1.5" fill="#C1C3C6" />
    </g>
  </svg>
);

CreditCard.propTypes = {
  width: string,
  height: string,
};

CreditCard.defaultProps = {
  width: '24',
  height: '24',
};

export default CreditCard;
