import React from 'react';
import { number, string } from 'prop-types';
import THEME from '../../constants/theme';

const DoubleArrowVertical = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.50893 12.5147L5.44084 10.0721C5.19677 9.78471 5.39513 9.33334 5.76554 9.33334H9.90173C9.98463 9.33327 10.0658 9.35789 10.1355 9.40425C10.2052 9.45062 10.2605 9.51676 10.2948 9.59476C10.329 9.67276 10.3408 9.75931 10.3287 9.84405C10.3166 9.92879 10.2811 10.0081 10.2264 10.0726L8.15834 12.5143C8.11786 12.5622 8.06795 12.6005 8.01195 12.6268C7.95594 12.6531 7.89514 12.6667 7.83363 12.6667C7.77213 12.6667 7.71133 12.6531 7.65532 12.6268C7.59932 12.6005 7.54941 12.5622 7.50893 12.5143V12.5147Z"
      fill={color}
    />
    <path
      d="M7.50893 3.48529L5.44084 5.92792C5.19677 6.21532 5.39513 6.66669 5.76554 6.66669H9.90173C9.98463 6.66676 10.0658 6.64214 10.1355 6.59578C10.2052 6.54941 10.2605 6.48327 10.2948 6.40527C10.329 6.32727 10.3408 6.24072 10.3287 6.15598C10.3166 6.07124 10.2811 5.99191 10.2264 5.92748L8.15834 3.48573C8.11786 3.43787 8.06795 3.39951 8.01195 3.37323C7.95594 3.34695 7.89514 3.33335 7.83363 3.33335C7.77213 3.33335 7.71133 3.34695 7.65532 3.37323C7.59932 3.39951 7.54941 3.43787 7.50893 3.48573V3.48529Z"
      fill={color}
    />
  </svg>
);

DoubleArrowVertical.propTypes = {
  width: number,
  height: number,
  color: string,
};

DoubleArrowVertical.defaultProps = {
  width: 16,
  height: 16,
  color: THEME.primaryColors.primary,
};

export default DoubleArrowVertical;
