import Mbway from '../../../../../../assets/icons/Connection/Mbway';
import Multibanco from '../../../../../../assets/icons/Connection/Multibanco';
import GooglePay from '../../../../../../assets/icons/Connection/GooglePay';
import ApplePay from '../../../../../../assets/icons/Connection/ApplePay';
import Satispay from '../../../../../../assets/icons/Connection/Satispay';
import Blik from '../../../../../../assets/icons/Connection/Blik';
import Paypal from '../../../../../../assets/icons/Connection/Paypal';
import Klarna from '../../../../../../assets/icons/Connection/Klarna';
import Ideal from '../../../../../../assets/icons/Connection/Ideal';
import Giropay from '../../../../../../assets/icons/Connection/Giropay';

import CreditCard from '../../../../../../assets/icons/CreditCard';

const LABEL_MAP = {
  MBWAY: 'MBWay',
  IDEAL_LEGACY: 'Ideal legacy',
  GIROPAY_LEGACY: 'Giropay legacy',
};

const ICON_MAP = {
  MBWAY: Mbway,
  MULTIBANCO: Multibanco,
  GOOGLE_PAY: GooglePay,
  APPLE_PAY: ApplePay,
  SATISPAY: Satispay,
  BLIK: Blik,
  PAYPAL: Paypal,
  KLARNA: Klarna,
  IDEAL: Ideal,
  IDEAL_LEGACY: Ideal,
  GIROPAY: Giropay,
  GIROPAY_LEGACY: Giropay,

  // default
  CARD: CreditCard,
};

export { LABEL_MAP, ICON_MAP };
