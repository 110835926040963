import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import Box from '@mui/material/Box';
import { bool, func, string } from 'prop-types';
import { MessagesModalWrapper } from '../styled';
import SubmitMessageButton from './SubmitMessageButton';
import GrowingInput from './GrowingInput';
import CommentsBlock from './CommentsBlock';
import LoadingState from '../../AutomationDetails/LoadingState';
import { stopPropagation } from './helpers';
import { useNodeContext } from '../context';

const MessagesModal = ({ toggleMessagesOpened, stepId, setNewMessages, showModal }) => {
  const {
    allComments,
    commentsLoading,
    commentsSaving,
    currentNodeComments,
    setCurrentNodeComments,
    message,
    setMessage,
    handleSubmitComment,
  } = useNodeContext();

  useEffect(() => {
    const currentThread = allComments.find(({ stepId: id }) => id === stepId);
    setNewMessages(!isEmpty(currentThread?.comments));
    setCurrentNodeComments(currentThread?.comments);
  }, [allComments, setCurrentNodeComments, setNewMessages, stepId]);

  return (
    !!showModal && (
      <MessagesModalWrapper onMouseDown={stopPropagation} onClick={stopPropagation} p={commentsLoading ? '24px' : '0'}>
        <LoadingState loading={commentsLoading} mt="0" height="200px">
          {isEmpty(currentNodeComments) ? (
            <Box display="flex" p="12px">
              <GrowingInput
                onMouseDown={stopPropagation}
                value={message}
                onChange={setMessage}
                placeholder="Type comment..."
              />
              <SubmitMessageButton
                onMouseDown={stopPropagation}
                onClick={handleSubmitComment}
                disabled={!message.trim() || commentsSaving}
              />
            </Box>
          ) : (
            <CommentsBlock toggleMessagesOpened={toggleMessagesOpened} />
          )}
        </LoadingState>
      </MessagesModalWrapper>
    )
  );
};

MessagesModal.propTypes = {
  toggleMessagesOpened: func.isRequired,
  stepId: string.isRequired,
  setNewMessages: func.isRequired,
  showModal: bool.isRequired,
};

export default MessagesModal;
