import PropTypes from 'prop-types';
import React from 'react';

const ArrowUp = ({ fill }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.99992 10.6667V1.33334M5.99992 1.33334L1.33325 6.00001M5.99992 1.33334L10.6666 6.00001"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ArrowUp.propTypes = {
  fill: PropTypes.string,
};
ArrowUp.defaultProps = {
  fill: '#12B76A',
};
export default ArrowUp;
