import React from 'react';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { StyledDialog, StyledDialogActions, StyledPaper } from '../../../../Dialog/styled';
import THEME from '../../../../../constants/theme';
import { L16B } from '../../../../atoms/Typography/L16B';
import { ButtonRounded, P14 } from '../../../../atoms';

const ABTestConfigureDialog = ({ open, cancelABTestConfiguring, continueABTestConfiguring }) => {
  const { t } = useTranslation();

  return (
    <StyledDialog
      open={open}
      scroll="paper"
      maxWidth="xs"
      PaperComponent={StyledPaper}
      aria-labelledby="ABTestConfigureDialog"
      width="337px"
    >
      <Box padding="24px">
        <L16B padding="0 0 12px 0">{t('Warning - this will erase your test results')}</L16B>
        <P14 color={THEME.greyColors.grey11} padding="0 0 24px 0">
          {t(`Changing your test configuration now will clear your A/T Test data you’ve accumulated and you’ll have to
          start over`)}
        </P14>
        <StyledDialogActions $justifyContent="flex-end" px="0" py="0">
          <ButtonRounded type="button" color="primary" onClick={continueABTestConfiguring}>
            {t('Continue')}
          </ButtonRounded>
          <ButtonRounded type="button" variant="contained" color="primary" onClick={cancelABTestConfiguring}>
            {t('Go Back')}
          </ButtonRounded>
        </StyledDialogActions>
      </Box>
    </StyledDialog>
  );
};

ABTestConfigureDialog.propTypes = {
  open: bool.isRequired,
  cancelABTestConfiguring: func.isRequired,
  continueABTestConfiguring: func.isRequired,
};

export default ABTestConfigureDialog;
