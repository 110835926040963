import omit from 'lodash/omit';

export const deleteCondition = ({ nodes, edgeId, stepId, targetId }) => {
  return nodes
    .filter((node) => node.id !== edgeId)
    .map((node) => {
      if (node.id !== stepId) {
        return node;
      }
      const elseStepId = targetId === node?.data?.elseStepId ? null : node?.data?.elseStepId;
      const conditions = node?.data?.conditions?.filter((c) => c?.nextStepId !== targetId);
      const layout = { ...node?.data?.layout, uiMetadata: omit(node.data?.layout?.uiMetadata, targetId) };
      return { ...node, data: { ...node.data, elseStepId, conditions, layout } };
    });
};
