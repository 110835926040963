import React from 'react';

const Reporting = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22Z"
        fill="#6750FA"
      />
      <path
        d="M15.75 30.125C15.2527 30.125 14.7758 29.9275 14.4242 29.5758C14.0725 29.2242 13.875 28.7473 13.875 28.25V15.75C13.875 15.2527 14.0725 14.7758 14.4242 14.4242C14.7758 14.0725 15.2527 13.875 15.75 13.875H25.75C25.9158 13.875 26.0747 13.9408 26.1919 14.0581C26.3092 14.1753 26.375 14.3342 26.375 14.5C26.375 14.6658 26.3092 14.8247 26.1919 14.9419C26.0747 15.0592 25.9158 15.125 25.75 15.125H15.75C15.5842 15.125 15.4253 15.1908 15.3081 15.3081C15.1908 15.4253 15.125 15.5842 15.125 15.75V28.25C15.125 28.4158 15.1908 28.5747 15.3081 28.6919C15.4253 28.8092 15.5842 28.875 15.75 28.875H28.25C28.4158 28.875 28.5747 28.8092 28.6919 28.6919C28.8092 28.5747 28.875 28.4158 28.875 28.25V22C28.875 21.8342 28.9408 21.6753 29.0581 21.5581C29.1753 21.4408 29.3342 21.375 29.5 21.375C29.6658 21.375 29.8247 21.4408 29.9419 21.5581C30.0592 21.6753 30.125 21.8342 30.125 22V28.25C30.125 28.7473 29.9275 29.2242 29.5758 29.5758C29.2242 29.9275 28.7473 30.125 28.25 30.125H15.75Z"
        fill="white"
      />
      <path
        d="M22.4426 24.9426L31.1926 16.1926C31.2507 16.1344 31.2968 16.0655 31.3282 15.9895C31.3597 15.9136 31.3758 15.8322 31.3758 15.7501C31.3758 15.6679 31.3597 15.5865 31.3282 15.5106C31.2968 15.4347 31.2507 15.3657 31.1926 15.3076C31.1344 15.2494 31.0655 15.2034 30.9895 15.1719C30.9136 15.1405 30.8322 15.1243 30.7501 15.1243C30.6679 15.1243 30.5865 15.1405 30.5106 15.1719C30.4347 15.2034 30.3657 15.2494 30.3076 15.3076L22.0001 23.6163L18.6926 20.3076C18.6344 20.2494 18.5655 20.2034 18.4895 20.1719C18.4136 20.1405 18.3322 20.1243 18.2501 20.1243C18.1679 20.1243 18.0865 20.1405 18.0106 20.1719C17.9347 20.2034 17.8657 20.2494 17.8076 20.3076C17.7494 20.3657 17.7034 20.4347 17.6719 20.5106C17.6405 20.5865 17.6243 20.6679 17.6243 20.7501C17.6243 20.8322 17.6405 20.9136 17.6719 20.9895C17.7034 21.0655 17.7494 21.1344 17.8076 21.1926L21.5576 24.9426C21.6156 25.0008 21.6846 25.0469 21.7605 25.0784C21.8364 25.11 21.9178 25.1262 22.0001 25.1262C22.0823 25.1262 22.1637 25.11 22.2396 25.0784C22.3155 25.0469 22.3845 25.0008 22.4426 24.9426Z"
        fill="white"
      />
    </svg>
  );
};

export default Reporting;
