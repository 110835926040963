export const NODE_TYPE = {
  basic: 'basic',
  startTest: 'startTest',
  completedTest: 'completedTest',
  userDecision: 'userDecision',
  annotation: 'annotation',
  retry: 'retry',
};

export const NODE_TYPE_ID = {
  testNode: 'test-node',
  decisionNode: 'decision-node',
  completedNode: 'completed-node',
  annotationNode: 'annotation-node',
  retryNode: 'retry-node',
};

export const EDGE_TYPE_ID = {
  testEdge: 'test-edge',
  completedEdge: 'completed-edge',
  retryEdge: 'retry-edge',
};
