import { PAYMENT_DETAILS_DATA_LABEL_MAP } from '../paymentDescribedDataLabelMap';

const convertPaymentObjectToArray = (obj) => {
  if (!obj) return [];

  const dataToExclude = ['__typename', 'description', 'statementDescriptor'];

  const reduced = Object.keys(obj).reduce((acc, curr) => {
    if (!dataToExclude.includes(curr)) {
      acc.push({
        key: curr,
        label: PAYMENT_DETAILS_DATA_LABEL_MAP[curr] ?? curr,
        value: typeof obj[curr] === 'string' || obj[curr] === null ? obj[curr] : obj[curr]?.formattedAmount,
      });
    }

    return acc;
  }, []);

  return reduced;
};

export { convertPaymentObjectToArray };
