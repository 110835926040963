import styled from 'styled-components';
import THEME from '../../../../constants/theme';

const StyledAutocomplete = styled.div`
  & .MuiAutocomplete-root {
    background-color: #f5f6f7;
    border-radius: 6px;
    height: 40px;

    & .MuiInput-underline::before,
    .MuiInput-underline::after {
      border-bottom: none !important;
    }

    & .MuiInput-underline.Mui-focused:before,
    .MuiInput-underline.Mui-focused:after {
      border-bottom: none;
    }

    & .MuiInputBase-root {
      padding: 4px 30px 4px 12px;
      height: 40px;

      &.Mui-focused {
        background-color: white;
        box-shadow: 0 0 2px 1.5px rgba(156, 160, 255, 0.2);
        border: 1px solid ${THEME.primaryColors.primary};
        border-radius: 6px;
      }

      & .MuiAutocomplete-input {
        &.Mui-disabled {
          color: ${THEME.greyColors.grey};
        }
      }
    }

    & .MuiButtonBase-root {
      right: 7px;
      top: 2px;
      position: absolute;
      width: 24px;
      height: 24px;
      margin-right: 4px;
      transition: all 300ms cubic-bezier(0, 0.84, 0.61, 1.01);
      transform: rotate(0);

      &:hover {
        background-color: transparent;
      }

      & .MuiIconButton-label {
        width: 24px;
        height: 24px;
      }

      &.MuiAutocomplete-popupIndicatorOpen {
        right: 12px;
        transform: rotate(180deg);
        margin-right: 0;
      }

      &.MuiAutocomplete-clearIndicatorDirty {
        visibility: hidden;
      }
    }

    & + .MuiAutocomplete-popper {
      /* max-height: 400px; */
      /* border: 2px solid red; */
      /* overflow-y: hidden; */
      /* background-color: orange; */
      /* top: 0; */
      margin-bottom: 10px;
    }

    & + .MuiAutocomplete-popper .MuiAutocomplete-listbox {
      padding: 4px;
      padding-right: 4px !important;

      & .MuiAutocomplete-option {
        border-radius: 4px;
        padding: 4px 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;

        &:hover {
          background-color: ${({ noBgColor }) => (noBgColor ? 'transparent' : THEME.greyColors.grey21)};
        }

        &[aria-selected='true'] {
          background-color: transparent;
          color: ${THEME.primaryColors.primary};
        }
      }
    }
  }
`;

export { StyledAutocomplete };
