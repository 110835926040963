const formNames = {
  currency: 'currency',
};

const formFields = [{ field: formNames.currency, label: 'Currency' }];

const initialValues = {
  [formNames.currency]: '',
};

export { initialValues, formFields, formNames };
