import React from 'react';
import PropTypes from 'prop-types';

import { StyledRadioLabel, StyledRadio, StyledFormControlLabel } from './styled';

const InputGroup = ({ color }) => {
  return (
    <StyledFormControlLabel
      value={color}
      control={<StyledRadio />}
      label={<StyledRadioLabel color={color}>&nbsp;</StyledRadioLabel>}
      labelPlacement="top"
    />
  );
};

InputGroup.propTypes = {
  color: PropTypes.string.isRequired,
};

export default InputGroup;
