import React from 'react';
import { shape, bool } from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useFormikContext } from 'formik';
import { L12, P14 } from '../../../../../atoms';
import THEME from '../../../../../../constants/theme';
import { DatePickerWrapper } from '../../../styled';

const Date = ({ item, showErrors }) => {
  const { values, errors, setFieldValue } = useFormikContext();

  return (
    <>
      <P14>{item.label.text}</P14>

      <DatePickerWrapper>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            value={values[item.block_id] || null}
            onChange={(value) => setFieldValue(item.block_id, value)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </DatePickerWrapper>

      {showErrors && errors[item.block_id] && (
        <L12 textAlign="left" margin="0 0 24px 0" color={THEME.secondaryColors.danger}>
          {errors[item.block_id]}
        </L12>
      )}
    </>
  );
};

Date.propTypes = {
  item: shape({}).isRequired,
  showErrors: bool,
};

Date.defaultProps = {
  showErrors: false,
};

export default Date;
