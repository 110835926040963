import { useCallback } from 'react';
import { Position } from 'react-flow-renderer';
import omit from 'lodash/omit';
import { repopulateEdges } from '../utils/facades';
import { flowStepTypes } from '../types';
import { filterNodes } from '../utils/filterElements';

export const useOnConnect = (setElementsAndSave) =>
  useCallback(
    ({ source, sourceHandle, targetHandle, target, removeTarget, isElseEdge }) =>
      setElementsAndSave((els) => {
        const nodes = filterNodes(els);
        const sourceNode = nodes.find((el) => el.id === source);
        const sourceNodeIndex = nodes.findIndex((el) => el.id === source);
        if (source === target || sourceNodeIndex < 0) {
          return repopulateEdges(nodes);
        }
        const typename = sourceNode?.data?.__typename;
        const newNodes = [...nodes];
        const newNode = newNodes[sourceNodeIndex];
        const newNodeData = newNode?.data;
        switch (sourceHandle) {
          case Position.Left:
          case Position.Right:
          case Position.Top:
          case Position.Bottom:
            // handle new conditions connections
            if (typename === flowStepTypes.DataConditionStep || typename === flowStepTypes.CountConditionStep) {
              const conditions = newNodeData?.conditions || [];
              // if condition already exists overwrite it
              const existingCondition = conditions.find((c) => c.nextStepId === target);
              const newConditions = existingCondition
                ? conditions.map((c) =>
                    c.nextStepId !== existingCondition?.nextStepId ? c : { ...c, nextStepId: target }
                  )
                : [...conditions, { nextStepId: target }];
              newNodes[sourceNodeIndex] = {
                ...newNode,
                data: {
                  ...newNodeData,
                  unsaved: removeTarget ? true : undefined,
                  conditions: removeTarget ? newConditions?.filter((c) => c?.nextStepId !== target) : newConditions,
                  elseStepId: removeTarget && isElseEdge ? null : newNodeData?.elseStepId,
                  layout: {
                    ...newNodeData?.layout,
                    uiMetadata: removeTarget
                      ? omit(newNodeData?.layout?.uiMetadata, target)
                      : {
                          ...newNodeData?.layout?.uiMetadata,
                          [target]: {
                            sourceHandle,
                            targetHandle,
                          },
                        },
                  },
                },
              };
              break;
            }
            // handle AB test condition connections
            if (typename === flowStepTypes.ABTestConditionStep) {
              const aBranchCondition = newNodeData?.aBranchCondition;
              const bBranchCondition = newNodeData?.bBranchCondition;
              const isABranchTarget = aBranchCondition?.nextStepId === target;
              const isBBranchTarget = bBranchCondition?.nextStepId === target;
              // handle AB test edge removing
              if (removeTarget) {
                newNodes[sourceNodeIndex] = {
                  ...newNode,
                  data: {
                    ...newNodeData,
                    aBranchCondition: {
                      ...aBranchCondition,
                      nextStepId: isABranchTarget ? null : aBranchCondition?.nextStepId,
                    },
                    bBranchCondition: {
                      ...bBranchCondition,
                      nextStepId: isBBranchTarget ? null : bBranchCondition?.nextStepId,
                    },
                    layout: {
                      ...newNodeData?.layout,
                      uiMetadata: omit(newNodeData?.layout?.uiMetadata, target),
                    },
                  },
                };
              }
              if (aBranchCondition?.nextStepId && bBranchCondition?.nextStepId) {
                break;
              }
              if (!aBranchCondition?.nextStepId) {
                if (isBBranchTarget) {
                  break;
                }
                newNodes[sourceNodeIndex] = {
                  ...newNode,
                  data: {
                    ...newNodeData,
                    aBranchCondition: {
                      ...aBranchCondition,
                      nextStepId: target,
                    },
                    layout: {
                      ...newNodeData?.layout,
                      uiMetadata: {
                        ...newNodeData?.layout?.uiMetadata,
                        [target]: {
                          sourceHandle,
                          targetHandle,
                        },
                      },
                    },
                  },
                };
              } else {
                if (isABranchTarget) {
                  break;
                }
                newNodes[sourceNodeIndex] = {
                  ...newNode,
                  data: {
                    ...newNodeData,
                    bBranchCondition: {
                      ...bBranchCondition,
                      nextStepId: target,
                    },
                    layout: {
                      ...newNodeData?.layout,
                      uiMetadata: {
                        ...newNodeData?.layout?.uiMetadata,
                        [target]: {
                          sourceHandle,
                          targetHandle,
                        },
                      },
                    },
                  },
                };
              }
              break;
            }
            // handle if else step connections
            if (typename === flowStepTypes.IfElseStep) {
              const trueStepId = newNodeData?.trueStepId;
              const falseStepId = newNodeData?.falseStepId;
              if (removeTarget) {
                const isTrueStepTarget = target === trueStepId;
                const isFalseStepTarget = target === falseStepId;
                newNodes[sourceNodeIndex] = {
                  ...newNode,
                  data: {
                    ...newNodeData,
                    trueStepId: isTrueStepTarget ? null : trueStepId,
                    falseStepId: isFalseStepTarget ? null : falseStepId,
                    layout: {
                      ...newNodeData?.layout,
                      uiMetadata: omit(newNodeData?.layout?.uiMetadata, target),
                    },
                  },
                };
                break;
              }
              if (trueStepId && falseStepId) {
                break;
              }
              // automatically set opposite condition if another has already been set
              if (trueStepId || falseStepId) {
                newNodes[sourceNodeIndex] = {
                  ...newNode,
                  data: {
                    ...newNodeData,
                    falseStepId: trueStepId ? target : falseStepId,
                    trueStepId: falseStepId ? target : trueStepId,
                    layout: {
                      ...newNodeData?.layout,
                      uiMetadata: {
                        ...newNodeData?.layout?.uiMetadata,
                        [target]: {
                          sourceHandle,
                          targetHandle,
                        },
                      },
                    },
                  },
                };
                break;
              }
            }
            newNodes[sourceNodeIndex] = {
              ...newNode,
              data: {
                ...newNodeData,
                nextStepId: removeTarget ? null : target,
                layout: {
                  ...newNodeData?.layout,
                  uiMetadata: removeTarget
                    ? omit(newNodeData?.layout?.uiMetadata, target)
                    : {
                        [target]: {
                          sourceHandle,
                          targetHandle,
                        },
                      },
                },
              },
            };
            break;
          default:
            newNodes[sourceNodeIndex] = {
              ...newNode,
              data: {
                ...newNodeData,
                nextStepId: removeTarget ? null : target,
                layout: {
                  ...newNodeData?.layout,
                  uiMetadata: removeTarget
                    ? omit(newNodeData?.layout?.uiMetadata, target)
                    : {
                        [target]: {
                          sourceHandle,
                          targetHandle,
                        },
                      },
                },
              },
            };
        }
        return repopulateEdges(newNodes);
      }),
    [setElementsAndSave]
  );
