/* eslint-disable array-callback-return */
/* eslint-disable no-case-declarations */
import { filterFn } from '../../../../hooks/dataTables/constant';

const LOADING_STATE_STYLES = {
  rows: 8,
  height: 50,
};

const DATA_TABLE_STYLE_PROPS = {
  width: '100%',
  height: '486px',
  margin: '27px 0',
  overflowY: 'hidden',
  trHeight: '56px',
};

const DIALOG_ID = 'team-modal';

const HEAD_CELL_DROPDOWN_OPTIONS = [
  {
    label: 'none',
  },
  {
    label: 'ascending',
  },
  {
    label: 'descending',
  },
];

const API_MAP = {
  getTeams: 'getTeams',
  searchTeams: 'searchTeams',
};

const SORT_DICTIONARY = {
  customer_name: 'customerName',
  team_name: 'teamName',
  team_members: 'teamMembers',
};

const SORT_DICTIONARY_MAP = {
  [SORT_DICTIONARY.customer_name]: 'customer',
  [SORT_DICTIONARY.team_name]: 'name',
  [SORT_DICTIONARY.team_members]: 'membersCount',
};

const parseBySearch = (data, search) => {
  return data?.filter((el) => {
    const _data = el?.node ?? {};

    return (
      filterFn(_data?.customer?.name, search) ||
      filterFn(_data?.customer?.billingDetails?.billingEmail, search) ||
      filterFn(_data?.name, search) ||
      filterFn(_data?.membersCount, search)
    );
  });
};

const parseBySort = (data, sort) => {
  const [key, sortValue] = Object.entries(sort).flat();
  const dataKey = SORT_DICTIONARY[key];

  if (!sortValue) {
    return data;
  }

  const sortFn = (a, b) => {
    if (dataKey === SORT_DICTIONARY.customer_name) {
      return a?.[SORT_DICTIONARY_MAP[dataKey]]?.name?.localeCompare(b?.customer?.name);
    }

    if (dataKey === SORT_DICTIONARY.team_members) {
      return a?.[SORT_DICTIONARY_MAP[dataKey]] - b?.[SORT_DICTIONARY_MAP[dataKey]];
    }

    return a?.[SORT_DICTIONARY_MAP[dataKey]]?.localeCompare(b?.[SORT_DICTIONARY_MAP[dataKey]]);
  };

  const result = data?.slice()?.sort((a, b) => (sortValue === 'asc' ? sortFn(a, b) : sortFn(b, a)));

  return result;
};

export {
  LOADING_STATE_STYLES,
  DATA_TABLE_STYLE_PROPS,
  DIALOG_ID,
  HEAD_CELL_DROPDOWN_OPTIONS,
  API_MAP,
  parseBySearch,
  parseBySort,
};
