import React from 'react';
import { Box } from '@mui/material';
import { shape, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  AutomationTemplate,
  Caption,
  Categories,
  DocsLink,
  Icons,
  IconWrapper,
  TemplateBorder,
  TemplateWrapper,
} from './styled';
import { ButtonRounded, L12M } from '../../../components/atoms';
import THEME from '../../../constants/theme';
import { TempH4 } from '../../../components/atoms/Typography/H4';
import { AUTOMATION_PLAN_DICTIONARY, AUTOMATION_PLAN_LABEL_MAP } from '../../FlowDetailsPage/PremiumDialog/constant';
import { getAutomationTemplateIcon } from '../../../constants/getAutomationTemplateIcon';
import AutomationItem from '../../FlowDetailsPage/Automation/RecommendedAutomations/RecommendedItem/AutomationItem';

const DOCUMENTATION_URL = process.env.REACT_APP_DEV_ZONE_URL;

const Template = ({ templateData, onSetOverviewData }) => {
  const { t } = useTranslation();
  const showPlanTag = templateData?.plan?.toLowerCase() === AUTOMATION_PLAN_DICTIONARY.PREMIUM?.toLowerCase();
  const planTag = AUTOMATION_PLAN_LABEL_MAP[templateData?.plan] || '';

  const handleSetOverviewData = (template) => {
    onSetOverviewData({ template, showPlanTag, planTag });
  };

  const printIcons = (categories, slug) => {
    const iconData = getAutomationTemplateIcon(categories);

    return iconData.map(({ Icon, color, key }, index) => {
      return (
        <IconWrapper key={`${slug}-icon-${key}`} zIndex={iconData.length - index}>
          <Icon color={color} size={20} />
        </IconWrapper>
      );
    });
  };

  return (
    <TemplateWrapper key={templateData.slug}>
      <Box display="flex">
        <Caption>
          <L12M
            color={THEME.primaryColors.white}
            textOverflow="ellipsis"
            overflow="hidden"
            noWrap
            textTransform="capitalize"
          >
            {templateData.paymentNeeds}
          </L12M>
        </Caption>
      </Box>
      <TemplateBorder>
        <AutomationTemplate key={templateData.slug}>
          <Box margin="0 0 16px" width="100%">
            <Box display="flex" justifyContent="space-between">
              <Icons>{printIcons(templateData.categories, templateData.slug)}</Icons>
              {showPlanTag && (
                <AutomationItem
                  category={planTag}
                  color={THEME.primaryColors.primary}
                  bgColor={THEME.primaryColors.primaryLight}
                />
              )}
            </Box>
            <Categories>{templateData.categories?.map((item) => item.split('_').join(' ')).join(', ')}</Categories>
            <TempH4 lineClamp={3} lineHeight="26px">
              {templateData.name}
            </TempH4>
          </Box>
          <Box display="flex">
            <ButtonRounded
              variant="contained"
              color="secondary"
              onClick={() => handleSetOverviewData(templateData)}
              fontWeight={500}
            >
              {t('Learn More')}
            </ButtonRounded>
            {templateData.paymentNeeds.includes('tokenization') && (
              <DocsLink href={`${DOCUMENTATION_URL}/tokenisation`} target="_blank">
                {t('View Documentation')}
              </DocsLink>
            )}
          </Box>
        </AutomationTemplate>
      </TemplateBorder>
    </TemplateWrapper>
  );
};

Template.propTypes = {
  templateData: shape({}).isRequired,
  onSetOverviewData: func.isRequired,
};

export default Template;
