import * as Yup from 'yup';
import FieldsWithSelect from '../../../../../pages/Payments/FilterList/fields/FieldsWithSelect';

import { OPTIONS as dateOptions } from '../../../../../pages/Payments/FilterList/fields/DateField/settings';
import CustomDateField from '../../../../../pages/Payments/FilterList/fields/DateField/CustomField';
import AsyncFieldset from '../../../../../pages/Payments/FilterList/fields/AsyncFieldset';
import { createFilterPropsFromSettings } from '../../../../../utils/createFilterPropsFromSettings';
import {
  useGetDisputesStatuses,
  useGetDisputesReasons,
  useGetDisputesSourceTypes,
} from '../../../../../hooks/useGetOptions';
import { SEARCH_KEYS, GT, LT } from '../../../../../utils/filterToSearchParams/constants';
import { OPTIONS as numberOptions } from '../../../../../pages/Payments/FilterList/fields/NumberField/settings';
import CustomNumberField from '../../../../../pages/Payments/FilterList/fields/NumberField/CustomField';

const lessMessage = ({ less }) => `must be greater than ${less}`;
const moreMessage = ({ more }) => `must be less than ${more}`;
const positive = 'must be a positive number';
const typeError = 'NaN'; //suppressed error to prevent triggering the valid state when we pass an empty string instead.

const FIELDS_SETTINGS = [
  {
    name: SEARCH_KEYS.disputedDate,
    label: 'disputed date',
    component: FieldsWithSelect,
    props: { options: dateOptions, component: CustomDateField, divider: 'and' },
    defaultValue: {},
  },
  {
    name: SEARCH_KEYS.responseDate,
    label: 'response date',
    component: FieldsWithSelect,
    props: { options: dateOptions, component: CustomDateField, divider: 'and' },
    defaultValue: {},
  },
  {
    name: SEARCH_KEYS.amount,
    label: 'amount',
    component: FieldsWithSelect,
    props: { options: numberOptions, component: CustomNumberField, divider: 'and' },
    defaultValue: {},
    schema: Yup.object().shape(
      {
        [LT]: Yup.number()
          .positive(positive)
          .when(`${GT}`, (gt, schema) => (gt ? schema.moreThan(Yup.ref(GT), moreMessage) : schema))
          .typeError(typeError),
        [GT]: Yup.number()
          .positive(positive)
          .when(`${LT}`, (lt, schema) => (lt ? schema.lessThan(Yup.ref(LT), lessMessage) : schema))
          .typeError(typeError),
      },
      [LT, GT]
    ),
  },
  {
    name: SEARCH_KEYS.paymentStatus,
    label: 'Status',
    component: AsyncFieldset,
    props: { useGetOptions: useGetDisputesStatuses },
    defaultValue: [],
  },
  {
    name: SEARCH_KEYS.reason,
    label: 'Reason',
    component: AsyncFieldset,
    props: { useGetOptions: useGetDisputesReasons },
    defaultValue: [],
  },
  {
    name: SEARCH_KEYS.sourceTypes,
    label: 'Processor',
    component: AsyncFieldset,
    props: { useGetOptions: useGetDisputesSourceTypes },
    defaultValue: [],
  },
];

const { FIELDS: DISPUTES_FIELDS, SHAPE, DEFAULT_VALUES: DISPUTES_DEFAULT_VALUES } = createFilterPropsFromSettings(
  FIELDS_SETTINGS
);

const disputesValidationSchema = Yup.object().shape(SHAPE);

export { DISPUTES_FIELDS, disputesValidationSchema, DISPUTES_DEFAULT_VALUES };
