import React from 'react';
import { string } from 'prop-types';

const Satispay = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#EF373F" />
    <path
      d="M15.5873 6.32054C15.7054 6.20236 15.6218 6.00022 15.4544 6.00022H12.6702L14.2891 7.61865L15.5873 6.32054Z"
      fill="white"
    />
    <path
      d="M11.6965 10.2115L9.70532 8.22024L7.91348 10.0124L6.05509 11.8707C5.98164 11.9439 5.98164 12.0629 6.05509 12.1361L7.91348 13.9948L9.70532 15.7866L11.6965 13.7954L10.0372 12.1361L9.90441 12.0036L10.0372 11.8707L11.6965 10.2115Z"
      fill="white"
    />
    <path
      d="M14.2893 16.3879L12.6772 18H15.4483C15.6153 18 15.6993 17.7979 15.5811 17.6797L14.2893 16.3879Z"
      fill="white"
    />
    <path
      d="M17.9394 11.8706L16.081 10.0122L13.9881 7.91941L12.1237 6.05495C12.09 6.02126 12.0444 6.00276 11.9969 6.0011C11.995 6.0011 11.9931 6 11.9909 6H8.53944C8.37238 6 8.28871 6.20213 8.4069 6.32032L10.006 7.91941L11.9969 9.91062L13.9572 11.8706L14.0898 12.0035L13.9572 12.136L11.9969 14.096L10.006 16.0872L8.41353 17.6797C8.29534 17.7979 8.37901 18 8.54607 18H11.9964H11.9978C12.0475 18 12.0952 17.9801 12.1303 17.945L13.9881 16.0872L16.081 13.9947L17.9394 12.136C18.0128 12.0628 18.0128 11.9441 17.9394 11.8706Z"
      fill="white"
    />
  </svg>
);

Satispay.propTypes = {
  width: string,
  height: string,
};

Satispay.defaultProps = {
  width: '24',
  height: '24',
};

export default Satispay;
