import React from 'react';

const Inbox = () => {
  return (
    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <rect x="8" y="66" width="144" height="36" rx="6" fill="white" />
        <rect x="7.75" y="65.75" width="144.5" height="36.5" rx="6.25" stroke="#E6E9EC" strokeWidth="0.5" />
      </g>
      <rect x="18" y="75" width="20" height="20" rx="4" fill="#E6E9EC" />
      <path
        d="M26.567 88.0999V88.3333C26.567 88.7134 26.718 89.078 26.9868 89.3468C27.2556 89.6156 27.6202 89.7666 28.0003 89.7666C28.3805 89.7666 28.745 89.6156 29.0138 89.3468C29.2826 89.078 29.4337 88.7134 29.4337 88.3333V88.0999H30.917C31.1866 88.0999 31.4452 87.9928 31.6359 87.8021L31.2116 87.3779L31.6359 87.8021C31.8265 87.6115 31.9337 87.3529 31.9337 87.0833L31.9337 86.8374L31.9337 86.8349C31.9325 86.5682 31.8266 86.3126 31.6388 86.1232L31.6371 86.1215L31.2913 85.7757L31.291 85.7754C31.1691 85.6536 31.1005 85.4884 31.1003 85.3161V84.1666C31.1003 83.4958 30.8828 82.8432 30.4803 82.3066L30.1053 81.8066C29.933 81.5768 29.7096 81.3903 29.4527 81.2619C29.314 81.1925 29.1676 81.1411 29.017 81.1085V81.0416C29.017 80.8272 28.9318 80.6216 28.7802 80.47C28.6286 80.3184 28.423 80.2333 28.2087 80.2333H27.792C27.5776 80.2333 27.372 80.3184 27.2204 80.47C27.0688 80.6216 26.9837 80.8272 26.9837 81.0416V81.1085C26.8331 81.1411 26.6867 81.1925 26.548 81.2619C26.2911 81.3903 26.0676 81.5768 25.8953 81.8066L25.5203 82.3066C25.1179 82.8432 24.9003 83.4958 24.9003 84.1666V85.3163C24.9001 85.4885 24.8315 85.6537 24.7097 85.7754L24.7094 85.7757L24.3636 86.1215L24.3636 86.1215L24.3618 86.1232C24.174 86.3126 24.0681 86.5682 24.067 86.8349H24.067V86.8374V87.0833C24.067 87.3529 24.1741 87.6115 24.3648 87.8021L24.789 87.3779L24.3648 87.8021C24.5554 87.9928 24.814 88.0999 25.0837 88.0999H26.567Z"
        stroke="#C1C3C6"
        strokeWidth="1.2"
      />
      <rect x="48.4463" y="82.8787" width="48" height="4" fill="#E6E9EC" />
      <rect x="120.446" y="82.8787" width="16" height="4" fill="#E6E9EC" />
      <g filter="url(#filter1_d)">
        <rect x="8" y="49" width="144" height="36" rx="6" fill="white" />
        <rect x="7.75" y="48.75" width="144.5" height="36.5" rx="6.25" stroke="#E6E9EC" strokeWidth="0.5" />
      </g>
      <rect x="18" y="57" width="20" height="20" rx="4" fill="#4E40EF" />
      <rect x="48" y="65" width="48" height="4" fill="#9CA0FF" />
      <rect x="120" y="65" width="16" height="4" fill="#F5F2FF" />
      <path
        d="M26.567 70.1V70.3334C26.567 70.7135 26.718 71.0781 26.9868 71.3469C27.2556 71.6157 27.6202 71.7667 28.0003 71.7667C28.3805 71.7667 28.745 71.6157 29.0138 71.3469C29.2826 71.0781 29.4337 70.7135 29.4337 70.3334V70.1H30.917C31.1866 70.1 31.4452 69.9929 31.6359 69.8023L31.2116 69.378L31.6359 69.8023C31.8265 69.6116 31.9337 69.353 31.9337 69.0834L31.9337 68.8375L31.9337 68.835C31.9325 68.5683 31.8266 68.3127 31.6388 68.1233L31.6371 68.1216L31.2913 67.7758L31.291 67.7755C31.1691 67.6537 31.1005 67.4885 31.1003 67.3162V66.1667C31.1003 65.496 30.8828 64.8433 30.4803 64.3067L30.1053 63.8067C29.933 63.5769 29.7096 63.3905 29.4527 63.262C29.314 63.1927 29.1676 63.1413 29.017 63.1086V63.0417C29.017 62.8273 28.9318 62.6217 28.7802 62.4701C28.6286 62.3185 28.423 62.2334 28.2087 62.2334H27.792C27.5776 62.2334 27.372 62.3185 27.2204 62.4701C27.0688 62.6217 26.9837 62.8273 26.9837 63.0417V63.1086C26.8331 63.1413 26.6867 63.1927 26.548 63.262C26.2911 63.3905 26.0676 63.5769 25.8953 63.8067L25.5203 64.3067C25.1179 64.8433 24.9003 65.496 24.9003 66.1667V67.3164C24.9001 67.4887 24.8315 67.6538 24.7097 67.7755L24.7094 67.7758L24.3636 68.1216L24.3636 68.1216L24.3618 68.1233C24.174 68.3127 24.0681 68.5683 24.067 68.835H24.067V68.8375V69.0834C24.067 69.353 24.1741 69.6116 24.3648 69.8023L24.789 69.378L24.3648 69.8023C24.5554 69.9929 24.814 70.1 25.0837 70.1H26.567Z"
        stroke="white"
        strokeWidth="1.2"
      />
      <defs>
        <filter
          id="filter0_d"
          x="3.5"
          y="63.5"
          width="153"
          height="45"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="filter1_d"
          x="3.5"
          y="46.5"
          width="153"
          height="45"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default Inbox;
