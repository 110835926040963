import styled, { css } from 'styled-components';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import THEME from '../../../../constants/theme';

export const CustomRadiosWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 16px;

  > div {
    width: 100%;

    > label {
      position: relative;
    }
  }
`;

const StyledOptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 6px;
  width: 100%;
  justify-content: space-between;
`;

export const CustomRadiosStyled = styled.div`
  margin-bottom: 8px;
  width: 100%;
  @media (min-width: ${THEME.breakPoints.tablet}px) {
    width: calc(50% - 4px);
  }

  > label {
    position: relative;
    display: flex;
    width: 100%;
    padding: 16px;
    cursor: pointer;
    transition: 0.25s;
    background-color: white;
    border: 1px solid ${THEME.greyColors.grey16};
    border-radius: 6px;

    > div {
      position: relative;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      box-sizing: border-box;
      border: 1px solid #c4c4c4;
      transition: 0.25s;
      display: inline-block;
      margin-right: 12px;
      top: 1px;
      flex-shrink: 0;
    }

    &:hover {
      border-color: ${({ checked }) => (checked ? THEME.primaryColors.primary : THEME.greyColors.grey300)};
    }

    ${({ checked }) =>
      checked &&
      css`
        background-color: ${THEME.primaryColors.lightBlue};
        border-color: ${THEME.primaryColors.primary};
        > label {
          /* background: white !important; */
          > div {
            border: 6px solid #4545e9;
          }
        }
      `};
  }

  input {
    opacity: 0;
    height: 0;
    width: 0;
  }

  ${({ checked }) =>
    checked &&
    css`
      /* background-color: white; */
      border-color: #4545e9;
      > label {
        /* background: white !important; */
        > div {
          border: 6px solid #4545e9;
        }
      }
    `};

  ${({ focused }) =>
    focused &&
    css`
      > label {
        border-color: ${THEME.greyColors.grey300};
      }
    `};
`;

const StyledInputLabel = styled(InputLabel)`
  &.MuiFormLabel-root {
    color: initial;
    line-height: 20px;
    font-weight: 600;
  }

  &.Mui-focused {
    color: #3f51b5;
  }

  &.MuiInputLabel-shrink {
    transform: translate(0, 1.5px);
  }
`;

const StyledInput = styled(InputBase)`
  &.MuiInputBase-root {
    label + & {
      margin-top: 30px;
    }
  }

  .MuiInputBase-input {
    position: relative;
    height: 18px;
    padding: 10px 16px;
    border: 1px solid transparent;
    border-radius: 6px;
    background-color: #f5f6f7;
    line-height: 18px;
    transition-property: border-color, box-shadow;
    transition-duration: 0.3s;

    &:focus {
      border-color: #3023c8;
    }
  }
`;
export { StyledInput, StyledInputLabel, StyledOptionsWrapper };
