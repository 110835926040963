import React from 'react';
import { string } from 'prop-types';
import { StyledLoadingAnimationWrapper, StyledLoadingAnimation, StyledLoadingItem } from './styled';
import { P16M } from '../atoms';

const LoadingAnimation = ({ text }) => (
  <StyledLoadingAnimationWrapper>
    <StyledLoadingAnimation>
      {[...Array(4).keys()].map((value) => (
        <StyledLoadingItem key={value} />
      ))}
    </StyledLoadingAnimation>
    {text && (
      <P16M margin="36px 0 0" maxWidth="225px" textAlign="center">
        {text}
      </P16M>
    )}
  </StyledLoadingAnimationWrapper>
);

LoadingAnimation.propTypes = {
  text: string,
};

LoadingAnimation.defaultProps = {
  text: '',
};

export default LoadingAnimation;
