import React from 'react';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { StyledButton } from './styled';
import { L10UM } from '../../../atoms/Typography/L10UM';
import THEME from '../../../../constants/theme';
import DoubleArrowVertical from '../../../../assets/icons/DoubleArrowVertical';
import LoadingState from './LoadingState';
import EmptyState from './EmptyState';
import { GET_TEAM } from '../../../../utils/queries/workspaces/workspacesQueries';

const AccountName = ({ toggleModal }) => {
  const { t } = useTranslation();

  const { data, loading } = useQuery(GET_TEAM, {
    fetchPolicy: 'network-only',
  });

  const customerName = data?.team?.name.toUpperCase();

  return (
    <StyledButton onClick={toggleModal} data-ci="account-name">
      <L10UM color={THEME.primaryColors.primary} margin="0 6px 0 0">
        {loading ? <LoadingState /> : t(customerName)}
        {!loading && !customerName && <EmptyState />}
      </L10UM>
      <DoubleArrowVertical />
    </StyledButton>
  );
};

AccountName.propTypes = {
  toggleModal: func.isRequired,
};

export default AccountName;
