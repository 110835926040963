import styled from 'styled-components';
import THEME from '../../../../constants/theme';

export const StyledIcon = styled.div`
  & svg {
    margin-left: 6px;
    color: ${THEME.primaryColors.primary};
    font-size: 12px;
    stroke: ${THEME.primaryColors.primary};
    stroke-width: 2px;
    transform: translateY(2px);
  }
`;

export const ConnectionTileLogo = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: ${({ width }) => width || '86px'};
  height: ${({ height }) => height || '86px'};
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    object-fit: cover;
    ${({ isSquare }) => isSquare && 'transform: scale(1.5);'}
  }
`;
