import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { func } from 'prop-types';
import moment from 'moment';
import Box from '@mui/material/Box';
import { useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import ActionItem from './ActionItem';
import ListEmptyState from '../EmptyState';
import { useNotificationManager } from '../../../hooks/useNotificationManager';
import { GET_LIST_REQUIRES_ACTIONS } from '../queries';
import { StyledListContainer } from '../styled';
import ListSkeleton from '../../ListSkeleton';

const TITLE = 'There are no open actions';
const DESC = 'New actions will appear here.';

const Actions = ({ toggleNotification }) => {
  const [currentActions, setCurrentActions] = useState([]);
  const { error, data, loading } = useQuery(GET_LIST_REQUIRES_ACTIONS, {
    fetchPolicy: 'no-cache',
  });
  useNotificationManager('error', error?.message, 'Fetch required actions');

  const actions = useMemo(() => data?.listRequiresAction ?? [], [data?.listRequiresAction]);
  useEffect(() => {
    setCurrentActions([...actions]?.sort((a, b) => moment(b?.ts).diff(a?.ts)));
  }, [actions]);

  const onSuccess = useCallback(
    (id) => {
      const filteredActions = currentActions.filter(({ id: itemId }) => itemId !== id);

      setCurrentActions(filteredActions);
    },
    [currentActions]
  );

  return (
    <StyledListContainer>
      {loading && (
        <Box mt="-8px">
          <ListSkeleton rowNumber={5} height={80} />
        </Box>
      )}

      {!loading &&
        !isEmpty(actions) &&
        currentActions.map((item) => (
          <ActionItem
            key={item.id}
            title={item.title}
            description={item.description}
            timeStamp={item.ts}
            actions={item.actions}
            toggleNotification={toggleNotification}
            onSuccess={() => onSuccess(item.id)}
          />
        ))}

      {!loading && isEmpty(actions) && <ListEmptyState title={TITLE} description={DESC} type="actions" />}
    </StyledListContainer>
  );
};

Actions.propTypes = {
  toggleNotification: func.isRequired,
};

export default Actions;
