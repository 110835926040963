import React from 'react';

const Star = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 12L5.27708 13.4316C4.95118 13.6029 4.5481 13.4776 4.37677 13.1517C4.30854 13.0219 4.285 12.8733 4.30978 12.7288L4.82981 9.69677L2.62692 7.54948C2.36327 7.29248 2.35787 6.87041 2.61487 6.60675C2.71721 6.50176 2.85131 6.43344 2.9964 6.41236L6.04072 5.96999L7.40218 3.21137C7.56513 2.8812 7.96488 2.74564 8.29504 2.90859C8.42652 2.97348 8.53294 3.0799 8.59783 3.21137L9.95929 5.96999L13.0036 6.41236C13.368 6.4653 13.6204 6.8036 13.5675 7.16796C13.5464 7.31305 13.4781 7.44715 13.3731 7.54948L11.1702 9.69677L11.6902 12.7288C11.7525 13.0917 11.5087 13.4363 11.1458 13.4986C11.0013 13.5233 10.8527 13.4998 10.7229 13.4316L8 12Z"
      fill="white"
    />
  </svg>
);

export default Star;
