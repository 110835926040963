import PropTypes from 'prop-types';
import React from 'react';
import { P14 } from '../../../../components/atoms';

const AmountComponent = ({ dataObj }) => {
  const amount = dataObj.value?.formattedAmount || 'N/A';

  return <P14>{amount}</P14>;
};

AmountComponent.propTypes = {
  dataObj: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  }),
};
AmountComponent.defaultProps = {
  dataObj: {},
};

export default AmountComponent;
