import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import { NotificationManager } from 'react-notifications';
import { useLoadingIndicator } from '../../../hooks/useLoadingIndicator';
import { globalLoadingConst } from '../../../constants/globalLoadingConsts';
import { ADD_COMMENT, DELETE_COMMENT, EDIT_COMMENT } from '../../../utils/queries/comments/commentsMutations';
import useIsDemo from '../../../hooks/useIsDemo';
import ToastCustomContainer from '../../../components/ToastCustomContainer';

const TOAST_TIMEOUT = 5000;

export const useComments = () => {
  const [addComment, { loading: addingLoading }] = useMutation(ADD_COMMENT);
  const [editComment, { loading: editCommentLoading }] = useMutation(EDIT_COMMENT);
  const [deleteComment, { loading: deleteCommentLoading }] = useMutation(DELETE_COMMENT);
  const loading = addingLoading || editCommentLoading || deleteCommentLoading;
  const isDemo = useIsDemo();
  const updateComment = useCallback(
    (data) => {
      const mutation = data.commentId ? editComment : addComment;
      return mutation({
        variables: data,
      }).then((res) => {
        if (res && !res.errors) {
          NotificationManager.success(
            <ToastCustomContainer
              message={`Comment have been ${data.commentId ? 'updated' : 'created'}.`}
              title="Success."
            />,
            null,
            TOAST_TIMEOUT
          );
        } else if (!isDemo) {
          NotificationManager.error(
            <ToastCustomContainer message="Server error." title="uiMessages.error" />,
            null,
            TOAST_TIMEOUT
          );
        }
        return res;
      });
    },
    [addComment, editComment, isDemo]
  );

  const deleteHandleFunction = useCallback(
    (variables) => {
      return deleteComment({
        variables,
      }).then((res) => {
        if (res && !res.errors) {
          NotificationManager.success(
            <ToastCustomContainer message="Comment have been deleted." title="Success." />,
            null,
            TOAST_TIMEOUT
          );
        } else {
          NotificationManager.error(
            <ToastCustomContainer message="Server error." title="uiMessages.error" />,
            null,
            TOAST_TIMEOUT
          );
        }
        return res;
      });
    },
    [deleteComment]
  );

  useLoadingIndicator(globalLoadingConst.createIssue, loading);

  return {
    updateComment,
    deleteComment: deleteHandleFunction,
  };
};
