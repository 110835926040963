import React from 'react';

const Pencil = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.50001 1.58569C9.37996 1.58569 9.26108 1.60934 9.15016 1.65528C9.03924 1.70123 8.93846 1.76857 8.85357 1.85346L2.19746 8.50957L1.71262 10.2873L3.49035 9.80246L10.1465 3.14635C10.2314 3.06146 10.2987 2.96068 10.3446 2.84976C10.3906 2.73885 10.4142 2.61996 10.4142 2.49991C10.4142 2.37985 10.3906 2.26097 10.3446 2.15005C10.2987 2.03914 10.2314 1.93835 10.1465 1.85346C10.0616 1.76857 9.96078 1.70123 9.84987 1.65528C9.73895 1.60934 9.62007 1.58569 9.50001 1.58569ZM8.76747 0.731404C8.99972 0.635206 9.24863 0.585693 9.50001 0.585693C9.75139 0.585693 10.0003 0.635206 10.2325 0.731404C10.4648 0.827602 10.6758 0.968602 10.8536 1.14635C11.0313 1.3241 11.1723 1.53513 11.2685 1.76737C11.3647 1.99961 11.4142 2.24853 11.4142 2.49991C11.4142 2.75128 11.3647 3.0002 11.2685 3.23244C11.1723 3.46469 11.0313 3.67571 10.8536 3.85346L4.10356 10.6035C4.04204 10.665 3.96552 10.7094 3.88157 10.7323L1.13157 11.4823C0.958464 11.5295 0.773333 11.4803 0.646458 11.3535C0.519583 11.2266 0.470419 11.0415 0.51763 10.8683L1.26763 8.11835C1.29052 8.0344 1.33493 7.95788 1.39646 7.89635L8.14646 1.14635C8.32421 0.968602 8.53523 0.827602 8.76747 0.731404Z"
      fill="white"
    />
  </svg>
);

export default Pencil;
