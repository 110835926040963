import React from 'react';
import { number, string } from 'prop-types';
import THEME from '../../../constants/theme';

const Slack = ({ size, color }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5 10C13.67 10 13 9.33 13 8.5V3.5C13 2.67 13.67 2 14.5 2C15.33 2 16 2.67 16 3.5V8.5C16 9.33 15.33 10 14.5 10Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 2.5C13.9461 2.5 13.5 2.94614 13.5 3.5V8.5C13.5 9.05386 13.9461 9.5 14.5 9.5C15.0539 9.5 15.5 9.05386 15.5 8.5V3.5C15.5 2.94614 15.0539 2.5 14.5 2.5ZM12.5 3.5C12.5 2.39386 13.3939 1.5 14.5 1.5C15.6061 1.5 16.5 2.39386 16.5 3.5V8.5C16.5 9.60614 15.6061 10.5 14.5 10.5C13.3939 10.5 12.5 9.60614 12.5 8.5V3.5Z"
      fill={color}
    />
    <path d="M20.5 10H19V8.5C19 7.67 19.67 7 20.5 7C21.33 7 22 7.67 22 8.5C22 9.33 21.33 10 20.5 10Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5 7.5C19.9461 7.5 19.5 7.94614 19.5 8.5V9.5H20.5C21.0539 9.5 21.5 9.05386 21.5 8.5C21.5 7.94614 21.0539 7.5 20.5 7.5ZM18.5 8.5C18.5 7.39386 19.3939 6.5 20.5 6.5C21.6061 6.5 22.5 7.39386 22.5 8.5C22.5 9.60614 21.6061 10.5 20.5 10.5H19C18.7239 10.5 18.5 10.2761 18.5 10V8.5Z"
      fill={color}
    />
    <path
      d="M9.5 14C10.33 14 11 14.67 11 15.5V20.5C11 21.33 10.33 22 9.5 22C8.67 22 8 21.33 8 20.5V15.5C8 14.67 8.67 14 9.5 14Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 14.5C8.94614 14.5 8.5 14.9461 8.5 15.5V20.5C8.5 21.0539 8.94614 21.5 9.5 21.5C10.0539 21.5 10.5 21.0539 10.5 20.5V15.5C10.5 14.9461 10.0539 14.5 9.5 14.5ZM7.5 15.5C7.5 14.3939 8.39386 13.5 9.5 13.5C10.6061 13.5 11.5 14.3939 11.5 15.5V20.5C11.5 21.6061 10.6061 22.5 9.5 22.5C8.39386 22.5 7.5 21.6061 7.5 20.5V15.5Z"
      fill={color}
    />
    <path d="M3.5 14H5V15.5C5 16.33 4.33 17 3.5 17C2.67 17 2 16.33 2 15.5C2 14.67 2.67 14 3.5 14Z" fill="black" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 14.5C2.94614 14.5 2.5 14.9461 2.5 15.5C2.5 16.0539 2.94614 16.5 3.5 16.5C4.05386 16.5 4.5 16.0539 4.5 15.5V14.5H3.5ZM1.5 15.5C1.5 14.3939 2.39386 13.5 3.5 13.5H5C5.27614 13.5 5.5 13.7239 5.5 14V15.5C5.5 16.6061 4.60614 17.5 3.5 17.5C2.39386 17.5 1.5 16.6061 1.5 15.5Z"
      fill={color}
    />
    <path
      d="M14 14.5C14 13.67 14.67 13 15.5 13H20.5C21.33 13 22 13.67 22 14.5C22 15.33 21.33 16 20.5 16H15.5C14.67 16 14 15.33 14 14.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 13.5C14.9461 13.5 14.5 13.9461 14.5 14.5C14.5 15.0539 14.9461 15.5 15.5 15.5H20.5C21.0539 15.5 21.5 15.0539 21.5 14.5C21.5 13.9461 21.0539 13.5 20.5 13.5H15.5ZM13.5 14.5C13.5 13.3939 14.3939 12.5 15.5 12.5H20.5C21.6061 12.5 22.5 13.3939 22.5 14.5C22.5 15.6061 21.6061 16.5 20.5 16.5H15.5C14.3939 16.5 13.5 15.6061 13.5 14.5Z"
      fill={color}
    />
    <path
      d="M15.5 19H14V20.5C14 21.33 14.67 22 15.5 22C16.33 22 17 21.33 17 20.5C17 19.67 16.33 19 15.5 19Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 19C13.5 18.7239 13.7239 18.5 14 18.5H15.5C16.6061 18.5 17.5 19.3939 17.5 20.5C17.5 21.6061 16.6061 22.5 15.5 22.5C14.3939 22.5 13.5 21.6061 13.5 20.5V19ZM14.5 19.5V20.5C14.5 21.0539 14.9461 21.5 15.5 21.5C16.0539 21.5 16.5 21.0539 16.5 20.5C16.5 19.9461 16.0539 19.5 15.5 19.5H14.5Z"
      fill={color}
    />
    <path
      d="M10 9.5C10 8.67 9.33 8 8.5 8H3.5C2.67 8 2 8.67 2 9.5C2 10.33 2.67 11 3.5 11H8.5C9.33 11 10 10.33 10 9.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 9.5C1.5 8.39386 2.39386 7.5 3.5 7.5H8.5C9.60614 7.5 10.5 8.39386 10.5 9.5C10.5 10.6061 9.60614 11.5 8.5 11.5H3.5C2.39386 11.5 1.5 10.6061 1.5 9.5ZM3.5 8.5C2.94614 8.5 2.5 8.94614 2.5 9.5C2.5 10.0539 2.94614 10.5 3.5 10.5H8.5C9.05386 10.5 9.5 10.0539 9.5 9.5C9.5 8.94614 9.05386 8.5 8.5 8.5H3.5Z"
      fill={color}
    />
    <path d="M8.5 5H10V3.5C10 2.67 9.33 2 8.5 2C7.67 2 7 2.67 7 3.5C7 4.33 7.67 5 8.5 5Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 3.5C6.5 2.39386 7.39386 1.5 8.5 1.5C9.60614 1.5 10.5 2.39386 10.5 3.5V5C10.5 5.27614 10.2761 5.5 10 5.5H8.5C7.39386 5.5 6.5 4.60614 6.5 3.5ZM8.5 2.5C7.94614 2.5 7.5 2.94614 7.5 3.5C7.5 4.05386 7.94614 4.5 8.5 4.5H9.5V3.5C9.5 2.94614 9.05386 2.5 8.5 2.5Z"
      fill={color}
    />
  </svg>
);

Slack.propTypes = {
  size: number,
  color: string,
};

Slack.defaultProps = {
  size: 24,
  color: THEME.greyColors.grey17,
};

export default Slack;
