import styled from 'styled-components';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import THEME from '../../constants/theme';

export const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
`;

export const StyledDialogBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const StyledDialogContent = styled(Box)`
  display: flex;
  align-items: center;
  height: 100%;
  overflow-y: hidden;
`;

export const StyledContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 40px 40px 0 40px;
  border-left: 1px solid ${THEME.greyColors.grey200};
  &.MuiBox-root {
    // styles from react-masonry-css docs
    & .pg-masonry-grid {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-left: -16px; /* gutter size offset */
      width: auto;
      & .pg-masonry-grid_column {
        padding-left: 16px; /* gutter size */
        background-clip: padding-box;
      }
    }
  }
`;

export const StyledGoalBox = styled(Box)`
  border-radius: 12px;
  margin-bottom: 16px;
  border: 1px solid ${THEME.greyColors.grey200};
  border-color: ${({ $checked }) => ($checked ? THEME.primaryColors.blue : THEME.greyColors.grey200)};
  background-color: ${({ $checked }) => ($checked ? THEME.primaryColors.lightBlue : 'transparent')};
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    display: flex;
    align-items: center;
    padding: 20px;
    margin: 0;
  }
`;
