import styled from 'styled-components';
import THEME from '../../../constants/theme';

export const LeftSideFormWrapper = styled.div`
  /* padding: ${({ padding }) => padding || '60px 94px 60px 112px'}; */

  

  /* width: 592px;
  box-sizing: border-box;

  @media (max-width: ${THEME.breakPoints.tablet}px) {
    padding: 0;
  }
  @media (max-width: ${THEME.breakPoints.desktop}px) {
    padding: 60px;
    max-width: 592px;
    width: 50vw;
    flex-shrink: 0;
  }
  @media (max-width: ${THEME.breakPoints.tablet}px) {
    width: 100%;
    padding: 20px;
  } */
`;
