import React from 'react';
import { string, number, oneOfType } from 'prop-types';

const DragHandleIcon = ({ size, fill }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 11.25C8.19036 11.25 8.75 10.6904 8.75 10C8.75 9.30964 8.19036 8.75 7.5 8.75C6.80964 8.75 6.25 9.30964 6.25 10C6.25 10.6904 6.80964 11.25 7.5 11.25Z"
        fill={fill}
      />
      <path
        d="M12.5 11.25C13.1904 11.25 13.75 10.6904 13.75 10C13.75 9.30964 13.1904 8.75 12.5 8.75C11.8096 8.75 11.25 9.30964 11.25 10C11.25 10.6904 11.8096 11.25 12.5 11.25Z"
        fill={fill}
      />
      <path
        d="M7.5 6.25C8.19036 6.25 8.75 5.69036 8.75 5C8.75 4.30964 8.19036 3.75 7.5 3.75C6.80964 3.75 6.25 4.30964 6.25 5C6.25 5.69036 6.80964 6.25 7.5 6.25Z"
        fill={fill}
      />
      <path
        d="M12.5 6.25C13.1904 6.25 13.75 5.69036 13.75 5C13.75 4.30964 13.1904 3.75 12.5 3.75C11.8096 3.75 11.25 4.30964 11.25 5C11.25 5.69036 11.8096 6.25 12.5 6.25Z"
        fill={fill}
      />
      <path
        d="M7.5 16.25C8.19036 16.25 8.75 15.6904 8.75 15C8.75 14.3096 8.19036 13.75 7.5 13.75C6.80964 13.75 6.25 14.3096 6.25 15C6.25 15.6904 6.80964 16.25 7.5 16.25Z"
        fill={fill}
      />
      <path
        d="M12.5 16.25C13.1904 16.25 13.75 15.6904 13.75 15C13.75 14.3096 13.1904 13.75 12.5 13.75C11.8096 13.75 11.25 14.3096 11.25 15C11.25 15.6904 11.8096 16.25 12.5 16.25Z"
        fill={fill}
      />
    </svg>
  );
};

DragHandleIcon.propTypes = {
  size: oneOfType([string, number]),
  fill: string,
};
DragHandleIcon.defaultProps = {
  size: '20',
  fill: '#C4C4C4',
};

export default DragHandleIcon;
