import React, { useEffect, useRef, useState } from 'react';
import { number, string } from 'prop-types';
import Box from '@mui/material/Box';
import { StyledWrapper } from './styled';
import { Retry } from '../Node/RetryNode/Retry';

const retryButtonSize = 44;

const RetryEdgeButton = ({ edgeCenterX, edgeCenterY, targetId }) => {
  const ref = useRef();
  const [width, setWidth] = useState(0);
  const [height] = useState(retryButtonSize);

  useEffect(() => {
    setWidth(ref.current.offsetWidth);
  }, []);

  return (
    <foreignObject
      width={width}
      height={height}
      x={edgeCenterX - width / 2}
      y={edgeCenterY - height / 2}
      className="edgebutton-foreignobject"
      requiredExtensions="http://www.w3.org/1999/xhtml"
    >
      <StyledWrapper>
        <Box ref={ref}>
          <Retry stepId={targetId} />
        </Box>
      </StyledWrapper>
    </foreignObject>
  );
};

RetryEdgeButton.propTypes = {
  edgeCenterX: number,
  edgeCenterY: number,
  targetId: string.isRequired,
};

RetryEdgeButton.defaultProps = {
  edgeCenterX: 0,
  edgeCenterY: 0,
};

export { RetryEdgeButton };
