import React from 'react';
import { useCopyToClipboard } from 'react-use';
import { string, node } from 'prop-types';
import { NotificationManager } from 'react-notifications';
import { StyledCopyContainer } from './styled';
import CopyIcon from '../../assets/icons/Copy';
import THEME from '../../constants/theme';
import { useNotificationManager } from '../../hooks/useNotificationManager';
import ToastCustomContainer from '../ToastCustomContainer';
import { TOAST_TIMEOUT } from '../../constants/toasts';

const StyledCopyToClipboardWithCopyIcon = ({ children, text, toastMessage, toastTitle }) => {
  const [{ error }, copyToClipboard] = useCopyToClipboard();

  useNotificationManager('error', error?.message, toastTitle);

  const handleCopy = (e) => {
    e.stopPropagation();

    copyToClipboard(text);

    NotificationManager.success(
      <ToastCustomContainer message={toastMessage} title={toastTitle} />,
      null,
      TOAST_TIMEOUT
    );
  };

  return (
    <StyledCopyContainer onClick={handleCopy}>
      {children}
      <CopyIcon fill={THEME.greyColors.grey1} />
    </StyledCopyContainer>
  );
};

StyledCopyToClipboardWithCopyIcon.propTypes = {
  children: node.isRequired,
  text: string.isRequired,
  toastMessage: string.isRequired,
  toastTitle: string.isRequired,
};

export default StyledCopyToClipboardWithCopyIcon;
