import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { string, number, bool } from 'prop-types';
import { BlockWrap, L12 } from '../../atoms';
import Select from '../_common/StyledSelect';
import StyledTooltip from '../../atoms/StyledTooltip';
import THEME from '../../../constants/theme';

// TODO : take this out of the SignUpCompanyDetailsForm and make it a global form element

const PaperComponent = styled.div`
  &.MuiPaper-root,
  &.MuiPaper-root.MuiPaper-elevation8:not(.MuiCard-root) {
    background-color: #fff;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    margin-top: 6px;
    max-height: 200px;
    max-width: 400px;
    border: none;
  }

  & .MuiList-padding {
    padding: 4px;
    padding-right: 4px !important; // Needed for Country select at Data Table - Create Customer form. Investigate further on ways we don't have to use '!important' on this.
  }
`;

const styles = {
  color: THEME.secondaryColors.nodeError,
  marginLeft: '3px',
};

const CustomSelect = ({ label, index, labelHeight, tooltip, isRequired, ...rest }) => {
  return (
    <BlockWrap margin="0 0 12px 0">
      {label && (
        <Box height={labelHeight} display="flex" justifyContent="flex-start" alignItems="center" position="relative">
          <L12 margin="" fontWeight="700">
            {label}

            {isRequired && (
              <span className="required" style={styles}>
                *
              </span>
            )}
          </L12>

          {tooltip && <StyledTooltip title={tooltip} />}
        </Box>
      )}
      <Select paperComponent={PaperComponent} {...rest} />
    </BlockWrap>
  );
};
CustomSelect.propTypes = {
  label: string,
  index: number,
  labelHeight: string,
  tooltip: string,
  isRequired: bool,
};

CustomSelect.defaultProps = {
  label: '',
  index: 0,
  labelHeight: '',
  tooltip: '',
  isRequired: false,
};
export default CustomSelect;
