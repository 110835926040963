import React from 'react';
import PropTypes from 'prop-types';

const Brackets = ({ size = 20 }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.12366 13.5183C5.12366 13.4631 5.07888 13.4183 5.02366 13.4183H4.53333C4.30359 13.4183 4.14964 13.3609 4.05243 13.2637C3.95522 13.1665 3.89785 13.0125 3.89785 12.7828C3.89785 12.5491 3.95676 12.2327 4.01872 11.9023L4.02021 11.8944C4.08038 11.5735 4.14301 11.2394 4.14301 10.9849C4.14301 10.7586 4.07433 10.5718 3.94569 10.4198C3.86863 10.3287 3.77199 10.2524 3.66022 10.1874C3.77151 10.1373 3.86826 10.0685 3.94569 9.97696C4.07728 9.82145 4.14301 9.61243 4.14301 9.35054C4.14301 9.09605 4.08038 8.76203 4.02021 8.44113L4.01872 8.43318C3.95676 8.10275 3.89785 7.78642 3.89785 7.55269C3.89785 7.33182 3.97005 7.20065 4.07334 7.1212C4.18141 7.03806 4.33977 6.99892 4.53333 6.99892H5.10538C5.1606 6.99892 5.20538 6.95415 5.20538 6.89892V6C5.20538 5.94477 5.1606 5.9 5.10538 5.9H4.36989C3.90723 5.9 3.51179 6.00494 3.23125 6.25181C2.94835 6.50076 2.79892 6.87919 2.79892 7.38925C2.79892 7.68564 2.86208 8.06234 2.92303 8.4077C2.92796 8.43564 2.93286 8.46333 2.93772 8.49075C2.99427 8.80987 3.04409 9.09105 3.04409 9.26882C3.04409 9.40463 2.99453 9.49739 2.91818 9.55847C2.83881 9.62196 2.72023 9.65914 2.57204 9.65914H2C1.94477 9.65914 1.9 9.70391 1.9 9.75914V10.6581C1.9 10.7133 1.94477 10.7581 2 10.7581H2.57204C2.76648 10.7581 2.87657 10.7976 2.94058 10.8616C3.00459 10.9256 3.04409 11.0357 3.04409 11.2301C3.04409 11.4006 2.99823 11.6505 2.94438 11.9439C2.9374 11.9819 2.93028 12.0207 2.92312 12.0601C2.86195 12.3965 2.79892 12.7726 2.79892 13.1097C2.79892 13.5815 2.95005 13.9394 3.23299 14.1771C3.51293 14.4123 3.90717 14.5172 4.36989 14.5172H5.02366C5.07888 14.5172 5.12366 14.4724 5.12366 14.4172V13.5183Z"
      fill="white"
      stroke="white"
      strokeWidth="0.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 12.5377C6 12.0473 6.32688 11.7205 6.8172 11.7205C7.22581 11.7205 7.55269 12.0473 7.55269 12.5377C7.55269 13.028 7.22581 13.2731 6.8172 13.2731C6.32688 13.2731 6 12.9463 6 12.5377Z"
      fill="white"
    />
    <path
      d="M9.2 12.5377C9.2 12.0473 9.52688 11.7205 9.93548 11.7205C10.3441 11.7205 10.671 12.0473 10.671 12.4559C10.671 12.8645 10.3441 13.2731 9.93548 13.2731C9.52688 13.2731 9.2 12.9463 9.2 12.5377Z"
      fill="white"
    />
    <path
      d="M12.3613 12.5377C12.3613 12.1291 12.6882 11.8022 13.0968 11.8022C13.5054 11.8022 13.8323 12.1291 13.8323 12.5377C13.8323 12.9463 13.5054 13.2732 13.0968 13.2732C12.6882 13.2732 12.3613 12.9463 12.3613 12.5377Z"
      fill="white"
    />
    <path
      d="M17.9999 10.7581C18.0551 10.7581 18.0999 10.7133 18.0999 10.6581V9.75914V9.67742C18.0999 9.62219 18.0551 9.57742 17.9999 9.57742H17.4279C17.2221 9.57742 17.112 9.5548 17.05 9.50655C16.9959 9.46444 16.9558 9.38293 16.9558 9.1871C16.9558 9.01656 17.0017 8.76675 17.0555 8.47333C17.0625 8.43531 17.0696 8.39655 17.0768 8.35714C17.138 8.02071 17.201 7.64463 17.201 7.30753C17.201 6.83566 17.0499 6.47777 16.7669 6.2401C16.487 6.00495 16.0927 5.9 15.63 5.9H14.8945C14.8393 5.9 14.7945 5.94477 14.7945 6V6.89892C14.7945 6.95415 14.8393 6.99892 14.8945 6.99892H15.3849C15.5689 6.99892 15.7025 7.05355 15.7912 7.15037C15.8812 7.24851 15.9386 7.40473 15.9386 7.63441C15.9386 7.86814 15.8797 8.18447 15.8177 8.5149L15.8163 8.52285C15.7561 8.84375 15.6935 9.17777 15.6935 9.43226C15.6935 9.65862 15.7621 9.84536 15.8908 9.99739C15.9648 10.0849 16.0569 10.1588 16.1632 10.2221C16.0355 10.2728 15.9334 10.3448 15.8572 10.4439C15.7352 10.6025 15.6935 10.8131 15.6935 11.0667C15.6935 11.3212 15.7561 11.6552 15.8163 11.9761L15.8177 11.984C15.8797 12.3145 15.9386 12.6308 15.9386 12.8645C15.9386 13.0942 15.8812 13.2504 15.7912 13.3486C15.7025 13.4454 15.5689 13.5 15.3849 13.5H14.8945C14.8393 13.5 14.7945 13.5448 14.7945 13.6V14.4172C14.7945 14.4724 14.8393 14.5172 14.8945 14.5172H15.63C16.0883 14.5172 16.4835 14.4345 16.7651 14.2093C17.0513 13.9803 17.201 13.6199 17.201 13.1097C17.201 12.7726 17.138 12.3965 17.0768 12.0601C17.0696 12.0207 17.0625 11.9819 17.0555 11.9439C17.0017 11.6505 16.9558 11.4006 16.9558 11.2301C16.9558 11.0357 16.9953 10.9256 17.0593 10.8616C17.1233 10.7976 17.2334 10.7581 17.4279 10.7581H17.9999Z"
      fill="white"
      stroke="white"
      strokeWidth="0.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Brackets.propTypes = {
  size: PropTypes.number,
};

Brackets.defaultProps = {
  size: 20,
};

export default Brackets;
