import fromPairs from 'lodash/fromPairs';
import entries from 'lodash/entries';
import isObject from 'lodash/isObject';
import omit from 'lodash/omit';

const generateFormData = (submissionId, disputeId, values, filteredInputIds) => {
  const formData = fromPairs(entries(values)?.filter((item) => !isObject(item[1])));

  const attachmentDetails = entries(values)?.reduce((acc, current) => {
    if (isObject(current[1])) {
      const attachment = omit(current[1], ['id', '__typename', 'accessUrl', 'submissionId']);

      return [...acc, attachment];
    }

    return acc;
  }, []);

  const filterAttachmentDetails = attachmentDetails.filter((attachment) =>
    filteredInputIds.includes(attachment.evidenceName)
  );

  const submissionData = {
    ...(submissionId && { id: submissionId }),
    disputeId,
    formData,
    attachmentDetails: filterAttachmentDetails,
  };

  return submissionData;
};

const generateForm = (form, selected, fileTypes) => {
  if (!selected) return form;

  if (form && fileTypes) {
    const filteredFileTyeps = fileTypes.filter((item) => item.fileTypeCategory === selected);
    const typeCodes = filteredFileTyeps.map((item) => item.fileTypeCode);
    const filteredForm = form.filter((field) => typeCodes.includes(field.id));

    return filteredForm;
  }

  return null;
};

export { generateFormData, generateForm };
