export const STORAGE_KEYS = {
  token: 'token',
  refreshToken: 'refreshToken',
  email: 'email',
  configurationState: 'configurationState',
  confirmDetails: 'confirmDetails',
  barcodeUri: 'barcodeUri',
  recoveryPass: 'recoveryPass',
  skipSlack: 'skipSlack',
  getMeData: 'getMeData',
  isConfigured: 'isConfigured',
  noCodeEditorHelper: 'noCodeEditorHelper',
};
