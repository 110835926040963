import * as Yup from 'yup';
import buildValidator from '../../../utils/validators/buildValidator';
import { signUpPaymentDetailsNames } from './signUpPaymentDetailsSchema';

const arrayValidationSchema = (requiredMessage, minMessage) =>
  Yup.array()
    .of(Yup.string())
    .min(1, minMessage)
    .required(requiredMessage);

const signUpPaymentDetailsValidator = Yup.object().shape({
  [signUpPaymentDetailsNames.paymentModel]: arrayValidationSchema(
    'Payment model is required',
    'Payment model is required'
  ),
  [signUpPaymentDetailsNames.acceptedTC]: Yup.bool()
    .oneOf([true], "You need to accept WhenThen's Terms and Conditions")
    .required(),
});

export default buildValidator(signUpPaymentDetailsValidator);
