import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { StyledP12 } from './styled';
import LogItem from './LogItem';

const FlowTestLogs = ({ logs }) => {
  return logs && logs.length ? (
    <>
      <StyledP12 color="#545A61">Output</StyledP12>
      <Box mt="20px">
        {logs?.map((log) => (
          <LogItem key={log?.id} message={log?.message} ts={log?.ts} />
        ))}
      </Box>
    </>
  ) : null;
};

FlowTestLogs.propTypes = {
  logs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      level: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      stepId: PropTypes.string.isRequired,
      ts: PropTypes.string.isRequired,
    }).isRequired
  ),
};

FlowTestLogs.defaultProps = {
  logs: [],
};

export default FlowTestLogs;
