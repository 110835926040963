import styled from 'styled-components';
import THEME from '../../../../constants/theme';

const StyledActionItem = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin-bottom: 4px;
`;

const Content = styled.div`
  padding: 16px;
  display: flex;
`;

const TimeStamp = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: ${THEME.greyColors.grey500};
  margin: 0;
`;

const ActionsButtons = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 10px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  border-right: ${({ isSingleAction }) => (isSingleAction ? 'none' : `1px solid ${THEME.greyColors.grey25}`)};

  &:nth-child(2) {
    border-right: none;
  }
`;

export { StyledActionItem, TimeStamp, Content, ActionsButtons, ButtonContainer };
