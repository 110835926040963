import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Skeleton from '@mui/lab/Skeleton';
import { L16B } from '../../../../components/atoms/Typography/L16B';
import { L14 } from '../../../../components/atoms/Typography/L14';
import { TitleContainer, StyledSection, MainContainer } from '../../styled';
import StyledCopyToClipboardWithCopyIcon from '../../../../components/StyledCopyToClipboardWithCopyIcon';
import { GET_TEAM } from '../../../../utils/queries/workspaces/workspacesQueries';

const TITLE = 'Team Id.';
const MESSAGE = 'Copied team id to clipboard.';

const TeamId = () => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(GET_TEAM);

  const teamId = data?.team?.id;

  return (
    <StyledSection>
      <TitleContainer>
        <L16B>{t('Current Team Id')}</L16B>
      </TitleContainer>

      <MainContainer>
        {loading ? (
          <Skeleton height="24px" animation="wave" />
        ) : (
          <StyledCopyToClipboardWithCopyIcon text={teamId} toastMessage={MESSAGE} toastTitle={TITLE}>
            <L14 noHover>{teamId}</L14>
          </StyledCopyToClipboardWithCopyIcon>
        )}
      </MainContainer>
    </StyledSection>
  );
};

export default TeamId;
