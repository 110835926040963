import React from 'react';

const Retry = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 4.5C10.379 4.50062 8.80183 5.02639 7.50475 5.99856C6.20766 6.97072 5.26045 8.33696 4.80498 9.89262C4.34952 11.4483 4.41031 13.1096 4.97827 14.6278C5.54622 16.1461 6.59076 17.4394 7.95543 18.3142C9.3201 19.1889 10.9315 19.598 12.5481 19.4801C14.1648 19.3623 15.6998 18.7238 16.9232 17.6604C18.1465 16.597 18.9925 15.1658 19.3342 13.5813C19.676 11.9968 19.4952 10.3442 18.8189 8.871C18.7453 8.69189 18.7438 8.49126 18.8148 8.31108C18.8858 8.13091 19.0237 7.98516 19.1996 7.90435C19.3756 7.82353 19.576 7.81391 19.7589 7.8775C19.9418 7.94109 20.093 8.07295 20.1809 8.2455C20.9924 10.0134 21.2093 11.9966 20.799 13.8981C20.3888 15.7996 19.3735 17.5169 17.9053 18.793C16.4371 20.069 14.595 20.835 12.6549 20.9762C10.7148 21.1175 8.78117 20.6263 7.14364 19.5764C5.50611 18.5264 4.25283 16.9742 3.57153 15.1522C2.89023 13.3301 2.81759 11.3364 3.36449 9.46967C3.91138 7.6029 5.04838 5.96357 6.60516 4.79723C8.16194 3.63089 10.0547 3.00033 11.9999 3V4.5Z"
      fill="black"
    />
    <path
      d="M12 6.69889V0.800885C12 0.72963 12.0204 0.659857 12.0586 0.599737C12.0969 0.539617 12.1514 0.491638 12.216 0.461419C12.2805 0.4312 12.3523 0.419992 12.423 0.429106C12.4936 0.438221 12.5602 0.467282 12.615 0.512885L16.155 3.46188C16.335 3.61189 16.335 3.88788 16.155 4.03788L12.615 6.98689C12.5602 7.03249 12.4936 7.06155 12.423 7.07066C12.3523 7.07978 12.2805 7.06857 12.216 7.03835C12.1514 7.00813 12.0969 6.96015 12.0586 6.90003C12.0204 6.83991 12 6.77014 12 6.69889Z"
      fill="black"
    />
  </svg>
);

export default Retry;
