import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import THEME from '../../../constants/theme';
import { BORDER_COLOR } from '../../FlowDetailsPage/constant';

const AutomationTemplatesLine = styled.div`
  position: relative;
`;

const AutomationTemplatesWrapWindow = styled.div`
  position: relative;
  margin-bottom: 16px;
`;

const AutomationTemplatesWrap = styled.div`
  display: flex;
  margin-left: ${({ position }) => (position > 0 ? '76px' : 0)};
  width: 100%;
  max-width: 444px;
  transition: 0.3s;
  transform: ${({ position }) => `translateX(${-position}00%)`};
`;

const Bullet = styled(Box)`
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;
  background: ${THEME.primaryColors.white};
  opacity: ${({ $active }) => ($active ? 1 : 0.4)};
  cursor: pointer;
`;

const Caption = styled(Box)`
  display: inline-block;
  background: ${THEME.secondaryColors.navyBlue};
  padding: 8px 16px 0 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  max-width: 95%;
`;

const TemplateWrapper = styled.div`
  width: calc(100% - 8px);
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  flex-shrink: 0;
`;

const TemplateBorder = styled.div`
  width: 100%;
  display: flex;
  flex-shrink: 0;
  padding: 8px;
  background: ${THEME.secondaryColors.navyBlue};
  border-radius: 0 8px 8px 8px;
  height: 292px;
`;

const AutomationTemplate = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px;
  border: 1px solid ${BORDER_COLOR};
  border-radius: 8px;
  background: ${THEME.primaryColors.white};

  h3 {
    font-size: 20px;
    line-height: 125%;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const Categories = styled.div`
  position: relative;
  font-size: 10px;
  color: ${THEME.greyColors.grey500};
  margin-bottom: 8px;
  letter-spacing: 0.08em;
`;

const Icons = styled.div`
  position: relative;
  margin-bottom: 16px;
  display: flex;
`;

const IconWrapper = styled(Box)`
  width: 44px;
  height: 44px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid ${BORDER_COLOR};
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: ${THEME.primaryColors.white};
  position: relative;
  &:not(:first-child) {
    margin-left: -12px;
  }
`;

const Buttons = styled.div`
  position: relative;
  display: flex;

  > div {
    &:first-child {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`;

const TemplateButtonSwipe = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background: transparent;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  transition: 0.25s;
  > * {
    transition: 0.25s;
  }
  ${({ disabled }) =>
    disabled &&
    `
      pointer-events: none;
      > * {
        opacity: 0.5;
      }
    `};
`;

const StyledLink = styled(Link)`
  > p {
    &::selection {
      background: transparent;
      color: ${THEME.primaryColors.blue};
    }
  }
  svg {
    vertical-align: text-bottom;
    margin-left: 6px;
  }
`;

const DocsLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${THEME.primaryColors.white};
  border-radius: 8px;
  padding: 6px 16px;
  line-height: 1.75;
  margin-left: 8px;
  font-weight: 600;
  color: ${THEME.primaryColors.black};
  &:hover {
    background: ${THEME.greyColors.grey24};
    color: ${THEME.primaryColors.black};
  }
`;

export {
  AutomationTemplatesLine,
  AutomationTemplatesWrapWindow,
  AutomationTemplatesWrap,
  AutomationTemplate,
  Categories,
  Icons,
  Buttons,
  TemplateButtonSwipe,
  StyledLink,
  TemplateWrapper,
  TemplateBorder,
  Caption,
  Bullet,
  DocsLink,
  IconWrapper,
};
