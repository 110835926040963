import React from 'react';
import PropTypes from 'prop-types';
import { useCopyToClipboard } from 'react-use';
import { NotificationManager } from 'react-notifications';
import capitalize from '@mui/material/utils/capitalize';
import { useNotificationManager } from '../../../../../hooks/useNotificationManager';
import CopyIcon from '../../../../../assets/icons/Copy';
import THEME from '../../../../../constants/theme';
import { StyledCopyComponent } from './styled';
import { MAX_STRING_LENGTH } from './constant';
import ToastCustomContainer from '../../../../../components/ToastCustomContainer';
import { TOAST_TIMEOUT } from '../../../../../constants/toasts';

const CopyToClipboardComponent = ({ value, label }) => {
  const [{ error }, copyToClipboard] = useCopyToClipboard();
  useNotificationManager('error', error?.message, `${label ?? 'Copy text'}`);

  const handleCopy = () => {
    copyToClipboard(value);

    NotificationManager.success(
      <ToastCustomContainer
        message={capitalize(`Successfully copied ${label ?? 'text'} to clipboard.`)}
        title={`${label ?? 'Copy text'}.`}
      />,
      null,
      TOAST_TIMEOUT
    );
  };

  const truncatedValue = `${value.substring(0, MAX_STRING_LENGTH)}...`;

  return (
    <StyledCopyComponent onClick={handleCopy}>
      {truncatedValue} <CopyIcon fill={THEME.primaryColors.black} />
    </StyledCopyComponent>
  );
};

CopyToClipboardComponent.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default CopyToClipboardComponent;
