import isEmpty from 'lodash/isEmpty';

import { mockedPaymentMethod } from '../mockedData';
import { PAYMENT_DESCRIBED_KEYS } from '../paymentDescribedDataKeys';

import { convertPaymentObjectToArray } from './constant';
import { PAYMENT_DETAILS_DATA_LABEL_MAP } from '../paymentDescribedDataLabelMap';

const getPaymentMethodSectionData = ({ isIntent, payment }) => {
  if (isIntent || isEmpty(payment?.paymentMethodDetails)) return mockedPaymentMethod;

  const data = {
    value: convertPaymentObjectToArray(payment.paymentMethodDetails),
  };

  const paymentVaultedCardObj = {
    label: PAYMENT_DETAILS_DATA_LABEL_MAP.vaultedCard,
    value: payment?.vaultedCard || '',
    key: PAYMENT_DESCRIBED_KEYS.vaultedCard,
  };

  return {
    ...data,
    value: [...(data.value || []), paymentVaultedCardObj],
  };
};

export { getPaymentMethodSectionData };
