import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';

import OnboardingLayout from '../../components/layouts/OnboardingLayout';
import OnboardingLayoutBox from '../../components/layouts/OnboardingLayoutBox';
import SignUpPaymentProcessorsForm from '../../components/forms/SignUpPaymentProcessorsForm';
import { useHandleRegistrationFlowRedirect, useRegistrationStorage } from '../../hooks/registration';
// import StepIndicatorOnboarding from '../../components/common/StepIndicatorOnboarding/StepIndicatorOnboarding';

const Fallback = () => (
  <Box display="flex" justifyContent="center">
    <CircularProgress size={24} />
  </Box>
);

const SignUpPaymentProcessors = () => {
  const { t } = useTranslation();
  const [registrationSession] = useRegistrationStorage();
  const acceptingPayments = registrationSession?.COMPANY_DETAILS?.acceptingPayments === 'YES';
  useHandleRegistrationFlowRedirect();

  return (
    <OnboardingLayout theme="dark">
      <OnboardingLayoutBox theme="dark">
        <Box mb="40px">
          {/* <StepIndicatorOnboarding currentStep={acceptingPayments ? 4 : 5} stepsCount={9} /> */}
          <h1>
            {t(
              acceptingPayments
                ? 'Who is your current payment processor(s)?'
                : 'Which payment processor(s) have you selected?'
            )}
          </h1>
        </Box>
        <React.Suspense fallback={<Fallback />}>
          <SignUpPaymentProcessorsForm />
        </React.Suspense>
      </OnboardingLayoutBox>
    </OnboardingLayout>
  );
};

export default SignUpPaymentProcessors;
