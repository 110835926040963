import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import isEmpty from 'lodash/isEmpty';
import Grid from '@mui/material/Grid';
import { bool, string, func } from 'prop-types';
import { isDefined } from '../../../../../utils/helpers';
import RecommendedItem from '../../../../FlowDetailsPage/Automation/RecommendedAutomations/RecommendedItem';
import LoadingState from '../../../../FlowDetailsPage/Automation/RecommendedAutomations/LoadingState';
import { useInfiniteScroll } from './useInfiniteScroll';
import RecommendedOverviewDialog from '../../../../FlowDetailsPage/Automation/RecommendedAutomations/RecommendedOverviewDialog';
import useSearch from '../../../../../hooks/useSearch';
import { PAYMENT_FLOW_KEY, TABS } from '../constant';
import PremiumBlock from '../../../Overview/PremiumBlock';
import { StyledContentAreaWrapper, StyledHeader, TabContainer, Tab } from './styled';
import useOverviewDialogData from '../../../useOverviewDialogData';
import { usePaymentFlowContext } from '../../../../FlowDetailsPage/paymentFlowContext';
import { P14 } from '../../../../../components/atoms';
import useFilterRecipes from './useFilterRecipes';
import EmptyState from './EmptyState';

const ContentArea = ({ inModal, activeTab, setActiveTab }) => {
  const { loading: paymentFlowLoading } = usePaymentFlowContext();
  const { loaderRefFn, loading: templatesLoading, templates } = useInfiniteScroll();
  const [searchParams, setSearchParams] = useSearch();
  const {
    overviewData,
    onSetOverviewData,
    onCloseDialog,
    actionButtonText,
    isAddToExistingAutomationButton,
    isShowAddYourRecipeContent,
    setIsShowAddYourRecipeContent,
    onlyOneInstructAutomationId,
  } = useOverviewDialogData();

  const loading = paymentFlowLoading || templatesLoading;

  const popularRecipes = templates.filter((recipe) => recipe.isPopular);

  const filteredRecipes = useFilterRecipes(templates);
  const recipesToShow = activeTab === TABS[0].label ? filteredRecipes : popularRecipes;
  const isFilterActive = !isEmpty(searchParams.filters);

  useEffect(() => {
    if (isDefined(searchParams.templateId)) {
      const templateObject = templates.find((template) => template?.template?.id === searchParams.templateId);

      if (isDefined(templateObject)) {
        onSetOverviewData({
          template: templateObject,
        });
        setSearchParams({});
      }
    }
  }, [searchParams.templateId, templates, setSearchParams, onSetOverviewData]);

  useEffect(() => {
    if (isFilterActive) {
      setActiveTab(TABS[0].label);
    }
  }, [searchParams, isFilterActive, setActiveTab]);

  const handleTab = (e) => {
    setActiveTab(e.target.dataset.label);
  };

  return (
    <StyledContentAreaWrapper $inModal={inModal}>
      <StyledHeader>
        <TabContainer>
          {TABS.map(({ label }) => (
            <Tab key={label} activeTab={activeTab === label} aria-label="tab" data-label={label} onClick={handleTab}>
              {label}
            </Tab>
          ))}
        </TabContainer>

        <P14 opacity="0.5">
          {recipesToShow.length} recipe{recipesToShow.length === 1 ? '' : 's'} found{' '}
        </P14>
      </StyledHeader>

      <Grid container spacing={2}>
        {loading && <LoadingState />}
        {!loading && isEmpty(recipesToShow) ? (
          <EmptyState />
        ) : (
          <>
            {recipesToShow?.map((template) => (
              <Grid key={template?.template?.id} item md={12} lg={6}>
                <RecommendedItem template={template} setOverviewData={onSetOverviewData} />
              </Grid>
            ))}

            {/* Helper ref for useInfiniteScroll */}
            <Box visibility="hidden" opacity="0" mt="-100px" height="60px" width="100%" ref={loaderRefFn}>
              &nbsp;
            </Box>
          </>
        )}
        {!loading && searchParams.automationCategories === PAYMENT_FLOW_KEY && (
          <PremiumBlock inModal={inModal} directory />
        )}
      </Grid>

      {overviewData ? (
        <RecommendedOverviewDialog
          isOpen={Boolean(overviewData)}
          closeDialog={onCloseDialog}
          overviewData={overviewData}
          actionButtonText={actionButtonText}
          isAddToExistingAutomationButton={isAddToExistingAutomationButton}
          isShowAddYourRecipeContent={isShowAddYourRecipeContent}
          setIsShowAddYourRecipeContent={setIsShowAddYourRecipeContent}
          onlyOneInstructAutomationId={onlyOneInstructAutomationId}
        />
      ) : null}
    </StyledContentAreaWrapper>
  );
};

ContentArea.propTypes = {
  inModal: bool,
  activeTab: string.isRequired,
  setActiveTab: func.isRequired,
};

ContentArea.defaultProps = {
  inModal: false,
};

export default ContentArea;
