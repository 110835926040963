import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { StyledButton, SwitchEnvBox } from './styled';
import { APP_ENVIRONMENT, PROD_URL, SANDBOX_URL } from '../../constants/api';
import { useLoginStatus } from '../../hooks/storageHooks';
import { useGetMe } from '../../hooks/useGetMe';
import SwitchEnvModal from './SwitchEnvModal';
import LockOutlined from '../../assets/icons/LockOutlined';
import StyledTooltip from '../styled/StyledTooltip';
import { useGlobalContext } from '../../containers/App/context';
import { ROLES } from '../../permissions/constants';
import { PEOPLE_PAGE } from '../../utils/queries/billing';

const SwitchEnv = () => {
  const { t } = useTranslation();
  const { data } = useQuery(PEOPLE_PAGE);
  const { isLogin } = useLoginStatus();
  const { isOwner } = useGetMe(isLogin);
  const {
    isOpenSwitchEnvModal,
    setIsOpenSwitchEnvModal,
    canMigrateCustomer,
    canMigrateCustomerLoading,
  } = useGlobalContext();
  const isProd = APP_ENVIRONMENT === 'production';
  const canUserMigrate = canMigrateCustomer?.canUserMigrate;
  const isAlreadyMigrated = canMigrateCustomer?.isAlreadyMigrated;
  const ownersEmailsList = data?.listUsers?.reduce(
    (acc, user) => (user.systemUserRole === ROLES.owner ? [...acc, user.email] : acc),
    []
  );
  const ownersEmailsStr = ownersEmailsList?.length && ownersEmailsList?.join(', ');
  const helpText = isAlreadyMigrated
    ? ''
    : t(
        `Ask your account owner to login to sandbox and click to button to setup your company's account for production and invite you. In case you are unsure here are their emails ${ownersEmailsStr}`
      );

  const handleSwitchSandbox = () => {
    window.open(SANDBOX_URL, '_blank');
  };

  const handleOwnerSwitchProd = () => {
    if (canUserMigrate) {
      setIsOpenSwitchEnvModal(true);
      return;
    }
    if (isAlreadyMigrated) {
      window.open(PROD_URL, '_blank');
    }
  };

  const handleNonOwnerSwitchProd = () => {
    window.open(PROD_URL, '_blank');
  };

  return (
    <>
      <SwitchEnvBox>
        <StyledButton
          type="button"
          color={isProd ? 'secondary' : 'primary'}
          variant="contained"
          disabled={!isProd}
          onClick={handleSwitchSandbox}
        >
          {t('Sandbox')}
        </StyledButton>
        {isOwner && canUserMigrate ? (
          <StyledButton
            ml="4px"
            type="button"
            color={isProd ? 'primary' : 'secondary'}
            variant="contained"
            disabled={canMigrateCustomerLoading || isProd}
            onClick={handleOwnerSwitchProd}
          >
            {t('Production')}
          </StyledButton>
        ) : (
          <StyledTooltip title={helpText} placement="top" width="auto" maxWidth={240}>
            <StyledButton
              ml="4px"
              type="button"
              color={isProd ? 'primary' : 'secondary'}
              variant="contained"
              endIcon={isAlreadyMigrated ? '' : <LockOutlined />}
              disabled={!isAlreadyMigrated}
              onClick={isAlreadyMigrated ? handleNonOwnerSwitchProd : null}
            >
              {t('Production')}
            </StyledButton>
          </StyledTooltip>
        )}
      </SwitchEnvBox>
      {isOpenSwitchEnvModal && <SwitchEnvModal isOpen={isOpenSwitchEnvModal} setIsOpen={setIsOpenSwitchEnvModal} />}
    </>
  );
};

export default SwitchEnv;
