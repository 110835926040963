import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_CONNECTION_DETAILS } from '../../../utils/queries/connections/connectionsQueries';
import { useGlobalContext } from '../../../containers/App/context';
import { globalLoadingConst } from '../../../constants/globalLoadingConsts';

const useGetConnectionDetails = (id) => {
  const { data, loading, error, refetch } = useQuery(GET_CONNECTION_DETAILS, {
    variables: {
      id,
    },
  });
  const { setGlobalLoading } = useGlobalContext();

  useEffect(() => {
    if (loading) {
      setGlobalLoading(globalLoadingConst.connectionDetail, true);
    } else {
      setGlobalLoading(globalLoadingConst.connectionDetail, false);
    }
  }, [setGlobalLoading, loading]);

  return {
    connection: data?.getConnection || {},
    loading,
    error,
    refetch,
  };
};

export { useGetConnectionDetails };
