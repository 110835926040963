import * as Yup from 'yup';
import { formNames } from './schema';

const arrayValidationSchema = (requiredMessage, minMessage) =>
  Yup.array()
    .of(Yup.string())
    .min(1, minMessage)
    .required(requiredMessage);

const stringValidationSchema = (requiredMessage) =>
  Yup.string()
    .trim()
    .required(requiredMessage);

export const validator = (showOtherInput) => {
  return Yup.object().shape({
    [formNames.paymentProcessors]: arrayValidationSchema(
      'Payment Processor is required',
      'Payment Processor is required'
    ),
    ...(showOtherInput
      ? { [formNames.paymentProcessorsOther]: stringValidationSchema('Please specify payment processor(s)') }
      : {}),
  });
};
