const BUTTON_TYPES_MAP = {
  SOLID: 'SOLID',
  SOLID_ROUND: 'SOLID_ROUND',
  THIN: 'THIN',
  THIN_ROUND: 'THIN_ROUND',
};

const checkIsSolidButtonType = (type) => type === BUTTON_TYPES_MAP.SOLID || type === BUTTON_TYPES_MAP.SOLID_ROUND;
const checkIsThinButtonType = (type) => type === BUTTON_TYPES_MAP.THIN || type === BUTTON_TYPES_MAP.THIN_ROUND;
const checkIsRoundButtonType = (type) => type === BUTTON_TYPES_MAP.SOLID_ROUND || type === BUTTON_TYPES_MAP.THIN_ROUND;

export { BUTTON_TYPES_MAP, checkIsSolidButtonType, checkIsThinButtonType, checkIsRoundButtonType };
