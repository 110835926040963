import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Box from '@mui/material/Box';
import { StyledFormControlLabel } from './styled';
import { CircleImage, Radio } from '../../../../../atoms';
import { selectTypes } from './types';

const RadioButtons = ({ name, options = [], value, onChange, selectType }) => {
  return (
    <FormControl variant="standard" component="fieldset" fullWidth>
      <RadioGroup aria-label={name} name={name} value={value} onChange={onChange}>
        <Box padding="4px" display="flex" flexDirection="column">
          {options.map(({ value: val, title, company }) => (
            <StyledFormControlLabel
              key={val}
              value={val}
              control={<Radio color="primary" size="small" />}
              label={
                <>
                  {selectType === selectTypes.connections && (
                    <Box display="flex" alignItems="center">
                      {company && <CircleImage src={company?.logo} alt={title} size="24" />}
                      <Box ml={1}> {title}</Box>
                    </Box>
                  )}
                  {selectType === selectTypes.people && (
                    <Box display="flex" alignItems="center">
                      <CircleImage src={company?.logo} alt={title} size="24" />
                      <Box ml={1}> {title}</Box>
                    </Box>
                  )}
                  {!selectType && title}
                </>
              }
              name={name}
              selected={val === value}
              $selectType={selectType}
            />
          ))}
        </Box>
      </RadioGroup>
    </FormControl>
  );
};

RadioButtons.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selectType: PropTypes.oneOf([selectTypes.connections, selectTypes.people, selectTypes.none]),
};

RadioButtons.defaultProps = {
  value: '',
  selectType: selectTypes.none,
};

export default RadioButtons;
