export const GRID_SIZE_MATRIX = {
  amountStat: {
    small: {
      xs: 12,
      sm: 6,
    },
    medium: {
      xs: 12,
      sm: 6,
    },
    large: {
      xs: 12,
      sm: 6,
    },
  },
  default: {
    small: {
      xs: 12,
      sm: 6,
    },
    medium: {
      xs: 12,
      sm: 6,
      md: 3,
    },
    large: {
      xs: 12,
      sm: 6,
      md: 3,
    },
  },
};

export const getGridSize = ({ isSmall, isMobile, isLarge }) => {
  switch (true) {
    case isLarge:
      return 'large';
    case isMobile:
      return 'medium';
    case isSmall:
      return 'small';
    default:
      return 'medium';
  }
};

export const getPercentage = (percentage) => {
  if (typeof percentage !== 'number') {
    return { percentage: '0%' };
  }

  if (Math.sign(percentage) === -1) {
    return {
      percentage: `${percentage * -1}%`, // - 2 * -1 = 2 -- we dont want to show the negative sign
      negativePercentage: true,
    };
  }

  return {
    percentage: `${percentage}%`,
  };
};
