import React from 'react';
import { string, number, oneOfType } from 'prop-types';

const Trash3 = ({ size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.33331 4.00001C1.33331 3.63182 1.63179 3.33334 1.99998 3.33334H14C14.3682 3.33334 14.6666 3.63182 14.6666 4.00001C14.6666 4.3682 14.3682 4.66668 14 4.66668H1.99998C1.63179 4.66668 1.33331 4.3682 1.33331 4.00001Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66669 1.99999C6.48988 1.99999 6.32031 2.07023 6.19528 2.19525C6.07026 2.32028 6.00002 2.48985 6.00002 2.66666V3.33332H10V2.66666C10 2.48985 9.92978 2.32028 9.80476 2.19525C9.67973 2.07023 9.51016 1.99999 9.33335 1.99999H6.66669ZM11.3334 3.33332V2.66666C11.3334 2.13622 11.1226 1.62752 10.7476 1.25244C10.3725 0.87737 9.86379 0.666656 9.33335 0.666656H6.66669C6.13625 0.666656 5.62755 0.87737 5.25247 1.25244C4.8774 1.62752 4.66669 2.13622 4.66669 2.66666V3.33332H3.33335C2.96516 3.33332 2.66669 3.6318 2.66669 3.99999V13.3333C2.66669 13.8638 2.8774 14.3725 3.25247 14.7475C3.62755 15.1226 4.13625 15.3333 4.66669 15.3333H11.3334C11.8638 15.3333 12.3725 15.1226 12.7476 14.7475C13.1226 14.3725 13.3334 13.8638 13.3334 13.3333V3.99999C13.3334 3.6318 13.0349 3.33332 12.6667 3.33332H11.3334ZM4.00002 4.66666V13.3333C4.00002 13.5101 4.07026 13.6797 4.19528 13.8047C4.32031 13.9298 4.48988 14 4.66669 14H11.3334C11.5102 14 11.6797 13.9298 11.8048 13.8047C11.9298 13.6797 12 13.5101 12 13.3333V4.66666H4.00002Z"
      fill="black"
    />
  </svg>
);

Trash3.propTypes = {
  size: oneOfType([string, number]),
};

Trash3.defaultProps = {
  size: 16,
};

export default Trash3;
