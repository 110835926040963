import styled from 'styled-components';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ButtonRounded } from '../atoms';
import THEME from '../../constants/theme';

export const SwitchEnvBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 16px;
  left: 50%;
  z-index: 300;
  height: 38px;
  border-radius: 8px;
  padding: 4px;
  background-color: ${THEME.primaryColors.orange};
`;

export const StyledButton = styled(ButtonRounded)`
  &.MuiButtonBase-root {
    height: 30px;
    font-size: 10px;
    font-weight: 600;
    box-shadow: none;
    border-radius: 6px;
    color: ${THEME.primaryColors.white};
    padding: 8px 16px 8px 16px;
    & .MuiButton-label {
      text-transform: uppercase !important;
    }
  }
  &.MuiButton-containedPrimary {
    color: ${THEME.primaryColors.white};
    background-color: ${THEME.secondaryColors.orangeSecondary};
    &.Mui-disabled {
      background-color: ${THEME.secondaryColors.orangeSecondary};
    }
    &:hover {
      background-color: ${THEME.secondaryColors.orangeSecondary};
    }
  }
  &.MuiButton-containedSecondary {
    color: ${THEME.secondaryColors.orangeLight};
    background-color: ${THEME.primaryColors.orange};
    &.Mui-disabled {
      color: ${THEME.secondaryColors.orangeLight};
      background-color: ${THEME.primaryColors.orange};
    }
    &:hover {
      background-color: ${THEME.secondaryColors.orangeSecondary};
    }
  }
`;

export const StyledDescriptionContentBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 512px;
  margin: 0 auto;
`;

export const StyledAutomationsContentBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 50%;
  border-left: 1px solid ${THEME.greyColors.grey5};
  padding: 8px;
`;

export const StyledAutomationBox = styled(Box)`
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: ${({ $checked }) => ($checked ? THEME.primaryColors.primaryLight : THEME.primaryColors.white)};
  &:hover {
    background-color: ${({ $checked }) => ($checked ? THEME.primaryColors.primaryLight : THEME.greyColors.grey14)};
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    display: flex;
    align-items: center;
    padding: 24px;
    margin: 0;
    & .MuiFormControlLabel-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
`;
