import ShieldCheck from '../../assets/icons/PaymentNeeds/ShieldCheck';
import Percent from '../../assets/icons/PaymentNeeds/Percent';
import Tokenization from '../../assets/icons/PaymentNeeds/Tokenization';
import PaymentProvider from '../../assets/icons/PaymentNeeds/PaymentProvider';
import Retry from '../../assets/icons/PaymentNeeds/Retry';
import Routing from '../../assets/icons/PaymentNeeds/Routing';
import Rates from '../../assets/icons/PaymentNeeds/Rates';
import Expand from '../../assets/icons/PaymentNeeds/Expand';
import Globe from '../../assets/icons/QuickStart/Globe';

const PAYMENT_NEED_VALUES = {
  TOKENIZATION: 'TOKENIZATION',
  PAYMENT_PROCESSOR_RETRY: 'PAYMENT_PROCESSOR_RETRY',
  MITIGATE_PROCESSOR_DOWNTIME: 'MITIGATE_PROCESSOR_DOWNTIME',
  REDUCE_PAYMENT_FEES: 'REDUCE_PAYMENT_FEES',
  INCREASE_AUTH_RATES: 'INCREASE_AUTH_RATES',
  DECREASE_FRAUD_AND_CHARGEBACKS: 'DECREASE_FRAUD_AND_CHARGEBACKS',
  EXPAND_INTO_NEW_MARKETS: 'EXPAND_INTO_NEW_MARKETS',
  CONNECT_NEW_PSP: 'CONNECT_NEW_PSP',
  PAYMENT_PROCESSOR_ROUTING: 'PAYMENT_PROCESSOR_ROUTING',
  AUTOMATE_MODERATE_REFUNDS: 'AUTOMATE_MODERATE_REFUNDS',
  NEEDS_HELP: 'NEEDS_HELP',
  ACCESS_GLOBAL_PROCESSORS: 'ACCESS_GLOBAL_PROCESSORS',
};

export const PAYMENT_NEEDS_LIST = [
  {
    value: PAYMENT_NEED_VALUES.TOKENIZATION,
    title: 'Tokenize',
    text: `Securely tokenize and store payments data using WhenThen’s PCI compliant token vault`,
    Icon: Tokenization,
  },
  {
    value: PAYMENT_NEED_VALUES.ACCESS_GLOBAL_PROCESSORS,
    title: 'Support multiple payment processors',
    text: `Through our single universal payments API access over 20 global payment processors and payment methods.`,
    Icon: Globe,
  },
  {
    value: PAYMENT_NEED_VALUES.PAYMENT_PROCESSOR_RETRY,
    title: 'Reduce Unnecessary Declines',
    text: `Reduce unnecessary payment declines and improve customer experience through smart retries of declined payments and real-time customer interaction.`,
    Icon: Retry,
  },
  {
    value: PAYMENT_NEED_VALUES.MITIGATE_PROCESSOR_DOWNTIME,
    title: 'Mitigate Processor Downtime',
    text: `Mitigate payment processor downtime through alerts and connect to new payment processors for redundancy/back-up.`,
    Icon: PaymentProvider,
  },
  {
    value: PAYMENT_NEED_VALUES.REDUCE_PAYMENT_FEES,
    title: 'Reduce Payment Fees',
    text: `Reduce payment fees by routing to local and lower cost payment processors, separating authorization and capture, we have sure-fire strategies to bring down your payment costs.`,
    Icon: Percent,
  },
  {
    value: PAYMENT_NEED_VALUES.INCREASE_AUTH_RATES,
    title: 'Increase Auth Rates',
    text: `Increase your authorization rates through smart retries, routing to local payment processors most likely to authorize the payment and recover declines in real-time.`,
    Icon: Rates,
  },
  {
    value: PAYMENT_NEED_VALUES.DECREASE_FRAUD_AND_CHARGEBACKS,
    title: 'Decrease Fraud',
    text: `Prevent fraud and unnecessary chargebacks by connecting 3rd party fraud providers, and easily experiment with rules. Define conditions where 3D Secure step-up is required.`,
    Icon: ShieldCheck,
  },
  // {
  //   value: PAYMENT_NEED_VALUES.PAYMENT_PROCESSOR_ROUTING,
  //   title: 'Payment Processor Routing',
  //   text: `Route your payment to alternative Payment Processors to increase authorization rates.`,
  //   Icon: Routing,
  // },
  {
    value: PAYMENT_NEED_VALUES.EXPAND_INTO_NEW_MARKETS,
    title: 'Expand into new international markets',
    text: `Successfully expand into new markets by routing foreign payments to local payment processors, optimizing SCA events, supporting local payment methods and localising the language of your checkout.`,
    Icon: Expand,
  },
  {
    value: PAYMENT_NEED_VALUES.AUTOMATE_MODERATE_REFUNDS,
    title: 'Automate or Moderate Refunds',
    text: `Simply your refund operations with automated flows that allow you to respond faster to customer requests and reduce manual work for your team.`,
    Icon: Routing,
  },
  // NOTE: Hide on Front-end for now
  // {
  //   value: PAYMENT_NEED_VALUES.NEEDS_HELP,
  //   title: 'Not sure yet?',
  //   text: `No problem, whenThen will assess your payment data and identify payment optimisation opportunities.`,
  //   Icon: Person,
  // },
];
