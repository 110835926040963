import { useMemo } from 'react';
import { useFlowPropertiesList } from './index';

const useFlatFlowProperties = (matchingTypes = [], skip) => {
  const result = useFlowPropertiesList(matchingTypes, 'no-cache', skip);
  const flatProperties = useMemo(() => {
    const set = new Set();

    return result?.getAvailableProperties?.items?.reduce((subAcc, property) => {
      const key = property?.key;

      if (!key || set.has(key)) {
        return subAcc;
      }

      const { label, type } = property;

      set.add(key);

      return [...subAcc, { value: key, title: label, propertyType: type }];
    }, []);
  }, [result?.getAvailableProperties?.items]);

  return { flatProperties, ...result };
};

export { useFlatFlowProperties };
