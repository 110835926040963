import ReactFlow, { ReactFlowProvider } from 'react-flow-renderer';
import React, { useCallback, useRef } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { string } from 'prop-types';
import { nodeTypesMap } from '../FlowEditor/utils/nodeTypesMap';
import { edgeTypes } from '../FlowEditor/utils/edgeTypes';
import { FlowEditorContextProvider } from '../FlowEditor/context';
import { CanvasContainer } from '../FlowEditor/styled';
import { FlowHeader } from '../FlowHeader';
import { CircularLoader } from '../atoms';
import { FlowTestDetailsDrawer } from './FlowTestDetailsDrawer';
import TestExamplesModal from './TestExamplesModal';
import { useFlowTestElements } from './hooks/useFlowTestElements';
import { useFlowTest } from './hooks/useFlowTest';
import FlowEmptyState from '../FlowEmptyState/FlowEmptyState';

const FlowTest = ({ flowId }) => {
  const reactFlowWrapper = useRef(null);
  const {
    context,
    nodes,
    edges,
    setSelectedNodeId,
    publishedData,
    isAutomationTestInProgress,
    testFlowInstance,
    currentTestStepId,
    examplesRequired,
    isUserDecisionRequired,
    isOpenTestExamplesModal,
    setIsOpenTestExamplesModal,
    onValidate,
    firstStepId,
    isDataReady,
    onTestExampleConfirm,
  } = useFlowTest({ flowId });

  const { reactFlowNodes, reactFlowEdges } = useFlowTestElements({
    nodes,
    edges,
    isAutomationTestInProgress,
    isUserDecisionRequired,
    testFlowInstance,
    currentTestStepId,
    firstStepId,
  });

  const handleClick = useCallback(
    (_, node) => {
      setSelectedNodeId(node.id);
    },
    [setSelectedNodeId]
  );

  if (!nodes) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        bgcolor="#f5f6f7"
      >
        {isDataReady ? <CircularProgress /> : <FlowEmptyState />}
      </Box>
    );
  }

  return (
    <Box width="100%" height="100%" bgcolor="#f5f6f7">
      <FlowEditorContextProvider value={context}>
        <ReactFlowProvider>
          <CanvasContainer ref={reactFlowWrapper}>
            <FlowHeader flowId={flowId} publishedData={publishedData} onValidate={onValidate} isTestMode />
            {!isDataReady && (
              <Box position="absolute" left="50%" top="45%">
                <CircularLoader />
              </Box>
            )}
            <ReactFlow
              defaultNodes={reactFlowNodes}
              defaultEdges={reactFlowEdges}
              nodeTypes={nodeTypesMap}
              edgeTypes={edgeTypes}
              nodesDraggable={false}
              nodesConnectable={false}
              elementsSelectable={false}
              minZoom={0.5}
              maxZoom={2}
              onNodeClick={handleClick}
            />
            <TestExamplesModal
              isOpen={examplesRequired && isOpenTestExamplesModal}
              onClose={() => setIsOpenTestExamplesModal(false)}
              onConfirm={onTestExampleConfirm}
            />
          </CanvasContainer>
        </ReactFlowProvider>
        {isAutomationTestInProgress && <FlowTestDetailsDrawer />}
      </FlowEditorContextProvider>
    </Box>
  );
};

FlowTest.propTypes = {
  flowId: string.isRequired,
};

export { FlowTest };
