import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { useMouseEventHandler } from '../../hooks/useMouseEventHandler';

const NodeBase = ({ id, children }) => {
  const { mouseEventHandler } = useMouseEventHandler();

  return (
    <Box onMouseEnter={() => mouseEventHandler(id)} onMouseLeave={() => mouseEventHandler(null)} display="flex">
      {children}
    </Box>
  );
};

NodeBase.propTypes = {
  id: PropTypes.string.isRequired,
};

export default NodeBase;
