import React from 'react';

const Googlepay = () => {
  return (
    <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="34" height="24" rx="4" fill="white" />
      <rect
        x="0.25"
        y="0.25"
        width="33.5"
        height="23.5"
        rx="3.75"
        stroke="black"
        strokeOpacity="0.15"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0578 15.5278V12.5932H17.5725C18.1931 12.5932 18.717 12.3852 19.144 11.975L19.2465 11.8711C20.0266 11.0219 19.9754 9.69896 19.144 8.91331C18.7284 8.49737 18.1589 8.27207 17.5725 8.28363H15.1411V15.5278H16.0578ZM16.0579 11.7035V9.17321H17.5956C17.9259 9.17321 18.2391 9.3003 18.4726 9.53137C18.9681 10.0166 18.9795 10.8254 18.5011 11.328C18.2676 11.5764 17.9373 11.715 17.5956 11.7035H16.0579ZM23.5228 10.9583C23.1299 10.5944 22.5947 10.4095 21.9171 10.4095C21.0459 10.4095 20.3911 10.733 19.9583 11.3742L20.7669 11.8884C21.063 11.4493 21.4672 11.2298 21.9797 11.2298C22.3043 11.2298 22.6174 11.3511 22.8623 11.5706C23.1014 11.7786 23.2381 12.079 23.2381 12.3967V12.6105C22.8851 12.4141 22.4409 12.3101 21.8943 12.3101C21.2566 12.3101 20.7441 12.4603 20.3626 12.7665C19.9811 13.0726 19.7875 13.477 19.7875 13.9912C19.7761 14.4591 19.9754 14.9039 20.3284 15.2043C20.6871 15.5278 21.1427 15.6896 21.6779 15.6896C22.31 15.6896 22.811 15.4065 23.1925 14.8404H23.2324V15.5278H24.1093V12.4718C24.1093 11.8306 23.9157 11.3222 23.5228 10.9583ZM21.0346 14.6151C20.8467 14.4764 20.7328 14.2511 20.7328 14.0085C20.7328 13.737 20.8581 13.5117 21.103 13.3326C21.3536 13.1535 21.6668 13.0611 22.037 13.0611C22.5495 13.0553 22.9482 13.1709 23.2329 13.4019C23.2329 13.7948 23.0791 14.1356 22.7773 14.4244C22.5039 14.7017 22.1338 14.8577 21.7465 14.8577C21.4902 14.8635 21.2397 14.7768 21.0346 14.6151ZM26.0795 17.7057L29.1429 10.5712H28.1464L26.7286 14.1298H26.7115L25.2595 10.5712H24.2631L26.2731 15.2101L25.1342 17.7057H26.0795Z"
        fill="#3C4043"
      />
      <path
        d="M12.8929 11.9579C12.8929 11.6749 12.8701 11.3918 12.8246 11.1145H8.95828V12.7147H11.1733C11.0822 13.2288 10.7861 13.691 10.3533 13.9798V15.0197H11.6744C12.4488 14.2976 12.8929 13.2288 12.8929 11.9579Z"
        fill="#4285F4"
      />
      <path
        d="M8.95848 16.0247C10.0631 16.0247 10.997 15.655 11.6746 15.0196L10.3535 13.9797C9.98341 14.2339 9.5108 14.3783 8.95848 14.3783C7.88799 14.3783 6.98263 13.6447 6.65807 12.6626H5.29718V13.7371C5.99186 15.1409 7.40969 16.0247 8.95848 16.0247Z"
        fill="#34A853"
      />
      <path
        d="M6.65819 12.6626C6.48734 12.1485 6.48734 11.5882 6.65819 11.0682V9.99951H5.29709C4.7105 11.1722 4.7105 12.5587 5.29709 13.7314L6.65819 12.6626Z"
        fill="#FBBC04"
      />
      <path
        d="M8.95848 9.35247C9.54497 9.34092 10.1087 9.56621 10.53 9.97637L11.703 8.78634C10.9571 8.08156 9.97772 7.69451 8.95848 7.70606C7.40969 7.70606 5.99186 8.5957 5.29718 9.99948L6.65807 11.074C6.98263 10.0861 7.88799 9.35247 8.95848 9.35247Z"
        fill="#EA4335"
      />
    </svg>
  );
};

export default Googlepay;
