import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { string } from 'prop-types';
import SubTitle from '../../SubTitle';
import Title from '../../Title';
import { useValidationMessage } from './hooks';
import { useNodeDataToSave } from './hooks/useNodeDataToSave';
import { StyledFormControl } from './styled';

const JsonInputField = ({ title }) => {
  const { t } = useTranslation();
  const validationPayloadMessage = useValidationMessage('payload');
  const [{ payload }, updateDataToSave] = useNodeDataToSave();

  const onChange = (type, { target }) => {
    updateDataToSave({ [type]: target.value });
  };

  const JSONFormatText = () => {
    let pretty;
    try {
      const obj = JSON.parse(payload);
      if (!isEmpty(obj)) {
        pretty = JSON.stringify(obj, undefined, 2);
      } else {
        pretty = payload;
      }
    } catch {
      pretty = payload;
    }
    return pretty;
  };

  return (
    <Box>
      <Box>
        <Title mt="40px">{t(title)}</Title>
        <SubTitle>
          {t(
            'Paste an example of the metadata you plan to send in the Intent or Authorize call to access your metadata as properties in the No-Code editor.'
          )}
        </SubTitle>
      </Box>
      <StyledFormControl error={Boolean(validationPayloadMessage)} $height="auto" fullWidth>
        <TextField
          variant="standard"
          type="textarea"
          rows={5}
          maxRows={15}
          value={JSONFormatText() ?? ''}
          onChange={(e) => onChange('payload', e)}
          multiline
        />
        <FormHelperText>{validationPayloadMessage}</FormHelperText>
      </StyledFormControl>
    </Box>
  );
};

JsonInputField.propTypes = {
  title: string.isRequired,
};

export default JsonInputField;
