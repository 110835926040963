import styled from 'styled-components';
import THEME from '../../../constants/theme';

const SwitcherWrapper = styled.div`
  h1 {
    color: ${THEME.primaryColors.primary};
    margin-bottom: 0;
  }

  svg {
    transition: all 0.1s ease-in-out;
    ${({ open }) => open && 'transform : rotate(180deg)'};
    margin-left: 8px;

    @media (min-width: ${THEME.breakPoints.desktop}px) {
      margin-top: 4px;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

export { SwitcherWrapper };
