import styled from 'styled-components';
import { P16, BorderLinearProgress } from '../../components/atoms';
import THEME from '../../constants/theme';

export const Title = styled.h1``;

export const Description = styled(P16)`
  margin: 20px 0 28px 0;
  color: ${THEME.greyColors.grey1};
`;

export const Progress = styled(BorderLinearProgress).attrs({
  height: 8,
})``;

export const ProgressBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #f4f4fe;
  transition: 4s;
  transform: scaleX(0);
  transform-origin: left;

  &.loading {
    transform: scaleX(1);
  }
`;
