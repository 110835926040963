import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import Skeleton from '@mui/lab/Skeleton';
import { StyledFormControl } from './styled';
import useSingleRecord from './hooks/useSingleRecord';
import SubTitle from '../../SubTitle';
import THEME from '../../../../../../constants/theme';
import Select, { NONE } from '../../../../../forms/_common/Select';
import useGetProjectionsFor from './hooks/useGetProjectionsFor';
import { useNodeDataToSave } from './hooks/useNodeDataToSave';
import DataSetAccordion from './DataSetAccordion';
import useGetOptionsForSelect from './hooks/useGetOptionsForSelect';

const DataSetField = () => {
  const { t } = useTranslation();

  const [{ dataSetVariable, dataSetProjection, filters }, updateDataToSave] = useNodeDataToSave();

  const selectedFilters = filters ? Object.keys(filters) : [];

  const { projectionsOptions, loading: projectionsOptionsLoading } = useGetProjectionsFor();

  const { options: schemaOptions, loading: schemaOptionsLoading } = useGetOptionsForSelect('getExampleSchemaForType');

  const checkIsSelected = (key) => {
    const filterKeysKey = selectedFilters.findIndex((filter) => filter === key);

    const filtersKey = selectedFilters[filterKeysKey];

    const hasKey = filterKeysKey > -1;

    const hasValue = filters && !isEmpty(filters[filtersKey === 'date' ? 'dateRange' : filtersKey]?.value);

    return hasKey && hasValue;
  };

  const onChange = ({ target: { value } }, name) => {
    updateDataToSave(
      name === 'dataSetVariable' ? { dataSetVariable: value, dataSetProjection: null } : { [name]: value }
    );
  };

  const { records, loading } = useSingleRecord();

  return loading ? (
    <Box borderRadius="8px">
      <Skeleton height="40px" />
    </Box>
  ) : (
    <>
      {records?.map((record) => (
        <DataSetAccordion record={record} isSelected={checkIsSelected(record.key)} />
      ))}
      <Box mt="24px">
        <SubTitle color={THEME.primaryColors.black} fontWeight={500}>
          {t('Query projection')}
        </SubTitle>
        {schemaOptionsLoading ? (
          <Box borderRadius="8px">
            <Skeleton height="40px" />
          </Box>
        ) : (
          <StyledFormControl fullWidth>
            <Select
              value={dataSetVariable || NONE}
              options={schemaOptions || []}
              onChange={(e) => onChange(e, 'dataSetVariable')}
              placeholder="Select Variable"
            />
          </StyledFormControl>
        )}
        {projectionsOptionsLoading ? (
          <Box borderRadius="8px">
            <Skeleton height="40px" />
          </Box>
        ) : (
          <StyledFormControl $mt="8px" fullWidth>
            <Select
              value={dataSetProjection || NONE}
              options={projectionsOptions || []}
              onChange={(e) => onChange(e, 'dataSetProjection')}
              placeholder="Select Operation"
            />
          </StyledFormControl>
        )}
      </Box>
    </>
  );
};

export default DataSetField;
