import React, { useState } from 'react';
import { string, shape, arrayOf, bool, elementType } from 'prop-types';
import { InputLabel } from '@mui/material';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { StyledPaper, StyledMenuItemOnboarding, CustomSelect } from '../../atoms/Select/StyledSelect';
import CheckIcon from '../../../assets/icons/Select/CheckIcon';
import SelectDropdownIcon from './SelectDropdownIcon';
import StyledFormControlOnboarding from '../../styled/StyledFormControlOnboarding';

const StyledSelect = ({ paperComponent, name, options, label, placeholder, errorProps, multiple, ...restProps }) => {
  const [{ value, onBlur, onChange }, { touched, error }] = useField(name);
  const { t } = useTranslation();
  const { renderValue, ...formControlProps } = restProps;
  const hasError = touched && Array.isArray(error) && error.length;
  const [focusedOption, setFocusedOption] = useState('');

  const handleFocus = (newValue) => {
    if (focusedOption === '') {
      setFocusedOption('-1');
    } else {
      setFocusedOption(newValue);
    }
  };

  const handleBlur = () => {
    setFocusedOption('');
  };

  return (
    <StyledFormControlOnboarding
      variant="outlined"
      fullWidth
      {...formControlProps}
      className={hasError ? 'has-error' : ''}
    >
      {label && (
        <InputLabel id={`${name}-label`} shrink>
          {hasError ? t(error[0]) : label}
        </InputLabel>
      )}
      <CustomSelect
        labelId={`${name}-label`}
        id={name}
        name={name}
        IconComponent={() => <SelectDropdownIcon switchIcon />}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        MenuProps={{
          getContentAnchorEl: null,
          PaperProps: {
            component: paperComponent ?? StyledPaper,
          },
        }}
        displayEmpty
        multiple={multiple}
        renderValue={value !== '' ? undefined : () => placeholder && ''}
        {...restProps}
      >
        {placeholder && (
          <StyledMenuItemOnboarding value="" disabled>
            {placeholder}
          </StyledMenuItemOnboarding>
        )}
        {options.map((option) => {
          // const focused = focusedOption === option.value;
          return (
            <StyledMenuItemOnboarding
              key={option.value}
              value={option.value}
              onMouseLeave={() => handleBlur()}
              onFocus={() => handleFocus(option.value)}
              $multiple={multiple}
            >
              {multiple && (
                <div className="checkbox">
                  <CheckIcon stroke="white" />
                </div>
              )}
              {option.icon || null}
              {option.text.text ? option.text.text : ''}
            </StyledMenuItemOnboarding>
          );
        })}
      </CustomSelect>
    </StyledFormControlOnboarding>
  );
};

StyledSelect.propTypes = {
  name: string,
  options: arrayOf(shape({})),
  label: string,
  placeholder: string,
  paperComponent: elementType,
  errorProps: shape({}),
  multiple: bool,
};

StyledSelect.defaultProps = {
  name: '',
  options: [],
  label: '',
  placeholder: '',
  paperComponent: undefined,
  errorProps: {},
  multiple: false,
};

export default StyledSelect;
