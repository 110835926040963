import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/lab/Skeleton';

const COMMON_PROPS = {
  borderRadius: '8px',
  overflow: 'hidden',
};

const LoadingState = () => {
  return (
    <Box {...COMMON_PROPS} mt="6px" mb="24px">
      <Skeleton variant="rectangular" height={44} width="100%" animation="wave" />
    </Box>
  );
};

export default LoadingState;
