import React, { useCallback, useEffect, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import debounce from 'lodash/debounce';
import { NotificationManager } from 'react-notifications';
import { SAVE_FLOW } from '../../../utils/queries/flows/mutations';
import { facadeNodesToSteps } from '../utils/facades';
import { fieldsUpdatedAfterSave } from './saveConfigUtils';
import { useOnSaveCompleted } from './useOnSaveCompleted';
import { getOperationNameForQuery } from '../../../utils/graphql';
import { GET_VERSION_HISTORY_FOR_FLOW } from '../../../utils/queries/flows/queries';
import ToastCustomContainer from '../../ToastCustomContainer';
import { TOAST_TIMEOUT } from '../../../constants/toasts';

const SAVE_DEBOUNCE_DELAY = 1500;

export const useSaveConfiguration = ({
  flowId,
  initialState,
  setInitialState,
  selectedNodeData,
  setNodes,
  nodeDataToSave,
  setNodeDataToSave,
  setChangesMade,
  setCurrentConfig,
  setDraftConfig,
  selectedNodeId,
  refetchConfigurationIssues,
}) => {
  const onSaveCompleted = useOnSaveCompleted({ setNodes, setChangesMade, selectedNodeId, refetchConfigurationIssues });
  const [saveConfiguration, { loading }] = useMutation(SAVE_FLOW, {
    context: { skipGlobalHandling: true },
    refetchQueries: [getOperationNameForQuery(GET_VERSION_HISTORY_FOR_FLOW)],
  });
  const [onSaveFlowPromise] = useMutation(SAVE_FLOW, {
    variables: { flowId },
    onCompleted: (data) => {
      setInitialState(data?.saveConfiguration?.initialState);
    },
    refetchQueries: [getOperationNameForQuery(GET_VERSION_HISTORY_FOR_FLOW)],
  });
  useEffect(() => {
    if (!nodeDataToSave || (nodeDataToSave && !selectedNodeData)) {
      setNodeDataToSave(selectedNodeData);
    }
    if (selectedNodeData && nodeDataToSave && nodeDataToSave.unsaved !== selectedNodeData.unsaved) {
      setNodeDataToSave({
        ...nodeDataToSave,
        ...fieldsUpdatedAfterSave(selectedNodeData),
      });
    }
  }, [nodeDataToSave, selectedNodeData, setNodeDataToSave]);

  const getVariables = useCallback(
    (rawNewData, skipOnSaveComplete) => {
      const newData = rawNewData?.map((node) =>
        node.id === selectedNodeId && !skipOnSaveComplete ? { ...node, data: { ...node.data, changed: true } } : node
      );
      if (newData) {
        const newSteps = facadeNodesToSteps(newData);
        return {
          flowId: flowId,
          steps: newSteps,
          initialState: initialState,
        };
      }

      return {
        flowId,
        steps: null,
      };
    },
    [flowId, initialState, selectedNodeId]
  );

  const saveData = useCallback(
    async (variables, skipOnSaveComplete) => {
      const { data, errors } = await saveConfiguration({
        variables,
      });
      if (errors?.length > 0) {
        throw Error('There was an error while saving your automation!');
      }
      if (!skipOnSaveComplete) {
        onSaveCompleted(data);
      } else {
        setChangesMade(true);
      }
      setCurrentConfig((prevConfig) => ({ ...prevConfig, id: data?.saveConfiguration?.id }));
      setDraftConfig((prevConfig) => {
        if (!prevConfig) {
          NotificationManager.info(
            <ToastCustomContainer
              message="your changes are being saved to a new draft"
              title="For your information"
              skipDot
            />,
            null,
            TOAST_TIMEOUT
          );
        }
        return { ...prevConfig, id: data?.saveConfiguration?.id };
      });
      return data;
    },
    [onSaveCompleted, saveConfiguration, setChangesMade, setCurrentConfig, setDraftConfig]
  );

  const onDataSaveAsync = useCallback(
    async (newData, skipOnSaveComplete) => {
      const variables = getVariables(newData, skipOnSaveComplete);
      return saveData(variables, skipOnSaveComplete);
    },
    [getVariables, saveData]
  );

  const onDataSave = useMemo(() => {
    return debounce(async (newData, skipOnSaveComplete) => {
      const variables = getVariables(newData, skipOnSaveComplete);
      return saveData(variables, skipOnSaveComplete);
    }, SAVE_DEBOUNCE_DELAY);
  }, [getVariables, saveData]);

  return {
    saveConfiguration: onDataSave,
    saveConfigurationAsync: onDataSaveAsync,
    saveFlowPromise: onSaveFlowPromise,
    isDataSaving: loading,
  };
};
