import React from 'react';
import Skeleton from '@mui/lab/Skeleton';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useGridProps } from '../StatItem/use-grid-props';
import { StatItemWrapper, StatItemHeader, StatItemValue, StatItemStat } from '../StatItem/styled';

const LoadingStateItem = ({ isAmount }) => {
  const { isLarge, gridItemProps } = useGridProps(isAmount ? 'amountStat' : 'default');
  const applyLargeStyles = isLarge || isAmount;

  return (
    <Grid item {...gridItemProps}>
      <StatItemWrapper $applyLargeStyles={applyLargeStyles}>
        <StatItemHeader>
          <Box borderRadius="4px" overflow="hidden" width="90px" height="10px">
            <Skeleton variant="rectangular" height={100} width={100} animation="wave" />
          </Box>

          <Box borderRadius="4px" overflow="hidden" width="10px" height="10px">
            <Skeleton variant="rectangular" height={100} width={100} animation="wave" />
          </Box>
        </StatItemHeader>

        <StatItemValue>
          <Box borderRadius="4px" overflow="hidden" width="120px" height="40px" mt="20px">
            <Skeleton variant="rectangular" height={100} width={220} animation="wave" />
          </Box>
        </StatItemValue>
        <StatItemStat>
          <Box borderRadius="4px" overflow="hidden" width="200px" height="10px">
            <Skeleton variant="rectangular" height={100} width={230} animation="wave" />
          </Box>
        </StatItemStat>
      </StatItemWrapper>
    </Grid>
  );
};
LoadingStateItem.propTypes = {
  isAmount: PropTypes.bool,
};
LoadingStateItem.defaultProps = {
  isAmount: false,
};
export default LoadingStateItem;
