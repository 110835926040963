import styled from 'styled-components';
import { TableContainer } from '@mui/material';
import { withStyles } from '@mui/styles';

const StyledWrapper = styled.span`
  background: #fff !important;
  width: 100%;
  & > span {
    content: '...';
    position: absolute;
    display: flex;
    top: -15px;
    left: 0;
    color: red;
    width: 100%;
    height: 20px;
    justify-content: center;
    align-items: center;
    opacity: 0;
  }

  &:hover span {
    opacity: 1;
  }
`;

const getTableHeightNegatives = ({ empty, $customTableNegativeHeight }) => {
  switch (true) {
    case empty:
      return $customTableNegativeHeight || '106px';
    default:
      return $customTableNegativeHeight || '160px';
  }
};

const getWidth = ({ draweropen, sidecollapsed, width }) => {
  if (!width) {
    return draweropen
      ? `calc(100vw - ${sidecollapsed ? '422px' : '674px'})`
      : `calc(100vw - ${sidecollapsed ? '100px' : '350px'})`;
  }

  return width;
};

const getHeight = ({ empty, $customTableNegativeHeight, height }) => {
  if (!height) {
    return `calc(100vh - ${getTableHeightNegatives({ empty, $customTableNegativeHeight })})`;
  }

  return height;
};

const StyledTableContainer = withStyles({
  root: {
    position: 'relative',
    width: ({ draweropen, sidecollapsed, width }) => getWidth({ draweropen, sidecollapsed, width }),
    height: ({ draweropen, sidecollapsed, height }) => getHeight({ draweropen, sidecollapsed, height }),
    margin: ({ margin }) => margin,
    overflowY: ({ overflowY }) => overflowY,

    '& .MuiTable-root': {
      position: 'sticky',
      top: '0px',
      zIndex: 10,
      width: ({ width }) => width,
    },

    '& .MuiTableHead-root': {
      position: 'relative',
      textTransform: 'uppercase',
      '& .MuiTableRow-root': {
        '& .MuiTableCell-root': {
          paddingBottom: ' 0 !important',
          borderRadius: '6px 6px 0 0 !important',
          transition: '0.35s',
          position: 'relative',

          '&:last-child': {
            '& .HeadCell_DropdownMenu': {
              left: '-110px !important', //fixes dropdown position of last-child
              transformOrigin: '100% 0%',
              '& .dropdown_facade': {
                left: '110px !important',
              },
            },
          },
        },
      },
    },
  },
})(TableContainer);

export { StyledTableContainer, StyledWrapper };
