import editPersonValidator from '../validators/editPersonValidator';
import { ROLES, ROLE_LABEL_MAP } from '../../permissions/constants';

const editPersonFieldsNames = {
  name: 'name',
  email: 'email',
  companyRole: 'companyRole',
};

export const editPersonFields = [
  // { field: editPersonFieldsNames.name, label: 'Name' }, // hide for now
  // { field: editPersonFieldsNames.email, label: 'Email editress' }, // hide for now
  { field: editPersonFieldsNames.companyRole, label: 'Role' },
];

export const editPersonOptions = [
  { value: ROLES.owner, text: { text: ROLE_LABEL_MAP[ROLES.owner] } },
  { value: ROLES.admin, text: { text: ROLE_LABEL_MAP[ROLES.admin] } },
  { value: ROLES.developer, text: { text: ROLE_LABEL_MAP[ROLES.developer] } },
  { value: ROLES.operator, text: { text: ROLE_LABEL_MAP[ROLES.operator] } },
];

const editPersonSchema = {
  mapPropsToValues: (props) => {
    const { name, email, role: companyRole } = props.initialValues;
    return {
      [editPersonFieldsNames.name]: name || '',
      [editPersonFieldsNames.email]: email || '',
      [editPersonFieldsNames.companyRole]: companyRole || '',
    };
  },
  validationSchema: editPersonValidator,
  displayName: 'editPersonForm',
};

export default editPersonSchema;
