import React from 'react';
import { string, bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { StyledItemBox } from './styled';
import { P12, P14 } from '../../atoms';
import THEME from '../../../constants/theme';

const Item = ({ label, path, selected, onSelect }) => {
  const { t } = useTranslation();
  return (
    <StyledItemBox $selected={selected} onClick={onSelect}>
      <Box flexDirection="column" maxWidth="720px" overflow="hidden">
        <P14 overflow="hidden" textOverflow="ellipsis" noWrap>
          {t(label)}
        </P14>
        {path && (
          <P12 color={THEME.greyColors.grey500} overflow="hidden" textOverflow="ellipsis" noWrap>
            {t(path)}
          </P12>
        )}
      </Box>
    </StyledItemBox>
  );
};

Item.propTypes = {
  label: string,
  path: string,
  selected: bool,
  onSelect: func,
};

Item.defaultProps = {
  label: '',
  path: '',
  selected: false,
  onSelect: () => {},
};

export default Item;
