import { gql } from '@apollo/client';
import { STEP_COMMENT } from '../../../fragments/publicFragments';

export const GET_ALL_STEP_COMMENTS_FOR_FLOW = gql`
  query getAllStepCommentsForFlow($flowId: ID!) {
    getAllStepCommentsForFlow(flowId: $flowId) {
      stepId
      newMessages
      comments {
        ...f_StepComment
      }
    }
  }
  ${STEP_COMMENT}
`;
