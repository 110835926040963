import React from 'react';
import capitalize from '@mui/material/utils/capitalize';

import { StyledTableCell } from './styled';
import { PROPTYPES } from './constant';
import { Tag } from '../../atoms';

const colors = {
  NEEDS_RESPONSE: {
    bg: '#F5F2FF',
    color: '#4E40EF',
  },
  LOST: {
    bg: '#FBF5F5',
    color: '#923535',
  },
  WON: {
    bg: '#F3FBF7',
    color: '#10944A',
  },
  OPEN: {
    bg: '#FEF8F6',
    color: '#C25639',
  },
  REFUNDED: {
    bg: '#FEF8F6',
    color: '#C25639',
  },
  default: {
    bg: '#F5F2FF',
    color: '#4E40EF',
  },
};

const parseStatusToUiLabel = (status) => {
  if (typeof status !== 'string') return '';

  return status.toLowerCase().replace(/_/g, ' ');
};

const TagStatusCell = ({ row: { original }, cell: { column } }) => {
  const header = column?.Header;
  const status = original[header];
  const uiStatusLabel = parseStatusToUiLabel(status);
  const derivedColor = colors[status] || colors.default;

  return (
    <StyledTableCell column={column} id={original?.id}>
      <Tag backgroundColor={derivedColor.bg} color={derivedColor.color}>
        {capitalize(uiStatusLabel ?? '')}
      </Tag>
    </StyledTableCell>
  );
};

TagStatusCell.propTypes = PROPTYPES;
export default TagStatusCell;
