import React from 'react';
import { shape, bool } from 'prop-types';
import { Checkbox, Box } from '@mui/material';
import { useFormikContext } from 'formik';
import { L12, P14, Button, MaterialIconStyler } from '../../../../../atoms';
import THEME from '../../../../../../constants/theme';
import { CheckboxWrapper } from '../../../styled';

const Checkboxes = ({ checkboxItem, showErrors }) => {
  const { values, errors, setFieldValue } = useFormikContext();

  const handleCheckboxUpdate = (valueItem, option) => {
    if (values[valueItem.block_id]) {
      if (values[valueItem.block_id]?.some((item) => item.value === option.value)) {
        setFieldValue(
          valueItem.block_id,
          values[valueItem.block_id].filter((valueObject) => valueObject.value !== option.value)
        );
      } else {
        setFieldValue(valueItem.block_id, [...values[valueItem.block_id], option]);
      }
    } else {
      setFieldValue(valueItem.block_id, [option]);
    }
  };

  return (
    <>
      <P14>{checkboxItem.label.text}</P14>

      <Box>
        {checkboxItem.element.options.map((option) => (
          <Button onClick={() => handleCheckboxUpdate(checkboxItem, option)} transparent smaller>
            <CheckboxWrapper>
              <MaterialIconStyler
                icon={Checkbox}
                checked={values[checkboxItem.block_id]?.some(
                  (currentItemValue) => currentItemValue.value === option.value
                )}
                color={THEME.secondaryColors.blue}
              />
              <P14>{option.text.text}</P14>
            </CheckboxWrapper>
          </Button>
        ))}
      </Box>

      {showErrors && errors[checkboxItem.block_id] && (
        <L12 textAlign="left" margin="0 0 24px 0" color={THEME.secondaryColors.danger}>
          {errors[checkboxItem.block_id]}
        </L12>
      )}
    </>
  );
};

Checkboxes.propTypes = {
  checkboxItem: shape({}).isRequired,
  showErrors: bool,
};

Checkboxes.defaultProps = {
  showErrors: false,
};

export default Checkboxes;
