import React from 'react';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { bool, func, string } from 'prop-types';
import { StyledDialog, StyledPaper } from '../../Dialog/styled';
import { H5New } from '../../atoms/Typography/H5New';
import { ButtonRounded, P14 } from '../../atoms';

const ConfirmModal = ({ isOpen, closeModal, title, text, handleSubmit, isDataSaving, confirmActionText }) => {
  const { t } = useTranslation();
  return (
    <StyledDialog
      open={isOpen}
      onClose={closeModal}
      width="337px"
      PaperComponent={StyledPaper}
      scroll="paper"
      maxWidth="xs"
    >
      <Box p="24px">
        <H5New margin="0 0 16px 0">{title}</H5New>
        <P14 margin="0 0 24px 0">{text}</P14>
        <Box display="flex" width="100%">
          <ButtonRounded type="button" color="secondary" variant="contained" onClick={closeModal} flex={1}>
            {t('Cancel')}
          </ButtonRounded>
          <ButtonRounded
            type="button"
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            flex={1}
            ml="8px"
            disabled={isDataSaving}
          >
            {isDataSaving ? <CircularProgress size={24} color="inherit" /> : t(confirmActionText)}
          </ButtonRounded>
        </Box>
      </Box>
    </StyledDialog>
  );
};

ConfirmModal.propTypes = {
  isOpen: bool.isRequired,
  closeModal: func.isRequired,
  title: string.isRequired,
  text: string.isRequired,
  confirmActionText: string.isRequired,
  handleSubmit: func.isRequired,
  isDataSaving: bool,
};
ConfirmModal.defaultProps = {
  isDataSaving: false,
};

export default ConfirmModal;
