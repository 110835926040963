import React from 'react';
import { string } from 'prop-types';

const GooglePay = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#F4F4F4" />
    <mask
      id="mask0_77_4095"
      style={{ 'mask-type': 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="5"
      y="5"
      width="14"
      height="14"
    >
      <path
        d="M18.5227 10.7273H12V13.4318H15.7545C15.4045 15.15 13.9409 16.1364 12 16.1364C9.70909 16.1364 7.86364 14.2909 7.86364 12C7.86364 9.70909 9.70909 7.86364 12 7.86364C12.9864 7.86364 13.8773 8.21364 14.5773 8.78636L16.6136 6.75C15.3727 5.66818 13.7818 5 12 5C8.11818 5 5 8.11818 5 12C5 15.8818 8.11818 19 12 19C15.5 19 18.6818 16.4545 18.6818 12C18.6818 11.5864 18.6182 11.1409 18.5227 10.7273Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_77_4095)">
      <path d="M4.36353 16.1364V7.86365L9.77262 12L4.36353 16.1364Z" fill="#FBBC05" />
    </g>
    <mask
      id="mask1_77_4095"
      style={{ 'mask-type': 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="5"
      y="5"
      width="14"
      height="14"
    >
      <path
        d="M18.5227 10.7273H12V13.4318H15.7545C15.4045 15.15 13.9409 16.1364 12 16.1364C9.70909 16.1364 7.86364 14.2909 7.86364 12C7.86364 9.70909 9.70909 7.86364 12 7.86364C12.9864 7.86364 13.8773 8.21364 14.5773 8.78636L16.6136 6.75C15.3727 5.66818 13.7818 5 12 5C8.11818 5 5 8.11818 5 12C5 15.8818 8.11818 19 12 19C15.5 19 18.6818 16.4545 18.6818 12C18.6818 11.5864 18.6182 11.1409 18.5227 10.7273Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_77_4095)">
      <path d="M4.36353 7.86365L9.77262 12L11.9999 10.0591L19.6363 8.81819V4.36365H4.36353V7.86365Z" fill="#EA4335" />
    </g>
    <mask
      id="mask2_77_4095"
      style={{ 'mask-type': 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="5"
      y="5"
      width="14"
      height="14"
    >
      <path
        d="M18.5227 10.7273H12V13.4318H15.7545C15.4045 15.15 13.9409 16.1364 12 16.1364C9.70909 16.1364 7.86364 14.2909 7.86364 12C7.86364 9.70909 9.70909 7.86364 12 7.86364C12.9864 7.86364 13.8773 8.21364 14.5773 8.78636L16.6136 6.75C15.3727 5.66818 13.7818 5 12 5C8.11818 5 5 8.11818 5 12C5 15.8818 8.11818 19 12 19C15.5 19 18.6818 16.4545 18.6818 12C18.6818 11.5864 18.6182 11.1409 18.5227 10.7273Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask2_77_4095)">
      <path
        d="M4.36353 16.1364L13.909 8.81819L16.4226 9.13637L19.6363 4.36365V19.6364H4.36353V16.1364Z"
        fill="#34A853"
      />
    </g>
    <mask
      id="mask3_77_4095"
      style={{ 'mask-type': 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="5"
      y="5"
      width="14"
      height="14"
    >
      <path
        d="M18.5227 10.7273H12V13.4318H15.7545C15.4045 15.15 13.9409 16.1364 12 16.1364C9.70909 16.1364 7.86364 14.2909 7.86364 12C7.86364 9.70909 9.70909 7.86364 12 7.86364C12.9864 7.86364 13.8773 8.21364 14.5773 8.78636L16.6136 6.75C15.3727 5.66818 13.7818 5 12 5C8.11818 5 5 8.11818 5 12C5 15.8818 8.11818 19 12 19C15.5 19 18.6818 16.4545 18.6818 12C18.6818 11.5864 18.6182 11.1409 18.5227 10.7273Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask3_77_4095)">
      <path d="M19.6364 19.6364L9.77273 12L8.5 11.0455L19.6364 7.86365V19.6364Z" fill="#4285F4" />
    </g>
  </svg>
);

GooglePay.propTypes = {
  width: string,
  height: string,
};

GooglePay.defaultProps = {
  width: '24',
  height: '24',
};

export default GooglePay;
