import * as Yup from 'yup';
import buildValidator from '../../../utils/validators/buildValidator';

const schema = Yup.lazy(() =>
  Yup.object().shape({
    email: Yup.string()
      .email('Email is not valid')
      .required('Email is required'),
  })
);

export default buildValidator(schema);
