import { useSelectedNode } from './useSelectedNode';
import { useFlowEditorContext } from '../../../../../context';
import { useGetEditorProperties } from '../../../../../../../hooks/useGetEditorProperties';

const useFlowPropertiesList = (matchingTypes = [], fetchPolicy, skip, id) => {
  const { flowId, selectedNodeData } = useFlowEditorContext();
  const [selectedNode] = useSelectedNode();

  const selectedDataType = selectedNodeData?.isPresetDataType && selectedNodeData?.selectedDataType;

  return useGetEditorProperties(
    { flowId, stepId: selectedNode?.id || id },
    matchingTypes,
    fetchPolicy,
    skip,
    selectedDataType
  );
};

export { useFlowPropertiesList };
