import React from 'react';
import CustomSelect from '../SignUpCompanyDetailsForm/CustomSelect';

const OPTIONS = [
  { value: 'RENTALS', text: { text: 'RENTALS' } },
  { value: 'STORES_FASHION_ACCESSORIES_OBJECTS', text: { text: 'STORES FASHION ACCESSORIES OBJECTS' } },
  { value: 'BEAUTY_COSMETICS_HEALTH', text: { text: 'BEAUTY COSMETICS HEALTH' } },
  { value: 'FOOD_WINE_RESTAURANTS', text: { text: 'FOOD WINE RESTAURANTS' } },
  { value: 'HOSPITALITY_TRAVEL_CORIDING', text: { text: 'HOSPITALITY TRAVEL CORIDING' } },
  { value: 'ART_MUSIC_ENTERTAINMENT', text: { text: 'ART MUSIC ENTERTAINMENT' } },
  { value: 'FURNITURE_GARDEN', text: { text: 'FURNITURE GARDEN' } },
  { value: 'SERVICES_JOBBING_EDUCATION', text: { text: 'SERVICES JOBBING EDUCATION' } },
  { value: 'SPORT_RECREATION_ACTIVITIES', text: { text: 'SPORT RECREATION ACTIVITIES' } },
  { value: 'TICKETING', text: { text: 'TICKETING' } },
  { value: 'LOAN', text: { text: 'LOAN' } },
  { value: 'EQUITY', text: { text: 'EQUITY' } },
  { value: 'PROPERTY_EQUITY', text: { text: 'PROPERTY EQUITY' } },
  { value: 'REWARDS_CHARITY', text: { text: 'REWARDS CHARITY' } },
  { value: 'POOL_GROUP_PAYMENT', text: { text: 'POOL GROUP PAYMENT' } },
  { value: 'FRANCHISE_', text: { text: 'FRANCHISE' } },
  { value: 'OTHER_', text: { text: 'OTHER' } },
];

const SectorTypeSelect = (props) => {
  return <CustomSelect {...props} options={OPTIONS} />;
};

export default SectorTypeSelect;
