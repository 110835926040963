import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { bool, func, number, string } from 'prop-types';
import moment from 'moment';
import { L12, P14, P14M } from '../../../../atoms';
import THEME from '../../../../../constants/theme';
import { CommentsMoreButton } from '../styled';
import More from '../../../../../assets/icons/More';
import DropDownMenu from '../../../../menus/DropDownMenu';
import { useNodeContext } from '../context';

const Comment = ({ index, length, id, authorName, timestamp, text, setSelectedComment, selected, removeComment }) => {
  const { commentsSaving } = useNodeContext();
  const [isHovering, setIsHovering] = useState(false);

  const options = [
    {
      Edit: () => setSelectedComment(id, text),
    },
    {
      Delete: () => removeComment(id),
    },
  ];
  return (
    <Box
      p="8px 12px"
      mb={index !== length - 1 ? '4px' : '0'}
      key={id}
      borderRadius="8px"
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      bgcolor={selected ? THEME.primaryColors.primaryLight : THEME.primaryColors.white}
    >
      <Box height="24px" display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" justifyContent="center" maxWidth="calc(100% - 20px)">
          <P14M margin="0 8px 0 0" textOverflow="ellipsis" overflow="hidden" noWrap>
            {authorName}
          </P14M>
          <L12 color={THEME.greyColors.grey600} noWrap>
            {selected ? 'editing...' : moment(timestamp).fromNow()}
          </L12>
        </Box>
        <Box width="20px">
          {isHovering && !commentsSaving && (
            <DropDownMenu
              id={`Node-${id}`}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              button={
                <CommentsMoreButton>
                  <More />
                </CommentsMoreButton>
              }
              options={options}
              lastItemDanger
            />
          )}
        </Box>
      </Box>
      <Box>
        <P14 whiteSpace="pre-wrap">{text.split('\\n').join('\n')}</P14>
      </Box>
    </Box>
  );
};

Comment.propTypes = {
  index: number.isRequired,
  length: number.isRequired,
  id: string.isRequired,
  authorName: string.isRequired,
  timestamp: string.isRequired,
  text: string.isRequired,
  setSelectedComment: func.isRequired,
  selected: bool.isRequired,
  removeComment: func.isRequired,
};

export default Comment;
