import styled from 'styled-components';
import THEME from '../../../../../constants/theme';

const StyledLink = styled.a`
  color: ${THEME.primaryColors.purpleMain};

  &:hover {
    color: ${THEME.secondaryColors.secondary};
  }
`;

export { StyledLink };
