import { gql } from '@apollo/client';
import { STEP_COMMENT } from '../../../fragments/publicFragments';

export const CREATE_STEP_COMMENT = gql`
  mutation createStepComment($flowId: ID!, $stepId: ID!, $text: String!) {
    createStepComment(flowId: $flowId, stepId: $stepId, text: $text) {
      ...f_StepComment
    }
  }
  ${STEP_COMMENT}
`;

export const EDIT_STEP_COMMENT = gql`
  mutation editStepComment($commentId: ID!, $text: String!) {
    editStepComment(commentId: $commentId, text: $text) {
      ...f_StepComment
    }
  }
  ${STEP_COMMENT}
`;

export const DELETE_STEP_COMMENT = gql`
  mutation deleteStepComment($commentId: ID!) {
    deleteStepComment(commentId: $commentId) {
      ...f_StepComment
    }
  }
  ${STEP_COMMENT}
`;

export const RESOLVE_THREAD = gql`
  mutation resolveThread($flowId: ID!, $stepId: ID!) {
    resolveThread(flowId: $flowId, stepId: $stepId)
  }
`;

export const READ_THREAD = gql`
  mutation readThread($flowId: ID!, $stepId: ID!) {
    readThread(flowId: $flowId, stepId: $stepId)
  }
`;
