import { useQuery } from '@apollo/client';
import { useNodeDataToSave } from './useNodeDataToSave';
import useGetAvailableDataProviders from '../../hooks/useGetAvailableDataProviders';
import {
  GET_EXAMPLE_SCHEMA_FOR_TYPE,
  GET_SINGLE_RECORD_UNIQUENESS_OPERATOR,
} from '../../../../../../../utils/queries/properties/editorPropertiesQueries';

const QUERIES_MAP = {
  getExampleSchemaForType: GET_EXAMPLE_SCHEMA_FOR_TYPE,
  getSingleRecordUniquenessOperators: GET_SINGLE_RECORD_UNIQUENESS_OPERATOR,
};

const useGetOptionsForSelect = (queryName) => {
  const [{ selectedDataProvider }] = useNodeDataToSave();

  const { availableDataProviders, loading } = useGetAvailableDataProviders();

  const dataProvider = availableDataProviders?.find(({ type }) => type === selectedDataProvider);
  const { data } = useQuery(QUERIES_MAP[queryName], {
    variables: {
      type: dataProvider?.type,
    },
  });

  const items = data ? data[queryName] : [];

  const options = items?.map(({ key, label }) => ({ value: key, title: label }));

  return { options, loading };
};

export default useGetOptionsForSelect;
