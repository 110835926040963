import { TextCell, DateCell, TextCellWithCopyIcon } from '../../../../../components/table/ReactTableCells';
import ConnectionCell from '../../../components/Details/DetailsTable/cells/ConnectionCell';

const WIDTH = 70;

const CELL_SETTINGS = {
  numeric: false,
  disablePadding: false,
  width: WIDTH,
  Cell: TextCell,
};

const LINKED_PAYMENT_GATEWAYS_COLUMNS_ID = {
  id: 'id',
  token: 'token',
  created: 'created',
  updated: 'updated',
};

const LINKED_PAYMENT_GATEWAYS_COLUMNS_LABELS = {
  [LINKED_PAYMENT_GATEWAYS_COLUMNS_ID.id]: 'Processor',
  [LINKED_PAYMENT_GATEWAYS_COLUMNS_ID.token]: 'Token',
  [LINKED_PAYMENT_GATEWAYS_COLUMNS_ID.created]: 'Date Created',
  [LINKED_PAYMENT_GATEWAYS_COLUMNS_ID.update]: 'Date Updated',
};

const headCells = [
  {
    accessor: LINKED_PAYMENT_GATEWAYS_COLUMNS_ID.id,
    ...CELL_SETTINGS,
    Header: LINKED_PAYMENT_GATEWAYS_COLUMNS_ID.id,
    label: LINKED_PAYMENT_GATEWAYS_COLUMNS_LABELS[LINKED_PAYMENT_GATEWAYS_COLUMNS_ID.id],
    Cell: ConnectionCell,
  },
  {
    accessor: LINKED_PAYMENT_GATEWAYS_COLUMNS_ID.token,
    ...CELL_SETTINGS,
    Header: LINKED_PAYMENT_GATEWAYS_COLUMNS_ID.token,
    label: LINKED_PAYMENT_GATEWAYS_COLUMNS_LABELS[LINKED_PAYMENT_GATEWAYS_COLUMNS_ID.token],
    Cell: TextCellWithCopyIcon,
    width: 100,
  },
  {
    accessor: LINKED_PAYMENT_GATEWAYS_COLUMNS_ID.created,
    ...CELL_SETTINGS,
    Header: LINKED_PAYMENT_GATEWAYS_COLUMNS_ID.created,
    label: LINKED_PAYMENT_GATEWAYS_COLUMNS_LABELS[LINKED_PAYMENT_GATEWAYS_COLUMNS_ID.created],
    Cell: DateCell,
    width: 50,
  },
];

const transformNodeToRow = (linkedPaymentMethods) =>
  linkedPaymentMethods.map((paymentMethod) => {
    return {
      id: paymentMethod?.id ?? '',
      [LINKED_PAYMENT_GATEWAYS_COLUMNS_ID.token]: paymentMethod?.token ?? '',
      [LINKED_PAYMENT_GATEWAYS_COLUMNS_ID.created]: paymentMethod?.created ?? '',
      [LINKED_PAYMENT_GATEWAYS_COLUMNS_ID.updated]: paymentMethod?.updated ?? '',
    };
  });

export {
  transformNodeToRow,
  headCells,
  LINKED_PAYMENT_GATEWAYS_COLUMNS_ID,
  LINKED_PAYMENT_GATEWAYS_COLUMNS_LABELS,
  CELL_SETTINGS,
};
