import React, { Fragment, useRef } from 'react';
import { Box, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Title from '../Title';
import SubTitle from '../SubTitle';
import THEME from '../../../../../constants/theme';
import { StyledTimeInput } from './styled';
import { useNodeDataToSave } from './fields/hooks/useNodeDataToSave';

const TIME_FIELDS = [
  { name: 'days', maxValue: 365, label: 'days' },
  { name: 'hours', maxValue: 23, label: 'hours' },
  { name: 'minutes', maxValue: 59, label: 'min' },
  { name: 'seconds', maxValue: 59, label: 'sec' },
];

const TimeBlock = () => {
  const { t } = useTranslation();
  const [{ delayTime }, updateDataToSave] = useNodeDataToSave();
  const inputRefs = useRef({});
  const handleChange = (e, maxValue, index) => {
    const { value, name } = e.target;

    const replacedValue = value.replace(/[^0-9.]/g, '');

    const updatedValue = +replacedValue;

    if (Number(updatedValue) > maxValue) {
      if (index !== TIME_FIELDS.length - 1) {
        const nextIndex = index + 1;
        inputRefs.current[nextIndex].focus();
      }
      updateDataToSave({ delayTime: { ...delayTime, [name]: maxValue } });
    } else {
      updateDataToSave({ delayTime: { ...delayTime, [name]: updatedValue } });
    }
  };

  return (
    <>
      <Title>{t('Options')}</Title>
      <SubTitle>{t('How long should we hold before proceeding to the next stage of the automation?')}</SubTitle>
      <Box
        display="flex"
        alignItems="center"
        border={`1px solid ${THEME.greyColors.grey200}`}
        p="4px"
        borderRadius="4px"
      >
        {TIME_FIELDS.map(({ name, maxValue, label }, index) => {
          const width = () => {
            if (delayTime?.[name] > 99) {
              return '27px';
            }
            if (delayTime?.[name] > 9) {
              return '18px';
            }
            return '9px';
          };
          return (
            <Fragment key={name}>
              <StyledTimeInput
                inputRef={(ref) => {
                  inputRefs.current[index] = ref;
                }}
                height="40px"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      color={delayTime?.[name] ? THEME.primaryColors.black : 'green'}
                      position="end"
                      disablePointerEvents
                    >
                      {label}
                    </InputAdornment>
                  ),
                  inputProps: {
                    style: { textAlign: 'right' },
                  },
                }}
                name={name}
                $width={width()}
                value={delayTime?.[name] || 0}
                $color={delayTime?.[name] ? THEME.primaryColors.black : THEME.greyColors.grey600}
                onChange={(e) => handleChange(e, maxValue, index)}
              />
              {index !== TIME_FIELDS.length - 1 && <Box height="24px" width="1px" bgcolor={THEME.greyColors.grey200} />}
            </Fragment>
          );
        })}
      </Box>
    </>
  );
};

export default TimeBlock;
