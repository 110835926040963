import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import { useCopyToClipboard } from 'react-use';
import { NotificationManager } from 'react-notifications';
import DetailsTable from '../../../components/Details/DetailsTable';
import { transformNodeToRow, headCells } from './table-data';
import { useNotificationManager } from '../../../../../hooks/useNotificationManager';
import ToastCustomContainer from '../../../../../components/ToastCustomContainer';
import { TOAST_TIMEOUT } from '../../../../../constants/toasts';

const TITLE = 'Linked Payment Processors';

const LinkedPaymentGateways = ({ linkedPaymentGateways }) => {
  const [{ error }, copyToClipboard] = useCopyToClipboard();
  const rows = useMemo(() => transformNodeToRow(linkedPaymentGateways), [linkedPaymentGateways]);
  useNotificationManager('error', error?.message, 'copy text');

  const handleRowClick = (row) => {
    if (!row?.token) return;

    copyToClipboard(row.token);
    NotificationManager.success(
      <ToastCustomContainer message="Successfully copied token to clipboard." title="Token." />,
      null,
      TOAST_TIMEOUT
    );
  };

  return (
    <Box component="section" mt="54px">
      <DetailsTable
        rows={rows}
        title={TITLE}
        cells={headCells}
        handleRowClick={handleRowClick}
        showHeadCells
        showDivider={false}
        minWidth="unset"
      />
    </Box>
  );
};

LinkedPaymentGateways.propTypes = {
  linkedPaymentGateways: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default LinkedPaymentGateways;
