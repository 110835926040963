import React from 'react';

const EmptyRightModal = () => (
  <svg width="108" height="80" viewBox="0 0 108 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M44 30V28C44 23.5817 40.4183 20 36 20H12C7.58172 20 4 23.5817 4 28V52C4 56.4183 7.58172 60 12 60H36C40.4183 60 44 56.4183 44 52V50"
      stroke="#8F92F9"
      strokeWidth="8"
      strokeLinecap="round"
    />
    <path
      d="M64 30V28C64 23.5817 67.5817 20 72 20H96C100.418 20 104 23.5817 104 28V52C104 56.4183 100.418 60 96 60H72C67.5817 60 64 56.4183 64 52V50"
      stroke="#8F92F9"
      strokeWidth="8"
      strokeLinecap="round"
    />
    <path d="M34 40H74" stroke="#F4F4FE" strokeWidth="6" strokeLinecap="round" />
  </svg>
);

export default EmptyRightModal;
