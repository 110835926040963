import styled from 'styled-components';
import THEME from '../../../constants/theme';

export const L10UB = styled.p`
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  margin: 0;
  color: ${({ color }) => color || THEME.greyColors.grey2};
`;
