import { Box } from '@mui/material';
import Skeleton from '@mui/lab/Skeleton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import { useHistory } from 'react-router-dom';
import Payment from '../../assets/icons/QuickStart/Payment';
import Rotate from '../../assets/icons/Rotate';
import { L14M, P14M } from '../../components/atoms';
import { L14B } from '../../components/atoms/Typography/L14B';
import { TempH2 } from '../../components/atoms/Typography/TempH2';
import OnboardingModal from '../../components/OnboardingModal';
import THEME from '../../constants/theme';
import { useGlobalContext } from '../../containers/App/context';
import NewAutomationTemplates from './NewAutomationTemplates';
import { ExplorePaymentFlow, StyledHeroWrapper, UpdateGoals } from './styled';
import RecommendedOverviewDialog from '../FlowDetailsPage/Automation/RecommendedAutomations/RecommendedOverviewDialog';
import { useGetAutomationTemplates } from '../FlowDetailsPage/hooks/useGetAutomationTemplates';
import { usePaymentFlowContext } from '../FlowDetailsPage/paymentFlowContext';
import { UI_ROUTES } from '../../constants/routes';

const HeroSection = () => {
  const { getMeData } = useGlobalContext();
  const { flow, flowLoading } = usePaymentFlowContext();
  const { push } = useHistory();
  const { t } = useTranslation();
  const { paymentProcessingBaseTemplate, loading: templatesLoading } = useGetAutomationTemplates();
  const [openModal, toggleOpenModal] = useToggle(false);

  const loading = flowLoading || templatesLoading;

  const [openOnBoardingModal, setOpenOnBoardingModal] = useState(false);
  const handleExploreClick = () => {
    if (flow?.instructFlowId) {
      push(UI_ROUTES.automations);
    } else {
      toggleOpenModal();
    }
  };

  return (
    <StyledHeroWrapper>
      {openOnBoardingModal && <OnboardingModal setOpen={setOpenOnBoardingModal} isOpen={openOnBoardingModal} />}
      {paymentProcessingBaseTemplate && (
        <RecommendedOverviewDialog
          closeDialog={toggleOpenModal}
          overviewData={{ template: paymentProcessingBaseTemplate }}
          isOpen={openModal}
        />
      )}
      {!getMeData?.we?.acceptingPayments || getMeData?.we?.acceptingPayments === 'NO' ? (
        <Box maxWidth="384px" zIndex={1}>
          <Box position="relative" zIndex={1}>
            <L14B color={THEME.primaryColors.white} margin="0 0 4px 0" lineHeight="24px">
              {t('Not accepting payments yet ?')}
            </L14B>
            <TempH2 color={THEME.primaryColors.white} margin="0 0 12px 0">
              {t('Let’s setup your payment processing in minutes.')}
            </TempH2>
            <L14M color={THEME.primaryColors.white} lineHeight="24px">
              {t(
                'Click the "Explore Payment Processing" button and we will create a default payment processing automation connected to our test processor. Customize and launch in a few clicks.'
              )}
            </L14M>
            {loading ? (
              <Box mt="40px" width="196px" height="48px" borderRadius="8px" overflow="hidden" mr="16px">
                <Skeleton variant="rectangular" height="90px" width="100%" animation="wave" />
              </Box>
            ) : (
              <ExplorePaymentFlow onClick={handleExploreClick}>
                <P14M lineHeight="24px">{t('Explore Payment Processing')}</P14M>
              </ExplorePaymentFlow>
            )}
          </Box>
          <Box position="absolute" bottom="-84px" right="-40px" zIndex={0}>
            <Payment />
          </Box>
        </Box>
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" mb="40px">
            <Box>
              <TempH2 color={THEME.primaryColors.white} maxWidth="320px" margin="0 0 12px 0">
                {t('Time to improve your payments')}
              </TempH2>
              <L14M color={THEME.primaryColors.white} lineHeight="24px" maxWidth="470px" margin="0 0 12px 0">
                {t(
                  'Based on your payment needs, we’ve curated recipes that will help you deliver payments success. Explore each recipe and start improving your payments today.'
                )}
              </L14M>
            </Box>
            <Box display="flex" mt="8px" mr="14px">
              <UpdateGoals display="flex" justifyContent="flex-start" onClick={() => setOpenOnBoardingModal(true)}>
                <Rotate />
                <L14M color={THEME.primaryColors.white} margin="0 0 0 6px">
                  {t('Update Needs')}
                </L14M>
              </UpdateGoals>
            </Box>
          </Box>
          <NewAutomationTemplates />
        </>
      )}
    </StyledHeroWrapper>
  );
};

export default HeroSection;
