import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { NotificationManager } from 'react-notifications';
import isEmpty from 'lodash/isEmpty';

import { GQL_REMOVE_PROCESSOR_FROM_FLOW } from '../../../../utils/queries/flows/flowSettings/mutations';
import { usePaymentFlowContext } from '../../paymentFlowContext';
import { TOAST_TITLE, TOAST_TIMEOUT } from './useHandleAddToFlow';
import { isDefined } from '../../../../utils/helpers';
import { EXCEPTION_DICTIONARY } from '../../constant';
import ToastCustomContainer from '../../../../components/ToastCustomContainer';

const useRemoveProcessorFromFlow = () => {
  const [removeProcessor, { loading: _loading }] = useMutation(GQL_REMOVE_PROCESSOR_FROM_FLOW, {
    context: { skipGlobalHandling: true },
  });
  const { setSaving, refetch, setFlow, flow, refetchLoading } = usePaymentFlowContext();
  const loading = _loading || refetchLoading;

  const handleRemoveProcessor = useCallback(
    async (gatewayConnectionId, processorName, callback) => {
      setSaving(true);
      try {
        const res = await removeProcessor({
          variables: {
            flowId: flow?.id,
            gatewayConnectionId,
          },
        });

        if (!isEmpty(res?.errors)) {
          const translatedError = EXCEPTION_DICTIONARY[res?.errors?.[0]?.message] ?? res?.errors?.[0]?.message;

          NotificationManager.error(
            <ToastCustomContainer message={translatedError} title={TOAST_TITLE} />,
            null,
            TOAST_TIMEOUT
          );

          return;
        }

        if (isDefined(res?.data?.removeGatewayFromPaymentFlow?.id)) {
          const successMessage = `Successfully removed ${processorName} from ${flow?.name}`;
          const refetchData = await refetch();
          if (!isEmpty(refetchData?.data?.getPaymentFlow)) {
            setFlow(refetchData?.data?.getPaymentFlow);
          }
          NotificationManager.success(
            <ToastCustomContainer message={successMessage} title={TOAST_TITLE} />,
            null,
            TOAST_TIMEOUT
          );

          return;
        }

        NotificationManager.error(
          <ToastCustomContainer message="uiMessages.error" title={TOAST_TITLE} />,
          null,
          TOAST_TIMEOUT
        );
      } catch {
        NotificationManager.error(
          <ToastCustomContainer message="uiMessages.error" title={TOAST_TITLE} />,
          null,
          TOAST_TIMEOUT
        );
      } finally {
        if (typeof callback === 'function') {
          callback();
        }
        setSaving(false);
      }
    },
    [removeProcessor, setSaving, refetch, setFlow, flow?.name, flow?.id]
  );

  return { handleRemoveProcessor, loading };
};

export { useRemoveProcessorFromFlow };
