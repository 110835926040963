import React from 'react';

const Cards = () => {
  return (
    <svg width="157" height="124" viewBox="0 0 157 124" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_3996_110303)">
        <rect
          width="93.6572"
          height="32.4933"
          rx="6"
          transform="matrix(0.866044 -0.499967 0.866044 0.499967 45.4102 99.6221)"
          fill="white"
        />
        <rect
          x="0.519033"
          width="93.0579"
          height="31.894"
          rx="5.70034"
          transform="matrix(0.866044 -0.499967 0.866044 0.499967 45.4797 99.8816)"
          stroke="#E6E9EC"
          strokeWidth="0.599315"
        />
      </g>
      <rect
        width="43.0058"
        height="7.64548"
        rx="3.82274"
        transform="matrix(0.866044 -0.499967 0.866044 0.499967 90.104 86.2432)"
        fill="#E6E9EC"
      />
      <path
        d="M88.4686 97.6993L75.9936 104.901C75.3457 105.275 74.4944 105.501 73.627 105.529C72.7595 105.557 71.9469 105.385 71.3681 105.051L64.164 100.892C63.8732 100.728 63.6487 100.527 63.5034 100.303C63.3581 100.079 63.2949 99.835 63.3175 99.5858C63.34 99.3366 63.4478 99.0868 63.6347 98.851C63.8216 98.6152 64.0839 98.3979 64.4064 98.2117L76.908 90.9945C77.5563 90.6221 78.4067 90.3975 79.2725 90.3701C80.1384 90.3426 80.9491 90.5145 81.5269 90.8481L88.731 95.007C89.0233 95.1727 89.2486 95.3742 89.3938 95.5999C89.5391 95.8255 89.6013 96.0708 89.5769 96.3214C89.5525 96.572 89.4419 96.8229 89.2516 97.0594C89.0613 97.296 88.7951 97.5135 88.4686 97.6993ZM65.5991 98.9003C65.3052 99.0699 65.1277 99.2928 65.1056 99.52C65.0835 99.7471 65.2185 99.9598 65.4811 100.111L72.6851 104.27C72.9476 104.422 73.3162 104.5 73.7096 104.487C74.1031 104.474 74.4892 104.372 74.783 104.202L87.2847 96.985C87.5758 96.8151 87.7509 96.5928 87.7718 96.3666C87.7926 96.1404 87.6576 95.9286 87.396 95.7777L80.2099 91.6291C79.9484 91.4781 79.5817 91.4002 79.1898 91.4122C78.798 91.4242 78.4129 91.5254 78.1186 91.6934L65.5991 98.9003Z"
        fill="#E6E9EC"
      />
      <path
        d="M74.5704 100.049L72.6414 101.163C72.4644 101.265 72.2318 101.327 71.9948 101.334C71.7578 101.342 71.5358 101.295 71.3776 101.204C71.2194 101.112 71.1381 100.984 71.1514 100.847C71.1648 100.711 71.2717 100.576 71.4487 100.474L73.3777 99.3604C73.5547 99.2583 73.7873 99.1965 74.0243 99.1888C74.2614 99.1811 74.4834 99.2281 74.6415 99.3194C74.7997 99.4107 74.881 99.5389 74.8677 99.6757C74.8544 99.8125 74.7474 99.9468 74.5704 100.049Z"
        fill="#E6E9EC"
      />
      <path
        d="M78.7996 100.024L74.7347 102.371C74.5577 102.473 74.3251 102.535 74.088 102.542C73.851 102.55 73.629 102.503 73.4709 102.412C73.3127 102.32 73.2314 102.192 73.2447 102.055C73.258 101.919 73.365 101.784 73.542 101.682L77.6068 99.3354C77.7839 99.2332 78.0165 99.1715 78.2535 99.1638C78.4905 99.1561 78.7125 99.203 78.8707 99.2944C79.0288 99.3857 79.1102 99.5138 79.0969 99.6507C79.0835 99.7875 78.9766 99.9218 78.7996 100.024Z"
        fill="#E6E9EC"
      />
      <g filter="url(#filter1_d_3996_110303)">
        <rect
          width="93.6572"
          height="32.4933"
          rx="6"
          transform="matrix(0.866044 -0.499967 0.866044 0.499967 23.7051 73.7236)"
          fill="white"
        />
        <rect
          x="0.519033"
          width="93.0579"
          height="31.894"
          rx="5.70034"
          transform="matrix(0.866044 -0.499967 0.866044 0.499967 23.7746 73.9831)"
          stroke="#E6E9EC"
          strokeWidth="0.599315"
        />
      </g>
      <rect
        width="43.0058"
        height="7.64548"
        rx="3.82274"
        transform="matrix(0.866044 -0.499967 0.866044 0.499967 68.3989 60.3447)"
        fill="#E6E9EC"
      />
      <path
        d="M66.7635 71.8008L54.2886 79.0026C53.6407 79.3766 52.7893 79.6026 51.9219 79.6308C51.0544 79.659 50.2419 79.487 49.663 79.1529L42.4589 74.994C42.1682 74.8292 41.9436 74.6288 41.7983 74.4046C41.6531 74.1803 41.5899 73.9365 41.6124 73.6873C41.6349 73.4381 41.7427 73.1884 41.9296 72.9526C42.1165 72.7167 42.3788 72.4995 42.7013 72.3133L55.2029 65.0961C55.8513 64.7236 56.7016 64.4991 57.5674 64.4716C58.4333 64.4442 59.244 64.6161 59.8219 64.9497L67.0259 69.1086C67.3182 69.2742 67.5435 69.4758 67.6888 69.7014C67.834 69.9271 67.8962 70.1724 67.8718 70.423C67.8474 70.6735 67.7368 70.9244 67.5465 71.161C67.3563 71.3976 67.0901 71.615 66.7635 71.8008ZM43.894 73.0018C43.6002 73.1715 43.4226 73.3944 43.4005 73.6215C43.3784 73.8487 43.5134 74.0614 43.776 74.213L50.98 78.3719C51.2426 78.5234 51.6111 78.6014 52.0045 78.5886C52.398 78.5759 52.7841 78.4734 53.078 78.3037L65.5796 71.0865C65.8707 70.9167 66.0458 70.6943 66.0667 70.4681C66.0876 70.2419 65.9525 70.0302 65.691 69.8792L58.5048 65.7307C58.2433 65.5797 57.8766 65.5017 57.4848 65.5138C57.0929 65.5258 56.7078 65.6269 56.4136 65.795L43.894 73.0018Z"
        fill="#E6E9EC"
      />
      <path
        d="M52.8653 74.1506L50.9364 75.2642C50.7593 75.3664 50.5267 75.4281 50.2897 75.4358C50.0527 75.4435 49.8307 75.3965 49.6725 75.3052C49.5144 75.2139 49.433 75.0857 49.4463 74.9489C49.4597 74.8121 49.5666 74.6778 49.7436 74.5756L51.6726 73.462C51.8496 73.3598 52.0822 73.2981 52.3193 73.2904C52.5563 73.2827 52.7783 73.3297 52.9364 73.421C53.0946 73.5123 53.176 73.6404 53.1626 73.7773C53.1493 73.9141 53.0424 74.0484 52.8653 74.1506Z"
        fill="#E6E9EC"
      />
      <path
        d="M57.0945 74.1255L53.0296 76.4722C52.8526 76.5744 52.62 76.6361 52.383 76.6438C52.146 76.6515 51.924 76.6045 51.7658 76.5132C51.6076 76.4219 51.5263 76.2938 51.5396 76.1569C51.5529 76.0201 51.6599 75.8858 51.8369 75.7836L55.9018 73.437C56.0788 73.3348 56.3114 73.273 56.5484 73.2653C56.7854 73.2576 57.0074 73.3046 57.1656 73.3959C57.3238 73.4872 57.4051 73.6154 57.3918 73.7522C57.3784 73.889 57.2715 74.0233 57.0945 74.1255Z"
        fill="#E6E9EC"
      />
      <g filter="url(#filter2_d_3996_110303)">
        <rect
          width="93.6572"
          height="32.4933"
          rx="6"
          transform="matrix(0.866044 -0.499967 0.866044 0.499967 2 47.8252)"
          fill="white"
        />
        <rect
          x="0.519033"
          width="93.0579"
          height="31.894"
          rx="5.70034"
          transform="matrix(0.866044 -0.499967 0.866044 0.499967 2.06953 48.0847)"
          stroke="#E6E9EC"
          strokeWidth="0.599315"
        />
      </g>
      <rect
        width="43.0058"
        height="7.64548"
        rx="3.82274"
        transform="matrix(0.866044 -0.499967 0.866044 0.499967 46.6938 34.4463)"
        fill="#E6E9EC"
      />
      <path
        d="M45.0584 45.9024L32.5835 53.1042C31.9356 53.4782 31.0843 53.7042 30.2168 53.7323C29.3493 53.7605 28.5368 53.5886 27.9579 53.2544L20.7539 49.0955C20.4631 48.9307 20.2386 48.7304 20.0933 48.5061C19.948 48.2819 19.8848 48.0381 19.9073 47.7889C19.9298 47.5397 20.0377 47.29 20.2245 47.0541C20.4114 46.8183 20.6737 46.601 20.9962 46.4148L33.4979 39.1977C34.1462 38.8252 34.9965 38.6006 35.8624 38.5732C36.7282 38.5457 37.5389 38.7177 38.1168 39.0512L45.3208 43.2101C45.6131 43.3758 45.8385 43.5773 45.9837 43.803C46.1289 44.0287 46.1912 44.2739 46.1667 44.5245C46.1423 44.7751 46.0317 45.026 45.8415 45.2626C45.6512 45.4991 45.385 45.7166 45.0584 45.9024ZM22.189 47.1034C21.8951 47.273 21.7176 47.496 21.6954 47.7231C21.6733 47.9502 21.8083 48.163 22.0709 48.3145L29.2749 52.4734C29.5375 52.625 29.906 52.703 30.2995 52.6902C30.6929 52.6774 31.079 52.5749 31.3729 52.4053L43.8745 45.1881C44.1657 45.0182 44.3408 44.7959 44.3616 44.5697C44.3825 44.3435 44.2474 44.1318 43.9859 43.9808L36.7998 39.8322C36.5382 39.6813 36.1715 39.6033 35.7797 39.6153C35.3879 39.6274 35.0028 39.7285 34.7085 39.8965L22.189 47.1034Z"
        fill="#6D61FF"
      />
      <path
        d="M31.1603 48.2521L29.2313 49.3657C29.0543 49.4679 28.8216 49.5297 28.5846 49.5374C28.3476 49.5451 28.1256 49.4981 27.9675 49.4068C27.8093 49.3155 27.7279 49.1873 27.7413 49.0505C27.7546 48.9137 27.8615 48.7794 28.0386 48.6772L29.9675 47.5636C30.1446 47.4614 30.3772 47.3996 30.6142 47.3919C30.8512 47.3842 31.0732 47.4312 31.2314 47.5225C31.3895 47.6138 31.4709 47.742 31.4575 47.8788C31.4442 48.0156 31.3373 48.1499 31.1603 48.2521Z"
        fill="#6D61FF"
      />
      <path
        d="M35.3894 48.2271L31.3245 50.5737C31.1475 50.6759 30.9149 50.7377 30.6779 50.7454C30.4409 50.7531 30.2189 50.7061 30.0607 50.6148C29.9026 50.5235 29.8212 50.3953 29.8345 50.2585C29.8479 50.1217 29.9548 49.9874 30.1318 49.8852L34.1967 47.5385C34.3737 47.4363 34.6063 47.3746 34.8433 47.3669C35.0803 47.3592 35.3023 47.4062 35.4605 47.4975C35.6187 47.5888 35.7 47.717 35.6867 47.8538C35.6734 47.9906 35.5664 48.1249 35.3894 48.2271Z"
        fill="#6D61FF"
      />
      <defs>
        <filter
          id="filter0_d_3996_110303"
          x="43.6596"
          y="52.1565"
          width="112.753"
          height="69.1457"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.39726" />
          <feGaussianBlur stdDeviation="2.39726" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3996_110303" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3996_110303" result="shape" />
        </filter>
        <filter
          id="filter1_d_3996_110303"
          x="21.9545"
          y="26.258"
          width="112.753"
          height="69.1457"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.39726" />
          <feGaussianBlur stdDeviation="2.39726" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3996_110303" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3996_110303" result="shape" />
        </filter>
        <filter
          id="filter2_d_3996_110303"
          x="0.249424"
          y="0.359576"
          width="112.753"
          height="69.1457"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.39726" />
          <feGaussianBlur stdDeviation="2.39726" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3996_110303" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3996_110303" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default Cards;
