import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { string } from 'prop-types';
import { Info2 } from '../../../assets/icons';
import { StyledInfoIconWrapper } from './styled';

const useStyles = makeStyles(() => ({
  customTooltip: {
    margin: '6px 0',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    borderRadius: '8px',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '16px',
    padding: '12px 16px',
  },
}));

const StyledTooltip = ({ title }) => {
  const classes = useStyles();

  return (
    <Tooltip title={title} placement="top" classes={{ tooltip: classes.customTooltip }}>
      <StyledInfoIconWrapper>
        <Info2 />
      </StyledInfoIconWrapper>
    </Tooltip>
  );
};

StyledTooltip.propTypes = {
  title: string.isRequired,
};

export default StyledTooltip;
