import { useHandleConnectionConnect } from '../../../../../ConnectionsPage/hooks/useHandleConnectionConnect';
import { useFlowEditorContext } from '../../../../../../components/FlowEditor/context';

const avoidRedirect = true;

export const useHandleConnection = ({ connection, closeModal }) => {
  const { topFlowId } = useFlowEditorContext();
  const isNoCodeContext = Boolean(topFlowId);

  const { handleConnect, renderConnectionForm } = useHandleConnectionConnect({
    connection,
    avoidRedirect,
    closeModal,
    isNoCodeContext,
  });

  return { handleConnect, renderConnectionForm };
};
