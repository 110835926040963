import React from 'react';
import CustomSelect from '../SignUpCompanyDetailsForm/CustomSelect';

const OPTIONS = [
  { value: 'MARKETPLACE', text: { text: 'MARKETPLACE' } },
  { value: 'CROWDFUNDING', text: { text: 'CROWDFUNDING' } },
  { value: 'FRANCHISE', text: { text: 'FRANCHISE' } },
  { value: 'OTHER', text: { text: 'OTHER' } },
];

const BusinessTypeSelect = (props) => {
  return <CustomSelect {...props} options={OPTIONS} />;
};

export default BusinessTypeSelect;
