import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { transformNodeToRow } from '../../pages/DataTables/Disputes/tableData';
import { LIST_DISPUTES_GQL } from '../../utils/queries/disputes/listDisputes';
import { useNotificationManager } from '../useNotificationManager';
import { getPageInfo } from '../useGetPaymentList/getPageInfo';
import { getRows } from '../useGetPaymentList/getRows';

/**
 * @param {Object} options
 * */
const useGetDIsputesListWithFilter = (options) => {
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(LIST_DISPUTES_GQL, options);
  useNotificationManager('error', error?.message, t('Disputes'), 5000);

  const edges = data?.listPaymentDisputes?.edges;
  const pageInfoRef = data?.listPaymentDisputes?.pageInfo;

  const rows = useMemo(() => getRows({ edges, transformFn: transformNodeToRow }), [edges]);

  const pageInfo = useMemo(() => getPageInfo({ pageInfo: pageInfoRef }), [pageInfoRef]);

  return {
    loading,
    error,
    rows,
    source: 'FILTER',
    pageInfo,
    totalSize: pageInfoRef?.totalSize,
  };
};

export { useGetDIsputesListWithFilter };
