import isEmpty from 'lodash/isEmpty';
import { TOAST_TEXT_MAP } from '../components/ConnectionsItem';
import { useHandleConnectionConnect } from './useHandleConnectionConnect';
import { useUpdateConnectionData } from './useUpdateConnectionData';
import { TOAST_TIMEOUT, TOAST_TYPES } from '../../../constants/toasts';
import { showNotification } from '../../../hooks/useNotificationManager';
import { CONNECTION_TOAST_TITLE } from './constant';
import { CONNECTION_STATUS } from '../components/constant';

export const useUpdateConnectionHook = ({ connection, callback, setTabValue }) => {
  const { handleConnect, handleReconnect, renderConnectionForm } = useHandleConnectionConnect({ connection, callback });
  const { disconnect } = useUpdateConnectionData(connection?.id);

  const handleUpdate = (mutation, shouldResetTabValue) => {
    mutation({
      variables: { id: connection.id },
    }).then((res) => {
      if (res && !isEmpty(res.errors)) {
        showNotification(
          TOAST_TYPES.error,
          res.errors[0]?.message ?? 'uiMessages.error',
          CONNECTION_TOAST_TITLE,
          TOAST_TIMEOUT
        );

        return;
      }

      if (typeof setTabValue === 'function' && shouldResetTabValue) {
        setTabValue(0);
      }

      showNotification(
        TOAST_TYPES.success,
        `${connection?.company?.name} successfully ${TOAST_TEXT_MAP[connection?.status] ?? TOAST_TEXT_MAP.DEFAULT}.`,
        CONNECTION_TOAST_TITLE,
        TOAST_TIMEOUT
      );
    });
  };

  const statusActionHandlers = {
    [CONNECTION_STATUS.CONNECTED]: {
      event: () => handleUpdate(disconnect, true),
      label: 'Disconnect',
    },
    [CONNECTION_STATUS.BROKEN]: { event: () => handleReconnect(), label: 'Reconnect' },
    [CONNECTION_STATUS.ARCHIVED]: { event: () => handleReconnect(), label: 'Reconnect' },
    [CONNECTION_STATUS.NOT_CONNECTED]: { event: () => handleConnect(), label: 'Connect' },
    [CONNECTION_STATUS.ONBOARDING]: { event: () => handleConnect(), label: 'Complete' },
  };

  return { statusActionHandlers, renderConnectionForm, handleConnect };
};
