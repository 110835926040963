import styled from 'styled-components';
import { ButtonRounded } from '../../../../../../components/atoms';
import THEME from '../../../../../../constants/theme';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const SubmitEvidenceBox = styled(ButtonRounded)`
  &.MuiButton-textPrimary {
    border-radius: 8px;
    margin-top: 12px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    height: 48px;
    border: 1px solid ${THEME.greyColors.grey200};

    span {
      color: ${THEME.primaryColors.blue};
    }

    &:hover {
      background-color: ${THEME.primaryColors.primaryLight};
    }
  }
`;

const SubmissionsContainer = styled.div`
  border-radius: 8px;
  border: 1px solid ${THEME.greyColors.grey200};
  overflow: hidden;
`;

export { StyledWrapper, SubmitEvidenceBox, SubmissionsContainer };
