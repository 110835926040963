import { useCallback } from 'react';
import { fieldsUpdatedAfterSave } from './saveConfigUtils';

export const useOnSaveCompleted = ({ setNodes, setChangesMade, refetchConfigurationIssues }) =>
  useCallback(
    (newData) => {
      const newSteps = newData?.saveConfiguration?.steps;
      if (setChangesMade) {
        setChangesMade(true);
      }
      refetchConfigurationIssues();
      setNodes((prevNodes) =>
        prevNodes.map((node) => {
          const step = newSteps?.find((s) => s.id === node.id);
          // to avoid inconsistency between sequential saveConfig requests
          // we have elements as source of truth for current elements state and
          // update only those that came from request
          if (step) {
            return {
              ...node,
              data: {
                ...node.data,
                ...fieldsUpdatedAfterSave(step),
                changed: step?.changed,
              },
            };
          }
          return node;
        })
      );
    },
    [refetchConfigurationIssues, setChangesMade, setNodes]
  );
