import React from 'react';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { arrayOf, shape, string } from 'prop-types';
import { L12UM } from '../atoms/Typography/L12UM';
import THEME from '../../constants/theme';
import { P12 } from '../atoms';
import IssueItem from './IssueItem';

const GroupWrapper = styled(Box)`
  padding: 16px 24px;
  &:first-child {
    padding-top: 24px;
  }
  &:not(:first-child) {
    border-top: 1px solid ${THEME.greyColors.grey200};
  }
  &:last-child {
    padding-bottom: 24px;
  }
`;

const ItemsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const IssuesGroup = ({ label, subLabel, items }) => {
  return (
    <GroupWrapper>
      <L12UM color={THEME.secondaryColors.nodeError} margin="0 0 8px 0">
        {label}
      </L12UM>
      <P12 margin="0 0 8px 0">{subLabel}</P12>
      <ItemsWrapper>
        {items?.map((item) => (
          <IssueItem label={item.name} item={item} />
        ))}
      </ItemsWrapper>
    </GroupWrapper>
  );
};

IssuesGroup.propTypes = {
  label: string.isRequired,
  items: arrayOf(shape({ name: string, group: string })).isRequired,
  subLabel: string,
};

IssuesGroup.defaultProps = {
  subLabel: null,
};

export default IssuesGroup;
