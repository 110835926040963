import { Node } from '../components/Node';
import { StartTestNode } from '../components/Node/StartTestNode';
import { CompletedTestNode } from '../components/Node/CompletedTestNode';
import { UserDecisionNode } from '../components/Node/UserDecisionNode';
import { AnnotationNode } from '../components/Node/AnnotationNode';
import { RetryNode } from '../components/Node/RetryNode';

export const nodeTypesMap = {
  basic: Node,
  startTest: StartTestNode,
  completedTest: CompletedTestNode,
  userDecision: UserDecisionNode,
  annotation: AnnotationNode,
  retry: RetryNode,
};
