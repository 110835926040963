import { DISPUTE_FIELDS } from './disputeFields';

const hiddenByDefault = [
  DISPUTE_FIELDS.id,
  DISPUTE_FIELDS.teamId,
  DISPUTE_FIELDS.paymentId,
  DISPUTE_FIELDS.sourceId,
  DISPUTE_FIELDS.processor,
  DISPUTE_FIELDS.currency,
  DISPUTE_FIELDS.processorStatus,
];

const INITIAL_TABLE_STATE = {
  tableState: {
    columnResizing: {
      columnWidths: [],
    },
    columnOrder: [],
    sortBy: [],

    hiddenColumns: hiddenByDefault,
  },
};

const transformHiddenColumnsFn = (state) => state?.hiddenFields?.fields?.map((item) => item?.value) ?? hiddenByDefault;

export { INITIAL_TABLE_STATE, transformHiddenColumnsFn, hiddenByDefault };
