import { useCallback } from 'react';
import { applyNodeChanges } from 'react-flow-renderer';
import omit from 'lodash/omit';
import { flowStepTriggerTypes, flowStepTypes } from '../types';
import { mergeDeep } from '../../../utils/mergeDeep';
import { filterNodes } from '../utils/filterElements';
import { repopulateEdges } from '../utils/facades';

export const useOnNodesRemove = (getRemoveNodeConfirmation) =>
  useCallback(
    async ({ nodesToRemove, selectedNodeId, setSelectedNodeId, setElementsAndSave }) => {
      const triggerNode = nodesToRemove.find((el) => flowStepTriggerTypes.includes(el?.data?.__typename));
      let isCancelled = true;
      // getting confirmation about trigger node deletion
      if (triggerNode) {
        isCancelled = await getRemoveNodeConfirmation();
      }
      let nodeToRemoveIDs = nodesToRemove.map(({ id }) => id);
      if (!isCancelled) {
        nodeToRemoveIDs = nodeToRemoveIDs.filter((id) => id !== triggerNode.id);
      }
      /* close selected */
      if (nodeToRemoveIDs.includes(selectedNodeId)) {
        setSelectedNodeId(null);
      }
      /* save configuration */
      setElementsAndSave((prevEls) => {
        const prevNodes = filterNodes(prevEls);
        const changes = nodeToRemoveIDs.map((id) => ({ id, type: 'remove' }));
        const res = applyNodeChanges(changes, prevNodes);
        return res.reduce((acc, node) => {
          const { data: nodeData } = node;
          const typename = nodeData?.__typename;
          let source;
          if (nodeData) {
            ['nextStepId', 'trueStepId', 'falseStepId', 'elseStepId'].forEach((key) => {
              const stepIdValue = nodeData[key];
              if (!stepIdValue || !nodeToRemoveIDs.includes(stepIdValue)) {
                return;
              }
              source = source ?? { data: {} };
              source.data[key] = null;
            });
            // filter removed conditions from DataConditionStep
            if (
              (typename === flowStepTypes.DataConditionStep || typename === flowStepTypes.CountConditionStep) &&
              nodeData?.conditions?.length
            ) {
              source = source ?? { data: {} };
              source.data.conditions = nodeData?.conditions?.filter(
                (condition) => !nodeToRemoveIDs.includes(condition.nextStepId)
              );
            }
            // handle remove AB conditions
            if (typename === flowStepTypes.ABTestConditionStep) {
              const { aBranchCondition } = nodeData;
              const { bBranchCondition } = nodeData;
              source = source ?? { data: {} };
              if (nodeToRemoveIDs.includes(aBranchCondition?.nextStepId)) {
                source.data.aBranchCondition = aBranchCondition;
                source.data.aBranchCondition.nextStepId = null;
              }
              if (nodeToRemoveIDs.includes(bBranchCondition?.nextStepId)) {
                source.data.bBranchCondition = bBranchCondition;
                source.data.bBranchCondition.nextStepId = null;
              }
            }
          }
          const updatedNode = {
            ...node,
            data: {
              ...node.data,
              layout: {
                ...node.data?.layout,
                uiMetadata: omit(nodeData?.layout?.uiMetadata, nodeToRemoveIDs),
              },
            },
          };
          if (source && source.data?.conditions) {
            return repopulateEdges([
              ...acc,
              mergeDeep({ ...updatedNode, data: { ...node.data, conditions: null } }, source),
            ]);
          }
          if (source) {
            return repopulateEdges([...acc, mergeDeep(updatedNode, source)]);
          }
          return repopulateEdges([...acc, updatedNode]);
        }, []);
      });
    },
    [getRemoveNodeConfirmation]
  );
