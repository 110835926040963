import * as Yup from 'yup';

const LETTERS_ONLY_VALIDATOR = Yup.string()
  .trim()
  .matches(/^[a-zA-Z\s]*$/, 'No special characters or numbers allowed');

const DOMAIN_VALIDATOR = Yup.string()
  .trim()
  .matches(/(^$|^[a-z0-9]+([-]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$)/i, 'Invalid domain format');

const URL_VALIDATOR = Yup.string()
  .trim()
  .matches(
    /^(http|https):\/\/([\w-]+\.)+[\w-]+(\/[\w\u002E\u003C\u003E]*)*(#[\w-]+)*(\?[^\s]+)*$|^$/,
    'Invalid URL format'
  );

const NUMBER_VALIDATOR = Yup.string()
  .trim()
  .matches(/(^$|^\d+$)/, 'Input must be a number');

const FOUR_NUMBER_DIGIT_VALIDATOR = Yup.string()
  .trim()
  .matches(/(^$|^\d{4}$)/, 'Input must be a 4-digit number');

export { LETTERS_ONLY_VALIDATOR, DOMAIN_VALIDATOR, NUMBER_VALIDATOR, FOUR_NUMBER_DIGIT_VALIDATOR, URL_VALIDATOR };
