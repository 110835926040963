import { useQuery } from '@apollo/client';
import { GET_CONNECTION_FEE } from '../../../utils/queries/connections/connectionsQueries';

const useGetConnectionFee = (id) => {
  const { data, loading, error } = useQuery(GET_CONNECTION_FEE, {
    variables: {
      id,
    },
  });

  return {
    fee: data?.getConnectionFee || {},
    loading,
    error,
  };
};

export { useGetConnectionFee };
