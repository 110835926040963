import React from 'react';
import {
  ItemRend,
  ItemType,
  ItemDate,
  ItemStatus,
  ItemAmount,
  ItemButtons,
  ItemCheckbox,
} from '../components/common/TableItems';
import {
  ItemInvoice as TItemInvoice,
  ItemProduct as TItemProduct,
  ItemRequester as TItemRequester,
  ItemDate as TItemDate,
  ItemStatus as TItemStatus,
  ItemAmount as TItemAmount,
  ItemAmount2 as TItemAmount2,
  ItemButtons as TtemButtons,
  ItemReceiptStatus as TItemReceiptStatus,
  ItemProgramme as TItemProgramme,
} from '../components/common/Transactions/TransactionsItems';

import {
  ItemName as PItemName,
  ItemEmail as PItemEmail,
  ItemRole as PItemRole,
  ItemStatus as PItemStatus,
  ItemButtons as PItemButtons,
} from '../components/common/PeopleItem/PeopleItem';

import PeopleNameHeadCell from '../pages/People/PeopleNameHeadCell';

export const TRANSACTIONS_TABLE = [
  {
    id: 'checkbox',
    align: 'left',
    disablePadding: false,
    label: '',
    width: '40px',
    minWidth: '40px',
    componentRender: (props) => <ItemCheckbox {...props} />,
  },
  {
    id: 'merchant',
    numeric: false,
    align: 'left',
    disablePadding: true,
    label: 'Item',
    long: true,
    width: '255',
    componentRender: (props) => <TItemProduct {...props} />,
  },
  {
    id: 'programmeName',
    numeric: false,
    align: 'center',
    disablePadding: true,
    label: 'Item',
    long: true,
    width: '255',
    componentRender: (props) => <TItemProgramme {...props} />,
  },
  {
    id: 'requester',
    numeric: false,
    align: 'center',
    disablePadding: true,
    label: 'Item',
    long: true,
    width: '255',
    componentRender: (props) => <TItemRequester {...props} />,
  },
  {
    id: 'amount',
    align: 'center',
    disablePadding: false,
    label: 'Amount',
    width: '130',
    minWidth: '130px',
    componentRender: (props) => <TItemAmount {...props} />,
  },
  {
    id: 'receipt',
    align: 'right',
    disablePadding: true,
    label: 'Status',
    width: '240',
    minWidth: '240px',
    componentRender: (props) => <TItemReceiptStatus showText {...props} />,
  },
];

export const PENDING_TABLE = [
  {
    id: 'merchant',
    numeric: false,
    align: 'left',
    disablePadding: true,
    label: 'Item',
    long: true,
    width: '320',
    componentRender: (props) => <ItemRend {...props} />,
  },
  {
    id: 'type',
    numeric: false,
    align: 'center',
    disablePadding: false,
    label: 'Type',
    width: '280',
    customClass: 'table-cell-mobile-row-padded',
    componentRender: (props) => <ItemType {...props} />,
  },
  {
    id: 'date',
    numeric: true,
    align: 'left',
    disablePadding: false,
    label: 'Date',
    width: '120',
    customClass: 'hidden-sm',
    componentRender: (props) => <ItemDate {...props} />,
  },
  {
    id: 'amount',
    align: 'left',
    disablePadding: false,
    label: 'Amount',
    width: '110',
    minWidth: '110px',
    customClass: 'hidden-sm',
    componentRender: (props) => <ItemAmount {...props} />,
  },
  {
    id: 'buttons',
    align: 'center',
    disablePadding: false,
    label: '',
    width: '180',
    minWidth: '180px',
    componentRender: (props) => <ItemButtons {...props} />,
  },
];

export const APPROVED_TABLE = [
  {
    id: 'merchant',
    numeric: false,
    align: 'left',
    disablePadding: true,
    label: 'Item',
    long: true,
    width: '320',
    componentRender: (props) => <ItemRend {...props} />,
  },
  {
    id: 'type',
    numeric: false,
    align: 'left',
    disablePadding: false,
    label: 'Type',
    width: '320',
    customClass: 'hidden-sm',
    componentRender: (props) => <ItemType {...props} />,
  },
  {
    id: 'date',
    numeric: true,
    align: 'left',
    disablePadding: false,
    label: 'Date',
    width: '120',
    customClass: 'hidden-sm',
    componentRender: (props) => <ItemDate {...props} />,
  },
  {
    id: 'amount',
    align: 'left',
    disablePadding: false,
    label: 'Amount',
    width: '110',
    minWidth: '110px',
    componentRender: (props) => <ItemAmount {...props} />,
  },
  {
    id: 'status',
    numeric: true,
    align: 'left',
    disablePadding: false,
    label: 'Status',
    minWidth: '140px',
    customClass: 'hidden-sm',
    componentRender: (props) => <ItemStatus {...props} />,
  },
];

export const DECLINED_TABLE = [
  {
    id: 'merchant',
    numeric: false,
    align: 'left',
    disablePadding: true,
    label: 'Item',
    long: true,
    width: '320',
    componentRender: (props) => <ItemRend {...props} />,
  },
  {
    id: 'type',
    numeric: false,
    align: 'left',
    disablePadding: false,
    label: 'Type',
    width: '320',
    customClass: 'hidden-sm',
    componentRender: (props) => <ItemType {...props} />,
  },
  {
    id: 'date',
    numeric: true,
    align: 'left',
    disablePadding: false,
    label: 'Date',
    width: '120',
    customClass: 'hidden-sm',
    componentRender: (props) => <ItemDate {...props} />,
  },
  {
    id: 'amount',
    align: 'left',
    disablePadding: false,
    label: 'Amount',
    width: '110',
    minWidth: '110px',
    componentRender: (props) => <ItemAmount {...props} />,
  },
  {
    id: 'status',
    numeric: true,
    align: 'left',
    disablePadding: false,
    label: 'Status',
    width: '110',
    minWidth: '140px',
    customClass: 'hidden-sm',
    componentRender: (props) => <ItemStatus {...props} />,
  },
];

export const FUTURE_TRANSACTIONS_TABLE = [
  {
    id: 'merchant',
    numeric: false,
    align: 'left',
    disablePadding: true,
    label: 'Item',
    long: true,
    width: '255',
    componentRender: (props) => <ItemRend {...props} />,
  },
  {
    id: 'type',
    numeric: false,
    align: 'left',
    disablePadding: false,
    label: 'Type',
    width: '225',
    customClass: 'hidden-sm',
    componentRender: (props) => <ItemType {...props} />,
  },
  {
    id: 'date',
    numeric: true,
    align: 'left',
    disablePadding: false,
    label: 'Date',
    width: '120',
    customClass: 'hidden-sm',
    componentRender: (props) => <ItemDate {...props} />,
  },
  {
    id: 'status',
    numeric: true,
    align: 'left',
    disablePadding: false,
    label: 'Status',
    width: '175',
    customClass: 'hidden-sm',
    componentRender: (props) => <ItemStatus {...props} />,
  },
  {
    id: 'amount',
    align: 'left',
    disablePadding: false,
    label: 'Amount',
    width: '130',
    minWidth: '130px',
    componentRender: (props) => <ItemAmount {...props} />,
  },
  {
    id: 'buttons',
    align: 'right',
    disablePadding: true,
    label: '',
    width: '60',
    componentRender: (props) => <ItemButtons {...props} />,
  },
];

export const PEOPLE_TABLE = [
  {
    id: 'name',
    align: 'left',
    disablePadding: false,
    label: (props) => <PeopleNameHeadCell {...props} />,
    componentRender: (props) => <PItemName {...props} />,
  },
  {
    id: 'email',
    align: 'left',
    disablePadding: false,
    label: 'Email',
    componentRender: (props) => <PItemEmail {...props} />,
  },
  {
    id: 'role',
    align: 'left',
    disablePadding: false,
    label: 'Role',
    componentRender: (props) => <PItemRole {...props} />,
  },
  {
    id: 'status',
    align: 'left',
    disablePadding: false,
    label: 'Status',
    customClass: 'hidden-sm',
    componentRender: (props) => <PItemStatus {...props} />,
  },
  {
    id: 'buttons',
    align: 'right',
    disablePadding: false,
    label: '',
    width: '34px',
    componentRender: (props) => <PItemButtons {...props} />,
  },
];

export const INVOICES_TABLE = [
  {
    id: 'invoice',
    align: 'left',
    disablePadding: false,
    label: 'Invoice',
    componentRender: (props) => <TItemInvoice {...props} />,
  },
  {
    id: 'date',
    align: 'left',
    disablePadding: false,
    label: 'Date',
    componentRender: (props) => <TItemDate {...props} />,
  },
  {
    id: 'status',
    align: 'left',
    disablePadding: false,
    label: 'Status',
    customClass: 'hidden-sm',
    componentRender: (props) => <TItemStatus {...props} />,
  },
  {
    id: 'amount',
    align: 'left',
    disablePadding: false,
    label: '',
    componentRender: (props) => <TItemAmount2 {...props} />,
  },
  {
    id: 'buttons',
    align: 'right',
    disablePadding: false,
    label: '',
    componentRender: (props) => <TtemButtons {...props} />,
  },
];
