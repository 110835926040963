import React from 'react';
import { Box } from '@mui/material';
import { string } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Skeleton from '@mui/lab/Skeleton';
import isArray from 'lodash/isArray';
import useFieldsValues from './hooks/useFieldsValues';
import Select from '../../../../../forms/_common/Select';
import { StyledFormControl } from './styled';
import { useNodeDataToSave } from './hooks/useNodeDataToSave';
import THEME from '../../../../../../constants/theme';
import TextEditorInput from './InputByType/TextEditorInput';

const DataSetSelect = ({ dataKey, type }) => {
  const [{ filters }, updateDataToSave] = useNodeDataToSave();

  const { items, options, getAvailableProperties, loading } = useFieldsValues(dataKey, type);

  const currentItemKey = dataKey === 'date' ? 'dateRange' : dataKey;

  const onChange = ({ target }) => {
    updateDataToSave(
      dataKey !== 'date'
        ? {
            filters: {
              ...filters,
              [dataKey]: {
                value: target?.value,
                selectedVerb: isArray(target?.value) ? 'CONTAINS' : 'IS_EQUAL',
              },
            },
          }
        : {
            filters: {
              ...filters,
              dateRange: {
                selectedVerb: target?.value,
                value: Intl.DateTimeFormat().resolvedOptions().timeZone,
              },
            },
          }
    );
  };

  return (
    <StyledFormControl $mt="8px" $padding={(isEmpty(items) || loading) && '0px'}>
      {!isEmpty(items) && !loading ? (
        <Select
          value={
            filters && isArray(filters[currentItemKey]?.value)
              ? filters[currentItemKey]?.value
              : (filters && filters[currentItemKey]?.selectedVerb) || []
          }
          options={options || []}
          onChange={onChange}
          multiple={dataKey !== 'date'}
          hideNone
        />
      ) : (
        !loading && (
          <TextEditorInput
            border={`1px solid ${THEME.greyColors.grey8}`}
            onChange={onChange}
            name={dataKey}
            value={filters && filters[currentItemKey]?.value}
            properties={getAvailableProperties}
            showToolbar={false}
            minHeight="none"
          />
        )
      )}
      {loading && (
        <Box borderRadius="8px">
          <Skeleton height="40px" />
        </Box>
      )}
    </StyledFormControl>
  );
};

DataSetSelect.propTypes = {
  dataKey: string.isRequired,
  type: string.isRequired,
};

export default DataSetSelect;
