const signUpCompanyDetailsNames = {
  companyName: 'companyName',
  companySize: 'companySize',
  companyIndustry: 'companyIndustry',
  companyIndustryOther: 'companyIndustryOther',
};

const signUpCompanyDetailsFields = [
  { field: signUpCompanyDetailsNames.companyName, type: 'text', label: 'Company Name' },
  { field: signUpCompanyDetailsNames.companySize, label: 'Company Size' },
  { field: signUpCompanyDetailsNames.companyIndustry, label: 'Which best fits your industry?' },
];

const initialValues = {
  [signUpCompanyDetailsNames.companyName]: '',
  [signUpCompanyDetailsNames.companySize]: '',
  [signUpCompanyDetailsNames.companyIndustry]: '',
  [signUpCompanyDetailsNames.companyIndustryOther]: '',
};

export { initialValues, signUpCompanyDetailsFields, signUpCompanyDetailsNames };
