import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useApolloClient } from '@apollo/client';
import { useToggle } from 'react-use';
import { useGetConnectionDetails } from '../hooks/useGetConnectionDetails';
import useSearch from '../../../hooks/useSearch';
import { useUpdateConnectionHook } from '../hooks/useUpdateConnectionHook';
import TakeAction from '../../../assets/img/TakeAction.svg';
import DetailsConnectBlock from './components/DetailsConnectBlock';
import DetailsContentBlock from './components/DetailsContentBlock';
import ConnectionDetailsSkeleton from './Skeleton';
import { StyledWrapper } from './styled';
import { FlexContainer } from '../../../components/atoms/flex/FlexContainer';
import Header from './Header';
import IconBoxScreen from '../../../components/common/IconBoxScreen/IconBoxScreen';
import WebhookConfigurationModal from '../components/WebhookConfigurationModal';
import { isDefined } from '../../../utils/helpers';

const ConnectionDetails = () => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const {
    params: { id },
  } = useRouteMatch();
  const { connection, loading, error, refetch } = useGetConnectionDetails(id);
  const [searchParams, setSearchParams] = useSearch();
  const [shouldModalOpen, setShouldModalOpen] = useToggle(false);
  const [tabValue, setTabValue] = useState(0);
  const [configForm, setConfigForm] = useState(null);

  const isPaymentConnection = connection?.type?.includes('PAYMENT_GATEWAY');

  const noData = useMemo(() => {
    return !loading && (error || Object.keys(connection)?.length === 0);
  }, [connection, loading, error]);

  const callback = async () => {
    refetch();

    await client.refetchQueries({
      include: ['getConnections'],
    });
  };

  const { statusActionHandlers, handleConnect, renderConnectionForm } = useUpdateConnectionHook({
    connection,
    callback,
    setTabValue,
  });

  useEffect(() => {
    if (searchParams?.connect && connection.id) {
      setSearchParams({}, true);
      handleConnect();
    }
  }, [searchParams, connection, handleConnect, setSearchParams]);

  return (
    <FlexContainer
      backgroundColor="#fff"
      alignItems="flex-start"
      flexDirection="column"
      flex={1}
      width="100%"
      justifyContent="flex-start"
    >
      <Box width="100%">
        <Header
          connection={connection}
          secondary={connection?.company?.name}
          statusActionHandlers={statusActionHandlers}
          isPaymentConnection={isPaymentConnection}
        />
      </Box>

      <StyledWrapper>
        {loading && <ConnectionDetailsSkeleton />}
        {noData && (
          <IconBoxScreen
            icon={<img src={TakeAction} alt="" />}
            iconMargin="0"
            description={t('connections.fetchingError')}
            padding="40px 82px"
            margin="105px auto"
            buttonText={error ? t('connections.retry') : null}
            onButtonClick={() => refetch()}
          />
        )}

        {!loading && !noData && (
          <Box m="60px 0" width="100%">
            <DetailsConnectBlock
              statusActionHandlers={statusActionHandlers}
              connection={connection}
              form={configForm}
              setConfigForm={setConfigForm}
              setShouldModalOpen={setShouldModalOpen}
            />

            <DetailsContentBlock
              connection={connection}
              tabValue={tabValue}
              setTabValue={setTabValue}
              isPaymentConnection={isPaymentConnection}
            />
          </Box>
        )}
      </StyledWrapper>

      {renderConnectionForm()}

      {shouldModalOpen && isDefined(configForm) && (
        <WebhookConfigurationModal
          setShouldModalOpen={setShouldModalOpen}
          shouldModalOpen={shouldModalOpen}
          connection={connection}
          goBack={setShouldModalOpen}
          form={configForm}
        />
      )}
    </FlexContainer>
  );
};

export default ConnectionDetails;
