import React, { useState } from 'react';
import { string, func, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { NotificationManager } from 'react-notifications';
import isEmpty from 'lodash/isEmpty';
import { StyledWrapper, StyledSecondaryButton } from './styled';
import { ACCEPT_DISPUTE } from '../../../../../../utils/queries/disputes/listDisputes';
import { useGlobalContext } from '../../../../../../containers/App/context';
import { getOperationNameForQuery } from '../../../../../../utils/graphql';
import { GET_PAYMENT } from '../../../../../../utils/queries/payments/paymentsQueries';
import { MODAL_TYPES } from '../FormDrawer/ConfirmationDialog/constant';
import ToastCustomContainer from '../../../../../../components/ToastCustomContainer';
import { TOAST_TIMEOUT } from '../../../../../../constants/toasts';

const TOAST_TITLE = 'Accept dispute.';

const ActionButtons = ({
  showAcceptDisputeButton,
  showSubmitEvidenceButton,
  setShouldDrawerOpen,
  connectionId,
  disputeId,
  setModalData,
  setShouldModalOpen,
  setDisabled,
}) => {
  const { t } = useTranslation();
  const { setGlobalLoading } = useGlobalContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { issueId, issueDetails } = useParams();

  const [acceptDispute] = useMutation(ACCEPT_DISPUTE, {
    onCompleted: () => {
      setShouldDrawerOpen(false);
    },
    refetchQueries: [getOperationNameForQuery(GET_PAYMENT(issueId || issueDetails))],
  });

  const handleOpenDrawer = () => {
    setShouldDrawerOpen(true);
  };

  const handleAcceptDispute = () => {
    setGlobalLoading('acceptDispute', true);
    setIsSubmitting(true);
    setDisabled(true);

    if (isSubmitting) {
      return;
    }

    acceptDispute({ variables: { connectionId, disputeId } })
      .then(({ data }) => {
        if (isEmpty(data.acceptDispute)) {
          NotificationManager.error(
            <ToastCustomContainer message="An unexpected error occured." title={TOAST_TITLE} />,
            null,
            TOAST_TIMEOUT
          );
        }
      })
      .finally(() => {
        setGlobalLoading('acceptDispute', false);
        setIsSubmitting(false);
      });
  };

  const handleAcceptDisputeClick = () => {
    setShouldModalOpen(true);
    setModalData({
      type: MODAL_TYPES.acceptDispute,
      action: handleAcceptDispute,
    });
  };

  return (
    <StyledWrapper>
      <StyledSecondaryButton variant="contained" color="secondary">
        {t('Email Customer')}
      </StyledSecondaryButton>

      {showAcceptDisputeButton && (
        <StyledSecondaryButton variant="contained" color="secondary" onClick={handleAcceptDisputeClick}>
          {t('Accept Dispute')}
        </StyledSecondaryButton>
      )}

      {showSubmitEvidenceButton && (
        <StyledSecondaryButton type="button" variant="contained" color="primary" onClick={handleOpenDrawer}>
          {t('Submit Evidence')}
        </StyledSecondaryButton>
      )}
    </StyledWrapper>
  );
};

ActionButtons.propTypes = {
  showAcceptDisputeButton: bool.isRequired,
  showSubmitEvidenceButton: bool.isRequired,
  connectionId: string.isRequired,
  disputeId: string.isRequired,
  setShouldDrawerOpen: func.isRequired,
  setModalData: func.isRequired,
  setShouldModalOpen: func.isRequired,
  setDisabled: func.isRequired,
};

export default ActionButtons;
