import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import isEmpty from 'lodash/isEmpty';
import { isNode } from 'react-flow-renderer';
import { NotificationManager } from 'react-notifications';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useToggle } from 'react-use';
import ButtonWithIcon from '../atoms/Buttons/ButtonWithIcon';
import THEME from '../../constants/theme';
import { useFlowEditorContext } from '../FlowEditor/context';
import { DELETE_FLOW, GQL_M_DISABLE_FLOW, GQL_M_PUBLISH_CONFIGURATION } from '../../utils/queries/flows/mutations';
import OptionsMenu from '../atoms/OptionsMenu';
import { StyledToggleButton, StyledToggleGroup, VerticalLine } from './styled';
import ManageFlowConfirmationModal from '../../pages/FlowDetailsPage/Header/ManageFlowConfirmationModal';
import { MODAL_KEYS } from '../../pages/FlowDetailsPage/Header/ManageFlowConfirmationModal/constant';
import { DRAFT_FLOW_STATUS, LIVE_FLOW_STATUS } from './constants';
import { TOAST_TIMEOUT, TOAST_TITLE } from '../../pages/FlowDetailsPage/hooks/constant';
import { UI_ROUTES } from '../../constants/routes';
import { findExeption } from '../../pages/AutomationTemplatePage/constant';
import { GET_FLOW_LIST } from '../../utils/queries/flows/flowsQueries';
import TestCantBeUsedDialog from './TestCantBeUsedDialog';
import BlankAutomationDialog from './BlankAutomationDialog';
import VersionControl from './VersionControl';
import { getOperationNameForQuery } from '../../utils/graphql';
import { GET_VERSION_HISTORY_FOR_FLOW } from '../../utils/queries/flows/queries';
import ToastCustomContainer from '../ToastCustomContainer';

const UPGRADE_PLAN_NEEDED = 'error.payment.flow.plan.insufficient';

const ActionButtons = ({ openErrors }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    flowId,
    flowName,
    flowStatus,
    isInstruct,
    refetch,
    nodes,
    setChangesMade,
    toggleShowUpgradeModal,
    setPushedStart,
    refetchConfigurationIssues,
  } = useFlowEditorContext();
  const [activeTab, setActiveTab] = useState(null);
  const [testModalOpen, setTestModalOpen] = useState(false);
  const [blankModalOpen, setBlankModalOpen] = useState(false);

  useEffect(() => {
    if (history?.location?.pathname) {
      const array = history.location.pathname.split('/');
      const path = array[array.length - 1];
      setActiveTab(path);
    }
  }, [history.location.pathname]);

  const [publishFlow, { error: publishError, loading }] = useMutation(GQL_M_PUBLISH_CONFIGURATION, {
    context: { skipGlobalHandling: true },
  });
  const [deleteFlow, { loading: deleteLoading }] = useMutation(DELETE_FLOW, {
    context: { skipGlobalHandling: true },
    refetchQueries: [{ query: GET_FLOW_LIST }],
  });
  const [disableAutomation] = useMutation(GQL_M_DISABLE_FLOW);

  const isValid = !nodes.filter(isNode).some((n) => n?.data?.validationErrors?.length > 0);
  const handleTabChange = (event, newTab) => {
    if (newTab) {
      const path = [...history.location.pathname.split('/')];
      const newPath = path.slice(0, -1).join('/');
      if (newTab === 'test') {
        if (isInstruct) {
          setTestModalOpen(true);
          return;
        }
        if (!isValid) {
          openErrors();
          return;
        }
      }
      setActiveTab(newTab);
      history.push(`${newPath}/${newTab}`);
    }
  };

  useEffect(() => {
    if (publishError && publishError?.message === UPGRADE_PLAN_NEEDED) {
      toggleShowUpgradeModal();
    }
  }, [publishError, toggleShowUpgradeModal]);

  const [deleteModal, toggleDeleteModal] = useToggle(false);

  const otherOptions = useMemo(() => [{ onClick: toggleDeleteModal, label: 'Delete' }], [toggleDeleteModal]);

  const isAutomationScreen = useMemo(() => DRAFT_FLOW_STATUS.has(flowStatus) || LIVE_FLOW_STATUS.has(flowStatus), [
    flowStatus,
  ]);

  const onClickPublish = useCallback(async () => {
    if (!isValid) {
      setPushedStart(true);
      await refetchConfigurationIssues();
      openErrors();
      return;
    }
    if (!nodes?.length) {
      setBlankModalOpen(true);
      return;
    }
    try {
      const data = await publishFlow({
        variables: {
          flowId,
        },
      });
      if (!isEmpty(data.errors)) {
        const exception = findExeption(data?.errors);
        if (exception === UPGRADE_PLAN_NEEDED) {
          return;
        }
        NotificationManager.error(
          <ToastCustomContainer message={data.errors[0].message} title="uiMessages.failed" />,
          null,
          TOAST_TIMEOUT
        );

        return;
      }
      if (setChangesMade) {
        setChangesMade(false);
      }
      NotificationManager.success(
        <ToastCustomContainer message="uiMessages.automationPublished" title="uiMessages.success" />,
        null,
        TOAST_TIMEOUT
      );

      await refetch({ id: flowId });
    } catch (error) {
      NotificationManager.error(
        <ToastCustomContainer message="uiMessages.failed" title="Oops.." />,
        null,
        TOAST_TIMEOUT
      );
    }
  }, [
    isValid,
    nodes?.length,
    setPushedStart,
    refetchConfigurationIssues,
    openErrors,
    publishFlow,
    flowId,
    setChangesMade,
    refetch,
  ]);

  const handleMutation = useCallback(
    async (fn, field) => {
      try {
        const data = await fn({
          variables: {
            flowId,
          },
          refetchQueries: [getOperationNameForQuery(GET_VERSION_HISTORY_FOR_FLOW)],
        });
        if (!isEmpty(data.errors)) {
          NotificationManager.error(
            <ToastCustomContainer message={data.errors[0].message} title="uiMessages.failed" />,
            null,
            TOAST_TIMEOUT
          );
          return;
        }
        if (!data?.data[field]) {
          NotificationManager.error(
            <ToastCustomContainer message="uiMessages.failed" title="Oops.." />,
            null,
            TOAST_TIMEOUT
          );
        } else {
          if (setChangesMade) {
            setChangesMade(false);
          }
          if (field === 'deleteFlow') {
            NotificationManager.success(
              <ToastCustomContainer message={`Successfully deleted ${flowName}.`} title={TOAST_TITLE} />,
              null,
              TOAST_TIMEOUT
            );

            history.push(UI_ROUTES.automations);
            return;
          }
          refetch();
        }
      } catch {
        NotificationManager.error(
          <ToastCustomContainer message="uiMessages.failed" title="Oops.." />,
          null,
          TOAST_TIMEOUT
        );
      }
    },
    [flowId, flowName, setChangesMade, refetch, history]
  );

  const handleStopFlow = async () => {
    await handleMutation(disableAutomation, 'disableFlow');
  };

  return (
    <Box display="flex" alignItems="center" height="100%">
      {isAutomationScreen && (
        <Box mr="8px" ml="8px">
          {DRAFT_FLOW_STATUS.has(flowStatus) && (
            <ButtonWithIcon
              text="Start"
              color={THEME.primaryColors.white}
              bgColor={THEME.secondaryColors.greenDark}
              onClick={onClickPublish}
              disabled={loading}
              p="8px 18px"
            />
          )}
          {LIVE_FLOW_STATUS.has(flowStatus) && (
            <ButtonWithIcon
              text="Stop"
              color={THEME.primaryColors.white}
              bgColor={THEME.secondaryColors.nodeError}
              onClick={handleStopFlow}
              disabled={loading}
              p="8px 18px"
            />
          )}
        </Box>
      )}

      {flowStatus && <VerticalLine />}

      <StyledToggleGroup value={activeTab} onChange={handleTabChange} $hasPadding={isAutomationScreen} exclusive>
        <StyledToggleButton value="editor">{t('Build')}</StyledToggleButton>
        <StyledToggleButton value="test">{t('Test')}</StyledToggleButton>
      </StyledToggleGroup>

      {isAutomationScreen ? <VerticalLine $marginRight="8px" /> : null}

      <VersionControl handlePublish={onClickPublish} />

      {isAutomationScreen ? (
        <>
          <Box mr="8px">
            <OptionsMenu
              options={otherOptions}
              lastItemDanger
              width="120px"
              bgcolor="#fff"
              color={THEME.greyColors.grey17}
              vertical
              hoverColor={THEME.greyColors.grey24}
            />
          </Box>
          <ManageFlowConfirmationModal
            open={deleteModal}
            onConfirm={() => handleMutation(deleteFlow, 'deleteFlow')}
            onCancel={toggleDeleteModal}
            flowName={flowName}
            modalKey={MODAL_KEYS.delete}
            loading={deleteLoading}
          />
          <TestCantBeUsedDialog open={testModalOpen} setOpen={setTestModalOpen} />
          <BlankAutomationDialog open={blankModalOpen} setOpen={setBlankModalOpen} />
        </>
      ) : null}
    </Box>
  );
};

ActionButtons.propTypes = {
  openErrors: func.isRequired,
};

export default ActionButtons;
