const signUpFieldsNames = {
  firstName: 'name',
  lastName: 'lastName',
  companyEmail: 'companyEmail',
  companyRole: 'companyRole',
  // promoCode: 'promoCode',
  acceptedTC: 'acceptedTC',
};

const initialValues = {
  [signUpFieldsNames.firstName]: '',
  [signUpFieldsNames.lastName]: '',
  [signUpFieldsNames.companyEmail]: '',
  [signUpFieldsNames.companyRole]: '',
  // [signUpFieldsNames.promoCode]: '',
  [signUpFieldsNames.acceptedTC]: false,
};

const signUpFields = [
  { type: 'text', field: signUpFieldsNames.firstName, label: 'First Name' },
  { type: 'text', field: signUpFieldsNames.lastName, label: 'Last Name' },
  { type: 'email', field: signUpFieldsNames.companyEmail, label: 'Work email' },
  { type: 'select', field: signUpFieldsNames.companyRole, label: 'Company Role' },
  // { type: 'text', field: signUpFieldsNames.promoCode, label: 'Promo Code' },
];

const COMPANY_ROLE_OPTIONS = [
  {
    value: 'FOUNDER_OR_OWNER_OR_CEO',
    text: { text: 'Founder / Owner / CEO' },
  },
  {
    value: 'FINANCE',
    text: { text: 'Finance' },
  },
  {
    value: 'MARKETING',
    text: { text: 'Marketing' },
  },
  {
    value: 'REVENUE',
    text: { text: 'Revenue' },
  },
  {
    value: 'PAYMENTS_AND_FRAUD',
    text: { text: 'Payments and Fraud' },
  },
  {
    value: 'PRODUCT',
    text: { text: 'Product' },
  },
  {
    value: 'ENGINEERING',
    text: { text: 'Engineering' },
  },
  {
    value: 'CUSTOMER_SUPPORT_SUCCESS',
    text: { text: 'Customer Support/Success' },
  },
];

export { initialValues, signUpFieldsNames, signUpFields, COMPANY_ROLE_OPTIONS };
