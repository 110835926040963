import React from 'react';

const ChevronDownBlackMini = () => (
  <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.3335 1.66669L4.00016 3.66669L6.66683 1.66669"
      stroke="black"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default ChevronDownBlackMini;
