import React from 'react';

const WeChat = () => {
  return (
    <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="34" height="24" rx="4" fill="white" />
      <rect
        x="0.25"
        y="0.25"
        width="33.5"
        height="23.5"
        rx="3.75"
        stroke="black"
        strokeOpacity="0.15"
        strokeWidth="0.5"
      />
      <path
        d="M15.1902 13.8559C15.1222 13.892 15.0458 13.9129 14.9644 13.9129C14.7762 13.9129 14.6125 13.8038 14.5266 13.6423L14.4937 13.5664L13.1231 10.3968C13.1083 10.3623 13.0992 10.3233 13.0992 10.2854C13.0992 10.1393 13.2116 10.021 13.3502 10.021C13.4064 10.021 13.4585 10.0406 13.5003 10.0734L15.1177 11.287C15.2359 11.3684 15.377 11.4162 15.5288 11.4162C15.6194 11.4162 15.7058 11.3984 15.7863 11.3675L23.3924 7.80001C22.029 6.10671 19.7836 5 17.2427 5C13.0847 5 9.71429 7.96007 9.71429 11.6118C9.71429 13.6038 10.7283 15.3971 12.3156 16.6093C12.4429 16.7051 12.5263 16.8625 12.5263 17.0401C12.5263 17.0989 12.5143 17.1525 12.4997 17.2085C12.373 17.7068 12.1701 18.5049 12.1607 18.5423C12.1448 18.6048 12.1201 18.6702 12.1201 18.7356C12.1201 18.8817 12.2324 19 12.3712 19C12.4256 19 12.4702 18.9786 12.5161 18.9508L14.1643 17.9479C14.2882 17.8727 14.4195 17.8258 14.5642 17.8258C14.6411 17.8258 14.7154 17.8384 14.7853 17.8609C15.5542 18.094 16.3838 18.2235 17.2428 18.2235C21.4005 18.2235 24.7714 15.2633 24.7714 11.6118C24.7714 10.5057 24.4604 9.46407 23.9138 8.54777L15.2454 13.8223L15.1902 13.8559Z"
        fill="#1AAD19"
      />
    </svg>
  );
};

export default WeChat;
