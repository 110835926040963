import React from 'react';
import { useMutation } from '@apollo/client';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';

import { NotificationManager } from 'react-notifications';
import { SAVE_BRAND } from '../utils/queries/brandCenter/brandCenterMutations';
import { GET_BRAND } from '../utils/queries/brandCenter/brandCenterQueries';

import { useGlobalContext } from '../containers/App/context';
import { TOAST_TIMEOUT } from '../constants/toastTimeout';
import ToastCustomContainer from '../components/ToastCustomContainer';

const mutateOptions = {
  refetchQueries: [{ query: GET_BRAND }],
  awaitRefetchQueries: true,
};

const TOAST_TITLE = 'Brand Center.';
const useBrandCenterMutation = () => {
  const [mutateBrand] = useMutation(SAVE_BRAND, mutateOptions);
  const { setGlobalLoading } = useGlobalContext();

  const mutateData = async (newObject, title) => {
    if (!newObject) return;

    setGlobalLoading('brandCenter', true);
    try {
      const result = await mutateBrand({ variables: omit(newObject, ['__typename']) });
      const hasFailed = !isEmpty(result?.errors) && !result?.data?.saveBrand;

      if (hasFailed) {
        NotificationManager.error(
          <ToastCustomContainer message={result.errors[0]?.message} title={TOAST_TITLE} />,
          null,
          TOAST_TIMEOUT
        );

        return;
      }

      NotificationManager.success(
        <ToastCustomContainer message={`${title} successfully updated.`} title={TOAST_TITLE} />,
        null,
        TOAST_TIMEOUT
      );
    } catch {
      NotificationManager.error(
        <ToastCustomContainer message="uiMessages.error" title={TOAST_TITLE} />,
        null,
        TOAST_TIMEOUT
      );
    } finally {
      setGlobalLoading('brandCenter', false);
    }
  };

  return { mutateData };
};

export { useBrandCenterMutation };
