import styled from 'styled-components';

export const StyledWrapper = styled.div`
  margin-top: 56px;
  @media screen and (min-width: 1650px) {
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
  }
`;
