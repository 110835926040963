import * as Yup from 'yup';
import buildValidator from '../../../utils/validators/buildValidator';
import { formNames } from './schema';

const stringValidationSchema = (requiredMessage) => Yup.string().required(requiredMessage);

const validator = Yup.object().shape({
  [formNames.transactionsAmount]: stringValidationSchema('This field is required'),
});

export default buildValidator(validator);
