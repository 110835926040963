import React from 'react';

const ModerateDisputesAndRefund = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22Z"
        fill="#C44975"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5 16.375C24.5 16.2092 24.4342 16.0503 24.3169 15.9331C24.1997 15.8158 24.0408 15.75 23.875 15.75H13.875C13.7092 15.75 13.5503 15.8158 13.4331 15.9331C13.3158 16.0503 13.25 16.2092 13.25 16.375V27.625C13.25 27.7908 13.3158 27.9497 13.4331 28.0669C13.5503 28.1842 13.7092 28.25 13.875 28.25H23.875C24.0408 28.25 24.1997 28.1842 24.3169 28.0669C24.4342 27.9497 24.5 27.7908 24.5 27.625V25.125C24.5 24.9592 24.5658 24.8003 24.6831 24.6831C24.8003 24.5658 24.9592 24.5 25.125 24.5C25.2908 24.5 25.4497 24.5658 25.5669 24.6831C25.6842 24.8003 25.75 24.9592 25.75 25.125V27.625C25.75 28.1223 25.5525 28.5992 25.2008 28.9508C24.8492 29.3025 24.3723 29.5 23.875 29.5H13.875C13.3777 29.5 12.9008 29.3025 12.5492 28.9508C12.1975 28.5992 12 28.1223 12 27.625V16.375C12 15.8777 12.1975 15.4008 12.5492 15.0492C12.9008 14.6975 13.3777 14.5 13.875 14.5H23.875C24.3723 14.5 24.8492 14.6975 25.2008 15.0492C25.5525 15.4008 25.75 15.8777 25.75 16.375V18.875C25.75 19.0408 25.6842 19.1997 25.5669 19.3169C25.4497 19.4342 25.2908 19.5 25.125 19.5C24.9592 19.5 24.8003 19.4342 24.6831 19.3169C24.5658 19.1997 24.5 19.0408 24.5 18.875V16.375Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1824 22.4426C17.1242 22.3845 17.078 22.3155 17.0465 22.2396C17.015 22.1637 16.9988 22.0823 16.9988 22.0001C16.9988 21.9178 17.015 21.8364 17.0465 21.7605C17.078 21.6846 17.1242 21.6156 17.1824 21.5576L20.9324 17.8076C21.0498 17.6902 21.2089 17.6243 21.3749 17.6243C21.5409 17.6243 21.7 17.6902 21.8174 17.8076C21.9348 17.9249 22.0007 18.0841 22.0007 18.2501C22.0007 18.416 21.9348 18.5752 21.8174 18.6926L19.1336 21.3751H30.1249C30.2907 21.3751 30.4496 21.4409 30.5668 21.5581C30.684 21.6753 30.7499 21.8343 30.7499 22.0001C30.7499 22.1658 30.684 22.3248 30.5668 22.442C30.4496 22.5592 30.2907 22.6251 30.1249 22.6251H19.1336L21.8174 25.3076C21.8755 25.3657 21.9216 25.4347 21.9531 25.5106C21.9845 25.5865 22.0007 25.6679 22.0007 25.7501C22.0007 25.8322 21.9845 25.9136 21.9531 25.9895C21.9216 26.0655 21.8755 26.1344 21.8174 26.1926C21.7593 26.2507 21.6903 26.2968 21.6144 26.3282C21.5385 26.3597 21.4571 26.3758 21.3749 26.3758C21.2927 26.3758 21.2113 26.3597 21.1354 26.3282C21.0595 26.2968 20.9905 26.2507 20.9324 26.1926L17.1824 22.4426Z"
        fill="white"
      />
    </svg>
  );
};

export default ModerateDisputesAndRefund;
