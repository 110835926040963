import React from 'react';
import { Box } from '@mui/material';
import { shape, string } from 'prop-types';
import NodeIcon from '../FlowEditor/components/NodeLibrary/NodeIcon';
import { L10UM } from '../atoms/Typography/L10UM';
import THEME from '../../constants/theme';

const IssueItem = ({ label, item }) => (
  <Box display="flex" alignItems="center">
    <NodeIcon type={item?.group?.toLowerCase()} nodeData={item} size={24} mr="8px" iconSize="xs" />
    <L10UM textTransform="uppercase" color={THEME.greyColors.grey600}>
      {label}
    </L10UM>
  </Box>
);

IssueItem.propTypes = {
  label: string.isRequired,
  item: shape({ group: string }).isRequired,
};

export default IssueItem;
