import styled from 'styled-components';
import THEME from '../../../constants/theme';

export const BoxWrapper = styled.div`
  position: relative;
`;

export const Box = styled.div`
  color: black;
  position: relative;
  /* border-radius: 12px; */
  /* background-color: white; */
  padding: 32px 24px;
  z-index: 1;
  max-width: 592px;
  width: 100vw;
  box-sizing: border-box;

  @media (min-width: ${THEME.breakPoints.tablet}px) {
    padding: 40px;
    max-width: 592px;
  }

  h1 {
    font-size: 32px;
    line-height: 120%;
    margin-bottom: 10px;
    margin-top: 0 !important;
    font-weight: 700 !important;
    @media (min-width: ${THEME.breakPoints.desktop}px) {
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 14px;
    }
  }
`;
