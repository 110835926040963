import styled from 'styled-components';
import FormControlLabel from '@mui/material/FormControlLabel';
import { selectTypes } from './types';
import THEME from '../../../../../../constants/theme';

export const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin: 0;
    padding: 8px !important;
    border-radius: 4px;
    height: 38px;
    background-color: ${({ selected, checked }) => (selected || checked ? '#f5f2ff' : THEME.primaryColors.white)};
    &:first-child {
      margin-top: ${({ selectAll }) => (selectAll ? '2px' : '0')};
    }
    & + & {
      margin-top: ${({ $selectType }) => ($selectType === selectTypes.people ? '2px' : '2px')};
    }
    .MuiFormControlLabel-label,
    .MuiFormControlLabel-label p {
      margin-left: 12px;
      font-size: 12px !important;
      font-weight: 600;
      color: ${({ selected, checked }) =>
        selected || checked ? THEME.primaryColors.purpleMain : THEME.primaryColors.black};
    }

    .MuiRadio-root {
      padding: 0;
    }
  }
`;
