import React from 'react';

const Paypal = () => {
  return (
    <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="34" height="24" rx="4" fill="white" />
      <rect
        x="0.25"
        y="0.25"
        width="33.5"
        height="23.5"
        rx="3.75"
        stroke="black"
        strokeOpacity="0.15"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6142 18.4483L14.8349 16.9992L14.3432 16.9873H11.9953L13.627 6.2937C13.6321 6.26132 13.6485 6.23126 13.6725 6.20987C13.6967 6.18849 13.7274 6.17676 13.7596 6.17676H17.7185C19.033 6.17676 19.94 6.45939 20.4135 7.01734C20.6355 7.27908 20.7769 7.55267 20.8453 7.85364C20.9171 8.16951 20.9183 8.54685 20.8483 9.00715L20.8432 9.04063V9.33561L21.0652 9.46563C21.2521 9.56815 21.4008 9.68546 21.5147 9.81975C21.7046 10.0436 21.8274 10.3281 21.8793 10.6652C21.933 11.012 21.9153 11.4248 21.8274 11.892C21.7261 12.4295 21.5624 12.8976 21.3412 13.2805C21.1378 13.6334 20.8787 13.9262 20.5709 14.153C20.2771 14.3686 19.9281 14.5322 19.5335 14.6369C19.1511 14.7398 18.715 14.7917 18.2368 14.7917H17.9287C17.7085 14.7917 17.4945 14.8737 17.3265 15.0207C17.158 15.1708 17.0467 15.3758 17.0125 15.6L16.9892 15.7305L16.5992 18.2848L16.5816 18.3785C16.5768 18.4082 16.5688 18.423 16.557 18.433C16.5465 18.4422 16.5313 18.4483 16.5165 18.4483H14.6142Z"
        fill="#28356A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2757 9.07471C21.2639 9.15279 21.2504 9.23258 21.2352 9.31458C20.7131 12.0852 18.9269 13.0424 16.6456 13.0424H15.4841C15.2051 13.0424 14.9699 13.2517 14.9265 13.5362L14.1634 18.5394C14.1351 18.7263 14.2744 18.8945 14.4567 18.8945H16.5169C16.7608 18.8945 16.968 18.7113 17.0064 18.4627L17.0267 18.3545L17.4146 15.8103L17.4395 15.6708C17.4775 15.4213 17.6852 15.238 17.9291 15.238H18.2372C20.2331 15.238 21.7957 14.4004 22.2524 11.9766C22.4431 10.9641 22.3444 10.1186 21.8396 9.52402C21.6868 9.34476 21.4973 9.19592 21.2757 9.07471Z"
        fill="#298FC2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7293 8.84968C20.6495 8.82561 20.5672 8.80386 20.4828 8.78419C20.3979 8.765 20.311 8.74802 20.2215 8.73311C19.9083 8.68081 19.5651 8.65601 19.1975 8.65601H16.0945C16.018 8.65601 15.9454 8.67385 15.8805 8.70611C15.7373 8.77722 15.6311 8.91726 15.6053 9.0887L14.9451 13.4102L14.9262 13.5362C14.9696 13.2517 15.2047 13.0424 15.4838 13.0424H16.6453C18.9266 13.0424 20.7128 12.0848 21.2349 9.31463C21.2505 9.23264 21.2636 9.15285 21.2753 9.07477C21.1433 9.00231 21.0002 8.94035 20.8462 8.88756C20.8081 8.87449 20.7689 8.8619 20.7293 8.84968Z"
        fill="#22284F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6055 9.08862C15.6312 8.91718 15.7375 8.77715 15.8807 8.70652C15.9461 8.67414 16.0182 8.6563 16.0947 8.6563H19.1977C19.5653 8.6563 19.9085 8.68123 20.2217 8.73353C20.3111 8.74831 20.398 8.76542 20.4829 8.7846C20.5673 8.80415 20.6496 8.82603 20.7294 8.84998C20.769 8.8622 20.8083 8.8749 20.8467 8.88749C21.0008 8.94028 21.1439 9.00272 21.276 9.07469C21.4313 8.05082 21.2747 7.3537 20.7391 6.72245C20.1486 6.0274 19.0829 5.72998 17.7192 5.72998H13.7601C13.4816 5.72998 13.2439 5.9393 13.2009 6.22426L11.5519 17.0279C11.5194 17.2416 11.6789 17.4344 11.8874 17.4344H14.3316L15.6055 9.08862Z"
        fill="#28356A"
      />
    </svg>
  );
};

export default Paypal;
