import { useQuery, useLazyQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GET_TEAMS, SEARCH_TEAMS } from '../../../../utils/queries/teams/queries';
import { useNotificationManager } from '../../../../hooks/useNotificationManager';
import useSearch from '../../../../hooks/useSearch';
import { parseBySort, API_MAP } from './constant';
import { TOAST_TIMEOUT } from '../../../../constants/toasts';

const ROWS_PER_PAGE = 8;
const TOAST_TITLE = 'Customer list';

const getPageInfo = (pageInfo = {}) => {
  const endCursor = parseInt(pageInfo?.endCursor, 10);
  const totalPages = Math.ceil(pageInfo?.totalSize / ROWS_PER_PAGE);

  return {
    endCursor,
    hasNextPage: pageInfo?.hasNextPage,
    hasPreviousPage: pageInfo?.hasPreviousPage,
    totalPages,
  };
};

const useGetCustomers = (isSubmit, touched) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearch();
  const { searchTeam, sort } = searchParams;

  const searchInput = searchTeam?.toString();

  const [data, setData] = useState({});
  const [api, setApi] = useState(API_MAP.getTeams);
  const [clearPage, setClearPage] = useState(false);

  const [searchTeams, { loading: searchLoading }] = useLazyQuery(SEARCH_TEAMS);

  const after = searchParams?.page ? searchParams.page - 1 : 0;

  const { error, loading, data: getTeamsData } = useQuery(GET_TEAMS, {
    variables: { cursor: { max: ROWS_PER_PAGE, page: after } },
  });

  const getSearchTeams = useCallback(async () => {
    const { data: searchTeamsData } = await searchTeams({
      variables: { input: searchInput, cursor: { max: ROWS_PER_PAGE, page: after } },
    });

    setData(searchTeamsData);
  }, [searchInput, searchTeams, after]);

  useEffect(() => {
    if (!searchInput && getTeamsData) {
      setData(getTeamsData);
      setApi((prevApi) => {
        if (prevApi === API_MAP.searchTeams) {
          setClearPage(true);
        }
        return API_MAP.getTeams;
      });
    }

    if ((!touched && !isEmpty(searchInput)) || (isSubmit && !isEmpty(searchInput))) {
      getSearchTeams();
      setApi(API_MAP.searchTeams);
    }
  }, [getTeamsData, isSubmit, searchInput, getSearchTeams, touched, setApi]);

  useEffect(() => {
    if (clearPage) {
      setSearchParams((prevSearchParams) => omit(prevSearchParams, 'page'));
    }
  }, [clearPage, setSearchParams]);

  useNotificationManager('error', t(error?.message), TOAST_TITLE, TOAST_TIMEOUT);

  const customers = useMemo(() => {
    const _data = data?.[api]?.teams ?? [];

    if (sort) {
      return parseBySort(_data, sort);
    }

    return _data;
  }, [data, sort, api]);

  const pageInfo = useMemo(() => {
    return getPageInfo(data?.[api]?.pageInfo) ?? {};
  }, [data, api]);

  return { error, loading, searchLoading, customers, pageInfo };
};

export { ROWS_PER_PAGE, getPageInfo, useGetCustomers };
