import React from 'react';

const BarThree = () => {
  return (
    <svg width="128" height="4" viewBox="0 0 128 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="128" height="4" fill="#E6E9EC" />
    </svg>
  );
};

export default BarThree;
