import isNil from 'lodash/isNil';
import THEME from '../../../constants/theme';

const NODE_BORDER = {
  Default: `2px solid transparent`,
  Tests: `2px solid ${THEME.primaryColors.purpleMain}`,
  Success: `2px solid ${THEME.secondaryColors.greenDark}`,
  Error: `2px solid ${THEME.secondaryColors.nodeError}`,
  Monitor: `2px solid ${THEME.primaryColors.blackPlain}`,
};

export const getNodeBorder = ({
  nodeId,
  isValid,
  selectedNodeId,
  entered,
  exited,
  isAutomationTest,
  isFlowMonitor,
  isStepLog,
  isLogsError,
  isLogsSuccess,
  isSavedNode,
  isErrorState,
  isCurrentError,
  changed,
}) => {
  if (isFlowMonitor) {
    if (!isStepLog) {
      return NODE_BORDER.Default;
    }
    if (isLogsError && changed) {
      return NODE_BORDER.Error;
    }
    if (isLogsSuccess) {
      return NODE_BORDER.Success;
    }
    if (!isNil(entered) && !isNil(exited)) {
      return NODE_BORDER.Monitor;
    }
  }
  if (isSavedNode) {
    return NODE_BORDER.Success;
  }
  if (selectedNodeId === nodeId) {
    if (isAutomationTest) {
      return NODE_BORDER.Tests;
    }
  }
  if ((!isValid || isErrorState || isCurrentError) && changed) {
    return NODE_BORDER.Error;
  }
  return NODE_BORDER.Default;
};
