import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';

import OnboardingLayout from '../../components/layouts/OnboardingLayout';
import OnboardingLayoutBox from '../../components/layouts/OnboardingLayoutBox';
import SignUpRevenueModelForm from '../../components/forms/SignUpRevenueModelForm';
import { useHandleRegistrationFlowRedirect, useRegistrationStorage } from '../../hooks/registration';
// import StepIndicatorOnboarding from '../../components/common/StepIndicatorOnboarding/StepIndicatorOnboarding';

const Fallback = () => (
  <Box display="flex" justifyContent="center">
    <CircularProgress size={24} />
  </Box>
);

const SignUpRevenueModel = () => {
  const { t } = useTranslation();
  const [registrationSession] = useRegistrationStorage();
  const acceptingPayments = registrationSession?.COMPANY_DETAILS?.acceptingPayments === 'YES';
  // const paymentProcessor = registrationSession?.COMPANY_DETAILS?.paymentProcessor === 'YES';
  useHandleRegistrationFlowRedirect();

  return (
    <OnboardingLayout theme="dark">
      <OnboardingLayoutBox theme="dark">
        <Box mb="40px">
          {/* <StepIndicatorOnboarding
            currentStep={acceptingPayments || paymentProcessor ? 8 : 7}
            stepsCount={acceptingPayments || paymentProcessor ? 9 : 8}
          /> */}
          <h1>{t(acceptingPayments ? 'What is your revenue model?' : 'What will your revenue model be?')}</h1>
        </Box>
        <React.Suspense fallback={<Fallback />}>
          <SignUpRevenueModelForm />
        </React.Suspense>
      </OnboardingLayoutBox>
    </OnboardingLayout>
  );
};

export default SignUpRevenueModel;
