import styled from 'styled-components';
import Box from '@mui/material/Box';
import THEME from '../../../../../../../constants/theme';
import { StyledBox } from '../NewCondition/styled';

export const TestsBox = styled(Box)`
  border: ${({ $tests }) => ($tests ? `1px solid ${THEME.greyColors.grey200}` : 'none')};
  border-bottom: none;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

export const AddNewTestBox = styled(StyledBox)`
  border: 1px solid ${THEME.greyColors.grey200};
  height: 56px;
  width: 100%;
  border-radius: 8px;
  border-top-left-radius: ${({ $tests }) => ($tests ? 0 : '8px')};
  border-top-right-radius: ${({ $tests }) => ($tests ? 0 : '8px')};
  cursor: pointer;
  button {
    font-size: 14px;
    width: 100%;
    height: 100%;
  }
`;
