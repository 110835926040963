import React, { useState } from 'react';
import { arrayOf, shape, string, number, bool, func } from 'prop-types';
import Box from '@mui/material/Box';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import HeadCellDropDown from './DropDownMenu';
import { Checkbox } from '../../atoms';
import {
  StyledHeadContent,
  StyledTableCell,
  StyledDropIndicator,
  StyledResizer,
  // StyledHeaderControlLabel,
} from './styled';
import { getItemStyle } from '../constants';
import { DnDHandle } from '../../../assets/icons';
import useTableContext from '../TableContext';
import ToastCustomContainer from '../../ToastCustomContainer';
import { TOAST_TIMEOUT } from '../../../constants/toasts';

const HeadCell = ({
  id,
  disablePadding,
  children,
  role,
  provided,
  snapshot,
  resizerProps,
  dragIndex,
  index,
  headCellDropdownOptions,
  noDnd,
}) => {
  const [showDropDownButton, setShowDropDownButton] = useState(false);
  // const isAmountHeadCell = id === 'amount';
  const {
    localHiddenColumn,
    setLocalHiddenColumn,
    checkboxEditMode,
    tableState,
    disableHideColumns,
  } = useTableContext();
  const isVisibleColumn = !localHiddenColumn?.includes(id);
  const columnWidths = tableState?.columnResizing?.columnWidths;
  const cellWidth = columnWidths[id] ?? 150;
  const dragActive = snapshot?.isDragging;
  const isSourceActive = dragActive && provided?.draggableProps?.['data-rbd-draggable-id'] === id;
  const destinationActive = index === dragIndex?.destination;
  const isGreater = dragIndex?.destination >= dragIndex?.source;

  const handleChange = (event) => {
    if (disableHideColumns) {
      NotificationManager.info(
        <ToastCustomContainer message="At least one visible column is required." title="Edit columns." />,
        null,
        TOAST_TIMEOUT
      );

      return;
    }
    const columnId = event.target.value;
    const hiddenLocally = localHiddenColumn.includes(columnId);
    /**if already hidden, remove  : add */
    if (hiddenLocally) {
      const editedColumns = localHiddenColumn.filter((column) => column !== columnId);
      setLocalHiddenColumn(editedColumns);
    } else {
      setLocalHiddenColumn((prevHiddenColumns) => [...prevHiddenColumns, columnId]);
    }
  };

  const handleMouseEnter = () => setShowDropDownButton(true);
  const handleMouseLeave = () => setShowDropDownButton(false);

  return (
    <StyledTableCell
      issourceactive={isSourceActive ? 1 : 0}
      role={role}
      align="left"
      padding={disablePadding ? 'none' : 'default'}
      currentcellwidth={`${cellWidth}px`}
      className="head-cell"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <StyledHeadContent
        {...provided?.draggableProps}
        ref={provided?.innerRef}
        style={{
          ...getItemStyle(snapshot, provided?.draggableProps?.style),
        }}
        isDragging={snapshot?.isDragging}
      >
        {!noDnd && (
          <div className="dragHandle" {...provided?.dragHandleProps} draggable="true">
            <DnDHandle />
          </div>
        )}
        <Box display="flex" alignItems="center">
          {checkboxEditMode && (
            <Box mr="6px" ml="-4px">
              <Checkbox type="checkbox" onChange={handleChange} checked={isVisibleColumn} value={id} />
            </Box>
          )}
          <div className="headerControl">
            {children}
            {/* {isAmountHeadCell ? (
              <StyledHeaderControlLabel $isAmountHeadCell={isAmountHeadCell}>{children}</StyledHeaderControlLabel>
            ) : (
              children
            )} */}

            {!checkboxEditMode && !dragActive && showDropDownButton && (
              <HeadCellDropDown id={id} headCellDropdownOptions={headCellDropdownOptions} noDnd={noDnd} />
            )}
          </div>
        </Box>
      </StyledHeadContent>

      {!isGreater && !noDnd && (
        <StyledResizer className="resizer" {...resizerProps()}>
          &nbsp;
        </StyledResizer>
      )}
      <StyledDropIndicator className="dropIndicator" destinationActive={destinationActive} isGreater={isGreater}>
        &nbsp;
      </StyledDropIndicator>
    </StyledTableCell>
  );
};

HeadCell.propTypes = {
  id: string,
  index: number.isRequired,
  label: string,
  role: string,
  disablePadding: bool,
  provided: shape({
    draggableProps: shape({
      style: shape({}),
      'data-rbd-draggable-id': string,
    }),
    dragHandleProps: shape({}),
    innerRef: func,
  }).isRequired,
  isActive: bool,
  snapshot: shape({
    isDragging: bool,
  }).isRequired,
  resizerProps: func.isRequired,
  dragIndex: shape({
    destination: number,
    source: number,
  }).isRequired,
  headCellDropdownOptions: arrayOf(shape({})),
  noDnd: bool,
};

HeadCell.defaultProps = {
  disablePadding: false,
  isActive: false,
  label: '',
  id: '',
  role: '',
  headCellDropdownOptions: null,
  noDnd: false,
};

export default HeadCell;
