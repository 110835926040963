import React from 'react';

const Crypto = () => {
  return (
    <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="34" height="24" rx="4" fill="white" />
      <rect
        x="0.25"
        y="0.25"
        width="33.5"
        height="23.5"
        rx="3.75"
        stroke="black"
        strokeOpacity="0.15"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.87 11.935C24.87 16.3174 21.3174 19.87 16.935 19.87C12.5526 19.87 9 16.3174 9 11.935C9 7.55262 12.5526 4 16.935 4C21.3174 4 24.87 7.55262 24.87 11.935ZM18.8414 8.98687C19.9451 9.36591 20.7526 9.93347 20.5942 10.9897C20.4791 11.7632 20.0488 12.1373 19.4769 12.2682C20.2614 12.6751 20.5288 13.4471 20.2804 14.3807C19.8087 15.7242 18.6874 15.8373 17.1961 15.5565L16.834 17.0008L15.9599 16.7835L16.317 15.3586C16.0904 15.3025 15.8587 15.2425 15.6202 15.1785L15.2615 16.6103L14.3884 16.393L14.75 14.9458L12.9892 14.5033L13.4235 13.5061C13.4235 13.5061 14.0681 13.6762 14.0591 13.6638C14.3067 13.7248 14.4167 13.5641 14.4601 13.4574L15.4413 9.53757C15.4523 9.35251 15.388 9.11933 15.0339 9.03102C15.0473 9.0216 14.3988 8.87375 14.3988 8.87375L14.6314 7.94352L16.3947 8.37862L16.7533 6.9478L17.627 7.1651L17.2758 8.56764C17.5109 8.62073 17.747 8.67481 17.9767 8.73186L18.3253 7.33825L19.1995 7.55555L18.8414 8.98687ZM16.7497 11.3737C17.3452 11.5315 18.6409 11.8751 18.8668 10.9743C19.0972 10.0528 17.8383 9.77447 17.2219 9.63819C17.1521 9.62275 17.0905 9.60913 17.0402 9.59661L16.6049 11.336C16.6464 11.3463 16.6951 11.3592 16.7497 11.3736L16.7497 11.3737ZM16.075 14.1742C16.7882 14.362 18.3481 14.7729 18.5963 13.7804C18.8503 12.7656 17.338 12.4276 16.6 12.2626L16.6 12.2626C16.5175 12.2442 16.4448 12.228 16.3852 12.2131L15.9051 14.1302C15.9538 14.1422 16.0109 14.1573 16.0748 14.1741L16.075 14.1742Z"
        fill="#F7931A"
      />
    </svg>
  );
};

export default Crypto;
