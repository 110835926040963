import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNodeDataToSave } from './fields/hooks/useNodeDataToSave';
import RadioButtonsSection from './sections/RadioButtonsSection';
import { useValidationMessage } from './fields/hooks';
import NewConditions from './fields/NewConditions';
import Title from '../Title';

const COUNT_SCOPE = {
  WITHIN_RUN: 'WITHIN_RUN',
  ACROSS_RUNS: 'ACROSS_RUNS',
};

const ACTION_SCOPE = {
  RESET: 'RESET',
  CONTINUE: 'CONTINUE',
};

const countScopeOptions = [
  {
    value: COUNT_SCOPE.ACROSS_RUNS,
    title: 'Across all runs',
  },
  {
    value: COUNT_SCOPE.WITHIN_RUN,
    title: 'Within single run',
  },
];

const countActionOptions = [
  {
    value: ACTION_SCOPE.RESET,
    title: 'Continue counting',
  },
  {
    value: ACTION_SCOPE.CONTINUE,
    title: 'Reset counter',
  },
];

const CountConditionStep = () => {
  const { t } = useTranslation();
  const [{ countScope, countAction }, updateDataToSave] = useNodeDataToSave();
  const countScopeValidationMessage = useValidationMessage('countScope');
  const countActionValidationMessage = useValidationMessage('countAction');

  const onChange = ({ target }) => {
    updateDataToSave({ [target.name]: target.value });
  };

  return (
    <>
      <RadioButtonsSection
        name="countScope"
        title={t('Settings')}
        subTitle={t('Count Scope')}
        options={countScopeOptions}
        validationMessage={countScopeValidationMessage}
        value={countScope}
        handleChange={onChange}
        subTitleMB="8px"
        mb="24px"
      />
      <RadioButtonsSection
        name="countAction"
        subTitle={t('After condition met')}
        options={countActionOptions}
        validationMessage={countActionValidationMessage}
        value={countAction}
        handleChange={onChange}
        subTitleMB="8px"
      />
      <Title>{t('Conditions')}</Title>
      <NewConditions />
    </>
  );
};

export default CountConditionStep;
