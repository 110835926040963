import * as Yup from 'yup';
import { signUpCompanyDetailsNames } from './signUpCompanyDetailsSchema';

const stringValidationSchema = (requiredMessage) =>
  Yup.string()
    .trim()
    .required(requiredMessage);

export const signUpCompanyDetailsValidator = (showOtherInput) => {
  return Yup.object().shape({
    [signUpCompanyDetailsNames.companyName]: stringValidationSchema('Company name is required'),
    [signUpCompanyDetailsNames.companyIndustry]: stringValidationSchema('Company Industry is required'),
    [signUpCompanyDetailsNames.companySize]: stringValidationSchema('Company size is required'),
    ...(showOtherInput
      ? { [signUpCompanyDetailsNames.companyIndustryOther]: stringValidationSchema('Please specify industry') }
      : {}),
  });
};
