import isEmpty from 'lodash/isEmpty';
import useSearch from '../../../../../hooks/useSearch';
import { APP_CATEGORIES, FILTERS_DICTIONARY } from '../SideFilter/constant';

const PAYPAL_RECIPE = {
  label: 'paypal',
  templateId: 'template.paypal-standard-config',
};

const checkEveryArrayElementsToTarget = (arr, target) => target.every((item) => arr.includes(item.toUpperCase()));

const useFilterRecipes = (recipes) => {
  const [searchParams] = useSearch();
  const filters = searchParams?.filters || {};

  if (isEmpty(filters)) return recipes;

  const apps = filters?.[FILTERS_DICTIONARY.apps] ? filters?.[FILTERS_DICTIONARY.apps].split(',') : [];
  const useCases = filters?.[FILTERS_DICTIONARY.useCases] ? filters?.[FILTERS_DICTIONARY.useCases].split(',') : [];
  const roles = filters?.[FILTERS_DICTIONARY.roles] ? filters?.[FILTERS_DICTIONARY.roles].split(',') : [];

  const filteredRecipes = recipes.filter((recipe) => {
    const { appCategories } = recipe;

    const appsArr = appCategories.reduce((arr, category) => {
      const targetObj = APP_CATEGORIES.find((app) => app.subLabel === category);

      if (targetObj) {
        // eslint-disable-next-line no-param-reassign
        arr = [...arr, ...targetObj.data];
      }

      return arr;
    }, []);

    // Do not show payPal recipe unless 'payPal' tag is selected from Apps
    if (recipe.template.id === PAYPAL_RECIPE.templateId && apps.length > 0 && !apps.includes(PAYPAL_RECIPE.label)) {
      return false;
    }

    return (
      checkEveryArrayElementsToTarget(appsArr, apps) &&
      checkEveryArrayElementsToTarget(recipe.useCases, useCases) &&
      checkEveryArrayElementsToTarget(recipe.roles, roles)
    );
  });

  return filteredRecipes;
};

export default useFilterRecipes;
