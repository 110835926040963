import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const RefundIcon = (props) => (
  <SvgIcon viewBox="0 0 18 22" {...props}>
    <path
      d="M9.35898 2.52345C14.1681 2.65745 17.9995 6.60014 17.9995 11.4127C17.9995 16.3239 14.018 20.3054 9.10661 20.3054C4.93571 20.3054 1.30909 17.3921 0.418966 13.3155C0.191006 12.2589 1.79723 11.9123 2.02519 12.9686C2.75154 16.2951 5.70453 18.6608 9.10661 18.6608C13.1098 18.6608 16.3547 15.4157 16.3547 11.4127C16.3547 7.50857 13.2594 4.30272 9.35898 4.16897V5.56358C9.35898 6.09589 8.76198 6.39952 8.33242 6.10859C7.19652 5.45302 6.06111 4.79574 4.92497 4.13968C4.48003 3.88414 4.4937 3.24492 4.93107 3.00037C6.07917 2.33747 7.22435 1.67507 8.3722 1.01242C8.81373 0.756638 9.35824 1.08247 9.35751 1.58013L9.35898 2.52345ZM6.1253 12.9096L7.51821 12.7714C7.67661 13.6503 8.1418 14.1243 9.05731 14.1243C9.65918 14.1243 10.4414 13.8805 10.4414 13.1517C10.4414 12.3929 9.21888 12.253 8.64581 12.1093C7.51699 11.8269 6.39231 11.3312 6.39231 9.97952C6.39231 8.77211 7.33784 8.19635 8.3744 8.04357V7.14881H9.62477V8.04625C10.7529 8.20929 11.6195 8.83118 11.6664 10.164L10.2345 10.2131C10.1068 9.44989 9.69945 9.18605 8.94699 9.18605C8.51132 9.18605 7.75495 9.31639 7.75495 9.88409C7.75495 10.4952 8.83374 10.6841 9.27599 10.7893C10.6494 11.1122 11.8736 11.5196 11.8736 13.1434C11.8736 13.9984 11.3701 14.743 10.5813 15.071C10.3067 15.1838 9.98819 15.2619 9.62477 15.3007V16.1813H8.3744V15.2851C7.10402 15.1055 6.29932 14.3684 6.1253 12.9096ZM0.906129 9.64783C1.47652 9.57315 1.99956 9.97488 2.074 10.5453C2.14844 11.1157 1.7467 11.6382 1.17656 11.7131C0.606167 11.7878 0.0833715 11.3861 0.00844223 10.8155C-0.0662429 10.2453 0.335739 9.72252 0.906129 9.64783ZM2.01005 6.91768C2.57117 7.04264 2.92556 7.59912 2.80035 8.16047C2.67539 8.72208 2.11915 9.07573 1.55731 8.95102C0.99619 8.8263 0.64229 8.26982 0.767253 7.70821C0.891728 7.14661 1.44869 6.79271 2.01005 6.91768ZM3.98091 4.7296C4.46563 5.03907 4.60793 5.68293 4.29869 6.16814C3.98897 6.65286 3.34536 6.79515 2.86039 6.48567C2.37567 6.17644 2.23338 5.53234 2.54261 5.04737C2.85185 4.56265 3.49595 4.42036 3.98091 4.7296Z"
      fill="#787F88"
    />
  </SvgIcon>
);

RefundIcon.propTypes = {};

export default RefundIcon;
