import React from 'react';

const EmptyStateIcon = () => (
  <svg width="38" height="38" viewBox="0 0 38 25" fill="none">
    <path
      d="M18.9373 2.02001C20.5321 2.02001 21.9275 3.43401 21.9275 5.05002C21.9275 6.66602 20.5321 8.08003 18.9373 8.08003C17.3426 8.08003 15.9472 6.66602 15.9472 5.05002C15.9472 3.43401 17.3426 2.02001 18.9373 2.02001ZM18.9373 0C16.1466 0 13.9538 2.22201 13.9538 5.05002C13.9538 7.87803 16.1466 10.1 18.9373 10.1C21.7281 10.1 23.9209 7.87803 23.9209 5.05002C23.9209 2.22201 21.7281 0 18.9373 0Z"
      fill="currentcolor"
    />

    <path
      d="M32.8911 16.1597C34.4859 16.1597 35.8813 17.5737 35.8813 19.1897C35.8813 20.8057 34.4859 22.2197 32.8911 22.2197C31.2964 22.2197 29.901 20.8057 29.901 19.1897C29.901 17.5737 31.2964 16.1597 32.8911 16.1597ZM32.8911 14.1396C30.1003 14.1396 27.9076 16.3617 27.9076 19.1897C27.9076 22.0177 30.1003 24.2397 32.8911 24.2397C35.6819 24.2397 37.8747 22.0177 37.8747 19.1897C37.8747 16.3617 35.6819 14.1396 32.8911 14.1396Z"
      fill="currentcolor"
    />

    <path d="M22.9246 9.09009L28.9049 15.1501" stroke="currentcolor" strokeMiterlimit="10" />

    <path
      d="M4.98357 16.1597C6.57831 16.1597 7.97371 17.5737 7.97371 19.1897C7.97371 20.8057 6.57831 22.2197 4.98357 22.2197C3.38883 22.2197 1.99343 20.8057 1.99343 19.1897C1.99343 17.5737 3.38883 16.1597 4.98357 16.1597ZM4.98357 14.1396C2.19277 14.1396 0 16.3617 0 19.1897C0 22.0177 2.19277 24.2397 4.98357 24.2397C7.77437 24.2397 9.96714 22.0177 9.96714 19.1897C9.96714 16.3617 7.77437 14.1396 4.98357 14.1396Z"
      fill="currentcolor"
    />

    <path d="M14.9503 9.09009L8.96999 15.1501" stroke="currentcolor" strokeMiterlimit="10" />
  </svg>
);

export { EmptyStateIcon };
