import React from 'react';
import { shape, number, string } from 'prop-types';
import { getEdgeCenter } from 'react-flow-renderer';
import { Edge } from './Edge';
import { EdgeLabel } from './EdgeLabel';
import { useFlowEditorContext } from '../../context';
import { flowStepTypes } from '../../types';

const getLabel = (sourceStep, target) => {
  if (sourceStep?.__typename === flowStepTypes.ABTestConditionStep) {
    if (sourceStep?.aBranchCondition?.nextStepId === target) {
      return `${Number(sourceStep?.aBranchCondition?.tests?.[0]?.right)} %`;
    }
    if (sourceStep?.bBranchCondition?.nextStepId === target) {
      return `${Number(sourceStep?.bBranchCondition?.tests?.[0]?.right)} %`;
    }
  }
  return 'other';
};

const EdgeWithLabel = (props) => {
  const { nodes } = useFlowEditorContext();
  const { id, sourceX, sourceY, targetX, targetY, source, target, sourcePosition, targetPosition } = props;
  const sourceStep = nodes.find((el) => source === el.id)?.data;
  const label = getLabel(sourceStep, target);

  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <Edge {...props}>
      <EdgeLabel id={id} edgeCenterX={edgeCenterX} edgeCenterY={edgeCenterY} label={label} />
    </Edge>
  );
};

EdgeWithLabel.propTypes = {
  id: string.isRequired,
  source: string.isRequired,
  target: string.isRequired,
  sourceHandleId: string,
  sourceX: number.isRequired,
  sourceY: number.isRequired,
  targetX: number.isRequired,
  targetY: number.isRequired,
  sourcePosition: string.isRequired,
  targetPosition: string.isRequired,
  style: shape({}),
};

EdgeWithLabel.defaultProps = {
  style: {},
  sourceHandleId: null,
};

export { EdgeWithLabel };
