import React from 'react';
import { string } from 'prop-types';

const IntentIcon = ({ size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_644_1463)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66602 14C4.66602 13.2636 5.26297 12.6667 5.99935 12.6667C6.73573 12.6667 7.33268 13.2636 7.33268 14C7.33268 14.7364 6.73573 15.3333 5.99935 15.3333C5.26297 15.3333 4.66602 14.7364 4.66602 14Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 14C12 13.2636 12.597 12.6667 13.3333 12.6667C14.0697 12.6667 14.6667 13.2636 14.6667 14C14.6667 14.7364 14.0697 15.3333 13.3333 15.3333C12.597 15.3333 12 14.7364 12 14Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.666667C0 0.298477 0.298477 0 0.666667 0H3.33333C3.65108 0 3.92467 0.224257 3.98704 0.535829L4.54695 3.33333H15.3333C15.532 3.33333 15.7203 3.42195 15.847 3.57504C15.9736 3.72812 16.0254 3.92972 15.9882 4.12489L14.9206 9.72322C14.8291 10.1836 14.5787 10.5972 14.213 10.8915C13.8492 11.1844 13.3944 11.3406 12.9276 11.3333H6.4591C5.99225 11.3406 5.53747 11.1844 5.17365 10.8915C4.80817 10.5973 4.55776 10.1839 4.46622 9.72378C4.46618 9.72359 4.46626 9.72397 4.46622 9.72378L3.35253 4.15947C3.34801 4.14106 3.34425 4.12236 3.3413 4.10338L2.78688 1.33333H0.666667C0.298477 1.33333 0 1.03486 0 0.666667ZM4.81382 4.66667L5.77389 9.46346C5.80437 9.61692 5.88786 9.75478 6.00974 9.85289C6.13162 9.951 6.28413 10.0031 6.44056 10.0001L6.45333 10H12.9333L12.9461 10.0001C13.1025 10.0031 13.255 9.951 13.3769 9.85289C13.4982 9.75523 13.5815 9.6182 13.6123 9.4656L14.5275 4.66667H4.81382Z"
        fill="black"
      />
    </g>
  </svg>
);

IntentIcon.propTypes = {
  size: string,
};

IntentIcon.defaultProps = {
  size: '16',
};

export default IntentIcon;
