import React from 'react';
import PropTypes from 'prop-types';

const Heads = ({ size = 20 }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2001 5.99995C17.2001 8.65091 15.051 10.8 12.4001 10.8C9.74911 10.8 7.60004 8.65091 7.60004 5.99995C7.60004 3.34898 9.74911 1.19995 12.4001 1.19995C15.051 1.19995 17.2001 3.34898 17.2001 5.99995ZM15.6001 5.99995C15.6001 7.76726 14.1673 9.19995 12.4001 9.19995C10.6327 9.19995 9.20007 7.76726 9.20007 5.99995C9.20007 4.23264 10.6327 2.79995 12.4001 2.79995C14.1673 2.79995 15.6001 4.23264 15.6001 5.99995Z"
      fill="white"
    />
    <path
      d="M6.95919 2.86371C7.39219 2.77582 7.67195 2.35355 7.58406 1.92055C7.49617 1.48756 7.0739 1.20779 6.6409 1.29569C4.44962 1.7405 2.80005 3.67655 2.80005 5.99969C2.80005 8.32282 4.44962 10.2589 6.6409 10.7037C7.0739 10.7915 7.49617 10.5118 7.58406 10.0788C7.67195 9.64586 7.39219 9.22354 6.95919 9.1357C5.4989 8.83922 4.40005 7.54689 4.40005 5.99969C4.40005 4.45248 5.4989 3.16014 6.95919 2.86371Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.15837 12.8587C9.14464 12.1016 10.5193 11.5999 12.4001 11.5999C14.2809 11.5999 15.6555 12.1016 16.6418 12.8587C17.6207 13.6101 18.1533 14.5639 18.4421 15.3667C18.6962 16.0735 18.5182 16.7652 18.0919 17.2555C17.6802 17.7289 17.057 17.9999 16.4001 17.9999H8.40008C7.74316 17.9999 7.11988 17.7289 6.70821 17.2555C6.28194 16.7651 6.10386 16.0734 6.35807 15.3667C6.64689 14.5639 7.17945 13.6101 8.15837 12.8587ZM9.13264 14.1279C8.45888 14.6451 8.0788 15.3101 7.86362 15.9083C7.81872 16.0331 7.84342 16.1226 7.91562 16.2056C8.00243 16.3055 8.17332 16.3999 8.40008 16.3999H16.4001C16.6268 16.3999 16.7977 16.3055 16.8845 16.2056C16.9567 16.1226 16.9814 16.0331 16.9365 15.9083C16.7214 15.3102 16.3413 14.6451 15.6675 14.1279C15.0012 13.6164 13.9832 13.1999 12.4001 13.1999C10.817 13.1999 9.79896 13.6164 9.13264 14.1279Z"
      fill="white"
    />
    <path
      d="M3.00079 15.8214C3.16455 15.1907 3.44563 14.5277 3.90948 14.0322C4.35036 13.5612 4.99246 13.1999 6.00004 13.1999C6.44188 13.1999 6.80004 12.8417 6.80004 12.3999C6.80004 11.958 6.44188 11.5999 6.00004 11.5999C4.54351 11.5999 3.48048 12.1492 2.74141 12.9387C2.02534 13.7037 1.65168 14.6509 1.45216 15.4191C1.0656 16.9076 2.33164 17.9999 3.60002 17.9999H4.2154C4.65723 17.9999 5.0154 17.6417 5.0154 17.1999C5.0154 16.758 4.65723 16.3999 4.2154 16.3999H3.60002C3.374 16.3999 3.194 16.307 3.09168 16.1939C2.999 16.0915 2.96212 15.9702 3.00079 15.8214Z"
      fill="white"
    />
  </svg>
);

Heads.propTypes = {
  size: PropTypes.number,
};

Heads.defaultProps = {
  size: 20,
};

export default Heads;
