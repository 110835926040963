import React, { useEffect, useRef, useState } from 'react';
import { number, string } from 'prop-types';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { InitialConditionBox, ConditionInnerBox, StyledWrapper, ConditionsBox, TestOpBox } from './styled';
import { useFlowEditorContext } from '../../context';
import { P12M } from '../../../atoms/Typography/P12M';
import { getValidOperatorLabel } from '../../utils/getValidOperatorLabel';
import { LogicalOperator } from '../AutomationDetails/AutomationStep/fields/constants';
import { L10UM } from '../../../atoms/Typography/L10UM';
import THEME from '../../../../constants/theme';
import { useMouseEventHandler } from '../../hooks/useMouseEventHandler';

const EdgeConditionBlock = ({ id, edgeCenterX, edgeCenterY, stepId, targetId, edgeColor }) => {
  const { t } = useTranslation();
  const ref = useRef();
  const {
    findNodeDataById,
    setSelectedNodeId,
    setSelectedConditionEdgeNumber,
    isDataSaving,
    edges,
  } = useFlowEditorContext();
  const { mouseEventHandler } = useMouseEventHandler();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const conditionNodeData = findNodeDataById(stepId);
  const conditions = conditionNodeData?.conditions;
  const edgeIndex = edges
    ?.filter(
      ({ id: edgeId, type, source }) =>
        type === 'edgeWithCondition' && !edgeId?.includes('e-else-') && source === stepId
    )
    .findIndex(({ target }) => target === targetId);
  const edgeNumber = edgeIndex > -1 ? edgeIndex + 1 : null;
  const condition = conditions?.find(({ nextStepId }) => nextStepId === targetId);
  const tests = condition?.tests?.map((test) => ({ ...test, id: test?.id ?? v4() }));
  const isConditionValid = !!(condition && condition?.op && condition?.right);
  const isElseEdge = conditionNodeData?.elseStepId === targetId;

  useEffect(() => {
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetHeight);
  }, [condition, isConditionValid, tests]);

  const handleClick = () => {
    setSelectedNodeId(stepId);
    setSelectedConditionEdgeNumber(edgeNumber);
  };

  return (
    <foreignObject
      width={width}
      height={height}
      x={edgeCenterX - width / 2}
      y={edgeCenterY - height / 2}
      className="ConditionEdge-foreignobject"
      requiredExtensions="http://www.w3.org/1999/xhtml"
      key={height}
      onMouseEnter={() => mouseEventHandler(id)}
      onMouseLeave={() => mouseEventHandler(null)}
    >
      <StyledWrapper>
        <Box ref={ref} onClick={handleClick}>
          {!tests?.length && !isElseEdge && edgeNumber ? (
            <InitialConditionBox $variant="standalone" $borderRadius="6px" $edgeColor={edgeColor}>
              {edgeNumber}
            </InitialConditionBox>
          ) : null}
          {tests?.length && !isElseEdge ? (
            <ConditionInnerBox $edgeColor={edgeColor}>
              <InitialConditionBox $height={`${height - 4}px`} $edgeColor={edgeColor}>
                {edgeNumber}
              </InitialConditionBox>
              <ConditionsBox>
                {tests?.length > 1 && (
                  <L10UM color={THEME.greyColors.grey600} margin="0 0 4px 0" textTransform="uppercase">
                    {condition?.logicalOperator === LogicalOperator.AND ? t('Match all') : t('Match any')}
                  </L10UM>
                )}
                {tests?.map((test, index) => (
                  <P12M key={test.id} padding={index === 2 ? '0' : '0 0 4px 0'} textTransform="capitalize" noWrap>
                    {index < 3 && (
                      <>
                        {!!test?.leftLabel && <Box component="span">{test.leftLabel}</Box>}
                        {!test?.leftLabel && !isDataSaving && !!test?.left && <Box component="span">{test.left}</Box>}
                        {test?.right?.from ? (
                          <Box component="span">
                            {' '}
                            {test.right?.from?.join ? test.right?.from?.join() : test.right?.from}
                          </Box>
                        ) : null}
                        {test?.op ? <TestOpBox> {getValidOperatorLabel(test.op)}</TestOpBox> : null}
                        {test?.right?.to ? (
                          <Box component="span"> {test.right?.to?.join ? test.right?.to?.join() : test.right?.to}</Box>
                        ) : (
                          <>
                            {!!test?.rightLabel && (
                              <Box component="span">
                                {' '}
                                {test.rightLabel?.join ? test.rightLabel?.join() : test.rightLabel}
                              </Box>
                            )}
                            {!test?.rightLabel && !isDataSaving && !!test?.right && (
                              <Box component="span"> {test.right?.join ? test.right?.join() : test.right}</Box>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {index === 3 && <span>...</span>}
                  </P12M>
                ))}
              </ConditionsBox>
            </ConditionInnerBox>
          ) : null}
          {isElseEdge && (
            <ConditionInnerBox $isElse $width="46px" $height="38px" $edgeColor={edgeColor}>
              <P12M padding="12px">{t('Else')}</P12M>
            </ConditionInnerBox>
          )}
        </Box>
      </StyledWrapper>
    </foreignObject>
  );
};

EdgeConditionBlock.propTypes = {
  id: string.isRequired,
  stepId: string.isRequired,
  edgeCenterX: number,
  edgeCenterY: number,
  targetId: string,
  edgeColor: string,
};

EdgeConditionBlock.defaultProps = {
  edgeCenterX: 0,
  edgeCenterY: 0,
  targetId: '',
  edgeColor: THEME.greyColors.grey500,
};

export { EdgeConditionBlock };
