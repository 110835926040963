import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import { parseBoolean } from '../../../utils/parseBoolean';
import { GET_CONNECTIONS } from '../../../utils/queries/connections/connectionsQueries';
import { CONNECTION_STATUS } from '../components/constant';
import { GET_CONNECTION_LIST_VARIABLES, REFETCH_CONNECTIONS_LIST_FLAG } from './constant';

/** To refetch connections list for oauth connections
 * On successful connection, either from connections list or connections details we redirect to connection details.
 * On successful connection, we need to update the connections list when user go back to the list screen
 */

const useGetConnectionsLists = () => {
  const { data, error, loading, refetch } = useQuery(GET_CONNECTIONS, {
    ...GET_CONNECTION_LIST_VARIABLES,
    update(cache) {
      cache.modify({
        fields: {
          getFlowStepLibrary(refs, { DELETE }) {
            return DELETE;
          },
          listConnections(refs, { DELETE }) {
            return DELETE;
          },
          getConnections(refs, { DELETE }) {
            return DELETE;
          },
        },
      });
    },
  });

  useEffect(() => {
    const refetchFlag = localStorage.getItem(REFETCH_CONNECTIONS_LIST_FLAG);
    if (refetchFlag && parseBoolean(refetchFlag)) {
      localStorage.removeItem(REFETCH_CONNECTIONS_LIST_FLAG);
      refetch();
    }
  }, [refetch]);

  const connections = useMemo(() => {
    if (!loading && isEmpty(error)) {
      const connectionsObject = {};
      const connectionsList = compact(data?.listConnections || []);

      connectionsList.forEach((item) => {
        if (item.status === CONNECTION_STATUS.CONNECTED || item.status === CONNECTION_STATUS.ONBOARDING) {
          connectionsObject.connected = [...(connectionsObject.connected || []), item];
        }

        if (item.status === CONNECTION_STATUS.BROKEN) {
          connectionsObject.connected = [...(connectionsObject.connected || []), item];
        }
        if (item.status === CONNECTION_STATUS.NOT_CONNECTED) {
          connectionsObject.notConnected = [...(connectionsObject.notConnected || []), item];
        }

        if (item.status === CONNECTION_STATUS.ARCHIVED) {
          connectionsObject.archived = [...(connectionsObject.archived || []), item];
        }
      });
      return connectionsObject;
    }
    return {};
  }, [error, data, loading]);

  return {
    connections,
    directoryConnections: [...(connections.notConnected || [])],
    error,
    loading,
    refetch,
  };
};

export { useGetConnectionsLists };
