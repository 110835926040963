import React from 'react';
import { useTranslation } from 'react-i18next';

import capitalize from '@mui/material/utils/capitalize';
import isEmpty from 'lodash/isEmpty';
import { P } from '../../atoms';
import { formNames } from './schema';

const OTHERS_VALUE = 'OTHER';

const CUSTOM_SELECT_PROPS = {
  placeholder: 'Select',
  errorProps: {
    margin: '-6px 0 14px 0',
  },
};

const PAYMENT_TRANSACTIONS_OPTIONS = [
  {
    value: 'DONT_KNOW',
    text: { text: "I don't know" },
  },
  {
    value: 'ZERO_1k',
    text: { text: '< 1,000' },
  },
  {
    value: 'THOUSAND_10000',
    text: { text: '1,000 - 10,000' },
  },
  {
    value: 'TEN_THOUSAND_100k',
    text: { text: '10,000 - 100,000' },
  },
  {
    value: 'HUNDRED_THOUSAND_MORE',
    text: { text: '> 100,000' },
  },
];

const SELECT_OPTIONS = {
  [formNames.transactionsAmount]: PAYMENT_TRANSACTIONS_OPTIONS,
};

const OTHERS_INPUT_NAME_MAP = {};

const SelectPlaceHolder = () => {
  const { t } = useTranslation();
  return <P color="#8e8f8f">{t('Select')}</P>;
};

const renderValue = (values, max) => {
  if (!Array.isArray(values)) return values;
  if (Array.isArray(values) && values.some((value) => typeof value !== 'string')) return values;

  if (isEmpty(values)) {
    return <SelectPlaceHolder />;
  }
  return (
    values
      ?.slice(0, max || values.length)
      ?.map((value) => capitalize(value?.toLowerCase()?.replace(/_/g, ' ') || ''))
      ?.join(', ') + (values?.length > (max || values.length) ? `...` : '')
  );
};

const RENDER_VALUE_MAP = {
  [formNames.transactionsAmount]: (value) => renderValue(value, 2),
};

export { SELECT_OPTIONS, CUSTOM_SELECT_PROPS, OTHERS_VALUE, OTHERS_INPUT_NAME_MAP, RENDER_VALUE_MAP, renderValue };
