/**
 * TODO
 * Remove duplicate colors ie. #E8E8E8
 * Follow grey 200, 500, 600 pattern
 */

const THEME = {
  primaryColors: {
    main: '#150544',
    green: '#005C69',
    black: '#232629',
    blackMain: '#000000',
    white: '#FFFFFF',
    blue: '#1F25F4',
    purple: '#1F25F4',
    purpleMain: '#4E40EF',
    primary: '#1F25F4',
    primaryLight: '#F4F4FE',
    primaryLightHover: '#EEEFFD',
    primaryLightDark: '#EEEEF8',
    primaryLightTransparent: 'rgba(245, 246, 247, 0.4)',
    primaryAccent2: '#6D61FF',
    primaryDisabled: '#C4C6FF',
    primaryD20: '#191EC3',
    lightBlue: '#F4F4FE',
    blackPlain: '#000000',
    orange: '#F36B47',
    l50: '#8F92F9',
    purpleLight: '#9CA0FF',
  },
  secondaryColors: {
    secondary: '#3023C8',
    pink: '#E99F97',
    pink2: 'rgba(240,138,115, 0.2)',
    yellow: '#F6CE4B',
    tial: '#248795',
    red: '#F08A73',
    purple: '#7B2DEF',
    purpleTransparent: 'rgba(206,181,231, 0.2)',
    danger: '#DF5B5B',
    orange: '#FEC030',
    orangeSecondary: '#F5896C',
    orangeLight: '#FBD3C8',
    blue: '#2346f2',
    blueLight: '#E9EDFE',
    green: '#006351',
    greenDark: '#14B95C',
    greenBright: '#0CC48C',
    greenStats: '#027A48',
    greenIconStats: '#12B76A',
    redStats: '#B42318',
    redIconStats: '#F04438',
    teal: '#5FB0CA',
    darkBlue: '#000074',
    tableLineSelected: '#EDEDF2',
    approved: '#3F94C7',
    black: '#0F1925',
    black2: '#232629',
    disconnected: '#DB8282',
    invalid: '#F24343',
    nodeError: '#B74242',
    inputBg: '#f5f6f7',
    warnYellow: '#E3B81F',
    salmon: '#DF735B',
    iconGreen: '#0CC48C',
    lightRed: '#FEF8F6',
    brightPurple: '#BA6CE2',
    brightOrange: '#FF6C4C',
    fuchsia: '#EF3985',
    aqua: '#22BCD1',
    brightBlue: '#6750FA',
    templatesPurple: '#BA6CE2',
    lightBrown: '#C49C0C',
    denimBlue: '#156CD1',
    positiveRank: '#F3FBF7',
    negativeRank: '#FBF5F5',
    navyBlue: '#463AD7',
    neonPurple: '#A015D1',
    tokenization: '#42B16E',
    lightBlue: '#5094FA',
    checkoutOptimization: '#15AFD1',
    braintree: '#4C40E6',
    paypal: '#9A47E7',
    success: '#10944A',
  },
  statusColors: {
    failed: '#DF5B5B',
    darkRed: '#B54345',
    succeeded: '#14B95C',
    purple: '#3023C8',
  },
  cardColors: {
    red: '#F0CBC7',
    yellow: '#F5C763',
    tial: '#0D7281',
    pink: '#E99F97',
    purple: '#5E1344',
    blue: '#19135E',
  },
  greyColors: {
    grey1: '#787F88',
    grey2: '#959595',
    grey3: '#E7E6E6',
    grey4: '#E9E9E9',
    grey5: '#E6E9EC',
    grey6: '#F8F9FA',
    grey7: '#F8F9F9',
    grey8: '#C1C3C6',
    grey8Transparent: 'rgba(193, 195, 198, 0.2)',
    grey9: '#787F88',
    grey10: '#ECF0F4',
    grey11: '#545A61',
    grey12: '#F5F6F7',
    grey12Transparent: 'rgba(245, 246, 247, 0.4)',
    grey13: '#828282',
    grey14: '#F8F8F8',
    grey16: '#E8E8E8',
    grey17: '#333333',
    grey18: '#6A6A6A',
    grey19: '#999999',
    grey20: '#777777',
    grey200: '#E8E8E8',
    grey300: '#C4C4C4',
    grey400: '#999999',
    grey500: '#808080',
    grey600: '#6A6A6A',
    grey800: '#333333',
    grey900: '#1A1A1A',
    greyButton: '#F4F4F4',
    grey21: '#F8F8F8',
    grey22: '#E8E8E8',
    grey23: '#595959',
    grey24: '#F4F4F4',
    grey25: '#D7D8E5',
    grey26: '#667085',
    lightGrey: '#8C909D',
    grey: '#5E6170',
  },
  functionalColors: {
    red: '#AF0F3B',
  },
  shadows: {
    small: '0px 4px 4px rgba(132, 132, 132, 0.03)',
    large: '0px 4px 4px rgba(132, 132, 132, 0.03), 0px 2px 10px rgba(0, 0, 0, 0.06)',
  },
  designerColors: {
    red: '#FFDDDD',
    yellow: '#ADDDD7',
    teal: '#DDECF5',
    pink: '#fbdcd5',
    purple: '#CEB5E7',
    blue: '#3946F2',
  },
  breakPoints: {
    tablet: 600,
    tabletLarge: 960,
    desktop: 1280,
    desktopLarge: 1600,
  },

  gradient: `linear-gradient(90deg, #BB7C8F 0%, #8672D3 26.41%, #686AF9 51.56%, #5740FA 77.27%, #4611F8 100%)`,
  gradientSecondary: `linear-gradient(90deg, #F58662 0%, #F59090 35.42%, #BA7FA1 70.31%, #8672D3 100%);`,
};

export default THEME;
