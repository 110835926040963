import React from 'react';
import Box from '@mui/material/Box';
import { oneOfType, string, number, func, element, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { P14M } from '../../atoms';
import {
  StyledErrorMessage,
  StyledFlowMonitorDetailsItem,
  StyledStatusDot,
  StyledStatusLine,
  StyledTimelineStatusWrap,
} from './styled';
import TemplatesRetry from '../../../assets/icons/Templates/TemplatesRetry';
import THEME from '../../../constants/theme';
import { P12M } from '../../atoms/Typography/P12M';

const FlowMonitorDetailsItem = ({
  errorMessage,
  label,
  onClick,
  children,
  size,
  height,
  px,
  showTimelineStatus,
  retryCount,
  width,
}) => {
  const { t } = useTranslation();
  const isError = !!errorMessage;
  return (
    <StyledFlowMonitorDetailsItem onClick={onClick} $height={height} $px={px}>
      {children}
      <Box display="flex" flexDirection="column" width={width || `calc(100% - ${size}px)`}>
        <P14M>{label}</P14M>
        {typeof errorMessage === 'string' ? (
          <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
        ) : (
          errorMessage || null
        )}
      </Box>
      {!!retryCount && (
        <Box display="flex" alignItems="center" ml="12px">
          <TemplatesRetry size={16} color={THEME.greyColors.grey600} />
          <P12M color={THEME.greyColors.grey600} margin="0 0 0 6px">
            {retryCount} {t('retries')}
          </P12M>
        </Box>
      )}
      {showTimelineStatus && (
        <StyledTimelineStatusWrap $isError={isError}>
          <StyledStatusLine className="status-top-line" $isError={isError} />
          <StyledStatusDot className="status-dot" $isError={isError} />
          <StyledStatusLine className="status-bottom-line" $isError={isError} />
        </StyledTimelineStatusWrap>
      )}
    </StyledFlowMonitorDetailsItem>
  );
};

FlowMonitorDetailsItem.propTypes = {
  errorMessage: oneOfType([string, element]),
  size: number,
  label: string,
  height: string,
  px: string,
  showTimelineStatus: bool,
  onClick: func.isRequired,
  retryCount: number,
  width: string,
};
FlowMonitorDetailsItem.defaultProps = {
  errorMessage: null,
  size: 32,
  label: '',
  height: '56px',
  px: '16px',
  showTimelineStatus: false,
  retryCount: null,
  width: null,
};
export default FlowMonitorDetailsItem;
