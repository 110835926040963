import React from 'react';
import PropTypes from 'prop-types';
import THEME from '../../../constants/theme';

const Play = ({ size, color }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 3.86852C4 3.06982 4.89015 2.59343 5.5547 3.03647L14.7519 9.16795C15.3457 9.56377 15.3457 10.4362 14.7519 10.8321L5.5547 16.9635C4.89014 17.4066 4 16.9302 4 16.1315V3.86852Z"
      fill={color}
    />
  </svg>
);

Play.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

Play.defaultProps = {
  size: 20,
  color: THEME.greyColors.grey400,
};

export default Play;
