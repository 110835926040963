import * as Yup from 'yup';
import buildValidator from '../../../../utils/validators/buildValidator';
import { DOMAIN_VALIDATOR, URL_VALIDATOR } from '../../../../utils/validators/constant';

const fieldNames = {
  domain: 'domain',
  currencyCode: 'currencyCode',
  clientId: 'clientId',
  subMerchantId: 'subMerchantId',
  companyName: 'companyName',
  registeredName: 'registeredName',
  platformUrl: 'platformUrl',
  platformDescription: 'platformDescription',
  companyReference: 'companyReference',
  companyNumber: 'companyNumber',
  addressLine1: 'addressLine1',
  addressLine2: 'addressLine2',
  city: 'city',
  postalCode: 'postalCode',
  state: 'state',
  country: 'country',
  billingEmail: 'billingEmail',
  techEmails: 'techEmails',
  adminsEmails: 'adminsEmails',
  otherBillingEmails: 'otherBillingEmails',
  fraudEmails: 'fraudEmails',
  businessType: 'businessType',
  sectorType: 'sectorType',
  merchantCategoryCode: 'merchantCategoryCode',
  taxId: 'taxId',
};

const initialValues = {
  [fieldNames.domain]: '',
  [fieldNames.currencyCode]: 'EUR',
  [fieldNames.clientId]: '',
  [fieldNames.subMerchantId]: '',
  [fieldNames.companyName]: '',
  [fieldNames.registeredName]: '',
  [fieldNames.registeredName]: '',
  [fieldNames.platformUrl]: '',
  [fieldNames.platformDescription]: '',
  [fieldNames.companyReference]: '',
  [fieldNames.companyNumber]: '',
  [fieldNames.addressLine1]: '',
  [fieldNames.addressLine2]: '',
  [fieldNames.city]: '',
  [fieldNames.postalCode]: '',
  [fieldNames.state]: '',
  [fieldNames.country]: '',
  [fieldNames.techEmails]: [],
  [fieldNames.adminsEmails]: [],
  [fieldNames.billingEmail]: '',
  [fieldNames.fraudEmails]: [],
  [fieldNames.otherBillingEmails]: [],
  [fieldNames.businessType]: '',
  [fieldNames.sectorType]: '',
  [fieldNames.merchantCategoryCode]: '',
  [fieldNames.taxId]: '',
};

const fields = [
  {
    id: 'col-1',
    groups: [
      {
        title: 'Client information',
        inputs: [
          {
            field: fieldNames.domain,
            type: 'text',
            componentType: 'input',
            label: 'Domain',
            tooltip: 'Domain name from your platform url. ie. "mangopay.com" from https://mangopay.com/',
          },
          {
            field: fieldNames.currencyCode,
            type: 'text',
            componentType: 'input',
            label: 'Currency code',
            isRequired: true,
            tooltip: 'Main market country',
            disabled: true,
          },
          {
            field: fieldNames.clientId,
            type: 'text',
            componentType: 'input',
            label: 'Client id',
            isRequired: true,
            tooltip: 'Client id',
          },
          {
            field: fieldNames.subMerchantId,
            type: 'text',
            componentType: 'input',
            label: 'Sub merchant id',
            isRequired: true,
            tooltip: 'Sub merchant id',
          },
          {
            field: fieldNames.companyName,
            type: 'text',
            componentType: 'input',
            label: 'Company name',
            isRequired: true,
            tooltip: 'Trading name',
          },
          {
            field: fieldNames.registeredName,
            type: 'text',
            componentType: 'input',
            label: 'Registered name',
            isRequired: true,
            tooltip: 'Registered name',
          },
          {
            field: fieldNames.platformUrl,
            type: 'text',
            componentType: 'input',
            label: 'Platform url',
            isRequired: true,
            tooltip: 'ie. https://example.com or http://example.com',
          },
          {
            field: fieldNames.platformDescription,
            type: 'text',
            componentType: 'input',
            label: 'Platform description',
            tooltip: 'Platform description',
          },
          {
            field: fieldNames.companyReference,
            type: 'text',
            componentType: 'input',
            label: 'Company reference',
            isRequired: true,
            tooltip: 'Company reference',
          },
          {
            field: fieldNames.companyNumber,
            type: 'text',
            componentType: 'input',
            label: 'Company number',
            isRequired: true,
            tooltip: 'Company number',
          },
        ],
      },
      {
        title: 'Client settings',
        inputs: [
          {
            field: fieldNames.businessType,
            componentType: 'businessTypeSelect',
            label: 'Business type',
            isRequired: true,
            tooltip: 'First value of the platform categorization',
          },
          {
            field: fieldNames.sectorType,
            type: 'text',
            componentType: 'sectorTypeSelect',
            label: 'Sector type',
            isRequired: true,
            tooltip: 'Second value of the platform categorization',
          },
          {
            field: fieldNames.merchantCategoryCode,
            type: 'text',
            componentType: 'merchantCategoryAutocomplete',
            label: 'Merchant category code',
            isRequired: true,
            tooltip: 'Merchant Category Code (MCC)',
          },
        ],
      },
    ],
  },
  {
    id: 'col-2',
    groups: [
      {
        title: 'Client contact information',
        inputs: [
          {
            field: fieldNames.addressLine1,
            type: 'text',
            componentType: 'input',
            label: 'Address line 1',
            isRequired: true,
            tooltip: 'Address line 1',
          },
          {
            field: fieldNames.addressLine2,
            type: 'text',
            componentType: 'input',
            label: 'Address line 2',
            tooltip: 'Address line 2',
          },
          {
            field: fieldNames.city,
            type: 'text',
            componentType: 'input',
            label: 'City',
            isRequired: true,
            tooltip: 'City',
          },
          {
            field: fieldNames.postalCode,
            type: 'text',
            componentType: 'input',
            label: 'Postal code',
            isRequired: true,
            tooltip: 'Postal code',
          },
          {
            field: fieldNames.state,
            type: 'text',
            componentType: 'input',
            label: 'State',
            isRequired: true,
            tooltip: 'Region',
          },
          {
            field: fieldNames.country,
            type: 'text',
            componentType: 'countrySelect',
            label: 'Country',
            isRequired: true,
            tooltip: 'Country',
          },
          {
            field: fieldNames.techEmails,
            type: 'email',
            componentType: 'tagInput',
            label: 'Tech emails',
            tooltip: 'Technical e-mails',
          },
          {
            field: fieldNames.adminsEmails,
            type: 'email',
            componentType: 'tagInput',
            label: 'Admin emails',
            tooltip: 'Admin e-mails',
          },
          {
            field: fieldNames.billingEmail,
            type: 'text',
            componentType: 'input',
            label: 'Billing email',
            tooltip: 'Billing email',
          },
          {
            field: fieldNames.fraudEmails,
            type: 'email',
            componentType: 'tagInput',
            label: 'Fraud emails',
            tooltip: 'Fraud e-mails',
          },
          {
            field: fieldNames.otherBillingEmails,
            type: 'email',
            componentType: 'tagInput',
            label: 'Other billing emails',
            tooltip: 'Other billing e-mails',
          },
        ],
      },
      {
        title: 'Accounting information',
        inputs: [
          {
            field: fieldNames.taxId,
            type: 'text',
            componentType: 'input',
            label: 'Tax id',
            isRequired: true,
            tooltip: 'Tax Number',
          },
        ],
      },
    ],
  },
];

const validator = buildValidator(
  Yup.object().shape({
    [fieldNames.domain]: DOMAIN_VALIDATOR,
    [fieldNames.clientId]: Yup.string()
      .trim()
      .required('Client id is required'),
    [fieldNames.subMerchantId]: Yup.string()
      .trim()
      .required('Sub merchant id is required'),
    [fieldNames.companyName]: Yup.string()
      .trim()
      .required('Company name is required'),
    [fieldNames.registeredName]: Yup.string()
      .trim()
      .required('Registered name is required'),
    [fieldNames.platformUrl]: URL_VALIDATOR.required(),
    [fieldNames.companyReference]: Yup.string()
      .trim()
      .required('Company reference is required'),
    [fieldNames.companyNumber]: Yup.string().required('Company number is required'),
    [fieldNames.addressLine1]: Yup.string()
      .trim()
      .required('Address line1 is required'),
    [fieldNames.city]: Yup.string()
      .trim()
      .required('City is required'),
    [fieldNames.postalCode]: Yup.string()
      .trim()
      .required('Postal code is required'),
    [fieldNames.state]: Yup.string()
      .trim()
      .required('State is required'),
    [fieldNames.country]: Yup.string()
      .trim()
      .required('Country is required'),
    [fieldNames.billingEmail]: Yup.string()
      .trim()
      .email('Email is not valid'),
    [fieldNames.businessType]: Yup.string()
      .trim()
      .required('Business type is required'),
    [fieldNames.sectorType]: Yup.string()
      .trim()
      .required('Sector type is required'),
    [fieldNames.merchantCategoryCode]: Yup.string()
      .trim()
      .required('Merchant category code is required'),
    [fieldNames.taxId]: Yup.string()
      .trim()
      .required('Tax id is required'),
  })
);

export { initialValues, fields, validator };
