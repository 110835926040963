import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';
import { StyledSelect as StyledSelectBase } from '../styled';
import THEME from '../../../../../../../constants/theme';

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    &.Mui-selected {
      color: ${THEME.primaryColors.primary};
      background-color: transparent;
    }
  }
`;

const StyledSelect = styled(StyledSelectBase)`
  &.MuiInputBase-root {
    border-radius: 8px;
    font-weight: 500;
    color: ${({ $color }) => $color || THEME.primaryColors.primary};
    background-color: ${({ $bgColor }) => $bgColor || THEME.primaryColors.primaryLight};
    &:before,
    &:after {
      display: none;
    }
  }
  & .MuiSelect-root {
    &.MuiSelect-select {
      &.MuiSelect-select {
        padding: ${({ $padding }) => $padding || '13px 36px 11px 16px'};
      }
    }
  }
  & .MuiSelect-icon {
    color: currentColor;
    right: ${({ $iconRight }) => $iconRight || '7px'};
    top: ${({ $iconTop }) => $iconTop || '9px'};
  }
`;

export { StyledMenuItem, StyledSelect };
