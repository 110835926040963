import { useQuery } from '@apollo/client';
import { GET_RECOMMENDED_TEMPLATES } from '../../../utils/queries/automation/queries';

const useGetRecommendedTemplates = () => {
  const { data, loading } = useQuery(GET_RECOMMENDED_TEMPLATES);

  return { recommendedTemplates: data?.getRecommendedTemplates || [], loading };
};

export default useGetRecommendedTemplates;
