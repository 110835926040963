import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { StyledDialog, StyledDialogActions, StyledPaper } from '../../../../../../../components/Dialog/styled';
import { L16B } from '../../../../../../../components/atoms/Typography/L16B';
import { ButtonRounded, P14 } from '../../../../../../../components/atoms';
import THEME from '../../../../../../../constants/theme';
import { MODAL_CONTENT } from './constant';

const ConfirmationDialog = ({ shouldModalOpen, setShouldModalOpen, modalData, disabled }) => {
  const { t } = useTranslation();
  const { type, action } = modalData;

  const handlePrimaryAction = () => {
    action();
  };

  const handleCancel = () => {
    setShouldModalOpen(false);
  };

  return (
    <StyledDialog
      open={shouldModalOpen}
      scroll="paper"
      maxWidth="xs"
      PaperComponent={StyledPaper}
      aria-labelledby="UnsavedChangesDialog"
      width="337px"
    >
      <Box padding="34px">
        <L16B padding="0 0 16px 0">{t(MODAL_CONTENT[type]?.title)}</L16B>
        <P14 color={THEME.greyColors.grey800} padding="0 0 24px 0">
          {t(MODAL_CONTENT[type]?.desc)}
        </P14>
        <StyledDialogActions $justifyContent="flex-end" px="0" py="0">
          <ButtonRounded
            type="button"
            variant="contained"
            color="secondary"
            width="100%"
            borderRadius="6px"
            onClick={handleCancel}
            disabled={disabled}
          >
            {t('Cancel')}
          </ButtonRounded>
          <ButtonRounded
            type="button"
            variant="contained"
            color="primary"
            width="100%"
            borderRadius="6px"
            onClick={handlePrimaryAction}
            disabled={disabled}
          >
            {t(MODAL_CONTENT[type]?.actionButtonLabel)}
          </ButtonRounded>
        </StyledDialogActions>
      </Box>
    </StyledDialog>
  );
};

ConfirmationDialog.propTypes = {
  shouldModalOpen: bool.isRequired,
  setShouldModalOpen: func.isRequired,
  modalData: shape({
    title: string,
    desc: string,
    actionButtonLabel: string,
    action: func,
  }).isRequired,
  disabled: bool.isRequired,
};

export default ConfirmationDialog;
