import styled from 'styled-components';
import { Box, Chip, TextField } from '@mui/material';
import THEME from '../../../../constants/theme';

const ContainerBox = styled(Box)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px 16px;
  background: #f5f6f7;
  border-radius: 4px;
  gap: 4px;
  border: 1px solid ${({ $isFocused }) => ($isFocused ? THEME.primaryColors.blue : 'transparent')};
  ${({ $isFocused }) => $isFocused && `box-shadow: 0 0 2px 1.5px rgba(156, 160, 255, 0.2);`}
  ${({ $isFocused }) => $isFocused && ` background-color: white;`}

  &:hover {
    background-color: rgba(193, 195, 198, 0.2);
  }
`;

const StyledChip = styled(Chip)`
  background-color: white;

  &:first-child {
    ${({ $hasTag }) => $hasTag && `margin-left: -12px;`}
  }

  &.MuiChip-root {
    height: 28px;

    &.Mui-disabled {
      opacity: 0.7;
    }
  }

  &.MuiChip-outlinedPrimary {
    color: rgba(63, 81, 181, 0.7);
    border: 1px solid rgba(63, 81, 181, 0.7);
    background-color: white;
  }

  & .MuiChip-deleteIconOutlinedColorPrimary {
    color: rgba(63, 81, 181, 0.7);

    &:hover {
      color: rgba(63, 81, 181, 0.8);
    }
  }
`;

const StyledTextField = styled(TextField)`
  flex-grow: 1;

  & .MuiInput-underline:before,
  .MuiInput-underline:after,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    display: none;
  }
`;

export { ContainerBox, StyledChip, StyledTextField };
