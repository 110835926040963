import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import React from 'react';
import LoadingStateItem from './LoadingStateItem';

const amountSkeletonArr = Array.from(Array(2).keys());
const countSkeletonArr = Array.from(Array(4).keys());

const DisputeStatsLoadingState = () => {
  return (
    <>
      <Grid container spacing={2} alignItems="flex-start">
        {amountSkeletonArr.map((key) => (
          <LoadingStateItem key={key} isAmount />
        ))}
      </Grid>
      <Box width="100%" mt="8px">
        <Grid container spacing={2} alignItems="flex-start">
          {countSkeletonArr.map((key) => (
            <LoadingStateItem key={key} />
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default DisputeStatsLoadingState;
