import React from 'react';
import { number, string } from 'prop-types';
import THEME from '../../constants/theme';

const Rotate = ({ size, color }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2903_20080)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666667 2C1.03486 2 1.33333 2.29848 1.33333 2.66667V6H4.66667C5.03486 6 5.33333 6.29848 5.33333 6.66667C5.33333 7.03486 5.03486 7.33333 4.66667 7.33333H0.666667C0.298477 7.33333 0 7.03486 0 6.66667V2.66667C0 2.29848 0.298477 2 0.666667 2Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.07024 1.40487C8.50152 1.2035 9.95947 1.47274 11.2244 2.17202C12.4894 2.8713 13.4928 3.96274 14.0835 5.2819C14.6742 6.60105 14.8202 8.07645 14.4995 9.48579C14.1788 10.8951 13.4088 12.1621 12.3054 13.0957C11.202 14.0294 9.82511 14.5791 8.38212 14.6622C6.93913 14.7452 5.50824 14.3571 4.30504 13.5562C3.10184 12.7553 2.19151 11.5851 1.71122 10.2218C1.58887 9.87456 1.7712 9.49386 2.11847 9.37151C2.46574 9.24916 2.84644 9.43149 2.96879 9.77876C3.35302 10.8694 4.08128 11.8055 5.04384 12.4462C6.0064 13.087 7.15112 13.3975 8.30551 13.331C9.4599 13.2646 10.5614 12.8248 11.4441 12.0779C12.3268 11.331 12.9429 10.3174 13.1994 9.18994C13.456 8.06246 13.3392 6.88214 12.8666 5.82682C12.3941 4.7715 11.5913 3.89834 10.5794 3.33892C9.56739 2.77949 8.40102 2.5641 7.256 2.7252C6.11098 2.8863 5.04933 3.41516 4.23101 4.2321C4.22625 4.23685 4.22142 4.24152 4.21652 4.24613L1.12319 7.15279C0.854867 7.40492 0.432961 7.3918 0.180833 7.12348C-0.0712944 6.85516 -0.0581685 6.43325 0.210151 6.18112L3.29633 3.28118C4.31823 2.26417 5.64238 1.60576 7.07024 1.40487Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_2903_20080">
        <rect width={size} height={size} fill={color} />
      </clipPath>
    </defs>
  </svg>
);

Rotate.propTypes = {
  size: number,
  color: string,
};

Rotate.defaultProps = {
  size: 16,
  color: THEME.primaryColors.white,
};

export default Rotate;
