import React from 'react';

const Account = () => {
  return (
    <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="34" height="24" rx="4" fill="white" />
      <rect
        x="0.25"
        y="0.25"
        width="33.5"
        height="23.5"
        rx="3.75"
        stroke="black"
        strokeOpacity="0.15"
        strokeWidth="0.5"
      />
      <path
        d="M15.75 11.75C15.8826 11.75 16.0098 11.6973 16.1036 11.6036C16.1973 11.5098 16.25 11.3826 16.25 11.25C16.25 11.1174 16.1973 10.9902 16.1036 10.8964C16.0098 10.8027 15.8826 10.75 15.75 10.75H12.75C12.6174 10.75 12.4902 10.8027 12.3964 10.8964C12.3027 10.9902 12.25 11.1174 12.25 11.25C12.25 11.3826 12.3027 11.5098 12.3964 11.6036C12.4902 11.6973 12.6174 11.75 12.75 11.75H13.125C13.1582 11.75 13.1899 11.7632 13.2134 11.7866C13.2368 11.8101 13.25 11.8418 13.25 11.875V14.375C13.25 14.4082 13.2368 14.4399 13.2134 14.4634C13.1899 14.4868 13.1582 14.5 13.125 14.5H12.75C12.6174 14.5 12.4902 14.5527 12.3964 14.6464C12.3027 14.7402 12.25 14.8674 12.25 15C12.25 15.1326 12.3027 15.2598 12.3964 15.3536C12.4902 15.4473 12.6174 15.5 12.75 15.5H15.75C15.8826 15.5 16.0098 15.4473 16.1036 15.3536C16.1973 15.2598 16.25 15.1326 16.25 15C16.25 14.8674 16.1973 14.7402 16.1036 14.6464C16.0098 14.5527 15.8826 14.5 15.75 14.5H15.375C15.3418 14.5 15.3101 14.4868 15.2866 14.4634C15.2632 14.4399 15.25 14.4082 15.25 14.375V11.875C15.25 11.8418 15.2632 11.8101 15.2866 11.7866C15.3101 11.7632 15.3418 11.75 15.375 11.75H15.75Z"
        fill="#545A61"
      />
      <path
        d="M21.25 11.75C21.3826 11.75 21.5098 11.6973 21.6036 11.6036C21.6973 11.5098 21.75 11.3826 21.75 11.25C21.75 11.1174 21.6973 10.9902 21.6036 10.8964C21.5098 10.8027 21.3826 10.75 21.25 10.75H18.25C18.1174 10.75 17.9902 10.8027 17.8964 10.8964C17.8027 10.9902 17.75 11.1174 17.75 11.25C17.75 11.3826 17.8027 11.5098 17.8964 11.6036C17.9902 11.6973 18.1174 11.75 18.25 11.75H18.625C18.6582 11.75 18.6899 11.7632 18.7134 11.7866C18.7368 11.8101 18.75 11.8418 18.75 11.875V14.375C18.75 14.4082 18.7368 14.4399 18.7134 14.4634C18.6899 14.4868 18.6582 14.5 18.625 14.5H18.25C18.1174 14.5 17.9902 14.5527 17.8964 14.6464C17.8027 14.7402 17.75 14.8674 17.75 15C17.75 15.1326 17.8027 15.2598 17.8964 15.3536C17.9902 15.4473 18.1174 15.5 18.25 15.5H21.25C21.3826 15.5 21.5098 15.4473 21.6036 15.3536C21.6973 15.2598 21.75 15.1326 21.75 15C21.75 14.8674 21.6973 14.7402 21.6036 14.6464C21.5098 14.5527 21.3826 14.5 21.25 14.5H20.875C20.8418 14.5 20.8101 14.4868 20.7866 14.4634C20.7632 14.4399 20.75 14.4082 20.75 14.375V11.875C20.75 11.8418 20.7632 11.8101 20.7866 11.7866C20.8101 11.7632 20.8418 11.75 20.875 11.75H21.25Z"
        fill="#545A61"
      />
      <path
        d="M11.919 9.03505C11.837 9.09912 11.777 9.18719 11.7474 9.28697C11.7179 9.38675 11.7202 9.49327 11.754 9.59168C11.7879 9.69009 11.8516 9.77549 11.9363 9.83596C12.021 9.89644 12.1224 9.92898 12.2265 9.92905H21.8265C21.9286 9.92914 22.0282 9.898 22.112 9.83982C22.1959 9.78163 22.2599 9.69919 22.2955 9.60355C22.3311 9.50199 22.3338 9.39176 22.3031 9.2886C22.2724 9.18543 22.2099 9.0946 22.1245 9.02905L17.584 6.12305C17.5015 6.05032 17.3969 6.00743 17.2871 6.00126C17.1773 5.99509 17.0686 6.02601 16.9785 6.08905L11.95 9.01605C11.939 9.02123 11.9286 9.02761 11.919 9.03505Z"
        fill="#545A61"
      />
      <path
        d="M22.25 16.25H11.75C11.6119 16.25 11.5 16.3619 11.5 16.5V17.75C11.5 17.8881 11.6119 18 11.75 18H22.25C22.3881 18 22.5 17.8881 22.5 17.75V16.5C22.5 16.3619 22.3881 16.25 22.25 16.25Z"
        fill="#545A61"
      />
    </svg>
  );
};

export default Account;
