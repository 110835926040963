import React from 'react';
import { useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { string, shape, bool, arrayOf } from 'prop-types';
import { StyledWrapper, IconWrapper } from './styled';
import { P14, CustomSwitch } from '../../../../../../components/atoms';
import { LABEL_MAP, ICON_MAP } from './constant';
import { generateLabel } from '../../../../../../utils/helpers';
import THEME from '../../../../../../constants/theme';
import { UPDATE_PAYMENT_METHOD } from '../../../../../../utils/queries/connections/connectionsMutations';

const LEGACY = 'LEGACY';
const LEGACY_COLOR = '#303c42';

const PaymentMethodItem = ({ method, showToggle, checked, connection, enabledPaymentMethods }) => {
  const [updatePaymentMethod] = useMutation(UPDATE_PAYMENT_METHOD);

  const label = LABEL_MAP[method] ?? generateLabel(method);
  const Icon = ICON_MAP[method] ?? ICON_MAP.CARD;
  const isLegacyPaymentMethod = method.includes(LEGACY);

  const isCardMethod = method === 'CARD';
  const shouldEnable = !enabledPaymentMethods.includes(method);

  const handleUpdatePaymentMethod = () => {
    updatePaymentMethod({
      variables: {
        id: connection.id,
        method: method,
        enabled: shouldEnable,
      },
    });
  };

  return (
    <StyledWrapper>
      <Box display="flex" alignItems="center">
        <IconWrapper>
          <Icon
            {...(isCardMethod && { color: THEME.greyColors.grey600 })}
            {...(isLegacyPaymentMethod ? { lineColor: LEGACY_COLOR, bodyColor: LEGACY_COLOR } : {})}
          />
        </IconWrapper>

        <P14>{label} </P14>
      </Box>

      {showToggle && <CustomSwitch checked={checked} onClick={handleUpdatePaymentMethod} />}
    </StyledWrapper>
  );
};

PaymentMethodItem.propTypes = {
  method: string.isRequired,
  showToggle: bool.isRequired,
  connection: shape({}).isRequired,
  checked: bool.isRequired,
  enabledPaymentMethods: arrayOf(string).isRequired,
};
export default PaymentMethodItem;
