import React from 'react';

const Customers = () => {
  return (
    <svg width="144" height="123" viewBox="0 0 144 123" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_3996_110275)">
        <rect
          width="81.3719"
          height="34.3825"
          rx="6"
          transform="matrix(0.866044 -0.499967 0.866044 0.499967 42 99.5161)"
          fill="white"
        />
        <rect
          x="0.519033"
          width="80.7726"
          height="33.7832"
          rx="5.70034"
          transform="matrix(0.866044 -0.499967 0.866044 0.499967 42.0695 99.7756)"
          stroke="#E6E9EC"
          strokeWidth="0.599315"
        />
      </g>
      <rect
        width="33.2364"
        height="9.16867"
        rx="4.58434"
        transform="matrix(0.866044 -0.499967 0.866044 0.499967 84.6797 90.9209)"
        fill="#E6E9EC"
      />
      <rect
        width="12.6069"
        height="3.43825"
        rx="1.71913"
        transform="matrix(0.866044 -0.499967 0.866044 0.499967 78.7241 87.4829)"
        fill="#E6E9EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.8391 94.6458C75.1885 96.0021 75.1885 98.201 72.8391 99.5573C70.4898 100.914 66.6808 100.914 64.3315 99.5573C61.9821 98.201 61.9822 96.0021 64.3315 94.6458C66.6808 93.2896 70.4898 93.2895 72.8391 94.6458ZM71.4212 95.4644C72.9874 96.3686 72.9874 97.8345 71.4212 98.7387C69.8549 99.6429 67.3156 99.6429 65.7494 98.7387C64.1832 97.8345 64.1832 96.3686 65.7494 95.4644C67.3156 94.5602 69.855 94.5602 71.4212 95.4644Z"
        fill="#E6E9EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.9048 102.781C71.108 101.889 71.8815 100.929 73.5483 99.9665C75.2151 99.0043 76.878 98.5577 78.423 98.4405C79.9564 98.324 81.2736 98.5395 82.2411 98.8025C83.0926 99.0341 83.5478 99.4791 83.6046 99.948C83.6593 100.401 83.3471 100.858 82.7649 101.194L75.6752 105.287C75.093 105.623 74.3006 105.804 73.5162 105.772C72.7039 105.739 71.933 105.476 71.5321 104.985C71.0765 104.426 70.7032 103.666 70.9048 102.781ZM72.893 102.932C72.7543 103.541 73.0068 104.076 73.3463 104.492C73.4171 104.578 73.5182 104.612 73.6558 104.617C73.8212 104.624 74.0563 104.585 74.2573 104.469L81.347 100.376C81.5479 100.26 81.6157 100.124 81.6041 100.029C81.5946 99.9492 81.5371 99.8908 81.3868 99.8499C80.666 99.6539 79.7398 99.5081 78.6843 99.5882C77.6406 99.6674 76.3692 99.9751 74.9662 100.785C73.5633 101.595 73.0303 102.329 72.893 102.932Z"
        fill="#E6E9EC"
      />
      <g filter="url(#filter1_d_3996_110275)">
        <rect
          width="81.3719"
          height="34.3825"
          rx="6"
          transform="matrix(0.866044 -0.499967 0.866044 0.499967 22 70.4604)"
          fill="white"
        />
        <rect
          x="0.519033"
          width="80.7726"
          height="33.7832"
          rx="5.70034"
          transform="matrix(0.866044 -0.499967 0.866044 0.499967 22.0695 70.7199)"
          stroke="#E6E9EC"
          strokeWidth="0.599315"
        />
      </g>
      <rect
        width="33.2364"
        height="9.16867"
        rx="4.58434"
        transform="matrix(0.866044 -0.499967 0.866044 0.499967 64.6802 61.8652)"
        fill="#E6E9EC"
      />
      <rect
        width="12.6069"
        height="3.43825"
        rx="1.71913"
        transform="matrix(0.866044 -0.499967 0.866044 0.499967 58.7246 58.4272)"
        fill="#E6E9EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.8396 65.5901C55.1889 66.9464 55.1889 69.1454 52.8396 70.5016C50.4903 71.8579 46.6813 71.8579 44.332 70.5016C41.9826 69.1454 41.9827 66.9464 44.332 65.5901C46.6813 64.2339 50.4903 64.2339 52.8396 65.5901ZM51.4217 66.4087C52.9879 67.3129 52.9879 68.7789 51.4217 69.683C49.8554 70.5872 47.3161 70.5872 45.7499 69.683C44.1837 68.7789 44.1837 67.3129 45.7499 66.4087C47.3161 65.5046 49.8555 65.5046 51.4217 66.4087Z"
        fill="#E6E9EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.9053 73.725C51.1084 72.8331 51.882 71.8731 53.5488 70.9108C55.2156 69.9486 56.8785 69.502 58.4235 69.3848C59.9569 69.2684 61.2741 69.4839 62.2416 69.7469C63.0931 69.9784 63.5483 70.4234 63.6051 70.8923C63.6598 71.3451 63.3476 71.8026 62.7654 72.1387L55.6757 76.2316C55.0935 76.5677 54.301 76.7479 53.5167 76.7163C52.7044 76.6836 51.9335 76.4208 51.5325 75.9292C51.077 75.3706 50.7037 74.6102 50.9053 73.725ZM52.8935 73.8759C52.7547 74.4852 53.0073 75.0199 53.3468 75.436C53.4176 75.5228 53.5187 75.556 53.6563 75.5615C53.8217 75.5682 54.0568 75.529 54.2578 75.413L61.3475 71.3201C61.5484 71.2041 61.6162 71.0684 61.6046 70.9729C61.595 70.8935 61.5376 70.8351 61.3872 70.7942C60.6665 70.5983 59.7402 70.4524 58.6848 70.5325C57.641 70.6118 56.3697 70.9195 54.9667 71.7294C53.5638 72.5394 53.0307 73.2733 52.8935 73.8759Z"
        fill="#E6E9EC"
      />
      <g filter="url(#filter2_d_3996_110275)">
        <rect
          width="81.3719"
          height="34.3825"
          rx="6"
          transform="matrix(0.866044 -0.499967 0.866044 0.499967 2 41.6831)"
          fill="white"
        />
        <rect
          x="0.519033"
          width="80.7726"
          height="33.7832"
          rx="5.70034"
          transform="matrix(0.866044 -0.499967 0.866044 0.499967 2.06953 41.9426)"
          stroke="#E6E9EC"
          strokeWidth="0.599315"
        />
      </g>
      <rect
        width="33.2364"
        height="9.16867"
        rx="4.58434"
        transform="matrix(0.866044 -0.499967 0.866044 0.499967 44.6802 33.0879)"
        fill="#6D61FF"
      />
      <rect
        width="12.6069"
        height="3.43825"
        rx="1.71913"
        transform="matrix(0.866044 -0.499967 0.866044 0.499967 38.7246 29.6499)"
        fill="#6D61FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.8396 36.8128C35.1889 38.1691 35.1889 40.368 32.8396 41.7243C30.4903 43.0805 26.6813 43.0805 24.332 41.7243C21.9826 40.368 21.9827 38.1691 24.332 36.8128C26.6813 35.4565 30.4903 35.4565 32.8396 36.8128ZM31.4217 37.6314C32.9879 38.5356 32.9879 40.0015 31.4217 40.9057C29.8554 41.8099 27.3161 41.8099 25.7499 40.9057C24.1837 40.0015 24.1837 38.5356 25.7499 37.6314C27.3161 36.7272 29.8555 36.7272 31.4217 37.6314Z"
        fill="#E6E9EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.9053 44.9476C31.1084 44.0557 31.882 43.0957 33.5488 42.1335C35.2156 41.1713 36.8785 40.7247 38.4235 40.6075C39.9569 40.491 41.2741 40.7065 42.2416 40.9695C43.0931 41.2011 43.5483 41.6461 43.6051 42.115C43.6598 42.5678 43.3476 43.0253 42.7654 43.3614L35.6757 47.4543C35.0935 47.7903 34.301 47.9706 33.5167 47.939C32.7044 47.9062 31.9335 47.6434 31.5325 47.1518C31.077 46.5933 30.7037 45.8329 30.9053 44.9476ZM32.8935 45.0985C32.7547 45.7078 33.0073 46.2425 33.3468 46.6587C33.4176 46.7455 33.5187 46.7786 33.6563 46.7842C33.8217 46.7908 34.0568 46.7517 34.2578 46.6357L41.3475 42.5428C41.5484 42.4268 41.6162 42.2911 41.6046 42.1956C41.595 42.1161 41.5376 42.0578 41.3872 42.0169C40.6665 41.8209 39.7402 41.6751 38.6848 41.7552C37.641 41.8344 36.3697 42.1421 34.9667 42.9521C33.5638 43.762 33.0307 44.496 32.8935 45.0985Z"
        fill="#E6E9EC"
      />
      <defs>
        <filter
          id="filter0_d_3996_110275"
          x="40.2494"
          y="58.1926"
          width="103.75"
          height="63.9484"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.39726" />
          <feGaussianBlur stdDeviation="2.39726" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3996_110275" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3996_110275" result="shape" />
        </filter>
        <filter
          id="filter1_d_3996_110275"
          x="20.2494"
          y="29.1369"
          width="103.75"
          height="63.9484"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.39726" />
          <feGaussianBlur stdDeviation="2.39726" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3996_110275" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3996_110275" result="shape" />
        </filter>
        <filter
          id="filter2_d_3996_110275"
          x="0.249424"
          y="0.359576"
          width="103.75"
          height="63.9484"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.39726" />
          <feGaussianBlur stdDeviation="2.39726" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3996_110275" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3996_110275" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default Customers;
