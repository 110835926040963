import { useQuery } from '@apollo/client';
import { GET_FILTER_VALUES } from '../../../../../../../utils/queries/properties/editorPropertiesQueries';
import useGetAvailableDataProviders from '../../hooks/useGetAvailableDataProviders';
import { useNodeDataToSave } from './useNodeDataToSave';
import { useFlatFlowProperties } from './useFlatFlowProperties';

const useFieldsValues = (property, matchingType) => {
  const [{ selectedDataProvider }] = useNodeDataToSave();
  const { availableDataProviders } = useGetAvailableDataProviders();

  const dataProvider = availableDataProviders?.find(({ type }) => type === selectedDataProvider);

  const { getAvailableProperties, loading: propertiesLoading } = useFlatFlowProperties(
    [matchingType],
    property === 'date'
  );

  const { data, valuesLoading } = useQuery(GET_FILTER_VALUES, {
    variables: {
      type: dataProvider?.type,
      property,
    },
  });
  const items = data?.getFilterValues[0][property === 'date' ? 'dateRange' : property];

  const additionalItems =
    getAvailableProperties[0]?.properties?.map(({ label, key }) => ({ value: key, title: label })) || [];

  const options =
    items?.map((item) =>
      item.id
        ? { value: property?.includes('paymentGateway') ? item.name : item.id, title: item.name }
        : { value: item, title: item }
    ) || [];

  return {
    items,
    getAvailableProperties,
    options: [...additionalItems, ...options],
    loading: propertiesLoading || valuesLoading,
  };
};

export default useFieldsValues;
