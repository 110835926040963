import React from 'react';
import { arrayOf, number, oneOf, shape, string } from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import {
  AddIfElseBox,
  AddTestBox,
  ConditionBoxAccordion,
  StyledAccordionSummary,
  StyledBox,
  StyledToggleButton,
  StyledToggleButtonGroup,
  SummaryTitle,
} from './styled';
import THEME from '../../../../../../../constants/theme';
import { DragHandleIcon } from '../../../../../../../assets/icons';
import { ButtonLink, P14M } from '../../../../../../atoms';
import { LogicalOperator } from '../constants';
import Test from '../Test';
import { createLogicOperator } from '../Conditions/utils';
import useNewCondition from './useNewCondition';
import { IconBox, TitleBox } from '../Test/styled';
import Trash3 from '../../../../../../../assets/icons/Trash3';

const logical = [
  { value: LogicalOperator.AND, title: 'match all' },
  { value: LogicalOperator.OR, title: 'match any' },
];

const NewCondition = ({ provided, index, condition, conditions }) => {
  const { t } = useTranslation();
  const {
    expanded,
    edgeNumber,
    isShowIfElse,
    isShowAddRule,
    handleChangeExpanded,
    updateConditionLogicalOperator,
    removeConditionTest,
    handleAddElseCondition,
    handleAddIfCondition,
    handleAddRule,
    tests,
    updateTest,
    removeCondition,
  } = useNewCondition({ condition, conditions, index });
  const hasTests = !!tests?.length;
  const hasMoreThanOneTest = tests?.length > 1;
  return (
    <ConditionBoxAccordion
      {...(provided && { ...provided.draggableProps, ...provided.dragHandleProps })}
      ref={provided?.innerRef}
      expanded={expanded}
      onChange={handleChangeExpanded}
      $expanded={expanded}
    >
      <StyledAccordionSummary
        $expanded={expanded}
        expandIcon={<ExpandMoreIcon color="action" />}
        aria-controls="new-condition-content"
        id="new-condition-content"
      >
        <>
          <SummaryTitle>{edgeNumber}</SummaryTitle>
          <DragHandleIcon fill={expanded ? THEME.primaryColors.blackMain : THEME.greyColors.grey600} />
        </>
      </StyledAccordionSummary>
      {hasTests && (
        <>
          {hasMoreThanOneTest && (
            <StyledBox padding="8px 8px 0 8px" justifyContent="space-between">
              <StyledToggleButtonGroup width="100%" value={createLogicOperator(condition?.logicalOperator)}>
                {logical?.map(({ value, title }) => (
                  <StyledToggleButton key={value} value={value} onClick={() => updateConditionLogicalOperator(value)}>
                    {t(title)}
                  </StyledToggleButton>
                ))}
              </StyledToggleButtonGroup>
            </StyledBox>
          )}
          <TitleBox>
            <P14M>{t('IF')}</P14M>
            <IconBox onClick={() => removeCondition(condition?.id)}>
              <Trash3 />
            </IconBox>
          </TitleBox>
          {tests.map((test, i) => (
            <Test
              key={test.id}
              test={test}
              logicalOperator={condition?.logicalOperator}
              tests={tests}
              updateTest={updateTest}
              removeTest={removeConditionTest}
              hasMoreThanOneCondition={conditions.length > 1}
              index={i}
              nodeDataType={condition?.selectedDataType}
            />
          ))}
        </>
      )}
      {isShowIfElse && (
        <AddIfElseBox>
          <ButtonLink onClick={handleAddIfCondition} color={THEME.primaryColors.primary}>
            {t('add IF')}
          </ButtonLink>
          <ButtonLink onClick={handleAddElseCondition} color={THEME.primaryColors.primary}>
            {t('add ELSE')}
          </ButtonLink>
        </AddIfElseBox>
      )}
      {isShowAddRule && (
        <AddTestBox $hasTests={hasTests}>
          <ButtonLink onClick={handleAddRule} color={THEME.primaryColors.primary}>
            {t('Add Rule')}
          </ButtonLink>
        </AddTestBox>
      )}
    </ConditionBoxAccordion>
  );
};

NewCondition.propTypes = {
  provided: shape({}),
  snapshot: shape({}),
  condition: shape({
    id: string,
    tests: arrayOf(
      shape({
        nextStepId: string,
        left: string,
        op: string,
        right: string,
      })
    ),
  }),
  conditions: arrayOf(
    shape({
      id: string,
      tests: arrayOf(
        shape({
          left: string,
          op: string,
          right: string,
          logicalOperator: oneOf(Object.values(LogicalOperator)),
        })
      ),
    })
  ),
  index: number,
};

NewCondition.defaultProps = {
  provided: null,
  snapshot: null,
  condition: {},
  conditions: [],
  index: 0,
};

export default NewCondition;
