import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useFlowEditorContext } from '../../FlowEditor/context';
import { GET_RETRIES_FOR_INSTANCE } from '../../../utils/queries/flows/queries';

export const useGetRetriesForInstance = () => {
  const { selectedInstance } = useFlowEditorContext();
  const { data, loading } = useQuery(GET_RETRIES_FOR_INSTANCE, {
    variables: { instanceId: selectedInstance?.originalInstanceId || selectedInstance?.id },
    skip: !selectedInstance,
  });
  const flowInstanceRetries = useMemo(() => data?.getRetriesForInstance || [], [data]);

  return {
    loading,
    flowInstanceRetries,
  };
};
