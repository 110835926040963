const parseCategoryLabel = (category) =>
  category
    ?.toLowerCase()
    ?.split('_')
    ?.join(' ');

const SIDEBAR_WIDTH = '248px';
const SIDEBAR_WIDTH_IN_MODAL = '231px';
const CATEGORY_KEY = 'automationCategories';
const RECOMMENDED_KEY = 'recommended';
const POPULAR_KEY = 'popular';
const PAYMENT_FLOW_KEY = 'paymentFlow';

const TABS = [{ label: 'All' }, { label: 'Popular' }];

export {
  SIDEBAR_WIDTH,
  SIDEBAR_WIDTH_IN_MODAL,
  CATEGORY_KEY,
  RECOMMENDED_KEY,
  POPULAR_KEY,
  PAYMENT_FLOW_KEY,
  parseCategoryLabel,
  TABS,
};
