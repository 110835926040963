import React, { useRef } from 'react';
import { string, bool, func } from 'prop-types';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';
import CloseIcon from '@mui/icons-material/Close';
import { StyledBox, StyledItemBox } from './styled';
import { L12B } from '../../../atoms/Typography/L12B';
import { useFlowEditorContext } from '../../context';
import { repopulateEdges } from '../../utils/facades';
import { deleteCondition } from './utils';
import THEME from '../../../../constants/theme';
import { filterEdges, filterNodes } from '../../utils/filterElements';

const iconProps = {
  color: 'inherit',
  fontSize: 'small',
};

const conditionItemTypes = {
  other: 'other',
  value: 'value',
  delete: 'delete',
};

const conditionItems = [
  {
    icon: <MoreHorizIcon {...iconProps} />,
    type: conditionItemTypes.other,
  },
  {
    icon: <LabelOutlinedIcon {...iconProps} />,
    type: conditionItemTypes.value,
  },
  {
    icon: <CloseIcon {...iconProps} />,
    type: conditionItemTypes.delete,
  },
];

const AddConditionModal = ({
  id,
  isOpen,
  stepId,
  targetId,
  sourceHandleId,
  targetHandleId,
  setIsShowAddConditionModal,
  setIsShowChooseValueConditionModal,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const { nodes, setNodes, setEdges, saveConfigurationAsync, setSelectedNodeId } = useFlowEditorContext();

  useClickAway(ref, () => {
    setSelectedNodeId(null);
  });

  const handleOther = async () => {
    const updatedNodes = nodes.map((node) =>
      node.id === stepId
        ? {
            ...node,
            data: {
              ...node.data,
              elseStepId: targetId,
              conditions: node?.data?.conditions?.filter((c) => c?.nextStepId !== targetId),
              layout: {
                ...node?.data?.layout,
                uiMetadata: {
                  ...node.data?.layout?.uiMetadata,
                  [targetId]: {
                    sourceHandle: sourceHandleId,
                    targetHandle: targetHandleId,
                  },
                },
              },
            },
          }
        : node
    );
    const _nodes = repopulateEdges(updatedNodes);
    const filteredNodes = filterNodes(_nodes);
    setNodes(filteredNodes);
    setEdges(filterEdges(_nodes));
    await saveConfigurationAsync(filteredNodes);
  };

  const handleDelete = async () => {
    const newNodes = deleteCondition({ nodes, edgeId: id, stepId, targetId });
    setNodes(newNodes);
    setIsShowAddConditionModal(false);
    setEdges(filterEdges(repopulateEdges(newNodes)));
    await saveConfigurationAsync(newNodes);
  };

  const handleClick = async (type) => {
    switch (type) {
      case conditionItemTypes.value:
        setIsShowAddConditionModal(false);
        setIsShowChooseValueConditionModal(true);
        break;
      case conditionItemTypes.other:
        setIsShowAddConditionModal(false);
        await handleOther();
        break;
      case conditionItemTypes.delete:
        setIsShowAddConditionModal(false);
        await handleDelete();
        break;
      default:
        setIsShowAddConditionModal(false);
    }
  };

  return (
    isOpen && (
      <StyledBox width="176px" ref={ref}>
        {conditionItems.map(({ type }) => (
          <StyledItemBox key={type} onClick={() => handleClick(type)}>
            <L12B
              textTransform="capitalize"
              color={type === conditionItemTypes.delete ? THEME.secondaryColors.nodeError : THEME.primaryColors.black}
            >
              {t(type)}
            </L12B>
          </StyledItemBox>
        ))}
      </StyledBox>
    )
  );
};

AddConditionModal.propTypes = {
  id: string.isRequired,
  isOpen: bool.isRequired,
  stepId: string.isRequired,
  targetId: string.isRequired,
  setIsShowAddConditionModal: func.isRequired,
  setIsShowChooseValueConditionModal: func.isRequired,
  sourceHandleId: string,
  targetHandleId: string,
};

AddConditionModal.defaultProps = {
  sourceHandleId: '',
  targetHandleId: '',
};

export { AddConditionModal };
