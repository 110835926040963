import React from 'react';
import { string, shape, arrayOf, oneOfType } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from './styled';
import { P12, L14M } from '../../../../../../../../components/atoms';
import { parseIntegrationText } from '../../../../constant';
import { INTEGRATION_FN_MAP, MENU_ITEMS_MAP } from '../constant';
import THEME from '../../../../../../../../constants/theme';
import ChevronDown from '../../../../../../../../assets/icons/ChevronDown';

const SetupItem = ({ integrationItem }) => {
  const { title, descriptions } = INTEGRATION_FN_MAP[integrationItem.dataKey](integrationItem);
  const isArrayDescription = Array.isArray(descriptions);

  // This needs refactoring
  if (integrationItem.dataKey === MENU_ITEMS_MAP.integration && isEmpty(descriptions)) return null;

  return (
    <StyledAccordion>
      <StyledAccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        expandIcon={<ChevronDown color={THEME.greyColors.grey1} width={20} height={20} />}
      >
        <L14M>{title} </L14M>
      </StyledAccordionSummary>

      <StyledAccordionDetails>
        {isArrayDescription ? (
          descriptions.map((desc) => (
            <P12 key={desc} margin="0 0 5px 0" color={THEME.greyColors.grey1}>
              {parseIntegrationText(desc)}
            </P12>
          ))
        ) : (
          <P12 color={THEME.greyColors.grey1}>{descriptions}</P12>
        )}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

SetupItem.propTypes = {
  integrationItem: shape({
    dataKey: string.isRequired,
    title: string.isRequired,
    data: oneOfType([string, arrayOf(string)]),
    subData: string,
  }).isRequired,
};

export default SetupItem;
