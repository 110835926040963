import styled from 'styled-components';

const StyledDetailsTab = styled.div`
  width: 100%;
  display: flex;
  margin-top: 32px;

  @media screen and (min-width: 1305px) {
    max-width: 570px;
  }
`;

const StyledPaymentMethods = styled.div`
  display: flex;
  flex-direction: ${({ isConnected }) => (isConnected ? 'column' : 'row')};
  ${({ isConnected }) => !isConnected && 'flex-wrap: wrap'};
  padding: 8px;
`;

export { StyledDetailsTab, StyledPaymentMethods };
