import React from 'react';
import WebhookGeneratedUrlField from './fields/WebhookGeneratedUrlField';
import WebhookBasicAuth from './fields/WebhookBasicAuth';
import JsonInputField from './fields/JsonInputField';

const WebhookTriggerStep = () => {
  return (
    <>
      <WebhookGeneratedUrlField marginBottom="0" stepType="TRIGGER" />

      <WebhookBasicAuth noMargin />

      <JsonInputField title="Body JSON Structure" />
    </>
  );
};

export default WebhookTriggerStep;
