import React from 'react';
import { number, string } from 'prop-types';

const Switch = ({ fill, size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.50887 12.5147L5.44078 10.0721C5.19671 9.78468 5.39507 9.33331 5.76548 9.33331H9.90167C9.98457 9.33324 10.0657 9.35786 10.1354 9.40422C10.2051 9.45059 10.2604 9.51673 10.2947 9.59473C10.329 9.67273 10.3407 9.75928 10.3286 9.84402C10.3165 9.92876 10.281 10.0081 10.2264 10.0725L8.15828 12.5143C8.1178 12.5621 8.06789 12.6005 8.01188 12.6268C7.95588 12.653 7.89508 12.6666 7.83357 12.6666C7.77206 12.6666 7.71127 12.653 7.65526 12.6268C7.59926 12.6005 7.54934 12.5621 7.50887 12.5143V12.5147Z"
        fill={fill}
      />
      <path
        d="M7.50887 3.48522L5.44078 5.92786C5.19671 6.21526 5.39507 6.66663 5.76548 6.66663H9.90167C9.98457 6.6667 10.0657 6.64208 10.1354 6.59572C10.2051 6.54935 10.2604 6.48321 10.2947 6.40521C10.329 6.32721 10.3407 6.24066 10.3286 6.15592C10.3165 6.07118 10.281 5.99185 10.2264 5.92742L8.15828 3.48567C8.1178 3.43781 8.06789 3.39945 8.01188 3.37317C7.95588 3.34689 7.89508 3.33329 7.83357 3.33329C7.77206 3.33329 7.71127 3.34689 7.65526 3.37317C7.59926 3.39945 7.54934 3.43781 7.50887 3.48567V3.48522Z"
        fill={fill}
      />
    </svg>
  );
};
Switch.propTypes = {
  fill: string,
  size: number,
};
Switch.defaultProps = {
  fill: '#1F25F4',
  size: 16,
};

export default Switch;
