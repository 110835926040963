import React from 'react';

const BarOne = () => {
  return (
    <svg width="176" height="4" viewBox="0 0 176 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="176" height="4" fill="#E6E9EC" />
    </svg>
  );
};

export default BarOne;
