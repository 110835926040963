import React, { useRef, useState } from 'react';
import ConfirmModal from '../components/ConfirmModal';

export const useConfirmationModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const confirmationPromise = useRef();

  const openConfirmationModal = () => {
    setIsOpen(true);
  };
  const closeConfirmationModal = () => {
    confirmationPromise.current(false);
    setIsOpen(false);
  };
  const confirm = () => {
    confirmationPromise.current(true);
    setIsOpen(false);
  };

  const getRemoveNodeConfirmation = () => {
    openConfirmationModal();
    return new Promise((resolve) => {
      confirmationPromise.current = resolve;
    });
  };

  const renderConfirmationModal = () => {
    return (
      <ConfirmModal
        text="A Trigger Node is required to initiate your Automation inside the WhenThen platform. If you delete this trigger, you'll have to replace it with another one"
        confirmActionText="Remove"
        closeModal={closeConfirmationModal}
        title="Delete Trigger Node?"
        isOpen={isOpen}
        handleSubmit={confirm}
      />
    );
  };

  return {
    getRemoveNodeConfirmation,
    renderConfirmationModal,
  };
};
