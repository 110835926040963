import React, { useState } from 'react';
import { Box } from '@mui/material';
import { shape } from 'prop-types';
import THEME from '../../constants/theme';
import { StyledToggleGroup, StyledToggleButton } from './styled';
import LogsRow from './LogsRow';

const LogsModal = ({ selectedStep }) => {
  const [activeTab, setActiveTab] = useState('input');

  const handleTabChange = (e, newTab) => {
    if (newTab) {
      setActiveTab(newTab);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      flex={1}
      bgcolor={THEME.primaryColors.black}
      height="100%"
      boxSizing="border-box"
      p="24px 0 0"
    >
      <StyledToggleGroup value={activeTab} onChange={handleTabChange} exclusive>
        <StyledToggleButton value="input">Input</StyledToggleButton>
        <StyledToggleButton value="log">Log</StyledToggleButton>
        <StyledToggleButton value="output">Output</StyledToggleButton>
      </StyledToggleGroup>
      <Box width="100%" overflow="auto" py="30px">
        {activeTab === 'input' || activeTab === 'output'
          ? selectedStep[activeTab]?.map(({ key, value }) => (
              <LogsRow key={`${key}-${activeTab}`} name={key} value={value} />
            ))
          : selectedStep?.logs?.map(({ id, message }) => <LogsRow key={id} value={message} />)}
      </Box>
    </Box>
  );
};

LogsModal.propTypes = {
  selectedStep: shape({}).isRequired,
};

export default LogsModal;
