import React from 'react';
import { Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { string } from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    width: ({ width }) => width || 'auto',
    height: ({ height }) => height || '40px',

    fontSize: ({ fontSize }) => fontSize || '14px',
    fontWeight: ({ fontWeight }) => fontWeight || '400',
    // padding: ({ size }) => {
    //   if (size === 'smaller') {
    //     return '12px 16px';
    //   }
    //   return '8px 16px';
    // },
    marginRight: 0,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: ({ backgroundColor }) => backgroundColor || '#F5F6F7',
    transition: 'all .3s ease-out',
    '&:hover': {
      backgroundColor: ({ hoverbg }) => hoverbg || 'rgba(230, 233, 236, 0.5)',
    },
    '& .check-icon': {
      display: 'none',
      opacity: '0',
      visibility: 'hidden',
    },

    '& .MuiSelect-icon': {
      top: 'calc(50% - 12px)',
    },
  },
  selectMenu: {
    border: 'none',
    padding: '0px',
  },
}));

const CustomSelect = ({ margin, backgroundColor, hoverbg, boxSizing, ...props }) => {
  const classes = useStyles({ margin, backgroundColor, hoverbg, boxSizing, ...props });

  return <Select {...props} classes={classes} />;
};

CustomSelect.propTypes = {
  margin: string,
  backgroundColor: string,
  hoverbg: string,
  boxSizing: string,
};

CustomSelect.defaultProps = {
  margin: null,
  backgroundColor: null,
  hoverbg: null,
  boxSizing: null,
};

export default CustomSelect;
