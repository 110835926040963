import React, { useEffect, useState } from 'react';
import { string, arrayOf, shape, bool, number } from 'prop-types';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import isEmpty from 'lodash/isEmpty';
import { useDropzone } from 'react-dropzone';
import { useLazyQuery } from '@apollo/client';
import { useNotificationManager, useFileUploadNotification } from '../../../../../../../hooks/useNotificationManager';
import DragAndDropZone from './DragAndDropZone';
import { P } from '../../../../../../../components/atoms';
import { GET_UPLOAD_URL } from '../../../../../../../utils/queries/uploadFile/getUploadUrl';
import AttachmentItem from './AttachmentItem';
import { saveAttachment } from './constant';
import { TOAST_TIMEOUT } from '../../../../../../../constants/toasts';
import useDropZoneFileConfig from './hooks/useDropZoneFileConfig';
import { FILE_UPLOAD_URL_TYPE } from '../../../constant';
import { StyledError } from '../../../../../../../components/forms/_common/styled';
import THEME from '../../../../../../../constants/theme';
import StyledTooltip from '../../../../../../../components/atoms/StyledTooltip';

const TOAST_TITLE = 'File upload';

const Attachment = ({
  name,
  label,
  inputId,
  list,
  disabled,
  filesConfig,
  attachments,
  tooltip,
  requiredLabel,
  shouldShowError,
  errors,
}) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const { setFieldValue } = useFormikContext();
  const { dropZoneConfig, customValidator } = useDropZoneFileConfig(filesConfig);
  const [uploadedFile, setUploadedFile] = useState({});

  const inputFieldError = errors?.[name];

  useEffect(() => {
    if (attachments[name]) {
      setUploadedFile((prev) => ({ ...prev, ...attachments[name] }));
    }
  }, [attachments, name]);

  const [getUploadUrl, { error, loading }] = useLazyQuery(GET_UPLOAD_URL, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { name: fileName, type: contentType } = files[0];
      const { objectKey, accessUrl } = data.getUploadUrl;

      setFieldValue(name, {
        fileName,
        objectKey,
        contentType,
        evidenceCategory: inputId,
        evidenceName: name,
      });

      setUploadedFile((prev) => ({ ...prev, accessUrl }));

      if (files) {
        saveAttachment(data.getUploadUrl, files, setFiles);
      }
    },
  });

  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
    setUploadedFile({ fileName: acceptedFiles[0].name });
    getUploadUrl({
      variables: { urlType: FILE_UPLOAD_URL_TYPE.payments },
    });
  };

  const { fileRejections, isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: list?.length > 0 || disabled,
    ...dropZoneConfig,
    validator: customValidator,
  });

  useNotificationManager(t('Error'), t(error?.message), TOAST_TITLE, TOAST_TIMEOUT);
  useFileUploadNotification('', TOAST_TITLE, fileRejections);

  return (
    <Box component="section" mb="24px">
      <Box display="flex" alignItems="center" marginBottom="6px">
        <P fontSize="12px !important" fontWeight="500" lineHeight="12px" textAlign="left">
          {t(`${label} ${requiredLabel}`)}
        </P>
        <StyledTooltip title={tooltip} />
      </Box>

      <Box position="relative">
        <Box width="100%">
          <DragAndDropZone
            dragging={isDragActive}
            getRootProps={getRootProps}
            disabled={disabled || list?.length > 0}
          />
          <input {...getInputProps()} />
        </Box>

        {!isEmpty(uploadedFile) && (
          <Box mt="16px">
            <AttachmentItem
              fieldName={name}
              fileName={uploadedFile.fileName}
              disabled={disabled}
              setUploadedFile={setUploadedFile}
              loading={loading}
              accessUrl={uploadedFile.accessUrl}
            />
          </Box>
        )}
      </Box>

      {shouldShowError && inputFieldError && (
        <Box marginTop="6px">
          <StyledError data-cy="error" color={THEME.secondaryColors.danger}>
            {t(inputFieldError)}
          </StyledError>
        </Box>
      )}
    </Box>
  );
};

Attachment.propTypes = {
  list: arrayOf(
    shape({
      contentType: string.isRequired,
      fileName: string.isRequired,
      evidenceCategory: string.isRequired,
      objectKey: string.isRequired,
    })
  ),
  inputId: string.isRequired,
  name: string.isRequired,
  label: string.isRequired,
  disabled: bool,
  filesConfig: arrayOf(shape({ contentType: string.isRequired, maxFileSizeMB: number.isRequired })).isRequired,
  attachments: shape({}),
  tooltip: string,
  requiredLabel: string,
  errors: shape({}),
  shouldShowError: bool,
};

Attachment.defaultProps = {
  list: [],
  attachments: {},
  disabled: false,
  tooltip: '',
  requiredLabel: '',
  errors: {},
  shouldShowError: false,
};

export default Attachment;
