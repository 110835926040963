import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, gql } from '@apollo/client';
import { NotificationManager } from 'react-notifications';
import { ButtonRounded, CircularLoader } from '../../atoms';
import { TOAST_TIMEOUT } from '../../../constants/toasts';
import ToastCustomContainer from '../../ToastCustomContainer';

// TODO : need to improve the toast message
const TOAST_TITLE = 'Action';
const TOAST_SUCCESS_MESSAGE = 'Action was successful';
const TOAST_ERROR_MESSAGE = 'Error occurred';

const ActionButtonMutation = ({ color, children, action, variables, onSuccess }) => {
  const [promise, { loading }] = useMutation(gql(action), { variables });

  const onClick = () => {
    promise()
      .then(({ errors }) => {
        if (errors) {
          NotificationManager.error(
            <ToastCustomContainer message={TOAST_ERROR_MESSAGE} title={TOAST_TITLE} />,
            null,
            TOAST_TIMEOUT
          );

          return;
        }

        onSuccess();

        NotificationManager.success(
          <ToastCustomContainer message={TOAST_SUCCESS_MESSAGE} title={TOAST_TITLE} />,
          null,
          TOAST_TIMEOUT
        );
      })
      .catch((err) => {
        NotificationManager.error(<ToastCustomContainer message={err} title={TOAST_TITLE} />, null, TOAST_TIMEOUT);
      });
  };

  return (
    <ButtonRounded
      type="button"
      variant="contained"
      color={color}
      disabled={loading}
      onClick={onClick}
      endIcon={loading && <CircularLoader bgcolor="#fff" size="16px" thickness={5} />}
    >
      {children}
    </ButtonRounded>
  );
};

ActionButtonMutation.propTypes = {
  action: PropTypes.string.isRequired,
  variables: PropTypes.shape({}).isRequired,
  color: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ActionButtonMutation;
