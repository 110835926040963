import React from 'react';

const PaymentMethod = ({ ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4.84212C1.63181 4.84212 1.33333 5.1406 1.33333 5.50879V13.5088C1.33333 13.877 1.63181 14.1755 2 14.1755H14C14.3682 14.1755 14.6667 13.877 14.6667 13.5088V5.50879C14.6667 5.1406 14.3682 4.84212 14 4.84212H2ZM0 5.50879C0 4.40422 0.895431 3.50879 2 3.50879H14C15.1046 3.50879 16 4.40422 16 5.50879V13.5088C16 14.6134 15.1046 15.5088 14 15.5088H2C0.895431 15.5088 0 14.6134 0 13.5088V5.50879Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.86191 4.14633C1.75426 3.79422 1.95243 3.42152 2.30453 3.31387L11.23 0.585072C12.2863 0.262127 13.4044 0.856633 13.7274 1.91294L13.7294 1.91951L14.2723 3.76308C14.3763 4.11627 14.1743 4.48691 13.8211 4.59092C13.4679 4.69494 13.0973 4.49294 12.9933 4.13974L12.4515 2.30005C12.3428 1.94965 11.9711 1.75277 11.6199 1.86015L2.69436 4.58895C2.34226 4.6966 1.96955 4.49843 1.86191 4.14633Z"
        fill="white"
      />
    </svg>
  );
};

export default PaymentMethod;
