import React from 'react';

const Play = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="56" height="56" rx="28" fill="black" fillOpacity="0.4" />
      <path
        d="M28 29.6733V26.3267L30.603 28L28 29.6733Z"
        stroke="white"
        strokeWidth="8"
        strokeLinecap="square"
        strokeLinejoin="miter"
      />
    </svg>
  );
};

export default Play;
