import React from 'react';
import { arrayOf, shape, string, oneOf, func } from 'prop-types';
import Box from '@mui/material/Box';
import { UiActionProvider } from '../UiActionProvider';
import { CircleImage, P14, P14M } from '../../../atoms';
import { StyledActionItem, TimeStamp, ActionsButtons, Content } from './styled';
import { formatTimeStamp, ACTION_ROLES, ACTION_TYPE_NAMES, ACTION_STYLES } from '../../constant';
import THEME from '../../../../constants/theme';
import { ACTIONS } from '../constant';
import logo from '../../../../assets/img/WhenThenLogo.png';

const ActionItem = ({ title, description, timeStamp, actions, toggleNotification, onSuccess }) => {
  return (
    <StyledActionItem>
      <Content>
        <CircleImage size="40" src={logo} />
        <Box ml="16px" flex={1}>
          <Box display="flex" flex={1} alignItems="center" justifyContent="space-between">
            <P14M fontWeight="500">{title}</P14M>
            <TimeStamp>{formatTimeStamp(timeStamp)}</TimeStamp>
          </Box>

          <Box display="flex" mt="4px">
            {description && (
              <P14 color={THEME.greyColors.grey18} maxWidth="340px" overflow="hidden" textOverflow="ellipsis" noWrap>
                {description || 'Some text'}
              </P14>
            )}
          </Box>
          {actions?.length > 0 && (
            <UiActionProvider>
              <ActionsButtons>
                {actions.map((action) => {
                  const getAction = ACTIONS[action.__typename];

                  if (!getAction) {
                    return null;
                  }

                  const { Button, ...uiProps } = getAction({ ...action, toggleNotification });

                  return (
                    <Box key={action.label} order={action.role === ACTION_ROLES.primary ? 0 : 1}>
                      <Button color={action.style.toLowerCase()} onSuccess={onSuccess} {...uiProps}>
                        {action.label}
                      </Button>
                    </Box>
                  );
                })}
              </ActionsButtons>
            </UiActionProvider>
          )}
        </Box>
      </Content>
    </StyledActionItem>
  );
};

ActionItem.propTypes = {
  title: string.isRequired,
  description: string,
  timeStamp: string.isRequired,
  actions: arrayOf(
    shape({
      label: string.isRequired,
      __typename: oneOf([ACTION_TYPE_NAMES.graphQLUiAction, ACTION_TYPE_NAMES.routeUiAction]).isRequired,
      style: oneOf([ACTION_STYLES.default, ACTION_STYLES.primary, ACTION_STYLES.danger, ACTION_STYLES.link]).isRequired,

      role: oneOf([ACTION_ROLES.primary, ACTION_ROLES.secondary]).isRequired,
    })
  ).isRequired,
  toggleNotification: func.isRequired,
  onSuccess: func.isRequired,
};

ActionItem.defaultProps = {
  description: '',
};

export default ActionItem;
