import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import OnboardingLayout from '../../components/layouts/OnboardingLayout';
import OnboardingLayoutBox from '../../components/layouts/OnboardingLayoutBox';

import { ButtonRounded, H1, P14, P16, P18B } from '../../components/atoms';
import SubmitButton from '../../components/forms/_common/SubmitButton';
import { UI_ROUTES } from '../../constants/routes';
import THEME from '../../constants/theme';
import { useHandleRegistrationFlowRedirect, useRegistrationStorage } from '../../hooks/registration';
import { StyledButtonsWrap, StyledWelcomeToBetaPageContainer } from './styled';

const SUB_TITLE =
  'The team is reviewing your registration and will provide you with an access link when your account is approved.';
const CONTENT = 'In the meantime, you can watch a demo of the platform below.';

const LINK = 'https://whenthen.com/demo';

const WelcomeToBetaPage = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const [, , clearRegistrationStorage] = useRegistrationStorage();

  useHandleRegistrationFlowRedirect();

  const handleComplete = () => {
    clearRegistrationStorage();
    push(UI_ROUTES.signIn);
  };

  return (
    <OnboardingLayout
      theme="dark"
      navigationRight={
        <P14 color={THEME.greyColors.lightGrey}>
          {t('Already have an account?')}{' '}
          <Link to={UI_ROUTES.signIn} className="text-primary">
            {t('Sign in')}
          </Link>
        </P14>
      }
    >
      <OnboardingLayoutBox theme="dark">
        <StyledWelcomeToBetaPageContainer>
          <div>
            <H1 fontWeight={700}>{t('You’re almost there...')}</H1>
            <P18B margin="24px 0 0 0" lineHeight="28px" color={THEME.greyColors.grey800} fontWeight="500">
              {t(SUB_TITLE)}
            </P18B>
            <P16 margin="8px 0 24px 0" color={THEME.greyColors.grey600}>
              {t(CONTENT)}
            </P16>
          </div>

          <StyledButtonsWrap>
            <ButtonRounded
              color="secondary"
              variant="contained"
              height="48px"
              fontWeight={500}
              onClick={handleComplete}
            >
              {t('Back to homepage')}
            </ButtonRounded>
            <SubmitButton as="a" href={LINK} target="_blank" rel="noreferrer noopener" showIcon={false}>
              {t('Watch Demo')}
            </SubmitButton>
          </StyledButtonsWrap>
        </StyledWelcomeToBetaPageContainer>
      </OnboardingLayoutBox>
    </OnboardingLayout>
  );
};

export default WelcomeToBetaPage;
