import styled from 'styled-components';
import THEME from '../../../../../../constants/theme';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 50%;
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  margin-bottom: 4px;
`;

const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  /* background-color: ${THEME.greyColors.grey24}; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;


  svg {
    width : 100%;
    height : 100%;
  }
`;

export { StyledWrapper, IconWrapper };
