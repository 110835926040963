import React, { useCallback, useEffect } from 'react';
import { useField } from 'formik';
import { func, bool, string } from 'prop-types';
import Box from '@mui/material/Box';
import { useSearchInput } from '../../../../../../hooks/useSearchInput';
import { StyledRoundedTextField } from '../../../../../styled/StyledRoundedTextField';

const SearchInput = ({
  inputWidth,
  inputMargin,
  borderColor,
  noAutofocus,
  name,
  handleChange,
  searchKey,
  useOnBlur,
  setTouched,
}) => {
  const [value, setValue] = useSearchInput(searchKey);

  const [{ onBlur }, { touched }] = useField(name);

  useEffect(() => {
    setTouched(touched);
  }, [touched, setTouched]);

  const onChange = useCallback(
    (event) => {
      setValue(event.target.value);
      handleChange();
    },
    [setValue, handleChange]
  );

  return (
    <Box display="flex" bgcolor="#fff">
      <Box width={inputWidth ?? '350px'} margin={inputMargin ?? '0 -40px 0 0'}>
        <StyledRoundedTextField
          name={name}
          value={value}
          onChange={onChange}
          fullWidth
          $borderColor={borderColor}
          {...(!noAutofocus && { autoFocus: true })}
          {...(useOnBlur && { onBlur })}
          placeholder="Search"
        />
      </Box>
    </Box>
  );
};

SearchInput.propTypes = {
  showInputField: bool,
  inputWidth: string,
  inputMargin: string,
  borderColor: string,
  noAutofocus: bool,
  name: string,
  handleChange: func,
  searchKey: string,
  useOnBlur: bool,
  setTouched: func,
};

SearchInput.defaultProps = {
  showInputField: false,
  inputWidth: undefined,
  inputMargin: undefined,
  borderColor: undefined,
  noAutofocus: false,
  name: '',
  handleChange: () => {},
  searchKey: undefined,
  useOnBlur: false,
  setTouched: () => {},
};

export default SearchInput;
