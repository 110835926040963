import React, { useCallback, useMemo } from 'react';
import { bool } from 'prop-types';
import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import CircleImage from '../table/CircleImage';
import UserMenu from './UserMenu';
import { StyledPaper, StyledButton } from './styled';
import { GQL_Q_TEAM_LIST } from '../../utils/queries/public/publicQueries';
import { GET_TEAM } from '../../utils/queries/workspaces/workspacesQueries';
import { IGNORE_DEMO_HEADER } from '../../client/links/demoLink/constants';
import { useUserSelector } from '../../providers/User/UserContext';
import { selectIsDemo } from '../../providers/User/state/selectors';
import DemoUser from '../../assets/img/DemoUser.svg';
import { useGlobalContext } from '../../containers/App/context';
import CreateTeamDialog from './CreateTeam/CreateTeamDialog';
import { useFeature } from '../../hooks/useFeature';
import { FEATURE_TOGGLES_KEYS } from '../../constants/featureToggles';

const SwitchUser = ({ isCustomerSupportRole }) => {
  const isDemo = useUserSelector(selectIsDemo);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleCreateTeamDialog = () => setIsOpen(!isOpen);
  const { loading: teamLoading, data: teamData } = useQuery(GET_TEAM);
  const { getMeData, getMeLoading } = useGlobalContext();
  const { loading: teamListLoading, data: teamListData } = useQuery(GQL_Q_TEAM_LIST, {
    fetchPolicy: 'no-cache',
    context: { [IGNORE_DEMO_HEADER]: true },
  });

  const handleOpen = useCallback(({ currentTarget }) => setAnchorEl(currentTarget), []);
  const handleClose = useCallback(() => setAnchorEl(null), []);
  const [canCreateTeam] = useFeature(FEATURE_TOGGLES_KEYS.CREATE_TEAM);

  const teams = useMemo(() => {
    const activeTeamId = teamData?.team?.id;
    const teamIdSet = new Set();

    return (
      teamListData?.me?.teams
        ?.reduce((acc, team) => {
          const teamId = team.team.id;

          if (team.status === 'INACTIVE' || teamIdSet.has(teamId)) {
            return acc;
          }

          teamIdSet.add(teamId);
          acc.push({
            id: teamId,
            selected: team.status === 'ACTIVE' && team.team?.id === activeTeamId,
            userName: team?.team?.name ?? '',
            membersCount: team.team?.membersCount,
          });

          return acc;
        }, [])
        .sort((a, b) => a?.userName?.localeCompare(b?.userName)) ?? []
    );
  }, [teamData?.team?.id, teamListData?.me?.teams]);

  const circleImageProps = useMemo(() => {
    if (isDemo) {
      return {
        text: 'DEMO',
        bgColor: '#3023C8',
        fontSize: '12px',
        bgImage: DemoUser,
        textOnly: true,
      };
    }

    const name = teamData?.team?.name ?? '';
    const logo = getMeData?.we?.brand?.logoUrl ?? '/';

    return {
      text: name,
      logo,
      bgColor: '#E6E9EC',
      color: '#232629',
      fontSize: '12px',
    };
  }, [isDemo, teamData?.team?.name, getMeData?.we]);

  const open = Boolean(anchorEl);
  const isDisabled = getMeLoading || teamLoading || teamListLoading;

  return (
    <>
      <Box m="-3px" className="switch-user">
        <StyledButton
          size="small"
          disabled={isDisabled}
          onClick={handleOpen}
          $isCustomerSupportRole={isCustomerSupportRole}
        >
          <CircleImage size={32} borderRadius="6px" matchRadius {...circleImageProps} />
        </StyledButton>
      </Box>

      {!isCustomerSupportRole && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{ component: StyledPaper, onClick: handleClose }}
        >
          <UserMenu teams={teams} toggleCreateTeamDialog={toggleCreateTeamDialog} />
        </Popover>
      )}

      {!isCustomerSupportRole && canCreateTeam && (
        <CreateTeamDialog toggleIsOpen={toggleCreateTeamDialog} isOpen={isOpen} />
      )}
    </>
  );
};

SwitchUser.propTypes = {
  isCustomerSupportRole: bool.isRequired,
};

export default SwitchUser;
