const PAYMENT_METHODS = {
  CASH: 'CASH',
  CARD: 'CARD',
  MASTERCARD: 'MASTERCARD',
  VISA: 'VISA',
  AMEX: 'AMEX',
  PAYPAL: 'PAYPAL',
  ACCOUNT: 'ACCOUNT',
  SEPA_CREDIT_TRANSFER: 'SEPA_CREDIT_TRANSFER',
  SEPA_DIRECT_DEBIT: 'SEPA_DIRECT_DEBIT',
  IDEAL: 'IDEAL',
  DIRECT_DEBIT: 'DIRECT_DEBIT',
  BAN_CONTACT: 'BAN_CONTACT',
  GIRO_PAY: 'GIRO_PAY',
  SOFORT: 'SOFORT',
  WALLET: 'WALLET',
  APPLE_PAY: 'APPLE_PAY',
  GOOGLE_PAY: 'GOOGLE_PAY',
  ALI_PAY: 'ALI_PAY',
  WE_CHAT: 'WE_CHAT',
  BUY_NOW_PAY_LATER: 'BUY_NOW_PAY_LATER',
  KLARNA: 'KLARNA',
  AFFIRM: 'AFFIRM',
  CRYPTO: 'CRYPTO',
  BITCOIN: 'BITCOIN',
  UNKNOWN_CARD: 'UNKNOWN_CARD',
  UNKNOWN: 'UNKNOWN',
  DANKORT: 'DANKORT',
  MULTIBANCO: 'MULTIBANCO',

  BACS: 'BACS',
  BECS: 'BECS',
  SAMSUNG_PAY: 'SAMSUNG_PAY',
  AFTER_PAY: 'AFTER_PAY',
  CLEAR_PAY: 'CLEAR_PAY',
  ACH: 'ACH',
  ACH_CREDIT: 'ACH_CREDIT',
  ACH_DEBIT: 'ACH_DEBIT',
};

export { PAYMENT_METHODS };
