import React, { useCallback, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { FormControl, FormHelperText } from '@mui/material';
import { useActionInputContext } from '../actionInputContext';
import { usePeopleField } from '../../hooks/usePeopleField';
import { useFlowEditorContext } from '../../../../../../context';
import { useSelectedNode } from '../../hooks';
import { createVariables } from './createVariables';
import { useSaveConfiguration } from '../../../../../../hooks/useSaveConfiguration';
import MultiSelectSection from '../../../sections/MultiSelectSection';
import { selectTypes } from '../../../inputs/types';
import ToastCustomContainer from '../../../../../../../ToastCustomContainer';
import { TOAST_TIMEOUT } from '../../../../../../../../constants/toasts';
import THEME from '../../../../../../../../constants/theme';
import { useStyles } from '../../styled';

const UserOrGroupInput = () => {
  const { validationMessage, fieldId } = useActionInputContext();
  const { options } = usePeopleField(fieldId);
  const {
    flowId,
    nodes,
    initialState,
    setInitialState,
    nodeDataToSave,
    setNodeDataToSave,
    setNodes,
    setChangesMade,
    selectedNodeData,
  } = useFlowEditorContext();
  const [{ id: stepId }] = useSelectedNode();
  const { saveFlowPromise } = useSaveConfiguration({
    flowId,
    initialState,
    setNodes,
    setInitialState,
    setChangesMade,
    selectedNodeData,
    nodeDataToSave,
    setNodeDataToSave,
  });
  const classes = useStyles();

  const [inputData, setInputData] = useState(() => {
    return initialState?.find(({ key }) => key === `${stepId}.user-or-group-list`)?.value;
  });

  const saveFlow = useCallback(
    (values) => {
      const variables = createVariables({ stepId, values, nodes });
      return saveFlowPromise({ variables }).then(({ errors }) => {
        if (errors) {
          NotificationManager.error(
            <ToastCustomContainer message="errors.errorSavingData" title="Oops.." />,
            null,
            TOAST_TIMEOUT
          );
        }
      });
    },
    [stepId, nodes, saveFlowPromise]
  );

  const selectHandler = async (values) => {
    setInputData(values);
    await saveFlow(values);
  };
  const hasError = Boolean(validationMessage);
  const border = `1px solid ${hasError ? THEME.secondaryColors.nodeError : THEME.greyColors.grey200}`;

  return (
    <FormControl variant="standard" fullWidth error={hasError}>
      <MultiSelectSection
        options={options}
        selectedOptionIds={inputData}
        validationMessage={validationMessage}
        handleChange={selectHandler}
        selectType={selectTypes.people}
        isSearchBar
        addBorder
        border={border}
      />
      {hasError && <FormHelperText classes={{ root: classes.root }}>{validationMessage}</FormHelperText>}
    </FormControl>
  );
};

export default UserOrGroupInput;
