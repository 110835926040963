import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/lab/Skeleton';

const FilterLoadingState = () => {
  return (
    <Box borderRadius="6px" width="150px" height="30px" overflow="hidden">
      <Skeleton variant="rectangular" height="40px" width="150px" animation="wave" />
    </Box>
  );
};

export default FilterLoadingState;
