import React from 'react';

const ShoppingCart = ({ ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_734_10608)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.66699 13.9998C4.66699 13.2635 5.26395 12.6665 6.00033 12.6665C6.73671 12.6665 7.33366 13.2635 7.33366 13.9998C7.33366 14.7362 6.73671 15.3332 6.00033 15.3332C5.26395 15.3332 4.66699 14.7362 4.66699 13.9998Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 13.9998C12 13.2635 12.597 12.6665 13.3333 12.6665C14.0697 12.6665 14.6667 13.2635 14.6667 13.9998C14.6667 14.7362 14.0697 15.3332 13.3333 15.3332C12.597 15.3332 12 14.7362 12 13.9998Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0.666667C0 0.298477 0.298477 0 0.666667 0H3.33333C3.65108 0 3.92467 0.224257 3.98704 0.535829L4.54695 3.33333H15.3333C15.532 3.33333 15.7203 3.42195 15.847 3.57504C15.9736 3.72812 16.0254 3.92972 15.9882 4.12489L14.9206 9.72322C14.8291 10.1836 14.5787 10.5972 14.213 10.8915C13.8492 11.1844 13.3944 11.3406 12.9276 11.3333H6.4591C5.99225 11.3406 5.53747 11.1844 5.17365 10.8915C4.80817 10.5973 4.55776 10.1839 4.46622 9.72378C4.46618 9.72359 4.46626 9.72397 4.46622 9.72378L3.35253 4.15947C3.34801 4.14106 3.34425 4.12236 3.3413 4.10338L2.78688 1.33333H0.666667C0.298477 1.33333 0 1.03486 0 0.666667ZM4.81382 4.66667L5.77389 9.46346C5.80437 9.61692 5.88786 9.75478 6.00974 9.85289C6.13162 9.951 6.28413 10.0031 6.44056 10.0001L6.45333 10H12.9333L12.9461 10.0001C13.1025 10.0031 13.255 9.951 13.3769 9.85289C13.4982 9.75523 13.5815 9.6182 13.6123 9.4656L14.5275 4.66667H4.81382Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_734_10608">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShoppingCart;
