import styled, { css } from 'styled-components';
import { P14 } from '../../../../../components/atoms';

const StyledFilterWrapper = styled.div`
  width: 248px;
  margin-right: 16px;

  ${({ $inModal }) =>
    $inModal &&
    css`
      position: sticky;
      left: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      padding-left: 0;
      padding-top: 52px;
      border-radius: 0;
      max-width: 231px;
      width: 100%;
      margin: 0;
      margin-right: 24px;
    `}
`;

const Title = styled(P14)`
  margin-bottom: 16px;
  font-weight: 500;
`;

const SideFilterMain = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid black;
`;

const FiltersContainer = styled.div`
  margin-right: 24px;
`;

export { StyledFilterWrapper, Title, SideFilterMain, FiltersContainer };
