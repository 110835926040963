import React from 'react';
import PropTypes from 'prop-types';
import THEME from '../../constants/theme';

const BackTime = ({ size = 20, color }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.26202 7.91678C3.23721 7.95301 3.21238 7.98928 3.18752 8.02558C2.92744 8.40529 2.40879 8.50227 2.02909 8.2422C1.64938 7.98212 1.55239 7.46348 1.81247 7.08377C1.86093 7.01302 1.9097 6.94161 1.95885 6.86964C3.53907 4.55608 5.51238 1.66699 9.99999 1.66699C14.6015 1.66699 18.3333 5.39878 18.3333 10.0003C18.3333 14.6019 14.6015 18.3337 9.99999 18.3337C5.39845 18.3337 1.66666 14.6019 1.66666 10.0003C1.66666 9.54009 2.03976 9.16699 2.49999 9.16699C2.96023 9.16699 3.33333 9.54009 3.33333 10.0003C3.33333 13.6814 6.31893 16.667 9.99999 16.667C13.6811 16.667 16.6667 13.6814 16.6667 10.0003C16.6667 6.31926 13.6811 3.33366 9.99999 3.33366C6.39952 3.33366 4.88777 5.54196 3.26202 7.91678Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66667 3.6416H3.33334V6.72131H6.41305V8.38798H1.66667V3.6416Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.75 6.16699C11.2103 6.16699 11.5834 6.54009 11.5834 7.00033V11.5003C11.5834 11.9606 11.2103 12.3337 10.75 12.3337H7.75004C7.2898 12.3337 6.9167 11.9606 6.9167 11.5003C6.9167 11.0401 7.2898 10.667 7.75004 10.667H9.9167V7.00033C9.9167 6.54009 10.2898 6.16699 10.75 6.16699Z"
      fill={color}
    />
  </svg>
);

BackTime.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

BackTime.defaultProps = {
  size: 20,
  color: THEME.primaryColors.white,
};

export default BackTime;
