import { useCallback, useMemo, useState } from 'react';

export const useGlobalLoading = () => {
  const [loadingObj, setLoadingObj] = useState({});

  const setGlobalLoading = useCallback((key, status) => {
    setLoadingObj((prev) => ({ ...prev, [key]: status }));
  }, []);
  const globalLoading = useMemo(() => {
    return Object.values(loadingObj).filter(Boolean).length !== 0;
  }, [loadingObj]);

  return {
    globalLoading,
    setGlobalLoading,
  };
};
