import React from 'react';
import { bool, string, func, arrayOf, oneOfType } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import isFunction from 'lodash/isFunction';
import { StyledWrapper } from './styled';
import InstructFeature from './InstructFeature';
import { ButtonRounded } from '../../../../../../../components/atoms';

const instructFeatures = [
  'Full control of the payment flow from Checkout to Capture',
  'Maximize conversion with smart routing and Orchestration',
  'Vast library of ready-to-go Recipes',
];

const InstructFeatures = ({
  margin,
  noMinHeight,
  hideButton,
  handleConfigure,
  mappedFeatures,
  buttonText,
  secondaryButtonText,
  secondaryButtonFn,
}) => {
  const { t } = useTranslation();
  return (
    <StyledWrapper $margin={margin} $noMinHeight={noMinHeight}>
      <Box>
        {[...(mappedFeatures || instructFeatures)]?.map((feature) => (
          <InstructFeature feature={feature} key={feature} />
        ))}
      </Box>
      {!hideButton && (
        <Box display="flex" mt="24px" alignSelf="flex-end">
          {secondaryButtonText && (
            <ButtonRounded type="button" variant="contained" color="secondary" onClick={secondaryButtonFn}>
              {t(secondaryButtonText)}
            </ButtonRounded>
          )}
          <ButtonRounded
            type="button"
            variant="contained"
            color="primary"
            ml="8px"
            target="_blank"
            rel="noopener noreferrer"
            {...(isFunction(handleConfigure) ? { onClick: handleConfigure } : { href: handleConfigure })}
          >
            {t(buttonText || 'Got it')}
          </ButtonRounded>
        </Box>
      )}
    </StyledWrapper>
  );
};

InstructFeatures.propTypes = {
  margin: string,
  noMinHeight: bool,
  handleConfigure: oneOfType([func, string]),
  hideButton: bool,
  mappedFeatures: arrayOf(string),
  buttonText: string,
  secondaryButtonText: string,
  secondaryButtonFn: func,
};

InstructFeatures.defaultProps = {
  margin: '40px 0 0 0',
  noMinHeight: false,
  handleConfigure: () => {},
  hideButton: false,
  mappedFeatures: null,
  buttonText: null,
  secondaryButtonText: null,
  secondaryButtonFn: null,
};

export default InstructFeatures;
