import React from 'react';
import { string } from 'prop-types';

const Visibility = ({ fill }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2002 6C9.23866 6 6.7002 8.45385 6.7002 11.5C6.7002 14.5462 9.23866 17 12.2002 17C15.2463 17 17.7002 14.5462 17.7002 11.5C17.7002 8.45385 15.2463 6 12.2002 6ZM10.4233 11.5846C9.23866 11.5846 8.30789 10.5692 8.30789 9.38462C8.30789 8.2 9.23866 7.18462 10.5079 7.18462C11.6925 7.18462 12.7079 8.11538 12.7079 9.38462C12.6233 10.5692 11.6925 11.5846 10.4233 11.5846Z"
        fill={fill}
      />
      <path
        d="M12.2 6.84615C16.7692 6.84615 20.7461 10.1462 22.1846 11.5C20.7461 12.8538 16.7692 16.1538 12.2 16.1538C7.63076 16.1538 3.65384 12.8538 2.21538 11.5C3.65384 10.1462 7.63076 6.84615 12.2 6.84615ZM12.2 6C6.02307 6 1.03076 11.5 1.03076 11.5C1.03076 11.5 6.02307 17 12.2 17C18.3769 17 23.3692 11.5 23.3692 11.5C23.3692 11.5 18.3769 6 12.2 6Z"
        fill={fill}
      />
    </svg>
  );
};
Visibility.propTypes = {
  fill: string,
};
Visibility.defaultProps = {
  fill: '#4E40EF',
};

export default Visibility;
