import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SubTitle from '../SubTitle';
import THEME from '../../../../../constants/theme';
import { StyledFormControl } from './fields/styled';
import Select, { NONE } from '../../../../forms/_common/Select';
import useGetAvailableDataProviders from './hooks/useGetAvailableDataProviders';
import { useNodeDataToSave } from './fields/hooks/useNodeDataToSave';
import LoadingState from '../LoadingState';

const QueryInfo = () => {
  const { t } = useTranslation();
  const [{ selectedDataProvider }, updateDataToSave] = useNodeDataToSave();
  const { availableDataProviders, loading } = useGetAvailableDataProviders();

  const options = availableDataProviders?.map(({ type, displayName, logo }) => ({
    value: type,
    title: displayName,
    icon: logo,
  }));

  const onChange = ({ target: { value } }) =>
    updateDataToSave({
      selectedDataProvider: value !== NONE ? value : null,
      selectedQueryType: null,
      filters: null,
      singleRecordUniquenessOperator: null,
      dataSetVariable: null,
      dataSetProjection: null,
    });

  return (
    <Box>
      <SubTitle color={THEME.primaryColors.black} fontWeight={500}>
        {t('Query info')}
      </SubTitle>
      <LoadingState height="40px" loading={loading}>
        <StyledFormControl $mt="8px" fullWidth>
          <Select value={selectedDataProvider || NONE} options={options} onChange={onChange} />
        </StyledFormControl>
      </LoadingState>
    </Box>
  );
};

export default QueryInfo;
