import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  width: ${({ width }) => width || 'auto'};
  fill: ${({ fill }) => fill || 'currentcolor'};
`;

const BookkeepingPlatform = (props) => (
  <StyledSvg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.3" d="M9.91658 2.33301H4.08325V4.66634H9.91658V2.33301Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.08341 1.16699H9.91675C10.5611 1.16699 11.0834 1.68933 11.0834 2.33366V11.667C11.0834 12.3113 10.5611 12.8337 9.91675 12.8337H4.08341C3.43908 12.8337 2.91675 12.3113 2.91675 11.667V2.33366C2.91675 1.68933 3.43908 1.16699 4.08341 1.16699ZM4.66675 7.00033C4.98891 7.00033 5.25008 6.73916 5.25008 6.41699C5.25008 6.09483 4.98891 5.83366 4.66675 5.83366C4.34458 5.83366 4.08341 6.09483 4.08341 6.41699C4.08341 6.73916 4.34458 7.00033 4.66675 7.00033ZM4.66675 9.33366C4.98891 9.33366 5.25008 9.07249 5.25008 8.75033C5.25008 8.42816 4.98891 8.16699 4.66675 8.16699C4.34458 8.16699 4.08341 8.42816 4.08341 8.75033C4.08341 9.07249 4.34458 9.33366 4.66675 9.33366ZM7.00008 7.00033C7.32225 7.00033 7.58342 6.73916 7.58342 6.41699C7.58342 6.09483 7.32225 5.83366 7.00008 5.83366C6.67792 5.83366 6.41675 6.09483 6.41675 6.41699C6.41675 6.73916 6.67792 7.00033 7.00008 7.00033ZM7.00008 9.33366C7.32225 9.33366 7.58342 9.07249 7.58342 8.75033C7.58342 8.42816 7.32225 8.16699 7.00008 8.16699C6.67792 8.16699 6.41675 8.42816 6.41675 8.75033C6.41675 9.07249 6.67792 9.33366 7.00008 9.33366ZM9.33342 7.00033C9.65558 7.00033 9.91675 6.73916 9.91675 6.41699C9.91675 6.09483 9.65558 5.83366 9.33342 5.83366C9.01125 5.83366 8.75008 6.09483 8.75008 6.41699C8.75008 6.73916 9.01125 7.00033 9.33342 7.00033ZM9.33342 9.33366C9.65558 9.33366 9.91675 9.07249 9.91675 8.75033C9.91675 8.42816 9.65558 8.16699 9.33342 8.16699C9.01125 8.16699 8.75008 8.42816 8.75008 8.75033C8.75008 9.07249 9.01125 9.33366 9.33342 9.33366ZM9.33342 11.667C9.65558 11.667 9.91675 11.4058 9.91675 11.0837C9.91675 10.7615 9.65558 10.5003 9.33342 10.5003C9.01125 10.5003 8.75008 10.7615 8.75008 11.0837C8.75008 11.4058 9.01125 11.667 9.33342 11.667ZM4.66675 10.5003C4.34458 10.5003 4.08341 10.7615 4.08341 11.0837C4.08341 11.4058 4.34458 11.667 4.66675 11.667H7.00008C7.32225 11.667 7.58342 11.4058 7.58342 11.0837C7.58342 10.7615 7.32225 10.5003 7.00008 10.5003H4.66675ZM4.08341 2.33366V4.66699H9.91675V2.33366H4.08341Z"
      fill="white"
    />
  </StyledSvg>
);

export default BookkeepingPlatform;
