import styled from 'styled-components';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import THEME from '../../../../../../../../constants/theme';

export const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
  &.MuiAccordion-rounded:first-child {
    border-color: ${THEME.greyColors.grey5};
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &.MuiAccordion-rounded:last-child {
    &:before {
      display: none;
    }
    border-color: ${THEME.greyColors.grey5};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 16px;
  &.MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    flex-direction: column;
    padding: 0 16px 16px 16px;
  }
`;
