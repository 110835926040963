import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { BlockWrap, Button } from '../../atoms';
import { formFields, formNames, initialValues } from './schema';
import { FieldsWrapper } from '../formStyles';
import Input from '../_common/Input';
import { SELECT_OPTIONS, CUSTOM_SELECT_PROPS } from './consts';
import SubmitButton from '../_common/SubmitButton';
import { isDefined } from '../../../utils/helpers';
import { useRegistrationStorage } from '../../../hooks/registration';
import { ONBOARDING_STATUS } from '../../../constants/registration';
import { UI_ROUTES } from '../../../constants/routes';
import { validator } from './validator';
import SignUpFormFooter from '../_common/SignUpFormFooter';
import CustomCheckboxes from '../_common/CustomCheckboxes';
import useDynamicValidation from '../../../hooks/useDynamicValidation';

const COMPONENTS_MAP = {
  [formNames.productsServices]: CustomCheckboxes,
};

const COMPONENTS_PROPS_MAP = {
  [formNames.productsServices]: [],
};

const SignUpProductsServicesForm = ({ loading, handleSubmit }) => {
  const { t } = useTranslation();
  const [registrationSession, setRegistrationSession] = useRegistrationStorage();
  const { push } = useHistory();
  const [showOtherInput, setShowOtherInput] = useState(false);
  const validationObject = useDynamicValidation(validator, showOtherInput);

  const handleBack = () => {
    setRegistrationSession({
      ...registrationSession,
      onboardingStatus: ONBOARDING_STATUS.revenueModel,
    });
    push(UI_ROUTES.signUpRevenueModel);
  };

  const parsedInitialValues = {
    ...initialValues,
    ...(registrationSession?.[ONBOARDING_STATUS.companyDetails] ?? {}),
  };

  return (
    <BlockWrap margin="37px 0 0">
      <FieldsWrapper>
        <Formik initialValues={parsedInitialValues} validate={validationObject} onSubmit={handleSubmit} validateOnBlur>
          <Form>
            {formFields.map(({ field, type, label, ...rest }, index) => {
              const Component = COMPONENTS_MAP[field] || Input;
              const addCustomMargin = field === formNames.companySize;

              return (
                <Component
                  key={field}
                  name={field}
                  {...(isDefined(SELECT_OPTIONS[field]) && {
                    options: SELECT_OPTIONS[field],
                    ...CUSTOM_SELECT_PROPS,
                  })}
                  type={type}
                  label={label}
                  index={index}
                  {...COMPONENTS_PROPS_MAP[field]}
                  {...rest}
                  {...(addCustomMargin && {
                    errorProps: {
                      margin: '6px 0 14px 0',
                    },
                  })}
                  setShowOtherInput={setShowOtherInput}
                />
              );
            })}

            <SignUpFormFooter>
              <SubmitButton
                type="submit"
                likeDisabled={loading}
                showIcon={false}
                className="gradient"
                margin="28px 0 0 0"
                data-cy="submit"
                disabled={loading}
                isLoading={loading}
              >
                {t('common.continueText')}
              </SubmitButton>
              <Button className="grey" size="small" onClick={() => handleBack()}>
                {t('Back')}
              </Button>
            </SignUpFormFooter>
          </Form>
        </Formik>
      </FieldsWrapper>
    </BlockWrap>
  );
};

SignUpProductsServicesForm.propTypes = { loading: PropTypes.bool, handleSubmit: PropTypes.func.isRequired };

SignUpProductsServicesForm.defaultProps = { loading: false };

export default SignUpProductsServicesForm;
