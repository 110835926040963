import { useMutation } from '@apollo/client';
import React, { useEffect, useState, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from 'react-i18next';
import THEME from '../../constants/theme';
import OtpBlock from '../../components/common/OtpBlock';
import { CREATE_LOGIN_AUTH, VERIFY_AUTHENTIFICATOR } from '../../utils/queries/public/publicMutations';
import { useGlobalContext } from '../../containers/App/context';
import { P16 } from '../../components/atoms';
import PureLayout from '../../components/layouts/PureLayout';
import PureLayoutBox from '../../components/layouts/PureLayoutBox';
import { LeftSideFormWrapper } from '../../components/forms/LeftSideFormWrapper';
import ToastCustomContainer from '../../components/ToastCustomContainer';
import { TOAST_TIMEOUT } from '../../constants/toasts';

const OtpLogin = () => {
  const { getMeData, getMeRefetch, setToken, setRefreshToken } = useGlobalContext();
  const { t } = useTranslation();
  const [codeSent, setCodeSent] = useState(false);
  const [pass, setPass] = useState('');
  const [createAuthLogin] = useMutation(CREATE_LOGIN_AUTH);
  const [verifyAuth, { loading: submitLoading }] = useMutation(VERIFY_AUTHENTIFICATOR);
  const [isPassEmpty, setIsPassEmpty] = useState(false);

  const sendPass = useCallback(() => {
    createAuthLogin().then((res) => {
      if (res && !res?.errors) {
        setToken(res?.data?.createLoginChallenge?.token);
        setRefreshToken(res?.data?.createLoginChallenge?.refreshToken);
      } else {
        NotificationManager.error(
          <ToastCustomContainer message="Server error." title="uiMessages.error" />,
          null,
          TOAST_TIMEOUT
        );
      }
    });
  }, [createAuthLogin, setRefreshToken, setToken]);

  useEffect(() => {
    if (codeSent === false) {
      setCodeSent(true);
      sendPass();
    }
  }, [codeSent, sendPass, getMeData?.getOnboardingStatus, getMeData]);

  const submitCode = () => {
    if (isEmpty(pass)) {
      setIsPassEmpty(true);
      return;
    }

    verifyAuth({
      variables: {
        bindingCode: pass,
      },
    }).then((res) => {
      if (res && isEmpty(res.errors) && res.data?.verifyAuthenticator?.token) {
        setToken(res.data.verifyAuthenticator.token);
        setRefreshToken(res.data.verifyAuthenticator.refreshToken);
        getMeRefetch();
      } else {
        setIsPassEmpty(true);
      }
    });
  };

  return (
    <PureLayout>
      <PureLayoutBox>
        <LeftSideFormWrapper spaceBetween>
          <h1>2 step verification code</h1>
          <P16 margin="0 0 40px" textAlign="center" color={THEME.greyColors.grey}>
            {t('Enter the code generated by your authenticator app or sent to you by SMS')}
          </P16>

          <OtpBlock
            pass={pass}
            setPass={setPass}
            numInputs={6}
            loading={submitLoading}
            submitFunc={submitCode}
            resendFunc={sendPass}
            bottomReset
            resetButtonText="Having trouble getting a code?"
            resetButtonPosition="bottom"
            isPassEmpty={isPassEmpty}
            preventDisabledButton={true}
          />
        </LeftSideFormWrapper>
      </PureLayoutBox>
    </PureLayout>
  );
};

export default OtpLogin;
