const MODAL_TYPES = {
  acceptDispute: 'acceptDispute',
  submitDispute: 'submitDispute',
};

const MODAL_CONTENT = {
  [MODAL_TYPES.acceptDispute]: {
    title: 'Accept Dispute',
    desc:
      'You are about to accept this dispute. This means you are forfeiting your chance to submit evidence and the dispute will move to a lost status. Are you sure?',
    actionButtonLabel: 'Accept',
  },
  [MODAL_TYPES.submitDispute]: {
    title: 'Confirm Dispute Submission',
    desc:
      'You can only submit dispute evidence once. Are you sure you want to send your submission to your processor? ',
    actionButtonLabel: 'Submit',
  },
};

export { MODAL_TYPES, MODAL_CONTENT };
