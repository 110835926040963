import React from 'react';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import { TOAST_TIMEOUT } from '../../../../../../../constants/toasts';
import ToastCustomContainer from '../../../../../../../components/ToastCustomContainer';

const uploadOptions = (file) => {
  return {
    headers: {
      'Content-Type': file.type,
      'Content-Disposition': `attachment; filename="${window.encodeURI(file.name)}"`,
    },
    withCredentials: false,
  };
};

const saveAttachment = (getUploadUrl, files, setFiles) => {
  const uploaders = files.map((file) => {
    return axios.put(getUploadUrl?.uploadUrl, file, uploadOptions(file));
  });

  axios.all(uploaders).catch((err) => {
    setFiles([]);
    NotificationManager.error(
      <ToastCustomContainer message={err.message} title="Save attachment." />,
      null,
      TOAST_TIMEOUT
    );
  });
};

const MIME_FILE_TYPE = {
  pdf: 'application/pdf',
  png: 'image/png',
  jpeg: 'image/jpeg',
  jpg: 'image/jpg',
  tiff: 'image/tiff',
};

export { saveAttachment, MIME_FILE_TYPE };
