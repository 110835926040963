import { useMemo, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GET_PAYMENT } from '../../../../utils/queries/payments/paymentsQueries';
import { usePaymentsDetailsContext } from '../../../DataTables/PaymentDetails/PaymentDetailsContext';
import { useCommentsContext } from '../../CommentsDrawerContent/CommentsContext';
import { useGlobalContext } from '../../../../containers/App/context';
import { GET_TEAM } from '../../../../utils/queries/workspaces/workspacesQueries';

import useSearch from '../../../../hooks/useSearch';
import { isDefined } from '../../../../utils/helpers';

const ISSUE_STATUS_MAP = {
  RESOLVED: 'RESOLVED',
};

const removePrefix = (str) => {
  return str.replace('payments:', '');
};

const useGetPaymentDetailsData = () => {
  const { getMeData } = useGlobalContext();
  const { detailsId: id, issueId, issueDetails } = useParams();

  const { setPageContentData, setPageContentRefetch } = usePaymentsDetailsContext();
  const { setCommentsData } = useCommentsContext();
  const { data: teamData } = useQuery(GET_TEAM);
  const [searchParams] = useSearch();

  const customerId = getMeData?.we?.id;
  const teamId = teamData?.team?.id;
  const { sourceId } = searchParams;

  const {
    replace,
    location: { pathname, search },
  } = useHistory();

  const removeHashFromId = id?.split('#')[0];

  const { loading, error, data, refetch } = useQuery(GET_PAYMENT(issueId || issueDetails), {
    variables: {
      id: removeHashFromId,
      issueId: issueId || issueDetails,
      customerId,
      teamId,
      sourceId: isDefined(sourceId) ? sourceId : removePrefix(removeHashFromId),
    },
  });

  useEffect(() => {
    if (!issueId) {
      const fixedId = data?.listPaymentIssues?.find((issue) => issue.status !== ISSUE_STATUS_MAP.RESOLVED)?.id;
      if (fixedId) {
        replace(`${pathname}/payment-issues/${fixedId}${search}`);
      }
    }
  }, [data, issueId, pathname, replace, search]);

  const payment = useMemo(() => data?.getPaymentInternal ?? {}, [data?.getPaymentInternal]);
  const intent = useMemo(() => data?.getLinkedIntent || {}, [data?.getLinkedIntent]);

  useEffect(() => {
    setPageContentData(payment);
    setPageContentRefetch(refetch);
  }, [payment, data, refetch, setPageContentData, setPageContentRefetch]);

  useEffect(() => {
    if (!loading && data) {
      setCommentsData({
        comments: data?.listPaymentComments || [],
        paymentId: removeHashFromId,
        issueId: issueId,
      });
    }
  }, [data, loading, issueId, setCommentsData, removeHashFromId]);

  return { payment, intent, loading: loading, error, id };
};

export { useGetPaymentDetailsData };
