import { gql } from '@apollo/client';

export const INVITE_USER = gql`
  mutation inviteUser($name: String!, $email: String!, $systemUserRole: SystemUserRole!, $teamId: ID) {
    inviteUser(name: $name, email: $email, systemUserRole: $systemUserRole, teamId: $teamId) {
      id
    }
  }
`;

export const RESEND_INVITE_USER = gql`
  mutation($id: ID!) {
    resendInviteUser(id: $id)
  }
`;

export const UPDATE_USER_ROLE = gql`
  mutation UpdateUserRole($id: ID!, $newRole: SystemUserRole) {
    updateUserRole(id: $id, newRole: $newRole) {
      id
    }
  }
`;

export const DEACTIVATE_USER = gql`
  mutation deactivateUser($id: ID!) {
    deactivateUser(id: $id) {
      id
    }
  }
`;

export const REACTIVATE_USER = gql`
  mutation reactivateUser($id: ID!) {
    reactivateUser(id: $id) {
      id
    }
  }
`;
