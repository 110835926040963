import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { bool, string, func, arrayOf, shape, elementType, number } from 'prop-types';
import { useTable, useColumnOrder, useAbsoluteLayout, useResizeColumns } from 'react-table';
import { useMeasure, usePrevious } from 'react-use';
import { useLocation } from 'react-router-dom';
import { StyledTable } from '../table/styled';
import { StyledTableContainer } from './styled';
import SaveView from './SaveView';
import ListSkeleton from '../ListSkeleton';
import EmptyState from './EmptyState';
import Pagination from './Pagination';
import OptionsDrawer from './OptionsDrawer';
import { ROWS_PER_PAGE, transformTableToFields, transformTableToState, transformStateToTable } from './constants';
import TableHead from './TableHead';
import TableBody from './TableBody';
import { TableContext } from './TableContext';
import { useTableSettings, useResetTable } from './hooks';
import useSearchChanges from '../../hooks/useSearchChanges';
import { useGlobalContext } from '../../containers/App/context';
import ExportFiles from './ExportFiles';
import useInfiniteHorizontalScroll from './hooks/useInfiniteHorizontalScroll';

const SmartTable = ({
  data,
  isEmpty,
  pageInfo,
  loading,
  initialState,
  setActiveDrawer,
  activeDrawer,
  drawerOpen,
  shouldSaveTable,
  setShouldSaveTable,
  exportDialog,
  toggleExportDialog,
  selected,
  setSelected,
  checkboxEditMode,
  toggleCheckboxEditMode,
  globalTableSaveDialog,
  toggleSaveDialog,
  headCells,
  searchSelectRef,
  transformHiddenColumnsFn,
  emptyStateComponent,
  customTableNegativeHeight,
  customDetailsId,
  customDetailsPathname,
  customDetailsPathParam,
  canExportTable,
  noCheckBox,
  width,
  height,
  margin,
  disableRowClick,
  headCellDropdownOptions,
  noLastRowBorder,
  noPagination,
  overflowY,
  loadingStateStyles,
  trHeight,
  noDnd,
}) => {
  /** Custom Empty State */
  const EmptyStateComponent = (typeof emptyStateComponent === 'function' && emptyStateComponent) || EmptyState;

  /*Get our view values and transforms search and filter to data shape supported by backend */
  const { initialValues } = useSearchChanges();

  /*If the left sidebar is collapsed  */
  const { sidebarCollapsed } = useGlobalContext();

  /*Manages hidden columns  */
  const [localHiddenColumn, setLocalHiddenColumn] = useState([]);

  /*helps set the width of the table and each columns  */
  const [containerRef, { width: tableWidth }] = useMeasure();

  const { pathname } = useLocation();

  /*Here, we filter out hidden columns from our list of columns  */
  const defaultVisibleColumns = useMemo(
    () => headCells?.filter((header) => !localHiddenColumn?.includes(header.accessor))?.filter(Boolean) ?? [],

    [headCells, localHiddenColumn]
  );

  const { loaderRefFn, chunkedColumns } = useInfiniteHorizontalScroll({
    headCells,
    checkboxEditMode,
    defaultVisibleColumns,
  });

  const columns = checkboxEditMode ? chunkedColumns : defaultVisibleColumns;

  /*A flag to disable hide columns feature - The table crashed when there are no columns (headCells) */
  const disableHideColumns = useMemo(() => defaultVisibleColumns.length === 1, [defaultVisibleColumns]);

  /*A flag to display pagination buttons */
  const showPagination = pageInfo?.totalPages > 1;

  /*here set localHiddenColumn value [""]  */
  useEffect(() => {
    setLocalHiddenColumn(transformHiddenColumnsFn(initialState));
  }, [initialState, transformHiddenColumnsFn]);

  /*useTableSettings here returns stateReducer and default column both passed into useTable. See react-table doc. */
  const { defaultColumn, stateReducer } = useTableSettings({
    setShouldSaveTable,
    shouldSaveTable,
    initialState,
  });

  /*https://react-table.tanstack.com*/
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setColumnOrder,
    allColumns,
    state: tableState,
    resetResizing,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: transformStateToTable(initialState),
      stateReducer,
    },
    useColumnOrder,
    useAbsoluteLayout,
    useResizeColumns
  );
  /*This helps reset the table and hide save view button on route change */
  const notifyStateOnRouteChange = useCallback(() => stateReducer({}, { type: 'switchingPages' }), [stateReducer]);

  const prevCount = usePrevious(pathname);

  useEffect(() => {
    if (prevCount !== pathname) {
      notifyStateOnRouteChange(); //hides save view when switching between views
    }
  }, [pathname, notifyStateOnRouteChange, prevCount]);

  /*This handles our reset view feature */
  const { handleReset } = useResetTable({
    initialState,
    resetResizing,
    setLocalHiddenColumn,
    setColumnOrder,
    tableState,
    stateReducer,
    transformHiddenColumnsFn,
  });

  const contextValue = useMemo(
    () => ({
      headerGroups,
      setColumnOrder,
      allColumns,
      rows,
      tableState,
      drawerOpen,
      checkboxEditMode,
      toggleCheckboxEditMode,
      localHiddenColumn,
      setLocalHiddenColumn,
      getTableBodyProps,
      prepareRow,
      headCells,
      handleReset,
      toggleSaveDialog,
      data,
      setSelected,
      tableWidth,
      activeDrawer,
      setActiveDrawer,
      shouldSaveTable,
      setShouldSaveTable,
      exportDialog,
      toggleExportDialog,
      selected,
      globalTableSaveDialog,
      disableHideColumns,
    }),
    [
      headerGroups,
      setColumnOrder,
      allColumns,
      rows,
      tableState,
      headCells,
      checkboxEditMode,
      toggleCheckboxEditMode,
      drawerOpen,
      localHiddenColumn,
      setLocalHiddenColumn,
      getTableBodyProps,
      prepareRow,
      handleReset,
      toggleSaveDialog,
      data,
      selected,
      setSelected,
      toggleExportDialog,
      tableWidth,
      activeDrawer,
      setActiveDrawer,
      shouldSaveTable,
      setShouldSaveTable,
      exportDialog,
      globalTableSaveDialog,
      disableHideColumns,
    ]
  );

  const visibleColumns = useMemo(() => headCells?.filter((header) => !localHiddenColumn?.includes(header.accessor)), [
    headCells,
    localHiddenColumn,
  ]);

  /*Values to send to back when user clicks on save view */
  const valuesToSave = useMemo(
    () => ({
      ...initialValues,
      hiddenFields: transformTableToFields(localHiddenColumn),
      visibleFields: transformTableToFields(visibleColumns),
      tableState: transformTableToState(tableState),
    }),
    [initialValues, localHiddenColumn, tableState, visibleColumns]
  );

  return (
    <TableContext.Provider value={contextValue}>
      <StyledTableContainer
        $customTableNegativeHeight={customTableNegativeHeight}
        ref={containerRef}
        // if there's no pagination, height = 100vh -106px
        //else adjust height to show scrollbar
        empty={!showPagination}
        draweropen={drawerOpen ? 1 : 0}
        sidecollapsed={sidebarCollapsed ? 1 : 0}
        width={width}
        height={height}
        margin={margin}
        overflowY={overflowY}
        {...getTableProps()}
      >
        {/**Table */}
        <StyledTable width="1px" aria-labelledby="tableTitle" size="small" aria-label="enhanced table">
          <TableHead noCheckBox={noCheckBox} headCellDropdownOptions={headCellDropdownOptions} noDnd={noDnd} />

          <TableBody
            loaderRefFn={loaderRefFn}
            customDetailsPathname={customDetailsPathname}
            customDetailsId={customDetailsId}
            customDetailsPathParam={customDetailsPathParam}
            noCheckBox={noCheckBox}
            disableRowClick={disableRowClick}
            noLastRowBorder={noLastRowBorder}
            trHeight={trHeight}
          />
        </StyledTable>

        <OptionsDrawer />

        {/**STATES */}
        {loading && (
          <ListSkeleton
            rowNumber={loadingStateStyles.rows || ROWS_PER_PAGE}
            height={loadingStateStyles.height || 38}
            p={loadingStateStyles?.padding || 0.2}
          />
        )}

        {isEmpty && <EmptyStateComponent searchSelectRef={searchSelectRef} />}

        {/**MODALS */}
        <SaveView
          initialValues={valuesToSave}
          isOpen={globalTableSaveDialog}
          toggleIsOpen={toggleSaveDialog}
          notifyStateOnRouteChange={notifyStateOnRouteChange}
        />
        {canExportTable && (
          <ExportFiles
            selected={selected}
            isOpen={exportDialog}
            toggleExportDialog={toggleExportDialog}
            currentViewColumns={allColumns}
          />
        )}
      </StyledTableContainer>
      {/**Pagination */}
      {showPagination && !noPagination && <Pagination loading={loading} pageInfo={pageInfo} />}
    </TableContext.Provider>
  );
};

SmartTable.propTypes = {
  data: arrayOf(shape({})).isRequired,
  pageInfo: shape({
    endCursor: number,
    totalPages: number,
    hasNextPage: bool,
    hasPreviousPage: bool,
  }).isRequired,
  loading: bool.isRequired,
  isEmpty: bool.isRequired,
  initialState: shape({
    tableState: shape({
      columnResizing: shape({
        columnWidths: arrayOf(shape({})),
      }),
      columnOrder: arrayOf(string),
    }),
  }).isRequired,
  //props
  setActiveDrawer: func.isRequired,
  activeDrawer: shape({
    menu: string,
    subMenu: string,
  }).isRequired,
  shouldSaveTable: bool.isRequired,
  setShouldSaveTable: func.isRequired,
  exportDialog: bool.isRequired,
  toggleExportDialog: func.isRequired,
  selected: arrayOf(string).isRequired,
  setSelected: func.isRequired,
  checkboxEditMode: bool.isRequired,
  toggleCheckboxEditMode: func.isRequired,
  globalTableSaveDialog: bool.isRequired,
  toggleSaveDialog: func.isRequired,
  headCells: arrayOf(shape({})).isRequired,
  searchSelectRef: shape({}).isRequired,
  transformHiddenColumnsFn: func.isRequired,
  drawerOpen: bool.isRequired,
  emptyStateComponent: elementType.isRequired,
  customTableNegativeHeight: string,
  customDetailsId: string,
  customDetailsPathname: string,
  customDetailsPathParam: string,
  canExportTable: bool,
  noCheckBox: bool,
  width: string,
  height: string,
  margin: string,
  disableRowClick: bool,
  headCellDropdownOptions: arrayOf(shape({})),
  noLastRowBorder: bool,
  noPagination: bool,
  overflowY: string,
  loadingStateStyles: shape({}),
  trHeight: string,
  noDnd: bool,
};
SmartTable.defaultProps = {
  customTableNegativeHeight: null,
  customDetailsId: null,
  customDetailsPathname: null,
  customDetailsPathParam: null,
  canExportTable: false,
  noCheckBox: false,
  width: null,
  height: null,
  margin: null,
  disableRowClick: null,
  headCellDropdownOptions: null,
  noLastRowBorder: false,
  noPagination: false,
  overflowY: undefined,
  loadingStateStyles: {},
  trHeight: undefined,
  noDnd: false,
};
export default SmartTable;
