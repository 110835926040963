import React, { useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails } from '@mui/material';
import { bool, func } from 'prop-types';
import { StyledBox } from './styled';
import { ButtonRounded, P14, P16B } from '../../../../../atoms';
import THEME from '../../../../../../constants/theme';
import { useGetConnectionDetails } from '../../../../../../pages/ConnectionsPage/hooks/useGetConnectionDetails';
import { CONNECTION_STATUS } from '../../../../../../pages/ConnectionsPage/components/constant';
import { useHandleConnection } from '../../../../../../pages/FlowDetailsPage/FlowSettings/PaymentProcessorPage/ConnectedSection/NewConnectionModal/useHandleConnection';
import { useFlowEditorContext } from '../../../../context';
import { useNodeDataToSave } from '../fields/hooks/useNodeDataToSave';
import { filterNodes } from '../../../../utils/filterElements';
import { repopulateEdges } from '../../../../utils/facades';
import { StyledAccordion, StyledAccordionSummary } from '../fields/NodeIssues/styled';

const NotConnectedSection = ({ isExpanded, setExpanded }) => {
  const { t } = useTranslation();
  const { setElementsAndSave, stepLibraryRefetch, nodes } = useFlowEditorContext();
  const [{ connectionId }, updateDataToSave] = useNodeDataToSave();
  const { connection } = useGetConnectionDetails(connectionId);
  const memoizedConnection = useMemo(() => ({ ...connection, name: connection?.company?.name }), [connection]);

  const onSuccess = useCallback(
    async (newConnectionId) => {
      if (newConnectionId) {
        const nodeToUpdateIds = nodes?.reduce(
          (acc, node) => (node.data?.connectionId === connectionId ? [...acc, node.id] : acc),
          []
        );
        const updatedData = {
          connectionId: `connection-provider:${newConnectionId}`,
          connectionStatus: CONNECTION_STATUS.CONNECTED,
        };
        updateDataToSave(updatedData);
        setElementsAndSave((els) =>
          repopulateEdges(
            filterNodes(els).map((node) =>
              nodeToUpdateIds?.includes(node.id)
                ? {
                    ...node,
                    data: {
                      ...node.data,
                      ...updatedData,
                    },
                  }
                : node
            )
          )
        );
        await stepLibraryRefetch();
      }
    },
    [connectionId, nodes, setElementsAndSave, stepLibraryRefetch, updateDataToSave]
  );

  const { handleConnect, renderConnectionForm } = useHandleConnection({
    connection: memoizedConnection,
    closeModal: onSuccess,
  });

  return (
    <StyledAccordion elevation={0} expanded={isExpanded} onChange={setExpanded} key="not-connected">
      <StyledAccordionSummary
        $expanded={isExpanded}
        expandIcon={<ExpandMoreIcon color={isExpanded ? 'action' : 'primary'} />}
        aria-controls="helper-field-content"
        id="helper-field-content"
      >
        <P16B color={THEME.primaryColors.white}>{t('Authentication Required')}</P16B>
      </StyledAccordionSummary>
      <AccordionDetails>
        <Box>
          <P14 color="rgba(255, 255, 255, 0.8)">
            {t(
              'Authenticate this Connection to see all configuration options and to successfully use it in your Automation'
            )}
          </P14>
          <StyledBox color={THEME.primaryColors.white}>
            <ButtonRounded
              type="button"
              color="secondary"
              variant="contained"
              width="100%"
              onClick={handleConnect}
              disabled={isEmpty(connectionId)}
              backgroundColor={THEME.primaryColors.white}
              mt="12px"
            >
              {t('Connect')}
            </ButtonRounded>
            {renderConnectionForm()}
          </StyledBox>
        </Box>
      </AccordionDetails>
    </StyledAccordion>
  );
};

NotConnectedSection.propTypes = {
  setExpanded: func.isRequired,
  isExpanded: bool.isRequired,
};

export default NotConnectedSection;
