import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { NotificationManager } from 'react-notifications';

import { UI_ROUTES } from '../../constants/routes';
import { GQL_M_VERIFY_BETA_CODE } from '../../utils/queries/public/publicMutations';
import { useRegistrationSearchParams, useRegistrationStorage } from '../../hooks/registration';
import { TOAST_TIMEOUT, TOAST_UNEXPECTED_ERROR_MESSAGE } from '../../constants/toasts';
import { REG_METHOD, ONBOARDING_STATUS } from '../../constants/registration';
import ToastCustomContainer from '../../components/ToastCustomContainer';

const TOAST_TITLE = 'Verify Beta Link.';

const useVerifyBetaLink = () => {
  const { betaAccessToken, emailAddress, registrationMethod } = useRegistrationSearchParams();
  const [, setRegistrationSession, clearRegistrationStorage] = useRegistrationStorage();
  const { replace, push, location } = useHistory();
  const [checkBetaCode, { called, loading }] = useMutation(GQL_M_VERIFY_BETA_CODE, {
    context: { skipGlobalHandling: true },
  });

  const handleCheckBetaCode = useCallback(async () => {
    try {
      const { data, errors } = await checkBetaCode({
        variables: {
          emailAddress,
          betaAccessToken,
        },
      });

      const isFailed = !isEmpty(errors) || !data?.checkBetaCode;

      if (isFailed) {
        setTimeout(() => {
          push(UI_ROUTES.expiredBetaLink);
        }, 3000);
        return;
      }

      setRegistrationSession({
        betaAccessToken: betaAccessToken,
        onboardingStatus: ONBOARDING_STATUS.completeRegistration,
      });

      switch (registrationMethod) {
        case REG_METHOD.email:
          setTimeout(() => {
            push(`${UI_ROUTES.enterPassword}${location?.search || ''}`);
          }, 3000);
          break;
        case REG_METHOD.google:
          setTimeout(() => {
            push(`${UI_ROUTES.completeSignUp}${location?.search || ''}`);
          }, 3000);
          break;
        default:
          setTimeout(() => {
            push(UI_ROUTES.signIn);
          }, 3000);
          break;
      }
    } catch (error) {
      setTimeout(() => {
        NotificationManager.error(
          <ToastCustomContainer message={TOAST_UNEXPECTED_ERROR_MESSAGE} title={TOAST_TITLE} />,
          null,
          TOAST_TIMEOUT
        );

        push(UI_ROUTES.expiredBetaLink);
      }, 3000);
    }
  }, [
    betaAccessToken,
    emailAddress,
    registrationMethod,
    location?.search,
    setRegistrationSession,
    checkBetaCode,
    push,
  ]);

  useEffect(() => {
    const isMissingRequiredParam = [betaAccessToken, emailAddress, registrationMethod].some(isEmpty);
    if (isMissingRequiredParam) {
      setTimeout(() => {
        replace(UI_ROUTES.signIn);
      }, 3000);
      return;
    }

    if (!called) {
      clearRegistrationStorage();
      handleCheckBetaCode();
    }
  }, [
    replace,
    called,
    handleCheckBetaCode,
    clearRegistrationStorage,
    betaAccessToken,
    emailAddress,
    registrationMethod,
  ]);

  return { loading };
};

export { useVerifyBetaLink };
