import React, { useCallback } from 'react';
import Skeleton from '@mui/lab/Skeleton';
import FormHelperText from '@mui/material/FormHelperText';
import { string } from 'prop-types';
import { useStyles, StyledFormControl } from '../../styled';
import { useFlatFlowProperties } from '../../hooks/useFlatFlowProperties';
import { useActionInputContext } from '../actionInputContext';
import Select, { NONE } from '../../../../../../../forms/_common/Select';

const SelectPropertyInput = ({ selectedActionType }) => {
  const { fieldValue, setInputField, validationMessage } = useActionInputContext();
  const { loading, flatProperties } = useFlatFlowProperties(selectedActionType ? [selectedActionType] : []);
  const classes = useStyles();

  const onChange = useCallback(
    ({ target }) => {
      return setInputField(target.value);
    },
    [setInputField]
  );

  const hasError = Boolean(validationMessage);

  return (
    <StyledFormControl fullWidth error={!!validationMessage} $mb={validationMessage ? '32px' : ''}>
      {loading && <Skeleton height="30px" animation="wave" />}
      {flatProperties ? (
        <Select name="connection" value={fieldValue || NONE} options={flatProperties} onChange={onChange} />
      ) : null}
      {hasError && <FormHelperText classes={{ root: classes.root }}>{validationMessage}</FormHelperText>}
    </StyledFormControl>
  );
};

SelectPropertyInput.propTypes = {
  selectedActionType: string,
};

SelectPropertyInput.defaultProps = {
  selectedActionType: '',
};

export default SelectPropertyInput;
