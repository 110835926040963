import React, { useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/lab/Skeleton';
import SimpleTabs from '../../../../../common/Tabs/Tabs';
import THEME from '../../../../../../constants/theme';
import SingleRecordField from '../fields/SingleRecordField';
import DataSetField from '../fields/DataSetField';
import useGetAvailableDataProviders from '../hooks/useGetAvailableDataProviders';
import { useNodeDataToSave } from '../fields/hooks/useNodeDataToSave';

const records = [
  { value: 'id', title: 'ID', options: [] },
  { value: 'email', title: 'Email', options: [] },
];

const QueryTypeSection = () => {
  const [{ selectedDataProvider, selectedQueryType }, updateDataToSave] = useNodeDataToSave();

  const { availableDataProviders, loading } = useGetAvailableDataProviders();

  const dataProvider = availableDataProviders?.find(({ type }) => type === selectedDataProvider);

  const onChange = (value) => {
    updateDataToSave({
      selectedQueryType: value,
      filters: null,
      singleRecordUniquenessOperator: null,
      dataSetVariable: null,
      dataSetProjection: null,
    });
  };

  const tabs = useMemo(
    () =>
      [
        {
          label: 'Query type',
          id: 'queryType',
          disabled: true,
        },
        dataProvider?.supportedSearches?.indexOf('DATA_SET') >= 0 && {
          label: 'Data Set',
          id: 'DATA_SET',
          node: <DataSetField>Data Set</DataSetField>,
        },
        dataProvider?.supportedSearches?.indexOf('SINGLE_RECORD') >= 0 && {
          label: 'Single Record',
          id: 'SINGLE_RECORD',
          node: <SingleRecordField records={records} value="email" />,
        },
      ].filter(Boolean),
    [dataProvider?.supportedSearches]
  );

  const currentTab = tabs.findIndex((tab) => tab.id === selectedQueryType);

  useEffect(() => {
    if (!selectedQueryType) {
      updateDataToSave({
        selectedQueryType: tabs[currentTab >= 0 ? currentTab : 1]?.id,
      });
    }
  }, [tabs, currentTab, selectedQueryType, updateDataToSave]);

  return selectedDataProvider && !loading ? (
    <SimpleTabs
      color={THEME.primaryColors.orange}
      tabs={tabs}
      initialValue={currentTab >= 0 ? currentTab : 1}
      onChange={onChange}
    />
  ) : (
    loading && (
      <Box borderRadius="8px" mt="8px">
        <Skeleton height="40px" />
      </Box>
    )
  );
};

export default QueryTypeSection;
