import React from 'react';
import PropTypes from 'prop-types';

const Tour = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.16675 3.33329C5.94509 3.33329 3.33341 5.94496 3.33341 9.16663C3.33341 12.3883 5.94509 15 9.16675 15C12.3884 15 15.0001 12.3883 15.0001 9.16663C15.0001 5.94496 12.3884 3.33329 9.16675 3.33329ZM1.66675 9.16663C1.66675 5.02449 5.02461 1.66663 9.16675 1.66663C13.3089 1.66663 16.6667 5.02449 16.6667 9.16663C16.6667 13.3088 13.3089 16.6666 9.16675 16.6666C5.02461 16.6666 1.66675 13.3088 1.66675 9.16663Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2858 13.2857C13.6113 12.9603 14.1389 12.9603 14.4643 13.2857L18.0893 16.9107C18.4148 17.2361 18.4148 17.7638 18.0893 18.0892C17.7639 18.4147 17.2363 18.4147 16.9108 18.0892L13.2858 14.4642C12.9604 14.1388 12.9604 13.6111 13.2858 13.2857Z"
      fill={fill}
    />
  </svg>
);
Tour.propTypes = {
  fill: PropTypes.string,
};
Tour.defaultProps = {
  fill: '#6A6A6A',
};
export default Tour;
