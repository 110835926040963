import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { isDefined } from '../../../utils/helpers';
import { useFeature } from '../../../hooks/useFeature';
import { FEATURE_TOGGLES_KEYS } from '../../../constants/featureToggles';
import { UI_ROUTES } from '../../../constants/routes';
import { REFETCH_CONNECTIONS_LIST_FLAG, CONNECTION_TOAST_TITLE } from './constant';
import { TOAST_TIMEOUT } from '../../../constants/toasts';
import { showNotification } from '../../../hooks/useNotificationManager';

const SERVER_ERROR_TOAST_MESSAGE = 'Server error.';
const WT_OAUTH_KEY = 'wt-0auth-payload-key';

const useConnectionWindowMessageListener = ({
  connection,
  childWindow,
  callback,
  avoidRedirect,
  closeModal,
  handleCallback,
  setOAuthData,
  togglePaymentMethodDialog,
}) => {
  const [multipleFlowEnabled] = useFeature(FEATURE_TOGGLES_KEYS.MULTIPLE_FLOW);
  const { push } = useHistory();

  const handleListener = useCallback(
    (e) => {
      if (e && typeof e?.data === 'string') {
        const payload = JSON.parse(e?.data);
        const id = payload?.res?.id;
        const connectionStep = payload?.res?.steps ?? [];
        const hasApmForm = isDefined(connectionStep?.find((el) => el?.type === 'APM'));

        const error = Array.isArray(payload?.errors)
          ? payload?.errors?.[0]?.message
          : payload?.errors?.split?.('_')?.join(' ');

        if (payload?.success && childWindow && payload?.key === WT_OAUTH_KEY && !error) {
          childWindow.close();

          localStorage.setItem(REFETCH_CONNECTIONS_LIST_FLAG, true);

          showNotification(
            'success',
            `${connection?.name || connection?.company?.name} has been successfully connected.`,
            CONNECTION_TOAST_TITLE,
            TOAST_TIMEOUT
          );

          if (multipleFlowEnabled && hasApmForm) {
            togglePaymentMethodDialog();
            setOAuthData(payload?.res);
            return;
          }

          if (!avoidRedirect) {
            setTimeout(() => push(`${UI_ROUTES.connectionDetails}/${id}`), 1000);
          }
          if (callback) {
            setTimeout(() => handleCallback(id), 1000);
          }
          if (closeModal) {
            closeModal(id);
          }
        }

        if (error) {
          childWindow.close();
          showNotification(
            'error',
            `There was an error while connecting ${connection?.name || connection?.company?.name}`,
            CONNECTION_TOAST_TITLE,
            TOAST_TIMEOUT
          );
        }

        if (!payload?.success && childWindow && payload?.key === WT_OAUTH_KEY) {
          childWindow.close();
          showNotification('error', error ?? SERVER_ERROR_TOAST_MESSAGE, CONNECTION_TOAST_TITLE, TOAST_TIMEOUT);
        }
      }
    },
    [
      childWindow,
      connection?.name,
      connection?.company?.name,
      multipleFlowEnabled,
      avoidRedirect,
      callback,
      closeModal,
      togglePaymentMethodDialog,
      push,
      handleCallback,
      setOAuthData,
    ]
  );

  useEffect(() => {
    window.addEventListener('message', handleListener);
    return () => window.removeEventListener('message', handleListener);
  }, [handleListener]);
};

export { useConnectionWindowMessageListener };
