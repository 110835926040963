import React, { useState } from 'react';
import { shape, string, arrayOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { H3B } from '../../../../../components/atoms';
import TableSection from '../../../../DataTables/components/Details/TableSection';
import { PAYMENT_DESCRIBED_KEYS, DISPUTES_DATA_KEYS } from '../../paymentDescribedDataKeys';
import { PAYMENT_DETAILS_SECTION_COMPONENT_MAP } from '../../paymentDetailsComponentMap';
import { StyledWrapper, Left, Header, StatusLabel, Right, Main } from './styled';
import { parseStatusToUiLabel } from '../../_common/StatusComponent';
import Submissions from './Submissions';
import ActionButtons from './ActionButtons';
import { STATUS_COLOR_MAP, DISPUTE_STATUS, DISPUTE_SUBMISSION_STATUS, getUuid } from './constant';
import { LIST_DISPUTES_EVIDENCE_SUBMISSION } from '../../../../../utils/queries/disputes/listDisputes';
import ConfirmationDialog from './FormDrawer/ConfirmationDialog';
import { parseSmartId } from '../../../../../utils/parseSmartId';

const hasDraftSubmission = (submissions) =>
  !isEmpty(submissions) && submissions?.some((submission) => submission.status === DISPUTE_SUBMISSION_STATUS.draft);

const PROCESSORS_TO_SHOW_DISPUTE_EVIDENCE_SUBMISSION = [
  'payment-gateway-stripe',
  'payment-gateway-adyen',
  'payment-gateway-paypal',
];

const STATUES_TO_SHOW_DISPUTE_EVIDENCE_SUBMISSION = [
  DISPUTE_STATUS.needsResponse,
  DISPUTE_STATUS.underReview,
  DISPUTE_STATUS.closed,
  DISPUTE_STATUS.sentForReview,
];

const Dispute = ({ dispute, connectionId }) => {
  const { t } = useTranslation();
  const [shouldDrawerOpen, setShouldDrawerOpen] = useState(false);
  const [shouldModalOpen, setShouldModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [disabled, setDisabled] = useState(false);

  const status = dispute.value.find((item) => item.key === PAYMENT_DESCRIBED_KEYS.status).value;
  const disputeId = getUuid(dispute.value.find((item) => item.key === PAYMENT_DESCRIBED_KEYS.id).value);
  const sourceDisputeId = dispute.value.find((item) => item.key === PAYMENT_DESCRIBED_KEYS.sourceDisputeId).value;
  const responseDate = dispute.value.find((item) => item.key === PAYMENT_DESCRIBED_KEYS.responseDate).value;

  const connectionServiceId = parseSmartId(connectionId)[0];

  const { loading, data } = useQuery(LIST_DISPUTES_EVIDENCE_SUBMISSION, {
    variables: { disputeId },
  });

  const listDisputeEvidenceSubmissions = data?.listDisputeEvidenceSubmissions;
  const isDisputeSupported = PROCESSORS_TO_SHOW_DISPUTE_EVIDENCE_SUBMISSION.includes(connectionServiceId);

  const showDisputeSubmissionSection =
    isDisputeSupported && STATUES_TO_SHOW_DISPUTE_EVIDENCE_SUBMISSION.includes(status);

  const showAcceptDisputeButton = status === DISPUTE_STATUS.needsResponse;
  const showSubmitEvidenceButton =
    !hasDraftSubmission(data?.listDisputeEvidenceSubmissions) && status === DISPUTE_STATUS.needsResponse;

  return (
    <StyledWrapper>
      <Header>
        <H3B>{t('Dispute')}</H3B>
        <StatusLabel color={STATUS_COLOR_MAP[status]}>{t(parseStatusToUiLabel(status))}</StatusLabel>
      </Header>

      <Main>
        <Left>
          <TableSection
            header="Info"
            data={dispute}
            dataKey={DISPUTES_DATA_KEYS}
            customComponentsMap={PAYMENT_DETAILS_SECTION_COMPONENT_MAP}
          />
        </Left>

        {showDisputeSubmissionSection && (
          <Right>
            <Submissions
              loading={loading}
              listDisputeEvidenceSubmissions={listDisputeEvidenceSubmissions}
              disputeStatus={status}
              shouldDrawerOpen={shouldDrawerOpen}
              setShouldDrawerOpen={setShouldDrawerOpen}
              disputeId={disputeId}
              connectionId={connectionId}
              sourceDisputeId={sourceDisputeId}
              responseDate={responseDate}
              setModalData={setModalData}
              setShouldModalOpen={setShouldModalOpen}
              setDisabled={setDisabled}
              connectionServiceId={connectionServiceId}
            />
          </Right>
        )}
      </Main>

      {isDisputeSupported && (
        <ActionButtons
          setShouldDrawerOpen={setShouldDrawerOpen}
          connectionId={connectionId}
          disputeId={disputeId}
          showAcceptDisputeButton={showAcceptDisputeButton}
          showSubmitEvidenceButton={showSubmitEvidenceButton}
          setModalData={setModalData}
          setShouldModalOpen={setShouldModalOpen}
          setDisabled={setDisabled}
        />
      )}

      <ConfirmationDialog
        shouldModalOpen={shouldModalOpen}
        modalData={modalData}
        setShouldModalOpen={setShouldModalOpen}
        disabled={disabled}
      />
    </StyledWrapper>
  );
};

Dispute.propTypes = {
  dispute: shape({
    value: arrayOf(shape({})),
    type: string,
  }).isRequired,
  connectionId: string.isRequired,
};

export default Dispute;
