import styled, { css } from 'styled-components';
import Popover from '@mui/material/Popover/Popover';
import IconButtonBase from '@mui/material/IconButton';
import { StyledOptionMenuItem } from '../../SwitchUser/styled';
import THEME from '../../../constants/theme';

const chooseHoverColor = (bgcolor, hoverColor) => {
  if (hoverColor) return hoverColor;
  if (bgcolor) return THEME.primaryColors.primaryLight;
  if (!bgcolor) return THEME.greyColors.grey5;

  return null;
};

const StyledPopover = styled(Popover)`
  & .MuiPaper-root,
  & .MuiPaper-root.MuiPaper-elevation8:not(.MuiCard-root) {
    min-width: ${({ width }) => `${width} !important` || 'min-content'};
    border-radius: 4px;
    border: none;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.12);
  }
`;
const StyledList = styled.ul`
  padding: 4px;
  margin: 0;
  list-style: none;

  ${({ lastItemDanger }) =>
    lastItemDanger
      ? css`
          & > :last-child {
            color: #b74242 !important;
          }
        `
      : undefined};
`;

const StyledListItem = styled(StyledOptionMenuItem)`
  &.MuiButtonBase-root {
    color: ${THEME.primaryColors.blackMain};
    font-weight: 400;
    font-size: ${({ $isSelect }) => ($isSelect ? '12px !important' : '14px !important')};
    padding: 6px 16px;
    margin: 0;
    border-radius: 4px;
    padding: 4px 8px;

    &:last-child {
      margin-bottom: 0px;
    }

    &:hover {
      background-color: ${THEME.greyColors.grey21};
      color: ${THEME.primaryColors.blackMain};
    }
  }
`;

const IconButton = styled(IconButtonBase)`
  &.MuiButtonBase-root {
    transition: all 0.3s ease-out;
    border-radius: 8px !important;
    background-color: ${({ bgcolor }) => bgcolor || '#F5F6F7'};
    padding: ${({ p }) => p || '6px'};
    font-size: 20px;
    width: 40px;
    height: 40px;
    margin: ${({ margin }) => margin || '0'};

    &:hover {
      background-color: ${({ bgcolor, $hoverColor }) => chooseHoverColor(bgcolor, $hoverColor)};
    }
  }
`;

export { StyledListItem, StyledList, StyledPopover, IconButton };
