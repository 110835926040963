import { arrayOf, shape, string, func, bool } from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { P12 } from '../../../../../../../components/atoms';
import Select from '../../../../../FilterList/fields/Select';
import { StyledSelectWrapper } from './styled';
import LoadingState from './LoadingState';

const DROPDOWN_TITLE = 'Applicable Defense Reason';

const Dropdown = ({ loading, options, selectedDropdown, setSelectedDropdown }) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    const { value } = e.target;
    setSelectedDropdown(value);
  };

  return (
    <Box margin="0 32px">
      <P12 fontWeight="500">{t(DROPDOWN_TITLE)}</P12>
      {loading && <LoadingState />}

      {!loading && (
        <StyledSelectWrapper>
          <Select name="defenseReason" value={selectedDropdown} options={options} onChange={handleChange} />
        </StyledSelectWrapper>
      )}
    </Box>
  );
};

Dropdown.propTypes = {
  loading: bool.isRequired,
  options: arrayOf(shape({})).isRequired,
  selectedDropdown: string.isRequired,
  setSelectedDropdown: func.isRequired,
};

export default Dropdown;
