import { gql } from '@apollo/client';

export const SEARCH_DISPUTES_GQL = gql`
  mutation searchInDisputes(
    $input: String
    $searchFilter: [SearchFilterDetails]
    $first: Int
    $after: Int
    $last: Int
    $before: Int
  ) {
    searchInDisputes(
      input: $input
      searchFilter: $searchFilter
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        totalSize
      }
      edges {
        node {
          id
          reason
          processorReason
          status
          processorStatus
          disputeDate
          responseDate
          amount {
            formattedAmount
            currency
          }
          processor
          sourceId
          connectionId
          teamId
        }
      }
      availableKnownValues
    }
  }
`;
