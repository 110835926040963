import React from 'react';
import { func, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { L14 } from '../../../../../../../components/atoms';
import { StyledWrapper, IconWrapper, SubmissionDetails } from './styled';
import File from '../../../../../../../assets/icons/File';
import { L10UM } from '../../../../../../../components/atoms/Typography/L10UM';
import THEME from '../../../../../../../constants/theme';
import { formatDate } from '../../constant';

const Submission = ({ submission, handleOpenDrawer, responseDate }) => {
  const { t } = useTranslation();

  return (
    <StyledWrapper onClick={() => handleOpenDrawer(submission.id)}>
      <IconWrapper>
        <File />
      </IconWrapper>

      <SubmissionDetails>
        <L14 noHover>{formatDate(responseDate)}</L14>
        <L10UM color={THEME.secondaryColors.greenDark}>{t(submission.status)} </L10UM>
      </SubmissionDetails>
    </StyledWrapper>
  );
};

Submission.propTypes = {
  submission: shape({}).isRequired,
  handleOpenDrawer: func.isRequired,
  responseDate: string.isRequired,
};

export default Submission;
