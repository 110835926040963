import React, { useCallback, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { NotificationManager } from 'react-notifications';
import { GQL_M_RENAME_FLOW } from '../../../utils/queries/flows/mutations';

import { TOAST_TITLE, TOAST_TIMEOUT } from './constant';
import ToastCustomContainer from '../../../components/ToastCustomContainer';

export const useRenameFlow = ({ setSaving, setFlow, flow }) => {
  const [newName, setNewName] = useState('');
  const [renameFlow] = useMutation(GQL_M_RENAME_FLOW);

  const handleFieldReset = useCallback(() => {
    setNewName(flow?.name);
  }, [flow?.name]);

  useEffect(() => {
    setNewName(flow?.name);
  }, [setNewName, flow?.name]);

  const handleRename = useCallback(() => {
    if (flow?.name === newName) {
      return;
    }

    if (isEmpty(newName)) {
      NotificationManager.error(
        <ToastCustomContainer message="Flow name cannot be empty." title={TOAST_TITLE} />,
        null,
        TOAST_TIMEOUT
      );

      setNewName(flow?.name);
      return;
    }
    setSaving(true);
    renameFlow({ variables: { flowId: flow?.id?.trim(), newName } })
      .then(({ data, errors }) => {
        if (!isEmpty(errors) || isEmpty(data?.renameFlow)) {
          NotificationManager.error(
            <ToastCustomContainer message={errors?.[0].message} title={TOAST_TITLE} />,
            null,
            TOAST_TIMEOUT
          );
          return;
        }

        setFlow({ ...flow, ...data.renameFlow });
        NotificationManager.success(
          <ToastCustomContainer message="Flow name renamed successfully." title={TOAST_TITLE} />,
          null,
          TOAST_TIMEOUT
        );
      })
      .finally(() => setSaving(false));
  }, [renameFlow, newName, setSaving, setFlow, flow, setNewName]);
  return { handleRename, handleFieldReset, newName, setNewName };
};
