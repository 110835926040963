import React from 'react';
import { arrayOf, shape } from 'prop-types';
import Box from '@mui/material/Box';
import { L12, L14 } from '../../../../../../../components/atoms';
import Download from '../../../../../../../assets/icons/Download';
import THEME from '../../../../../../../constants/theme';
import { DownloadContentContainer, StyledDownloadLink } from './styled';

const SubmittedEvidence = ({ form, initialValues, submission }) => {
  const initialValueKeys = Object.keys(initialValues);

  return (
    <>
      {form.map(({ id, name }) => {
        if (initialValueKeys?.includes(id)) {
          if (typeof initialValues[id] === 'string') {
            return (
              <Box margin="0 0 24px 0">
                <L12 fontWeight="500">{name} </L12>
                <L14 noHover fontWeight="400" margin="6px 0 0 0">
                  {initialValues[id]}
                </L14>
              </Box>
            );
          }

          const attachment = submission.attachmentDetails.find((item) => item.fileName === initialValues[id].fileName);

          return (
            <Box margin="0 0 24px 0">
              <L12 fontWeight="500">{name} </L12>
              <DownloadContentContainer>
                <L14 noHover fontWeight="400">
                  {initialValues[id].fileName}
                </L14>
                <StyledDownloadLink href={attachment.accessUrl}>
                  <Download fill={THEME.greyColors.grey600} />
                </StyledDownloadLink>
              </DownloadContentContainer>
            </Box>
          );
        }

        return null;
      })}
    </>
  );
};

SubmittedEvidence.propTypes = {
  form: arrayOf(shape({})).isRequired,
  initialValues: shape({}).isRequired,
  submission: shape({}).isRequired,
};
export default SubmittedEvidence;
