import React from 'react';
import { useTranslation } from 'react-i18next';
import { number } from 'prop-types';
import { StyledDialogActions, StyledDialogContent } from '../../Dialog/styled';
import { ButtonRounded, P16 } from '../../atoms';
import ArrowRight from '../../../assets/arrows/arrowRight';
import THEME from '../../../constants/theme';
import { StyledDescriptionContentBox } from '../styled';
import { H2B } from '../../atoms/Typography/stories/H2B';
import { PROD_URL } from '../../../constants/api';
import { UI_ROUTES } from '../../../constants/routes';
import { useGlobalContext } from '../../../containers/App/context';
import { SWITCH_ENV_STEPS } from '../constant';

const SuccessStep = ({ automationsNumber }) => {
  const { t } = useTranslation();
  const { setIsOpenSwitchEnvModal, setCurrentSwitchEnvStep } = useGlobalContext();

  const handleClick = () => {
    window.open(`${PROD_URL}${UI_ROUTES.checkYourEmail}`, '_blank');
    setIsOpenSwitchEnvModal(false);
    setCurrentSwitchEnvStep(SWITCH_ENV_STEPS.VIDEO);
  };

  return (
    <>
      <StyledDialogContent px="0">
        <StyledDescriptionContentBox>
          <H2B margin="0 0 24px 0">{t('Automations transferred to production.')}</H2B>
          <P16 color={THEME.greyColors.grey600}>
            {t(`We transferred ${automationsNumber} automations from your sandbox account to production. You can now login to your
          production account and continue your work.`)}
          </P16>
        </StyledDescriptionContentBox>
      </StyledDialogContent>
      <StyledDialogActions $justifyContent="flex-end" px="32px" py="16px" $borderTop>
        <ButtonRounded
          type="button"
          variant="contained"
          color="primary"
          borderRadius="6px"
          endIcon={<ArrowRight size={24} color={`${THEME.primaryColors.white}`} />}
          onClick={handleClick}
        >
          {t('Complete Setup')}
        </ButtonRounded>
      </StyledDialogActions>
    </>
  );
};

SuccessStep.propTypes = {
  automationsNumber: number.isRequired,
};

export default SuccessStep;
