import styled from 'styled-components';
import Popover from '@mui/material/Popover/Popover';
import IconButtonBase from '@mui/material/IconButton';
import { StyledOptionMenuItem } from '../../../../components/SwitchUser/styled';
import THEME from '../../../../constants/theme';

const StyledPopover = styled(Popover)`
  & .MuiPaper-root {
    min-width: ${({ width }) => `${width} !important` || 'min-content'};
    border: none !important;

    &.MuiPaper-elevation1 {
      box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
    }
  }
`;
const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const StyledListItemWrapper = styled.div`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${THEME.greyColors.grey200};
  }
`;

const StyledListItem = styled(StyledOptionMenuItem)`
  &.MuiMenuItem-root {
    padding: 6px 16px;
    color: ${THEME.primaryColors.primary};
    margin: 4px;
    border-radius: 6px;
    font-size: 32px;
    line-height: 120%;
    font-weight: 700;
    letter-spacing: 0;

    @media (min-width: ${THEME.breakPoints.desktop}px) {
      font-size: 40px;
      line-height: 41px;
    }

    &:hover {
      background: ${THEME.primaryColors.primaryLight};
    }
  }

  svg {
    margin-left: 8px;
    margin-top: 2px;
  }
`;

const IconButton = styled(IconButtonBase)`
  &.MuiButtonBase-root {
    transition: all 0.3s ease-out;
    border-radius: 8px !important;
    background-color: ${({ bgcolor }) => bgcolor || '#F5F6F7'};
    padding: ${({ p }) => p || '8px'};
    font-size: 20px;
    width: 40px;
    height: 40px;
    margin: ${({ margin }) => margin || '0'};

    &:hover {
      background-color: ${({ bgcolor }) => (bgcolor ? '#f5f6f7' : '#E6E9EC')};
    }

    color: ${({ $color }) => $color || '#787F88'};
  }
`;

export { StyledListItem, StyledList, StyledListItemWrapper, StyledPopover, IconButton };
