import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import Test from '../Test';
import { initialComparisonOperator } from '../constants';
import { createNewTests, createLogicOperator } from './utils';
import { useNodeDataToSave } from '../hooks/useNodeDataToSave';
import { AddNewTestBox, TestsBox } from './styled';
import SubTitle from '../../../SubTitle';
import { createTest } from '../Test/utils';
import { ButtonLink } from '../../../../../../atoms';
import THEME from '../../../../../../../constants/theme';

const Conditions = () => {
  const { t } = useTranslation();
  const [{ tests: initialTests, logicalOperator }, updateDataToSave] = useNodeDataToSave();
  const tests = useMemo(() => initialTests?.map((test) => ({ ...test, id: test?.id ?? v4() })), [initialTests]);

  const setTests = (newTest) => {
    const prevTests = tests ?? [];
    const newTests = [...prevTests, newTest];
    updateDataToSave({
      tests: createNewTests(newTests),
      logicalOperator: createLogicOperator(logicalOperator),
    });
  };

  const updateTest = ({ id, name, value }) => {
    const updatedTests = tests.map((test) => (test.id === id ? createTest(test, name, value) : test));
    updateDataToSave({ tests: updatedTests });
  };

  const removeTest = (id) => {
    const updatedConditions = tests.filter((test) => test.id !== id);
    updateDataToSave({ tests: updatedConditions });
  };

  return (
    <>
      <SubTitle>
        {t('Create one or more conditions to match and then build logic based on true or false conditions')}
      </SubTitle>
      <TestsBox $tests={!!tests?.length}>
        {tests?.map((test, i) => (
          <Test
            key={test?.id}
            test={test}
            tests={tests}
            logicalOperator={logicalOperator}
            updateTest={updateTest}
            removeTest={removeTest}
            index={i}
          />
        ))}
      </TestsBox>
      <AddNewTestBox $tests={!!tests?.length}>
        <ButtonLink
          onClick={() => setTests({ left: '', op: initialComparisonOperator, right: null })}
          color={THEME.primaryColors.primary}
        >
          {t('Add Rule')}
        </ButtonLink>
      </AddNewTestBox>
    </>
  );
};

export default Conditions;
