import React from 'react';
import { Box } from '@mui/material';
import { P14 } from '../../../../atoms';
import EmptyRightModal from '../../../../../assets/icons/EmptyRightModal';
import THEME from '../../../../../constants/theme';

const EmptyState = () => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <EmptyRightModal />
    <P14 textAlign="center" width="240px" color={THEME.greyColors.grey600}>
      Connect your condition node to other nodes before configuring branch ordering
    </P14>
  </Box>
);

export default EmptyState;
