import React from 'react';
import { useTranslation } from 'react-i18next';
import { string, bool, func } from 'prop-types';
import Box from '@mui/material/Box';
import omit from 'lodash/omit';
import { useFormikContext } from 'formik';
import { StyledAttachmentWrapper, StyledDownloadLink } from './styled';
import Trash2 from '../../../../../../../assets/icons/Trash2';
import { L14, L12, CircularLoader } from '../../../../../../../components/atoms';
import THEME from '../../../../../../../constants/theme';
import Download from '../../../../../../../assets/icons/Download';

const AttachmentItem = ({ fieldName, fileName, disabled, setUploadedFile, loading, accessUrl }) => {
  const { t } = useTranslation();
  const { setValues, values } = useFormikContext();

  const handleDelete = () => {
    if (!disabled) {
      setValues(omit(values, [fieldName]));
      setUploadedFile({});
    }
  };

  return (
    <StyledAttachmentWrapper
      margin="0 20px 20px 0"
      justifyContent="flex-start"
      alignItems="center"
      width="150px"
      zIndex="50"
      disabled={disabled}
    >
      <L14 noHover fontWeight="400">
        {fileName}
      </L14>

      <Box display="flex" alignItems="center">
        {loading && (
          <>
            <L12 margin="0 12px 0 0">{t('Uploading ..')}</L12>
            <CircularLoader bgcolor={THEME.primaryColors.primary} size={16} thickness={5} />
          </>
        )}

        {!loading && (
          <>
            <StyledDownloadLink href={accessUrl}>
              <Download fill={THEME.greyColors.grey600} />
            </StyledDownloadLink>

            <Box type="button" onClick={handleDelete}>
              <Trash2 />
            </Box>
          </>
        )}
      </Box>
    </StyledAttachmentWrapper>
  );
};

AttachmentItem.propTypes = {
  fieldName: string.isRequired,
  fileName: string.isRequired,
  disabled: bool,
  setUploadedFile: func.isRequired,
  loading: bool.isRequired,
  accessUrl: string.isRequired,
};

AttachmentItem.defaultProps = {
  disabled: false,
};

export default AttachmentItem;
