import React from 'react';

const Bolt = () => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.9323 0.721796C8.20378 0.839641 8.36506 1.12235 8.32836 1.41602L7.75536 6H13.0002C13.2588 6 13.4942 6.14964 13.6039 6.38389C13.7136 6.61815 13.6779 6.89473 13.5123 7.09345L6.84565 15.0935C6.65619 15.3208 6.33952 15.396 6.06804 15.2782C5.79657 15.1603 5.63528 14.8776 5.67199 14.584L6.24498 10H1.00017C0.741492 10 0.506164 9.85036 0.396445 9.6161C0.286725 9.38184 0.322421 9.10526 0.488023 8.90654L7.15469 0.90654C7.34415 0.679184 7.66082 0.60395 7.9323 0.721796ZM2.42353 8.66666H7.00017C7.19139 8.66666 7.37341 8.74877 7.49996 8.89213C7.62651 9.03548 7.68541 9.22628 7.66169 9.41602L7.27706 12.493L11.5768 7.33333H7.00017C6.80895 7.33333 6.62694 7.25122 6.50038 7.10786C6.37384 6.96451 6.31493 6.77372 6.33865 6.58397L6.72328 3.50697L2.42353 8.66666Z"
        fill="#0CC48C"
      />
    </svg>
  );
};

export default Bolt;
