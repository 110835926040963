import { createTheme } from '@mui/material';

const ThemeUtils = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export default ThemeUtils;
