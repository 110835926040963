import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Palette from './Palette';
import { StyledPaletteContainer } from './styled';
import EditBrandColorDialog from './EditBrandColorDialog';

const BrandColor = ({ color, handleColorPick }) => {
  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);

  const [initialColor, setInitialColor] = useState(color);

  const handleCancel = () => {
    setInitialColor(color);
    setShowModal(false);
  };
  const handleSave = () => {
    handleColorPick(initialColor);
    setShowModal(false);
  };

  return (
    <>
      <StyledPaletteContainer>
        <Palette color={color} openModal={openModal} />
      </StyledPaletteContainer>

      <EditBrandColorDialog
        isOpen={showModal}
        initialColor={initialColor}
        setInitialColor={setInitialColor}
        handleCancel={handleCancel}
        handleSave={handleSave}
      />
    </>
  );
};

BrandColor.propTypes = {
  color: PropTypes.string.isRequired,
  handleColorPick: PropTypes.func.isRequired,
};

export default BrandColor;
