const formNames = {
  paymentProcessors: 'paymentProcessors',
  paymentProcessorsOther: 'paymentProcessorsOther',
};

const formFields = [{ field: formNames.paymentProcessors, label: 'Payment Processors(s)' }];

const initialValues = {
  [formNames.paymentProcessors]: [],
  [formNames.paymentProcessorsOther]: '',
};

export { initialValues, formFields, formNames };
