import styled from 'styled-components';
import THEME from '../../../constants/theme';

export const QRCover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background: ${THEME.secondaryColors.lightRed};
  border-radius: 6px;
  min-height: 180px;
  margin-bottom: 16px;

  canvas {
    width: 100px !important;
    height: 100px !important;
  }
`;
