import styled from 'styled-components';

const StyledWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    padding: 4px 12px;
    background-color: #f4f4f4;
    color: #6a6a6a;
    border-radius: 4px;
    display: flex;
    align-items: center;
    -webkit-appearance: none;

    svg {
      margin-left: 4px;
      margin-top: 2px;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

export { StyledWrapper };
