import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { LIST_DISPUTES_STATS_GQL } from '../../utils/queries/disputes/listDisputes';
import { useNotificationManager } from '../useNotificationManager';

const useGetDisputeStats = () => {
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(LIST_DISPUTES_STATS_GQL, {
    fetchPolicy: 'cache-and-network',
  });
  useNotificationManager('error', error?.message, t('Disputes'), 5000);

  const disputeStats = useMemo(() => data?.listDisputeStats || {}, [data?.listDisputeStats]);

  return {
    loading,
    error,
    disputeStats,
  };
};

export { useGetDisputeStats };
