import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import isArray from 'lodash/isArray';
import { GET_POSSIBLE_CONDITION_VALUES_FOR_STEP } from '../../../../../../../utils/queries/flows/queries';
import { useFlowEditorContext } from '../../../../../context';
import { useNodeDataToSave } from '../hooks/useNodeDataToSave';
import AutocompleteMultiple from '../../../../../../AutocompleteMultiple';
import LoadingState from '../../../LoadingState';

const InListSelectInput = ({ name, value, property, onChange, propertyType }) => {
  const { flowId } = useFlowEditorContext();
  const [{ id: stepId }] = useNodeDataToSave();

  const { data, loading } = useQuery(GET_POSSIBLE_CONDITION_VALUES_FOR_STEP, {
    variables: {
      flowId,
      stepId,
      property,
      propertyType,
    },
    fetchPolicy: 'no-cache',
  });

  const options = data?.getPossibleConditionValuesForStep || [];

  const labels = options.map(({ label }) => label);

  const handleChange = (e, val) => {
    onChange({ target: { name, value: val } });
  };

  return (
    <LoadingState height="40px" mt={0} loading={loading}>
      <AutocompleteMultiple options={labels} onChange={handleChange} value={isArray(value) ? value : []} />
    </LoadingState>
  );
};

InListSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  property: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  propertyType: PropTypes.string.isRequired,
};

export default InListSelectInput;
