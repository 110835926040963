import React, { useCallback, useState } from 'react';
import PropTypes, { arrayOf, oneOf, shape, string } from 'prop-types';
import FormControl from '@mui/material/FormControl';
import isArray from 'lodash/isArray';
import FormHelperText from '@mui/material/FormHelperText';
import PropertyTextEditor from '../../../../../../PropertyTextEditor';
import { useFlowPropertiesList } from '../hooks';
import LoadingState from '../../../LoadingState';
import { propertyObjectTypes } from '../../../../../../PropertyTextEditor/PropertyTextEditorModal/types';
import { useStyles } from '../styled';

const TextEditorInput = ({ name, value, onChange, properties }) => {
  const [showError, setShowError] = useState(false);
  const { loading, getAvailableProperties } = useFlowPropertiesList(null, null, properties);
  const classes = useStyles();

  const propertiesToDisplay = properties || getAvailableProperties;

  const handleSavePlainText = useCallback(
    (inputValue) => {
      onChange({ target: { name, value: inputValue } });
    },
    [name, onChange]
  );

  const isCorrectValue = isArray(value) ? value?.[0]?.value : true;

  return (
    <FormControl variant="standard" fullWidth error={showError}>
      <LoadingState loading={loading} height="40px" mt={0}>
        <PropertyTextEditor
          initialEditorText={isCorrectValue ? value : null}
          handleSavePlainText={handleSavePlainText}
          properties={propertiesToDisplay}
          border="1px solid #C1C3C6"
          maxHeight="40px"
          minHeight="40px"
          showToolbar={false}
          singleProperty
          showError={showError}
          setShowError={setShowError}
        />
        {showError && <FormHelperText classes={{ root: classes.root }}>Please select a valid input</FormHelperText>}
      </LoadingState>
    </FormControl>
  );
};

TextEditorInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  properties: shape({
    groups: arrayOf(
      shape({
        icon: string,
        label: string.isRequired,
        type: oneOf(Object.values(propertyObjectTypes)).isRequired,
      })
    ),
    items: arrayOf(
      shape({
        key: string.isRequired,
        label: string.isRequired,
        path: string,
        type: oneOf(Object.values(propertyObjectTypes)).isRequired,
      })
    ),
  }),
};

TextEditorInput.defaultProps = {
  properties: null,
  value: null,
};

export default TextEditorInput;
