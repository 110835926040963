import { useMemo } from 'react';
import { useMedia } from 'react-use';
import { GRID_SIZE_MATRIX, getGridSize } from './constant';
import { useRightAsideContext } from '../../../../../providers/RightAsideProvider';

export const useGridProps = (key = 'default') => {
  const isSmall = useMedia('(max-width: 1400px)');
  const isMedium = useMedia('(max-width: 1600px)');
  const isLarge = useMedia('(min-width: 1800px)');
  const { globalFilterState } = useRightAsideContext();

  const currentGridSize = useMemo(() => getGridSize({ isSmall, isMedium, isLarge }), [isSmall, isMedium, isLarge]);

  const filterStateProps = GRID_SIZE_MATRIX.default.small;

  /**
   * if drawer is open, adjust the grid sizes to filterStateProps
   * if drawer is closed, adjust the grid sizes to GRID_SIZE_MATRIX[index]?.[key] || GRID_SIZE_MATRIX.default[currentGridSize];
   */
  const gridItemProps = globalFilterState
    ? filterStateProps
    : GRID_SIZE_MATRIX[key]?.[currentGridSize] || GRID_SIZE_MATRIX.default[currentGridSize];

  return {
    gridItemProps,
    isMedium,
    isLarge,
    isFilterState: globalFilterState,
  };
};
