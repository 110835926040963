import { nodeBaseStyles } from '../styled';
import { flowStepTypes } from '../types';
import { NODE_TYPE } from '../components/Node/types';

export const getFlowNodes = (nodes) =>
  nodes?.length
    ? nodes.map((node) => ({
        ...node,
        ...nodeBaseStyles,
        type: node?.data?.__typename === flowStepTypes.AnnotationStep ? NODE_TYPE.annotation : NODE_TYPE.basic,
      }))
    : [];
