import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { ArrowBackRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFlowEditorContext } from '../../FlowEditor/context';
import THEME from '../../../constants/theme';
import NodeIcon from '../../FlowEditor/components/NodeLibrary/NodeIcon';
import { L14M, P16B } from '../../atoms';
import { P12M } from '../../atoms/Typography/P12M';
import { StyledDetailsTitle } from '../../DetailDrawer/styled';
import { prepareFlowInstance } from './helpers';

const BackButton = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 12px;
  color: ${THEME.primaryColors.black};

  &:hover {
    background: ${THEME.greyColors.grey12};
  }
`;

const NodeLikeHeader = ({ isLogsScreen, isInstanceStepsScreen, selectedStep, setSelectedStep }) => {
  const { t } = useTranslation();
  const {
    selectedInstanceId,
    setSelectedInstanceId,
    selectedInstance,
    selectedInstanceRetries,
  } = useFlowEditorContext();

  const preparedSelectedInstance = prepareFlowInstance(selectedInstance);
  const retriesOnly = selectedInstanceRetries?.filter((retry) => retry.isRetry);
  const retriesCount = retriesOnly?.length;
  const retryNumber = retriesOnly.findIndex((retry) => retry.id === selectedInstanceId) + 1;

  const onGoBack = () => {
    if (selectedStep) {
      setSelectedStep(null);
      return;
    }
    if (selectedInstanceId) {
      setSelectedInstanceId(null);
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      p="16px 24px"
      borderBottom={`1px solid ${THEME.greyColors.grey200}`}
      width="100%"
    >
      <BackButton onClick={onGoBack}>
        <ArrowBackRounded color="inherit" fontSize="inherit" />
      </BackButton>
      {isLogsScreen && <NodeIcon nodeData={selectedStep?.step} type={selectedStep?.step?.group?.toLowerCase()} />}
      {isInstanceStepsScreen && (
        <StyledDetailsTitle>
          <P16B>{preparedSelectedInstance?.date}</P16B>
          {retriesCount >= 0 && (
            <P12M color={THEME.secondaryColors.nodeError}>
              {retryNumber === 0 ? t('Original Run') : t(`Retry ${retryNumber} of ${retriesCount}`)}
            </P12M>
          )}
        </StyledDetailsTitle>
      )}
      {isLogsScreen && <L14M color={THEME.greyColors.grey11}>{selectedStep.step?.name}</L14M>}
    </Box>
  );
};
NodeLikeHeader.propTypes = {
  isLogsScreen: PropTypes.bool.isRequired,
  isInstanceStepsScreen: PropTypes.bool.isRequired,
  setSelectedStep: PropTypes.func.isRequired,
  selectedStep: PropTypes.shape({
    step: PropTypes.shape({
      name: PropTypes.string,
      group: PropTypes.string,
    }),
  }),
};

NodeLikeHeader.defaultProps = {
  selectedStep: null,
};

export default NodeLikeHeader;
