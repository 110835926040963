import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/lab/Skeleton';

const skeletonArray = Array.from(Array(6).keys());

const ListLoadingState = () => {
  return (
    <Box padding="4px">
      {skeletonArray.map((key) => (
        <Box key={key} borderRadius="6px" overflow="hidden" height="46px" mb="2px">
          <Skeleton height="100px" width="500px" animation="wave" />
        </Box>
      ))}
    </Box>
  );
};

export default ListLoadingState;
