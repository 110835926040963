import { CONNECTION_STATUS } from '../components/constant';

const GET_CONNECTION_LIST_VARIABLES = {
  variables: {
    status: [
      CONNECTION_STATUS.CONNECTED,
      CONNECTION_STATUS.NOT_CONNECTED,
      CONNECTION_STATUS.BROKEN,
      CONNECTION_STATUS.ARCHIVED,
      CONNECTION_STATUS.ONBOARDING,
    ],
  },
};

const REFETCH_CONNECTIONS_LIST_FLAG = 'wt_refetch_connections';

const CONNECTION_TOAST_TITLE = 'Connections.';

export { GET_CONNECTION_LIST_VARIABLES, REFETCH_CONNECTIONS_LIST_FLAG, CONNECTION_TOAST_TITLE };
