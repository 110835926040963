import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Content from '../../components/layouts/MainLayout/Content';
import { SITE_MAP, UI_ROUTES } from '../../constants/routes';
import { useFeatureChecker } from '../../hooks/useFeature';
import { CUSTOMER_SUPPORT_PERMISSIONS_IDS } from './permissions';
import usePermission from '../../permissions/hooks/usePermission';
import usePermissionChecker from '../../permissions/hooks/usePermissionChecker';
import { isDefined } from '../../utils/helpers';
import SignupMangopay from './SignupMangopay';
import ViewCustomerDetails from './ViewCustomerDetails';
import RoutingTable from './RoutingTable';

const CustomerSupport = () => {
  const [hasCustomerSupportPermission] = usePermission(CUSTOMER_SUPPORT_PERMISSIONS_IDS.customerSupport);

  const { checkPermission } = usePermissionChecker();
  const { checkFeature } = useFeatureChecker();

  const { path } = useRouteMatch();
  const menu = SITE_MAP.find((r) => r.path === path);

  const routeComponents = {
    ...(hasCustomerSupportPermission ? { '/signup-mangopay': SignupMangopay } : {}),
    ...(hasCustomerSupportPermission ? { '/view-customer-details': ViewCustomerDetails } : {}),
    ...(hasCustomerSupportPermission ? { '/routing-table': RoutingTable } : {}),
  };

  const firstNavItem = menu.subRoutes.find((item) => {
    if (isDefined(item.permission)) {
      const hasPermission = checkPermission(item.permission);

      if (!hasPermission) return false;
    }

    if (isDefined(item.features)) {
      const isFeatureDisabled = Object.keys(item.features).some(
        (featureId) => checkFeature(featureId) !== item.features[featureId].expectedValue
      );

      if (isFeatureDisabled) return false;
    }
    return true;
  });

  if (!firstNavItem) return <Redirect to={UI_ROUTES.root} />;

  return (
    <Content>
      <Switch>
        {menu.subRoutes.map(({ path: subRoutePath }) => (
          <Route
            key={`${path}${subRoutePath}`}
            path={`${path}${subRoutePath}`}
            component={routeComponents[subRoutePath]}
          />
        ))}

        <Redirect to={`${path}${firstNavItem.path}`} />
      </Switch>
    </Content>
  );
};

export default CustomerSupport;
