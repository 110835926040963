import styled from 'styled-components';
import THEME from '../../constants/theme';
import SubmitButton from '../../components/forms/_common/SubmitButton';

export const StyledBackToHomepageButton = styled(SubmitButton)`
  background-color: ${THEME.greyColors.greyButton};
  padding: 13px 19px;
  color: ${THEME.greyColors.grey800} !important;
  transition: all 0.3s ease-out;

  &::before {
    background-color: ${THEME.greyColors.grey16};
  }

  &:hover,
  &:active {
    opacity: 1;
    background-color: ${THEME.greyColors.grey16};
  }

  &:hover::before,
  &:active::before {
    opacity: 1;
  }
`;

export const StyledWelcomeToBetaPageContainer = styled.div`
  width: 100%;
  max-width: 336px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  h1 {
    font-family: 'Inter', sans-serif !important;
    line-height: 1.2;
    color: ${THEME.primaryColors.blackPlain};
  }

  p {
    font-family: 'Inter', sans-serif !important;
  }
`;

export const StyledButtonsWrap = styled.div`
  @media (min-width: ${THEME.breakPoints.tablet}px) {
    display: flex;
  }

  @media (max-width: ${THEME.breakPoints.tablet - 1}px) {
    button,
    a {
      width: 100%;
      margin-bottom: 16px;
      justify-content: space-around;
      align-items: center;
      -webkit-appearance: none;
      height: 48px;
    }
  }

  button {
    margin-right: 16px;
  }

  button,
  a {
    display: inline-flex;
    white-space: nowrap;
  }
`;
