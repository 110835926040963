import { makeStyles } from '@mui/styles';
import styled, { css } from 'styled-components';
import Box from '@mui/material/Box';
import THEME from '../../constants/theme';

export const useMentionContainerStyles = makeStyles({
  root: {
    minWidth: '376px',
    border: '1px solid #f0f0f0',
    backgroundColor: '#ffffff',
    zIndex: 100,
    boxSizing: 'border-box',
    boxShadow: ' 0px 2px 4px rgba(155, 159, 171, 0.11)',
    borderRadius: '6px',
    position: 'absolute',
    left: '100% !important',
    transform: 'translate(-100%, 0)',
    overflow: 'hidden',

    '& .ql-mention-list': {
      margin: '0',
      padding: '0 0 8px 0',
      maxHeight: '300px',
      overflowX: 'auto',
    },
    '& .ql-mention-list-item': {
      listStyle: 'none',
      cursor: 'pointer',
      fontSize: '14px',
      lineHeight: '20px',
      padding: '6px 16px',
      verticalAlign: 'middle',
      boxSizing: 'border-box',
      transition: 'all 0.2s ease-out',
    },
    '& .ql-mention-list-item.disabled': {
      fontWeight: 'bold',
      backgroundColor: '#F5F6F7',
      color: '#787F88',
      cursor: 'not-allowed',
      borderTop: '1px solid #E6E9EC',
      borderBottom: '1px solid #E6E9EC',
    },
    '& .ql-mention-list-item.disabled:hover': { color: '#787F88' },
    '& .ql-mention-list-item:hover': { color: '#2346f2', backgroundColor: '#F5F2FF' },
  },
});

export const StyledContainer = styled.div`
  min-height: ${({ minHeight }) => minHeight};
  height: ${({ readOnly }) => (readOnly ? '100px' : 'min-content')};
  .ql-tooltip {
    z-index: 500;
    display: none;
  }
  .mention {
    border-radius: 4px;
    cursor: pointer;
    padding: 7px 0;
    margin-right: 2px;
    color: ${THEME.primaryColors.primaryD20};
    background-color: ${THEME.primaryColors.primaryLight};
    & > span {
      padding: 0 8px;
    }
  }
  .ql-editor {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    cursor: auto;
    padding: ${({ padding }) => padding || '5px 12px !important'};
    overflow: ${({ readOnly }) => (readOnly ? 'hidden' : 'auto')};
    ${({ minHeight }) =>
      minHeight &&
      css`
        height: ${minHeight};
      `}
    ${({ maxHeight }) =>
      maxHeight &&
      css`
        max-height: ${maxHeight};
        height: 100%;
      `};
    &.ql-blank:before {
      color: ${THEME.greyColors.grey400};
      line-height: ${({ maxHeight }) => (maxHeight ? `calc(${maxHeight} - 10px)` : `38px`)};
      left: 12px;
      font-size: 14px;
      font-style: normal;
    }
  }
  .ql-editor p {
    width: 100%;
    font-size: ${({ isFooter }) => (isFooter ? '12px' : '14px')};
    line-height: ${({ maxHeight }) => (maxHeight ? `calc(${maxHeight} - 10px)` : `38px`)};
    ${({ isFooter }) => (isFooter ? 'text-align: center; color: #787F88' : '')};
  }

  .ql-emojiblot {
    margin: -5px 2px 0 2px;
  }
`;

export const StyledBox = styled(Box)`
  cursor: pointer;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: none ${THEME.greyColors.grey200};
  border-left-width: ${({ noBorder }) => (noBorder ? 0 : '1px')};
  border-left-style: solid;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
