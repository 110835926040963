import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { string } from 'prop-types';
import { Form, Formik } from 'formik';
import { capitalize } from '@mui/material';
import Box from '@mui/material/Box';
import omit from 'lodash/omit';

import { BlockWrap } from '../../atoms';
import SubmitButton from '../_common/SubmitButton';
import GoogleAuth from '../../GoogleAuth';
import { GOOGLE_AUTH_TYPE } from '../../../hooks/useGoogleOAuth';
import { invitationFields, initialValues, invitationFieldsNames } from '../../../utils/schemas/invitationSchema';
import invitationValidator from '../../../utils/validators/invitationValidator';
import AcceptLabel from '../SignUpPersonalDetailsForm/AcceptLabel';
import Checkbox from '../_common/Checkbox';
import { useCompleteInvitation } from '../../../hooks/registration/useCompleteInvitation';
import InputOnboarding from '../_common/InputOnboarding';
import PasswordOnboarding from '../_common/PasswordOnboarding';
import { LineOr } from '../../../pages/SignUp/styled';

const InvitationForm = ({ email, token }) => {
  const { t } = useTranslation();
  const { completeInvitation, loading, authenticationState } = useCompleteInvitation();

  const parsedInitialValues = useMemo(() => initialValues({ email }), [email]);

  //Handles Google Authentication
  useEffect(() => {
    if (authenticationState?.state !== GOOGLE_AUTH_TYPE.completeSignUpWithInvitation) {
      return;
    }

    completeInvitation({
      email: authenticationState.jwt.email,
      googleToken: authenticationState.token,
      token,
    });
  }, [authenticationState, token, completeInvitation, email]);

  const handleSubmit = async (values, actions) => {
    const removedAccepted = omit(values, 'accepted');
    try {
      await completeInvitation({ ...removedAccepted, token });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={parsedInitialValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validate={invitationValidator}
      validateOnBlur={true}
      validateOnMount={false}
    >
      <Form>
        <h1>{capitalize(t(`Join your team`))}</h1>

        <BlockWrap height="43px" margin="32px 0">
          <GoogleAuth type={GOOGLE_AUTH_TYPE.completeSignUpWithInvitation} loading={loading}>
            {t('Continue with Google')}
          </GoogleAuth>
        </BlockWrap>

        <LineOr>{t('or')}</LineOr>

        {invitationFields.map(({ field, type, label }) => {
          const Component = type === 'password' ? PasswordOnboarding : InputOnboarding;

          return <Component key={field} name={field} type={type} label={label} customLabel />;
        })}

        <Box mt="10px" mb="20px">
          <Checkbox name={invitationFieldsNames.accepted} label={<AcceptLabel />} />
        </Box>

        <SubmitButton width="100%" showIcon={false}>
          {t('common.continueText')}
        </SubmitButton>
      </Form>
    </Formik>
  );
};
InvitationForm.propTypes = {
  email: string,
  token: string,
};
InvitationForm.defaultProps = {
  email: '',
  token: '',
};
export default InvitationForm;
