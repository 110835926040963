import styled from 'styled-components';
import THEME from '../../constants/theme';

const StyledCopyContainer = styled.div`
  display: flex;
  justify-content: space-between;

  * {
    transition: all 0.2s ease-out;
  }

  &:hover {
    cursor: pointer;

    p,
    span {
      color: ${THEME.primaryColors.primary};
    }

    svg path {
      fill: ${THEME.primaryColors.primary};
    }
  }
`;

export { StyledCopyContainer };
