import { CONNECTION_STATUS } from '../../../components/constant';

const LOGO_SIZE = '80px';
const SUB_TEXT_TRANSFORM = 'translateY(4px)';

const BUTTON_TYPES_MAP = {
  [CONNECTION_STATUS.CONNECTED]: 'secondary',
  [CONNECTION_STATUS.NOT_CONNECTED]: 'primary',
  [CONNECTION_STATUS.ARCHIVED]: 'primary',
  [CONNECTION_STATUS.BROKEN]: 'primary',
  [CONNECTION_STATUS.ONBOARDING]: 'primary',
};

const CONNECTION_STATUS_BADGE_MAP = {
  [CONNECTION_STATUS.CONNECTED]: 'success',
  [CONNECTION_STATUS.ARCHIVED]: 'error',
  [CONNECTION_STATUS.BROKEN]: 'error',
  [CONNECTION_STATUS.ONBOARDING]: 'secondary',
};

const STATUS_TO_SHOW_STATUS_BADGE = [
  CONNECTION_STATUS.CONNECTED,
  CONNECTION_STATUS.DISCONNECTED,
  CONNECTION_STATUS.ARCHIVED,
  CONNECTION_STATUS.ONBOARDING,
  CONNECTION_STATUS.BROKEN,
];

export { LOGO_SIZE, SUB_TEXT_TRANSFORM, BUTTON_TYPES_MAP, CONNECTION_STATUS_BADGE_MAP, STATUS_TO_SHOW_STATUS_BADGE };
