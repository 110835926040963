import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { arrayOf, bool, func, string, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { P16 } from '../../../../../../components/atoms';
import { StyledWrapper, SubmitEvidenceBox, SubmissionsContainer } from './styled';
import LoadingState from './LoadingState';
import FormDrawer from '../FormDrawer';
import Submission from './Submission';

const Submissions = ({
  loading,
  listDisputeEvidenceSubmissions,
  shouldDrawerOpen,
  setShouldDrawerOpen,
  disputeId,
  connectionId,
  sourceDisputeId,
  responseDate,
  disputeStatus,
  setModalData,
  setShouldModalOpen,
  setDisabled,
  connectionServiceId,
}) => {
  const { t } = useTranslation();
  const [submissionData, setSubmissionData] = useState({});

  const handleOpenDrawer = (submissionId) => {
    setShouldDrawerOpen(true);

    const submission = listDisputeEvidenceSubmissions.find((item) => item.id === submissionId);
    setSubmissionData((prev) => ({ ...prev, ...submission }));
  };

  return (
    <>
      <StyledWrapper>
        <P16 fontWeight="600" lineHeight="21.6px" margin="0 0 12px 0">
          {t('Submissions')}
        </P16>

        {loading && <LoadingState />}

        {!loading && isEmpty(listDisputeEvidenceSubmissions) && (
          <SubmitEvidenceBox variant="text" color="primary" onClick={handleOpenDrawer}>
            {t('Submit Evidence')}
          </SubmitEvidenceBox>
        )}

        {!loading && !isEmpty(listDisputeEvidenceSubmissions) && (
          <SubmissionsContainer>
            {listDisputeEvidenceSubmissions.map((submission) => (
              <Submission
                key={submission.id}
                submission={submission}
                handleOpenDrawer={handleOpenDrawer}
                responseDate={responseDate}
              />
            ))}
          </SubmissionsContainer>
        )}
      </StyledWrapper>

      {/* FORM DRAWER */}
      {shouldDrawerOpen && (
        <FormDrawer
          submission={submissionData}
          shouldDrawerOpen={shouldDrawerOpen}
          setShouldDrawerOpen={setShouldDrawerOpen}
          connectionId={connectionId}
          disputeId={disputeId}
          sourceDisputeId={sourceDisputeId}
          responseDate={responseDate}
          disputeStatus={disputeStatus}
          setModalData={setModalData}
          setShouldModalOpen={setShouldModalOpen}
          setDisabled={setDisabled}
          connectionServiceId={connectionServiceId}
        />
      )}
    </>
  );
};

Submissions.propTypes = {
  loading: bool.isRequired,
  listDisputeEvidenceSubmissions: arrayOf(shape),
  shouldDrawerOpen: bool.isRequired,
  setShouldDrawerOpen: func.isRequired,
  disputeId: string.isRequired,
  connectionId: string.isRequired,
  sourceDisputeId: string.isRequired,
  responseDate: string.isRequired,
  disputeStatus: string.isRequired,
  setModalData: func.isRequired,
  setShouldModalOpen: func.isRequired,
  setDisabled: func.isRequired,
  connectionServiceId: string.isRequired,
};

Submissions.defaultProps = {
  listDisputeEvidenceSubmissions: [],
};

export default Submissions;
