import PropTypes from 'prop-types';
import React from 'react';
import Grid from '@mui/material/Grid';
import { StatItemWrapper, StatItemLabel, StatItemHeader, StatItemValue, StatItemStat, StatItemPercent } from './styled';
import { Checkbox } from '../../../../../components/atoms/Checkbox/Checkbox';
import ArrowUp from '../../../../../assets/arrows/ArrowUp';
import THEME from '../../../../../constants/theme';
import { useGridProps } from './use-grid-props';
import { getPercentage } from './constant';
import { isDefined } from '../../../../../utils/helpers';
import useSearch from '../../../../../hooks/useSearch';

const parseLabel = (label) => label.toLowerCase().replace('_', ' ');

const StaItem = ({ stat, handleStatSelect }) => {
  const isAmountStats = isDefined(stat.amount);
  const [searchParams] = useSearch();
  const { isLarge, gridItemProps } = useGridProps(isAmountStats ? 'amountStat' : 'default');
  const label = isAmountStats ? `Total ${parseLabel(stat?.label)}` : parseLabel(stat.label);
  const value = isAmountStats ? stat.amount?.formattedAmount : stat.count;

  const { percentage, negativePercentage } = getPercentage(stat.percentage);

  const isChecked = Boolean(searchParams?.filter?.paymentStatus?.includes(stat?.label?.toLowerCase()));

  const statsColor = negativePercentage ? THEME.secondaryColors.redStats : THEME.secondaryColors.greenStats;
  const statsIconColor = negativePercentage ? THEME.secondaryColors.redStats : THEME.secondaryColors.greenStats;

  const applyLargeStyles = isLarge || isAmountStats;

  return (
    <Grid item {...gridItemProps}>
      <StatItemWrapper $isChecked={isChecked} $applyLargeStyles={applyLargeStyles}>
        <StatItemHeader>
          <StatItemLabel>
            {label} <span>(Month-to-Date)</span>
          </StatItemLabel>
          <Checkbox
            onChange={handleStatSelect}
            checked={isChecked}
            name={label}
            data-label={stat?.label?.toLowerCase()}
          />
        </StatItemHeader>
        <StatItemValue>{value}</StatItemValue>
        <StatItemStat>
          <ArrowUp fill={statsIconColor} />
          <StatItemPercent $color={statsColor}>{percentage}</StatItemPercent> vs last month
        </StatItemStat>
      </StatItemWrapper>
    </Grid>
  );
};

StaItem.propTypes = {
  stat: PropTypes.shape({
    label: PropTypes.string.isRequired,
    amount: PropTypes.shape({
      formattedAmount: PropTypes.string.isRequired,
    }),
    count: PropTypes.string,
    percentage: PropTypes.string,
  }).isRequired,
  handleStatSelect: PropTypes.func.isRequired,
};

export default StaItem;
