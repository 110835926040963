import React from 'react';
import PropTypes from 'prop-types';
import { Box, RadioGroup, FormControl } from '@mui/material';
import InputGroup from '../InputGroupRadio/InputGroup';
import { BUTTON_TYPES_MAP } from '../InputGroupRadio/constant';

const ButtonStyleSection = ({ handleChange, color, defaultValue }) => {
  const accentColor = color || '#777777';
  const currentValue = defaultValue || BUTTON_TYPES_MAP.SOLID;
  return (
    <FormControl variant="standard" component="fieldset">
      <RadioGroup aria-label="button-style" name="button-style" defaultValue={currentValue} onChange={handleChange}>
        <Box mb="32px" display="flex">
          <InputGroup value="SOLID" color={accentColor} currentValue={currentValue} />
          <InputGroup value="SOLID_ROUND" color={accentColor} currentValue={currentValue} />
        </Box>
        <Box display="flex">
          <InputGroup value="THIN" color={accentColor} currentValue={currentValue} />
          <InputGroup value="THIN_ROUND" color={accentColor} currentValue={currentValue} />
        </Box>
      </RadioGroup>
    </FormControl>
  );
};

ButtonStyleSection.propTypes = {
  handleChange: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
};

export default ButtonStyleSection;
