import React from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { bool, elementType, func, string } from 'prop-types';
import noop from 'lodash/noop';
import THEME from '../../../../../constants/theme';
import ArrowUpMessage from '../../../../../assets/icons/ArrowUpMessage';
import { stopPropagation } from './helpers';

const ButtonWrapper = styled(Box)`
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: ${({ disabled, bgcolor }) =>
    bgcolor || disabled ? THEME.primaryColors.primaryLight : THEME.primaryColors.primary};
  align-self: flex-end;
`;

const SubmitMessageButton = ({ onClick, disabled, Icon, iconColor, bgcolor, margin }) => (
  <ButtonWrapper
    onMouseDown={stopPropagation}
    onClick={disabled ? noop : onClick}
    disabled={disabled}
    bgcolor={bgcolor}
    m={margin}
  >
    {Icon ? (
      <Icon color={iconColor} fill={iconColor} size={20} />
    ) : (
      <ArrowUpMessage color={iconColor || disabled ? THEME.primaryColors.l50 : THEME.primaryColors.white} />
    )}
  </ButtonWrapper>
);

SubmitMessageButton.propTypes = {
  onClick: func,
  disabled: bool.isRequired,
  Icon: elementType,
  iconColor: string,
  bgcolor: string,
  margin: string,
};

SubmitMessageButton.defaultProps = {
  onClick: () => null,
  Icon: null,
  iconColor: '',
  bgcolor: null,
  margin: '',
};

export default SubmitMessageButton;
