import { useFlowEditorContext } from '../context';

export const useMouseEventHandler = () => {
  const { setHoverNodeId, isAutomationTest, isFlowMonitor } = useFlowEditorContext();

  const mouseEventHandler = (value) => {
    if (isAutomationTest || isFlowMonitor) {
      return;
    }
    setHoverNodeId(value);
  };

  return {
    mouseEventHandler,
  };
};
