import React from 'react';
import { Box } from '@mui/material';
import { shape, bool, func } from 'prop-types';
import { useFormikContext } from 'formik';
import { P, L12, InputField } from '../../../../../atoms';
import THEME from '../../../../../../constants/theme';
import { useGlobalContext } from '../../../../../../containers/App/context';
import useActionHandlers from '../hooks/useActionHandlers';
import { StyledLabel } from '../../../../_common/styled';

const Text = ({ item, animatedLabel, showErrors, setShowErrors }) => {
  const { getMeData } = useGlobalContext();
  const { values, errors, handleBlur } = useFormikContext();
  const { onChangeHandler, handleFormat } = useActionHandlers(setShowErrors);

  const currency = getMeData?.we?.currency?.symbol || '';

  return (
    <Box margin="0 0 12px 0" key={item.block_id}>
      <StyledLabel>
        {!animatedLabel && (
          <Box display="flex" justifyContent="flex-start" alignItems="center" position="relative">
            <P fontSize="12px !important" fontWeight={600} textAlign="left" lineHeight="30px">
              {item.label?.text}
            </P>
          </Box>
        )}

        <InputField
          variant="outlined"
          type="text"
          id={item.block_id}
          name={item.block_id}
          label={animatedLabel ? item.label.text : ''}
          padding={animatedLabel ? undefined : '10px 16px'}
          onChange={item.block_id === 'amount' ? handleFormat : onChangeHandler}
          onBlur={handleBlur}
          value={
            item.block_id === 'amount'
              ? (values[item.block_id] && currency + values[item.block_id]) || ''
              : values[item.block_id] || ''
          }
        />
      </StyledLabel>

      {showErrors && errors[item.block_id] && (
        <L12 textAlign="left" margin="4px 0 0 0" color={THEME.secondaryColors.danger}>
          {errors[item.block_id]}
        </L12>
      )}
    </Box>
  );
};

Text.propTypes = {
  item: shape({}).isRequired,
  animatedLabel: bool,
  showErrors: bool,
  setShowErrors: func.isRequired,
};

Text.defaultProps = {
  animatedLabel: false,
  showErrors: false,
};

export default Text;
