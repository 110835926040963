const FIELD_MAP = {
  name: 'name',
  phone: 'phone',
  city: 'city',
  state: 'state',
  country: 'country',
  line1: 'line1',
  line2: 'line2',
  expiry: 'expiry',
  postalCode: 'postalCode',
  number: 'number',
  bankName: 'bankName',
  externalId: 'externalId',
  vatId: 'vatId',
  taxId: 'taxId',
};

const LABEL_MAP = {
  [FIELD_MAP.postalCode]: 'postal code',
  [FIELD_MAP.line1]: 'address line 1',
  [FIELD_MAP.line2]: 'address line 2',
  [FIELD_MAP.bankName]: 'bank name',
  [FIELD_MAP.externalId]: 'external id',
  [FIELD_MAP.expiry]: 'expiry date',
  [FIELD_MAP.name]: 'name',
  [FIELD_MAP.phone]: 'phone',
  [FIELD_MAP.city]: 'city',
  [FIELD_MAP.state]: 'state',
  [FIELD_MAP.country]: 'country',
  [FIELD_MAP.number]: 'number',
  [FIELD_MAP.vatId]: 'Vat ID',
  [FIELD_MAP.taxId]: 'Tax ID',
};
const BILLING_DETAILS = [
  LABEL_MAP[FIELD_MAP.line1],
  LABEL_MAP[FIELD_MAP.line2],
  LABEL_MAP[FIELD_MAP.city],
  LABEL_MAP[FIELD_MAP.postalCode],
  LABEL_MAP[FIELD_MAP.state],
  LABEL_MAP[FIELD_MAP.country],
];

const SHIPPING_DETAILS = [
  LABEL_MAP[FIELD_MAP.name],
  LABEL_MAP[FIELD_MAP.phone],
  LABEL_MAP[FIELD_MAP.line1],
  LABEL_MAP[FIELD_MAP.line2],
  LABEL_MAP[FIELD_MAP.city],
  LABEL_MAP[FIELD_MAP.postalCode],
  LABEL_MAP[FIELD_MAP.state],
  LABEL_MAP[FIELD_MAP.country],
];

const COMPANY_DETAILS = [
  LABEL_MAP[FIELD_MAP.name],
  LABEL_MAP[FIELD_MAP.number],
  LABEL_MAP[FIELD_MAP.vatId],
  LABEL_MAP[FIELD_MAP.taxId],
];

const flattenNestedObject = (data) =>
  Object.keys(data ?? {}).reduce((acc, curr) => {
    const value = data[curr];
    if (typeof value === 'object') {
      const _value = Object.keys(value ?? {}).reduce((_acc, _curr) => {
        const nestedValue = value[_curr];

        return { ..._acc, [LABEL_MAP[_curr] ?? _curr]: nestedValue };
      }, {});
      return { ...acc, ..._value };
    }
    return { ...acc, [LABEL_MAP[curr] ?? curr]: value };
  }, {});

/*
  Payments details page
*/
const PAYMENTS_DETAILS_LABEL_MAP = {
  sourceDisputeId: 'Processor Dispute Id',
};

export {
  BILLING_DETAILS,
  SHIPPING_DETAILS,
  LABEL_MAP,
  flattenNestedObject,
  COMPANY_DETAILS,
  FIELD_MAP,
  PAYMENTS_DETAILS_LABEL_MAP,
};
