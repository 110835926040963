import styled from 'styled-components';
import THEME from '../../../../../../constants/theme';

const StyledSearchBar = styled.div`
  position: relative;
  width: 100%;
  flex: 1;

  svg {
    position: absolute;
    top: 50%;
    left: 20px;
    width: 20px;
    height: 20px;
    display: block;
    transform: translateY(-50%);
    transition: 0.2s;
    pointer-events: none;
  }
`;

const StyledInput = styled.input`
  position: relative;
  width: 100%;
  padding: 17.5px 12px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-style: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  transition: 0.2s;
  color: ${THEME.greyColors.grey18};
  padding-left: 56px;
  border: 1px solid ${THEME.greyColors.grey16};
  height: 56px;

  &::placeholder {
    color: ${THEME.greyColors.grey18};
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    opacity: 0.5;
  }
`;

export { StyledSearchBar, StyledInput };
