import Box from '@mui/material/Box';
import { string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useUpdateNodeInternals, Position } from 'react-flow-renderer';
import { useTranslation } from 'react-i18next';
import { ErrorRounded } from '@mui/icons-material';
import isEmpty from 'lodash/isEmpty';
import THEME from '../../../../constants/theme';
import { P11B } from '../../../atoms';
import { useFlowEditorContext } from '../../context';
import { flowStepPropType } from '../../types';
import NodeIcon from '../NodeLibrary/NodeIcon';
import { CONDITION_LABELS_MAP, NODE_DETAILS } from './constant';
import { useCurrentTestStep } from './hooks/useCurrentTestStep';
import { useNodeProperties } from './hooks/useNodeProperties';
import MoreBlock from './MoreBlock';
import NodeBase from './NodeBase';
import { NodeContextProvider } from './context';
import {
  BottomSourceHandle,
  BottomTargetDot,
  BottomTargetHandle,
  LeftSourceHandle,
  LeftTargetDot,
  LeftTargetHandle,
  NodeNameBox,
  RightSourceHandle,
  RightTargetDot,
  RightTargetHandle,
  StatusIconBox,
  StyledGrabbableBox,
  TopSourceHandle,
  TopTargetDot,
  TopTargetHandle,
} from './styled';
import { TestBadge } from './TestBadge';
import { generateUserPilotAttribute } from '../../../../constants/generateUserPilotLabel';
import { USER_PILOT_SECTION_ID } from '../constant';
import Messages from './MessagesModal/Messages';
import MessagesModal from './MessagesModal/MessagesModal';
import SuccessCheckmarkIcon from '../../../../assets/icons/FlowMonitor/SuccessCheckmarkIcon';
import LoaderIcon from '../../../../assets/icons/FlowMonitor/LoaderIcon';
import { LoaderIconBox } from '../../../FlowMonitor/styled';
import { useNodeComments } from '../../hooks/useComments';
import { getIsNodeConnected } from '../../utils/getIsNodeConnected';
import { CONNECTION_STATUS } from '../../../../pages/ConnectionsPage/components/constant';
import ScrollingRow from './ScrollingRow';

const Node = (props) => {
  const { t } = useTranslation();
  const { flowId } = useFlowEditorContext();
  const { id, data } = props;
  const {
    name,
    __typename,
    group,
    connectionId,
    locked,
    nickname,
    layout,
    nextStepId,
    elseStepId,
    trueStepId,
    falseStepId,
    aBranchCondition,
    bBranchCondition,
    conditions,
    subLabel,
    connectionStatus,
    validationErrors,
    changed,
  } = data;
  const {
    isAutomationTestInProgress,
    connectingNodeData,
    isUpdatingEdgeTarget,
    isFlowEditor,
    isFlowMonitor,
    messagesModalId,
    setMessagesModalId,
    retryingStepId,
    versionId,
    edges,
  } = useFlowEditorContext();
  const [isErrorState, setIsErrorState] = useState(false);
  const [label, setLabel] = useState('Start Configuration');
  const [labelColor, setLabelColor] = useState(THEME.primaryColors.primary);
  const { isHighlighted, border, isHover, isLogsError, isLogsSuccess, isSavedNode } = useNodeProperties({
    ...data,
    isErrorState,
  });
  const updateNodeInternals = useUpdateNodeInternals();
  useCurrentTestStep({ stepId: id });
  const {
    allComments,
    currentThread,
    commentsLoading,
    commentsSaving,
    commentsResolving,
    createComment,
    editComment,
    deleteComment,
    resolveThread,
  } = useNodeComments({ flowId, stepId: id, isFlowMonitor });
  const [currentNodeComments, setCurrentNodeComments] = useState([]);
  const [message, setMessage] = useState('');
  const [newMessages, setNewMessages] = useState(false);

  const isShowSuccessIcon = isSavedNode || (isFlowMonitor && isLogsSuccess);
  const isShowErrorIcon = (isFlowMonitor && isLogsError) || (isFlowEditor && !isEmpty(validationErrors));
  const isShowMessages = (isHover || newMessages) && !versionId && !isFlowMonitor;
  const isShowMoreBlock = isHover && !locked && !versionId;

  const nodeTitle = useMemo(() => {
    const _name = name ?? __typename;
    const _nickname = nickname ? `(${nickname})` : '';
    return `${_name} ${_nickname}`;
  }, [__typename, name, nickname]);

  const handleSubmitComment = async () => {
    const withNewLines = message;
    setMessage('');
    await createComment(withNewLines);
  };

  const context = {
    allComments,
    currentNodeComments,
    setCurrentNodeComments,
    createComment,
    commentsLoading,
    commentsSaving,
    commentsResolving,
    message,
    setMessage,
    editComment,
    deleteComment,
    resolveThread,
    stepI: id,
    handleSubmitComment,
    isErrorState,
    setIsErrorState,
  };

  useEffect(() => updateNodeInternals(id), [updateNodeInternals, id]);

  useEffect(() => {
    if (subLabel) {
      const isNodeConnected = getIsNodeConnected(id, edges);
      const isConnectionNotConnected = connectionStatus === CONNECTION_STATUS.NOT_CONNECTED;
      if (group === 'Actions') {
        setIsErrorState(!isNodeConnected || isConnectionNotConnected);
      }
      if (group === 'Triggers') {
        setIsErrorState(isConnectionNotConnected);
      }
    }
  }, [connectionStatus, data, edges, group, id, subLabel]);

  const fixedActionStepRow = __typename === 'FixedActionStep' && connectionId && t('Any  Connection');
  const nodeSelectedDetails = NODE_DETAILS[group] ?? NODE_DETAILS.default;
  const connecting = connectingNodeData || isUpdatingEdgeTarget;
  const isShowSourceHandle = isHover && !connecting;
  const conditionsUiMetadata = conditions?.map((condition) => layout?.uiMetadata?.[condition.nextStepId]);

  useEffect(() => {
    if (name in CONDITION_LABELS_MAP) {
      setLabelColor(THEME.primaryColors.blackPlain);
      setLabel(subLabel ? t(subLabel) : t(CONDITION_LABELS_MAP[name]));
      return;
    }
    if (nodeSelectedDetails && subLabel) {
      setLabel(nodeSelectedDetails?.transformFn?.(subLabel) || t(subLabel));
      setLabelColor(THEME.primaryColors.blackPlain);
      return;
    }
    if (!subLabel) {
      setLabel(t('Start Configuration'));
      setLabelColor(THEME.primaryColors.primary);
    }
  }, [name, nodeSelectedDetails, subLabel, t]);

  const isShowSource = (position) =>
    isShowSourceHandle ||
    layout?.uiMetadata?.[nextStepId]?.sourceHandle === position ||
    layout?.uiMetadata?.[elseStepId]?.sourceHandle === position ||
    layout?.uiMetadata?.[trueStepId]?.sourceHandle === position ||
    layout?.uiMetadata?.[falseStepId]?.sourceHandle === position ||
    layout?.uiMetadata?.[aBranchCondition?.nextStepId]?.sourceHandle === position ||
    layout?.uiMetadata?.[bBranchCondition?.nextStepId]?.sourceHandle === position ||
    conditionsUiMetadata?.find((metadata) => metadata?.sourceHandle === position);

  return (
    <NodeContextProvider value={context}>
      <NodeBase id={id}>
        <StyledGrabbableBox
          display="flex"
          justifyContent="flex-start"
          flexDirection="column"
          width="300px"
          height="80px"
          padding="20px"
          borderRadius="8px"
          bgcolor={isHover && !newMessages ? THEME.greyColors.grey24 : THEME.primaryColors.white}
          boxShadow={
            isHighlighted
              ? '0 2px 2px rgba(0, 0, 0, 0.04), 0 10px 14px rgba(0, 0, 0, 0.04)'
              : '0px 0px 16px rgba(0, 0, 0, 0.04)'
          }
          border={border}
          {...generateUserPilotAttribute(USER_PILOT_SECTION_ID, 'node', id)}
        >
          <>
            {isShowMoreBlock && <MoreBlock id={id} />}
            {isShowMessages && (
              <Messages
                handleClick={(e) => {
                  e.stopPropagation();
                  setMessagesModalId(messagesModalId === id ? null : id);
                }}
                newMessages={newMessages}
                commentsQty={currentThread?.comments?.length ?? 0}
              />
            )}
            {isShowMessages && (
              <MessagesModal
                toggleMessagesOpened={() => setMessagesModalId(null)}
                setNewMessages={setNewMessages}
                newMessages={newMessages}
                stepId={id}
                showModal={messagesModalId === id}
              />
            )}
            <Box
              flex={1}
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start"
              className="ellipse-container"
              flexDirection="column"
            >
              <Box display="flex" alignItems="center" width="100%">
                <NodeIcon nodeData={data} type={group?.toLowerCase()} size="40" />
                <NodeNameBox color="#787F88" fontSize="12px" lineHeight="16px" overflow="hidden">
                  <ScrollingRow
                    isHover={isHover}
                    textColor={THEME.greyColors.grey600}
                    textFontSize="11px"
                    textTransform="uppercase"
                    letterSpacing="0.08em"
                    noWrap
                  >
                    {nodeTitle}
                  </ScrollingRow>
                  <Box display="flex" flexDirection="column" mt="4px" width="100%" maxHeight="22px">
                    <ScrollingRow isHover={isHover} textColor={labelColor}>
                      {label}
                    </ScrollingRow>
                  </Box>
                </NodeNameBox>
              </Box>
            </Box>
            {isAutomationTestInProgress && <TestBadge stepId={id} />}
            {fixedActionStepRow && (
              <Box display="flex" ml="28px">
                <P11B lineHeight="24px">{fixedActionStepRow}</P11B>
              </Box>
            )}
            {isShowErrorIcon && !isShowSuccessIcon && changed && (
              <StatusIconBox>
                <ErrorRounded color="inherit" />
              </StatusIconBox>
            )}
            {isShowSuccessIcon && (
              <StatusIconBox>
                <SuccessCheckmarkIcon />
              </StatusIconBox>
            )}
            {isFlowMonitor && retryingStepId && retryingStepId === id ? (
              <StatusIconBox>
                <LoaderIconBox>
                  <LoaderIcon />
                </LoaderIconBox>
              </StatusIconBox>
            ) : null}
          </>
          <RightSourceHandle
            id={Position.Right}
            type="source"
            position={Position.Right}
            $isShow={isShowSource(Position.Right)}
          />
          <LeftSourceHandle
            id={Position.Left}
            type="source"
            position={Position.Left}
            $isShow={isShowSource(Position.Left)}
          />
          <BottomSourceHandle
            id={Position.Bottom}
            type="source"
            position={Position.Bottom}
            $isShow={isShowSource(Position.Bottom)}
          />
          <TopSourceHandle
            id={Position.Top}
            type="source"
            position={Position.Top}
            $isShow={isShowSource(Position.Top)}
          />
          <LeftTargetHandle id={Position.Left} type="target" position={Position.Left} $connecting={connecting}>
            <LeftTargetDot />
          </LeftTargetHandle>
          <RightTargetHandle id={Position.Right} type="target" position={Position.Right} $connecting={connecting}>
            <RightTargetDot />
          </RightTargetHandle>
          <TopTargetHandle id={Position.Top} type="target" position={Position.Top} $connecting={connecting}>
            <TopTargetDot />
          </TopTargetHandle>
          <BottomTargetHandle id={Position.Bottom} type="target" position={Position.Bottom} $connecting={connecting}>
            <BottomTargetDot />
          </BottomTargetHandle>
        </StyledGrabbableBox>
      </NodeBase>
    </NodeContextProvider>
  );
};

Node.propTypes = {
  id: string.isRequired,
  data: flowStepPropType.isRequired,
};

export { Node };
