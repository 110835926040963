import React from 'react';
import DirectoryList from './DirectoryList';

const Directory = () => {
  return (
    <div>
      <DirectoryList />
    </div>
  );
};

export default Directory;
