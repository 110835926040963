import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, useField, useFormikContext } from 'formik';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CustomRadiosWrapper, CustomRadiosStyled, StyledOptionsWrapper } from './styled';
import InputOnboarding from '../InputOnboarding';

const Label = styled.label`
  display: block;
  text-align: left;
  width: 100%;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
`;

const CustomRadios = ({ name, label, options, fullWidth }) => {
  const [{ value }, { touched, error }] = useField(name);
  const { setFieldValue } = useFormikContext();
  const hasError = Boolean(touched && error);
  const showOtherInput = value === 'OTHER';
  const { t } = useTranslation();
  const [focusedOption, setFocusedOption] = useState('');

  const handleFocus = (newValue) => {
    setFocusedOption(newValue);
    setFieldValue(name, newValue);
  };

  const handleBlur = () => {
    setFocusedOption('');
  };

  return (
    <CustomRadiosWrapper>
      <FormControl variant="standard" error={hasError} fullWidth={fullWidth}>
        {label && <Label>{label}</Label>}
        <StyledOptionsWrapper>
          <Field name={name}>
            {({ field }) => {
              return options.map((option, id) => (
                <CustomRadiosStyled
                  checked={String(value) === String(option.value)}
                  focused={String(focusedOption) === String(option.value)}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${name}-${id}`}
                  onMouseLeave={() => handleBlur()}
                >
                  <label htmlFor={`${name}-${id}`}>
                    <div />
                    {option.text.text}
                    <input
                      {...field}
                      id={`${name}-${id}`}
                      value={option.value}
                      onFocus={() => handleFocus(option.value)}
                      onBlur={() => handleBlur()}
                      type="radio"
                    />
                  </label>
                </CustomRadiosStyled>
              ));
            }}
          </Field>
        </StyledOptionsWrapper>

        {showOtherInput && (
          <Box mt="32px">
            <InputOnboarding type="text" name={`${name}Other`} label={t('Please specify')} />
          </Box>
        )}

        {hasError && <FormHelperText id={name}>{error}</FormHelperText>}
      </FormControl>
    </CustomRadiosWrapper>
  );
};

CustomRadios.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.PropTypes.any).isRequired,
  errorProps: PropTypes.shape({}),
};

CustomRadios.defaultProps = {
  type: 'text',
  label: null,
  placeholder: 'Enter value',
  fullWidth: false,
  errorProps: {},
};

export default CustomRadios;
