import styled from 'styled-components';
import Box from '@mui/material/Box';
import { StyledFormControl as StyledFormControlBase } from '../styled';
import THEME from '../../../../../../../constants/theme';

const WrapperBox = styled(Box)`
  position: relative;
  margin: 0 8px;
  padding: 8px 0;
  background-color: ${({ $isRulesStep }) => ($isRulesStep ? THEME.primaryColors.white : THEME.greyColors.grey14)};
  & .MuiButtonBase-root {
    visibility: hidden;
  }
  &:hover {
    & .MuiButtonBase-root {
      visibility: visible;
    }
  }
`;

const TestBox = styled(Box)`
  position: relative;
  padding: 28px 20px 0 20px;
`;

const SelectBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InputBox = styled(Box)`
  padding: 0 20px 28px 20px;
  display: flex;
  gap: 8px;
`;

const StyledFormControl = styled(StyledFormControlBase)`
  &.MuiFormControl-root {
    padding: 5px 16px;
    background-color: ${THEME.primaryColors.white};
    border: 1px solid ${THEME.greyColors.grey8};
  }
`;

const StyledOperatorControl = styled(StyledFormControlBase)`
  &.MuiFormControl-root {
    display: flex;
    align-items: center;
    width: auto;
    z-index: 3;
    height: 42px;
    background-color: transparent;
    & .MuiInputBase-root {
      margin: 0;
      color: ${THEME.primaryColors.blue};
      & .MuiSelect-root {
        &.MuiSelect-select {
          &.MuiSelect-select {
            padding-right: 32px;
          }
        }
      }
    }
  }
  & .MuiSelect-icon {
    color: currentColor;
    right: 5px;
  }
`;

const DeleteBox = styled(Box)`
  position: absolute;
  color: #fff;
  top: -10px;
  right: -10px;
  & .MuiButtonBase-root {
    width: 24px;
    height: 24px;
    background-color: rgba(183, 66, 66, 1);
    cursor: pointer;
    &:hover {
      background-color: rgba(183, 66, 66, 0.8);
    }
  }
  & .MuiSvgIcon-root {
    font-size: 16px;
  }
`;

const LogicalOpBox = styled(Box)`
  position: absolute;
  background-color: transparent;
  bottom: -8px;
  right: 50%;
  transform: translateX(50%);
  z-index: 10;
`;

const TitleBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  background-color: ${THEME.greyColors.grey14};
  margin: 8px 8px 1px 8px;
  padding: 12px 20px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  & svg {
    cursor: pointer;
  }
`;

const IconBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  WrapperBox,
  TestBox,
  SelectBox,
  StyledFormControl,
  StyledOperatorControl,
  InputBox,
  DeleteBox,
  LogicalOpBox,
  TitleBox,
  IconBox,
};
