import React from 'react';

const Copy = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.99967 5.33398H12.6663V4.00065H5.99967V5.33398ZM13.333 6.00065V12.6673H14.6663V6.00065H13.333ZM12.6663 13.334H5.99967V14.6673H12.6663V13.334ZM5.33301 12.6673V6.00065H3.99967V12.6673H5.33301ZM5.99967 13.334C5.63149 13.334 5.33301 13.0355 5.33301 12.6673H3.99967C3.99967 13.7719 4.89511 14.6673 5.99967 14.6673V13.334ZM13.333 12.6673C13.333 13.0355 13.0345 13.334 12.6663 13.334V14.6673C13.7709 14.6673 14.6663 13.7719 14.6663 12.6673H13.333ZM12.6663 5.33398C13.0345 5.33398 13.333 5.63246 13.333 6.00065H14.6663C14.6663 4.89608 13.7709 4.00065 12.6663 4.00065V5.33398ZM5.99967 4.00065C4.89511 4.00065 3.99967 4.89608 3.99967 6.00065H5.33301C5.33301 5.63246 5.63149 5.33398 5.99967 5.33398V4.00065ZM3.33301 2.66732H9.99967V1.33398H3.33301V2.66732ZM2.66634 10.0007V3.33398H1.33301V10.0007H2.66634ZM3.99967 10.6673H3.33301V12.0007H3.99967V10.6673ZM10.6663 3.33398V4.00065H11.9997V3.33398H10.6663ZM1.33301 10.0007C1.33301 11.1053 2.22844 12.0007 3.33301 12.0007V10.6673C2.96482 10.6673 2.66634 10.3689 2.66634 10.0007H1.33301ZM9.99967 2.66732C10.3679 2.66732 10.6663 2.9658 10.6663 3.33398H11.9997C11.9997 2.22942 11.1043 1.33398 9.99967 1.33398V2.66732ZM3.33301 1.33398C2.22844 1.33398 1.33301 2.22942 1.33301 3.33398H2.66634C2.66634 2.9658 2.96482 2.66732 3.33301 2.66732V1.33398Z"
        fill="#787F88"
      />
    </svg>
  );
};

export default Copy;
