import React from 'react';
import { Box } from '@mui/material';
import { arrayOf, shape, bool, string, number } from 'prop-types';
import { useFormikContext } from 'formik';
import capitalize from '@mui/material/utils/capitalize';
import Input from '../../../../../../../components/forms/_common/Input';
import Attachment from '../Attachment';
import { DISPUT_FORM_INPUT_TYPE } from '../../constant';

const LABEL_PROPS = {
  fontWeight: '500 !important',
  fontSize: '12px',
  lineHeight: '12px',
};

const DisputeEvidenceForm = ({
  form,
  isFormSubmitted,
  isSaving,
  filesConfig,
  attachments,
  shouldShowError,
  fileTypes,
}) => {
  const { values, isSubmitting, errors } = useFormikContext();

  const disabled = isFormSubmitted || isSubmitting || isSaving;

  return (
    <>
      {form.map(({ id, name, type, required, tooltip }) => {
        const requiredLabel = required ? '(required)' : '(optional)';
        const fileTypeCategory = fileTypes?.find((fileType) => fileType.fileTypeCode === id)?.fileTypeCategory;

        if (type === DISPUT_FORM_INPUT_TYPE.string) {
          return (
            <Box key={id} mb="24px">
              <Input
                key={id}
                name={id}
                label={capitalize(name)}
                type="text"
                customLabel
                customLabelProps={LABEL_PROPS}
                disabled={disabled}
                requiredLabel={requiredLabel}
                tooltip={tooltip}
              />
            </Box>
          );
        }

        return (
          <Attachment
            key={id}
            name={id}
            label={capitalize(name)}
            inputId={fileTypeCategory ?? id}
            list={values[id] ? [values[id]] : []}
            disabled={disabled}
            filesConfig={filesConfig}
            attachments={attachments}
            tooltip={tooltip}
            requiredLabel={requiredLabel}
            shouldShowError={shouldShowError}
            errors={errors}
          />
        );
      })}
    </>
  );
};

DisputeEvidenceForm.propTypes = {
  form: arrayOf(shape({})).isRequired,
  isFormSubmitted: bool.isRequired,
  isSaving: bool,
  filesConfig: arrayOf(shape({ contentType: string.isRequired, maxFileSizeMB: number.isRequired })),
  attachments: shape({}),
  shouldShowError: bool,
  fileTypes: arrayOf(shape({})).isRequired,
};
DisputeEvidenceForm.defaultProps = {
  attachments: {},
  isSaving: false,
  filesConfig: [],
  shouldShowError: false,
};

export default DisputeEvidenceForm;
