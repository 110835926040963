import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import ReactGA from 'react-ga';

import OnboardingLayout from '../../components/layouts/OnboardingLayout';
import OnboardingLayoutBox from '../../components/layouts/OnboardingLayoutBox';
import SignUpProductsServicesForm from '../../components/forms/SignUpProductsServicesForm';
import { useSignUpBeta } from './useSignUpBeta';
// import StepIndicatorOnboarding from '../../components/common/StepIndicatorOnboarding/StepIndicatorOnboarding';
import { useRegistrationStorage } from '../../hooks/registration';
import { useGlobalContext } from '../../containers/App/context';

const Fallback = () => (
  <Box display="flex" justifyContent="center">
    <CircularProgress size={24} />
  </Box>
);

const SignUpProductsServices = () => {
  const { t } = useTranslation();
  const [registrationSession] = useRegistrationStorage();
  const acceptingPayments = registrationSession?.COMPANY_DETAILS?.acceptingPayments === 'YES';
  // const paymentProcessor = registrationSession?.COMPANY_DETAILS?.paymentProcessor === 'YES';
  const { signUpBeta, loading } = useSignUpBeta();
  const { isReactGAInitialized } = useGlobalContext();

  const handleSubmit = async (values, actions) => {
    if (isReactGAInitialized) {
      // For Google Analytics
      ReactGA.event({
        category: 'Button',
        action: "Click 'Continue' to complete last step of the registration",
      });
    }

    try {
      await signUpBeta(values);
    } finally {
      actions.setSubmitting(false);
    }
  };
  // useHandleRegistrationFlowRedirect();

  return (
    <OnboardingLayout theme="dark">
      <OnboardingLayoutBox theme="dark">
        <Box mb="40px">
          {/* <StepIndicatorOnboarding
            currentStep={acceptingPayments || paymentProcessor ? 9 : 8}
            stepsCount={acceptingPayments || paymentProcessor ? 9 : 8}
          /> */}
          <h1>
            {t(
              acceptingPayments
                ? 'What types of products or services  are you selling?'
                : 'What types of products or services do you plan on selling?'
            )}
          </h1>
        </Box>
        <React.Suspense fallback={<Fallback />}>
          <SignUpProductsServicesForm loading={loading} handleSubmit={handleSubmit} />
        </React.Suspense>
      </OnboardingLayoutBox>
    </OnboardingLayout>
  );
};

export default SignUpProductsServices;
