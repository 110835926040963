import { UI_ROUTES } from '../../constants/routes';
import { SETTINGS_PERMISSIONS_IDS } from '../Settings/permissions';
import Code from '../../assets/icons/QuickStart/Code';
import Globe from '../../assets/icons/QuickStart/Globe';
import File from '../../assets/icons/QuickStart/File';
import Diagram from '../../assets/icons/QuickStart/Diagram';
import Node from '../../assets/icons/QuickStart/Node';
import People from '../../assets/icons/QuickStart/People';
import Slack from '../../assets/icons/QuickStart/Slack';
// import Users from '../../assets/icons/QuickStart/Users';
// import Plus from '../../assets/icons/QuickStart/Plus';
// import Link from '../../assets/icons/QuickStart/Link';

const DEVELOPER_LINK = process.env.REACT_APP_DEV_ZONE_URL;

const SECTIONS = [
  {
    title: 'Get started',
    desc: 'Follow the steps below to get the most out of WhenThen.',
    steps: [
      // {
      //   dataKey: 'basicAutomation',
      //   Icon: Flows,
      //   url: UI_ROUTES.automationsDirectory,
      //   title: 'Create a Payment Automation',
      //   desc:
      //     'WhenThen automations help you increase revenue, decrease PaymentOps costs and delight your customers. and colleagues',
      // },
      // {
      //   dataKey: 'premiumAutomation',
      //   Icon: Flows,
      //   url: UI_ROUTES.automationsDirectory,
      //   title: 'Create a Premium Automation',
      //   desc: 'Build your own automation or enable a template.',
      // },
      // {
      //   dataKey: 'usedRecipe',
      //   Icon: Plus,
      //   url: UI_ROUTES.automationsDirectory,
      //   title: 'Use a recipe',
      //   desc: 'Create a new automation using recipe or add a recipe to an existing flow',
      // },
      {
        dataKey: 'tourTaken',
        Icon: Globe,
        url: '#',
        title: 'Take a Tour',
        desc: 'Take a simple tour through all the features of WhenThen.',
      },
      {
        dataKey: 'invitedDeveloper',
        Icon: Code,
        url: `${UI_ROUTES.userManagement}?invite=true&role=DEVELOPER`,
        title: 'Invite a developer or technical product manager',
        desc:
          'Some automations can be tricky to build, so we recommend you invite a developer or technical product manager to WhenThen. If you don’t have one, no worries – we offer free automation builder support. ',
        permission: SETTINGS_PERMISSIONS_IDS.userManagement,
      },
      {
        dataKey: 'readDocs',
        Icon: File,
        url: DEVELOPER_LINK,
        title: 'Review our dev docs',
        desc:
          'Some automations require utilising and processing sensitive data such as credit card or personal informaion, so integrating WhenThen’s Checkout SDK and Data Tokenisation APIs are required.',
        isExternalUrl: true,
      },
      {
        dataKey: 'exploreRecipes',
        Icon: Diagram,
        url: UI_ROUTES.automationsDirectory,
        title: 'Explore automation recipes',
        desc:
          'Select a recipe from our automation recipe directory to quickly get started quickly. If you’re unsure what you reciepe you need select ‘recommendation below’.',
      },
      {
        dataKey: 'buildAutomation',
        Icon: Node,
        url: `${UI_ROUTES.automationsDirectory}?showBlankAutomationModal=true`,
        title: 'Build your own automation from scratch',
        desc:
          'You don’t have to use an automation recipe to build or lanch an automation. You can build your own from scratch too.',
      },
      // {
      //   dataKey: 'paymentConnections',
      //   Icon: Link,
      //   url: UI_ROUTES.connections,
      //   title: 'Connect your payment processor',
      //   desc: 'We’ve already integrated with your payment processor, simply authenticate with credentials',
      //   permission: SETTINGS_PERMISSIONS_IDS.acceptingPayments,
      // },
      // {
      //   dataKey: 'paymentConnections',
      //   Icon: Link,
      //   url: UI_ROUTES.connections,
      //   title: 'Explore our connections',
      //   desc: 'Explore our connections to connect payment processors or business applications',
      //   permission: SETTINGS_PERMISSIONS_IDS.notAcceptingPayments,
      // },
      {
        dataKey: 'invitedColleagues',
        Icon: People,
        url: `${UI_ROUTES.userManagement}?invite=true`,
        title: 'Invite more colleagues',
        desc:
          'WhenThen is designed to be collaborative, invite your work colleagues into WhenThen so they too can build automations that drive revenue and reduce costs.',
        permission: SETTINGS_PERMISSIONS_IDS.userManagement,
      },
      {
        dataKey: 'joinSlack',
        Icon: Slack,
        url: UI_ROUTES.slack,
        title: 'Join our Slack',
        desc:
          'Engage with the WhenThen Automators in near real-time and stay up to date on all happenings and new product releases in WhenThen.',
        permission: SETTINGS_PERMISSIONS_IDS.userManagement,
        isExternalUrl: true,
      },
    ],
  },
  // {
  //   title: 'Invite team',
  //   desc: "Don't have to do it alone, WhenThen works great with colleagues.",
  //   permission: SETTINGS_PERMISSIONS_IDS.userManagement,
  //   steps: [
  //     {
  //       dataKey: 'inviteColleagues',
  //       Icon: Users,
  //       url: UI_ROUTES.userManagement,
  //       title: 'Invite colleagues',
  //       desc: 'Invite colleagues under different user permission levels',
  //     },
  //     {
  //       dataKey: 'takeTour',
  //       Icon: Tour,
  //       url: UI_ROUTES.userManagement,
  //       title: 'Take a Tour',
  //       desc: 'Take a simple tour through all the features of WhenThen',
  //     },
  //   ],
  // },
];

export { SECTIONS };
