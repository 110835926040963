import React from 'react';
import { bool } from 'prop-types';
import ABTestSlider from './fields/ABTestSlider';
import ABTestStats from './fields/ABTestStats';

const ABTestConditionStep = ({ isABTestConfigured }) => (isABTestConfigured ? <ABTestStats /> : <ABTestSlider />);

ABTestConditionStep.propTypes = {
  isABTestConfigured: bool,
};

ABTestConditionStep.defaultProps = {
  isABTestConfigured: false,
};

export default ABTestConditionStep;
