import styled from 'styled-components';
import { customTextColor } from '../../../constants/CommonStyles';

export const H5 = styled.h5`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1px;
  ${customTextColor}
`;
