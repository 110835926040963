import styled from 'styled-components';

export const StyledWrapper = styled.div`
  table {
    tbody {
      tr:first-child {
        td {
          border-top: none !important;
        }
      }
    }
  }
`;
