const FILTERS_DICTIONARY = {
  useCases: 'Use Cases',
  roles: 'Roles',
  apps: 'Apps',
};

const APP_CATEGORIES = [
  {
    subLabel: 'PAYMENT_PROCESSOR',
    data: [
      'ADYEN',
      'AUTHORISE_NET',
      'BAMBORA',
      'BRAINTREE',
      'BUCKAROO',
      'CHECKOUT_COM',
      'GLOBAL_PAYMENTS',
      'GOCARDLESS',
      'MOLLIE',
      'MONERIS',
      'PAY_360',
      'PAY_NL',
      'PAYPAL',
      'SQUARE',
      'STRIPE',
      'WORLDINE',
      'WORLDPAY',
      '2CHECKOUT',
    ],
  },
  { subLabel: 'CRM', data: ['ACTIVE_CAMPAIGN', 'HUBSPOT', 'INTERCOM', 'PIPEDRIVE', 'SALESFORCE', 'ZENDESK'] },
  { subLabel: 'COMMUNICATION', data: ['ACTIVE_CAMPAIGN', 'MAILCHIMP', 'MANDRILL', 'SENDGRID', 'SLACK', 'TWILIO'] },
  { subLabel: 'CUSTOMER_SUPPORT', data: ['INTERCOM', 'ZENDESK'] },
  {
    subLabel: 'ALTERNATIVE_PAYMENT_METHODS',
    data: [
      'AMAZON_PAY',
      'APPLE_PAY',
      'BANCONTACT',
      'EPS',
      'GIROPAY',
      'GOOGLE_PAY',
      'GOCARDLESS',
      'IDEAL',
      'KLARNA',
      'P24',
      'PAYPAL',
      'SEPA',
      'SOFORT',
      'TRILO',
      'TWINT',
    ],
  },
  { subLabel: 'FRAUD_PROVIDER', data: ['FINTECTURE', 'RISKIFILED', 'SIFT', 'SIGNIFIED'] },
  { subLabel: 'OPEN_BANKING', data: ['BANKED', 'BRIDGE', 'FINTECTURE', 'NEONOMICS', 'PLAID', 'TRILO', 'VOLT', 'VYNE'] },
  { subLabel: 'TAX', data: ['AVALARA', 'TAXJAR'] },
  { subLabel: 'DISCOUNTS', data: ['VOUCHERIFY'] },
];

const TAG_LABELS_MAP = {
  //   For labels that needs customisation
  'Authorise Net': 'Authorise.net',
  'Checkout Com': 'Checkout.com',
  Gocardless: 'GoCardless',
  Activecampaign: 'Active Campaign',
  Sendgrid: 'SendGrid',
  Eps: 'EPS',
  Sepa: 'SEPA',
  Sofort: 'SOFORT',
  Paypal: 'PayPal',
  'Pay Nl': 'Pay.nl',
};

const toLowercaseArr = (arr) => {
  return arr.map((item) => item.toLowerCase());
};

const generateFilterObject = (recipes) => {
  const filterObject = recipes.reduce((obj, recipe) => {
    const useCases = new Set([...(obj?.useCases ? obj?.useCases : []), ...recipe?.useCases]);
    const roles = new Set([...(obj?.roles ? obj?.roles : []), ...recipe?.roles]);

    // eslint-disable-next-line no-param-reassign
    obj.useCases = [...useCases];
    // eslint-disable-next-line no-param-reassign
    obj.roles = [...roles];

    return obj;
  }, {});

  const lowercaseAppCategories = APP_CATEGORIES.map((appCategory) => {
    return { ...appCategory, data: appCategory?.data.map((app) => app?.toLowerCase()) };
  });

  // Sort APP_CATEGORIES
  const paymentProcesssorLabel = 'PAYMENT_PROCESSOR';
  const paymentProcessorIndex = lowercaseAppCategories.findIndex((item) => item.subLabel === paymentProcesssorLabel);

  if (paymentProcessorIndex !== -1) {
    const paymentProcessor = lowercaseAppCategories.splice(paymentProcessorIndex, 1);

    lowercaseAppCategories.sort((a, b) => {
      const subLabelA = a.subLabel.toUpperCase();
      const subLabelB = b.subLabel.toUpperCase();
      const ifSubLabelIsALarger = subLabelA > subLabelB ? 1 : 0;

      return subLabelA < subLabelB ? -1 : ifSubLabelIsALarger;
    });

    lowercaseAppCategories.unshift(...paymentProcessor);
  }

  const filters = [
    { id: 'apps', label: FILTERS_DICTIONARY.apps, data: lowercaseAppCategories },
    { id: 'useCases', label: FILTERS_DICTIONARY.useCases, data: toLowercaseArr(filterObject.useCases) },
    { id: 'roles', label: FILTERS_DICTIONARY.roles, data: toLowercaseArr(filterObject.roles) },
  ];

  return filters;
};

const generateLabel = (str, appCategory) => {
  if (typeof str !== 'string') return null;

  const strToTransform = appCategory ? str : str.toLowerCase();
  const label = strToTransform
    .split('_')
    .join(' ')
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

  return TAG_LABELS_MAP[label] ?? label;
};

const generateAllTags = (filters) => {
  // filters => Filters (Apps, Use Cses and Roles)
  const tags = filters.reduce((arr, item, index) => {
    if (index === 0) {
      const appsTag = item.data.reduce((appsArr, appCategory) => {
        if (!appCategory) return null;

        // eslint-disable-next-line no-param-reassign
        appsArr = [...appsArr, ...appCategory.data];
        return appsArr;
      }, []);

      // eslint-disable-next-line no-param-reassign
      arr = [...arr, ...appsTag];
    } else {
      // eslint-disable-next-line no-param-reassign
      arr = [...arr, ...item.data];
    }

    return arr;
  }, []);

  return [...new Set(tags)];
};

export { generateFilterObject, generateLabel, generateAllTags, APP_CATEGORIES, FILTERS_DICTIONARY };
