import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { ButtonRounded } from '../../atoms';
import { StyledTableCell } from './styled';
import { PROPTYPES } from './constant';
import { useSwitchTeam } from '../../../hooks/useSwitchTeam';
import { useGlobalContext } from '../../../containers/App/context';

const GLOBAL_LOADING_KEY = 'Switch team';

const SwitchCustomerCell = ({ row: { original }, cell: { column } }) => {
  const { t } = useTranslation();
  const { setGlobalLoading } = useGlobalContext();

  const header = column?.Header;
  const switchTeam = useSwitchTeam();

  const handleSwitchTeam = async () => {
    setGlobalLoading(GLOBAL_LOADING_KEY, true);

    await switchTeam(original?.id);
    setGlobalLoading(GLOBAL_LOADING_KEY, false);
  };

  return (
    <StyledTableCell column={column} id={original?.id}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {original[header]}
        <ButtonRounded
          className="switchButton"
          type="button"
          variant="contained"
          color="primary"
          borderRadius="4px"
          fontSize="12px"
          onClick={handleSwitchTeam}
        >
          {t('Switch')}
        </ButtonRounded>
      </Box>
    </StyledTableCell>
  );
};

SwitchCustomerCell.propTypes = PROPTYPES;
export default SwitchCustomerCell;
