import React, { useRef, useMemo, useState, useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import fromPairs from 'lodash/fromPairs';
import Box from '@mui/material/Box';
import entries from 'lodash/entries';
import { Form, Formik } from 'formik';
import { StyledDrawer, Main } from './styled';
import Header from './Header';
import Content from './Content';
import DisputeEvidenceForm from './DisputeEvidenceForm';
import FormControl from './FormControl';
import LoadingState from './LoadingState';
import SubmittedEvidenceLoadingState from './SubmittedEvidenceLoadingState';
import { DISPUTE_SUBMISSION_STATUS } from '../constant';
import useGetDisputeEvidenceFormAndFilesConfig from './hooks/useGetDisputeEvidenceFormAndFilesConfig';
import useDisputeEvidenceSubmissionActions from './hooks/useDisputeEvidenceSubmissionActions';
import SubmittedEvidence from './SubmittedEvidence';
import generateDynamicValidator from './validator';
import Dropdown from './Dropdown';
import { generateForm } from './constant';

const ADYEN_PAYMENT = 'payment-gateway-adyen';

const FormDrawer = ({
  submission,
  shouldDrawerOpen,
  setShouldDrawerOpen,
  connectionId,
  disputeId,
  sourceDisputeId,
  responseDate,
  setModalData,
  setShouldModalOpen,
  setDisabled,
  connectionServiceId,
}) => {
  const formikRef = useRef(null);
  const [shouldShowError, setShouldShowError] = useState(false);
  const [selectedDropdown, setSelectedDropdown] = useState('');
  const {
    id: submissionId,
    status,
    formData: submissionFormData,
    attachmentDetails: submissionAttachmentDetails,
  } = submission;

  const {
    filesConfigLoading,
    loading,
    form,
    filesConfig,
    defenseReasonOptions,
    fileTypes,
  } = useGetDisputeEvidenceFormAndFilesConfig(connectionId, sourceDisputeId);

  const initialValues = useMemo(
    () =>
      submissionAttachmentDetails?.reduce((acc, current) => {
        const key = current.evidenceName;

        return { ...acc, [key]: current };
      }, submissionFormData),
    [submissionAttachmentDetails, submissionFormData]
  );

  const filteredForm = generateForm(form, selectedDropdown, fileTypes);
  const filteredInputIds = filteredForm?.map((input) => input.id);

  const { handleSave, handleSubmit, isSaving } = useDisputeEvidenceSubmissionActions(
    initialValues,
    submissionId,
    disputeId,
    formikRef,
    setShouldDrawerOpen,
    setShouldModalOpen,
    setDisabled,
    selectedDropdown,
    filteredInputIds
  );

  const isFormSubmitted = status === DISPUTE_SUBMISSION_STATUS.submitted;
  const adyenDispute = connectionServiceId === ADYEN_PAYMENT;

  const attachments = useMemo(() => fromPairs(entries(initialValues)?.filter((item) => isObject(item[1]))), [
    initialValues,
  ]);

  const validator = generateDynamicValidator(filteredForm);

  useEffect(() => {
    if (defenseReasonOptions.length > 0) {
      setSelectedDropdown(defenseReasonOptions[0]?.title);
    }
  }, [defenseReasonOptions]);

  const toggleDrawer = (e) => {
    if (e.type === 'keydown') return;

    setShouldDrawerOpen(false);
  };

  return (
    <StyledDrawer anchor="right" open={shouldDrawerOpen} onClose={toggleDrawer}>
      <Box position="relative" height="100%">
        <Header setShouldDrawerOpen={setShouldDrawerOpen} status={status} responseDate={responseDate} />

        {isFormSubmitted && loading && <SubmittedEvidenceLoadingState />}

        {isFormSubmitted && !isEmpty(form) && !loading && (
          <Box padding="32px" marginTop="72px">
            <SubmittedEvidence form={form} initialValues={initialValues} submission={submission} />
          </Box>
        )}

        {!isFormSubmitted && (
          <Formik
            initialValues={initialValues ?? {}}
            onSubmit={handleSubmit}
            validate={validator}
            validateOnBlur
            enableReinitialize
            innerRef={formikRef}
          >
            <>
              <Box height="calc(100% - 144px)" overflow="auto" marginTop="72px">
                <Content />

                {adyenDispute && (
                  <Dropdown
                    loading={filesConfigLoading}
                    options={defenseReasonOptions}
                    selectedDropdown={selectedDropdown}
                    setSelectedDropdown={setSelectedDropdown}
                  />
                )}

                {loading && <LoadingState />}
                {!loading && !isEmpty(filteredForm) && (
                  <Form>
                    <Box position="relative">
                      <Main>
                        <DisputeEvidenceForm
                          form={filteredForm}
                          isFormSubmitted={isFormSubmitted}
                          isSaving={isSaving}
                          filesConfig={filesConfig}
                          attachments={attachments}
                          shouldShowError={shouldShowError}
                          fileTypes={fileTypes}
                        />
                      </Main>
                    </Box>
                  </Form>
                )}
              </Box>

              <FormControl
                handleSave={handleSave}
                isFormSubmitted={isFormSubmitted}
                isSaving={isSaving}
                setModalData={setModalData}
                setShouldModalOpen={setShouldModalOpen}
                formikRef={formikRef}
                setShouldShowError={setShouldShowError}
              />
            </>
          </Formik>
        )}
      </Box>
    </StyledDrawer>
  );
};

FormDrawer.propTypes = {
  submission: shape({}).isRequired,
  shouldDrawerOpen: bool.isRequired,
  setShouldDrawerOpen: func.isRequired,
  connectionId: string.isRequired,
  disputeId: string.isRequired,
  sourceDisputeId: string.isRequired,
  responseDate: string.isRequired,
  setModalData: func.isRequired,
  setShouldModalOpen: func.isRequired,
  setDisabled: func.isRequired,
  connectionServiceId: string.isRequired,
};

export default FormDrawer;
