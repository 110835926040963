import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/lab/Skeleton';

const LoadingState = () => {
  return (
    <Box borderRadius="8px" overflow="hidden" mb="24px">
      <Skeleton variant="rectangular" height="42px" width="100%" animation="wave" />
    </Box>
  );
};

export default LoadingState;
