import styled, { css } from 'styled-components';
import THEME from '../../../../../constants/theme';

export const StatItemHeader = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: baseline;

  span {
    color: ${THEME.greyColors.grey1};
  }
`;

export const StatItemLabel = styled.p`
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 16px;
  text-transform: capitalize;
`;
export const StatItemValue = styled.p`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 16px;
`;
export const StatItemStat = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${THEME.greyColors.grey26};
`;
export const StatItemPercent = styled.span`
  color: ${({ $color }) => $color};
  font-size: inherit;
  margin: 0 6px;
`;

export const DisputesStatsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

const MEDIUM_SCREEN_CSS = css`
  ${StatItemLabel} {
    font-size: 16px;
    font-weight: 500;
  }

  ${StatItemValue} {
    font-weight: 600;
    font-size: 36px;
    letter-spacing: -1px !important;
  }
`;
export const StatItemWrapper = styled.div`
  background-color: ${({ $checked }) => ($checked ? THEME.primaryColors.primaryLight : THEME.primaryColors.white)};
  border: 1px solid ${({ $checked }) => ($checked ? 'rgba(0,0,0,0)' : THEME.greyColors.grey5)};
  border-radius: 8px;
  padding: 12px;
  min-width: 242px;
  transition: background-color 0.3s ease-out;

  ${({ $applyLargeStyles }) => $applyLargeStyles && MEDIUM_SCREEN_CSS};
`;
