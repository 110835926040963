import React from 'react';

const NoData = (props) => (
  <svg width="80" height="72" viewBox="0 0 80 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="56" height="8" rx="4" fill="#F4F4FE" />
    <rect y="16" width="80" height="8" rx="4" fill="#F4F4FE" />
    <rect y="32" width="72" height="8" rx="4" fill="#8F92F9" />
    <rect y="48" width="56" height="8" rx="4" fill="#F4F4FE" />
    <rect y="64" width="72" height="8" rx="4" fill="#F4F4FE" />
  </svg>
);

export default NoData;
