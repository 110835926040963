import PropTypes from 'prop-types';
import React from 'react';
import capitalize from '@mui/material/utils/capitalize';
import { P14 } from '../../../../components/atoms';

export const parseStatusToUiLabel = (status) => {
  if (typeof status !== 'string') return '';

  return status.toLowerCase().replace(/_/g, ' ');
};

const StatusComponent = ({ dataObj }) => {
  const label = capitalize(parseStatusToUiLabel(dataObj.value));
  return <P14>{label}</P14>;
};

StatusComponent.propTypes = {
  dataObj: PropTypes.shape({
    value: PropTypes.string,
  }),
};
StatusComponent.defaultProps = {
  dataObj: {},
};

export default StatusComponent;
