import styled, { css } from 'styled-components';
import THEME from '../../../../../../../constants/theme';

const StyledWrapper = styled.div`
  padding: 32px;

  h4 {
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 8px;
    line-height: 24px;
    color: ${THEME.greyColors.grey800};
  }
`;

const COMMON_LINK_CSS = css`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  border-radius: 6px;
  transition: all 0.3s ease-out;
  text-decoration: none;
  color: ${THEME.primaryColors.primary};
  font-weight: 500;
  line-height: 24px;
  background: transparent;
  cursor: pointer;
  svg {
    margin-left: 6px;
    color: ${THEME.primaryColors.primary};
    font-size: 10px;
  }
`;

const LINK_HOVER_STYLES = css`
  color: ${THEME.primaryColors.primary};
  svg {
    color: ${THEME.primaryColors.primary};
    stroke: ${THEME.primaryColors.primary};
  }
`;

const ExternalLink = styled.a`
  ${COMMON_LINK_CSS};
  line-height: ${({ $lineHeight }) => $lineHeight || '24px'};
  &:hover {
    ${LINK_HOVER_STYLES};
  }
`;

export { StyledWrapper, ExternalLink };
