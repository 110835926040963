import styled from 'styled-components';
import THEME from '../../../../../../constants/theme';

const StyledSearchResult = styled.div`
  padding: 20px 24px 18px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${THEME.greyColors.grey16};
  max-height: 500px;
  min-height: 74px;
  overflow-y: auto;
`;

export { StyledSearchResult };
