import React, { useState } from 'react';
import { bool, func } from 'prop-types';
import { StyledDialog, StyledPaper, StyledDialogTitle, StyledDialogContent } from '../../../Dialog/styled';
import CloseButton from '../../../Dialog/CloseButton';
import Table from '../../../../pages/DataTables/Table';
import { HEAD_CELLS, transformNodeToRow } from './tableData';
import { TableStarterContextProvider, useTableStarter } from '../../../../pages/DataTables/hooks/useTableStarter';
import { StyledDialogTitleInner } from './styled';
import { useGetCustomers } from './useGetCustomers';
import Footer from './Footer';
import EmptyStateTemplate from '../../../../pages/DataTables/components/EmptyStateTemplate';
import Search from './Search';
import { LOADING_STATE_STYLES, DATA_TABLE_STYLE_PROPS, DIALOG_ID, HEAD_CELL_DROPDOWN_OPTIONS } from './constant';
import THEME from '../../../../constants/theme';

const CustomerListModal = ({ shouldModalOpen, toggleModal }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const value = useTableStarter();
  const [touched, setTouched] = useState(false);

  const { loading, searchLoading, customers, pageInfo } = useGetCustomers(isSubmit, touched);

  const isEmpty =
    !loading && !searchLoading && customers !== undefined && (!customers?.length || customers?.length === 0);

  return (
    <StyledDialog
      open={shouldModalOpen}
      scroll="paper"
      maxWidth="xl"
      PaperComponent={StyledPaper}
      onClose={toggleModal}
      aria-labelledby={DIALOG_ID}
    >
      <CloseButton onClick={toggleModal} top="16px" iconColor={THEME.primaryColors.blackMain} />

      <StyledDialogTitle disableTypography padding="16px 24px">
        <StyledDialogTitleInner>
          Choose account to switch
          <Search setIsSubmit={setIsSubmit} setTouched={setTouched} />
        </StyledDialogTitleInner>
      </StyledDialogTitle>

      <StyledDialogContent px="24px">
        <TableStarterContextProvider value={value}>
          <Table
            data={transformNodeToRow(customers) ?? []}
            loading={loading || searchLoading}
            isEmpty={isEmpty}
            pageInfo={pageInfo}
            headCells={HEAD_CELLS}
            initialTableState={{}}
            transformHiddenColumnsFn={() => {}}
            emptyStateComponent={EmptyStateTemplate}
            noCheckBox
            disableRowClick={true}
            headCellDropdownOptions={HEAD_CELL_DROPDOWN_OPTIONS}
            noLastRowBorder
            noPagination
            loadingStateStyles={LOADING_STATE_STYLES}
            noDnd
            {...DATA_TABLE_STYLE_PROPS}
          />
        </TableStarterContextProvider>
      </StyledDialogContent>

      <Footer pageInfo={pageInfo} />
    </StyledDialog>
  );
};

CustomerListModal.propTypes = {
  shouldModalOpen: bool.isRequired,
  toggleModal: func.isRequired,
};

export default CustomerListModal;
