import React from 'react';
import { string } from 'prop-types';

const Paypal = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#F5F6F7" />
    <path
      d="M16.1798 6.10198C15.5266 5.3575 14.3459 5.03833 12.8355 5.03833H8.45176C8.14273 5.03833 7.88013 5.26308 7.83175 5.56782L6.00645 17.1443C5.97016 17.3726 6.14692 17.5794 6.3783 17.5794H9.08464L9.76435 13.2682L9.74328 13.4032C9.79166 13.0984 10.0523 12.8737 10.3609 12.8737H11.647C14.1735 12.8737 16.1517 11.8475 16.7296 8.87895C16.7468 8.79116 16.7616 8.70571 16.7745 8.62221C16.7015 8.58358 16.7015 8.58358 16.7745 8.62221C16.9465 7.525 16.7733 6.77818 16.1798 6.10198Z"
      fill="#27346A"
    />
    <path
      d="M10.7999 8.22695C10.8721 8.19261 10.9525 8.17349 11.0368 8.17349H14.4735C14.8805 8.17349 15.2601 8.20002 15.607 8.25582C15.7065 8.27182 15.8029 8.29016 15.8965 8.31123C15.9902 8.33191 16.0811 8.35532 16.1693 8.38107C16.2134 8.39395 16.2567 8.40721 16.2992 8.42126C16.4697 8.47823 16.6285 8.54456 16.7745 8.62221C16.9465 7.52461 16.7733 6.77818 16.1798 6.10198C15.5263 5.3575 14.3459 5.03833 12.8355 5.03833H8.45137C8.14273 5.03833 7.88013 5.26308 7.83175 5.56782L6.00645 17.1439C5.97016 17.3726 6.14692 17.579 6.37791 17.579H9.08464L10.4948 8.63665C10.5237 8.45326 10.6415 8.30303 10.7999 8.22695Z"
      fill="#27346A"
    />
    <path
      d="M16.7295 8.87894C16.1517 11.8471 14.1734 12.8737 11.6469 12.8737H10.3605C10.0518 12.8737 9.7912 13.0984 9.74321 13.4032L8.89767 18.7636C8.86606 18.9634 9.02058 19.1444 9.2227 19.1444H11.5041C11.7741 19.1444 12.004 18.9477 12.0461 18.6812L12.0683 18.565L12.4983 15.8399L12.526 15.6893C12.5682 15.4228 12.798 15.2261 13.068 15.2261H13.4094C15.6194 15.2261 17.3499 14.3283 17.8556 11.7316C18.0667 10.6465 17.9574 9.74047 17.3991 9.10407C17.2297 8.91132 17.0194 8.75213 16.7744 8.62219C16.7611 8.70608 16.7467 8.79114 16.7295 8.87894Z"
      fill="#2790C3"
    />
    <path
      d="M16.1697 8.38104C16.0815 8.35529 15.9906 8.33188 15.8969 8.3112C15.8033 8.29052 15.7065 8.27218 15.6074 8.25618C15.2601 8.19999 14.8809 8.17346 14.4735 8.17346H11.0371C10.9525 8.17346 10.8721 8.19258 10.8003 8.22731C10.6415 8.30339 10.524 8.45323 10.4952 8.63701L9.76472 13.2681L9.74365 13.4032C9.79165 13.0984 10.0523 12.8737 10.3609 12.8737H11.6474C14.1738 12.8737 16.1521 11.8475 16.73 8.87892C16.7471 8.79113 16.7616 8.70607 16.7748 8.62218C16.6285 8.54492 16.4701 8.4782 16.2996 8.42162C16.2571 8.40757 16.2138 8.39392 16.1697 8.38104Z"
      fill="#1F264F"
    />
  </svg>
);

Paypal.propTypes = {
  width: string,
  height: string,
};

Paypal.defaultProps = {
  width: '24',
  height: '24',
};

export default Paypal;
