import styled from 'styled-components';

const DownloadContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
`;

const StyledDownloadLink = styled.a`
  svg {
    &:hover {
      cursor: pointer;
    }
  }
`;

export { DownloadContentContainer, StyledDownloadLink };
