import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useHistory, Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import OnboardingLayout from '../../components/layouts/OnboardingLayout';
import OnboardingLayoutBox from '../../components/layouts/OnboardingLayoutBox';
import EnterPasswordForm from '../../components/forms/EnterPasswordForm';
import { useRegistrationSearchParams } from '../../hooks/registration';
import { UI_ROUTES } from '../../constants/routes';
import { P14 } from '../../components/atoms';
import THEME from '../../constants/theme';

const TITLE = 'Password';

const EnterPasswordPage = memo(() => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { betaAccessToken, emailAddress, registrationMethod } = useRegistrationSearchParams();

  useEffect(() => {
    const isMissingRequiredParam = [betaAccessToken, emailAddress, registrationMethod].some(isEmpty);
    if (isMissingRequiredParam) {
      push(UI_ROUTES.signIn);
    }
  }, [push, betaAccessToken, emailAddress, registrationMethod]);

  return (
    <OnboardingLayout
      theme="dark"
      navigationRight={
        <P14 color={THEME.greyColors.lightGrey}>
          {t('Already have an account?')}{' '}
          <Link to={UI_ROUTES.signIn} className="text-primary">
            {t('Sign in')}
          </Link>
        </P14>
      }
    >
      <OnboardingLayoutBox>
        <Box maxWidth="336px" margin="0 auto">
          <Box mb="30px">
            <h1>{t(TITLE)}</h1>
          </Box>

          <EnterPasswordForm
            betaAccessToken={betaAccessToken}
            emailAddress={emailAddress}
            registrationMethod={registrationMethod}
          />
        </Box>
      </OnboardingLayoutBox>
    </OnboardingLayout>
  );
});

export default EnterPasswordPage;
