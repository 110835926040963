import React from 'react';
import Box from '@mui/material/Box';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { FIELDS, DEFAULT_VALUES, validationSchema } from '../../../../../pages/Payments/FilterList/fieldsSettings';
import FilterList from '../../../../../pages/Payments/FilterList';
import { CUSTOMER_FIELDS, CUSTOMER_DEFAULT_VALUES, customerValidationSchema } from './customerFilterData';
import { CARD_FIELDS, cardValidationSchema, CARD_DEFAULT_VALUES } from './cardFilterData';
import { DISPUTES_FIELDS, disputesValidationSchema, DISPUTES_DEFAULT_VALUES } from './disputesFilterData';

const FilterData = () => {
  const { pathname } = useLocation();
  const match = useRouteMatch(pathname.includes('payments') ? '/payments/:page' : '/data-tables/:page');

  const { page } = match?.params || {};
  return (
    <Box mt="-6px" height="90vh" overflow="hidden">
      {/* payments (empty) or saved views (views) */}
      {(!page || page === 'views') && (
        <FilterList fields={FIELDS} defaultValues={DEFAULT_VALUES} validationSchema={validationSchema} />
      )}
      {page === 'customers' && (
        <FilterList
          fields={CUSTOMER_FIELDS}
          defaultValues={CUSTOMER_DEFAULT_VALUES}
          validationSchema={customerValidationSchema}
        />
      )}
      {page === 'cards' && (
        <FilterList fields={CARD_FIELDS} defaultValues={CARD_DEFAULT_VALUES} validationSchema={cardValidationSchema} />
      )}
      {page === 'payment-disputes' && (
        <FilterList
          fields={DISPUTES_FIELDS}
          defaultValues={DISPUTES_DEFAULT_VALUES}
          validationSchema={disputesValidationSchema}
        />
      )}
    </Box>
  );
};
export default FilterData;
