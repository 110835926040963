import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import uniq from 'lodash/uniq';
import { useFlowEditorContext } from '../../../../context';
import useGetConnections from '../hooks/useGetConnections';
import { useNodeDataToSave } from '../fields/hooks/useNodeDataToSave';
import PaymentMethodWithSwitch from '../fields/PaymentMethodWithSwitch';
import LoadingState from '../../LoadingState';
import { StyledPaymentsWrapper } from './styled';
import AddNewConnectionButton from '../../../AddNewConnectionButton';
import THEME from '../../../../../../constants/theme';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const PaymentMethodsSection = () => {
  const { t } = useTranslation();
  const { topFlowId } = useFlowEditorContext();
  const { paymentMethods: fetchedPaymentMethods, loading } = useGetConnections({ topFlowId });
  const [{ enabledPaymentMethods }, updateDataToSave] = useNodeDataToSave();
  const [orderedPaymentMethods, setOrderedPaymentMethods] = useState();
  const [paymentMethods, setPaymentMethods] = useState(enabledPaymentMethods);

  useEffect(() => {
    if (!orderedPaymentMethods && fetchedPaymentMethods?.length) {
      setOrderedPaymentMethods(uniq([...(enabledPaymentMethods || []), ...fetchedPaymentMethods]));
    }
  }, [enabledPaymentMethods, fetchedPaymentMethods, orderedPaymentMethods]);

  useEffect(() => {
    if (!paymentMethods && enabledPaymentMethods) {
      setPaymentMethods(enabledPaymentMethods);
    }
  }, [enabledPaymentMethods, paymentMethods]);

  const switchMethod = useCallback(
    (value, item) => {
      let newPaymentMethods = [];
      if (value) {
        const unique = enabledPaymentMethods?.findIndex((method) => item === method) < 0;
        if (unique) {
          newPaymentMethods = [...paymentMethods, item];
        }
      } else {
        newPaymentMethods = paymentMethods.filter((method) => method !== item);
      }
      updateDataToSave({
        enabledPaymentMethods: newPaymentMethods,
      });
      setPaymentMethods(newPaymentMethods);
    },
    [enabledPaymentMethods, paymentMethods, updateDataToSave]
  );

  const setColumnOrder = (item) => {
    if (item) {
      const reorderedItems = reorder(orderedPaymentMethods, item.source?.index, item.destination?.index);
      const reorderedEnabledItems = reorderedItems.filter((pm) => enabledPaymentMethods.includes(pm));
      updateDataToSave({
        enabledPaymentMethods: reorderedEnabledItems,
      });
      setOrderedPaymentMethods(reorderedItems);
    }
  };

  return (
    <LoadingState loading={loading}>
      {orderedPaymentMethods?.length ? (
        <Box mt="32px">
          <DragDropContext onDragEnd={setColumnOrder}>
            <Droppable droppableId="payment-methods">
              {(droppableProvided) => (
                <StyledPaymentsWrapper {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                  {orderedPaymentMethods?.map((item, index) => (
                    <Draggable key={item} draggableId={item} index={index} content={item}>
                      {(provided, snapshot) => (
                        <PaymentMethodWithSwitch
                          provided={provided}
                          snapshot={snapshot}
                          item={item}
                          handleSwitch={switchMethod}
                          checked={!!enabledPaymentMethods?.includes(item)}
                        />
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </StyledPaymentsWrapper>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      ) : null}
      <AddNewConnectionButton
        text={t('Add more payment methods')}
        border={`1px solid ${THEME.primaryColors.primaryLight}`}
        borderRadius="8px"
        mt="8px"
      />
    </LoadingState>
  );
};

export default PaymentMethodsSection;
