import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import moment from 'moment';
import { StyledDetailsBox } from '../styled';
import { useFlowEditorContext } from '../../FlowEditor/context';
import FlowMonitorDetailsItem from '../FlowMonitorDetailsDrawer/FlowMonitorDetailsItem';
import NodeIcon from '../../FlowEditor/components/NodeLibrary/NodeIcon';
import LoadingState from '../FlowMonitorDetailsDrawer/LoadingState';
import { useLogsData } from '../hooks/useLogsData';
import { useGetRetriesForInstance } from '../hooks/useGetRetriesForInstance';
import { prepareStepLogs } from '../FlowMonitorDetailsDrawer/helpers';

const FlowMonitorInstanceDetailsDrawer = ({ setSelectedStep }) => {
  const { t } = useTranslation();
  const {
    selectedInstance,
    setSelectedInstance,
    setSelectedInstanceRetries,
    setSelectedInstanceLoading,
    setSelectedInstanceRetriesLoading,
  } = useFlowEditorContext();
  const { flowInstance, loading } = useLogsData();
  const { flowInstanceRetries, loading: retriesLoading } = useGetRetriesForInstance();
  const errorText = t('There was an error running the action');

  useEffect(() => {
    if (flowInstance) {
      setSelectedInstance(flowInstance);
    }
  }, [flowInstance, setSelectedInstance]);

  useEffect(() => {
    setSelectedInstanceLoading(loading);
  }, [loading, setSelectedInstanceLoading]);

  useEffect(() => {
    setSelectedInstanceRetriesLoading(retriesLoading);
  }, [retriesLoading, setSelectedInstanceRetriesLoading]);

  useEffect(() => {
    setSelectedInstanceRetries(
      flowInstanceRetries?.length ? [...flowInstanceRetries]?.sort((a, b) => moment(a?.created).diff(b?.created)) : []
    );
  }, [flowInstanceRetries, setSelectedInstanceRetries]);

  if (!selectedInstance) {
    if (loading) {
      return (
        <Box p="6px 24px">
          <LoadingState />
        </Box>
      );
    }
    return null;
  }

  const stepLogs = prepareStepLogs(selectedInstance);

  return (
    <StyledDetailsBox>
      <Box p="6px 8px">
        {stepLogs.map(({ step, ...item }) => {
          const hasError = !!item?.logs?.find((log) => log.level === 'ERROR');
          return (
            <FlowMonitorDetailsItem
              key={step.id}
              label={step.name}
              size={52}
              errorMessage={hasError ? errorText : null}
              px="24px"
              showTimelineStatus
              onClick={() => setSelectedStep({ ...item, step })}
            >
              <NodeIcon mr="12px" nodeData={step} type={step.group?.toLowerCase()} hasError={hasError} />
            </FlowMonitorDetailsItem>
          );
        })}
      </Box>
    </StyledDetailsBox>
  );
};

FlowMonitorInstanceDetailsDrawer.propTypes = {
  setSelectedStep: func.isRequired,
};

export default FlowMonitorInstanceDetailsDrawer;
