import React from 'react';

const AliPay = () => {
  return (
    <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="34" height="24" rx="4" fill="white" />
      <rect
        x="0.25"
        y="0.25"
        width="33.5"
        height="23.5"
        rx="3.75"
        stroke="black"
        strokeOpacity="0.15"
        strokeWidth="0.5"
      />
      <path
        d="M8.8796 8.79834H4.12156C3.50193 8.79834 3 9.30489 3 9.92916V14.7275C3 15.3518 3.50193 15.8584 4.12156 15.8584H8.8796C9.49923 15.8584 10.0005 15.3518 10.0005 14.7275V9.92916C10.0012 9.30423 9.49923 8.79834 8.8796 8.79834Z"
        fill="#1677FF"
      />
      <path
        d="M8.76385 13.2315C8.48412 13.1369 8.10785 12.9921 7.68924 12.8393C7.94054 12.3983 8.14157 11.8963 8.27383 11.3508H6.8937V10.8495H8.58398V10.5698H6.8937V9.73389H6.20397C6.08295 9.73389 6.08295 9.85424 6.08295 9.85424V10.5691H4.3735V10.8488H6.08295V11.3501H4.67108V11.6298H7.40885C7.309 11.9777 7.17409 12.305 7.01472 12.6019C6.1266 12.3063 5.1783 12.0669 4.58313 12.2144C4.20222 12.309 3.95688 12.4776 3.81272 12.6548C3.15142 13.4662 3.62557 14.6989 5.02223 14.6989C5.84819 14.6989 6.64373 14.2347 7.26006 13.4696C8.17993 13.9153 10.0005 14.6811 10.0005 14.6811V13.5899C10.0011 13.5899 9.77233 13.5714 8.76385 13.2315ZM4.89196 14.2274C3.8028 14.2274 3.48075 13.3624 4.01904 12.8889C4.19892 12.7289 4.52692 12.6509 4.7015 12.6337C5.34825 12.5695 5.94739 12.8182 6.65431 13.166C6.15768 13.8194 5.52482 14.2274 4.89196 14.2274Z"
        fill="white"
      />
      <path
        d="M18.2012 9.83174C18.2012 10.1968 18.4684 10.4428 18.8407 10.4428C19.213 10.4428 19.4802 10.1968 19.4802 9.83174C19.4802 9.47332 19.213 9.2207 18.8407 9.2207C18.4684 9.2207 18.2012 9.47332 18.2012 9.83174Z"
        fill="#1677FF"
      />
      <path d="M17.5267 9.375H16.4171V14.5027H17.5267V9.375Z" fill="black" />
      <path
        d="M14.4715 9.55078H12.9823L11.3178 14.5032H12.3435L12.6245 13.5338H14.3875L14.6547 14.5032H15.968L14.4715 9.55078ZM12.856 12.7257L13.5159 10.4429H13.5437L14.1686 12.7257H12.856Z"
        fill="black"
      />
      <path d="M19.3955 10.7378H18.2859V14.5032H19.3955V10.7378Z" fill="black" />
      <path
        d="M30.9934 10.7451L31 10.7378H29.9538L29.2932 13.0279H29.2581L28.4996 10.7378H27.2564L28.7522 14.5171L28.1273 15.6691V15.6969H29.1034L30.9934 10.7451Z"
        fill="black"
      />
      <path
        d="M22.1565 10.6606C21.7491 10.6606 21.433 10.8154 21.0746 11.1103V10.738H19.9649V15.6971H21.0746V14.4677C21.2855 14.5239 21.4819 14.5524 21.7207 14.5524C22.7113 14.5524 23.6034 13.8216 23.6034 12.5222C23.6034 11.3563 22.9573 10.6606 22.1565 10.6606ZM21.4535 13.8567C21.3272 13.8567 21.2075 13.8428 21.0739 13.8005V11.7637C21.306 11.6023 21.4952 11.525 21.7339 11.525C22.1485 11.525 22.4785 11.8549 22.4785 12.5579C22.4792 13.4566 21.9944 13.8567 21.4535 13.8567Z"
        fill="black"
      />
      <path
        d="M27.0382 13.5618V11.9885C27.0382 11.1315 26.5323 10.6606 25.6402 10.6606C25.0708 10.6606 24.678 10.7592 23.9612 10.9767L24.1576 11.8411C24.8109 11.5461 25.0986 11.4198 25.4008 11.4198C25.7658 11.4198 25.9279 11.6797 25.9279 12.0805V12.1082C24.6562 12.347 24.2627 12.4805 24.0174 12.7265C23.8348 12.9091 23.7575 13.1689 23.7575 13.4712C23.7575 14.1946 24.3196 14.5808 24.846 14.5808C25.2394 14.5808 25.5555 14.4333 25.9841 14.11L26.0614 14.5034H27.1711L27.0382 13.5618ZM25.9285 13.5902C25.6336 13.7515 25.465 13.815 25.2685 13.815C25.0014 13.815 24.8327 13.6391 24.8327 13.3587C24.8327 13.2536 24.8539 13.1478 24.9379 13.0638C25.0715 12.9302 25.3314 12.8317 25.9285 12.6915V13.5902Z"
        fill="black"
      />
    </svg>
  );
};

export default AliPay;
