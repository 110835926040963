import React, { useCallback } from 'react';
import { string, shape, arrayOf, oneOfType, number, func, bool } from 'prop-types';
import { MenuItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { useToggle } from 'react-use';
import CheckIcon from '../../../assets/icons/Select/CheckIcon';
import THEME from '../../../constants/theme';
import { P14 } from '../Typography/P14';
import StyledFormControl from '../../styled/StyledFormControl';
import CustomSelect from './CustomSelect';

/*
  TODO 
   - fully migrate StyledPaper, StyledMenuItem and StyledMenuItemOnboarding from here to ./styled.js
   - currently there are too many places we're importing these three componenets from this file
   - so move small exports at a time as you work on a task consuming these components 
*/

const StyledPaper = styled.div`
  &.MuiPaper-root,
  &.MuiPaper-root.MuiPaper-elevation8:not(.MuiCard-root) {
    background-color: #fff;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    margin-top: 6px;
    border: none;
    max-height: 207px;
  }

  & .MuiList-padding {
    padding: 4px;
    padding-right: 4px !important; // Needed for Country select at Data Table - Create Customer form. Investigate further on ways we don't have to use '!important' on this.
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &.MuiButtonBase-root {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    justify-content: ${({ $justifyContent }) => $justifyContent || 'flex-start'};
    align-items: center;
    transition: all 0.3s ease-out;
    padding-top: ${({ pt }) => pt || '8px'};
    padding-bottom: 8px;
    color: ${THEME.primaryColors.blackMain};
    ${({ margin }) => margin && `margin: ${margin};`};
    border-radius: ${({ divider }) => (divider ? '' : '4px')};
    padding: 4px 8px;

    &:last-child {
      margin-bottom: 0px;
    }

    .check-icon {
      display: none;
      visibility: hidden;
      opacity: 0;
      margin-left: auto;
      margin-right: 8px;
    }

    &:hover {
      background-color: ${({ noBgColor }) => (noBgColor ? 'transparent' : THEME.greyColors.grey21)};
    }

    &.MuiMenuItem-root.Mui-selected {
      background-color: transparent;
      color: ${THEME.primaryColors.primary};

      .check-icon {
        display: block;
        visibility: visible;
        opacity: 1;
        margin-left: 6px;
        transform: translateX(6px);
      }

      &:hover {
        background-color: ${THEME.greyColors.grey21};
      }
    }
  }

  &.Mui-focusVisible {
    background-color: transparent !important;
  }
`;

const StyledMenuItemOnboarding = styled(MenuItem)`
  &.MuiButtonBase-root {
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: ${({ $justifyContent }) => $justifyContent || 'flex-start'};
    align-items: center;
    transition: all 0.3s ease-out;
    padding-top: ${({ pt }) => pt || '8px'};
    padding-bottom: 8px;
    background: white;
    .checkbox {
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      border-radius: 4px;
      border: 1px solid ${THEME.greyColors.grey300};
      display: inline-flex;
      align-items: center;
      flex-shrink: 0;
      margin-right: 10px;
      transition: background 0.25s, border-color 0.25s;
      justify-content: space-around;
      .check-icon {
        opacity: 0;
      }
    }
    .check-icon {
      display: none;
      visibility: hidden;
      opacity: 0;
      margin-left: auto;
      margin-right: 8px;
      transition: 0.25s;
      * {
        fill: white;
      }
    }
    &:hover {
      background-color: #fff !important;
      color: ${({ $multiple }) => ($multiple ? `${THEME.primaryColors.black}` : `${THEME.primaryColors.blue}`)};
      .checkbox {
        border-color: ${THEME.greyColors.grey600};
      }
    }
  }
  &.Mui-selected {
    background-color: ${THEME.primaryColors.white} !important;
    color: ${({ $multiple }) => ($multiple ? `${THEME.primaryColors.black}` : `${THEME.primaryColors.blue}`)};
    &.Mui-disabled {
      color: ${THEME.primaryColors.black};
    }
    .check-icon {
      display: block;
      visibility: visible;
      opacity: 1;
      margin-left: 6px;
      transform: translateX(6px);
    }
    .checkbox {
      background: ${THEME.primaryColors.primary};
      border-color: ${THEME.primaryColors.primary} !important;
      .check-icon {
        opacity: 1;
        margin-left: 0;
        margin-right: 0;
        transform: none;
      }
    }
  }
  &.Mui-focusVisible {
    background-color: white !important;
    .checkbox {
      border-color: ${THEME.greyColors.grey600};
      border-width: 2px;
    }
  }
`;

const StyledSelect = ({
  paperComponent,
  hideLink,
  id,
  name,
  options,
  value,
  onChange,
  label,
  open,
  onClose,
  renderValue,
  displayEmpty,
  ...restProps
}) => {
  const [isOpen, toggleOpen] = useToggle(open || false);
  const handleOnChange = useCallback(
    (e) =>
      e.target.value !== 'addCard' ? onChange(e) : options.find((item) => item.value === 'addCard').clickHandler(),
    [options, onChange]
  );
  const handleClose = (e) => {
    e.stopPropagation();
    onClose(e);
    toggleOpen();
  };

  return (
    <StyledFormControl variant="outlined" {...restProps}>
      <CustomSelect
        open={isOpen}
        onOpen={toggleOpen}
        onClose={handleClose}
        labelId={`${name}-label`}
        value={value}
        id={name}
        name={name}
        MenuProps={{
          getContentAnchorEl: null,
          PaperProps: {
            component: paperComponent ?? StyledPaper,
          },
        }}
        displayEmpty={displayEmpty}
        IconComponent={ExpandMoreIcon}
        inputProps={{ name, id, onChange: handleOnChange, value }}
        {...(renderValue && { renderValue })}
        {...restProps}
      >
        {options.map((option) => (
          <StyledMenuItem
            key={option.value}
            value={option.value}
            disabled={option.disabled}
            $justifyContent="space-between"
          >
            {option.icon || null}
            {option.text.text ? option.text.text : '0'}
            <CheckIcon />
            {!hideLink && option.connectedIds && (
              <P14 margin="0 0 0 auto" color={THEME.greyColors.grey2}>
                {!isEmpty(option.connectedIds.find((item) => item.id === option.value)) ? 'Linked' : 'Not linked'}
              </P14>
            )}
          </StyledMenuItem>
        ))}
      </CustomSelect>
    </StyledFormControl>
  );
};

StyledSelect.propTypes = {
  name: string.isRequired,
  onChange: func.isRequired,
  value: oneOfType([number, string, arrayOf(string)]).isRequired,
  options: arrayOf(shape({})).isRequired,
  id: string,
  label: string,
  hideLink: bool,
  open: bool,
  onClose: func,
  paperComponent: func,
  renderValue: func,
  displayEmpty: bool,
};
StyledSelect.defaultProps = {
  id: null,
  label: null,
  hideLink: false,
  open: false,
  onClose: () => null,
  paperComponent: undefined,
  renderValue: undefined,
  displayEmpty: false,
};

export default StyledSelect;
export { StyledPaper, StyledMenuItem, StyledMenuItemOnboarding, CustomSelect, StyledFormControl };
