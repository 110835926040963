const SORT_DICTIONARY = {
  none: 'undefined',
  ascending: 'asc',
  descending: 'desc',
};
const SORT_DICTIONARY_UI = {
  asc: 'ascending',
  desc: 'descending',
};

const HIDE_EDIT_MAP = ['customers', 'cards', 'payment-disputes'];

const getPageDropdownOptions = (page) => [
  {
    label: 'sort',
    withIcon: true,
    subMenuLabels: ['none', 'ascending', 'descending'],
  },

  {
    label: 'hide',
  },

  ...(HIDE_EDIT_MAP.includes(page)
    ? []
    : [
        {
          label: 'edit columns',
        },
      ]),
];

const handleSort = ({ handleRequestSort, event, id }) => {
  event.stopPropagation();
  const { label } = event.currentTarget.dataset;

  handleRequestSort({
    label: id?.split(' ')?.join('_'),
    value: SORT_DICTIONARY[label],
  });
};

export { SORT_DICTIONARY, SORT_DICTIONARY_UI, getPageDropdownOptions, handleSort };
