import { string } from 'prop-types';
import React from 'react';
import { getNodeColorFromGroup } from '../../utils/getNodeColor';
import Icon from '../../../atoms/Icon/Icon';

const FlowStepIcon = ({ group, icon, size }) => {
  return <Icon icon={icon ?? 'FlowStep'} size={size} color={getNodeColorFromGroup(group)} />;
};

FlowStepIcon.propTypes = {
  group: string.isRequired,
  icon: string,
  size: string,
};

FlowStepIcon.defaultProps = {
  icon: 'FlowStep',
  size: 's',
};

export { FlowStepIcon };
