import THEME from '../../../constants/theme';

export const getEdgeColor = ({
  edge,
  selectedNodeId,
  hoverNodeId,
  isAutomationTest,
  edgeError,
  selected,
  edgeWithCondition,
}) => {
  if (edgeError) {
    return THEME.secondaryColors.nodeError;
  }
  if (isAutomationTest) {
    return THEME.greyColors.grey8;
  }
  const { source, id } = edge;
  if (source === selectedNodeId || source === hoverNodeId || id === selectedNodeId || id === hoverNodeId || selected) {
    return THEME.secondaryColors.secondary;
  }
  if (edgeWithCondition) {
    return THEME.greyColors.grey500;
  }
  return THEME.greyColors.grey8;
};
