import { string } from 'prop-types';
import React from 'react';
import THEME from '../../constants/theme';

const Info2 = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2640_5759)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0002 2.5C5.85803 2.5 2.50016 5.85786 2.50016 10C2.50016 14.1421 5.85803 17.5 10.0002 17.5C14.1423 17.5 17.5002 14.1421 17.5002 10C17.5002 5.85786 14.1423 2.5 10.0002 2.5ZM0.833496 10C0.833496 4.93739 4.93755 0.833332 10.0002 0.833332C15.0628 0.833332 19.1668 4.93739 19.1668 10C19.1668 15.0626 15.0628 19.1667 10.0002 19.1667C4.93755 19.1667 0.833496 15.0626 0.833496 10Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99984 9.16667C10.4601 9.16667 10.8332 9.53976 10.8332 10V13.3333C10.8332 13.7936 10.4601 14.1667 9.99984 14.1667C9.5396 14.1667 9.1665 13.7936 9.1665 13.3333V10C9.1665 9.53976 9.5396 9.16667 9.99984 9.16667Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.1665 6.66667C9.1665 6.20643 9.5396 5.83333 9.99984 5.83333H10.0082C10.4684 5.83333 10.8415 6.20643 10.8415 6.66667C10.8415 7.1269 10.4684 7.5 10.0082 7.5H9.99984C9.5396 7.5 9.1665 7.1269 9.1665 6.66667Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2640_5759">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

Info2.propTypes = {
  fill: string,
  width: string,
  height: string,
};
Info2.defaultProps = {
  width: '16',
  height: '16',
  fill: THEME.greyColors.grey600,
};

export default Info2;
