import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import { createLogicOperator } from '../Conditions/utils';
import { createTest } from '../Test/utils';
import { useFlowEditorContext } from '../../../../../context';
import { useNodeDataToSave } from '../hooks/useNodeDataToSave';
import { initialComparisonOperator } from '../constants';

const emptyTest = { left: '', op: initialComparisonOperator, right: null };
const useNewCondition = ({ conditions, condition, index }) => {
  const { selectedConditionEdgeNumber } = useFlowEditorContext();
  const [{ elseStepId }, updateDataToSave] = useNodeDataToSave();
  const edgeNumber = index + 1;
  const [expanded, setExpanded] = useState(selectedConditionEdgeNumber === edgeNumber);
  const tests = useMemo(() => condition?.tests?.map((test) => ({ ...test, id: test?.id ?? v4() })), [condition?.tests]);
  const isShowIfElse = !!(!tests?.length && !elseStepId);
  const isShowAddRule = !!(tests?.length || !!(tests?.length || elseStepId));

  const handleChangeExpanded = () => {
    setExpanded(!expanded);
  };

  const updateConditionLogicalOperator = (value) => {
    const newConditions = conditions?.map((cond) =>
      cond.id === condition.id ? { ...cond, logicalOperator: value } : cond
    );
    updateDataToSave({ conditions: newConditions });
  };

  const updateConditionTests = useCallback(
    (newTests) => {
      const newConditions = conditions?.map((cond) =>
        cond.id === condition.id
          ? { ...cond, tests: newTests, logicalOperator: createLogicOperator(condition?.logicalOperator) }
          : cond
      );
      updateDataToSave({ conditions: newConditions });
    },
    [condition.id, condition?.logicalOperator, conditions, updateDataToSave]
  );

  const removeConditionTest = (testId) => {
    const newConditions = conditions?.map((c) =>
      c.id === condition.id
        ? {
            ...c,
            tests: c.tests?.filter((test) => test.id !== testId),
            logicalOperator: createLogicOperator(condition?.logicalOperator),
          }
        : c
    );
    updateDataToSave({ conditions: newConditions });
  };

  const removeCondition = (id) => {
    const newConditions = conditions?.filter((c) => c.id !== id);
    updateDataToSave({ conditions: newConditions });
  };

  const setTests = useCallback(
    (newTest) => {
      const prevTests = tests ?? [];
      const newTests = [...prevTests, newTest];
      updateConditionTests(newTests);
    },
    [tests, updateConditionTests]
  );

  useEffect(() => {
    const isAnyRuleInConditions = conditions.find(
      (conditionItem) => conditionItem.tests && conditionItem.tests.length > 0
    );
    if (!isAnyRuleInConditions && conditions?.length === 1) {
      setTests(emptyTest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateTest = ({ id, name, value }) => {
    const updatedTests = tests.map((test) => (test.id === id ? createTest(test, name, value) : test));
    updateConditionTests(updatedTests);
  };

  const handleAddElseCondition = () => {
    if (condition.id && condition.nextStepId) {
      const updatedConditions = conditions?.filter(({ id }) => condition.id !== id);
      updateDataToSave({ conditions: updatedConditions, elseStepId: condition.nextStepId });
    }
  };

  const handleAddRule = () => setTests(emptyTest);

  const handleAddIfCondition = () => {
    handleAddRule();
  };

  return {
    expanded,
    edgeNumber,
    isShowIfElse,
    isShowAddRule,
    updateTest,
    tests,
    handleChangeExpanded,
    updateConditionLogicalOperator,
    removeConditionTest,
    handleAddElseCondition,
    handleAddIfCondition,
    handleAddRule,
    removeCondition,
  };
};

export default useNewCondition;
