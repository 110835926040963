import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
  GET_CONNECTIONS,
  GET_CONNECTION_DETAILS,
  GET_CONNECTION_FEE,
} from '../../../utils/queries/connections/connectionsQueries';
import {
  RECONNECT_CONNECTION,
  DISCONNECT_CONNECTION,
  CHANGE_CONNECTION_NAME,
  START_CONNECTION,
  SAVE_CREDENTIALS,
  SAVE_CONNECTION_FORM,
  SET_CONNECTION_FEE,
} from '../../../utils/queries/connections/connectionsMutations';
import { useGlobalContext } from '../../../containers/App/context';
import { globalLoadingConst } from '../../../constants/globalLoadingConsts';
import { GET_CONNECTION_LIST_VARIABLES } from './constant';

const getConnections = GET_CONNECTIONS.definitions[0].name.value;

const FEES_MUTATION_OPTION = (id) => ({
  refetchQueries: [{ query: GET_CONNECTION_FEE, variables: { id } }],
  awaitRefetchQueries: true,
  context: { skipGlobalHandling: true },
});

/** ON Connect (saveForm) get updated connection list and connection details */
const CONNECT_FORM_MUTATION_OPTION = (id) => ({
  refetchQueries: [
    { query: GET_CONNECTIONS, ...GET_CONNECTION_LIST_VARIABLES },
    getConnections,
    ...(id ? [{ query: GET_CONNECTION_DETAILS, variables: { id } }] : []),
  ],
  update(cache) {
    cache.modify({
      fields: {
        getFlowStepLibrary(refs, { DELETE }) {
          return DELETE;
        },
        listConnections(refs, { DELETE }) {
          return DELETE;
        },
        getConnections(refs, { DELETE }) {
          return DELETE;
        },
      },
    });
  },
  awaitRefetchQueries: true,
});

const useUpdateConnectionData = (id) => {
  const [reconnect, { loading: reconnectLoading }] = useMutation(RECONNECT_CONNECTION);
  const [disconnect, { loading: disconnectLoading }] = useMutation(
    DISCONNECT_CONNECTION,
    CONNECT_FORM_MUTATION_OPTION(id)
  );

  const [changeName, { loading: nameChangeLoading }] = useMutation(
    CHANGE_CONNECTION_NAME,
    CONNECT_FORM_MUTATION_OPTION(id)
  );
  const [saveForm, { loading: saveFormLoading }] = useMutation(SAVE_CONNECTION_FORM, CONNECT_FORM_MUTATION_OPTION(id));
  const [connect, { loading: connectingLoading }] = useMutation(START_CONNECTION);
  const [setFees, { loading: feesLoading }] = useMutation(SET_CONNECTION_FEE, FEES_MUTATION_OPTION(id));

  const [saveCredentials, { loading: saveCredentialsLoading }] = useMutation(SAVE_CREDENTIALS, {
    context: { skipGlobalHandling: true },
  });
  const { setGlobalLoading } = useGlobalContext();

  useEffect(() => {
    if (
      reconnectLoading ||
      disconnectLoading ||
      nameChangeLoading ||
      connectingLoading ||
      saveCredentialsLoading ||
      saveFormLoading ||
      feesLoading
    ) {
      setGlobalLoading(globalLoadingConst.connectionUpdate, true);
    } else {
      setGlobalLoading(globalLoadingConst.connectionUpdate, false);
    }
  }, [
    setGlobalLoading,
    connectingLoading,
    reconnectLoading,
    disconnectLoading,
    nameChangeLoading,
    saveCredentialsLoading,
    feesLoading,
    saveFormLoading,
  ]);

  return {
    saveForm,
    connect,
    disconnect,
    reconnect,
    changeName,
    saveCredentials,
    setFees,
  };
};

export { useUpdateConnectionData };
