import React from 'react';
import { func } from 'prop-types';
import XSmall from '../../../../../../assets/icons/XSmall';
import { StyledWrapper } from './styled';
import THEME from '../../../../../../constants/theme';

const ClearFilter = ({ handleClearFilters }) => {
  return (
    <StyledWrapper>
      <div type="button" onClick={handleClearFilters}>
        Clear all filters <XSmall color={THEME.greyColors.grey19} />
      </div>
    </StyledWrapper>
  );
};

ClearFilter.propTypes = {
  handleClearFilters: func.isRequired,
};

export default ClearFilter;
