import * as Yup from 'yup';

const FILED_MAP = {
  teamName: 'teamName',
};

const INITIAL_VALUES = {
  [FILED_MAP.teamName]: '',
};

const validationSchema = Yup.object({
  [FILED_MAP.teamName]: Yup.string().required('Team name is required.'),
});

const FIELDS = [
  {
    name: FILED_MAP.teamName,
    type: 'text',
    placeholder: 'Enter new team name',
    label: 'Team name',
  },
];

export { validationSchema, INITIAL_VALUES, FIELDS };
