import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import OnboardingLayout from '../../components/layouts/OnboardingLayout';
import OnboardingLayoutBox from '../../components/layouts/OnboardingLayoutBox';
import { P14, P16 } from '../../components/atoms';
import THEME from '../../constants/theme';
import SubmitButton from '../../components/forms/_common/SubmitButton';
import { useGlobalContext } from '../../containers/App/context';
import { UI_ROUTES } from '../../constants/routes';

const TITLE = 'This link has expired.';
const DESC =
  'The access link you have been provided has expired. You’ll need to register again to be granted access to the platform.';

const ExpiredBetaLink = memo(() => {
  const { t } = useTranslation();
  const { toggleIntercom } = useGlobalContext();
  //TODO: Block route based on a search_param

  return (
    <OnboardingLayout
      theme="dark"
      navigationRight={
        <P14 color={THEME.greyColors.lightGrey}>
          {t('Already have an account?')}{' '}
          <Link to={UI_ROUTES.signIn} className="text-primary">
            {t('Sign in')}
          </Link>
        </P14>
      }
    >
      <OnboardingLayoutBox>
        <Box display="flex" flexDirection="column" maxWidth="336px" margin="0 auto">
          <h1>{t(TITLE)}</h1>
          <P16 margin="16px 0 40px" color={THEME.greyColors.grey9}>
            {t(DESC)}
          </P16>

          <Box mt="auto">
            <SubmitButton showIcon={false} onClick={toggleIntercom} width="100%">
              {t('Contact Support')}
            </SubmitButton>
          </Box>
        </Box>
      </OnboardingLayoutBox>
    </OnboardingLayout>
  );
});

export default ExpiredBetaLink;
