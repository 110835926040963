import styled from 'styled-components';

const StyledInfoIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
`;

export { StyledInfoIconWrapper };
