import React, { useState } from 'react';
import { shape, number, string, bool } from 'prop-types';
import { getEdgeCenter } from 'react-flow-renderer';
import { useFlowEditorContext } from '../../context';
import { Edge } from './Edge';
import { EdgeConditionBlock } from './EdgeConditionBlock';
import { getEdgeColor } from '../../utils/getEdgeColor';

const EdgeWithCondition = (props) => {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    source,
    target,
    edgeError,
    sourcePosition,
    targetPosition,
    sourceHandleId,
    targetHandleId,
    selected,
  } = props;
  const { isAutomationTest, isFlowMonitor, selectedNodeId, hoverNodeId } = useFlowEditorContext();
  const [isShowCondition] = useState(true);

  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const edgeColor = getEdgeColor({
    edge: { source, id, sourceHandleId },
    selectedNodeId,
    hoverNodeId,
    isAutomationTest,
    edgeError,
    selected,
    edgeWithCondition: true,
  });

  return (
    <Edge {...props}>
      {!isAutomationTest && !isFlowMonitor && isShowCondition && (
        <EdgeConditionBlock
          id={id}
          edgeCenterX={edgeCenterX}
          edgeCenterY={edgeCenterY}
          stepId={source}
          targetId={target}
          sourceHandleId={sourceHandleId}
          targetHandleId={targetHandleId}
          edgeError={edgeError}
          edgeColor={edgeColor}
        />
      )}
    </Edge>
  );
};

EdgeWithCondition.propTypes = {
  id: string.isRequired,
  source: string.isRequired,
  target: string.isRequired,
  sourceHandleId: string,
  targetHandleId: string,
  sourceX: number.isRequired,
  sourceY: number.isRequired,
  targetX: number.isRequired,
  targetY: number.isRequired,
  sourcePosition: string.isRequired,
  targetPosition: string.isRequired,
  arrowHeadType: string,
  edgeError: bool,
  style: shape({}),
  selected: bool,
};

EdgeWithCondition.defaultProps = {
  style: {},
  sourceHandleId: null,
  targetHandleId: null,
  edgeError: false,
  arrowHeadType: null,
  selected: false,
};

export { EdgeWithCondition };
