import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import _isEmpty from 'lodash/isEmpty';
import unionBy from 'lodash/unionBy';
import { TableStarterContextProvider, useTableStarter } from '../hooks/useTableStarter';
import { useGetDisputesList } from '../../../hooks/payment-disputes/useGetDisputesList';
import { INITIAL_TABLE_STATE, transformHiddenColumnsFn } from './tableConst';
import { useNotificationManager } from '../../../hooks/useNotificationManager';
import { headCells } from './tableData';
import DisputeStats from './DisputeStats';
import Header from '../components/Header';
import Table from '../Table';
import { useRightAsideContext } from '../../../providers/RightAsideProvider';

const CUSTOM_DETAILS_PATHNAME = '/payments';
const CUSTOM_DETAILS_ID = 'payment id';

const Disputes = () => {
  const value = useTableStarter();
  const { loading, error, rows, pageInfo } = useGetDisputesList();
  const unionRows = useMemo(() => rows && unionBy(rows, 'id'), [rows]);
  const isEmpty = !loading && _isEmpty(rows);
  const { globalFilterState } = useRightAsideContext();

  useNotificationManager('error', error?.message, 'Disputes', 5000);

  const height = globalFilterState ? 426 : 286;
  const headerAndFooterHeight = pageInfo?.totalPages > 1 ? 200 : 140; // if we show pagination, we need to add extra height
  const customTableNegativeHeight = `${height + headerAndFooterHeight}px`;

  return (
    <TableStarterContextProvider value={value}>
      <Box position="relative" display="flex" flexDirection="column" width="100%" height="100%" bgcolor="transparent">
        <Box padding="16px 32px">
          <Header primaryText="disputes" />
        </Box>

        <Box display="flex" flexDirection="column" flexGrow="" p="0px 32px 16px 24px" bgcolor="#fff">
          <Box width="100%" mb="24px">
            <DisputeStats />
          </Box>
          <Table
            data={unionRows ?? []}
            loading={loading}
            isEmpty={isEmpty}
            pageInfo={pageInfo}
            headCells={headCells}
            initialTableState={INITIAL_TABLE_STATE}
            transformHiddenColumnsFn={transformHiddenColumnsFn}
            customTableNegativeHeight={customTableNegativeHeight}
            customDetailsPathname={CUSTOM_DETAILS_PATHNAME}
            customDetailsId={CUSTOM_DETAILS_ID}
            customDetailsPathParam="#dispute"
          />
        </Box>
      </Box>
    </TableStarterContextProvider>
  );
};

export default Disputes;
