import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Form, Formik } from 'formik';
import { NotificationManager } from 'react-notifications';
import { shape, func } from 'prop-types';
import { P14 } from '../../atoms';
import THEME from '../../../constants/theme';
import { formFields, initialValues } from './schema';

import { FORGOT_PASSWORD } from '../../../utils/queries/customer/customerMutations';
import SubmitButton from '../_common/SubmitButton';
import InputOnboarding from '../_common/InputOnboarding';
import validator from './validator';
import ToastCustomContainer from '../../ToastCustomContainer';
import { TOAST_TIMEOUT } from '../../../constants/toasts';

const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD);

  const notifyUser = (values, data) => {
    if (data.errors) {
      const { code } = data.errors[0].extensions;
      const message = t(code);

      NotificationManager.error(<ToastCustomContainer message={message} title="Failed." />, null, TOAST_TIMEOUT);

      return;
    }

    const followInstructions = t('forgotPasswordForm.notification.followInstructions');
    const toReset = t('forgotPasswordForm.notification.toReset');
    const title = t('forgotPasswordForm.notification.successTitle');
    const message = `${followInstructions} ${values.email} ${toReset}`;

    NotificationManager.success(<ToastCustomContainer message={message} title={title} />, null, TOAST_TIMEOUT);
  };

  const confirm = async (values) => {
    const data = await forgotPassword({
      variables: {
        email: values.email,
      },
    });

    notifyUser(values, data);
  };

  const handleKeyDown = (key) => {
    if (key === 'Enter') {
      confirm();
    }
  };

  return (
    <>
      <h1>{t('forgotPasswordForm.forgotYourPassword')}</h1>
      <P14 margin="0 0 40px" color={THEME.greyColors.grey9}>
        {t('forgotPasswordForm.enterYourEmail')}
      </P14>
      <Formik validate={validator} onSubmit={confirm} validateOnBlur initialValues={initialValues}>
        <Form>
          {formFields.map(({ field, label, ...rest }) => (
            <InputOnboarding
              variant="outlined"
              type="email"
              name={field}
              label={label}
              autoFocus
              root={{ margin: '4px 0 13px' }}
              onKeyPress={(event) => handleKeyDown(event.key)}
              key={field}
              {...rest}
            />
          ))}
          <Box mt="24px">
            <SubmitButton
              className="gradient"
              margin="44px 0 0 0"
              isLoading={loading}
              onKeyPress={(event) => handleKeyDown(event.key)}
              showIcon={false}
              width="100%"
            >
              {t('common.continueText')}
            </SubmitButton>
          </Box>
        </Form>
      </Formik>
    </>
  );
};

ForgotPasswordForm.propTypes = {
  errors: shape({}),
  values: shape({}),
  handleChange: func,
  handleBlur: func,
};

ForgotPasswordForm.defaultProps = {
  errors: null,
  values: null,
  handleChange: null,
  handleBlur: null,
};

export default ForgotPasswordForm;
