import React, { useState } from 'react';
import { string, func } from 'prop-types';
import { StyledSearchBar, StyledInput } from './styled';
import SearchMedium from '../../../../../../assets/icons/SearchMedium';

const SearchBar = ({ search, setSearch }) => {
  const [, setSearchFocused] = useState(false);

  const handleSearchFilter = (e) => {
    const { value } = e.target;

    setSearch(value);
  };

  return (
    <StyledSearchBar>
      <StyledInput
        type="text"
        onFocus={() => setSearchFocused(true)}
        onBlur={() => setSearchFocused(false)}
        placeholder="Search ..."
        value={search}
        onChange={handleSearchFilter}
      />
      <SearchMedium />
    </StyledSearchBar>
  );
};

SearchBar.propTypes = {
  search: string.isRequired,
  setSearch: func.isRequired,
};

export default SearchBar;
