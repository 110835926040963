import PropTypes from 'prop-types';
import React from 'react';

const UploadIcon = ({ fill }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.41667 11.1667C6.34535 11.1667 5.2981 11.4844 4.40733 12.0796C3.51656 12.6748 2.8223 13.5208 2.41232 14.5105C2.00235 15.5003 1.89508 16.5894 2.10408 17.6402C2.31309 18.6909 2.82897 19.656 3.58651 20.4136C4.34404 21.1711 5.3092 21.687 6.35993 21.896C7.41066 22.105 8.49977 21.9977 9.48954 21.5878C10.4793 21.1778 11.3253 20.4835 11.9205 19.5928C12.5157 18.702 12.8333 17.6547 12.8333 16.5834C12.8316 15.1474 12.2603 13.7706 11.2449 12.7552C10.2294 11.7398 8.85272 11.1685 7.41667 11.1667ZM9.40917 15.9067C9.45814 15.9681 9.48879 16.042 9.4976 16.12C9.50642 16.1979 9.49303 16.2768 9.45899 16.3475C9.42494 16.4182 9.37162 16.4779 9.30516 16.5196C9.2387 16.5613 9.16181 16.5835 9.08333 16.5834H8.45833C8.40308 16.5834 8.35009 16.6054 8.31102 16.6444C8.27195 16.6835 8.25 16.7365 8.25 16.7917V19.0834C8.25 19.3044 8.1622 19.5164 8.00592 19.6727C7.84964 19.8289 7.63768 19.9167 7.41667 19.9167C7.19565 19.9167 6.98369 19.8289 6.82741 19.6727C6.67113 19.5164 6.58333 19.3044 6.58333 19.0834V16.7917C6.58333 16.7365 6.56139 16.6835 6.52232 16.6444C6.48325 16.6054 6.43025 16.5834 6.375 16.5834H5.75C5.67153 16.5835 5.59463 16.5613 5.52818 16.5196C5.46172 16.4779 5.4084 16.4182 5.37435 16.3475C5.3403 16.2768 5.32692 16.1979 5.33573 16.12C5.34454 16.042 5.3752 15.9681 5.42417 15.9067L7.09084 13.8234C7.13128 13.7767 7.18131 13.7392 7.23753 13.7135C7.29375 13.6878 7.35485 13.6744 7.41667 13.6744C7.47849 13.6744 7.53958 13.6878 7.5958 13.7135C7.65203 13.7392 7.70206 13.7767 7.7425 13.8234L9.40917 15.9067Z"
        fill={fill}
      />
      <path
        d="M22 5.67833C21.9999 5.23634 21.8243 4.81249 21.5117 4.5L19.5 2.48833C19.3452 2.33346 19.1613 2.21062 18.959 2.12682C18.7567 2.04303 18.5398 1.99994 18.3208 2H8.66667C8.22465 2 7.80072 2.17559 7.48816 2.48816C7.1756 2.80072 7.00001 3.22464 7.00001 3.66667V9.72917C6.99955 9.78341 7.02045 9.83566 7.05817 9.87463C7.0959 9.91361 7.14744 9.93619 7.20167 9.9375C7.50667 9.9425 8.06667 9.965 8.43917 10.0067C8.46806 10.0099 8.4973 10.0069 8.52495 9.9979C8.5526 9.98893 8.57802 9.97419 8.59954 9.95464C8.62105 9.9351 8.63816 9.9112 8.64974 9.88454C8.66131 9.85788 8.66709 9.82906 8.66667 9.8V4.08333C8.66667 3.97283 8.71057 3.86685 8.78871 3.78871C8.86685 3.71057 8.97283 3.66667 9.08334 3.66667H18.1492C18.2595 3.66669 18.3653 3.71044 18.4433 3.78833L20.2117 5.55667C20.2896 5.63475 20.3333 5.74054 20.3333 5.85083V17C20.3333 17.1105 20.2894 17.2165 20.2113 17.2946C20.1332 17.3728 20.0272 17.4167 19.9167 17.4167H14.2017C14.1528 17.417 14.1056 17.4348 14.0688 17.4669C14.0319 17.499 14.0078 17.5433 14.0008 17.5917C13.9383 18.0053 13.8367 18.4121 13.6975 18.8067C13.6858 18.8375 13.6817 18.8706 13.6856 18.9034C13.6895 18.9361 13.7013 18.9674 13.72 18.9945C13.7387 19.0217 13.7637 19.0438 13.7929 19.0592C13.8221 19.0745 13.8545 19.0825 13.8875 19.0825H20.3333C20.7754 19.0825 21.1993 18.9069 21.5118 18.5943C21.8244 18.2818 22 17.8579 22 17.4158V5.67833Z"
        fill={fill}
      />
    </svg>
  );
};

UploadIcon.propTypes = {
  fill: PropTypes.string,
};
UploadIcon.defaultProps = {
  fill: '#999999',
};

export default UploadIcon;
