import React from 'react';
import { number, string } from 'prop-types';
import THEME from '../../constants/theme';

const LockOutlined = ({ size, fill }) => (
  <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 6C2.22386 6 2 6.22386 2 6.5V10C2 10.2761 2.22386 10.5 2.5 10.5H9.5C9.77614 10.5 10 10.2761 10 10V6.5C10 6.22386 9.77614 6 9.5 6H2.5ZM1 6.5C1 5.67157 1.67157 5 2.5 5H9.5C10.3284 5 11 5.67157 11 6.5V10C11 10.8284 10.3284 11.5 9.5 11.5H2.5C1.67157 11.5 1 10.8284 1 10V6.5Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 1.5C5.46957 1.5 4.96086 1.71071 4.58579 2.08579C4.21071 2.46086 4 2.96957 4 3.5V5.5C4 5.77614 3.77614 6 3.5 6C3.22386 6 3 5.77614 3 5.5V3.5C3 2.70435 3.31607 1.94129 3.87868 1.37868C4.44129 0.81607 5.20435 0.5 6 0.5C6.79565 0.5 7.55871 0.81607 8.12132 1.37868C8.68393 1.94129 9 2.70435 9 3.5V5.5C9 5.77614 8.77614 6 8.5 6C8.22386 6 8 5.77614 8 5.5V3.5C8 2.96957 7.78929 2.46086 7.41421 2.08579C7.03914 1.71071 6.53043 1.5 6 1.5Z"
      fill={fill}
    />
  </svg>
);

LockOutlined.propTypes = {
  size: number,
  fill: string,
};

LockOutlined.defaultProps = {
  size: 12,
  fill: THEME.secondaryColors.orangeLight,
};

export default LockOutlined;
