import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import { useAsync } from 'react-use';
import { useTranslation } from 'react-i18next';
import { StyledDialogContent } from '../../Dialog/styled';
import { StyledAnimationBox } from '../../LoadingAnimation/styled';
import LoadingAnimation from '../../LoadingAnimation';
import { SWITCH_ENV_STEPS } from '../constant';
import { useGlobalContext } from '../../../containers/App/context';

const LoadingStep = ({ selectedAutomationIds, migrateFlowsToProduction, loading }) => {
  const { t } = useTranslation();
  const { setCurrentSwitchEnvStep } = useGlobalContext();
  const [isTimeOutPassed, setIsTimeOutPassed] = useState(false);

  useAsync(async () => {
    setTimeout(() => setIsTimeOutPassed(true), 3000);
    if (selectedAutomationIds?.length) {
      await migrateFlowsToProduction({
        variables: {
          flowIds: selectedAutomationIds,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (isTimeOutPassed && !loading) {
      setCurrentSwitchEnvStep(SWITCH_ENV_STEPS.SUCCESS);
    }
  }, [isTimeOutPassed, loading, setCurrentSwitchEnvStep]);

  return (
    <StyledDialogContent px="0">
      <StyledAnimationBox>
        <LoadingAnimation text={t('Migrating your Automations')} />
      </StyledAnimationBox>
    </StyledDialogContent>
  );
};

LoadingStep.propTypes = {
  selectedAutomationIds: arrayOf(string),
  migrateFlowsToProduction: func.isRequired,
  loading: bool,
};

LoadingStep.defaultProps = {
  selectedAutomationIds: [],
  loading: false,
};

export default LoadingStep;
