import { withStyles } from '@mui/styles';
import Slider from '@mui/material/Slider';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import THEME from '../../../../../../../constants/theme';

export const StyledTestSlider = withStyles({
  root: {
    color: THEME.greyColors.grey300,
    height: 2,
  },
  thumb: {
    height: 48,
    width: 48,
    backgroundColor: THEME.primaryColors.blue,
    border: '6px solid #fff',
    marginTop: -24,
    marginLeft: -24,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
    '& svg': {
      transform: 'rotate(90deg)',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 2,
    borderRadius: 2,
  },
  rail: {
    height: 2,
    opacity: 1,
    borderRadius: 2,
  },
})(Slider);

export const StyledPercentBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px !important;
`;
