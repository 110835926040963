import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, capitalize } from '@mui/material';
import OnboardingLayout from '../../components/layouts/OnboardingLayout';
import OnboardingLayoutBox from '../../components/layouts/OnboardingLayoutBox';
import SignInForm from '../../components/forms/SignInForm';
import { PUBLIC_SITE_ROUTES } from '../../constants/routes';
import { StyledExternalLink, StyledSnackbar, SnackbarWrapper, StyledButton } from './styled';
import { BlockWrap, P14 } from '../../components/atoms';
import THEME from '../../constants/theme';
import GoogleAuth from '../../components/GoogleAuth';
import { LineOr } from '../SignUp/styled';
import { GOOGLE_AUTH_TYPE } from '../../hooks/useGoogleOAuth';
import { useSignInWithGoogleCallback } from './useSignInWithGoogleCallback';
import Switcher from './Switcher';
import { APP_ENVIRONMENT, PROD_URL, SANDBOX_URL } from '../../constants/api';
import { ENVIRONMENT } from '../../constants/environment';

const isProd = APP_ENVIRONMENT === ENVIRONMENT.prod;

const SignIn = memo(() => {
  const { t } = useTranslation();
  const { loading } = useSignInWithGoogleCallback();
  const [enviornment] = useState(isProd ? ENVIRONMENT.prod : ENVIRONMENT.sandbox);
  const [incorrectLoginCount, setIncorrectLoginCount] = useState(0);
  const [open, setOpen] = useState(incorrectLoginCount > 1);

  const toastMessage = (
    <p>
      You’re trying to sign into {capitalize(enviornment)},{' '}
      <span className="greyText">
        are you trying to sign into{' '}
        {enviornment === ENVIRONMENT.sandbox ? capitalize(ENVIRONMENT.prod) : capitalize(ENVIRONMENT.sandbox)}?
      </span>
    </p>
  );

  useEffect(() => {
    if (incorrectLoginCount > 1) {
      setOpen(true);
    }
  }, [incorrectLoginCount]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSwitchEnv = () => {
    return enviornment === ENVIRONMENT.sandbox ? window.open(PROD_URL, '_self') : window.open(SANDBOX_URL, '_self');
  };

  const action = (
    <>
      <StyledButton variant="text" color="secondary" onClick={handleClose}>
        {t('Dismiss')}
      </StyledButton>
      <StyledButton variant="contained" color="primary" onClick={handleSwitchEnv}>
        {t(
          `Switch to ${
            enviornment === ENVIRONMENT.sandbox ? capitalize(ENVIRONMENT.prod) : capitalize(ENVIRONMENT.sandbox)
          }`
        )}
      </StyledButton>
    </>
  );

  return (
    <>
      <OnboardingLayout
        navigationRight={
          <P14 color={THEME.greyColors.grey9}>
            {t('common.dontHaveAccount')}

            <StyledExternalLink href={PUBLIC_SITE_ROUTES.paygeek} className="text-primary">
              {t('Register')}
            </StyledExternalLink>
          </P14>
        }
      >
        <OnboardingLayoutBox>
          <Box maxWidth="336px" margin="0 auto">
            <h1>{t('Sign into')}</h1>
            <Switcher enviornment={enviornment} />

            <BlockWrap margin="40px 0 20px 0">
              <GoogleAuth type={GOOGLE_AUTH_TYPE.login} loading={loading}>
                {t('Sign in with Google')}
              </GoogleAuth>
            </BlockWrap>
            <LineOr>{t('or')}</LineOr>
            <SignInForm setIncorrectLoginCount={setIncorrectLoginCount} />
          </Box>
        </OnboardingLayoutBox>
      </OnboardingLayout>

      <SnackbarWrapper open={open}>
        <StyledSnackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          message={toastMessage}
          action={action}
        />
      </SnackbarWrapper>
    </>
  );
});

export default SignIn;
