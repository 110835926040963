import React, { useEffect, useState } from 'react';
import { AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import { useMutation, useQuery } from '@apollo/client';
import { func } from 'prop-types';
import { CloseButton, StyledAccordion, StyledAccordionSummary, StyledWrapper } from './styled';
import { H5, P16M } from '../../../atoms';
import THEME from '../../../../constants/theme';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import { ExternalLink } from '../../../../pages/FlowDetailsPage/Sidebar/styled';
import ArrowUpRight from '../../../../assets/icons/ArrowUpRight';
import PeopleModal from '../../../modals/PeopleModal';
import { UPDATE_USER_ROLE } from '../../../../utils/queries/users/usersMutations';
import { PEOPLE_PAGE } from '../../../../utils/queries/billing';
import { useLocalStorageState } from '../../../../hooks/storage';
import { SETUP_GUIDE_KEY } from '../../constants';
import { Close } from '../../../../assets/icons';

const DOCUMENTATION_URL = process.env.REACT_APP_DEV_ZONE_URL;

const STEPS = [
  {
    title: 'Step 1',
    Description: StepOne,
    links: [
      {
        title: 'Learn more about the No-Code Canvas',
        uri: `${DOCUMENTATION_URL}/no-code`,
      },
    ],
  },
  {
    title: 'Step 2',
    Description: StepThree,
    links: [
      {
        title: 'Checkout Documentation',
        uri: `${DOCUMENTATION_URL}/checkout`,
      },
      {
        title: 'Invite Developer',
        redirect: '/settings/directory',
      },
    ],
  },
  {
    title: 'Step 3',
    Description: StepFour,
    links: [
      {
        title: 'Payment API Documentation',
        uri: `${DOCUMENTATION_URL}/payments`,
      },
      {
        title: 'Invite Developer',
        redirect: '/settings/directory',
      },
    ],
  },
  {
    title: 'Step 4 (Optional)',
    Description: StepTwo,
    links: [
      {
        title: 'Intent API Documentation',
        uri: `${DOCUMENTATION_URL}/intent`,
      },
      {
        title: 'Invite Developer',
        redirect: '/settings/directory',
      },
    ],
  },
];

const SetupGuide = ({ closeModal }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState('step0');
  const [modalOpen, toggleModal] = useToggle(false);
  const [updateUser] = useMutation(UPDATE_USER_ROLE);
  const { refetch: userRefetch } = useQuery(PEOPLE_PAGE);
  const [setupGuideDefaultState, setSetupGuideDefaultState] = useLocalStorageState(SETUP_GUIDE_KEY, false);

  useEffect(() => {
    if (!setupGuideDefaultState) {
      setSetupGuideDefaultState(true);
    }
  }, [setSetupGuideDefaultState, setupGuideDefaultState]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : null);
  };

  return (
    <>
      <StyledWrapper position="absolute" width={400} right={32} bottom={84} zIndex={10} overflow="visible">
        <Box position="relative" width="100%" height="100%">
          <CloseButton onClick={closeModal}>
            <Close />
          </CloseButton>
          <Box p="24px" bgcolor={THEME.primaryColors.white} borderBottom={`1px solid ${THEME.greyColors.grey16}`}>
            <H5>{t('Setup Guide')}</H5>
          </Box>
          {STEPS.map(({ title, Description, links }, index) => (
            <StyledAccordion
              elevation={0}
              expanded={expanded === `step${index}`}
              onChange={handleChange(`step${index}`)}
              key={title}
              square
            >
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`step${index}bh-content`}
                id={`step${index}bh-header`}
              >
                <P16M>{title}</P16M>
              </StyledAccordionSummary>
              <AccordionDetails>
                <Box>
                  <Description />
                  {links.map((link, linkIndex) => (
                    <Box display="flex" mb={linkIndex === links.length - 1 ? '0' : '4px'}>
                      <ExternalLink
                        href={link.uri}
                        target="_blank"
                        noUnderline
                        fontSize="14px"
                        color="#545A61"
                        {...(link.redirect && { onClick: () => toggleModal() })}
                      >
                        {link?.title}
                        <ArrowUpRight />
                      </ExternalLink>
                    </Box>
                  ))}
                </Box>
              </AccordionDetails>
            </StyledAccordion>
          ))}
        </Box>
      </StyledWrapper>
      <PeopleModal
        open={modalOpen}
        setShowModal={toggleModal}
        userRefetch={userRefetch}
        modalType="add"
        updateUser={updateUser}
      />
    </>
  );
};

SetupGuide.propTypes = {
  closeModal: func.isRequired,
};

export default SetupGuide;
