import { makeStyles } from '@mui/styles';
import THEME from '../../../../../constants/theme';

const useStyles = makeStyles(() => ({
  ul: {
    '& li button': {
      color: THEME.greyColors.grey600,

      '&:hover': {
        backgroundColor: THEME.greyColors.grey21,
      },

      '&.Mui-selected': {
        color: THEME.primaryColors.blackMain,
        backgroundColor: 'transparent',
        cursor: 'default',

        '&:hover': {
          backgroundColor: 'transparent',
        },
      },

      '&.Mui-disabled': {
        color: THEME.greyColors.grey200,
        opacity: '1',

        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
}));

export { useStyles };
