import React from 'react';
import { number, string } from 'prop-types';
import THEME from '../../constants/theme';

const ArrowUpMessage = ({ size, color }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0001 3.33398C10.4603 3.33398 10.8334 3.70708 10.8334 4.16732V15.834C10.8334 16.2942 10.4603 16.6673 10.0001 16.6673C9.53984 16.6673 9.16675 16.2942 9.16675 15.834V4.16732C9.16675 3.70708 9.53984 3.33398 10.0001 3.33398Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.41066 3.57806C9.7361 3.25263 10.2637 3.25263 10.5892 3.57806L16.4225 9.4114C16.7479 9.73683 16.7479 10.2645 16.4225 10.5899C16.0971 10.9153 15.5694 10.9153 15.244 10.5899L9.99992 5.34583L4.75584 10.5899C4.4304 10.9153 3.90277 10.9153 3.57733 10.5899C3.25189 10.2645 3.25189 9.73683 3.57733 9.4114L9.41066 3.57806Z"
      fill={color}
    />
  </svg>
);

ArrowUpMessage.propTypes = {
  size: number,
  color: string,
};

ArrowUpMessage.defaultProps = {
  size: 20,
  color: THEME.primaryColors.l50,
};

export default ArrowUpMessage;
