import React from 'react';
import { shape, bool, func } from 'prop-types';
import { useFormikContext } from 'formik';
import ExternalSelect from '../../../ExternalSelect';

const ExternalSelectWrapper = ({ item, showErrors, setShowErrors }) => {
  const { values, errors, setFieldValue } = useFormikContext();

  return (
    <ExternalSelect
      showErrors={showErrors}
      errors={errors}
      item={item}
      setShowErrors={setShowErrors}
      setFieldValue={setFieldValue}
      values={values}
      valueFromEdit
    />
  );
};

ExternalSelectWrapper.propTypes = {
  item: shape({}).isRequired,
  showErrors: bool,
  setShowErrors: func.isRequired,
};

ExternalSelectWrapper.defaultProps = {
  showErrors: false,
};

export default ExternalSelectWrapper;
