import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFeature } from '../../../hooks/useFeature';
import { FEATURE_TOGGLES_KEYS } from '../../../constants/featureToggles';
import { useLocalStorageState } from '../../../hooks/storage';
import { UI_ROUTES } from '../../../constants/routes';
import useSearch from '../../../hooks/useSearch';
import { getFirstErrorMessage } from '../../../utils/graphql';
import { useUpdateConnectionData } from './useUpdateConnectionData';
import { REFETCH_CONNECTIONS_LIST_FLAG, CONNECTION_TOAST_TITLE } from './constant';
import { TOAST_TIMEOUT } from '../../../constants/toasts';
import { showNotification } from '../../../hooks/useNotificationManager';
import { CONNECTION_STATUS } from '../components/constant';

const SERVER_ERROR_TOAST_MESSAGE = 'Server error.';

const STORAGE_CONNECTION_ID_KEY = 'wt-storage-connection-id-key';
const WT_OAUTH_KEY = 'wt-0auth-payload-key';

const PAYPAL_APM_KEY = 'wt-apm';

const useHandleOauthRedirect = ({ connection, togglePaymentMethodDialog }) => {
  const [multipleFlowEnabled] = useFeature(FEATURE_TOGGLES_KEYS.MULTIPLE_FLOW);
  const [searchParams, setSearchParams] = useSearch();
  const { t } = useTranslation();
  const { saveCredentials } = useUpdateConnectionData(connection?.id);
  const location = useLocation();
  const { push } = useHistory();
  const [storageConnectionId, , removeStorageConnectionId] = useLocalStorageState(STORAGE_CONNECTION_ID_KEY, '');

  useEffect(() => {
    //unique searchParam to identify paypal
    const isPaypal = searchParams.merchantIdInPayPal;

    if ((window.opener || isPaypal) && searchParams?.error) {
      //handle paypal error with a toast
      if (isPaypal) {
        const errorMessage = searchParams?.error?.split?.('_')?.join(' ');
        showNotification('error', errorMessage ?? SERVER_ERROR_TOAST_MESSAGE, CONNECTION_TOAST_TITLE, TOAST_TIMEOUT);

        return;
      }
      const payload = JSON.stringify({
        success: false,
        res: {},
        error: searchParams?.error,
        key: WT_OAUTH_KEY,
      });

      window.opener.postMessage(payload, window.origin);
      return;
    }

    if (
      (searchParams.code && searchParams.state) ||
      (searchParams.state && searchParams.queryString) ||
      (isPaypal && searchParams.permissionsGranted)
    ) {
      saveCredentials({
        variables: {
          id: searchParams.state || storageConnectionId,
          queryString: searchParams.queryString ? searchParams.queryString : location.search.replace('?', ''),
        },
      })
        .then((res) => {
          if (window.opener) {
            const payload = JSON.stringify({
              success: true,
              res: res?.data?.exchangeConnectionToken ?? {},
              key: WT_OAUTH_KEY,
              ...(!isEmpty(res?.errors) && { errors: res?.errors }),
            });

            window.opener.postMessage(payload, window.origin);
          }

          //handle paypal success state
          if (isPaypal) {
            const connectionId = res?.data?.exchangeConnectionToken?.id || storageConnectionId;

            if (res?.data?.exchangeConnectionToken.status === CONNECTION_STATUS.CONNECTED) {
              const connectionSteps = res?.data?.exchangeConnectionToken?.steps ?? [];
              const hasApmForm = connectionSteps.some((connectionStep) => connectionStep?.type === 'APM');
              //redirect to connection details
              push(`${UI_ROUTES.connectionDetails}/${connectionId}${hasApmForm ? `?${PAYPAL_APM_KEY}=true` : ''}`);

              //flag to refresh connections list
              localStorage.setItem(REFETCH_CONNECTIONS_LIST_FLAG, true);

              showNotification(
                'success',
                'Paypal has been successfully connected.',
                CONNECTION_TOAST_TITLE,
                TOAST_TIMEOUT
              );

              return;
            }

            showNotification(
              'error',
              getFirstErrorMessage(res?.errors) || `There was an error while connecting Paypal`,
              CONNECTION_TOAST_TITLE,
              TOAST_TIMEOUT
            );
          }
        })
        .finally(() => {
          removeStorageConnectionId();
        });
    }
  }, [searchParams, location, saveCredentials, push, t, storageConnectionId, removeStorageConnectionId]);

  //handle APM experience for Paypal
  useEffect(() => {
    if (searchParams[PAYPAL_APM_KEY] && multipleFlowEnabled) {
      togglePaymentMethodDialog();
    }
  }, [searchParams, setSearchParams, togglePaymentMethodDialog, multipleFlowEnabled]);
};

export { useHandleOauthRedirect };
