import React from 'react';
import { string } from 'prop-types';
import THEME from '../../../constants/theme';

const CheckIcon2 = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4857 7.15128C18.9543 7.61991 18.9543 8.3797 18.4857 8.84833L10.4857 16.8483C10.017 17.317 9.25725 17.317 8.78862 16.8483L5.15225 13.212C4.68362 12.7433 4.68362 11.9835 5.15225 11.5149C5.62088 11.0463 6.38068 11.0463 6.84931 11.5149L9.63715 14.3027L16.7886 7.15128C17.2572 6.68265 18.017 6.68265 18.4857 7.15128Z"
        fill={fill}
      />
    </svg>
  );
};

CheckIcon2.propTypes = {
  fill: string,
  width: string,
  height: string,
};

CheckIcon2.defaultProps = {
  fill: THEME.primaryColors.primary,
  width: '24',
  height: '24',
};

export default CheckIcon2;
