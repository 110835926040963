import { useCallback } from 'react';
import { updateEdge } from 'react-flow-renderer';
import omit from 'lodash/omit';
import { repopulateEdges } from '../utils/facades';
import { flowStepConditionTypes, flowStepTypes } from '../types';

/**
 * @param {Function} setElementsAndSave
 * */
export const useOnEdgeUpdate = (setElementsAndSave) =>
  useCallback(
    (oldEdge, newConnection) => {
      return setElementsAndSave((nodes) => {
        const oldSourceNode = nodes.find((node) => node.id === oldEdge?.source);
        const oldSourceNodeIndex = nodes.findIndex((node) => node.id === oldEdge?.source);
        const oldSourceTypename = oldSourceNode?.data?.__typename;
        if (oldSourceNodeIndex < 0) {
          return nodes;
        }
        const newNodes = [...nodes];
        const isOldSourceCondition =
          oldSourceTypename === flowStepTypes.DataConditionStep ||
          oldSourceTypename === flowStepTypes.CountConditionStep;
        const isOldSourceABConditionNode = oldSourceTypename === flowStepTypes.ABTestConditionStep;

        const conditions = newNodes[oldSourceNodeIndex].data?.conditions || [];
        const isElseOldEdge = oldEdge.id?.includes('e-else-');

        if (oldEdge?.target !== newConnection?.target) {
          const newConditions = conditions.map((c) =>
            c.nextStepId !== oldEdge?.target ? c : { ...c, nextStepId: newConnection?.target }
          );
          newNodes[oldSourceNodeIndex] = {
            ...newNodes[oldSourceNodeIndex],
            data: {
              ...newNodes[oldSourceNodeIndex].data,
              layout: {
                ...newNodes[oldSourceNodeIndex].data?.layout,
                uiMetadata: {
                  ...omit(newNodes[oldSourceNodeIndex].data?.layout?.uiMetadata, oldEdge?.target),
                  [newConnection?.target]: {
                    sourceHandle: newConnection?.sourceHandle,
                    targetHandle: newConnection?.targetHandle,
                  },
                },
              },
            },
          };
          if (isOldSourceCondition) {
            if (isElseOldEdge) {
              newNodes[oldSourceNodeIndex].data.elseStepId = newConnection?.target;
            } else {
              newNodes[oldSourceNodeIndex].data.conditions = newConditions;
            }
          } else if (isOldSourceABConditionNode) {
            const isABranchChanged = newNodes[oldSourceNodeIndex].data.aBranchCondition?.nextStepId === oldEdge?.target;
            const branchKey = isABranchChanged ? 'aBranchCondition' : 'bBranchCondition';
            const branchCondition = newNodes[oldSourceNodeIndex].data[branchKey];
            newNodes[oldSourceNodeIndex].data[branchKey] = { ...branchCondition, nextStepId: newConnection?.target };
          } else {
            newNodes[oldSourceNodeIndex].data.nextStepId = newConnection?.target;
          }
        }
        if (oldEdge?.source !== newConnection?.source) {
          newNodes[oldSourceNodeIndex] = {
            ...newNodes[oldSourceNodeIndex],
            data: {
              ...newNodes[oldSourceNodeIndex].data,
              layout: {
                ...newNodes[oldSourceNodeIndex].data?.layout,
                uiMetadata: {
                  ...omit(newNodes[oldSourceNodeIndex].data?.layout?.uiMetadata, oldEdge?.target),
                },
              },
            },
          };
          if (isOldSourceCondition) {
            if (isElseOldEdge) {
              newNodes[oldSourceNodeIndex].data.elseStepId = null;
            } else {
              newNodes[oldSourceNodeIndex].data.conditions = [];
            }
          } else if (isOldSourceABConditionNode) {
            const isABranchChanged = newNodes[oldSourceNodeIndex].data.aBranchCondition?.nextStepId === oldEdge?.target;
            const branchKey = isABranchChanged ? 'aBranchCondition' : 'bBranchCondition';
            const branchCondition = newNodes[oldSourceNodeIndex].data[branchKey];
            newNodes[oldSourceNodeIndex].data[branchKey] = { ...branchCondition, nextStepId: null };
          } else {
            newNodes[oldSourceNodeIndex].data.nextStepId = null;
          }
          const newSourceNode = nodes.find((node) => node.id === newConnection?.source);
          const newSourceNodeIndex = nodes.findIndex((node) => node.id === newConnection?.source);
          const newSourceTypename = newSourceNode?.data?.__typename;
          const isNewSourceCondition = flowStepConditionTypes.includes(newSourceTypename);
          const isNewSourceABConditionNode = newSourceTypename === flowStepTypes.ABTestConditionStep;
          const isNewSourceHasABranch = isNewSourceABConditionNode && newSourceNode.data.aBranchCondition.nextStepId;
          const isNewSourceHasBBranch = isNewSourceABConditionNode && newSourceNode.data.bBranchCondition.nextStepId;
          const newConditions = conditions.map((c) => ({
            label: null,
            nextStepId: c.nextStepId === oldEdge?.target ? newConnection?.target : c.nextStepId,
            op: null,
            right: null,
          }));
          newNodes[newSourceNodeIndex] = {
            ...newNodes[newSourceNodeIndex],
            data: {
              ...newNodes[newSourceNodeIndex].data,
              ...(!isOldSourceCondition &&
                !isNewSourceCondition &&
                !isNewSourceABConditionNode && { nextStepId: newConnection?.target }),
              ...(isOldSourceCondition &&
                !isNewSourceCondition &&
                !isNewSourceABConditionNode && { nextStepId: newConnection?.target }),
              ...(!isOldSourceCondition &&
                isNewSourceCondition && {
                  conditions: [{ label: null, nextStepId: newConnection?.target, op: null, right: null }],
                }),
              ...(isOldSourceCondition &&
                isNewSourceCondition &&
                isElseOldEdge && { elseStepId: newConnection?.target }),
              ...(isOldSourceCondition && isNewSourceCondition && !isElseOldEdge && { conditions: newConditions }),
              ...(isNewSourceABConditionNode &&
                isNewSourceHasABranch &&
                !isNewSourceHasBBranch && {
                  bBranchCondition: {
                    ...newNodes[newSourceNodeIndex].data.bBranchCondition,
                    nextStepId: newConnection.target,
                  },
                }),
              ...(isNewSourceABConditionNode &&
                !isNewSourceHasABranch &&
                isNewSourceHasBBranch && {
                  aBranchCondition: {
                    ...newNodes[newSourceNodeIndex].data.aBranchCondition,
                    nextStepId: newConnection.target,
                  },
                }),
              ...(isNewSourceABConditionNode &&
                !isNewSourceHasABranch &&
                !isNewSourceHasBBranch && {
                  aBranchCondition: {
                    ...newNodes[newSourceNodeIndex].data.aBranchCondition,
                    nextStepId: newConnection.target,
                  },
                }),
              layout: {
                ...newNodes[oldSourceNodeIndex].data?.layout,
                uiMetadata: {
                  ...omit(newNodes[oldSourceNodeIndex].data?.layout?.uiMetadata, oldEdge?.target),
                  [newConnection?.target]: {
                    sourceHandle: newConnection?.sourceHandle,
                    targetHandle: newConnection?.targetHandle,
                  },
                },
              },
            },
          };
        }
        updateEdge(oldEdge, newConnection, newNodes);
        return repopulateEdges(newNodes);
      });
    },
    [setElementsAndSave]
  );
