import React from 'react';
import { string, shape, arrayOf, bool, elementType } from 'prop-types';
import { InputLabel } from '@mui/material';
import { useField } from 'formik';
import { StyledPaper, StyledMenuItem, CustomSelect, StyledFormControl } from '../../atoms/Select/StyledSelect';
import THEME from '../../../constants/theme';
import { P } from '../../atoms';
import CheckIcon from '../../../assets/icons/Select/CheckIcon';
import SelectDropdownIcon from './SelectDropdownIcon';
import { StyledError } from './styled';

const StyledSelect = ({
  paperComponent,
  name,
  options,
  label,
  placeholder,
  errorProps,
  multiple,
  disabled,
  ...restProps
}) => {
  const [{ value, onBlur, onChange }, { touched, error }] = useField(name);
  const { renderValue, ...formControlProps } = restProps;

  return (
    <>
      <StyledFormControl variant="outlined" fullWidth {...formControlProps} disabled={disabled}>
        {label && (
          <InputLabel id={`${name}-label`} shrink>
            {label}
          </InputLabel>
        )}
        <CustomSelect
          labelId={`${name}-label`}
          id={name}
          name={name}
          IconComponent={SelectDropdownIcon}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          MenuProps={{
            getContentAnchorEl: null,
            PaperProps: {
              component: paperComponent ?? StyledPaper,
            },
          }}
          displayEmpty
          multiple={multiple}
          renderValue={
            value !== '' ? undefined : () => placeholder && <P color={THEME.greyColors.grey}>{placeholder}</P>
          }
          hoverbg="rgba(193, 195, 198, 0.2)"
          {...restProps}
        >
          {placeholder && (
            <StyledMenuItem value="" disabled>
              {placeholder}
            </StyledMenuItem>
          )}

          {options.map((option) => {
            return (
              <StyledMenuItem key={option.value} value={option.value} $justifyContent="space-between">
                {option.icon || null}
                {option.text.text ? option.text.text : ''}
                <CheckIcon />
              </StyledMenuItem>
            );
          })}
        </CustomSelect>
      </StyledFormControl>

      {touched &&
        error?.map((message) => (
          <StyledError key={message} data-cy="error" color={THEME.secondaryColors.danger} {...errorProps}>
            {message}
          </StyledError>
        ))}
    </>
  );
};

StyledSelect.propTypes = {
  name: string,
  options: arrayOf(shape({})),
  label: string,
  placeholder: string,
  paperComponent: elementType,
  errorProps: shape({}),
  multiple: bool,
  disabled: bool,
};

StyledSelect.defaultProps = {
  name: '',
  options: [],
  label: '',
  placeholder: '',
  paperComponent: undefined,
  errorProps: {},
  multiple: false,
  disabled: false,
};

export default StyledSelect;
