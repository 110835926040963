import React from 'react';
import { string, shape, arrayOf, bool, elementType } from 'prop-types';
import { useField, useFormikContext } from 'formik';
import Select from 'react-select';
import StyledFormControlOnboarding from '../../styled/StyledFormControlOnboarding';
import THEME from '../../../constants/theme';

const StyledSelectOnboardingWithSearch = ({
  paperComponent,
  name,
  options,
  label,
  placeholder,
  errorProps,
  multiple,
  ...restProps
}) => {
  const [{ value, onBlur }, { touched, error }] = useField(name);
  const { setFieldValue } = useFormikContext();
  const { renderValue, ...formControlProps } = restProps;
  const hasError = touched && Array.isArray(error) && error.length;

  const getBorderColor = (isFocused) => {
    if (isFocused) {
      return `${THEME.primaryColors.primary} !important`;
    }
    if (hasError) {
      return `${THEME.secondaryColors.danger} !important`;
    }
    return THEME.greyColors.grey16;
  };

  const customStyles = {
    position: 'relative',
    zIndex: 20,
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      cursor: 'pointer',
      padding: 12,
      position: 'relative',
      zIndex: 20,
      backgroundColor: state.isFocused ? '#f8f8f8' : 'white',
    }),
    indicatorSeparator: () => {},
    control: (_provided, state) => {
      return {
        position: 'relative',
        zIndex: 20,
        border: '1px solid',
        '&:hover': {
          borderColor: THEME.greyColors.grey300,
        },
        borderColor: getBorderColor(state.isFocused),
        borderRadius: 6,
        padding: '4px 10px 4px 5px',
        height: '56px',
        display: 'flex',
        cursor: 'pointer',
        transition: '0.25s',
      };
    },
    placeholder: () => {
      return {
        color: hasError ? THEME.secondaryColors.danger : THEME.greyColors.grey1,
      };
    },
  };

  const handleChange = (valueNew) => {
    setFieldValue(name, valueNew);
  };

  return (
    <StyledFormControlOnboarding
      variant="outlined"
      fullWidth
      {...formControlProps}
      className={hasError ? 'has-error' : ''}
    >
      <Select
        labelId={`${name}-label`}
        id={name}
        name={name}
        label={label}
        placeholder={hasError ? error[0] : label}
        onChange={(selectedOption) => handleChange(selectedOption.value)}
        onBlur={onBlur}
        defaultValue={options.find((option) => option.value === value)}
        options={options}
        styles={customStyles}
      />
    </StyledFormControlOnboarding>
  );
};

StyledSelectOnboardingWithSearch.propTypes = {
  name: string,
  options: arrayOf(shape({})),
  label: string,
  placeholder: string,
  paperComponent: elementType,
  errorProps: shape({}),
  multiple: bool,
};

StyledSelectOnboardingWithSearch.defaultProps = {
  name: '',
  options: [],
  label: '',
  placeholder: '',
  paperComponent: undefined,
  errorProps: {},
  multiple: false,
};

export default StyledSelectOnboardingWithSearch;
