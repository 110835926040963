import React, { useMemo, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, Box } from '@mui/material';
import { StyledAccordion, StyledAccordionSummary, StyledBox } from './styled';
import { useFlowEditorContext } from '../../../../../context';
import { P14, P16B } from '../../../../../../atoms';
import THEME from '../../../../../../../constants/theme';
import NotConnectedSection from '../../sections/NotConnectedSection';

const AUTHENTICATION_REQUIRED = 'AUTHENTICATION_REQUIRED';

const ERRORS = [
  {
    title: 'Not Properly Configured',
    description: 'Address all mandatory configurations in order to successfully use this Node',
    category: 'NOT_PROPERLY_CONFIGURED',
  },
  {
    title: 'Missing Condition Branching',
    description: 'Create at least one condition branch to use this Condition Node',
    category: 'MISSING_CONDITION_BRANCHING',
  },
  {
    title: 'Not Connected to your Automation',
    description: 'Connect this node to the rest of your Automation to include it in your workflow',
    category: 'NOT_CONNECTED_TO_AUTOMATION',
  },
  {
    category: 'AUTHENTICATION_REQUIRED',
  },
];

const NodeIssues = () => {
  const { selectedNodeData } = useFlowEditorContext();
  const [expanded, setExpanded] = useState('step0');
  const errorCategories = useMemo(() => selectedNodeData?.validationErrors?.map(({ category }) => category), [
    selectedNodeData?.validationErrors,
  ]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : null);
  };

  return (
    <Box>
      {ERRORS.map(({ category, title, description }) =>
        category === AUTHENTICATION_REQUIRED && errorCategories.includes(category) ? (
          <NotConnectedSection
            key={category}
            isExpanded={expanded === 'step-connect'}
            setExpanded={() => setExpanded(expanded === 'step-connect' ? null : 'step-connect')}
          />
        ) : (
          errorCategories.includes(category) && (
            <StyledAccordion
              elevation={0}
              expanded={expanded === `step${category}`}
              onChange={handleChange(`step${category}`)}
              key={category}
            >
              <StyledAccordionSummary
                $expanded={expanded}
                expandIcon={<ExpandMoreIcon color={expanded ? 'action' : 'primary'} />}
                aria-controls="helper-field-content"
                id="helper-field-content"
              >
                <P16B color={THEME.primaryColors.white}>{title}</P16B>
              </StyledAccordionSummary>
              <AccordionDetails>
                <StyledBox>
                  <P14 color="rgba(255, 255, 255, 0.8)">{description}</P14>
                </StyledBox>
              </AccordionDetails>
            </StyledAccordion>
          )
        )
      )}
    </Box>
  );
};

export default NodeIssues;
