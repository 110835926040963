import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_PAYMENT_FLOW_STAGES } from '../../utils/queries/flows/flowsQueries';

const useGetPaymentFlowId = () => {
  const { id: flowId } = useParams();
  const id = flowId?.trim() || 'default';

  const { data, loading } = useQuery(GET_PAYMENT_FLOW_STAGES, {
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  });

  return { flow: data?.getPaymentFlow, flowId: data?.getPaymentFlow?.id, loading };
};

export default useGetPaymentFlowId;
