import React from 'react';
import { number } from 'prop-types';

const Condition = ({ size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.88955 2.64138C6.88955 2.28716 7.17671 2 7.53093 2H7.531H12.5068C12.861 2 13.1482 2.28716 13.1482 2.64138C13.1482 2.9956 12.861 3.28276 12.5068 3.28276H8.17238V5.1323C9.48648 5.42493 10.4689 6.59774 10.4689 8C10.4689 9.40226 9.48648 10.5751 8.17238 10.8677V12.7172H12.5068C12.861 12.7172 13.1482 13.0044 13.1482 13.3586C13.1482 13.7128 12.861 14 12.5068 14H7.53093C7.17671 14 6.88955 13.7128 6.88955 13.3586L6.88962 13.3492V10.8677C5.78193 10.6211 4.9099 9.74906 4.66325 8.64137H0.641379C0.287155 8.64137 0 8.35421 0 7.99999C0 7.64576 0.287155 7.35861 0.641379 7.35861H4.66325C4.90991 6.25093 5.78194 5.37892 6.88962 5.13228V2.65086C6.88957 2.64771 6.88955 2.64454 6.88955 2.64138ZM5.87579 8C5.87579 7.08587 6.61684 6.34483 7.53096 6.34483C8.44509 6.34483 9.18613 7.08587 9.18613 8C9.18613 8.91412 8.44509 9.65517 7.53096 9.65517C6.61684 9.65517 5.87579 8.91412 5.87579 8Z"
      fill="white"
    />
  </svg>
);

Condition.propTypes = {
  size: number,
};

Condition.defaultProps = {
  size: 32,
};

export default Condition;
