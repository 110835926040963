import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import Box from '@mui/material/Box';
import { useMutation } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { NotificationManager } from 'react-notifications';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  StyledPaper,
} from '../../../Dialog/styled';
import CloseButton from '../../../Dialog/CloseButton';
import Input from '../../../forms/_common/Input';
import { CREATE_TEAM_GQL } from '../../../../utils/queries/team/createTeam';
import { useGlobalContext } from '../../../../containers/App/context';

import FormControl from '../../../forms/_common/FormControl';
import { TOAST_TIMEOUT } from '../../../../constants/toasts';
import { useSwitchTeam } from '../../../../hooks/useSwitchTeam';

import { validationSchema, INITIAL_VALUES, FIELDS } from './formSettings';
import ToastCustomContainer from '../../../ToastCustomContainer';

const ID = 'create-new-team-dialog';
const TOAST_TITLE = 'Create Team.';

const CreateTeamDialog = ({ isOpen, toggleIsOpen }) => {
  const { t } = useTranslation();
  const [createTeam] = useMutation(CREATE_TEAM_GQL);
  const { setGlobalLoading } = useGlobalContext();
  const switchTeam = useSwitchTeam();

  const handleSubmit = async (values, actions) => {
    setGlobalLoading(ID, true);
    try {
      const res = await createTeam({ variables: values, context: { skipGlobalHandling: true } });
      const requestFailed = !isEmpty(res.errors) && !res.data?.createTeam;

      if (requestFailed) {
        NotificationManager.error(
          <ToastCustomContainer message={res.errors[0]?.message} title={TOAST_TITLE} />,
          null,
          TOAST_TIMEOUT
        );

        return;
      }

      NotificationManager.success(
        <ToastCustomContainer message={`${values.teamName} successfully created.`} title={TOAST_TITLE} />,
        null,
        TOAST_TIMEOUT
      );

      await switchTeam(res.data?.createTeam?.id);
    } catch {
      NotificationManager.error(
        <ToastCustomContainer message="uiMessages.error" title={TOAST_TITLE} />,
        null,
        TOAST_TIMEOUT
      );
    } finally {
      actions.setSubmitting(false);
      setGlobalLoading(ID, false);
    }
  };

  return (
    <StyledDialog
      open={isOpen}
      scroll="paper"
      maxWidth="xs"
      PaperComponent={StyledPaper}
      onClose={toggleIsOpen}
      aria-labelledby={ID}
    >
      <CloseButton onClick={toggleIsOpen} />
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount
        enableReinitialize
      >
        <Form>
          <StyledDialogTitle id={`${ID}-title`} disableTypography>
            {t('Create New Team')}
          </StyledDialogTitle>

          <StyledDialogContent>
            <Box minWidth="300px" pb="24px" mt="16px">
              {FIELDS.map(({ name, placeholder, type, label }) => (
                <Input
                  type={type}
                  label={label}
                  name={name}
                  placeholder={placeholder}
                  key={`create-dialog-${name}`}
                  customLabel
                />
              ))}
            </Box>
          </StyledDialogContent>

          <StyledDialogActions>
            <FormControl toggleIsOpen={toggleIsOpen} primaryText="Create team" />
          </StyledDialogActions>
        </Form>
      </Formik>
    </StyledDialog>
  );
};

CreateTeamDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleIsOpen: PropTypes.func.isRequired,
};

export default CreateTeamDialog;
