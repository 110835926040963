import React from 'react';

const Maestro = () => {
  return (
    <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="34" height="24" rx="4" fill="white" />
      <rect
        x="0.25"
        y="0.25"
        width="33.5"
        height="23.5"
        rx="3.75"
        stroke="black"
        strokeOpacity="0.15"
        strokeWidth="0.5"
      />
      <path
        d="M19.2576 11.7058C19.2576 15.4092 16.2898 18.4115 12.6288 18.4115C8.96782 18.4115 6 15.4092 6 11.7058C6 8.00227 8.96782 5 12.6288 5C16.2898 5 19.2576 8.00227 19.2576 11.7058Z"
        fill="#ED0006"
      />
      <path
        d="M27.8571 11.7058C27.8571 15.4092 24.8893 18.4115 21.2283 18.4115C17.5673 18.4115 14.5995 15.4092 14.5995 11.7058C14.5995 8.00227 17.5673 5 21.2283 5C24.8893 5 27.8571 8.00227 27.8571 11.7058Z"
        fill="#0099DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9286 6.60205C18.3539 7.83201 19.2576 9.66217 19.2576 11.7059C19.2576 13.7496 18.3539 15.5797 16.9286 16.8097C15.5033 15.5797 14.5996 13.7496 14.5996 11.7059C14.5996 9.66217 15.5033 7.83201 16.9286 6.60205Z"
        fill="#6C6BBD"
      />
    </svg>
  );
};

export default Maestro;
