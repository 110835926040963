import React from 'react';

const ReduceDeclines = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22Z"
        fill="#C40C0C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12H13.25V30.75H32V32H12V12ZM24.5 26.375C24.5 26.5408 24.5658 26.6997 24.6831 26.8169C24.8003 26.9342 24.9592 27 25.125 27H30.125C30.2908 27 30.4497 26.9342 30.5669 26.8169C30.6842 26.6997 30.75 26.5408 30.75 26.375V21.375C30.75 21.2092 30.6842 21.0503 30.5669 20.9331C30.4497 20.8158 30.2908 20.75 30.125 20.75C29.9592 20.75 29.8003 20.8158 29.6831 20.9331C29.5658 21.0503 29.5 21.2092 29.5 21.375V24.625L24.9838 19.1038C24.9285 19.0363 24.8597 18.9811 24.7818 18.9418C24.7039 18.9025 24.6186 18.88 24.5315 18.8756C24.4444 18.8713 24.3573 18.8852 24.2759 18.9165C24.1945 18.9478 24.1205 18.9959 24.0588 19.0575L20.825 22.2913L16.255 16.0075C16.155 15.8804 16.0097 15.797 15.8495 15.7748C15.6893 15.7526 15.5267 15.7933 15.396 15.8885C15.2652 15.9837 15.1764 16.1258 15.1483 16.2851C15.1201 16.4443 15.1548 16.6083 15.245 16.7425L20.245 23.6175C20.2981 23.6906 20.3664 23.7513 20.4452 23.7955C20.524 23.8397 20.6114 23.8663 20.7015 23.8734C20.7915 23.8805 20.8821 23.868 20.9668 23.8368C21.0516 23.8056 21.1286 23.7563 21.1925 23.6925L24.4538 20.43L28.8062 25.75H25.125C24.9592 25.75 24.8003 25.8158 24.6831 25.9331C24.5658 26.0503 24.5 26.2092 24.5 26.375Z"
        fill="white"
      />
    </svg>
  );
};

export default ReduceDeclines;
