import { useQuery } from '@apollo/client';
import { useFlowEditorContext } from '../../FlowEditor/context';
import { GET_FLOW_INSTANCE } from '../../../utils/queries/flows/queries';

export const useLogsData = () => {
  const { selectedInstanceId, selectedInstance } = useFlowEditorContext();
  const { loading, data } = useQuery(GET_FLOW_INSTANCE, {
    variables: {
      id: selectedInstanceId,
    },
    skip: selectedInstance?.id === selectedInstanceId,
  });

  const flowInstance = data?.getFlowInstance;

  return {
    loading,
    flowInstance,
  };
};
