const PAYMENT_STATUS_MAP = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  REFUNDED: 'REFUNDED',
  DECLINED: 'DECLINED',
  DISPUTED: 'DISPUTED',
  FAILED: 'FAILED',
};

const INTENT_STATUSES = [PAYMENT_STATUS_MAP.INACTIVE, PAYMENT_STATUS_MAP.ACTIVE];

export { PAYMENT_STATUS_MAP, INTENT_STATUSES };
