import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import { GET_PAYMENT_FLOW_FOR_GATEWAYS } from '../../../../../../utils/queries/flows/flowsQueries';

const getPaymentMethods = (paymentMethodObjects) => {
  const fullList = paymentMethodObjects.map((item) => item.methods);
  const flattenedArray = flatten(fullList);
  return uniq(flattenedArray);
};

const useGetConnections = ({ topFlowId }) => {
  const { data: flow, loading, refetch } = useQuery(GET_PAYMENT_FLOW_FOR_GATEWAYS, {
    variables: {
      id: topFlowId,
      status: ['CONNECTED'],
      types: ['PAYMENT_GATEWAY'],
    },
    skip: !topFlowId,
  });

  const allConnections = useMemo(() => compact(flow?.listConnections || []), [flow?.listConnections]);

  const paymentMethodObjects = useMemo(() => compact(flow?.getPaymentFlow?.paymentMethods || []), [
    flow?.getPaymentFlow?.paymentMethods,
  ]);

  const paymentMethods = getPaymentMethods(paymentMethodObjects);

  const defaultValue = flow?.getPaymentFlow?.defaultGateway;

  return { allConnections, connected: allConnections, paymentMethods, defaultValue, loading, refetch };
};

export default useGetConnections;
