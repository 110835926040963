import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Skeleton from '@mui/lab/Skeleton';
import { useTranslation } from 'react-i18next';
import { H4, P14 } from '../../../../components/atoms';
import MultipleFlows from './MultipleFlows';
import { automationPropType } from './constant';

const FlowIdBlock = ({ automationList, loading }) => {
  const { t } = useTranslation();
  const isEmpty = automationList.length === 0;

  return (
    <Box mt="28px">
      <Box>
        <H4>{t('Flow ID')}</H4>
        <P14 color="#787F88" margin="8px 0 0 0">
          {t('Use the Flow ID value in the Intent or Payments API.')}
        </P14>

        {loading && (
          <Box borderRadius="8px" overflow="hidden" mt="32px">
            <Skeleton variant="rectangular" height="120px" width="100%" animation="wave" />
          </Box>
        )}

        {!loading && !isEmpty && <MultipleFlows automationList={automationList} />}
      </Box>
    </Box>
  );
};

FlowIdBlock.propTypes = {
  automationList: PropTypes.arrayOf(automationPropType).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default FlowIdBlock;
