import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Title from '../../Title';
import SubTitle from '../../SubTitle';
import { SearchBar } from '../../../../../atoms';
import { selectTypes } from '../inputs/types';
import MultiSelect from '../inputs/MultiSelect';
import ListLoadingState from '../fields/ListLoadingState';
import { StyledFormControlLabel } from '../inputs/styled';
import Checkbox from '../inputs/Checkbox';
import ListEmptyState from '../../../../../ListEmptyState';
import THEME from '../../../../../../constants/theme';

const MultiSelectSection = ({
  title,
  subTitle,
  options,
  selectedOptionIds,
  handleChange,
  selectType,
  isSearchBar,
  selectAll,
  mb,
  loading,
  showEmptyState,
  addBorder,
  border,
}) => {
  const [search, setSearch] = useState('');

  const filteredOptions = useMemo(
    () => options?.filter((option) => !search.toLowerCase() || option?.title?.toLowerCase().search(search) >= 0),
    [options, search]
  );

  const onSelectAll = ({ target }) => {
    const { checked } = target;

    if (checked) {
      handleChange([...options?.map((el) => el?.value)]);
    } else {
      handleChange([]);
    }
  };

  return (
    <Box component="section" marginBottom={mb}>
      {title && <Title>{title}</Title>}
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
      <Box {...(addBorder && { border, borderRadius: '4px' })}>
        {isSearchBar && (
          <Box borderBottom={`1px solid ${THEME.greyColors.grey12}`}>
            <SearchBar search={search} setSearch={setSearch} paddingY="0" bgcolor="transparent" noIcon paddingX="4px" />
          </Box>
        )}
        {loading ? (
          <ListLoadingState />
        ) : (
          <>
            {isEmpty(options) && showEmptyState && (
              <Box position="relative" height="200px" mb="32px">
                <ListEmptyState title="Connections" description="There are currently no connections." />
              </Box>
            )}
            <Box padding="4px" display="flex" flexDirection="column">
              {selectAll && !search && !isEmpty(options) && (
                <StyledFormControlLabel
                  value="all"
                  control={<Checkbox name="all" color="primary" size="small" />}
                  label={<Box>All {title}</Box>}
                  onChange={onSelectAll}
                  checked={options?.length === selectedOptionIds?.length}
                  $selectType={selectType}
                />
              )}
              <MultiSelect
                options={filteredOptions}
                selectedOptionIds={selectedOptionIds}
                onSelect={handleChange}
                selectType={selectType}
                selectAll={selectAll}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

MultiSelectSection.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedOptionIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleChange: PropTypes.func.isRequired,
  selectType: PropTypes.oneOf([selectTypes.connections, selectTypes.people, selectTypes.none]),
  isSearchBar: PropTypes.bool,
  mb: PropTypes.string,
  loading: PropTypes.bool,
  showEmptyState: PropTypes.bool,
  selectAll: PropTypes.bool,
  addBorder: PropTypes.bool,
  border: PropTypes.string,
};

MultiSelectSection.defaultProps = {
  title: '',
  subTitle: '',
  selectType: selectTypes.none,
  isSearchBar: false,
  mb: '0',
  loading: false,
  showEmptyState: false,
  selectAll: false,
  addBorder: false,
  border: '',
};

export default MultiSelectSection;
