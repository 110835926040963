import moment from 'moment';
import { isDefined } from '../../../../../utils/helpers';
import THEME from '../../../../../constants/theme';

const formatDate = (date) => {
  const dateToFormat = !isDefined(date) ? moment() : moment(date);

  return dateToFormat.format('MMM DD, YYYY');
};

const getUuid = (id) => {
  const index = id.indexOf(':') + 1;
  const formattedId = id.slice(index);

  // return `connection-provider:${id}`;
  return formattedId;
};

const DISPUTE_STATUS = {
  won: 'WON',
  lost: 'LOST',
  refunded: 'REFUNDED',
  needsResponse: 'NEEDS_RESPONSE',
  underReview: 'UNDER_REVIEW',
  closed: 'CLOSED',
  sentForReview: 'SENT_FOR_REVIEW',
};

const STATUS_COLOR_MAP = {
  [DISPUTE_STATUS.needsResponse]: THEME.secondaryColors.nodeError,
  [DISPUTE_STATUS.lost]: THEME.secondaryColors.nodeError,
  [DISPUTE_STATUS.won]: THEME.secondaryColors.greenDark,
  [DISPUTE_STATUS.refunded]: THEME.secondaryColors.greenDark,
  [DISPUTE_STATUS.underReview]: THEME.secondaryColors.greenDark,
  [DISPUTE_STATUS.sentForReview]: THEME.secondaryColors.greenDark,
  [DISPUTE_STATUS.closed]: THEME.secondaryColors.greenDark,
};

const DISPUTE_SUBMISSION_STATUS = {
  draft: 'DRAFT',
  submitted: 'SUBMITTED',
  submissionFailedAtProcessor: 'SUBMISSION_FAILED_AT_PROCESSOR',
};

const DISPUT_FORM_INPUT_TYPE = {
  string: 'STRING',
  file: 'FILE',
};

export { formatDate, getUuid, DISPUTE_STATUS, STATUS_COLOR_MAP, DISPUTE_SUBMISSION_STATUS, DISPUT_FORM_INPUT_TYPE };
