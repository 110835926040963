import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { string } from 'prop-types';
import Rotate from '../../../../../assets/icons/Rotate';
import { RetryBox, StyledIconButton } from '../styled';
import { useFlowEditorContext } from '../../../context';

const RetryButton = ({ stepId }) => {
  const {
    selectedInstance,
    retryingStepId,
    isOpenRetryDialog,
    setIsOpenRetryDialog,
    setSelectedRetryStepId,
  } = useFlowEditorContext();

  const handleClick = () => {
    setSelectedRetryStepId(stepId);
    setIsOpenRetryDialog(true);
  };

  return (
    <RetryBox className="ellipse-container" $retrying={!selectedInstance || !!retryingStepId}>
      <StyledIconButton onClick={handleClick} disabled={!selectedInstance || !!retryingStepId || isOpenRetryDialog}>
        {retryingStepId && retryingStepId === stepId ? (
          <Box display="flex" alignItems="center" color="white">
            <CircularProgress size={22} color="inherit" />
          </Box>
        ) : (
          <Rotate size={22} />
        )}
      </StyledIconButton>
    </RetryBox>
  );
};

RetryButton.propTypes = {
  stepId: string.isRequired,
};

export default RetryButton;
