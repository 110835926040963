import React from 'react';
import { number, string } from 'prop-types';
import THEME from '../../constants/theme';

const Remove = ({ size, color, ...props }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5891 4.41009C15.9145 4.73553 15.9145 5.26317 15.5891 5.5886L5.58909 15.5886C5.26366 15.914 4.73602 15.914 4.41058 15.5886C4.08514 15.2632 4.08514 14.7355 4.41058 14.4101L14.4106 4.41009C14.736 4.08466 15.2637 4.08466 15.5891 4.41009Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.41058 4.41009C4.73602 4.08466 5.26366 4.08466 5.58909 4.41009L15.5891 14.4101C15.9145 14.7355 15.9145 15.2632 15.5891 15.5886C15.2637 15.914 14.736 15.914 14.4106 15.5886L4.41058 5.5886C4.08514 5.26317 4.08514 4.73553 4.41058 4.41009Z"
      fill={color}
    />
  </svg>
);

Remove.propTypes = {
  size: number,
  color: string,
};

Remove.defaultProps = {
  size: 20,
  color: THEME.greyColors.grey800,
};

export default Remove;
