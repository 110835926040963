import React from 'react';

const LoaderIcon = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="20" height="20" rx="10" fill="black" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 4.5C10.2761 4.5 10.5 4.72386 10.5 5V7C10.5 7.27614 10.2761 7.5 10 7.5C9.72386 7.5 9.5 7.27614 9.5 7V5C9.5 4.72386 9.72386 4.5 10 4.5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 12.5C10.2761 12.5 10.5 12.7239 10.5 13V15C10.5 15.2761 10.2761 15.5 10 15.5C9.72386 15.5 9.5 15.2761 9.5 15V13C9.5 12.7239 9.72386 12.5 10 12.5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.11129 6.11129C6.30655 5.91603 6.62314 5.91603 6.8184 6.11129L8.2334 7.52629C8.42866 7.72155 8.42866 8.03814 8.2334 8.2334C8.03814 8.42866 7.72155 8.42866 7.52629 8.2334L6.11129 6.8184C5.91603 6.62314 5.91603 6.30655 6.11129 6.11129Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7675 11.7675C11.9628 11.5723 12.2794 11.5723 12.4746 11.7675L13.8896 13.1825C14.0849 13.3778 14.0849 13.6944 13.8896 13.8896C13.6944 14.0849 13.3778 14.0849 13.1825 13.8896L11.7675 12.4746C11.5723 12.2794 11.5723 11.9628 11.7675 11.7675Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 10C4.5 9.72386 4.72386 9.5 5 9.5H7C7.27614 9.5 7.5 9.72386 7.5 10C7.5 10.2761 7.27614 10.5 7 10.5H5C4.72386 10.5 4.5 10.2761 4.5 10Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 10C12.5 9.72386 12.7239 9.5 13 9.5H15C15.2761 9.5 15.5 9.72386 15.5 10C15.5 10.2761 15.2761 10.5 15 10.5H13C12.7239 10.5 12.5 10.2761 12.5 10Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.2334 11.7675C8.42866 11.9628 8.42866 12.2794 8.2334 12.4746L6.8184 13.8896C6.62313 14.0849 6.30655 14.0849 6.11129 13.8896C5.91603 13.6944 5.91603 13.3778 6.11129 13.1825L7.52629 11.7675C7.72155 11.5723 8.03814 11.5723 8.2334 11.7675Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8896 6.11129C14.0849 6.30655 14.0849 6.62313 13.8896 6.8184L12.4746 8.2334C12.2794 8.42866 11.9628 8.42866 11.7675 8.2334C11.5723 8.03814 11.5723 7.72155 11.7675 7.52629L13.1825 6.11129C13.3778 5.91603 13.6944 5.91603 13.8896 6.11129Z"
      fill="white"
    />
  </svg>
);

export default LoaderIcon;
