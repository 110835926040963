import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import Skeleton from '@mui/lab/Skeleton';
import Select, { NONE } from '../../../../../../forms/_common/Select';
import { StyledFormControl } from '../Test/styled';
import { useNotificationManager } from '../../../../../../../hooks/useNotificationManager';
import { GET_POSSIBLE_CONDITION_VALUES_FOR_STEP } from '../../../../../../../utils/queries/flows/queries';
import { useFlowEditorContext } from '../../../../../context';
import { useNodeDataToSave } from '../hooks/useNodeDataToSave';

const EnumSelectInput = ({ name, value, propertyType, onChange, property }) => {
  const { flowId } = useFlowEditorContext();
  const [{ id: stepId }] = useNodeDataToSave();
  const { data, loading, error } = useQuery(GET_POSSIBLE_CONDITION_VALUES_FOR_STEP, {
    variables: {
      flowId,
      stepId,
      property,
      propertyType,
    },
    fetchPolicy: 'no-cache',
  });
  useNotificationManager('error', error?.message, 'Fetch Possible Enum Values');

  const options = useMemo(() => {
    const values = data?.getPossibleConditionValuesForStep;
    if (!Array.isArray(values)) {
      return [];
    }
    return values.map((item) => ({
      value: item?.key,
      title: item?.label,
    }));
  }, [data?.getPossibleConditionValuesForStep]);

  return (
    <StyledFormControl fullWidth>
      {loading ? (
        <Skeleton height="30px" />
      ) : (
        <Select value={value || NONE} name={name} options={options} onChange={onChange} />
      )}
    </StyledFormControl>
  );
};

EnumSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  propertyType: PropTypes.string.isRequired,
  property: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default EnumSelectInput;
