import React from 'react';
import Box from '@mui/material/Box';
import { NodeLibraryGroup } from './NodeLibraryGroup';

/*
 * define flex order
 * https://developer.mozilla.org/en-US/docs/Web/CSS/order
 */
const ORDER = {
  Triggers: 0,
  Actions: 1,
  Conditions: 2,
  Services: 3,
};

const MAX = Object.values(ORDER).reduce((acc, order) => (acc > order ? acc : order));

const NodeLibraryList = ({ filteredEntries }) => {
  return filteredEntries.map(
    ([name, items]) =>
      items?.length > 0 && (
        <Box component="section" key={name} order={ORDER[name] ?? MAX}>
          <NodeLibraryGroup name={name} items={items} />
        </Box>
      )
  );
};

export default React.memo(NodeLibraryList);
