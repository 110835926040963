import React from 'react';
import { string } from 'prop-types';
import Box from '@mui/material/Box';
import { StyledAnnotationMediaBox, StyledGrabbableBox, StyledPlayIconBox } from './styled';
import NodeBase from './NodeBase';
import THEME from '../../../../constants/theme';
import { P14, P14M } from '../../../atoms';
import { flowStepPropType } from '../../types';
import CloseButton from '../../../Dialog/CloseButton';
import { useFlowEditorContext } from '../../context';
import Image from '../../../../assets/img/flow-settings-gradient.png';
import PlayIcon from '../../../../assets/icons/FlowList/Play';
import { MULTIMEDIA_TYPES } from './constant';
import { StyledImage } from '../../../styled/StyledImage';

const AnnotationNode = (props) => {
  const { nodes, setNodes, saveConfigurationAsync, setIsShowVideoModal, setVideoModalUrl } = useFlowEditorContext();
  const { id, data } = props;
  const { title, text, multimediaUrl, multimediaType } = data;
  const isImageMultimedia = multimediaUrl && multimediaType === MULTIMEDIA_TYPES.IMAGE;
  const isVideoMultimedia = multimediaUrl && multimediaType === MULTIMEDIA_TYPES.VIDEO;

  const handleRemove = async () => {
    const updatedNodes = nodes.filter((node) => node.id !== id);
    setNodes(updatedNodes);
    await saveConfigurationAsync(updatedNodes);
  };

  const handlePlay = () => {
    setVideoModalUrl(multimediaUrl);
    setIsShowVideoModal(true);
  };

  return (
    <NodeBase id={id}>
      <StyledGrabbableBox
        onClick={(e) => e.stopPropagation()}
        display="flex"
        flexDirection="column"
        width="336px"
        borderRadius="8px"
        bgcolor={THEME.primaryColors.white}
        boxShadow="0px 2px 2px rgba(0, 0, 0, 0.04), 0px 10px 14px rgba(0, 0, 0, 0.04)"
        justifyContent="flex-start"
        zIndex="300"
      >
        <>
          <CloseButton
            onClick={handleRemove}
            $bgcolor={THEME.primaryColors.white}
            $hoverBg={THEME.primaryColors.white}
            $borderRadius="50%"
            top="-20px"
            right="-20px"
          />
          <Box height="100%" padding="24px">
            {title && <P14M>{title}</P14M>}
            {isImageMultimedia ? (
              <StyledAnnotationMediaBox>
                <StyledImage width="100%" height="100%" src={multimediaUrl} alt="" />
              </StyledAnnotationMediaBox>
            ) : null}
            {isVideoMultimedia ? (
              <StyledAnnotationMediaBox image={Image}>
                <StyledPlayIconBox onClick={handlePlay}>
                  <PlayIcon />
                </StyledPlayIconBox>
              </StyledAnnotationMediaBox>
            ) : null}
            {text && <P14 margin="4px 0 0 0">{text}</P14>}
          </Box>
        </>
      </StyledGrabbableBox>
    </NodeBase>
  );
};

AnnotationNode.propTypes = {
  id: string.isRequired,
  data: flowStepPropType.isRequired,
};

export { AnnotationNode };
