import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_ALL_STEP_COMMENTS_FOR_FLOW } from '../../../utils/queries/flows/comments/queries';
import {
  CREATE_STEP_COMMENT,
  DELETE_STEP_COMMENT,
  EDIT_STEP_COMMENT,
  READ_THREAD,
  RESOLVE_THREAD,
} from '../../../utils/queries/flows/comments/mutations';

export const useNodeComments = ({ flowId, stepId, isFlowMonitor }) => {
  const { loading: commentsLoading, data: comments } = useQuery(GET_ALL_STEP_COMMENTS_FOR_FLOW, {
    variables: { flowId },
    fetchPolicy: 'no-cache',
    skip: !flowId || isFlowMonitor,
  });
  const [createCommentMutation, { loading: createLoading }] = useMutation(CREATE_STEP_COMMENT);
  const [editCommentMutation, { loading: editLoading }] = useMutation(EDIT_STEP_COMMENT);
  const [deleteCommentMutation, { loading: deleteLoading }] = useMutation(DELETE_STEP_COMMENT);
  const [resolveThreadMutation, { loading: resolveLoading }] = useMutation(RESOLVE_THREAD);
  const [readThreadMutation] = useMutation(READ_THREAD);

  const [allComments, setAllComments] = useState([]);
  const [currentThread, setCurrentTread] = useState([]);

  useEffect(() => {
    setAllComments(comments?.getAllStepCommentsForFlow || []);
  }, [comments]);

  useEffect(() => {
    setCurrentTread(allComments.find((item) => item.stepId === stepId));
  }, [allComments, comments, stepId]);

  const createComment = async (text) => {
    const { data, errors } = await createCommentMutation({ variables: { flowId, stepId, text } });

    if (!errors) {
      let newComments;
      if (currentThread) {
        newComments = allComments.map((item, index) =>
          item.stepId === stepId
            ? { ...allComments[index], comments: [...allComments[index]?.comments, data?.createStepComment] }
            : item
        );
      } else {
        newComments = [...allComments, { stepId, comments: [data?.createStepComment] }];
      }

      setAllComments(newComments);
    }
  };

  const editComment = async (commentId, text) => {
    const { data, errors } = await editCommentMutation({ variables: { commentId, text } });
    if (!errors) {
      const newComment = data?.editStepComment;
      const currentComments = allComments.find((item) => item.stepId === stepId);
      const updatedComments = currentComments.comments.map((item) => (item.id === commentId ? newComment : item));
      const newComments = allComments.map((item, index) =>
        item.stepId === stepId ? { ...allComments[index], comments: updatedComments } : item
      );
      setAllComments(newComments);
    }
  };

  const deleteComment = async (commentId) => {
    const { errors } = await deleteCommentMutation({ variables: { commentId } });
    if (!errors) {
      const currentComments = allComments.find((item) => item.stepId === stepId);
      const updatedComments = currentComments.comments.filter((item) => item.id !== commentId);
      const newComments = allComments.map((item, index) =>
        item.stepId === stepId ? { ...allComments[index], comments: updatedComments } : item
      );
      setAllComments(newComments);
    }
  };

  const readThread = async () => {
    await readThreadMutation({ variables: { flowId, stepId } });
  };

  const resolveThread = async () => {
    const { errors } = await resolveThreadMutation({ variables: { flowId, stepId } });
    if (!errors) {
      const currentComments = allComments.find((item) => item.stepId === stepId);
      const updatedComments = { ...currentComments, comments: [] };
      const newComments = allComments.map((item) => (item.stepId === stepId ? updatedComments : item));
      setAllComments(newComments);
    }
  };

  return {
    allComments,
    currentThread,
    commentsLoading,
    commentsSaving: createLoading || editLoading || deleteLoading,
    commentsResolving: resolveLoading,
    resolveThread,
    createComment,
    editComment,
    deleteComment,
    readThread,
  };
};
