import styled from 'styled-components';
import { Radio, FormControlLabel } from '@mui/material';
import { checkIsSolidButtonType, checkIsThinButtonType, checkIsRoundButtonType } from './constant';
import THEME from '../../../constants/theme';

export const StyledLabel = styled.span`
  background-color: ${({ brandcolor, type }) => (checkIsSolidButtonType(type) ? brandcolor : 'rgba(0,0,0,0)')};
  color: ${({ brandcolor, type }) => (checkIsThinButtonType(type) ? brandcolor : '#fff')};

  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 48px;
  font-size: 14px;
  margin-top: 10px;

  border: 1.1px solid ${({ brandcolor }) => brandcolor};
  border-radius: ${({ type }) => (checkIsRoundButtonType(type) ? '315px' : '5px')};
  transition: all 0.3s ease-out;
  position: relative;
  backface-visibility: hidden;
`;

export const StyledRadio = styled(Radio)`
  &.MuiRadio-root {
    &:hover {
      background-color: rgba(0, 0, 0, 0);

      svg:first-child {
        fill: ${THEME.primaryColors.primary};
      }
    }

    svg {
      font-size: 18px;
      fill: #c1c3c6;

      &:last-child {
        fill: #fff;
        transform: scale(1.8);
      }
    }

    &.Mui-checked {
      svg {
        fill: ${THEME.primaryColors.primary};

        &:last-child {
          transform: scale(1);
        }
      }
    }
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin: 0;
    background-color: ${({ isActive }) => (isActive ? THEME.primaryColors.primaryLight : THEME.greyColors.grey12)};
    width: 260px;
    height: 144px;
    border-radius: 6px;

    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

    &:not(:last-child) {
      margin-right: 32px;
    }
  }
`;
