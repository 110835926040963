import React from 'react';

const NoSearchData = (props) => (
  <svg width="80" height="72" viewBox="0 0 80 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="56" height="8" rx="4" fill="#F4F4FE" />
    <rect y="16" width="80" height="8" rx="4" fill="#F4F4FE" />
    <rect y="32" width="72" height="8" rx="4" fill="#F4F4FE" />
    <rect y="48" width="56" height="8" rx="4" fill="#F4F4FE" />
    <rect y="64" width="72" height="8" rx="4" fill="#F4F4FE" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.667 17.3332C28.6463 17.3332 21.3337 24.6459 21.3337 33.6665C21.3337 42.6872 28.6463 49.9998 37.667 49.9998C46.6876 49.9998 54.0003 42.6872 54.0003 33.6665C54.0003 24.6459 46.6876 17.3332 37.667 17.3332ZM16.667 33.6665C16.667 22.0685 26.069 12.6665 37.667 12.6665C49.265 12.6665 58.667 22.0685 58.667 33.6665C58.667 45.2645 49.265 54.6665 37.667 54.6665C26.069 54.6665 16.667 45.2645 16.667 33.6665Z"
      fill="#8F92F9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.2 45.2C50.1112 44.2888 51.5886 44.2888 52.4999 45.2L62.6498 55.35C63.5611 56.2612 63.5611 57.7386 62.6498 58.6498C61.7386 59.5611 60.2612 59.5611 59.35 58.6498L49.2 48.4999C48.2888 47.5886 48.2888 46.1112 49.2 45.2Z"
      fill="#8F92F9"
    />
  </svg>
);

export default NoSearchData;
