import React from 'react';
import { useTranslation } from 'react-i18next';
import { arrayOf, bool, shape, func } from 'prop-types';
import { StyledDialogActions, StyledDialogContent } from '../../Dialog/styled';
import { StyledIframe } from '../../atoms/StyledIframe/StyledIframe';
import { ButtonRounded, CircularLoader } from '../../atoms';
import ArrowRight from '../../../assets/arrows/arrowRight';
import THEME from '../../../constants/theme';
import { SWITCH_ENV_STEPS } from '../constant';
import { useGlobalContext } from '../../../containers/App/context';

const VideoStep = ({ automations, migrateCustomerToProduction, loading }) => {
  const { t } = useTranslation();
  const { setCurrentSwitchEnvStep } = useGlobalContext();
  const videoUrl = 'https://player.vimeo.com/video/706981483';

  const onClickHandle = () => {
    migrateCustomerToProduction();
    setCurrentSwitchEnvStep(automations?.length ? SWITCH_ENV_STEPS.MIGRATE : SWITCH_ENV_STEPS.LOADING);
  };

  return (
    <>
      <StyledDialogContent px="0">
        <StyledIframe title="embed-video" src={videoUrl} frameBorder="0" allowFullScreen />
      </StyledDialogContent>
      <StyledDialogActions $justifyContent="flex-end" px="32px" py="16px" $borderTop>
        <ButtonRounded
          type="button"
          variant="contained"
          color="primary"
          borderRadius="6px"
          endIcon={
            loading ? (
              <CircularLoader bgcolor="#fff" size="24px" thickness={5} />
            ) : (
              <ArrowRight size={24} color={`${THEME.primaryColors.white}`} />
            )
          }
          onClick={onClickHandle}
          disabled={loading}
        >
          {t('Continue')}
        </ButtonRounded>
      </StyledDialogActions>
    </>
  );
};

VideoStep.propTypes = {
  automations: arrayOf(shape({})),
  migrateCustomerToProduction: func.isRequired,
  loading: bool,
};

VideoStep.defaultProps = {
  automations: [],
  loading: false,
};

export default VideoStep;
