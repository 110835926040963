import React from 'react';

const Routing = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.5 2.12109V3.00009H3C2.60218 3.00009 2.22064 3.15813 1.93934 3.43943C1.65804 3.72074 1.5 4.10227 1.5 4.50009V7.50009C1.5 7.89792 1.65804 8.27945 1.93934 8.56075C2.22064 8.84206 2.60218 9.00009 3 9.00009H10.5V10.5001H3.75C3.51713 10.5001 3.28746 10.5543 3.07918 10.6585C2.8709 10.7626 2.68972 10.9138 2.55 11.1001L1.0875 13.0501C0.990133 13.1799 0.9375 13.3378 0.9375 13.5001C0.9375 13.6624 0.990133 13.8203 1.0875 13.9501L2.55 15.9001C2.68972 16.0864 2.8709 16.2376 3.07918 16.3417C3.28746 16.4459 3.51713 16.5001 3.75 16.5001H10.5V24.0001H13.5V16.5001H21C21.3978 16.5001 21.7794 16.3421 22.0607 16.0608C22.342 15.7794 22.5 15.3979 22.5 15.0001V12.0001C22.5 11.6023 22.342 11.2207 22.0607 10.9394C21.7794 10.6581 21.3978 10.5001 21 10.5001H13.5V9.00009H20.25C20.4829 9.00009 20.7125 8.94588 20.9208 8.84173C21.1291 8.73759 21.3103 8.58639 21.45 8.40009L22.9125 6.45009C23.0099 6.32027 23.0625 6.16237 23.0625 6.00009C23.0625 5.83782 23.0099 5.67992 22.9125 5.55009L21.45 3.60009C21.3103 3.4138 21.1291 3.26259 20.9208 3.15845C20.7125 3.05431 20.4829 3.00009 20.25 3.00009H13.5V2.12109C13.5 1.72327 13.342 1.34174 13.0607 1.06043C12.7794 0.779129 12.3978 0.621094 12 0.621094C11.6022 0.621094 11.2206 0.779129 10.9393 1.06043C10.658 1.34174 10.5 1.72327 10.5 2.12109ZM20.25 4.50009L21.375 6.00009L20.25 7.50009H3V4.50009H20.25ZM21 12.0001V15.0001H3.75L2.625 13.5001L3.75 12.0001H21Z"
      fill="black"
    />
  </svg>
);

export default Routing;
