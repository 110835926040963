import styled from 'styled-components';
import THEME from '../../../../../constants/theme';

export const StyledCopyComponent = styled.span`
  cursor: pointer;
  transition: all 0.2s linear;
  display: flex;
  align-items: center;

  &:hover {
    color: ${THEME.greyColors.grey1};
    & svg {
      path {
        transition: all 0.2s linear;

        fill: ${THEME.greyColors.grey1};
      }
    }
  }
`;
