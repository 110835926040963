import { Box } from '@mui/material';
import React from 'react';
import THEME from '../../../../constants/theme';
import { P14 } from '../../../atoms';

const StepFour = () => (
  <Box>
    <P14 margin="0 0 12px 0" color={THEME.greyColors.grey23}>
      Once your checkout is ready to go, you should integrate the final steps of the process with our secure payments
      APIs. To start, you will need your Flow ID to reference which set of logic you want to use during the life of the
      payment.
    </P14>
    <P14 margin="0 0 12px 0" color={THEME.greyColors.grey23}>
      Access your Flow ID within this automation by clicking on the <b>Start Payment Flow Trigger</b>. The ID is
      available in the trigger properties for easy reference in your integration code.
    </P14>
    <P14 margin="0 0 12px 0" color={THEME.greyColors.grey23}>
      Now that you are fully processing payments through WhenThen, changing your logic and integrations is easy. Add new
      processors to other enhancements in minutes and publish right from WhenThen without changing a single line of your
      code.
    </P14>
  </Box>
);

export default StepFour;
