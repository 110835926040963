import React from 'react';
import { arrayOf, shape, func, string } from 'prop-types';
import Tag from '../Tag';
import { StyledSearchResult } from './styled';

const SearchResult = ({ searchTagResult, filters, selectedFilters, setSelectedFilters, clearSearchFilter }) => {
  return (
    <StyledSearchResult>
      {searchTagResult.map((tag) => {
        const findFilterObject = filters.find((filter) => {
          if (filter.label === 'Apps') {
            return filter.data.find((subCategories) => subCategories.data.includes(tag));
          }
          return filter.data.includes(tag);
        });

        return (
          <Tag
            key={tag}
            label={findFilterObject.label}
            tag={tag}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            clearSearchFilter={clearSearchFilter}
          />
        );
      })}
    </StyledSearchResult>
  );
};

SearchResult.propTypes = {
  searchTagResult: arrayOf(string).isRequired,
  filters: arrayOf(shape({})).isRequired,
  selectedFilters: shape({}).isRequired,
  setSelectedFilters: func.isRequired,
  clearSearchFilter: func,
};

SearchResult.defaultProps = {
  clearSearchFilter: null,
};

export default SearchResult;
