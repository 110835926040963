export const parseBoolean = (value) => {
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
    case true:
      return true;
    case false:
      return false;
    default:
      return null;
  }
};
