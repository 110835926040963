import React, { useState, useMemo } from 'react';
import { bool } from 'prop-types';
import { useSearchInput } from './hooks/useSearchInput';
import useSearch from '../../../../../hooks/useSearch';
import { generateFilterObject, generateAllTags } from './constant';
import Filter from './Filter';
import { useInfiniteScroll } from '../ContentArea/useInfiniteScroll';
import { StyledFilterWrapper, Title } from './styled';
import SearchBar from './SearchBar';
import SearchResult from './SearchResult';
import ClearFilter from './ClearFilter';

const SideFilter = ({ inModal }) => {
  const { templates } = useInfiniteScroll();

  const [activeFilter, setActiveFilter] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [search, setSearch] = useSearchInput('search', 'tag');
  const [searchParams, setSearchParams] = useSearch();

  // Filters
  const filters = generateFilterObject(templates);

  // For Search
  const allTags = generateAllTags(filters);

  const searchValue = searchParams?.tag?.search;
  const searchTagResult = useMemo(
    () => allTags.filter((tag) => tag?.toUpperCase()?.includes(searchValue?.toUpperCase())),
    [allTags, searchValue]
  );

  const handleClearFilters = () => {
    setSelectedFilters({});
    setActiveFilter(null);
    setSearchParams({});
  };

  const isFiltersEmpty = () => {
    if (selectedFilters['Use Cases'] && selectedFilters['Use Cases'].length) {
      return false;
    }
    if (selectedFilters.Apps && selectedFilters.Apps.length) {
      return false;
    }
    if (selectedFilters.Roles && selectedFilters.Roles.length) {
      return false;
    }
    return true;
  };

  const clearSearchFilter = () => {
    setSearch('');
    setActiveFilter(null);
  };

  return (
    <StyledFilterWrapper $inModal={inModal}>
      <Title>Filters</Title>

      <div>
        {/* SEARCH BAR */}
        <SearchBar search={search} setSearch={setSearch} />

        {/* FILTERS */}
        {search.length < 1 && (
          <div>
            {filters.map(({ id, label, data }, index) => {
              const isApps = index === 0;

              return (
                <Filter
                  key={id}
                  label={label}
                  items={data}
                  isApps={isApps}
                  activeFilter={activeFilter}
                  setActiveFilter={setActiveFilter}
                  selectedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                />
              );
            })}
          </div>
        )}
      </div>

      {/* SEARCH RESULT */}
      {search.length > 0 && (
        <SearchResult
          searchTagResult={searchTagResult}
          filters={filters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          clearSearchFilter={clearSearchFilter}
        />
      )}

      {/* CLEAR ALL FILTERS */}
      {!isFiltersEmpty(selectedFilters) && <ClearFilter handleClearFilters={handleClearFilters} />}
    </StyledFilterWrapper>
  );
};

SideFilter.propTypes = {
  inModal: bool,
};

SideFilter.defaultProps = {
  inModal: false,
};

export default SideFilter;
