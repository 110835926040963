import React from 'react';
import { func, bool } from 'prop-types';
import Box from '@mui/material/Box';
import isEmpty from 'lodash/isEmpty';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ButtonRounded } from '../../../../../../../components/atoms';
import { StyledFooter } from './styled';
import { MODAL_TYPES } from '../ConfirmationDialog/constant';

const isValuesEmpty = (obj) => Object.values(obj).every((value) => isEmpty(value));

const FormControl = ({
  handleSave,
  isFormSubmitted,
  isSaving,
  setModalData,
  setShouldModalOpen,
  setShouldShowError,
}) => {
  const { t } = useTranslation();
  const { handleSubmit: submit, values, isSubmitting, errors, isValid, setTouched } = useFormikContext();

  const isDisabled = isValuesEmpty(values) || isSubmitting || isSaving || isFormSubmitted;

  const handleSubmit = () => {
    if (!isValid) {
      const keys = Object.keys(errors);

      const fieldsObj = keys.reduce((acc, curr) => {
        return { ...acc, [curr]: true };
      }, {});

      setTouched(fieldsObj);
      setShouldShowError(true);

      return;
    }

    setShouldModalOpen(true);
    setModalData({
      type: MODAL_TYPES.submitDispute,
      action: submit,
    });
  };

  return (
    <StyledFooter>
      <Box mr="8px">
        <ButtonRounded type="button" variant="contained" color="secondary" disabled={isDisabled} onClick={handleSave}>
          {t('Save')}
        </ButtonRounded>
      </Box>

      <ButtonRounded type="submit" color="primary" variant="contained" disabled={isDisabled} onClick={handleSubmit}>
        {t('Submit')}
      </ButtonRounded>
    </StyledFooter>
  );
};

FormControl.propTypes = {
  handleSave: func.isRequired,
  isFormSubmitted: bool.isRequired,
  isSaving: bool,
  setModalData: func.isRequired,
  setShouldModalOpen: func.isRequired,
  setShouldShowError: func,
};

FormControl.defaultProps = {
  isSaving: false,
  setShouldShowError: () => {},
};

export default FormControl;
