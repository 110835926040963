import React from 'react';
import { string } from 'prop-types';

const Trash2 = ({ color, height, width }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.3335 4.00004C1.3335 3.63185 1.63197 3.33337 2.00016 3.33337H14.0002C14.3684 3.33337 14.6668 3.63185 14.6668 4.00004C14.6668 4.36823 14.3684 4.66671 14.0002 4.66671H2.00016C1.63197 4.66671 1.3335 4.36823 1.3335 4.00004Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.6665 1.99996C6.48969 1.99996 6.32012 2.0702 6.1951 2.19522C6.07007 2.32025 5.99984 2.48982 5.99984 2.66663V3.33329H9.99984V2.66663C9.99984 2.48982 9.9296 2.32025 9.80457 2.19522C9.67955 2.0702 9.50998 1.99996 9.33317 1.99996H6.6665ZM11.3332 3.33329V2.66663C11.3332 2.13619 11.1225 1.62748 10.7474 1.25241C10.3723 0.87734 9.8636 0.666626 9.33317 0.666626H6.6665C6.13607 0.666626 5.62736 0.87734 5.25229 1.25241C4.87722 1.62748 4.6665 2.13619 4.6665 2.66663V3.33329H3.33317C2.96498 3.33329 2.6665 3.63177 2.6665 3.99996V13.3333C2.6665 13.8637 2.87722 14.3724 3.25229 14.7475C3.62736 15.1226 4.13607 15.3333 4.6665 15.3333H11.3332C11.8636 15.3333 12.3723 15.1226 12.7474 14.7475C13.1225 14.3724 13.3332 13.8637 13.3332 13.3333V3.99996C13.3332 3.63177 13.0347 3.33329 12.6665 3.33329H11.3332ZM3.99984 4.66663V13.3333C3.99984 13.5101 4.07007 13.6797 4.1951 13.8047C4.32012 13.9297 4.48969 14 4.6665 14H11.3332C11.51 14 11.6795 13.9297 11.8046 13.8047C11.9296 13.6797 11.9998 13.5101 11.9998 13.3333V4.66663H3.99984Z"
      fill={color}
    />
  </svg>
);

Trash2.propTypes = {
  color: string,
  height: string,
  width: string,
};

Trash2.defaultProps = {
  color: '#6A6A6A',
  height: '16',
  width: '16',
};
export default Trash2;
