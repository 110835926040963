import React from 'react';

const CurrencyAmount = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="4" fill="#F08A73" />
    <path
      d="M15.566 14.413C15.566 13.035 14.708 12.151 13.213 11.826L11.757 11.514C10.756 11.293 10.288 10.929 10.288 10.175C10.288 9.317 10.977 8.849 12.056 8.849C13.252 8.849 13.993 9.356 13.993 10.357H15.384C15.384 8.68 14.175 7.809 12.641 7.64V6.535H11.471V7.653C9.833 7.861 8.806 8.888 8.806 10.279C8.806 11.774 9.872 12.554 11.133 12.814L12.602 13.139C13.603 13.347 14.084 13.685 14.084 14.478C14.084 15.401 13.252 15.869 12.147 15.869C10.73 15.869 10.041 15.258 10.041 14.374H8.637C8.637 15.856 9.69 16.883 11.471 17.091V18.209H12.641V17.104C14.305 16.948 15.566 15.973 15.566 14.413Z"
      fill="black"
    />
  </svg>
);

export default CurrencyAmount;
