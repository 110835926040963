import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import TableBody from '@mui/material/TableBody/index';
import isEmpty from 'lodash/isEmpty';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import EmptyState from './EmptyState';
import { StyledTable, StyledTableRow, StyledTableHead } from '../../../../../components/table/styled';
import { P16B } from '../../../../../components/atoms';
import { StyledWrapper } from './styled';
import { StyledDivider } from '../styled';
import { StyledTableCell } from '../../../../../components/table/Cells/styled';

const DetailsTable = ({ rows, cells, title, handleRowClick, showHeadCells, showDivider, minWidth }) => {
  const { t } = useTranslation();

  const emptyData = useMemo(() => isEmpty(rows), [rows]);

  return (
    <StyledWrapper>
      <P16B>{t(title)}</P16B>
      {showDivider && <StyledDivider />}
      {emptyData && (
        <Box height="200px" overflow="hidden">
          <EmptyState title={title} />
        </Box>
      )}
      {!emptyData && (
        <StyledTable size="small" $minWidth={minWidth}>
          {showHeadCells && (
            <StyledTableHead>
              <StyledTableRow>
                {cells.map((headCell) => (
                  <StyledTableCell key={headCell.accessor} id={headCell.accessor} customWidth={headCell.width}>
                    {t(headCell.label)}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </StyledTableHead>
          )}
          <TableBody>
            {rows.map((row) => {
              return (
                <StyledTableRow
                  key={row?.id}
                  hover
                  $noCheckBox
                  $checkboxEditMode
                  padding="checkbox"
                  onClick={() => handleRowClick(row)}
                >
                  {cells.map(({ accessor, Cell, ...rest }) => (
                    <Cell
                      key={accessor}
                      {...rest}
                      row={{ original: row }}
                      cell={{ column: { ...rest } }}
                      padding="checkbox"
                    />
                  ))}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </StyledTable>
      )}
    </StyledWrapper>
  );
};

DetailsTable.propTypes = {
  cells: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      id: PropTypes.string,
      Cell: PropTypes.func,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  showHeadCells: PropTypes.bool,
  showDivider: PropTypes.bool,
  minWidth: PropTypes.string,
};
DetailsTable.defaultProps = {
  showHeadCells: false,
  showDivider: true,
  minWidth: null,
};

export default DetailsTable;
