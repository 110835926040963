import { gql } from '@apollo/client';

export const TEAM_FRAGMENT = gql`
  fragment f_Team on Team {
    id
    name
    membersCount
    customer {
      id
      name
      clientId
      billingDetails {
        billingEmail
      }
    }
  }
`;

export const GET_TEAMS = gql`
  query getTeams($cursor: PaginationInput) {
    getTeams(cursor: $cursor) {
      teams {
        ...f_Team
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        totalSize
      }
    }
  }
  ${TEAM_FRAGMENT}
`;

export const SEARCH_TEAMS = gql`
  query searchTeams($input: String, $cursor: PaginationInput) {
    searchTeams(input: $input, cursor: $cursor) {
      teams {
        ...f_Team
      }
      pageInfo {
        totalSize
      }
    }
  }
  ${TEAM_FRAGMENT}
`;
