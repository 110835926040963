import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { matchPath, useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import ConfirmationModal from '../../../../modals/ConfirmationModal';
import { DELETE_PAYMENT_VIEW } from '../../../../../utils/queries/payments/paymentsMutation';
import { GQL_Q_LIST_PAYMENT_VIEW } from '../../../../../utils/queries/payments/paymentsQueries';
import { UI_ROUTES } from '../../../../../constants/routes';
import ToastCustomContainer from '../../../../ToastCustomContainer';
import { TOAST_TIMEOUT } from '../../../../../constants/toasts';

const mutateOptions = {
  refetchQueries: [{ query: GQL_Q_LIST_PAYMENT_VIEW }],
  awaitRefetchQueries: true,
};

const TITLE = 'Are you sure you want to delete the saved view?';
const TOAST_TITLE = 'Delete saved view';

const DialogDeleteView = ({ viewId, viewName, handleOnClose }) => {
  const history = useHistory();
  const [deletePaymentView, { loading }] = useMutation(DELETE_PAYMENT_VIEW, mutateOptions);

  const confirmHandler = useCallback(() => {
    return deletePaymentView({ variables: { id: viewId } }).then(({ errors, data }) => {
      if (errors) {
        NotificationManager.error(
          <ToastCustomContainer message={errors?.[0]?.message} title={TOAST_TITLE} />,
          null,
          TOAST_TIMEOUT
        );

        return;
      }

      if (data) {
        const { location } = history;
        const match = matchPath(location.pathname, '/payments/views/:viewId');
        if (viewId === match?.params?.viewId) {
          history.replace(UI_ROUTES.payments);
        }

        NotificationManager.success(
          <ToastCustomContainer message={`${viewName} successfully deleted.`} title={TOAST_TITLE} />,
          null,
          TOAST_TIMEOUT
        );

        handleOnClose();
      }
    });
  }, [history, viewId, handleOnClose, deletePaymentView, viewName]);

  return (
    <ConfirmationModal
      loading={loading}
      open={Boolean(viewId)}
      onConfirm={confirmHandler}
      onClose={handleOnClose}
      onCancel={handleOnClose}
      text={{
        title: TITLE,
        description: `You're about to delete ${viewName}.`,
        submit: 'Delete',
        cancel: 'Cancel',
      }}
    />
  );
};

DialogDeleteView.propTypes = {
  viewId: PropTypes.string.isRequired,
  viewName: PropTypes.string.isRequired,
  handleOnClose: PropTypes.func.isRequired,
};

export default DialogDeleteView;
