import React, { useMemo } from 'react';
import { arrayOf, shape, string, func } from 'prop-types';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { StyledButtonCard, StyledCardHeaderBox, TemplateTitle } from './styled';
import { AUTOMATION_PLAN_LABEL_MAP, AUTOMATION_PLAN_DICTIONARY } from '../../../PremiumDialog/constant';
import { getAutomationTemplateIcon } from '../../../../../constants/getAutomationTemplateIcon';
import CircleWithIcon from '../../../../../components/atoms/CircleWithIcon/CircleWithIcon';

const RecommendedItem = ({ template, setOverviewData }) => {
  /**
   * showPremium scenario
   * 1 - plan is advanced plan
   */
  const { t } = useTranslation();
  const showPlanTag = template?.plan?.toLowerCase() === AUTOMATION_PLAN_DICTIONARY.PREMIUM?.toLowerCase();
  const planTag = AUTOMATION_PLAN_LABEL_MAP[template?.plan] || '';

  const icons = useMemo(() => getAutomationTemplateIcon(template?.useCases) || [], [template?.useCases]);

  return (
    <StyledButtonCard onClick={() => setOverviewData({ template, showPlanTag, planTag })}>
      <StyledCardHeaderBox>
        <Box display="flex">
          {icons?.map(
            ({ Icon, color, key }, index) =>
              Icon && (
                <CircleWithIcon key={key} array={icons} index={index}>
                  <Icon size={20} color={color} />
                </CircleWithIcon>
              )
          )}
        </Box>
      </StyledCardHeaderBox>
      <Box display="flex" flex={1} flexDirection="column" justifyContent="space-between" mt="24px">
        <Box>
          <TemplateTitle lineClamp={3} margin="0 0 4px 0">
            {t(template?.name)}
          </TemplateTitle>
        </Box>
      </Box>
    </StyledButtonCard>
  );
};

RecommendedItem.propTypes = {
  template: shape({
    categories: arrayOf(string),
    color: string,
    icon: string,
    description: string,
    name: string,
    plan: string,
  }).isRequired,
  setOverviewData: func.isRequired,
};

export default RecommendedItem;
