import React from 'react';
import styled from 'styled-components';
import { string, number } from 'prop-types';
import { BlockWrap } from '../../atoms';

import Select from '../_common/StyledSelectOnboarding';

const PaperComponent = styled.div`
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04) !important;
  border-radius: 6px !important;
  margin-top: 0px;
  height: 214px;
`;

const CustomSelectOnboarding = ({ label, index, ...rest }) => (
  <BlockWrap margin="0 0 16px">
    <Select paperComponent={PaperComponent} label={label} {...rest} />
  </BlockWrap>
);

CustomSelectOnboarding.propTypes = {
  label: string,
  index: number,
};

CustomSelectOnboarding.defaultProps = {
  label: '',
  index: 0,
};
export default CustomSelectOnboarding;
