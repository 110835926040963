import { MarkerType } from 'react-flow-renderer';
import THEME from '../../../constants/theme';

const getMarker = (selected) => ({
  type: MarkerType.Arrow,
  width: 22,
  height: 22,
  color: !selected ? THEME.greyColors.grey8 : THEME.secondaryColors.secondary,
});

export const edgeMarkers = [
  {
    id: 'default-marker',
    source: 'default-marker-source',
    target: 'default-marker-target',
    markerEnd: getMarker(),
  },
  {
    id: 'selected-marker',
    source: 'selected-marker-source',
    target: 'selected-marker-target',
    markerEnd: getMarker(true),
  },
];
