import React from 'react';
import { useTranslation } from 'react-i18next';
import { arrayOf, bool, string, func, shape } from 'prop-types';
import Box from '@mui/material/Box';
import compact from 'lodash/compact';
import { StyledDialogActions, StyledDialogContent } from '../../../Dialog/styled';
import { ButtonRounded, P16 } from '../../../atoms';
import ArrowRight from '../../../../assets/arrows/arrowRight';
import THEME from '../../../../constants/theme';
import { SWITCH_ENV_STEPS } from '../../constant';
import { H2B } from '../../../atoms/Typography/stories/H2B';
import { StyledDescriptionContentBox } from '../../styled';
import AutomationsContent from './AutomationsContent';
import { useGlobalContext } from '../../../../containers/App/context';
import { PROD_URL } from '../../../../constants/api';
import { UI_ROUTES } from '../../../../constants/routes';

const MigrateStep = ({ automations, selectedAutomationIds, setSelectedAutomationIds, loading }) => {
  const { t } = useTranslation();
  const { currentSwitchEnvStep, setCurrentSwitchEnvStep, setIsOpenSwitchEnvModal } = useGlobalContext();
  const isMigrateAutomationOnly = currentSwitchEnvStep === SWITCH_ENV_STEPS.MIGRATE_AUTOMATIONS_ONLY;

  const onSelect = ({ target }) => {
    const { name, checked } = target;
    if (checked) {
      setSelectedAutomationIds(compact([...(selectedAutomationIds ?? []), name]));
    } else {
      setSelectedAutomationIds((selectedAutomationIds ?? []).filter((id) => id !== name));
    }
  };

  const handleMigrate = () => {
    setCurrentSwitchEnvStep(SWITCH_ENV_STEPS.LOADING);
  };

  const handleSkipMigrate = () => {
    window.open(`${PROD_URL}${UI_ROUTES.checkYourEmail}`, '_blank');
    setIsOpenSwitchEnvModal(false);
    setCurrentSwitchEnvStep(SWITCH_ENV_STEPS.VIDEO);
  };

  return (
    <>
      <StyledDialogContent px="0">
        <Box display="flex" height="100%">
          <StyledDescriptionContentBox width="50%" px="32px">
            <H2B margin="0 0 24px 0">{t('Select automations to migrate.')}</H2B>
            <P16 color={THEME.greyColors.grey600}>
              {t(
                `Select which Automations you want to export to your production account. We'll securely port them for you - all you have to do is log into your production account, re-authenticate your connections and launch your Automations`
              )}
            </P16>
          </StyledDescriptionContentBox>
          <AutomationsContent
            automations={automations}
            selectedAutomationIds={selectedAutomationIds}
            loading={loading}
            onSelect={onSelect}
          />
        </Box>
      </StyledDialogContent>
      <StyledDialogActions $justifyContent="flex-end" px="32px" py="16px" $borderTop>
        {!isMigrateAutomationOnly && (
          <ButtonRounded type="button" color="inherit" onClick={handleSkipMigrate}>
            {t('Skip')}
          </ButtonRounded>
        )}
        <ButtonRounded
          type="button"
          variant="contained"
          color="primary"
          borderRadius="6px"
          endIcon={<ArrowRight size={24} color={`${THEME.primaryColors.white}`} />}
          disabled={!selectedAutomationIds?.length}
          onClick={handleMigrate}
        >
          {isMigrateAutomationOnly ? t('Next') : t('Migrate')}
        </ButtonRounded>
      </StyledDialogActions>
    </>
  );
};

MigrateStep.propTypes = {
  automations: arrayOf(shape({})),
  selectedAutomationIds: arrayOf(string),
  setSelectedAutomationIds: func,
  loading: bool,
};

MigrateStep.defaultProps = {
  automations: [],
  selectedAutomationIds: [],
  setSelectedAutomationIds: () => {},
  loading: false,
};

export default MigrateStep;
