import React from 'react';
import { number, string } from 'prop-types';

const Zap = ({ size, color }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.93206 0.721796C9.20353 0.839641 9.36482 1.12235 9.32811 1.41602L8.75511 6H13.9999C14.2586 6 14.4939 6.14964 14.6037 6.38389C14.7134 6.61815 14.6777 6.89473 14.5121 7.09345L7.84541 15.0935C7.65594 15.3208 7.33927 15.396 7.0678 15.2782C6.79632 15.1603 6.63503 14.8776 6.67174 14.584L7.24474 10H1.99993C1.74125 10 1.50592 9.85036 1.3962 9.6161C1.28648 9.38184 1.32218 9.10526 1.48778 8.90654L8.15445 0.90654C8.34391 0.679184 8.66058 0.60395 8.93206 0.721796ZM3.42329 8.66666H7.99993C8.19115 8.66666 8.37316 8.74877 8.49971 8.89213C8.62626 9.03548 8.68516 9.22628 8.66145 9.41602L8.27682 12.493L12.5766 7.33333H7.99993C7.80871 7.33333 7.62669 7.25122 7.50014 7.10786C7.37359 6.96451 7.31469 6.77372 7.33841 6.58397L7.72303 3.50697L3.42329 8.66666Z"
      fill={color}
    />
  </svg>
);

Zap.propTypes = {
  size: number,
  color: string,
};

Zap.defaultProps = {
  size: 16,
  color: '#0CC48C',
};

export default Zap;
