import React, { useMemo } from 'react';
import { shape, string, arrayOf } from 'prop-types';
import Box from '@mui/material/Box/index';
import { useTranslation } from 'react-i18next/src/index';
import Processor from '../../../../../components/table/Processor';
import reduceValue from '../../_utils/reduceValue';
import CustomerDetails from './CustomerDetails';
import { P14, P16B } from '../../../../../components/atoms';
import { StyledTable } from '../../../../../components/GridSystem/styled';
import THEME from '../../../../../constants/theme';
import { isDefined } from '../../../../../utils/helpers';

const GUEST_CUSTOMER = 'Guest';

const Customer = ({ data, vaultedCustomerId }) => {
  const { t } = useTranslation();
  const { name, logo } = useMemo(() => {
    const paymentCustomer = Array.isArray(data.value) ? reduceValue(data.value) : data;

    return {
      name: isDefined(paymentCustomer.name) ? paymentCustomer.name || GUEST_CUSTOMER : GUEST_CUSTOMER,
    };
  }, [data]);

  return (
    <Box component="section" mt="40px">
      <P16B>{t('Customer')}</P16B>

      <StyledTable>
        <div>
          <P14 color={THEME.greyColors.grey1}>{t('Name')}</P14>
          <Box display="flex" alignItems="center">
            <Processor logo={logo} name={name} size={20} vaultedCustomerId={vaultedCustomerId} />
          </Box>
        </div>
        {name !== GUEST_CUSTOMER && <CustomerDetails data={data} />}
      </StyledTable>
    </Box>
  );
};

Customer.propTypes = {
  data: shape({
    value: arrayOf(
      shape({
        key: string.isRequired,
        value: string,
      })
    ),
    location: string,
  }).isRequired,
  vaultedCustomerId: string,
};

Customer.defaultProps = {
  vaultedCustomerId: undefined,
};
export default Customer;
