import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import omit from 'lodash/omit';
import { useNotificationManager } from '../../../../hooks/useNotificationManager';
import { GET_BRAND } from '../../../../utils/queries/brandCenter/brandCenterQueries';

export const useBrandCenterData = () => {
  const { t } = useTranslation();

  const { data, loading, error } = useQuery(GET_BRAND);
  useNotificationManager('error', error?.message, t('Brand Center'), 5000);

  const { graphics, masterTemplate, socialNetworks } = useMemo(() => {
    const brand = data?.we?.brand;
    const templateConfig = brand?.templateConfig;
    const removeTypenameFromSocialNetworks = data?.we?.brand?.socialNetworks.map((el) => omit(el, ['__typename']));

    return {
      socialNetworks: removeTypenameFromSocialNetworks || [],
      graphics: {
        favicon: {
          name: 'favicon',
          contentType: brand?.faviconContentType,
          objectKey: brand?.faviconObjectKey,
        },
        logo: {
          name: 'logo',
          contentType: brand?.logoContentType,
          objectKey: brand?.logoObjectKey,
        },
      },
      masterTemplate: {
        signOffContent: templateConfig?.signOffContent,
        footerText: templateConfig?.footerText,
        logoConfig: {
          logoType: templateConfig?.logoType,
          logoPosition: templateConfig?.logoPosition,
          logoSize: templateConfig?.logoSize,
          logoText: templateConfig?.logoText,
        },
      },
    };
  }, [data?.we?.brand]);

  return {
    data,
    loading,
    graphics,
    masterTemplate,
    socialNetworks,
  };
};
