export const AUTOMATION_TYPES = {
  FREEFORM_SUBSTAGE_AUTOMATION: 'FREEFORM_SUBSTAGE_AUTOMATION',
  PAYMENT_FLOW: 'PAYMENT_FLOW',
  SUBSTAGE_AUTHORIZE_FLOW: 'SUBSTAGE_AUTHORIZE_FLOW',
  CARD_ORCHESTRATION: 'CARD_ORCHESTRATION',
  SUBSTAGE_CAPTURE_FLOW: 'SUBSTAGE_CAPTURE_FLOW',
  STAGE_SETTLEMENT_FLOW: 'STAGE_SETTLEMENT_FLOW',
  SUBSTAGE_SETTLEMENT_FLOW: 'SUBSTAGE_SETTLEMENT_FLOW',
  SUBSTAGE_AUTOMATION: 'SUBSTAGE_AUTOMATION',
  STAGE_MANAGE_FLOW: 'STAGE_MANAGE_FLOW',
  CARD_CAPTURE: 'CARD_CAPTURE',
};

export const SETUP_GUIDE_KEY = 'setup-guide.key';
