import styled from 'styled-components';
import THEME from '../../../../../../../constants/theme';

const StyledWrapper = styled.div`
  padding: 12px 16px;
  display: flex;
  transition: all 0.1s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: ${THEME.greyColors.grey24};
  }

  &:not(:first-child) {
    border-top: 1px solid ${THEME.greyColors.grey200};
  }
`;

const IconWrapper = styled.div`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: ${THEME.greyColors.grey16};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`;

const SubmissionDetails = styled.div`
  p:last-child {
    text-transform: uppercase;
  }
`;

export { StyledWrapper, IconWrapper, SubmissionDetails };
