import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { useDropzone } from 'react-dropzone';
import { v4 } from 'uuid';
import { useNotificationManager, useFileUploadNotification } from '../../../../hooks/useNotificationManager';

import { GET_UPLOAD_URL } from '../../../../utils/queries/uploadFile/getUploadUrl';
import Uploader from './Uploader';
import UploadHeader from './Header';
import { StyledUpload } from './styled';
import { MAX_FILE_SIZE, ACCEPTED_IMAGE_FILE_TYPES } from '../../../../utils/fileSelectReader';

const dropZoneConfig = {
  accept: ACCEPTED_IMAGE_FILE_TYPES,
  maxFiles: 1,
  maxSize: MAX_FILE_SIZE,
};

const UploadBox = ({ title, handleGraphics, graphics }) => {
  const [file, setFile] = useState(null);

  const [getUploadUrl, { error }] = useLazyQuery(GET_UPLOAD_URL, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (file?.type.match('image.*') && getUploadUrl) {
        handleGraphics(data.getUploadUrl, file, title);
      }
    },
  });

  useNotificationManager('error', error?.message, 'Upload Graphics', 5000);

  const onDrop = (acceptedFiles) => {
    const image = acceptedFiles[0];
    setFile(image);

    getUploadUrl({
      variables: { urlType: 'BRAND', fileName: v4() },
    });
  };

  const dropZone = useDropzone({ onDrop, ...dropZoneConfig });

  useFileUploadNotification('5mb', 'Graphics', dropZone.fileRejections);

  const handleButtonClick = () => {
    dropZone.open();
  };

  return (
    <StyledUpload>
      <UploadHeader title={title} />
      <Uploader
        title={title}
        graphics={graphics}
        dragging={dropZone.isDragActive}
        handleClick={handleButtonClick}
        getRootProps={dropZone.getRootProps}
      />
      <input {...dropZone.getInputProps()} />
    </StyledUpload>
  );
};

UploadBox.propTypes = {
  title: PropTypes.string.isRequired,
  handleGraphics: PropTypes.func.isRequired,
  graphics: PropTypes.string,
};
UploadBox.defaultProps = {
  graphics: '',
};
export default UploadBox;
