import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSignUpFormFooter = styled.div`
  position: relative;
  margin-top: 40px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;

  button {
    margin-left: 8px;
  }
`;

const SignUpFormFooter = ({ children }) => {
  return <StyledSignUpFormFooter>{children}</StyledSignUpFormFooter>;
};
SignUpFormFooter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
SignUpFormFooter.defaultProps = {
  children: null,
};

export default SignUpFormFooter;
