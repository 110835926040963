import React from 'react';
import { string } from 'prop-types';
import Box from '@mui/material/Box';
import Skeleton from '@mui/lab/Skeleton';

const COMMON_PROPS = {
  borderRadius: '8px',
  overflow: 'hidden',
};

const INPUTES = [
  { labelWidth: '64px', inputWidth: '200px' },
  { labelWidth: '100px', inputWidth: '240px' },
  { labelWidth: '72px', inputWidth: '160px' },
  { labelWidth: '64px', inputWidth: '280px' },
];

const InputSkeleton = ({ labelWidth, inputWidth }) => {
  return (
    <>
      <Box {...COMMON_PROPS} mb="6px" width={labelWidth}>
        <Skeleton variant="rectangular" height={16} width="100%" animation="wave" />
      </Box>

      <Box {...COMMON_PROPS} mb="24px" width={inputWidth}>
        <Skeleton variant="rectangular" height={32} width="100%" animation="wave" />
      </Box>
    </>
  );
};

InputSkeleton.propTypes = {
  labelWidth: string.isRequired,
  inputWidth: string.isRequired,
};

const SubmittedEvidenceLoadingState = () => {
  return (
    <Box width="100%" padding="32px" marginTop="72px">
      {INPUTES.map(({ labelWidth, inputWidth }) => (
        <InputSkeleton labelWidth={labelWidth} inputWidth={inputWidth} />
      ))}
    </Box>
  );
};

export default SubmittedEvidenceLoadingState;
