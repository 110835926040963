import { useState } from 'react';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useGlobalContext } from '../../../../../../../containers/App/context';
import { generateFormData } from '../constant';
import {
  SAVE_DISPUTE_EVIDENCE_SUBMISSION,
  LIST_DISPUTES_EVIDENCE_SUBMISSION,
  SUBMIT_DISPUTE_EVIDENCE_SUBMISSION,
} from '../../../../../../../utils/queries/disputes/listDisputes';
import { getOperationNameForQuery } from '../../../../../../../utils/graphql';
import { GET_PAYMENT } from '../../../../../../../utils/queries/payments/paymentsQueries';
import { isDefined } from '../../../../../../../utils/helpers';
import { SAVE_DISPUTE_TOAST_TITLE, SUBMIT_DISPUTE_TOAST_TITLE, showErrorNotification } from './constant';

const useDisputeEvidenceSubmissionActions = (
  initialValues,
  submissionId,
  disputeId,
  formikRef,
  setShouldDrawerOpen,
  setShouldModalOpen,
  setDisabled,
  selectedDropdown,
  filteredInputIds
) => {
  const { setGlobalLoading } = useGlobalContext();
  const { issueId, issueDetails } = useParams();
  const [isSaving, setIsSaving] = useState(null);

  const [saveDisputeEvidenceSubmission] = useMutation(SAVE_DISPUTE_EVIDENCE_SUBMISSION, {
    refetchQueries: [getOperationNameForQuery(LIST_DISPUTES_EVIDENCE_SUBMISSION)],
    context: { skipGlobalHandling: true },
  });

  const [submiteDisputeEvidenceSubmission] = useMutation(SUBMIT_DISPUTE_EVIDENCE_SUBMISSION, {
    refetchQueries: [
      getOperationNameForQuery(LIST_DISPUTES_EVIDENCE_SUBMISSION),
      getOperationNameForQuery(GET_PAYMENT(issueId || issueDetails)),
    ],
    context: { skipGlobalHandling: true },
  });

  /*
    Handle save
  */
  const handleSave = async () => {
    setGlobalLoading('saveDisputeEvidenceSubmission', true);
    setIsSaving(true);

    const submissionData = generateFormData(submissionId, disputeId, formikRef.current.values, filteredInputIds);

    const { errors } = await saveDisputeEvidenceSubmission({
      variables: { submission: submissionData },
    });

    const hasNoErrors = isEmpty(errors);

    if (hasNoErrors) {
      setShouldDrawerOpen(false);
    }

    if (!hasNoErrors) {
      showErrorNotification(errors, SAVE_DISPUTE_TOAST_TITLE);
    }

    setGlobalLoading('saveDisputeEvidenceSubmission', false);
    setIsSaving(false);
  };

  /*
    Handle submit
  */
  const handleSubmit = async (values) => {
    setGlobalLoading('submitDisputeEvidenceSubmission', true);
    setDisabled(true);

    /*
      Save the form and then call submiteDisputeEvidenceSubmission if
      - there is no draft submission
      - there is a draft submission but user executes Submit after updating the form without saving
    */
    if (!isDefined(submissionId) || !isEqual(initialValues, values)) {
      const submissionData = generateFormData(submissionId, disputeId, formikRef.current.values, filteredInputIds);

      // Save submission
      const { data, errors: saveSubmissionErrors } = await saveDisputeEvidenceSubmission({
        variables: { submission: submissionData },
      });

      if (!isEmpty(saveSubmissionErrors)) {
        showErrorNotification(saveSubmissionErrors, SUBMIT_DISPUTE_TOAST_TITLE);

        setGlobalLoading('submitDisputeEvidenceSubmission', false);
        setShouldModalOpen(false);
        setDisabled(false);

        return;
      }

      // Submit submission
      const { errors } = await submiteDisputeEvidenceSubmission({
        variables: { submissionId: data?.saveDisputeEvidenceSubmission.id },
      });

      const hasNoErrors = isEmpty(errors);

      if (hasNoErrors) {
        setShouldDrawerOpen(false);
      }

      if (!hasNoErrors) {
        showErrorNotification(errors, SUBMIT_DISPUTE_TOAST_TITLE);
      }

      setGlobalLoading('submitDisputeEvidenceSubmission', false);
      return;
    }

    /*
      Call submiteDisputeEvidenceSubmission on draft submission
    */
    const { errors } = await submiteDisputeEvidenceSubmission({
      variables: { submissionId },
    });

    const hasNoErrors = isEmpty(errors);

    if (hasNoErrors) {
      setShouldDrawerOpen(false);
    }

    if (!hasNoErrors) {
      showErrorNotification(errors, SUBMIT_DISPUTE_TOAST_TITLE);

      setShouldModalOpen(false);
      setDisabled(false);
    }

    setGlobalLoading('submitDisputeEvidenceSubmission', false);
  };

  return { handleSave, handleSubmit, isSaving };
};

export default useDisputeEvidenceSubmissionActions;
