import React from 'react';

const PaymentProvider = (props) => (
  <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.204 0.488884C18.5356 0.405939 18.8817 0.399631 19.2161 0.470439C19.5505 0.541247 19.8644 0.687309 20.1339 0.897538C20.4034 1.10777 20.6214 1.37663 20.7715 1.68373C20.9216 1.99083 20.9997 2.32808 21 2.66988V4.49988H21.75C22.3467 4.49988 22.919 4.73694 23.341 5.15889C23.7629 5.58085 24 6.15315 24 6.74988V20.2499C24 20.8466 23.7629 21.4189 23.341 21.8409C22.919 22.2628 22.3467 22.4999 21.75 22.4999H2.25C1.65326 22.4999 1.08097 22.2628 0.65901 21.8409C0.237053 21.4189 1.47137e-07 20.8466 1.47137e-07 20.2499V6.74988C-0.000209191 6.17066 0.222964 5.61368 0.623066 5.19485C1.02317 4.77603 1.56938 4.52764 2.148 4.50138L18.204 0.488884ZM8.343 4.49988H19.5V2.66988C19.4997 2.55609 19.4736 2.44384 19.4235 2.34165C19.3735 2.23945 19.3008 2.14999 19.2111 2.08004C19.1213 2.01008 19.0168 1.96147 18.9055 1.93788C18.7941 1.91429 18.6789 1.91634 18.5685 1.94388L8.343 4.49988ZM2.25 5.99988C2.05109 5.99988 1.86032 6.0789 1.71967 6.21955C1.57902 6.36021 1.5 6.55097 1.5 6.74988V20.2499C1.5 20.4488 1.57902 20.6396 1.71967 20.7802C1.86032 20.9209 2.05109 20.9999 2.25 20.9999H21.75C21.9489 20.9999 22.1397 20.9209 22.2803 20.7802C22.421 20.6396 22.5 20.4488 22.5 20.2499V6.74988C22.5 6.55097 22.421 6.36021 22.2803 6.21955C22.1397 6.0789 21.9489 5.99988 21.75 5.99988H2.25Z"
      fill="black"
    />
  </svg>
);

export default PaymentProvider;
