import { PAYMENT_DESCRIBED_KEYS } from './paymentDescribedDataKeys';

const PAYMENT_DETAILS_DATA_LABEL_MAP = {
  [PAYMENT_DESCRIBED_KEYS.localCurrency]: 'Local Currency',
  [PAYMENT_DESCRIBED_KEYS.localAmount]: 'Local Amount',
  [PAYMENT_DESCRIBED_KEYS.exchangeRate]: 'Exchange Rate',
  [PAYMENT_DESCRIBED_KEYS.fee]: 'Fee',
  [PAYMENT_DESCRIBED_KEYS.tax]: 'Tax',
  [PAYMENT_DESCRIBED_KEYS.processingFee]: 'Processing Fee',
  [PAYMENT_DESCRIBED_KEYS.net]: 'Net',
  [PAYMENT_DESCRIBED_KEYS.statementDescriptor]: 'Statement Descriptor',
  [PAYMENT_DESCRIBED_KEYS.sourceId]: 'Payment Processor Id',
  [PAYMENT_DESCRIBED_KEYS.processorDeclineCode]: 'Processor Decline Code',
  [PAYMENT_DESCRIBED_KEYS.reason]: 'Processor Reason',
  [PAYMENT_DESCRIBED_KEYS.declineType]: 'Hard/Soft Decline',
  [PAYMENT_DESCRIBED_KEYS.id]: 'Id',
  [PAYMENT_DESCRIBED_KEYS.number]: 'Number',
  [PAYMENT_DESCRIBED_KEYS.expires]: 'Expires',
  [PAYMENT_DESCRIBED_KEYS.paymentMethod]: 'Payment Method',
  [PAYMENT_DESCRIBED_KEYS.type]: 'Type',
  [PAYMENT_DESCRIBED_KEYS.product]: 'Product',
  [PAYMENT_DESCRIBED_KEYS.issuingBank]: 'Issuing Bank',
  [PAYMENT_DESCRIBED_KEYS.category]: 'Category',
  [PAYMENT_DESCRIBED_KEYS.brand]: 'CateBrandgory',
  [PAYMENT_DESCRIBED_KEYS.name]: 'Name',
  [PAYMENT_DESCRIBED_KEYS.country]: 'Country',
  [PAYMENT_DESCRIBED_KEYS.vaultedCard]: 'Vaulted Card',
  [PAYMENT_DESCRIBED_KEYS.additionalData]: 'Additional Data',
  [PAYMENT_DESCRIBED_KEYS.businessFailureCode]: 'Business Failure Code',
  [PAYMENT_DESCRIBED_KEYS.businessFailureReason]: 'Business Failure Reason',
  [PAYMENT_DESCRIBED_KEYS.fraudProvider]: 'Fraud Provider',
  [PAYMENT_DESCRIBED_KEYS.score]: 'Fraud Score',
  [PAYMENT_DESCRIBED_KEYS.date]: 'Fraud Date',
  [PAYMENT_DESCRIBED_KEYS.fraudDecision]: 'Fraud Decision',
  [PAYMENT_DESCRIBED_KEYS.additionalData]: 'Additional Data',
};

export { PAYMENT_DETAILS_DATA_LABEL_MAP };
