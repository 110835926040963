import React, { useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { number, shape, string } from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { P12, P14M } from '../../atoms';
import THEME from '../../../constants/theme';
import { useFlowEditorContext } from '../../FlowEditor/context';
import { UI_ROUTES } from '../../../constants/routes';
import Play from '../../../assets/icons/VersionControl/Play';
import { L10UM } from '../../atoms/Typography/L10UM';

const PointerBlock = styled(Box)`
  cursor: pointer;
`;

const VersionItem = ({ item, index, arrayLength }) => {
  const { versionId, flowId, draftConfig, config } = useFlowEditorContext();
  const { push } = useHistory();
  const { t } = useTranslation();

  const [isHovering, setIsHovering] = useState(false);
  const isActive = config?.id === item.id;
  const isDraft = draftConfig?.id === item.id;
  const isCurrent = versionId === item.id;

  const openVersion = () => {
    if (isDraft || (isActive && !draftConfig)) {
      push(`${UI_ROUTES.automations}/${flowId}/editor`);
    } else {
      push(`${UI_ROUTES.automations}/${flowId}/version/${item.id}`);
    }
  };

  const timeLabel = useMemo(() => {
    if (isDraft) {
      return 'Updated';
    }
    if (isActive) {
      return 'Started';
    }
    return 'Stopped';
  }, [isActive, isDraft]);

  const time = moment(item.systemUpdated).format('DD MMM, HH:mm');

  return (
    <>
      <PointerBlock
        borderRadius="6px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height="56px"
        p="8px 12px"
        bgcolor={isHovering ? '#f8f8f8' : THEME.primaryColors.white}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={openVersion}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box maxWidth="234px" overflow="hidden">
            <Box display="flex" alignItems="center" mb="2px">
              {isActive && (
                <Box mr="4px" display="flex" alignItems="center">
                  <Play size="17" color={THEME.secondaryColors.greenDark} />
                </Box>
              )}
              <P14M textOverflow="ellipsis" overflow="hidden" noWrap>
                {t(isDraft ? `Version ${arrayLength} (Draft)` : `Version ${arrayLength - index}`)}
              </P14M>
            </Box>
            <P12 textOverflow="ellipsis" overflow="hidden" color={THEME.greyColors.grey600} noWrap>
              {t(`${timeLabel} on`)} {time}
            </P12>
          </Box>
        </Box>
        {((isDraft && !versionId) || isCurrent || (!draftConfig && !versionId && isActive)) && (
          <L10UM color={THEME.greyColors.grey400}>ON CANVAS</L10UM>
        )}
      </PointerBlock>
      {arrayLength > 1 && isDraft && (
        <Box left="-8px" position="relative" width="calc(100% + 16px)" height="1px" bgcolor={THEME.greyColors.grey22} />
      )}
    </>
  );
};

VersionItem.propTypes = {
  item: shape({
    versionName: string.isRequired,
    systemUpdated: string.isRequired,
    systemCreated: string.isRequired,
  }).isRequired,
  index: number.isRequired,
  arrayLength: number.isRequired,
};

export default VersionItem;
