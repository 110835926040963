/* eslint-disable react/no-array-index-key */
import React from 'react';
import { func, string, bool, arrayOf, shape } from 'prop-types';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SubTitle from '../../../../FlowEditor/components/AutomationDetails/SubTitle';
import CircleImage from '../../../../table/CircleImage';
import WhenThenLogo from '../../../../../assets/img/WhenThenLogo.png';
import { COMMON_CONNECTION_STYLE_PROPS, CONTAINER_HEIGHT, getConnectionFormBlock } from '../../constant';
import { H2 } from '../../../../atoms';
import useGenerateBlock from '../../hook/useGenerateBlock';

const ConnectionForm = ({
  connectionName,
  connectionLogo,
  description,
  form,
  showErrors,
  setShowErrors,
  animatedLabel,
}) => {
  const { t } = useTranslation();
  const { generateBlockFn } = useGenerateBlock();
  const { textBlock, inputBlock } = getConnectionFormBlock(form);

  return (
    <Box display="flex" pb="0">
      <Box
        {...COMMON_CONNECTION_STYLE_PROPS}
        borderRight="1px solid rgba(193, 195, 198, 0.2)"
        pl="0"
        height={CONTAINER_HEIGHT}
        justifyContent="flex-start"
      >
        <Box display="flex" alignItems="center" mb="32px">
          <Box display="flex" alignItems="center" position="relative" zIndex={1}>
            <CircleImage text="WhenThen" logo={WhenThenLogo} size={44} />
          </Box>
          <Box display="flex" alignItems="center" position="relative" left="-4px">
            <CircleImage text={connectionName} logo={connectionLogo} size={44} />
          </Box>
        </Box>

        <H2 fontWeight="700">
          {t('Allow WhenThen to')}
          <br />
          {t('access your')}
          <br />
          {connectionName} {t('account')}
        </H2>

        <SubTitle mt="24px">{t(description)}</SubTitle>

        <Box mt="24px">
          {textBlock?.map((item, index) => {
            const Input = generateBlockFn(item);

            return (
              <Box key={`${item.type}-${item.block_id}-${index}`}>
                <Input item={item} />
              </Box>
            );
          })}
        </Box>
      </Box>

      <Box {...COMMON_CONNECTION_STYLE_PROPS} pt="60px" flex="1 0 520px" height={CONTAINER_HEIGHT}>
        {inputBlock?.map((item, index) => {
          const Input = generateBlockFn(item);

          return (
            <Box width="100%" key={`${item.type}-${item.block_id}-${index}`}>
              <Input item={item} showErrors={showErrors} setShowErrors={setShowErrors} animatedLabel={animatedLabel} />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

ConnectionForm.propTypes = {
  connectionName: string.isRequired,
  connectionLogo: string.isRequired,
  description: string.isRequired,
  form: arrayOf(shape({})).isRequired,
  showErrors: bool,
  setShowErrors: func,
  animatedLabel: bool,
};

ConnectionForm.defaultProps = {
  showErrors: false,
  setShowErrors: null,
  animatedLabel: false,
};

export default ConnectionForm;
