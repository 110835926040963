import PropTypes from 'prop-types';
import React from 'react';

const Translate = ({ fill }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 9C7 8.44772 7.44772 8 8 8H16C16.5523 8 17 8.44772 17 9C17 9.55229 16.5523 10 16 10H8C7.44772 10 7 9.55229 7 9Z"
        fill={fill}
      />
      <path
        d="M8 12C7.44772 12 7 12.4477 7 13C7 13.5523 7.44772 14 8 14H12C12.5523 14 13 13.5523 13 13C13 12.4477 12.5523 12 12 12H8Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.87429 3.90187C4.53087 2.01939 7.38921 1 12 1C16.6108 1 19.4691 2.01939 21.1257 3.90187C22.7638 5.76332 23 8.22786 23 10.5C23 12.7773 22.7576 15.1557 21.5923 16.9766C20.4291 18.794 18.471 19.8807 15.5069 19.9907L13.6 22.5333C12.8 23.6 11.2 23.6 10.4 22.5333L8.49306 19.9907C5.52903 19.8807 3.57092 18.794 2.40773 16.9766C1.24241 15.1557 1 12.7773 1 10.5C1 8.22786 1.23622 5.76332 2.87429 3.90187ZM4.37571 5.22313C3.26378 6.48668 3 8.27214 3 10.5C3 12.7227 3.25759 14.5943 4.09227 15.8984C4.864 17.1043 6.24939 18 9 18C9.31476 18 9.61115 18.1482 9.8 18.4L12 21.3333L14.2 18.4C14.3889 18.1482 14.6852 18 15 18C17.7506 18 19.136 17.1043 19.9077 15.8984C20.7424 14.5943 21 12.7227 21 10.5C21 8.27214 20.7362 6.48668 19.6243 5.22313C18.5309 3.98061 16.3892 3 12 3C7.61079 3 5.46912 3.98061 4.37571 5.22313Z"
        fill={fill}
      />
    </svg>
  );
};

Translate.propTypes = {
  fill: PropTypes.string,
};
Translate.defaultProps = {
  fill: '#787F88',
};

export default Translate;
