const HEAD_CELL_KEYS = {
  currencyCode: 'currencyCode',
  merchantCategoryCode: 'merchantCategoryCode',
  paymentMethod: 'paymentMethod',
  bankName: 'bankName',
  binCode: 'binCode',
  countryCode: 'countryCode',
  cardBrand: 'cardBrand',
  cardType: 'cardType',
  cardProduct: 'cardProduct',
  destination: 'destination',
};

const HEAD_CELLS = [
  { id: HEAD_CELL_KEYS.currencyCode, name: 'Currency' },
  { id: HEAD_CELL_KEYS.merchantCategoryCode, name: 'MCC' },
  { id: HEAD_CELL_KEYS.paymentMethod, name: 'Payment Method' },
  { id: HEAD_CELL_KEYS.bankName, name: 'Bank name' },
  { id: HEAD_CELL_KEYS.binCode, name: 'Bank BIN' },
  { id: HEAD_CELL_KEYS.countryCode, name: 'Country code' },
  { id: HEAD_CELL_KEYS.cardBrand, name: 'Card brand' },
  { id: HEAD_CELL_KEYS.cardType, name: 'Card type' },
  { id: HEAD_CELL_KEYS.cardProduct, name: 'Card prouct' },
  { id: HEAD_CELL_KEYS.destination, name: 'Destination' },
];

export { HEAD_CELL_KEYS, HEAD_CELLS };
