import React from 'react';
import QueryTypeSection from './sections/QueryTypeSection';
import QueryInfo from './QueryInfo';

const DataLookupStep = () => {
  return (
    <>
      <QueryInfo />

      <QueryTypeSection />
    </>
  );
};

export default DataLookupStep;
