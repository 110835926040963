import { isEmpty } from 'lodash';
import { showNotification } from '../../../../hooks/useNotificationManager';
import { TOAST_TYPES, TOAST_TIMEOUT } from '../../../../constants/toasts';

const transformDataToCustomerObject = ({
  addressLine1: line1,
  addressLine2: line2,
  city,
  postalCode,
  state,
  country,
  platformUrl,
  domain,
  ...rest
}) => {
  const { billingEmail, otherBillingEmails } = rest;
  const _billingEmail = isEmpty(billingEmail) && !isEmpty(otherBillingEmails) ? otherBillingEmails[0] : billingEmail;

  let extractedDomain = '';

  if (platformUrl) {
    const url = new URL(platformUrl);
    extractedDomain = url.hostname.replace(/^www\./, '');
  }

  return {
    domain: domain || extractedDomain,
    address: { line1, line2, city, postalCode, state, country },
    billingEmail: _billingEmail,
    platformUrl,
    ...rest,
  };
};

const CUSTOM_ERRORS = {
  'user.unauthenticated': 'User is unauthenticated - Please log out, then log back in to continue.',
};

const TOAST_TITLE = 'Signup Mangopay';

const showErrorNotification = (errors) => {
  let errorMessage;

  if (errors[0].message.includes('ERROR: duplicate key value violates unique constraint')) {
    errorMessage = 'Client id already exists.';
  } else {
    const { code } = errors[0]?.extensions;
    errorMessage = CUSTOM_ERRORS[code] ?? 'An unexpected error occurred.';
  }

  showNotification(TOAST_TYPES.error, errorMessage, TOAST_TITLE, TOAST_TIMEOUT);
};

export { transformDataToCustomerObject, showErrorNotification, TOAST_TITLE };
