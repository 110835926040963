import { useCallback } from 'react';
import { useGlobalContext } from '../../containers/App/context';
import { usePermissionsContext } from '../context';
import { getPermission } from '../utils';
import { SETTINGS_PERMISSIONS_IDS } from '../../pages/Settings/permissions';

const usePermissionChecker = () => {
  const { permissionsMap } = usePermissionsContext();
  const { getMeData } = useGlobalContext() ?? {};

  const activePlan = getMeData?.we?.activePlan?.plan?.name;
  const userRole = getMeData?.me?.systemUserRole;

  const checkPermission = useCallback(
    (permissionId) => {
      switch (permissionId) {
        case SETTINGS_PERMISSIONS_IDS.acceptingPayments:
          return getMeData?.we?.acceptingPayments === 'YES';
        case SETTINGS_PERMISSIONS_IDS.notAcceptingPayments:
          return getMeData?.we?.acceptingPayments !== 'YES';
        default:
          return getPermission(permissionId, userRole, activePlan, permissionsMap);
      }
    },
    [getMeData?.we?.acceptingPayments, userRole, activePlan, permissionsMap]
  );

  return { checkPermission };
};

export default usePermissionChecker;
