import { useMemo } from 'react';
import { useGetDIsputesListWithFilter } from './useGetDisputesListWithFilter';
import { useGetDisputesListWithSearch } from './useGetDisputesListWithSearch';
import useSearch from '../useSearch';
import { getFilterData } from './getFilterData';
import { getSearchFilterData } from '../useGetPaymentList/getSearchFilterData';
import { ROWS_PER_PAGE } from '../useGetPaymentList/constant';
import { DISPUTES_SORT_KEYS } from './disputesSortKeys';

const useGetDisputesList = () => {
  const [searchParams] = useSearch();
  const search = searchParams?.search ?? '';
  const after = searchParams?.page ? searchParams.page - 1 : 0;
  const filter = searchParams?.filter;
  const sort = searchParams?.sort;
  const searchFilter = searchParams?.searchFilter;

  const filterOptions = useMemo(
    () => ({
      variables: {
        first: ROWS_PER_PAGE,
        after,
        data: getFilterData({ filter, sort, sortKeys: DISPUTES_SORT_KEYS }),
      },
      skip: Boolean(search),
      fetchPolicy: 'network-only',
    }),
    [after, filter, search, sort]
  );

  const searchOptions = useMemo(
    () => ({
      variables: {
        first: ROWS_PER_PAGE,
        input: search,
        searchFilter: getSearchFilterData(searchFilter),
        after,
      },
    }),
    [after, search, searchFilter]
  );

  const disputesWithFilter = useGetDIsputesListWithFilter(filterOptions);
  const disputesWithSearch = useGetDisputesListWithSearch(searchOptions);

  if (search || searchFilter) {
    return disputesWithSearch;
  }

  return disputesWithFilter;
};

export { useGetDisputesList };
