import React from 'react';
import omit from 'lodash/omit';
import { func } from 'prop-types';
import { Form, Formik } from 'formik';
import SearchInput from './SearchInput';
import THEME from '../../../../../constants/theme';
import useSearch from '../../../../../hooks/useSearch';

const SEARCH_KEY = 'searchTeam';

const Search = ({ setIsSubmit, setTouched }) => {
  const [searchParams, setSearchParams] = useSearch();

  const initialValues = { [SEARCH_KEY]: '' };

  const handleSubmit = () => {
    if (!searchParams?.searchTeam) return;

    setIsSubmit(true);
    setSearchParams((prevSearchParams) => omit(prevSearchParams, 'page'));
  };

  const handleChange = () => {
    setIsSubmit(false);
    setTouched(true);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
      <Form>
        <SearchInput
          name={SEARCH_KEY}
          showInputField
          inputWidth="248px"
          inputMargin="0"
          borderColor={THEME.greyColors.grey200}
          noAutofocus
          handleChange={handleChange}
          searchKey={SEARCH_KEY}
          useOnBlur
          setTouched={setTouched}
        />
      </Form>
    </Formik>
  );
};

Search.propTypes = {
  setIsSubmit: func.isRequired,
  setTouched: func.isRequired,
};

export default Search;
