import React from 'react';
import PropTypes from 'prop-types';

const Filter = ({ size = 20 }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.666748 3.99998C0.666748 3.63179 1.07715 3.33331 1.58341 3.33331H14.4167C14.923 3.33331 15.3334 3.63179 15.3334 3.99998C15.3334 4.36817 14.923 4.66665 14.4167 4.66665H1.58341C1.07715 4.66665 0.666748 4.36817 0.666748 3.99998Z"
      fill="white"
    />
    <path
      d="M2.66675 7.99998C2.66675 7.63179 3.06472 7.33331 3.55564 7.33331H12.4445C12.9354 7.33331 13.3334 7.63179 13.3334 7.99998C13.3334 8.36817 12.9354 8.66665 12.4445 8.66665H3.55564C3.06472 8.66665 2.66675 8.36817 2.66675 7.99998Z"
      fill="white"
    />
    <path
      d="M5.33325 12C5.33325 11.6318 5.63173 11.3333 5.99992 11.3333H9.99992C10.3681 11.3333 10.6666 11.6318 10.6666 12C10.6666 12.3682 10.3681 12.6666 9.99992 12.6666H5.99992C5.63173 12.6666 5.33325 12.3682 5.33325 12Z"
      fill="white"
    />
  </svg>
);

Filter.propTypes = {
  size: PropTypes.number,
};

Filter.defaultProps = {
  size: 20,
};

export default Filter;
