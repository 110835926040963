// deprecated

const confirmDetailsNames = {
  companyName: 'companyName',
  companyWebsite: 'companyWebsite',
  currency: 'currency',
  companySize: 'companySize',
  companyIndustry: 'companyIndustry',
  companyMarkets: 'companyMarkets',
  paymentNeeds: 'paymentNeeds',
};

const confirmDetailsFields = [
  { field: confirmDetailsNames.companyName, type: 'text', label: 'Company Name' },
  { field: confirmDetailsNames.companyWebsite, type: 'text', label: 'Company Website' },
  { field: confirmDetailsNames.companySize, label: 'Company Size' },
  { field: confirmDetailsNames.companyIndustry, label: 'What Industry Do You Operate In?' },
  { field: confirmDetailsNames.companyMarkets, label: 'What Markets Do You Operate In?' },
  { field: confirmDetailsNames.currency, label: 'What Is Your Operating Currency?' },
];

const initialValues = {
  [confirmDetailsNames.companyName]: '',
  [confirmDetailsNames.companyWebsite]: '',
  [confirmDetailsNames.currency]: '',
  [confirmDetailsNames.companySize]: '',
  [confirmDetailsNames.companyIndustry]: [],
  [confirmDetailsNames.companyMarkets]: [],
};

export { initialValues, confirmDetailsFields, confirmDetailsNames };
