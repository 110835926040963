import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import OnboardingLayout from '../../components/layouts/OnboardingLayout';
import OnboardingLayoutBox from '../../components/layouts/OnboardingLayoutBox';
import SmsAuth from '../../components/mfaSetup/SmsAuth';
import AppAuth from '../../components/mfaSetup/AppAuth/AppAuth';
import RecoverySave from '../../components/mfaSetup/RecoverySave';
import Tabs from '../../components/common/Tabs/Tabs';
import { H1 } from '../../components/atoms';

const TITLE_MAP = {
  default: '2-Factor Authentication',
  success: '2-factor authentication completed',
};

const MFASetup = () => {
  const [setupSuccess, setSetupSuccess] = useState(false);

  const tabs = useMemo(
    () => [
      { label: 'SMS', show: true, node: <SmsAuth setSetupSuccess={setSetupSuccess} /> },
      { label: 'Mobile App', show: true, node: <AppAuth setSetupSuccess={setSetupSuccess} /> },
    ],
    []
  );

  return (
    <OnboardingLayout theme="dark">
      <OnboardingLayoutBox theme="dark">
        <Box maxWidth="336px" margin="0 auto">
          <Box mb="40px">
            <H1 fontWeight={700}>{setupSuccess ? TITLE_MAP.success : TITLE_MAP.default}</H1>
          </Box>
          {setupSuccess ? <RecoverySave /> : <Tabs tabs={tabs} />}
        </Box>
      </OnboardingLayoutBox>
    </OnboardingLayout>
  );
};

export default MFASetup;
