import React from 'react';

const Communication = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22Z"
        fill="#0CC48C"
      />
      <path
        d="M15.3475 26.8675C15.4772 26.9977 15.5767 27.155 15.6387 27.3281C15.7007 27.5011 15.7238 27.6857 15.7062 27.8687C15.6184 28.7157 15.4518 29.5527 15.2087 30.3687C16.9525 29.965 18.0175 29.4975 18.5013 29.2525C18.7756 29.1135 19.0916 29.0806 19.3887 29.16C20.2405 29.3871 21.1185 29.5014 22 29.5C26.995 29.5 30.75 25.9913 30.75 22C30.75 18.01 26.995 14.5 22 14.5C17.005 14.5 13.25 18.01 13.25 22C13.25 23.835 14.0213 25.5375 15.3475 26.8675ZM14.7312 31.7487C14.4351 31.8075 14.138 31.8612 13.84 31.91C13.59 31.95 13.4 31.69 13.4987 31.4575C13.6097 31.1957 13.7115 30.9302 13.8037 30.6612L13.8075 30.6488C14.1175 29.7487 14.37 28.7138 14.4625 27.75C12.9288 26.2125 12 24.2 12 22C12 17.1675 16.4775 13.25 22 13.25C27.5225 13.25 32 17.1675 32 22C32 26.8325 27.5225 30.75 22 30.75C21.0096 30.7513 20.0232 30.6227 19.0662 30.3675C18.4162 30.6963 17.0175 31.295 14.7312 31.7487Z"
        fill="white"
      />
    </svg>
  );
};

export default Communication;
