import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { H3 } from '../../../components/atoms';
import CustomerDetailsForm from './CustomerDetailsForm';

const ViewCustomerDetails = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <H3 fontWeight="600" margin="0 0 16px 0">
        {t('View Customer Details')}
      </H3>

      <CustomerDetailsForm />
    </Box>
  );
};

export default ViewCustomerDetails;
