import { useQuery } from '@apollo/client';
import { GET_AVAILABLE_DATA_PROVIDERS } from '../../../../../../utils/queries/properties/editorPropertiesQueries';

const useGetAvailableDataProviders = () => {
  const { data, loading } = useQuery(GET_AVAILABLE_DATA_PROVIDERS, { fetchPolicy: 'no-cache' });

  const availableDataProviders = data?.getAvailableDataProviders;

  return { availableDataProviders, loading };
};

export default useGetAvailableDataProviders;
