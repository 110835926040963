import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TextField } from '@mui/material';
import { bool, func } from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import { StyledDialog, StyledPaper } from '../../../../Dialog/styled';
import Pencil from '../../../../../assets/icons/Pencil';
import { TempH4 } from '../../../../atoms/Typography/H4';
import { ButtonRounded, P14 } from '../../../../atoms';
import THEME from '../../../../../constants/theme';
import { useNodeDataToSave } from '../AutomationStep/fields/hooks/useNodeDataToSave';
import { useFlowEditorContext } from '../../../context';

const StyledInput = styled(TextField)`
  width: 100%;

  .MuiInputBase-root {
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
    input {
      padding: 0;
    }
    border-radius: 4px;
    border: 1px solid ${THEME.greyColors.grey200};
    padding: 10px 12px;
    margin-bottom: 24px;
  }
`;

const AddNickname = ({ open, closeModal }) => {
  const { t } = useTranslation();
  const [nodeDataToSave, updateDataToSave] = useNodeDataToSave();
  const { commitNodeDataToSave, isDataSaving } = useFlowEditorContext();
  const [defaultValue] = useState(nodeDataToSave?.nickname);

  const onChange = ({ target: { value: nicknameValue } }) => {
    updateDataToSave({
      nickname: nicknameValue,
    });
  };

  const onSave = async (e) => {
    await commitNodeDataToSave(e, true);
    closeModal();
  };

  const onCancel = () => {
    updateDataToSave({
      nickname: defaultValue,
    });
    closeModal();
  };

  return (
    <StyledDialog
      open={open}
      scroll="paper"
      maxWidth="xs"
      PaperComponent={StyledPaper}
      aria-labelledby="AddNicknameDialog"
      width="400px"
    >
      <Box display="flex" flexDirection="column" alignItems="center" p="24px">
        <Pencil />
        <TempH4 margin="24px 0 8px 0">{t('Add Nickname')}</TempH4>
        <P14 margin="0 6px 16px 6px" textAlign="center" whiteSpace="pre-line">
          {t('Customize the name for your node so you and your team know exactly what this node is supposed to do')}
        </P14>
        <StyledInput value={nodeDataToSave?.nickname} onChange={onChange} placeholder={t('Name this node')} />
        <Box display="flex" width="100%">
          <ButtonRounded type="button" color="secondary" variant="contained" onClick={onCancel} flex={1}>
            {t('Cancel')}
          </ButtonRounded>
          <ButtonRounded
            type="button"
            color="primary"
            variant="contained"
            onClick={onSave}
            flex={1}
            ml="8px"
            disabled={isDataSaving}
          >
            {isDataSaving ? <CircularProgress size={24} color="inherit" /> : t('Save')}
          </ButtonRounded>
        </Box>
      </Box>
    </StyledDialog>
  );
};

AddNickname.propTypes = {
  open: bool.isRequired,
  closeModal: func.isRequired,
};

export default AddNickname;
