import React from 'react';

const Alerts = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22Z"
        fill="#C40C0C"
      />
      <g clipPath="url(#clip0_1868_13641)">
        <path
          d="M22 30.75C19.6794 30.75 17.4538 29.8281 15.8128 28.1872C14.1719 26.5462 13.25 24.3206 13.25 22C13.25 19.6794 14.1719 17.4538 15.8128 15.8128C17.4538 14.1719 19.6794 13.25 22 13.25C24.3206 13.25 26.5462 14.1719 28.1872 15.8128C29.8281 17.4538 30.75 19.6794 30.75 22C30.75 24.3206 29.8281 26.5462 28.1872 28.1872C26.5462 29.8281 24.3206 30.75 22 30.75ZM22 32C24.6522 32 27.1957 30.9464 29.0711 29.0711C30.9464 27.1957 32 24.6522 32 22C32 19.3478 30.9464 16.8043 29.0711 14.9289C27.1957 13.0536 24.6522 12 22 12C19.3478 12 16.8043 13.0536 14.9289 14.9289C13.0536 16.8043 12 19.3478 12 22C12 24.6522 13.0536 27.1957 14.9289 29.0711C16.8043 30.9464 19.3478 32 22 32Z"
          fill="white"
        />
        <path
          d="M20.7524 25.75C20.7524 25.5859 20.7848 25.4233 20.8476 25.2717C20.9104 25.12 21.0025 24.9822 21.1186 24.8661C21.2346 24.7501 21.3724 24.658 21.5241 24.5952C21.6757 24.5324 21.8383 24.5 22.0024 24.5C22.1666 24.5 22.3291 24.5324 22.4808 24.5952C22.6325 24.658 22.7703 24.7501 22.8863 24.8661C23.0024 24.9822 23.0945 25.12 23.1573 25.2717C23.2201 25.4233 23.2524 25.5859 23.2524 25.75C23.2524 26.0815 23.1207 26.3995 22.8863 26.6339C22.6519 26.8683 22.334 27 22.0024 27C21.6709 27 21.353 26.8683 21.1186 26.6339C20.8841 26.3995 20.7524 26.0815 20.7524 25.75ZM20.8749 18.2438C20.8583 18.0861 20.875 17.9266 20.9239 17.7758C20.9729 17.625 21.053 17.4861 21.1591 17.3683C21.2652 17.2504 21.3948 17.1561 21.5397 17.0916C21.6846 17.0271 21.8414 16.9938 21.9999 16.9938C22.1585 16.9938 22.3153 17.0271 22.4602 17.0916C22.605 17.1561 22.7347 17.2504 22.8408 17.3683C22.9469 17.4861 23.027 17.625 23.0759 17.7758C23.1249 17.9266 23.1416 18.0861 23.1249 18.2438L22.6874 22.6275C22.6727 22.7997 22.5939 22.9602 22.4666 23.0771C22.3393 23.194 22.1728 23.2588 21.9999 23.2588C21.8271 23.2588 21.6606 23.194 21.5332 23.0771C21.4059 22.9602 21.3271 22.7997 21.3124 22.6275L20.8749 18.2438Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1868_13641">
          <rect width="20" height="20" fill="white" transform="translate(12 12)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Alerts;
