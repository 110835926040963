import React from 'react';
import moment from 'moment';
import { StyledTableCell } from './styled';
import { PROPTYPES, FORMATS } from './constant';

const DateCell = ({ row: { original }, cell: { column } }) => {
  const header = column?.Header;
  const date = original[header];
  const transformedDate = date && date !== 'N/A' ? moment(date).calendar(FORMATS) : date ?? 'N/A';

  return (
    <StyledTableCell column={column} id={original?.id}>
      {transformedDate}
    </StyledTableCell>
  );
};
DateCell.propTypes = PROPTYPES;
export default DateCell;
