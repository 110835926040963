import React, { useCallback, useMemo } from 'react';
import { string } from 'prop-types';
import { ActionInputContext } from './actionInputContext';
import { useNodeDataToSave } from '../hooks/useNodeDataToSave';

const findCallback = (id) => ({ inputId }) => inputId === id;

const ActionInputProvider = ({ fieldId, fieldName, fieldType, placeholder, children, validationMessage }) => {
  const [{ actionId, inputMappings, layout }, updateDataToSave] = useNodeDataToSave();
  const fieldValue = useMemo(() => inputMappings?.find(findCallback(fieldId))?.key ?? '', [fieldId, inputMappings]);
  const fieldObjectValue = layout?.uiMetadata?.[fieldId];
  const setInputField = useCallback(
    (value) => {
      const newInput = { inputId: fieldId, key: value };
      const prevInputMappings = inputMappings ?? [];
      const nextInputMappings = prevInputMappings.some(findCallback(fieldId))
        ? prevInputMappings.map((input) => (input.inputId === fieldId ? newInput : input))
        : [...prevInputMappings, newInput];
      updateDataToSave({ inputMappings: nextInputMappings });
    },
    [fieldId, inputMappings, updateDataToSave]
  );

  return (
    <ActionInputContext.Provider
      value={{
        fieldId,
        fieldName,
        fieldType,
        fieldObjectValue,
        placeholder,
        fieldValue,
        setInputField,
        validationMessage,
        actionId,
      }}
    >
      {children}
    </ActionInputContext.Provider>
  );
};

ActionInputProvider.propTypes = {
  fieldId: string.isRequired,
  fieldName: string.isRequired,
  fieldType: string.isRequired,
  placeholder: string,
  validationMessage: string,
};

ActionInputProvider.defaultProps = {
  placeholder: null,
  validationMessage: '',
};

export default ActionInputProvider;
