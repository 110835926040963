import capitalize from '@mui/material/utils/capitalize';
import { CURRENCY_LIST } from '../../../constants/currencyList';
import { signUpCompanyDetailsNames } from './signUpCompanyDetailsSchema';

const OTHERS_VALUE = 'OTHER';

const CUSTOM_SELECT_PROPS = {
  placeholder: 'Select',
};

const generateOptions = (values) =>
  values.map((value) => ({
    value: value
      .split(' ')
      .join('_')
      .toUpperCase(),
    text: { text: value },
  }));

const currencyOptions = Object.keys(CURRENCY_LIST).map((currencyKey) => {
  return {
    value: currencyKey,
    text: { text: `${CURRENCY_LIST[currencyKey].name} ${CURRENCY_LIST[currencyKey].code}` },
  };
});

const sizeOptions = [
  {
    value: 'ONE_10',
    text: { text: '1 - 10' },
  },
  {
    value: 'TEN_100',
    text: { text: '10 - 100' },
  },
  {
    value: 'ONE_HUNDRED_1000',
    text: { text: '100 - 1000' },
  },
  {
    value: 'TEN_THOUSAND_PLUS',
    text: { text: '1000+' },
  },
];

const COMPANY_INDUSTRY_OPTIONS = generateOptions([
  'Ecommerce',
  'Entertainment',
  'Fintech',
  'Gaming',
  'Retail',
  'Travel',
  'Other',
]);

const SELECT_OPTIONS = {
  [signUpCompanyDetailsNames.currency]: currencyOptions,
  [signUpCompanyDetailsNames.companySize]: sizeOptions,
  [signUpCompanyDetailsNames.companyIndustry]: COMPANY_INDUSTRY_OPTIONS,
};

const OTHERS_INPUT_NAME_MAP = {
  [signUpCompanyDetailsNames.companyIndustry]: signUpCompanyDetailsNames.companyIndustryOther,
};

const renderValue = (values, max) => {
  if (!Array.isArray(values)) return values;
  if (Array.isArray(values) && values.some((value) => typeof value !== 'string')) return values;

  return (
    values
      ?.slice(0, max || values.length)
      ?.map((value) => capitalize(value?.toLowerCase()?.replace(/_/g, ' ') || ''))
      ?.join(', ') + (values?.length > (max || values.length) ? `...` : '')
  );
};

const RENDER_VALUE_MAP = {
  [signUpCompanyDetailsNames.companyIndustry]: (value) => renderValue(value, 3),
};

export { SELECT_OPTIONS, CUSTOM_SELECT_PROPS, OTHERS_VALUE, OTHERS_INPUT_NAME_MAP, RENDER_VALUE_MAP, renderValue };
