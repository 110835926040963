import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import OnboardingLayout from '../../components/layouts/OnboardingLayout';
import OnboardingLayoutBox from '../../components/layouts/OnboardingLayoutBox';

import { useVerifyBetaLink } from './useVerifyBetaLink';
import { Title, Description, ProgressBg } from './styled';
import { P14 } from '../../components/atoms';
import THEME from '../../constants/theme';
import { UI_ROUTES } from '../../constants/routes';

const TITLE = 'Please wait.';
const DESC = 'Access link verification in progress.';

const VerifyBetaLink = () => {
  const { t } = useTranslation();
  // const progressRef = useRef();
  const [progressAnimating, setProgressAnimating] = useState(false);
  useVerifyBetaLink();

  useEffect(() => {
    if (!progressAnimating) {
      setTimeout(() => {
        setProgressAnimating(true);
      }, 500);
    }
  }, [progressAnimating]);

  return (
    <OnboardingLayout
      navigationRight={
        <P14 color={THEME.greyColors.lightGrey}>
          {t('Already have an account?')}{' '}
          <Link to={UI_ROUTES.signIn} className="text-primary">
            {t('Sign in')}
          </Link>
        </P14>
      }
    >
      <ProgressBg className={progressAnimating ? 'loading' : ''} />
      <OnboardingLayoutBox>
        <Box maxWidth="336px" margin="0 auto">
          <Title>{t(TITLE)}</Title>
          <Description>{t(DESC)}</Description>
        </Box>
      </OnboardingLayoutBox>
    </OnboardingLayout>
  );
};

export default VerifyBetaLink;
