import { shape, func, bool, string, arrayOf } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import capitalize from '@mui/material/utils/capitalize';
import { StyledOptionsPaper } from '../../../../components/SwitchUser/styled';
import { StyledListItem, StyledList, StyledPopover, StyledListItemWrapper } from './styled';
import CheckIcon2 from '../../../../assets/icons/Select/CheckIcon2';

const Popover = ({ open, anchorEl, handleClose, width, lastItemDanger, options, enviornment }) => {
  const { t } = useTranslation();

  return (
    <StyledPopover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      width={width}
      anchorOrigin={{
        vertical: -14,
        horizontal: -4,
      }}
      PaperProps={{ component: StyledOptionsPaper }}
    >
      <StyledList onClick={handleClose} lastItemDanger={lastItemDanger}>
        {options &&
          options.map(({ label, onClick }) => (
            <StyledListItemWrapper>
              <StyledListItem key={label} onClick={onClick} disableRipple>
                {capitalize(t(label) ?? '')}
                {label === enviornment && <CheckIcon2 />}
              </StyledListItem>
            </StyledListItemWrapper>
          ))}
      </StyledList>
    </StyledPopover>
  );
};

Popover.propTypes = {
  anchorEl: shape({}),
  handleClose: func.isRequired,
  open: bool.isRequired,
  options: arrayOf(
    shape({
      onClick: func,
      value: string,
      label: string,
      disabled: bool,
    })
  ).isRequired,
  lastItemDanger: bool,
  width: string,
  enviornment: string.isRequired,
};
Popover.defaultProps = {
  anchorEl: {},
  lastItemDanger: false,
  width: undefined,
};

export default Popover;
