import styled from 'styled-components';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import { ToggleButton, ToggleButtonGroup } from '@mui/lab';
import THEME from '../../../../../../../constants/theme';
import { P16M } from '../../../../../../atoms';

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ConditionBoxAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    flex: 1;
    border: 1px solid ${THEME.greyColors.grey200};
    box-shadow: none !important;
    height: 56px !important;
    width: 100%;
    border-radius: 8px !important;
    margin: 8px 0 0 0;
    &:first-child {
      margin: 8px 0 0 0;
    }
    & .MuiCollapse-root {
      & .MuiCollapse-wrapper {
        padding: 0 24px 24px 24px;
      }
    }
    &.Mui-expanded {
      margin: 8px 0 0 0;
      &:first-child {
        margin: 8px 0 0 0;
      }
    }
    &:before {
      height: 0;
    }
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    min-height: 56px;
    padding: 0 24px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: ${({ $expanded }) => ($expanded ? `${THEME.greyColors.grey200}` : `transparent`)};
    & .MuiAccordionSummary-content {
      margin: 0;
    }
    &.Mui-expanded {
      min-height: 56px;
      margin: 0;
    }
    & .MuiButtonBase-root {
      padding: 0;
      margin: 0;
    }
  }
`;

export const AddIfElseBox = styled(StyledBox)`
  justify-content: space-between;
  border-top: 1px solid ${THEME.greyColors.grey200};
  padding: 8px;
  & button {
    width: 50%;
    height: 48px;
    font-size: 14px;
    background-color: ${THEME.primaryColors.primaryLight};
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    &:last-child {
      margin-left: 1px;
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
    }
  }
`;

export const SummaryTitle = styled(P16M)`
  position: absolute;
  right: 50%;
  transform: translateX(50%);
`;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  width: 100%;
`;

export const StyledToggleButton = styled(ToggleButton)`
  &.MuiToggleButton-root.MuiToggleButtonGroup-grouped {
    font-weight: 500;
    color: ${THEME.greyColors.grey9};
    padding: 4px 12px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    text-transform: lowercase;
    transition: all 0.3s ease-out;
    width: 50%;
    &:hover {
      background-color: ${THEME.greyColors.grey12};
    }
  }
  &.MuiToggleButton-root.Mui-selected {
    background-color: ${THEME.primaryColors.primaryLight};
    color: ${THEME.primaryColors.primary};
    &:hover {
      background-color: ${THEME.primaryColors.primaryLight};
    }
  }
  &:first-child {
    margin-right: 4px;
  }
`;

export const AddTestBox = styled(StyledBox)`
  width: 100%;
  cursor: pointer;
  padding: 0 8px 8px 8px;
  margin-top: ${({ $hasTests }) => ($hasTests ? '1px' : '8px')};
  button {
    font-size: 14px;
    width: 100%;
    height: 48px;
    background-color: ${THEME.greyColors.grey14};
    border-top-left-radius: ${({ $hasTests }) => ($hasTests ? 0 : '8px')};
    border-top-right-radius: ${({ $hasTests }) => ($hasTests ? 0 : '8px')};
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;
