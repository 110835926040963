import React, { useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import noop from 'lodash/noop';
import { useToggle } from 'react-use';
import { H3, L14, P16, P14M } from '../../components/atoms';
import THEME from '../../constants/theme';
import PeopleModal from '../../components/modals/PeopleModal';
import { HelpBox, StyledPageWrapper, StyledStepsWrapper } from './styled';
import Step from './Step';
import { SECTIONS } from './constant';
import { useGetEntryData } from './useGetEntryData';
import { useGlobalContext } from '../../containers/App/context';
import useSetCustomerMetadata from '../../hooks/useSetCustomerMetadata';
import usePermissionChecker from '../../permissions/hooks/usePermissionChecker';
import { isDefined } from '../../utils/helpers';
import ConfirmationModal from '../../components/modals/ConfirmationModal';
import { useSwitchDemo } from '../../hooks/useSwitchDemo';
import { useUserSelector } from '../../providers/User/UserContext';
import { selectIsDemo } from '../../providers/User/state/selectors';
// import HeroSection from './HeroSection';
import Help from '../../assets/icons/QuickStart/Help';
import ChevronRight from '../../assets/icons/QuickStart/ChevronRight';
import SupportModal from '../../components/SupportModal';
import useGetPaymentFlowId from './useGetPaymentFlowId';
import { PaymentFlowContext } from '../FlowDetailsPage/paymentFlowContext';
import { useGetAutomationTemplates } from '../FlowDetailsPage/hooks/useGetAutomationTemplates';
import useGetRecommendedTemplates from './NewAutomationTemplates/useGetRecommendedTemplates';
import { useIntercom } from '../../hooks/useIntercom';
import IntroSection from './IntroSection';

const GettingStarted = () => {
  const { t } = useTranslation();
  const { checkPermission } = usePermissionChecker();
  const { flow, loading: flowLoading } = useGetPaymentFlowId();
  const { getMeData, getMeLoading, toggleDrawer, sidebarCollapsed, toggleIntercom } = useGlobalContext();
  const { gettingStartedChecklist, loading } = useGetEntryData();
  const [addPeopleModalOpen, setAddPeopleModalOpen] = useState(false);
  const [markCompleteModalOpened, setMarkCompleteModalOpened] = useState(false);
  const showIntroSection = !getMeLoading && getMeData?.we?.activePlan?.remainingTrialDays > 0;

  const {
    templates,
    loading: automationTemplatesLoading,
    popularTemplates,
    paymentProcessingBaseTemplate,
  } = useGetAutomationTemplates();
  const { recommendedTemplates, loading: recommendedTemplatesLoading } = useGetRecommendedTemplates();
  const templatesLoading = automationTemplatesLoading || recommendedTemplatesLoading;

  const context = useMemo(
    () => ({
      flow,
      flowLoading,
      templates,
      automationTemplatesLoading,
      popularTemplates,
      paymentProcessingBaseTemplate,
      recommendedTemplates,
      recommendedTemplatesLoading,
      templatesLoading,
    }),
    [
      flow,
      flowLoading,
      templates,
      automationTemplatesLoading,
      popularTemplates,
      paymentProcessingBaseTemplate,
      recommendedTemplates,
      recommendedTemplatesLoading,
      templatesLoading,
    ]
  );

  const { setMetadata } = useSetCustomerMetadata();
  const switchDemo = useSwitchDemo();
  const isDemoMode = useUserSelector(selectIsDemo);
  const { startDemoTour } = useIntercom(true);
  const [showCommunityModal, toggleCommunityModal] = useToggle(false);

  const handleFTEMarkedAsCompleted = () => {
    setMarkCompleteModalOpened(false);
    setMetadata({
      firstTimeEntry: {
        ...getMeData?.we?.metadata?.firstTimeEntry,
        markedAsCompleted: true,
      },
    });
  };

  useLayoutEffect(() => {
    if (!sidebarCollapsed) {
      toggleDrawer();
    }
    return () => {
      if (sidebarCollapsed) {
        toggleDrawer();
      }
    };
  }, [toggleDrawer, sidebarCollapsed]);

  const permittedSections = SECTIONS.filter(({ permission }) =>
    isDefined(permission) ? checkPermission(permission) : true
  );

  const getOnClickFn = (dataKey) => {
    switch (dataKey) {
      case 'invitedColleagues':
        return () => setAddPeopleModalOpen(true);
      case 'tourTaken':
        return isDemoMode ? startDemoTour : () => switchDemo(true);
      default:
        return noop;
    }
  };

  return (
    <PaymentFlowContext.Provider value={context}>
      <PeopleModal
        open={addPeopleModalOpen}
        setShowModal={() => setAddPeopleModalOpen(false)}
        userRefetch={() => {}}
        modalType="add"
        userContent={{}}
        updateUser={() => {}}
      />
      <H3 fontWeight="600" margin="0 0 16px 0">
        {t('Getting Started')}
      </H3>
      <StyledPageWrapper>
        <Box display="flex" flexDirection="column" alignItems="center" maxWidth="768px" width="100%">
          {/* <HeroSection /> */}
          {showIntroSection && <IntroSection remainingTrialDays={getMeData?.we?.activePlan?.remainingTrialDays} />}

          {permittedSections.map(({ title, desc, steps }) => (
            <Box key={title} marginBottom="32px" width="100%" maxWidth="688px">
              <Box mt="32px" marginBottom="16px">
                <P16 fontWeight="600">{t(title)}</P16>
                <L14 color={THEME.greyColors.grey18} noHover>
                  {t(desc)}
                </L14>
              </Box>

              <StyledStepsWrapper>
                {steps
                  .filter(({ permission }) => (isDefined(permission) ? checkPermission(permission) : true))
                  .map((step) => {
                    const isCompleted = gettingStartedChecklist?.[step?.dataKey] || false;

                    const onClickFunction = getOnClickFn(step.dataKey);
                    return (
                      <Step
                        key={step.dataKey}
                        dataKey={step.dataKey}
                        url={step.url}
                        title={step.title}
                        desc={step.desc}
                        icon={step.Icon}
                        completed={isCompleted}
                        loading={loading}
                        isStrokeSvg={step?.isStrokeSvg}
                        isExternalUrl={step?.isExternalUrl}
                        onClickFunction={onClickFunction}
                      />
                    );
                  })}
              </StyledStepsWrapper>
            </Box>
          ))}
          <HelpBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            bgcolor={THEME.primaryColors.primaryLight}
            borderRadius="8px"
            p="16px 24px"
            onClick={toggleCommunityModal}
            width="100%"
            maxWidth="688px"
          >
            <Box display="flex" alignItems="center">
              <Help />
              <P14M margin="0 0 0 24px" maxWidth="380px">
                {t('Get help and inspiration from a WhenThen Automator and other customers.')}
              </P14M>
            </Box>
            <ChevronRight />
          </HelpBox>
          <SupportModal
            toggleIsOpen={toggleCommunityModal}
            isOpen={showCommunityModal}
            toggleIntercom={toggleIntercom}
          />
        </Box>
      </StyledPageWrapper>
      <ConfirmationModal
        open={markCompleteModalOpened}
        text={{
          title: 'Are you sure you want to mark as completed?',
          description: 'You won’t be able to see this section again after',
          submit: 'Mark complete',
        }}
        onConfirm={() => {
          handleFTEMarkedAsCompleted();
        }}
        onClose={() => {
          setMarkCompleteModalOpened(false);
        }}
        onCancel={() => {
          setMarkCompleteModalOpened(false);
        }}
      />
    </PaymentFlowContext.Provider>
  );
};

export default GettingStarted;
