import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, useField } from 'formik';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CustomCheckboxesWrapper, CustomCheckboxesStyled, StyledOptionsWrapper } from './styled';
import InputOnboarding from '../InputOnboarding';

const Label = styled.label`
  display: block;
  text-align: left;
  width: 100%;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
`;

const CustomCheckboxes = ({ name, label, options, fullWidth, setShowOtherInput }) => {
  const [{ value }, { touched, error }] = useField(name);
  const hasError = Boolean(touched && error);
  const { t } = useTranslation();
  const showOtherInput = value.includes('OTHER');
  const [focusedOption, setFocusedOption] = useState('');

  const handleFocus = (newValue) => {
    setFocusedOption(newValue);
  };

  const handleBlur = () => {
    setFocusedOption('');
  };

  useEffect(() => {
    if (setShowOtherInput) {
      setShowOtherInput(showOtherInput);
    }
  }, [showOtherInput, setShowOtherInput]);

  return (
    <CustomCheckboxesWrapper>
      <FormControl variant="standard" error={hasError} fullWidth={fullWidth}>
        {label && <Label>{label}</Label>}
        <StyledOptionsWrapper role="group">
          <Field>
            {({ field }) => {
              return options.map((option, id) => (
                <CustomCheckboxesStyled
                  checked={value?.includes(option.value)}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${name}-${id}`}
                  focused={String(focusedOption) === String(option.value)}
                  onMouseLeave={() => handleBlur()}
                >
                  <label htmlFor={`${name}-${id}`}>
                    <div>
                      <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.32346 0.76764C9.63588 1.08006 9.63588 1.58659 9.32346 1.89901L3.99012 7.23234C3.6777 7.54476 3.17117 7.54476 2.85875 7.23234L0.43451 4.8081C0.12209 4.49568 0.12209 3.98915 0.43451 3.67673C0.746929 3.36431 1.25346 3.36431 1.56588 3.67673L3.42444 5.53529L8.19209 0.76764C8.50451 0.45522 9.01104 0.45522 9.32346 0.76764Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    {option.text.text}
                    <input
                      {...field}
                      id={`${name}-${id}`}
                      value={option.value}
                      name={name}
                      type="checkbox"
                      onFocus={() => handleFocus(option.value)}
                      onBlur={() => handleBlur()}
                    />
                  </label>
                </CustomCheckboxesStyled>
              ));
            }}
          </Field>
        </StyledOptionsWrapper>

        {showOtherInput && (
          <Box mt="32px">
            <InputOnboarding type="text" name={`${name}Other`} label={t('Please specify')} skipTouched />
          </Box>
        )}

        {hasError && <FormHelperText id={name}>{error}</FormHelperText>}
      </FormControl>
    </CustomCheckboxesWrapper>
  );
};

CustomCheckboxes.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.PropTypes.any).isRequired,
  errorProps: PropTypes.shape({}),
  setShowOtherInput: PropTypes.func,
};

CustomCheckboxes.defaultProps = {
  type: 'text',
  label: null,
  placeholder: 'Enter value',
  fullWidth: false,
  errorProps: {},
  setShowOtherInput: null,
};

export default CustomCheckboxes;
