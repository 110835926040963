import React from 'react';

const Party = () => {
  return (
    <svg width="92" height="92" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M19.7085 38.8047L10.8828 62.3459V62.3477C13.0425 70.9758 17.7896 78.6031 24.5095 84.3563L35.583 80.204C23.8211 69.6694 18.0158 54.174 19.7085 38.8047Z"
          fill="#4E40EF"
        />
        <path
          d="M7.89459 70.3145L1.12976 88.3522C0.321216 90.5065 2.42882 92.6375 4.59932 91.8218L18.871 86.471C14.0841 81.8249 10.3707 76.288 7.89459 70.3145Z"
          fill="#4E40EF"
        />
        <path
          d="M38.6276 36.6089L28.5675 26.5488C20.4109 44.9481 25.9871 65.9783 41.3766 78.0316L52.1141 74.005C42.6839 64.2665 37.5835 50.6735 38.6276 36.6089Z"
          fill="#4E40EF"
        />
        <path
          d="M43.8926 41.8711C44.3058 53.3075 49.2865 64.1151 57.7133 71.904H57.7151L65.5849 68.9538C67.423 68.2638 67.9261 65.9046 66.5444 64.5229L43.8926 41.8711Z"
          fill="#4E40EF"
        />
        <path
          d="M63.5746 50.3389C64.6272 51.3914 66.3335 51.3914 67.3861 50.3389C77.5256 40.1993 87.3987 43.3342 87.4973 43.3669C88.9094 43.8374 90.4357 43.0744 90.9065 41.6623C91.3774 40.2506 90.614 38.7238 89.2019 38.2531C88.6704 38.0761 76.0438 34.0582 63.5748 46.5274C62.5221 47.5797 62.5219 49.2865 63.5746 50.3389Z"
          fill="#4E40EF"
        />
        <path
          d="M46.4234 29.3761C58.893 16.9083 54.8736 4.28063 54.6975 3.74878C54.2268 2.33653 52.6995 1.5747 51.2872 2.04545C49.8768 2.5162 49.1132 4.04166 49.5839 5.45392C49.6163 5.55274 52.7516 15.426 42.6107 25.5651C41.5648 26.611 41.5542 28.3163 42.6107 29.3761C43.6688 30.434 45.3737 30.4257 46.4234 29.3761Z"
          fill="#4E40EF"
        />
        <path
          d="M52.141 35.0933C51.0884 36.1458 51.0884 37.8522 52.141 38.9047C53.1838 39.9478 54.8783 39.9614 55.9365 38.9207C57.3288 38.227 61.6504 40.8291 64.0521 38.4282C66.428 36.0522 63.9037 31.8185 64.5342 30.3347C66.0199 29.7026 70.252 32.2281 72.6279 29.8525C75.0043 27.4764 72.4798 23.2427 73.1103 21.7587C74.5982 21.1265 78.828 23.6524 81.2038 21.2765C83.5802 18.9005 81.0558 14.6667 81.6863 13.1828C83.1654 12.5537 87.4057 15.0739 89.7802 12.7003C92.1808 10.2993 89.5836 5.97002 90.2723 4.5849C91.309 3.53127 91.3035 1.83674 90.2563 0.789409C89.2039 -0.263136 87.4974 -0.263136 86.4448 0.789409C84.3205 2.91373 84.7361 5.91271 85.1362 8.05679C82.9922 7.65665 79.9934 7.24106 77.869 9.36519C75.7447 11.4895 76.1603 14.4885 76.5605 16.6326C74.4164 16.2326 71.4172 15.8167 69.2931 17.9412C67.1688 20.0655 67.5845 23.0645 67.9847 25.2085C65.8402 24.8084 62.8414 24.3926 60.7173 26.5169C58.593 28.6413 59.0086 31.6402 59.4087 33.7843C57.2644 33.3845 54.2651 32.969 52.141 35.0933Z"
          fill="#4E40EF"
        />
        <path
          d="M67.388 12.2239C68.4405 11.1714 68.4405 9.465 67.388 8.41246C66.3355 7.35991 64.6291 7.35991 63.5765 8.41246C62.524 9.465 62.524 11.1714 63.5765 12.2239C64.6291 13.2765 66.3355 13.2765 67.388 12.2239Z"
          fill="#4E40EF"
        />
        <path
          d="M84.5389 32.0719C86.0274 32.0719 87.2341 30.8653 87.2341 29.3768C87.2341 27.8883 86.0274 26.6816 84.5389 26.6816C83.0504 26.6816 81.8438 27.8883 81.8438 29.3768C81.8438 30.8653 83.0504 32.0719 84.5389 32.0719Z"
          fill="#4E40EF"
        />
        <path
          d="M78.8207 54.1507C77.7681 55.2033 77.7681 56.9097 78.8207 57.9622C79.8732 59.0148 81.5796 59.0148 82.6321 57.9622C83.6847 56.9097 83.6847 55.2033 82.6321 54.1507C81.5798 53.0982 79.8732 53.0982 78.8207 54.1507Z"
          fill="#4E40EF"
        />
        <path
          d="M40.7064 16.0345C41.7589 14.9819 41.7589 13.2755 40.7064 12.223C39.6538 11.1705 37.9474 11.1705 36.8949 12.223C35.8423 13.2754 35.8423 14.9819 36.8949 16.0345C37.9474 17.087 39.654 17.087 40.7064 16.0345Z"
          fill="#4E40EF"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="92" height="92" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Party;
