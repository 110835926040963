import React from 'react';

const EmptyStateIcon = () => {
  return (
    <svg width="80" height="72" viewBox="0 0 80 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="56" height="8" rx="4" fill="black" fillOpacity="0.1" />
      <rect y="16" width="80" height="8" rx="4" fill="black" fillOpacity="0.1" />
      <rect y="32" width="72" height="8" rx="4" fill="black" fillOpacity="0.1" />
      <rect y="48" width="56" height="8" rx="4" fill="black" fillOpacity="0.1" />
      <rect y="64" width="72" height="8" rx="4" fill="black" fillOpacity="0.1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.6665 17.3333C28.6459 17.3333 21.3332 24.646 21.3332 33.6667C21.3332 42.6873 28.6459 50 37.6665 50C46.6872 50 53.9998 42.6873 53.9998 33.6667C53.9998 24.646 46.6872 17.3333 37.6665 17.3333ZM16.6665 33.6667C16.6665 22.0687 26.0685 12.6667 37.6665 12.6667C49.2645 12.6667 58.6665 22.0687 58.6665 33.6667C58.6665 45.2646 49.2645 54.6667 37.6665 54.6667C26.0685 54.6667 16.6665 45.2646 16.6665 33.6667Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.2 45.2001C50.1112 44.2889 51.5886 44.2889 52.4999 45.2001L62.6498 55.3501C63.5611 56.2613 63.5611 57.7387 62.6498 58.6499C61.7386 59.5611 60.2612 59.5611 59.35 58.6499L49.2 48.4999C48.2888 47.5887 48.2888 46.1113 49.2 45.2001Z"
        fill="black"
      />
    </svg>
  );
};

export default EmptyStateIcon;
