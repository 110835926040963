import React, { useMemo } from 'react';
import { number, shape, string, func, bool } from 'prop-types';
import uniqBy from 'lodash/uniqBy';
import TabContent from './TabContent';
import StatusTab from './StatusTab';
import Tabs from '../../../../components/common/Tabs/Tabs';
import { generateUserPilotLabel } from '../../../../constants/generateUserPilotLabel';
import { USER_PILOT_SECTION_ID } from '../../constant';
import { FlexContainer } from '../../../../components/atoms/flex/FlexContainer';
import DetailsTab from './DetailsTab';

// const PPRO_NAME = 'PPRO';

const DetailsContentBlock = ({ connection, setTabValue, tabValue, isPaymentConnection }) => {
  // Talk to BE for better value to use as PPRO indicator
  // const isPpro = connection?.company?.name === PPRO_NAME;

  const triggersArray = useMemo(
    () =>
      uniqBy(
        (connection?.supportedEventDetails || []).map((item) => ({
          name: item?.label,
        })),

        'name'
      ),
    [connection]
  );

  const actionsArray = useMemo(
    () =>
      uniqBy(
        (connection?.actionDetails?.actions || []).map((item) => ({
          name: item?.name,
        })),
        'name'
      ),
    [connection]
  );

  const tabs = useMemo(
    () =>
      [
        isPaymentConnection && {
          label: 'Details',
          id: generateUserPilotLabel(USER_PILOT_SECTION_ID, 'tabs', 'details'),
          node: <DetailsTab connection={connection} />,
        },
        isPaymentConnection &&
          connection.status === 'CONNECTED' && {
            label: 'Status',
            id: generateUserPilotLabel(USER_PILOT_SECTION_ID, 'tabs', 'status'),
            node: <StatusTab connection={connection} />,
          },
        {
          label: 'Actions',
          id: generateUserPilotLabel(USER_PILOT_SECTION_ID, 'tabs', 'actions'),
          node: (
            <TabContent
              itemsArray={actionsArray}
              type="actions"
              connectionName={connection?.company?.name}
              connectionLogo={connection.company?.logo}
            />
          ),
        },
        {
          label: 'Triggers',
          id: generateUserPilotLabel(USER_PILOT_SECTION_ID, 'tabs', 'triggers'),
          node: (
            <TabContent
              itemsArray={triggersArray}
              type="triggers"
              connectionName={connection?.company?.name}
              connectionLogo={connection.company?.logo}
            />
          ),
        },
      ]?.filter(Boolean),
    [triggersArray, actionsArray, connection, isPaymentConnection]
  );
  return (
    <FlexContainer
      width="100%"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      alignSelf="flex-start"
    >
      <FlexContainer width="100%">
        <Tabs tabs={tabs} tabValue={tabValue} setTabValue={setTabValue} />
      </FlexContainer>
    </FlexContainer>
  );
};

DetailsContentBlock.propTypes = {
  connection: shape({
    status: string,
    name: string,
    company: shape({
      name: string,
      logo: string,
      homepageUrl: string,
      supportUrl: string,
      longDescription: string,
    }),
  }).isRequired,
  tabValue: number.isRequired,
  setTabValue: func.isRequired,
  isPaymentConnection: bool.isRequired,
};

export default DetailsContentBlock;
