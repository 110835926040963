import { gql } from '@apollo/client/core';

export const CREATE_TEAM_GQL = gql`
  mutation createTeam($teamName: String!) {
    createTeam(teamName: $teamName) {
      id
      name
      limitsAndFeatures
      membersCount
    }
  }
`;
