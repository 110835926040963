import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import OnboardingLayoutBox from '../../components/layouts/OnboardingLayoutBox';
import OnboardingLayout from '../../components/layouts/OnboardingLayout';
import { ButtonRounded, P14, P16, P18 } from '../../components/atoms';
import THEME from '../../constants/theme';
import { UI_ROUTES } from '../../constants/routes';

const CheckYourEmailPage = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const handleComplete = () => {
    push(UI_ROUTES.signIn);
  };

  return (
    <OnboardingLayout
      theme="dark"
      navigationRight={
        <P14 color={THEME.greyColors.lightGrey}>
          {t('Already have an account?')}{' '}
          <Link to={UI_ROUTES.signIn} className="text-primary">
            {t('Sign in')}
          </Link>
        </P14>
      }
    >
      <OnboardingLayoutBox theme="dark">
        <Box width="336px" display="flex" flexDirection="column" margin="0 auto">
          <div>
            <h1>{t('Registration link sent.')}</h1>
            <P18 margin="24px 0 0 0" fontWeight="500" maxWidth="300px" color={THEME.greyColors.grey17}>
              {t(`We emailed you a registration link. Please check your email.`)}
            </P18>
            <P16 margin="8px 0 24px 0" color={THEME.greyColors.grey18}>
              {t(`Don’t forget to check your spam folder.`)}
            </P16>
          </div>

          <Box mt="auto">
            <ButtonRounded
              type="button"
              variant="contained"
              color="secondary"
              borderRadius="6px"
              width="100%"
              height="48px"
              onClick={handleComplete}
            >
              {t('Back to homepage')}
            </ButtonRounded>
          </Box>
        </Box>
      </OnboardingLayoutBox>
    </OnboardingLayout>
  );
};

export default CheckYourEmailPage;
