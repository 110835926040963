import React from 'react';
import { shape, bool, func } from 'prop-types';
import { Box } from '@mui/material';
import { useFormikContext } from 'formik';
import { InputField, L12 } from '../../../../../atoms';
import useActionHandlers from '../hooks/useActionHandlers';
import THEME from '../../../../../../constants/theme';
import { useGlobalContext } from '../../../../../../containers/App/context';

const Number = ({ item, showErrors, setShowErrors }) => {
  const { getMeData } = useGlobalContext();
  const { values, errors, handleBlur } = useFormikContext();
  const { handleFormat } = useActionHandlers(setShowErrors);

  const currency = getMeData?.we?.currency?.symbol || '';

  return (
    <Box key={item.block_id}>
      <InputField
        variant="outlined"
        type="text"
        id={item.block_id}
        name={item.block_id}
        label={item.label.text}
        value={
          item.block_id === 'amount'
            ? (values[item.block_id] && currency + values[item.block_id]) || ''
            : values[item.block_id] || ''
        }
        onChange={handleFormat}
        onBlur={handleBlur}
      />

      {showErrors && errors[item.block_id] && (
        <L12 textAlign="left" margin="0 0 24px 0" color={THEME.secondaryColors.danger}>
          {errors[item.block_id]}
        </L12>
      )}
    </Box>
  );
};

Number.propTypes = {
  item: shape({}).isRequired,
  showErrors: bool,
  setShowErrors: func.isRequired,
};

Number.defaultProps = {
  showErrors: false,
};

export default Number;
