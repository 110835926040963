import { useQuery } from '@apollo/client';
import { GET_FILTER_FIELDS } from '../../../../../../../utils/queries/properties/editorPropertiesQueries';
import useGetAvailableDataProviders from '../../hooks/useGetAvailableDataProviders';
import { useNodeDataToSave } from './useNodeDataToSave';

const useSingleRecord = () => {
  const [{ selectedDataProvider }] = useNodeDataToSave();

  const { availableDataProviders } = useGetAvailableDataProviders();

  const dataProvider = availableDataProviders?.find(({ type }) => type === selectedDataProvider);

  const { data, loading } = useQuery(GET_FILTER_FIELDS, {
    variables: {
      type: dataProvider?.type,
    },
  });

  return { records: data?.getFilterFields, loading };
};
export default useSingleRecord;
