import { number } from 'prop-types';
import React from 'react';

const MessageBubble = ({ size }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33325 2.66665C3.15644 2.66665 2.98687 2.73688 2.86185 2.86191C2.73682 2.98693 2.66659 3.1565 2.66659 3.33331V12.3905L4.19518 10.8619C4.32021 10.7369 4.48977 10.6666 4.66659 10.6666H12.6666C12.8434 10.6666 13.013 10.5964 13.138 10.4714C13.263 10.3464 13.3333 10.1768 13.3333 9.99998V3.33331C13.3333 3.1565 13.263 2.98693 13.138 2.86191C13.013 2.73688 12.8434 2.66665 12.6666 2.66665H3.33325ZM1.91904 1.9191C2.29411 1.54403 2.80282 1.33331 3.33325 1.33331H12.6666C13.197 1.33331 13.7057 1.54403 14.0808 1.9191C14.4559 2.29417 14.6666 2.80288 14.6666 3.33331V9.99998C14.6666 10.5304 14.4559 11.0391 14.0808 11.4142C13.7057 11.7893 13.197 12 12.6666 12H4.94273L2.47132 14.4714C2.28066 14.6621 1.99391 14.7191 1.7448 14.6159C1.49568 14.5127 1.33325 14.2696 1.33325 14V3.33331C1.33325 2.80288 1.54397 2.29417 1.91904 1.9191Z"
      fill="#1F25F4"
    />
  </svg>
);

MessageBubble.propTypes = {
  size: number,
};
MessageBubble.defaultProps = {
  size: 24,
};

export default MessageBubble;
