import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { bool, func } from 'prop-types';
import { StyledBox, StyledOperatorControl } from './styled';
import { ButtonRounded } from '../../../atoms';
import THEME from '../../../../constants/theme';
import AutocompleteMultiple from '../../../AutocompleteMultiple';
import Select from '../../../forms/_common/Select';

const tempArray = [
  {
    value: 'EQUALS',
    title: 'equals',
  },
  {
    value: 'NOT_EQUALS',
    title: 'not equals',
  },
  {
    value: 'LESS_THAN',
    title: 'less than',
  },
  {
    value: 'LESS_THAN_EQUALS',
    title: 'less than or equals',
  },
  {
    value: 'GREATER_THAN',
    title: 'greater than',
  },
  {
    value: 'GREATER_THAN_EQUALS',
    title: 'greater than or equals',
  },
  {
    value: 'BETWEEN_INCLUSIVE',
    title: 'between inclusively',
  },
  {
    value: 'BETWEEN_EXCLUSIVE',
    title: 'between exclusively',
  },
  {
    value: 'CONTAINS',
    title: 'contains',
  },
  {
    value: 'IS_EMPTY',
    title: 'is empty',
  },
  {
    value: 'NOT_EMPTY',
    title: 'not empty',
  },
];

const ChooseCustomConditionValue = ({ isOpen, setIsShowChooseValueConditionModal }) => {
  // const [options, setOptions] = useState([]);
  const { t } = useTranslation();

  return (
    isOpen && (
      <StyledBox width="328px">
        <Box p="24px">
          <StyledOperatorControl>
            <Select value="IS_EMPTY" options={tempArray} />
          </StyledOperatorControl>
          <AutocompleteMultiple options={[]} />
        </Box>
        <Box display="flex" p="8px" gap="8px" borderTop={`1px solid ${THEME.greyColors.grey200}`}>
          <ButtonRounded
            flex="1"
            type="button"
            color="secondary"
            variant="contained"
            onClick={() => setIsShowChooseValueConditionModal(false)}
          >
            {t('Cancel')}
          </ButtonRounded>
          <ButtonRounded flex="1" type="button" variant="contained" color="primary" disabled>
            {t('Save')}
          </ButtonRounded>
        </Box>
      </StyledBox>
    )
  );
};

ChooseCustomConditionValue.propTypes = {
  isOpen: bool.isRequired,
  setIsShowChooseValueConditionModal: func.isRequired,
};

export default ChooseCustomConditionValue;
