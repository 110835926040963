import React from 'react';
import { string } from 'prop-types';

const Giropay = ({ width, height, lineColor, bodyColor }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#F5F6F7" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.30762 10.4897C4.30762 9.86831 4.84179 9.3645 5.50038 9.3645H18.4995C19.1583 9.3645 19.6922 9.86831 19.6922 10.4897V14.5107C19.6922 15.1317 19.1583 15.6356 18.4995 15.6356H5.50038C4.84179 15.6356 4.30762 15.1317 4.30762 14.5107V10.4897Z"
      fill={bodyColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.79712 10.5283V14.4727C4.79712 14.8609 5.13101 15.1758 5.54287 15.1758H12.3532V9.8252H5.54287C5.13101 9.8252 4.79712 10.1401 4.79712 10.5283ZM14.1129 12.4808C14.1129 12.7319 13.9818 12.9045 13.7649 12.9045C13.5732 12.9045 13.4135 12.7319 13.4135 12.5019C13.4135 12.2659 13.554 12.0904 13.7649 12.0904C13.9885 12.0904 14.1129 12.272 14.1129 12.4808ZM12.8391 13.9211H13.4135V13.0647H13.4199C13.5287 13.2523 13.7459 13.3219 13.947 13.3219C14.4417 13.3219 14.7066 12.9346 14.7066 12.4686C14.7066 12.0875 14.4545 11.6726 13.9947 11.6726C13.7331 11.6726 13.4905 11.7726 13.3753 11.9936H13.3688V11.7092H12.8391V13.9211ZM15.4911 12.7863C15.4911 12.6351 15.6442 12.5778 15.8392 12.5778C15.9253 12.5778 16.0085 12.5836 16.0818 12.5868C16.0818 12.7713 15.9446 12.959 15.7273 12.959C15.5933 12.959 15.4911 12.8955 15.4911 12.7863ZM16.6498 13.2857C16.6244 13.1617 16.618 13.0374 16.618 12.9135V12.3264C16.618 11.8453 16.2511 11.6726 15.8264 11.6726C15.5807 11.6726 15.3668 11.706 15.1624 11.7848L15.1722 12.1541C15.3313 12.0692 15.5168 12.0359 15.702 12.0359C15.9091 12.0359 16.0784 12.0936 16.0818 12.3085C16.0085 12.2963 15.9061 12.287 15.8135 12.287C15.5073 12.287 14.955 12.3447 14.955 12.8257C14.955 13.1678 15.2485 13.3219 15.5773 13.3219C15.8135 13.3219 15.9733 13.2344 16.1041 13.0374H16.1106C16.1106 13.1194 16.1199 13.2008 16.1234 13.2857H16.6498ZM16.9083 13.9211C17.0265 13.9454 17.1445 13.9577 17.2658 13.9577C17.7926 13.9577 17.9169 13.5733 18.0797 13.1768L18.6896 11.7092H18.1149L17.7733 12.738H17.7669L17.4093 11.7092H16.7903L17.4637 13.3219C17.4222 13.4612 17.3138 13.54 17.1732 13.54C17.0931 13.54 17.0232 13.5307 16.9464 13.5066L16.9083 13.9211Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.20034 12.4838C6.20034 12.2689 6.31221 12.0902 6.52609 12.0902C6.7846 12.0902 6.89303 12.2869 6.89303 12.4624C6.89303 12.7045 6.7302 12.8679 6.52609 12.8679C6.35378 12.8679 6.20034 12.7289 6.20034 12.4838ZM7.44856 11.7091H6.92818V11.9935H6.92214C6.8005 11.7997 6.60243 11.6725 6.35684 11.6725C5.83982 11.6725 5.60669 12.0239 5.60669 12.4928C5.60669 12.9589 5.89087 13.2855 6.34698 13.2855C6.57714 13.2855 6.7687 13.2006 6.90586 13.0161H6.91228V13.101C6.91228 13.4095 6.73355 13.5578 6.40177 13.5578C6.16222 13.5578 6.01519 13.5094 5.83982 13.4278L5.81108 13.8576C5.94489 13.9031 6.17161 13.9576 6.4464 13.9576C7.11677 13.9576 7.44856 13.7487 7.44856 13.101V11.7091ZM8.41968 11.0525H7.84499V11.4519H8.41968V11.0525ZM7.84528 13.2855H8.41968V11.7091H7.84528V13.2855ZM10.0196 11.6908C9.96213 11.6819 9.89183 11.6725 9.82498 11.6725C9.57595 11.6725 9.43237 11.7997 9.33333 11.9995H9.32691V11.7091H8.80347V13.2855H9.37797V12.62C9.37797 12.3112 9.52825 12.1267 9.79624 12.1267C9.86348 12.1267 9.92698 12.1267 9.99087 12.1446L10.0196 11.6908ZM11.0089 12.9406C10.744 12.9406 10.6356 12.7318 10.6356 12.4989C10.6356 12.2628 10.744 12.054 11.0089 12.054C11.2742 12.054 11.3827 12.2628 11.3827 12.4989C11.3827 12.7318 11.2742 12.9406 11.0089 12.9406ZM11.0089 13.3217C11.558 13.3217 11.9763 13.0194 11.9763 12.4989C11.9763 11.9752 11.558 11.6725 11.0089 11.6725C10.4599 11.6725 10.0419 11.9752 10.0419 12.4989C10.0419 13.0194 10.4599 13.3217 11.0089 13.3217Z"
      fill={lineColor}
    />
  </svg>
);

Giropay.propTypes = {
  width: string,
  height: string,
  lineColor: string,
  bodyColor: string,
};

Giropay.defaultProps = {
  width: '24',
  height: '24',
  lineColor: '#FF0007',
  bodyColor: '#000268',
};

export default Giropay;
