import React from 'react';
import { Box } from '@mui/material';
import { arrayOf, number, shape } from 'prop-types';
import THEME from '../../../constants/theme';

const CircleWithIcon = ({ index, array, children }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    borderRadius="50%"
    border="1px solid transparent"
    width="42px"
    height="42px"
    ml={index && '-10px'}
    zIndex={array?.length}
    bgcolor={THEME.primaryColors.white}
  >
    {children}
  </Box>
);

CircleWithIcon.propTypes = {
  index: number.isRequired,
  array: arrayOf(shape({})).isRequired,
};

export default CircleWithIcon;
