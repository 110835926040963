import React from 'react';
import EmptyStateIcon from '../../../../../../assets/icons/Templates/New/EmptyStateIcon';
import { P16 } from '../../../../../../components/atoms';
import { Wrapper } from './styled';

const EmptyState = () => {
  return (
    <Wrapper>
      <EmptyStateIcon />
      <P16 margin="16px 0 0 0">No results matching your search query.</P16>
    </Wrapper>
  );
};

export default EmptyState;
