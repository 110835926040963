import React from 'react';

const Ach = () => {
  return (
    <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="34" height="24" rx="4" fill="#4E40EF" />
      <path
        d="M7.65128 16L8.25142 14.2067H10.9858L11.5895 16H12.9957L10.4318 8.72727H8.8054L6.24503 16H7.65128ZM8.60653 13.1484L9.5902 10.2188H9.64702L10.6307 13.1484H8.60653ZM19.837 11.1811C19.6133 9.56534 18.3704 8.62784 16.7582 8.62784C14.8583 8.62784 13.4272 10.0128 13.4272 12.3636C13.4272 14.7109 14.8406 16.0994 16.7582 16.0994C18.4876 16.0994 19.6346 14.9808 19.837 13.5923L18.5089 13.5852C18.342 14.4375 17.646 14.9169 16.7759 14.9169C15.5969 14.9169 14.7518 14.0327 14.7518 12.3636C14.7518 10.723 15.5898 9.81037 16.7795 9.81037C17.6637 9.81037 18.3562 10.3111 18.5089 11.1811H19.837ZM21.0435 16H22.361V12.9105H25.731V16H27.052V8.72727H25.731V11.8061H22.361V8.72727H21.0435V16Z"
        fill="white"
      />
    </svg>
  );
};

export default Ach;
