import React from 'react';

const Knowledgebase = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="15.5" fill="white" stroke="#E6E9EC" />
      <path
        d="M19.4944 13.3999H19.3058L17.2667 11.3609V11.1723L19.4944 13.3999ZM20.6001 15.9333H20.6667H20.7334V20.0666H20.6001V15.9333ZM13.2667 9.93325H14.7334V9.99992V10.0666H13.2667V9.93325ZM18.8088 14.5999L18.9421 14.7333H17.2667V14.5999H18.8088ZM16.0667 11.8579V13.3999H15.9334V11.7246L16.0667 11.8579ZM12.0667 10.068V10.2174C11.8484 10.3351 11.6686 10.5149 11.5509 10.7333H11.4015C11.5398 10.4421 11.7756 10.2063 12.0667 10.068ZM11.4001 11.9333V20.0666H11.2667V11.9333H11.4001ZM11.4015 21.2666H11.5509C11.6686 21.4849 11.8484 21.6648 12.0667 21.7824V21.9319C11.7756 21.7936 11.5398 21.5577 11.4015 21.2666ZM13.2667 22.0666V21.9333H18.7334V22.0666H13.2667ZM19.9334 21.9319V21.7824C20.1517 21.6648 20.3316 21.4849 20.4492 21.2666H20.5987C20.4604 21.5577 20.2246 21.7936 19.9334 21.9319ZM13.9334 16.5999H17.4001V16.7333H13.9334V16.5999ZM13.9334 19.2666H16.0667V19.3999H13.9334V19.2666Z"
        fill="#4E40EF"
        stroke="#4E40EF"
        strokeWidth="1.2"
      />
    </svg>
  );
};

export default Knowledgebase;
