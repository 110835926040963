import { v4 } from 'uuid';
import { facadeStepsToNodes } from './facades';
import { flowStepTypes } from '../types';

export const getNewElements = (newNodeData, position) => {
  if (newNodeData.__typename === flowStepTypes.CompositeFlowStep) {
    const { children } = newNodeData;
    let offsetLeft = -400;
    let offsetTop = -200;
    const newChildren = children.map((child) => ({ ...child, id: v4() }));
    const childrenWithLayout = newChildren.map((child, index) => {
      offsetLeft += 400;
      offsetTop += 200;
      const nextStep = newChildren[index + 1];
      const childWithLayout = {
        ...child,
        layout: {
          location: {
            left: position.x + offsetLeft,
            top: position.y + offsetTop,
          },
        },
        unsaved: true,
      };
      if (child.__typename === flowStepTypes.DataConditionStep) {
        return childWithLayout;
      }
      return {
        ...childWithLayout,
        nextStepId: nextStep?.id,
      };
    });

    return facadeStepsToNodes(childrenWithLayout);
  }
  const positionedNewNodeData = {
    ...newNodeData,
    layout: {
      location: {
        left: position.x,
        top: position.y,
      },
    },
    unsaved: true,
    ...((newNodeData.__typename === flowStepTypes.WebhookActionStep ||
      newNodeData.__typename === flowStepTypes.WebhookTriggerStep) && { useBasicAuthentication: true }),
  };
  return facadeStepsToNodes([positionedNewNodeData]);
};
