import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { OTHERS_VALUE, RENDER_VALUE_MAP, OTHERS_INPUT_NAME_MAP } from './consts';
import InputOnboarding from '../_common/InputOnboarding';
import CustomSelectOnboarding from '../SignUpCompanyDetailsForm/CustomSelectOnboarding';

const SelectWithOthers = ({ name, label, setShowOtherInput, ...rest }) => {
  const { t } = useTranslation();

  const { values, setFieldValue, initialValues } = useFormikContext();
  const showOtherInput = values[name]?.includes(OTHERS_VALUE);

  useEffect(() => {
    setShowOtherInput(showOtherInput);
    setFieldValue(OTHERS_INPUT_NAME_MAP[name], showOtherInput ? initialValues[OTHERS_INPUT_NAME_MAP[name]] : '');
  }, [showOtherInput, setShowOtherInput, setFieldValue, name, initialValues]);

  return (
    <>
      <CustomSelectOnboarding name={name} label={label} multiple renderValue={RENDER_VALUE_MAP[name]} {...rest} />
      {showOtherInput && (
        <Box>
          <InputOnboarding type="text" name={OTHERS_INPUT_NAME_MAP[name]} label={t('Please specify')} />
        </Box>
      )}
    </>
  );
};

SelectWithOthers.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setShowOtherInput: PropTypes.func.isRequired,
};

export default SelectWithOthers;
