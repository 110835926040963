import React from 'react';
import { makeStyles } from '@mui/styles';
import { FormControl } from '@mui/material';
import { string } from 'prop-types';
import THEME from '../../constants/theme';

const useStyles = makeStyles(() => ({
  root: {
    borderColor: 'rgba(255,255,255,0) !important',
    borderRadius: '6px !important',
    background: 'white',

    fontFamily: 'inherit',
    minWidth: ({ width }) => width || '100%',
    margin: ({ margin }) => margin || '0',
    height: ({ height }) => height || 'auto',
    maxWidth: '100%',

    '& hover': {
      // backgroundColor: THEME.greyColors.grey6,
    },
    '&.has-error': {
      // background: 'red !important',
      '& label': {
        color: `${THEME.secondaryColors.nodeError}!important`,
      },
      '& .MuiOutlinedInput-root fieldset': {
        borderColor: `${THEME.secondaryColors.nodeError}`,
      },
    },
    '& .MuiSelect-root > div': {
      overflow: 'hidden',
    },
    '& .MuiInputBase-input': {
      fontSize: ({ fontSize }) => fontSize || '14px',
      boxSizing: ({ boxSizing }) => boxSizing || 'content-box',
      background: 'transparent !important',
      border: 'none !important',
      transform: 'translateY(9px)',
    },
    '& .MuiInputLabel-outlined': {
      color: '#787F88 !important',
      transform: ({ size }) => {
        if (size === 'smaller') {
          return 'translate(16px, 17px) scale(1)';
        }
        return 'translate(16px, 20px) scale(1)';
      },
      '&.MuiInputLabel-shrink.MuiFormLabel-filled': {
        transform: 'translate(14px, 10px) scale(0.8)',
        fontWeight: 400,
        padding: '0 4px!important',
        marginLeft: '-2px',
        color: '#6A6A6A',
      },
    },
    '& .MuiInputBase-root': {
      backgroundColor: 'transparent',
      color: THEME.primaryColors.black,
      borderColor: 'red',
      fontWeight: ({ withoutBorder }) => (withoutBorder ? 'bold' : 'normal'),
      '&:hover fieldset': {
        borderColor: THEME.greyColors.grey300,
      },
      '& fieldset': {
        borderColor: `${THEME.greyColors.grey16}`,
      },
      '&.Mui-focused fieldset': {
        borderColor: `${THEME.primaryColors.primary}`,
        borderWidth: '1px',
      },
      '& .MuiSelect-root': {
        padding: ({ padding }) => padding || '4px 30px 4px 15px',
        borderRadius: '32px',
        width: ({ width }) => width || '100%',
        borderColor: 'blue',
      },
      '&.Mui-focused .MuiSelect-select': {
        backgroundColor: '#fff',
      },
      '& .MuiSelect-select:focus': {
        backgroundColor: '#fff',
        borderColor: '#9CA0FF !important',
      },
      '& .MuiSelect-iconOpen + fieldset': {
        borderColor: '#4E40EF !important',
      },
    },
    '& label': {
      color: THEME.primaryColors.black,
      fontWeight: 'normal',
      fontSize: ({ size }) => {
        if (size === 'smaller') {
          return '14px';
        }
        return '18px';
      },
    },
    '& label.Mui-focused': {
      color: THEME.primaryColors.black,
    },
    '& .MuiInputBase-root fieldset': {
      borderRadius: '8px',
    },
  },
}));

const StyledFormControlOnboarding = ({ backgroundColor, margin, boxSizing, ...props }) => {
  const classes = useStyles({ backgroundColor, margin, boxSizing, ...props });

  return <FormControl variant="standard" {...props} classes={classes} />;
};

StyledFormControlOnboarding.propTypes = {
  backgroundColor: string,
  margin: string,
  boxSizing: string,
};

StyledFormControlOnboarding.defaultProps = {
  boxSizing: null,
  backgroundColor: null,
  margin: null,
};

export default StyledFormControlOnboarding;
