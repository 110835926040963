import React from 'react';

const Chat = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2011_6569)">
      <path
        d="M17.5 1.25C17.8315 1.25 18.1495 1.3817 18.3839 1.61612C18.6183 1.85054 18.75 2.16848 18.75 2.5V12.5C18.75 12.8315 18.6183 13.1495 18.3839 13.3839C18.1495 13.6183 17.8315 13.75 17.5 13.75H5.5175C4.85451 13.7501 4.21873 14.0136 3.75 14.4825L1.25 16.9825V2.5C1.25 2.16848 1.3817 1.85054 1.61612 1.61612C1.85054 1.3817 2.16848 1.25 2.5 1.25H17.5ZM2.5 0C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5L0 18.4913C2.62686e-05 18.6149 0.0367407 18.7358 0.105497 18.8386C0.174252 18.9414 0.271959 19.0215 0.386249 19.0687C0.50054 19.116 0.626276 19.1282 0.747545 19.104C0.868814 19.0797 0.980163 19.0201 1.0675 18.9325L4.63375 15.3663C4.86812 15.1318 5.18601 15.0001 5.5175 15H17.5C18.163 15 18.7989 14.7366 19.2678 14.2678C19.7366 13.7989 20 13.163 20 12.5V2.5C20 1.83696 19.7366 1.20107 19.2678 0.732233C18.7989 0.263392 18.163 0 17.5 0L2.5 0Z"
        fill="black"
      />
      <path
        d="M6.25 7.5C6.25 7.83152 6.1183 8.14946 5.88388 8.38388C5.64946 8.6183 5.33152 8.75 5 8.75C4.66848 8.75 4.35054 8.6183 4.11612 8.38388C3.8817 8.14946 3.75 7.83152 3.75 7.5C3.75 7.16848 3.8817 6.85054 4.11612 6.61612C4.35054 6.3817 4.66848 6.25 5 6.25C5.33152 6.25 5.64946 6.3817 5.88388 6.61612C6.1183 6.85054 6.25 7.16848 6.25 7.5ZM11.25 7.5C11.25 7.83152 11.1183 8.14946 10.8839 8.38388C10.6495 8.6183 10.3315 8.75 10 8.75C9.66848 8.75 9.35054 8.6183 9.11612 8.38388C8.8817 8.14946 8.75 7.83152 8.75 7.5C8.75 7.16848 8.8817 6.85054 9.11612 6.61612C9.35054 6.3817 9.66848 6.25 10 6.25C10.3315 6.25 10.6495 6.3817 10.8839 6.61612C11.1183 6.85054 11.25 7.16848 11.25 7.5ZM16.25 7.5C16.25 7.83152 16.1183 8.14946 15.8839 8.38388C15.6495 8.6183 15.3315 8.75 15 8.75C14.6685 8.75 14.3505 8.6183 14.1161 8.38388C13.8817 8.14946 13.75 7.83152 13.75 7.5C13.75 7.16848 13.8817 6.85054 14.1161 6.61612C14.3505 6.3817 14.6685 6.25 15 6.25C15.3315 6.25 15.6495 6.3817 15.8839 6.61612C16.1183 6.85054 16.25 7.16848 16.25 7.5Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_2011_6569">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Chat;
