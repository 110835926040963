import styled from 'styled-components';
import { Chip, TextField } from '@mui/material';
import THEME from '../../constants/theme';

export const StyledInput = styled(TextField)`
  & .MuiInputBase-root {
    border: 1px solid ${THEME.greyColors.grey8};
    background-color: ${THEME.primaryColors.white};
    border-radius: 4px;
    padding: 0 4px;
    min-height: 40px;
    input {
      line-height: 32px;
      color: ${THEME.primaryColors.black};
    }
    &::before,
    &::after {
      display: none;
    }
  }
`;

export const StyledChip = styled(Chip)`
  &.MuiChip-root {
    font-size: 14px;
    border: none;
    border-radius: 4px;
    background: ${THEME.greyColors.greyButton};
    span {
      padding: 1px 8px 0;
    }
    & .MuiChip-deleteIcon {
      width: 16px;
      margin: 0 8px 0 0;
    }
  }
`;
