const signUpAcceptingPaymentsNames = {
  acceptingPayments: 'acceptingPayments',
};

const signUpAcceptingPaymentsFields = [{ field: signUpAcceptingPaymentsNames.acceptingPayments, label: '' }];

const initialValues = {
  [signUpAcceptingPaymentsNames.acceptingPayments]: '',
};

export { initialValues, signUpAcceptingPaymentsFields, signUpAcceptingPaymentsNames };
