import styled from 'styled-components';
import Box from '@mui/material/Box';
import THEME from '../../../../../../../constants/theme';

export const ConditionsBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ElseConditionBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  width: 100%;
  border: 1px solid ${THEME.greyColors.grey200};
  border-radius: 8px;
  margin-top: 8px;
  padding: 12px 20px;
  cursor: pointer;
`;
