import React, { useState } from 'react';
import { string, number, oneOfType } from 'prop-types';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import THEME from '../../constants/theme';

const useStyles = makeStyles(() => ({
  tooltip: {
    borderRadius: '6px',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
    background: THEME.primaryColors.black,
    maxWidth: ({ maxWidth }) => maxWidth,
    padding: ({ padding }) => padding || '8px 12px',
  },
}));

const StyledTooltip = ({ children, title, placement, width, height, maxWidth, padding }) => {
  const classes = useStyles({ maxWidth, padding });
  const [open, setOpen] = useState(false);

  return (
    <Tooltip
      placement={placement}
      disableFocusListener
      disableTouchListener
      disableHoverListener
      title={title}
      classes={classes}
      open={title && open}
    >
      <Box
        display="flex"
        alignItems="center"
        width={width}
        height={height}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        {children}
      </Box>
    </Tooltip>
  );
};

StyledTooltip.propTypes = {
  title: string,
  placement: string,
  width: oneOfType([string, number]),
  height: oneOfType([string, number]),
  maxWidth: oneOfType([string, number]),
  padding: string,
};

StyledTooltip.defaultProps = {
  title: '',
  placement: 'top',
  width: '100%',
  height: '100%',
  maxWidth: 'auto',
  padding: '8px 12px',
};

export default StyledTooltip;
