import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Skeleton from '@mui/lab/Skeleton';
import range from 'lodash/range';
import { useNotificationManager } from '../../../../hooks/useNotificationManager';
import { P14 } from '../../../atoms';
import { NodeLibraryWrapper } from './styled';
import { useFlowEditorContext } from '../../context';
import NodeLibraryList from './NodeLibraryList';

const RANGE = range(12);

const NodeLibraryContainer = ({ search, open }) => {
  const { t } = useTranslation();
  const { stepLibraryData, stepLibraryError, stepLibraryLoading } = useFlowEditorContext();
  const searchValue = search?.toLowerCase()?.trim();
  const isLoading = stepLibraryLoading || !stepLibraryData;
  const entries = useMemo(() => {
    const steps = Object.entries(stepLibraryData?.getFlowStepLibrary || {});
    return steps?.filter(([name]) => !name.includes('extra') && name !== '__typename') || [];
  }, [stepLibraryData?.getFlowStepLibrary]);

  const filteredEntries = useMemo(() => {
    return searchValue === ''
      ? entries
      : entries?.map(([name, items]) => {
          const matches = items?.filter(({ name: itemName }) => itemName?.toLowerCase().includes(searchValue)) || [];
          return [name, matches];
        });
  }, [entries, searchValue]);

  useNotificationManager('error', stepLibraryError?.message, 'Fetch Node Library', 5000);

  return (
    <NodeLibraryWrapper open={open} $searchActive={!!search}>
      {isLoading && (
        <Box
          mt="12px"
          display="flex"
          flexWrap="wrap"
          alignItems="flex-start"
          height="500px"
          width="100%"
          justifyContent="center"
        >
          {RANGE.map((i) => (
            <Box width="90%" height={48} mb={1} key={i}>
              <Skeleton height="48px" />
            </Box>
          ))}
        </Box>
      )}
      {filteredEntries?.length > 0 && !stepLibraryLoading && <NodeLibraryList filteredEntries={filteredEntries} />}
      {filteredEntries?.length === 0 && !isLoading && (
        <Box flexGrow={1} marginTop="32px">
          <P14 color="#787F88" textAlign="center">
            {t('flowEditor.left.nodeNotFound', { search })}
          </P14>
        </Box>
      )}
    </NodeLibraryWrapper>
  );
};

NodeLibraryContainer.propTypes = {
  search: PropTypes.string,
  open: PropTypes.bool.isRequired,
};

NodeLibraryContainer.defaultProps = {
  search: '',
};

export { NodeLibraryContainer };
