import React from 'react';
import PropTypes from 'prop-types';

const SearchMedium = ({ size, fill }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.16601 3.33317C5.94435 3.33317 3.33268 5.94484 3.33268 9.1665C3.33268 12.3882 5.94435 14.9998 9.16601 14.9998C12.3877 14.9998 14.9993 12.3882 14.9993 9.1665C14.9993 5.94484 12.3877 3.33317 9.16601 3.33317ZM1.66602 9.1665C1.66602 5.02437 5.02388 1.6665 9.16601 1.6665C13.3082 1.6665 16.666 5.02437 16.666 9.1665C16.666 13.3086 13.3082 16.6665 9.16601 16.6665C5.02388 16.6665 1.66602 13.3086 1.66602 9.1665Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2851 13.2856C13.6105 12.9601 14.1382 12.9601 14.4636 13.2856L18.0886 16.9106C18.414 17.236 18.414 17.7637 18.0886 18.0891C17.7632 18.4145 17.2355 18.4145 16.9101 18.0891L13.2851 14.4641C12.9597 14.1387 12.9597 13.611 13.2851 13.2856Z"
      fill={fill}
    />
  </svg>
);

SearchMedium.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

SearchMedium.defaultProps = {
  size: 20,
  fill: '#999999',
};

export default SearchMedium;
