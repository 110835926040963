import React, { useCallback, useMemo } from 'react';
import Skeleton from '@mui/lab/Skeleton';
import FormHelperText from '@mui/material/FormHelperText';
import { useQuery } from '@apollo/client';
import { StyledFormControl } from '../../styled';
import { useActionInputContext } from '../actionInputContext';
import Select, { NONE } from '../../../../../../../forms/_common/Select';
import { GQL_Q_LOOKUP_ACTION_INPUT_SELECTION } from '../../../../../../../../utils/queries/flows/queries';
import { useNodeDataToSave } from '../../hooks/useNodeDataToSave';
import { CONNECTION_STATUS } from '../../../../../../../../pages/ConnectionsPage/components/constant';

const LookupInput = () => {
  const { fieldValue, fieldId, setInputField, validationMessage } = useActionInputContext();
  const [{ actionId, connectionId, connectionStatus }] = useNodeDataToSave();

  const { data, loading } = useQuery(GQL_Q_LOOKUP_ACTION_INPUT_SELECTION, {
    variables: {
      actionId,
      connectionId,
      inputId: fieldId,
    },
  });

  const lookupSelection = useMemo(() => {
    return data?.lookupActionInputSelection?.map((item) => {
      return {
        value: item.key,
        title: item.label,
      };
    });
  }, [data?.lookupActionInputSelection]);

  const isShowSelection = !loading && lookupSelection;

  const onChange = useCallback(({ target }) => setInputField(target.value), [setInputField]);

  return connectionStatus !== CONNECTION_STATUS.NOT_CONNECTED ? (
    <StyledFormControl fullWidth error={!!validationMessage}>
      {loading && <Skeleton height="30px" animation="wave" />}
      {isShowSelection ? (
        <Select name="connection" value={fieldValue || NONE} options={lookupSelection} onChange={onChange} />
      ) : null}
      <FormHelperText>{validationMessage}</FormHelperText>
    </StyledFormControl>
  ) : null;
};

export default LookupInput;
