import { PAYMENT_METHODS } from './paymentMethodDictionary';

const PAYMENT_METHODS_TITLE_MAP = {
  [PAYMENT_METHODS.CARD]: 'card',
  [PAYMENT_METHODS.CASH]: 'cash',
  [PAYMENT_METHODS.MASTERCARD]: 'mastercard',
  [PAYMENT_METHODS.VISA]: 'visa',
  [PAYMENT_METHODS.AMEX]: 'amex',
  [PAYMENT_METHODS.PAYPAL]: 'paypal',
  [PAYMENT_METHODS.ACCOUNT]: 'account',
  [PAYMENT_METHODS.ACH]: 'ach',
  [PAYMENT_METHODS.SEPA_CREDIT_TRANSFER]: 'sepa credit transfer',
  [PAYMENT_METHODS.SEPA_DIRECT_DEBIT]: 'sepa direct debit',
  [PAYMENT_METHODS.IDEAL]: 'ideal',
  [PAYMENT_METHODS.DIRECT_DEBIT]: 'direct debit',
  [PAYMENT_METHODS.BAN_CONTACT]: 'ban contact',
  [PAYMENT_METHODS.BACS]: 'bacs',
  [PAYMENT_METHODS.BECS]: 'becs',
  [PAYMENT_METHODS.GIRO_PAY]: 'giropay',
  [PAYMENT_METHODS.SOFORT]: 'sofort',
  [PAYMENT_METHODS.WALLET]: 'wallet',
  [PAYMENT_METHODS.APPLE_PAY]: 'Apple Pay',
  [PAYMENT_METHODS.GOOGLE_PAY]: 'Google Pay',
  [PAYMENT_METHODS.SAMSUNG_PAY]: 'Samsung Pay',
  [PAYMENT_METHODS.ALI_PAY]: 'AliPay',
  [PAYMENT_METHODS.WE_CHAT]: 'WeChat',
  [PAYMENT_METHODS.BUY_NOW_PAY_LATER]: 'buy now pay later',
  [PAYMENT_METHODS.KLARNA]: 'klarna',
  [PAYMENT_METHODS.AFFIRM]: 'affrim',
  [PAYMENT_METHODS.AFTER_PAY]: 'afterpay',
  [PAYMENT_METHODS.CLEAR_PAY]: 'clearpay',
  [PAYMENT_METHODS.CRYPTO]: 'Cryptocurrency',
  [PAYMENT_METHODS.BITCOIN]: 'bitcoin',
  [PAYMENT_METHODS.ACH]: 'Automated clearing house',
  [PAYMENT_METHODS.ACH_CREDIT]: 'Automated clearing house credit',
  [PAYMENT_METHODS.ACH_DEBIT]: 'Automated clearing house debit',
};

export { PAYMENT_METHODS_TITLE_MAP };
