import React from 'react';
import Box from '@mui/material/Box';
import { NotificationManager } from 'react-notifications';
import Title from '../../Title';
import { StyledCopyToClipboard, StyledCopyValue, StyledFormControl } from './styled';
import { useFlowEditorContext } from '../../../../context';
import { CopyToClipboard } from '../../../../../atoms';
import THEME from '../../../../../../constants/theme';
import Copy from '../../../../../../assets/icons/Copy';
import ToastCustomContainer from '../../../../../ToastCustomContainer';
import { TOAST_TIMEOUT } from '../../../../../../constants/toasts';

const FlowIdCopyToClipboard = () => {
  const { flowId } = useFlowEditorContext();

  const handleCopy = () => {
    NotificationManager.success(
      <ToastCustomContainer message="Copied Flow ID to clipboard." title="Flow ID." />,
      null,
      TOAST_TIMEOUT
    );
  };

  return (
    <Box component="section">
      <Title fontSize="12px">Flow ID:</Title>
      <StyledFormControl $bgColor={THEME.primaryColors.primaryLight} fullWidth>
        <CopyToClipboard className="smallUrl" text={flowId} onCopy={handleCopy} fill={THEME.primaryColors.primary}>
          <StyledCopyToClipboard>
            <StyledCopyValue $color={THEME.primaryColors.primary}>{flowId}</StyledCopyValue>
            <Copy fill={THEME.primaryColors.primary} />
          </StyledCopyToClipboard>
        </CopyToClipboard>
      </StyledFormControl>
    </Box>
  );
};

export default FlowIdCopyToClipboard;
