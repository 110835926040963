import THEME from '../../../../../../../constants/theme';

const BRANCH_RESULT_RANK = {
  POSITIVE: 'POSITIVE',
  NEGATIVE: 'NEGATIVE',
  NEUTRAL: 'NEUTRAL',
};

export const getResultColor = (item, rank) => {
  switch (item) {
    case 'container':
      switch (rank) {
        case BRANCH_RESULT_RANK.POSITIVE:
          return THEME.secondaryColors.positiveRank;
        case BRANCH_RESULT_RANK.NEGATIVE:
          return THEME.secondaryColors.negativeRank;
        default:
          return 'transparent';
      }
    case 'box':
      switch (rank) {
        case BRANCH_RESULT_RANK.POSITIVE:
          return THEME.statusColors.succeeded;
        case BRANCH_RESULT_RANK.NEGATIVE:
          return THEME.secondaryColors.nodeError;
        default:
          return THEME.greyColors.grey24;
      }
    case 'title':
      switch (rank) {
        case BRANCH_RESULT_RANK.POSITIVE:
          return '#fff';
        case BRANCH_RESULT_RANK.NEGATIVE:
          return '#fff';
        default:
          return '#000';
      }
    default:
      return 'transparent';
  }
};
