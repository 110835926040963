import React from 'react';
import PropTypes from 'prop-types';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardRounded';
import { useFormikContext } from 'formik';
import { ButtonRounded, CircularLoader } from '../../atoms';

const SubmitButton = ({ children, icon, showIcon, width, isLoading, ...restProps }) => {
  const formik = useFormikContext();
  const loading = formik?.isSubmitting || isLoading;

  return (
    <ButtonRounded
      type="submit"
      color="primary"
      variant="contained"
      disabled={formik?.isSubmitting}
      // $showIcon={showIcon} // Remove once fully confirmed
      // $isLoading={loading} // Remove once fully confirmed
      width={width}
      height="48px"
      fontWeight={500}
      endIcon={
        !loading && showIcon ? (
          <ArrowForwardIcon />
        ) : (
          loading && <CircularLoader bgcolor="#fff" size="16px" thickness={5} />
        )
      }
      {...restProps}
    >
      {children}
    </ButtonRounded>
  );
};
SubmitButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isLoading: PropTypes.bool,
  icon: PropTypes.node,
  showIcon: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  width: PropTypes.string,
};
SubmitButton.defaultProps = {
  label: undefined,
  isLoading: false,
  icon: undefined,
  showIcon: true,
  children: null,
  width: 'auto',
};

export default SubmitButton;
