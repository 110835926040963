import { useMemo } from 'react';
import buildValidator from '../utils/validators/buildValidator';

const useDynamicValidation = (func, parameter) => {
  const validationObject = useMemo(() => {
    return buildValidator(func(parameter));
  }, [func, parameter]);

  return validationObject;
};

export default useDynamicValidation;
