const PAYMENT_FIELDS = {
  amount: 'amount',
  currency: 'currency',
  status: 'status',
  method: 'method',
  type: 'type',
  customer: 'customer',
  processor: 'processor',
  date: 'date',
  /**Extra columns Starts here */
  id: 'payment id',
  country: 'country',
  sourceId: 'source id',
  net: 'net fee',
  fee: 'fee',
  processingFee: 'processing fee',
  tax: 'tax',
  exchangeRate: 'exchange rate',
  localCurrency: 'local currency',
  localAmount: 'local amount',
  customerId: 'customer id',
  phone: 'phone',
  preferredPaymentMethod: 'preferred payment method',
  receiptMessage: 'receipt message',
  receiptType: 'receipt type',
  receiptDate: 'receipt date',
  receiptUrl: 'receipt url',
  networkStatus: 'network status',
  corePaymentMethod: 'core payment method',
  reason: 'reason',
  riskLevel: 'risk level',
  riskScore: 'risk score',
  declineCode: 'decline code',
  latestCharge: 'latest charge',
  invoice: 'invoice',
  payout: 'payout',
  statementDescriptor: 'statement descriptor',
  description: 'payment description',
  paymentServiceId: 'payment service id',
  category: 'category',

  /**Additional Card data */
  cardType: 'card type',
  cardProduct: 'product name',
  cardIssuingBank: 'issuing bank',
};

export { PAYMENT_FIELDS };
