import styled, { css } from 'styled-components';

const StyledTag = styled.div`
  position: relative;
  border-radius: 4px;
  padding: 4px 12px;
  margin-right: 20px;
  margin-bottom: 4px;
  font-size: 14px;
  letter-spacing: 0.6px;
  align-self: flex-start;
  line-height: 24px;
  display: flex;
  align-items: center;
  // background: ${({ isSelected }) => (isSelected ? '#333333' : ' #f4f4f4')};
  // color: ${({ isSelected }) => (isSelected ? 'white' : '#6a6a6a')};
  background: #f4f4f4;
  color: #000000;
  transition: 0.2s;

  ${({ isPersistent }) => {
    if (isPersistent) {
      return css`
        background: #333333;
        padding-right: 30px;
        margin-right: 0;
        color: #ffffff;
      `;
    }
    return '';
  }};
  
  svg {
    position: absolute;
    margin-left: 4px;
    right: 10px;
    top: 8px;
    transition: 0.2s;
  }

  ${({ isSelected, isPersistent }) => {
    if (isSelected && !isPersistent) {
      return css`
        background: #333333;
        color: #ffffff;
        svg {
          transform: translateX(8px);
          opacity: 0;
        }
        &:hover {
          padding-right: 30px;
          margin-right: 0;
          svg {
            transform: translateX(0px);
            opacity: 1;
          }
        }
      `;
    }
    if (isPersistent) {
      return css`
        svg {
          transform: translateX(0px);
          opacity: 1;
        }
      `;
    }
    return '';
  }};

  &:hover {
    cursor: pointer;
    background: ${({ isSelected }) => (isSelected ? '#333333' : '#E8E8E8')};
    ${({ isSelected, isPersistent }) => {
      if (isSelected || isPersistent) {
        return css`
          background: #333333;
          padding-right: 30px;
          margin-right: 0;
          color: #ffffff;
        `;
      }
      return '';
    }};
  }
`;

// ${({ isSelected }) =>
//   VARIANTS[color] ||
//   css`
//     color: #000000;
//     background: #e9e9e9;
//   `};

export { StyledTag };
