import React from 'react';
import { oneOfType, string, number } from 'prop-types';

const ABTest = ({ fill, width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.32552 9.1397H0L2.97838 0.863281H4.4211L7.39948 9.1397H6.07395L3.73409 2.36662H3.66943L1.32552 9.1397ZM1.54779 5.89864H5.84765V6.94936H1.54779V5.89864Z"
      fill={fill}
    />
    <path d="M12.4096 0.753906L10.1234 9.2474H9.02539L11.3116 0.753906H12.4096Z" fill={fill} />
    <path
      d="M14.0352 9.1397V0.863281H17.0661C17.6534 0.863281 18.1397 0.96027 18.525 1.15425C18.9102 1.34553 19.1985 1.60552 19.3898 1.9342C19.5811 2.2602 19.6767 2.62795 19.6767 3.03746C19.6767 3.38231 19.6134 3.67328 19.4868 3.91036C19.3601 4.14475 19.1904 4.33334 18.9776 4.47613C18.7674 4.61623 18.5357 4.71861 18.2825 4.78327V4.86409C18.5573 4.87756 18.8253 4.96647 19.0867 5.13081C19.3507 5.29246 19.5689 5.52281 19.7414 5.82186C19.9138 6.12091 20 6.48462 20 6.91299C20 7.33597 19.9003 7.71585 19.7009 8.05261C19.5043 8.38669 19.1998 8.65206 18.7876 8.84873C18.3754 9.04271 17.8487 9.1397 17.2075 9.1397H14.0352ZM15.2839 8.06878H17.0863C17.6844 8.06878 18.1127 7.95293 18.3714 7.72123C18.63 7.48954 18.7593 7.19991 18.7593 6.85237C18.7593 6.59104 18.6933 6.35126 18.5613 6.13303C18.4293 5.91481 18.2407 5.74103 17.9956 5.61171C17.7531 5.4824 17.4648 5.41774 17.1307 5.41774H15.2839V8.06878ZM15.2839 4.4438H16.957C17.2371 4.4438 17.4891 4.38992 17.7127 4.28215C17.939 4.17439 18.1181 4.02352 18.2501 3.82954C18.3849 3.63286 18.4522 3.40117 18.4522 3.13445C18.4522 2.79229 18.3323 2.50536 18.0925 2.27367C17.8528 2.04197 17.485 1.92612 16.9893 1.92612H15.2839V4.4438Z"
      fill={fill}
    />
  </svg>
);

ABTest.propTypes = {
  fill: oneOfType([string, number]),
  width: oneOfType([string, number]),
  height: oneOfType([string, number]),
};
ABTest.defaultProps = {
  fill: 'white',
  width: 20,
  height: 10,
};

export default ABTest;
