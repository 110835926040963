import { Box } from '@mui/material';
import React from 'react';
import THEME from '../../../../constants/theme';
import { P14 } from '../../../atoms';
import { HighlightedText } from './styled';

const StepThree = () => (
  <Box>
    <P14 margin="0 0 12px 0" color={THEME.greyColors.grey23}>
      Once you&apos;ve published your payment processing automation, it&apos;s time to integrate your checkout. This
      will usually require a developer&apos;s help.
    </P14>
    <P14 margin="0 0 12px 0" color={THEME.greyColors.grey23}>
      <HighlightedText $fontWeight={500}>Checkout Integration:</HighlightedText> Build a custom checkout form, and
      securely capture payment information with our Checkout SDKs.
    </P14>
  </Box>
);

export default StepThree;
