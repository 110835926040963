import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { v4 } from 'uuid';
import LinesEllipsis from 'react-lines-ellipsis';
import PropTypes from 'prop-types';
import { L14M } from '../../../atoms';
import { flowStepPropType } from '../../types';
import { ItemContainer, StyledStatusDot } from './styled';
import NodeIcon from './NodeIcon';
import DragHandleIcon from '../../../../assets/icons/DragHandleIcon';
import NodeLibraryItemTooltip from './NodeLibraryItemTooltip';
import THEME from '../../../../constants/theme';
import { CONNECTION_STATUS } from '../../../../pages/ConnectionsPage/components/constant';

const NodeLibraryItem = ({ nodeData, section }) => {
  const { name, connectionStatus, __typename } = nodeData;
  const [isDragging, setIsDragging] = useState(false);

  const isShowStatusDot =
    section === 'services' &&
    (connectionStatus === CONNECTION_STATUS.CONNECTED || connectionStatus === CONNECTION_STATUS.BROKEN);
  const statusDotColor =
    connectionStatus === CONNECTION_STATUS.BROKEN ? THEME.secondaryColors.nodeError : THEME.secondaryColors.greenDark;

  const onDragStart = (event) => {
    setIsDragging(true);
    const newNodeData = {
      ...nodeData,
      isValid: true,
      id: v4(),
    };
    event.dataTransfer.setData('application/new-node-data', JSON.stringify(newNodeData));
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.effectAllowed = 'move';
  };

  const onDragEnd = (e) => {
    e.stopPropagation();
    setIsDragging(false);
  };

  return (
    <NodeLibraryItemTooltip name={name} __typename={__typename} isDragging={isDragging}>
      <ItemContainer
        onMouseDown={(e) => e.stopPropagation()}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        draggable={true}
      >
        <Box position="relative">
          <NodeIcon mr="12px" nodeData={nodeData} type={section} />
          {isShowStatusDot && <StyledStatusDot $bgColor={statusDotColor} />}
        </Box>
        <Box textAlign="center">
          <L14M as="span" title={name} color={THEME.primaryColors.black}>
            <LinesEllipsis text={name} maxLine={2} basedOn="letters" />
          </L14M>
        </Box>
        <Box display="flex" ml="auto" alignItems="center">
          <DragHandleIcon />
        </Box>
      </ItemContainer>
    </NodeLibraryItemTooltip>
  );
};

NodeLibraryItem.propTypes = {
  nodeData: flowStepPropType.isRequired,
  section: PropTypes.string.isRequired,
};

export { NodeLibraryItem };
