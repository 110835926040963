import React, { memo, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import OnboardingLayout from '../../components/layouts/OnboardingLayout';
import THEME from '../../constants/theme';
import { L12, P14 } from '../../components/atoms';
import { UI_ROUTES } from '../../constants/routes';
import { SlackButtonCover, LineOr } from './styled';
import OnboardingLayoutBox from '../../components/layouts/OnboardingLayoutBox';
import SubmitButton from '../../components/forms/_common/SubmitButton';
import GoogleAuth from '../../components/GoogleAuth';
import { FREE_EMAIL_ERROR_FLAG, useSignUpWithGoogleCallback } from './useSignUpWithGoogleCallback';
import { useRegistrationStorage } from '../../hooks/registration';
import { GOOGLE_AUTH_TYPE } from '../../hooks/useGoogleOAuth';
import useSearch from '../../hooks/useSearch';
import { useGlobalContext } from '../../containers/App/context';

const SignUp = memo(() => {
  const { t } = useTranslation();
  const [searchParams] = useSearch();
  const [, , clearRegistrationSession] = useRegistrationStorage();
  const { completed: isSignedUpWithGoogle } = useSignUpWithGoogleCallback();
  const { isReactGAInitialized } = useGlobalContext();
  const { push } = useHistory();

  const isFreeMail = !!searchParams[FREE_EMAIL_ERROR_FLAG];

  useEffect(() => {
    push(UI_ROUTES.signIn);
  }, [push]);

  useEffect(() => {
    if (!isSignedUpWithGoogle) {
      clearRegistrationSession();
    }
  }, [isSignedUpWithGoogle, clearRegistrationSession]);

  const handleClick = () => {
    if (isReactGAInitialized) {
      // For Google Analytics
      ReactGA.event({
        category: 'Button',
        action: "Click the button for 'Sign up with email'",
      });
    }
  };

  return (
    <OnboardingLayout
      theme="dark"
      navigationRight={
        <P14 color={THEME.greyColors.lightGrey}>
          {t('Already have an account?')}{' '}
          <Link to={UI_ROUTES.signIn} className="text-primary">
            {t('Sign in')}
          </Link>
        </P14>
      }
    >
      <OnboardingLayoutBox theme="dark">
        <Box maxWidth="336px" margin="0 auto">
          <h1>{t('Welcome to WhenThen')}</h1>
          <SlackButtonCover>
            <Box zIndex="10">
              <GoogleAuth type={GOOGLE_AUTH_TYPE.signUp}>{t('Sign up with Google')}</GoogleAuth>
            </Box>
            {isFreeMail && (
              <L12 data-cy="error" color={THEME.secondaryColors.danger}>
                {t('Please use your company email')}
              </L12>
            )}
            <LineOr>{t('or')}</LineOr>
            <Box>
              <Link to={UI_ROUTES.signUpPersonalDetails}>
                <SubmitButton width="100%" className="gradient" showIcon={false} onClick={handleClick}>
                  {t('Sign up with email')}
                </SubmitButton>
              </Link>
            </Box>
          </SlackButtonCover>
        </Box>
      </OnboardingLayoutBox>
    </OnboardingLayout>
  );
});

export default SignUp;
