import React from 'react';
import { number } from 'prop-types';
import { CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import THEME from '../../../constants/theme';

const StyledProgress = withStyles({
  root: {
    '& .MuiCircularProgress-circle': { stroke: ({ bgcolor }) => bgcolor || THEME.primaryColors.primary },
  },
})(CircularProgress);

const CircularLoader = ({ thickness, ...rest }) => <StyledProgress thickness={thickness} {...rest} />;

CircularLoader.propTypes = {
  thickness: number,
};

CircularLoader.defaultProps = {
  thickness: 3,
};

export default CircularLoader;
