import styled from 'styled-components';
import Button from '@mui/material/Button';

const StyledButton = styled(Button)`
  .MuiButton-label {
    text-transform: none;
  }
`;

export { StyledButton };
