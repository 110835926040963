import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import capitalize from 'lodash/capitalize';
import WebhookGeneratedUrlField from './fields/WebhookGeneratedUrlField';
import RadioButtonsSection from './sections/RadioButtonsSection';
import { useNodeDataToSave } from './fields/hooks/useNodeDataToSave';
import { useValidationMessage } from './fields/hooks';
import Title from '../Title';
import SubTitle from '../SubTitle';
import InfoLinkSection from './sections/InfoLinkSection';
import DelayActionWebhookField from './fields/DelayActionWebhookField';
import TimeBlock from './TimeBlock';
import { StyledFormControl } from './fields/styled';
import Select, { NONE } from '../../../../forms/_common/Select';

const GENERAL_TYPE = 'GENERAL';
const DEV_ZONE_URL = process.env.REACT_APP_DEV_ZONE_URL;

const DelayActionStep = () => {
  const { t } = useTranslation();
  const validationMessage = useValidationMessage('delay');
  const [{ delayTypes, selectedType, selectedInputType, delayInputTypes = [] }, updateDataToSave] = useNodeDataToSave();
  const [selectedValue, setSelectedValue] = useState(() => selectedType);

  const options = useMemo(
    () =>
      delayTypes
        ?.filter((item) => (selectedInputType === GENERAL_TYPE ? item !== 'API' : true))
        ?.map((type) => ({
          value: type,
          title: type,
        })),
    [delayTypes, selectedInputType]
  );

  const inputTypesOptions = useMemo(
    () =>
      delayInputTypes?.map((type) => ({
        value: type,
        title: capitalize(
          type
            .toLowerCase()
            .split('_')
            .join(' ')
        ),
      })),
    [delayInputTypes]
  );

  const handleChange = (e, type) => {
    setSelectedValue(e.target.value);
    updateDataToSave({ [type]: e.target.value });
  };

  return (
    <>
      <Title>{t('Select Delay Type')}</Title>
      <SubTitle>
        {t('Choose between a general delay of your Automation, or delay the Capture of your Authorization')}
      </SubTitle>
      <StyledFormControl fullWidth>
        <Select
          name="method"
          value={selectedInputType ?? NONE}
          options={inputTypesOptions}
          onChange={(e) => handleChange(e, 'selectedInputType')}
          placeholder="Select"
          hideNone
        />
      </StyledFormControl>
      {selectedInputType && selectedInputType !== NONE && (
        <Box mt="40px">
          <RadioButtonsSection
            name="matchType"
            title={t('Wait for')}
            subTitle={t('Delay the automation until one of the following occurs')}
            options={options}
            validationMessage={validationMessage}
            value={selectedValue}
            handleChange={(e) => handleChange(e, 'selectedType')}
            mb="40px"
          />
          {selectedValue === 'WEBHOOK' && (
            <>
              <Title>{t('Options')}</Title>
              <SubTitle>
                {t('When we receive this webhook trigger, we will initiate the next step of this automation')}
              </SubTitle>
              <WebhookGeneratedUrlField stepType="DELAY_ACTION" titleSize="12px" />
              <DelayActionWebhookField titleSize="12px" />
            </>
          )}
          {selectedValue === 'API' && (
            <InfoLinkSection
              link={`${DEV_ZONE_URL}/payments/tutorial#capture-a-payment`}
              linkTitle={t('Developer documentation')}
              title={t('Setup Capture API')}
              subTitle={t(
                'Hold the automation until you use the WhenThen Capture API. We build this API option specifically for auth and capture later use cases. '
              )}
              linkSubTitle={t('Capture a Payment via API')}
            />
          )}
          {selectedValue === 'TIME' && <TimeBlock />}
        </Box>
      )}
    </>
  );
};

export default DelayActionStep;
