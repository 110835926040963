import React, { useMemo } from 'react';
import { func, shape, string } from 'prop-types';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import CallMadeIcon from '@mui/icons-material/CallMadeRounded';
import capitalize from '@mui/material/utils/capitalize';
import THEME from '../../../../../constants/theme';
import { ButtonRounded, P14, P12, Badge } from '../../../../../components/atoms';
import { ConnectionTileLogo, StyledIcon } from '../styled';
import EditableField from '../../../components/EditableField';
import { useEditableFieldHook } from '../../../hooks/useEditableFieldHook';
import { generateUserPilotAttribute } from '../../../../../constants/generateUserPilotLabel';
import { USER_PILOT_SECTION_ID } from '../../../constant';
import {
  LOGO_SIZE,
  SUB_TEXT_TRANSFORM,
  BUTTON_TYPES_MAP,
  CONNECTION_STATUS_BADGE_MAP,
  STATUS_TO_SHOW_STATUS_BADGE,
} from './constant';
import { CONNECTION_STATUS, CONNECTION_STATUS_MAP } from '../../../components/constant';
import { SectionLeft, SectionRight, ButtonsContainer, ConfigButton, NameContainer } from './styled';
import { useHandleViewConfiguration } from './hooks/useHandleViewConfiguration';

const DetailsConnectBlock = ({ connection, statusActionHandlers, setShouldModalOpen, setConfigForm }) => {
  const { t } = useTranslation();
  const { nameText, setNameText, handleFieldReset, handleSubmitName } = useEditableFieldHook(connection);

  const { label, event } = statusActionHandlers[connection?.status] ?? statusActionHandlers.NOT_CONNECTED;
  const isConnected = connection.status === CONNECTION_STATUS.CONNECTED;
  const showStatusBadge = STATUS_TO_SHOW_STATUS_BADGE.includes(connection.status);

  const linkText = useMemo(() => {
    const link = connection?.company?.homepageUrl?.replace(/(^\w+:|^)\/\//, '')?.replace('www.', '');
    return link?.charAt(link?.length - 1) === '/' ? link?.slice(0, -1) : link ?? '';
  }, [connection]);

  const handleViewConfiguration = useHandleViewConfiguration(connection, setConfigForm, setShouldModalOpen);

  return (
    <Box display="flex" alignItems="center" mb="54px">
      <SectionLeft>
        <ConnectionTileLogo height={LOGO_SIZE} width={LOGO_SIZE} img={connection?.company?.logo}>
          <img src={connection?.company?.logo} alt={connection?.company?.name} />
        </ConnectionTileLogo>

        <Box ml="22px" display="flex" flexDirection="column" alignItems="flex-start">
          <NameContainer>
            <EditableField
              nameText={nameText}
              updateValue={setNameText}
              value={nameText}
              reset={handleFieldReset}
              submit={handleSubmitName}
              height="auto"
              fontSize="32px !important"
              fontWeight="700"
              lineHeight="37px"
              editingTransform="translateX(-2px)"
              padding="0 0 0 2px !important"
              inputCoverBeforeHeight="130%"
              margin="0 12px 0 0"
              {...generateUserPilotAttribute(USER_PILOT_SECTION_ID, 'details', 'connection_name')}
            />

            {showStatusBadge && (
              <Badge color={CONNECTION_STATUS_BADGE_MAP[connection.status]}>
                {t(CONNECTION_STATUS_MAP[connection.status])}
              </Badge>
            )}
          </NameContainer>

          <P14 color={THEME.greyColors.grey1}>{connection?.company?.categories[0]}</P14>

          <ButtonsContainer display="flex" mt="16px">
            {isConnected && (
              <ConfigButton mr="10px">
                <ButtonRounded onClick={handleViewConfiguration} type="button" variant="contained" color="primary">
                  {t('View Configuration')}
                </ButtonRounded>
              </ConfigButton>
            )}

            <ButtonRounded
              type="button"
              variant="contained"
              color={BUTTON_TYPES_MAP[connection?.status]}
              onClick={event}
              data-ci="connect-button"
              alignSelf={!isConnected ? 'flex-start' : ''}
              {...generateUserPilotAttribute(USER_PILOT_SECTION_ID, 'details', 'primary_button')}
            >
              {label}
            </ButtonRounded>
          </ButtonsContainer>
        </Box>
      </SectionLeft>

      <SectionRight>
        <P14 color={THEME.greyColors.grey11} lineHeight="24px">
          {connection?.company?.longDescription}
        </P14>
        <Box display="flex" mt="24px">
          <Box display="flex" mr="40px" flexDirection="column">
            <P12 color={THEME.greyColors.grey1} transform={SUB_TEXT_TRANSFORM}>
              {capitalize(t('Read more'))}
            </P12>
            <ButtonRounded
              component="a"
              variant="text"
              color="primary"
              href={connection?.company?.homepageUrl}
              target="_blank"
              rel="noreferrer noopener"
              inlineBlock
            >
              <Box display="flex">
                <Box component="span" fontWeight="normal">
                  {capitalize(linkText ?? '')}
                </Box>
                <StyledIcon>
                  <CallMadeIcon color="inherit" fontSize="inherit" />
                </StyledIcon>
              </Box>
            </ButtonRounded>
          </Box>

          <Box display="flex" flexDirection="column">
            <P12 color={THEME.greyColors.grey1} transform={SUB_TEXT_TRANSFORM}>
              {capitalize(t('Support'))}
            </P12>

            <ButtonRounded
              component="a"
              variant="text"
              color="primary"
              href={connection?.company?.supportUrl}
              target="_blank"
              rel="noreferrer noopener"
              inlineBlock
            >
              <Box display="flex">
                <Box component="span" fontWeight="normal">
                  {capitalize(connection?.company?.name ?? '')} Support
                </Box>
                <StyledIcon>
                  <CallMadeIcon color="inherit" fontSize="inherit" />
                </StyledIcon>
              </Box>
            </ButtonRounded>
          </Box>
        </Box>
      </SectionRight>
    </Box>
  );
};

DetailsConnectBlock.propTypes = {
  connection: shape({
    status: string,
    name: string,
    company: shape({
      name: string,
      logo: string,
      homepageUrl: string,
      supportUrl: string,
      longDescription: string,
    }),
  }).isRequired,
  statusActionHandlers: shape({}).isRequired,
  setShouldModalOpen: func.isRequired,
  setConfigForm: func.isRequired,
};

export default DetailsConnectBlock;
