import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { string, bool, func, arrayOf, shape, oneOf } from 'prop-types';
import Box from '@mui/material/Box';
import { useQuill } from 'react-quilljs';
import quillEmoji from 'quill-emoji';
import 'quill-mention';
import { formats } from './options';
import { StyledContainer, StyledBox } from './styled';
import 'quill/dist/quill.bubble.css';
import 'quill-emoji/dist/quill-emoji.css';
import { cutLabels, getQuillEditorHtml, getQuillInnerHtmlText } from './utils';
import THEME from '../../constants/theme';
import CategoryAlerts from '../../assets/icons/Properties';
import { PropertyTextEditorContextProvider } from './context';
import PropertyTextEditorModal from './PropertyTextEditorModal';
import { propertyObjectTypes } from './PropertyTextEditorModal/types';
import { useFlowEditorContext } from '../FlowEditor/context';

const theme = 'bubble';
const VALIDATION_ERROR_CATEGORY = 'MISSING_CONDITION_BRANCHING';

const PropertyTextEditor = ({
  initialEditorText,
  initialOpsObject,
  handleSave,
  properties,
  isFooter,
  minHeight,
  readOnly,
  handleSavePlainText,
  border,
  maxHeight,
  singleProperty,
  padding,
  type,
  placeholder,
  bgcolor,
  noBorder,
  showError,
  setShowError,
}) => {
  const [openPropertyModal, setOpenPropertyModal] = useState(false);
  const [selectedPropertyObject, setSelectedPropertyObject] = useState(null);
  const [selectedPathArr, setSelectedPathArr] = useState(null);
  const [cursorPosition, setCursorPosition] = useState({});

  const { quill, quillRef, Quill } = useQuill({
    theme,
    formats: singleProperty ? ['mention'] : formats,
    readOnly,
    placeholder,
  });

  const cutLabelsArr = cutLabels(properties);
  const quillInstanceRef = useRef();

  const { selectedNodeData } = useFlowEditorContext();
  const { validationErrors, changed } = selectedNodeData;

  const openPropertyMenu = () => {
    setOpenPropertyModal(true);
  };

  const updateCursorPosition = useCallback(
    (mention) => {
      const position = quill.getSelection();
      if (position) {
        if (mention) {
          setCursorPosition({ index: position.index - 1, length: 1 });
        } else {
          setCursorPosition(position);
        }
      }
      quill.blur();
    },
    [quill]
  );

  const onBlur = () => {
    updateCursorPosition();
    if (!quillInstanceRef.current) return;
    const { html, text } = getQuillInnerHtmlText(quillInstanceRef.current.root.innerHTML);
    if (text === '~') {
      handleSavePlainText('');
      return;
    }
    handleSave(html);
    handleSavePlainText(text, quill.editor.getDelta());
  };

  const mentionClickHandler = useCallback(
    (clickItem) => {
      const isCurrent = clickItem.event.composedPath().includes(quillRef.current);
      const item = properties?.items?.find((_item) => _item.key === clickItem.value.key);
      if (item && isCurrent) {
        updateCursorPosition(true);
        setSelectedPropertyObject(item);
        setOpenPropertyModal(true);
      }
    },
    [properties?.items, quillRef, updateCursorPosition]
  );

  // Show error message when upper node is removed
  useEffect(() => {
    const property = cutLabelsArr?.find((prop) => !prop.disabled && prop.key === initialEditorText);

    const missingConditionBranchingError = validationErrors.find(
      ({ category }) => category === VALIDATION_ERROR_CATEGORY
    );
    const shouldShorError = changed && missingConditionBranchingError && !property;

    setShowError(shouldShorError);
  }, [cutLabelsArr, initialEditorText, setShowError, changed, validationErrors]);

  useEffect(() => {
    if (quill) {
      const initialHTMLText = getQuillEditorHtml(initialEditorText, cutLabelsArr);
      quill.setContents(initialOpsObject || quill.clipboard.convert(initialHTMLText));
    }
    // Added for avoiding infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cutLabelsArr, initialOpsObject, quill]);

  useEffect(() => {
    if (quillInstanceRef.current || !quill || !Quill) {
      return;
    }

    Quill.register(
      {
        'formats/emoji': quillEmoji.EmojiBlot,
        'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
        'modules/emoji-textarea': quillEmoji.TextAreaEmoji,
        'modules/emoji-shortname': quillEmoji.ShortNameEmoji,
      },
      true
    );
    quillInstanceRef.current = quill;
    quill.on('text-change', ({ ops }) => {
      const { containsProperties } = getQuillInnerHtmlText(quill.root.innerHTML);
      const insertOp = ops.find((op) => op.insert);
      const delta = quill.getContents();
      const text = quill.getText().trim();
      if (type === 'LONG') {
        if (!text.includes('~') && (new RegExp(/[^.0-9]/gm).test(text) || Number.isNaN(Number(text)))) {
          let filtered = text.replace(/[^.0-9]/gm, '');
          if (Number.isNaN(Number(text))) {
            const firstOccuranceIndex = filtered.search(/\./) + 1; // Index of first occurance of (.)
            filtered = filtered.substr(0, firstOccuranceIndex) + filtered.slice(firstOccuranceIndex).replace(/\./g, '');
          }
          quill.setText(filtered);
        } else if (text && delta.ops.length > 1) {
          quill.updateContents({
            ops: [{ delete: 1 }],
          });
        }
      }
      if (singleProperty && typeof insertOp?.insert === 'string' && insertOp?.insert !== '~' && containsProperties) {
        quill.updateContents({
          ops: [{ delete: insertOp?.insert?.length }, insertOp, { delete: insertOp?.insert?.length }],
        });
      }
    });
  }, [Quill, initialEditorText, cutLabelsArr, quill, singleProperty, type]);

  useEffect(() => {
    window.addEventListener('mention-clicked', mentionClickHandler);
    return () => window.removeEventListener('mention-clicked', mentionClickHandler);
  }, [mentionClickHandler]);

  const onPropertySelect = useCallback(
    ({ key }) => {
      const content = getQuillEditorHtml(key, cutLabelsArr);
      const property = quill.clipboard.convert(content);
      if (singleProperty || type === 'LONG') {
        quill.setContents(property);
      } else {
        const { index = quill.getLength() - 1, length = 0 } = cursorPosition;
        const newOps = [!!index && { retain: index }, !!length && { delete: length }, ...property.ops].filter(Boolean);

        quill.updateContents(newOps);
      }
      const { text } = getQuillInnerHtmlText(quillInstanceRef.current.root.innerHTML);
      handleSave(text);
      setCursorPosition({});
      handleSavePlainText(text, quill.editor.getDelta());
    },
    [cursorPosition, cutLabelsArr, handleSave, handleSavePlainText, quill, singleProperty, type]
  );

  const context = useMemo(
    () => ({
      availableProperties: properties,
      openPropertyModal,
      setOpenPropertyModal,
      selectedPropertyObject,
      setSelectedPropertyObject,
      selectedPathArr,
      setSelectedPathArr,
      onPropertySelect,
    }),
    [onPropertySelect, openPropertyModal, properties, selectedPathArr, selectedPropertyObject]
  );

  return (
    <PropertyTextEditorContextProvider value={context}>
      <Box
        position="relative"
        border={showError ? `1px solid ${THEME.secondaryColors.nodeError}` : border}
        borderRadius="4px"
        bgcolor={bgcolor}
        width="100%"
        pr="40px"
      >
        <StyledContainer
          ref={quillRef}
          onBlur={onBlur}
          minHeight={minHeight}
          maxHeight={maxHeight}
          isFooter={isFooter}
          readOnly={readOnly}
          onClick={() => quill.focus()}
          padding={padding}
        />
        <StyledBox
          component="button"
          position="absolute"
          top="0"
          right="0"
          display="flex"
          m="0"
          p="0"
          bgcolor="transparent"
          noBorder={noBorder}
          onClick={openPropertyMenu}
        >
          <CategoryAlerts />
        </StyledBox>
        {openPropertyModal && (
          <PropertyTextEditorModal
            open={openPropertyModal}
            setOpen={setOpenPropertyModal}
            onSelect={onPropertySelect}
          />
        )}
      </Box>
    </PropertyTextEditorContextProvider>
  );
};

PropertyTextEditor.propTypes = {
  initialEditorText: string,
  handleSave: func,
  handleSavePlainText: func,
  properties: shape({
    groups: arrayOf(
      shape({
        icon: string,
        label: string.isRequired,
        type: oneOf(Object.values(propertyObjectTypes)).isRequired,
      })
    ),
    items: arrayOf(
      shape({
        key: string.isRequired,
        label: string.isRequired,
        path: string,
        type: oneOf(Object.values(propertyObjectTypes)).isRequired,
      })
    ),
  }),
  initialOpsObject: arrayOf(shape({})),
  minHeight: string,
  isFooter: bool,
  readOnly: bool,
  border: string,
  maxHeight: string,
  singleProperty: bool,
  padding: string,
  type: string,
  placeholder: string,
  bgcolor: string,
  noBorder: bool,
  showError: bool,
  setShowError: func,
};
PropertyTextEditor.defaultProps = {
  minHeight: '100px',
  isFooter: false,
  initialEditorText: '',
  properties: {
    structure: {
      icon: '',
    },
    items: {
      path: '',
    },
  },
  readOnly: false,
  handleSave: () => null,
  handleSavePlainText: () => null,
  border: `1px solid ${THEME.greyColors.grey200}`,
  maxHeight: undefined,
  singleProperty: false,
  padding: undefined,
  type: null,
  placeholder: null,
  initialOpsObject: null,
  bgcolor: THEME.primaryColors.white,
  noBorder: false,
  showError: false,
  setShowError: () => {},
};

export default PropertyTextEditor;
