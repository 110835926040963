const formNames = {
  paymentModel: 'paymentModel',
  paymentModelOther: 'paymentModelOther',
};

const formFields = [{ field: formNames.paymentModel, label: '' }];

const initialValues = {
  [formNames.paymentModel]: [],
};

export { initialValues, formFields, formNames };
