import styled from 'styled-components';
import Box from '@mui/material/Box';
import THEME from '../../../../../../../constants/theme';

export const StyledTestStatWrapper = styled(Box)`
  &:first-child {
    margin-top: 16px;
  }
`;

export const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
`;

export const StyledInfoBox = styled(StyledBox)`
  margin-left: 9px;
  cursor: pointer;
`;

export const StyledResultsBox = styled(StyledBox)`
  margin-bottom: 10px;
`;

export const StyledResultContainer = styled(StyledBox)`
  width: 100%;
  height: 53px;
  border-radius: 8px;
  padding: 10px 16px;
  background-color: ${({ $bgColor }) => $bgColor || 'transparent'};
`;

export const StyledResultTitleBox = styled(StyledBox)`
  width: 32px;
  height: 32px;
  justify-content: center;
  background: ${THEME.greyColors.grey24};
  border-radius: 4px;
  background-color: ${({ $bgColor }) => $bgColor || `${THEME.greyColors.grey24}`};
`;

export const StyledResultValueBox = styled(StyledBox)`
  width: 90%;
  justify-content: space-between;
`;
