const FEATURE_TOGGLES_KEYS = {
  MULTIPLE_FLOW: 'multipleFlow',
  /* MULTIPLE_FLOW
  - Flows back to main nav with the route component
  - Hide automations nav and the route component
  - Make the flow details page active in src/routes/RestrictedRoutes.js
  - Import historic payment → Select flow modal
  - APM
    - Connection form experience
    - Payment method section in connection details
  */
  INSIGHTS_NAV: 'insightsNav',
  /* INSIGHTS_NAV
  - Insights back to main nav
  - Removes insights from payments sub nav
  - Attach insight route component
  */
  SETTINGS_ELEMENTS_NAV: 'settingsElementsNav',
  /*SETTINGS_ELEMENTS_NAV
  - Adds Elements to settings sub nav with the route component
  - Makes payments issues route active
  */
  POC: 'poc',
  /* POC
  - Adds Payment Methods block to Checkout action node
  */
  CREATE_TEAM: 'createNewTeam',
  /* CREATE_TEAM
  - Can create new team
  */
  PAYMENT_ISSUES_SUB_NAV: 'paymentIssuesSubNav',
  /* PAYMENT_ISSUES
  - Can create issues > Payment details + payment list
  - Can view payment issue 
  - Payment issues navigation
  */
  DISPUTES_SUBMIT_EVIDENCE: 'disputesSubmitEvidence',
  /* DISPUTES_SUBMIT_EVIDENCE
  - Show submit evidence section at Disputes
  */
};

export { FEATURE_TOGGLES_KEYS };
