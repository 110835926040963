import Box from '@mui/material/Box';
import React, { useState } from 'react';
import StickyBox from 'react-sticky-box';

import ContentArea from './ContentArea';
import SideFilter from './SideFilter';
import { StyledWrapper } from './styled';
import { TABS } from './constant';

const DirectoryList = () => {
  const [activeTab, setActiveTab] = useState(TABS[0].label);

  return (
    <Box mt="40px" mb="60px" display="flex">
      <StyledWrapper>
        <StickyBox offsetTop={42} offsetBottom={32}>
          <SideFilter setActiveTab={setActiveTab} />
        </StickyBox>
      </StyledWrapper>

      <ContentArea activeTab={activeTab} setActiveTab={setActiveTab} />
    </Box>
  );
};

export default DirectoryList;
