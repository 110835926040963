import { useFormikContext } from 'formik';

const useActionHandlers = (setShowErrors) => {
  const { handleChange } = useFormikContext();

  const onChangeHandler = (e) => {
    setShowErrors(false);
    handleChange(e);
  };

  const handleFormat = (e) => {
    setShowErrors(false);

    const {
      target: { value },
    } = e;
    e.target.value = value.replace(/[^0-9.-]+/g, '');
    onChangeHandler(e);
  };

  return { onChangeHandler, handleFormat };
};

export default useActionHandlers;
